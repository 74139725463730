// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwTabSet from 'viewmodel/AwTabSetViewModel';
import Awp0ClsNodesOverview from 'viewmodel/Awp0ClsNodesOverviewViewModel';
import Awp0ClsNodesAppClass from 'viewmodel/Awp0ClsNodesAppClassViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const Awp0ClsNodesOverviewVisibleWhen = VisibleWhen(Awp0ClsNodesOverview);
const Awp0ClsNodesAppClassVisibleWhen = VisibleWhen(Awp0ClsNodesAppClass);
Awp0ClsNodesOverviewVisibleWhen.displayName = 'Awp0ClsNodesOverviewVisibleWhen';
Awp0ClsNodesAppClassVisibleWhen.displayName = 'Awp0ClsNodesAppClassVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/classifyAdminService', () => import('js/classifyAdminService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "lifecycleHooks": {
        "onInit": "initializeNodesSWA",
        "onUpdate": {
            "action": "initializeNodesSWA",
            "observers": [
                "props.subPanelContext.context.searchState.propertiesSWA"
            ]
        }
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        }
    },
    "data": {
        "headerProperties": [
            {
                "propertyName": "NodeId"
            },
            {
                "propertyName": "owning_user"
            },
            {
                "propertyName": "dateModified"
            },
            {
                "propertyName": "object_type"
            }
        ],
        "tabModels": [
            {
                "tabKey": "Awp0ClsNodesOverview",
                "name": "{{i18n.overview}}"
            },
            {
                "tabKey": "Awp0ClsNodesAppClass",
                "name": "{{i18n.applicationClass}}"
            }
        ],
        "classifyImageCommands": "classify_imgCommands"
    },
    "actions": {
        "initializeNodesSWA": {
            "actionType": "JSFunction",
            "method": "initializeSWA",
            "inputData": {
                "subPanelContext": "{{subPanelContext.context.searchState}}",
                "subLocationName": "Nodes"
            },
            "outputData": {
                "currentSecData": "currentSecData",
                "hasImages": "hasImages",
                "selectedUid": "selectedUid",
                "parentProp": "parentProp",
                "appClassProp": "appClassProp"
            },
            "deps": "js/classifyAdminService"
        }
    },
    "onEvent": [],
    "conditions": {
        "showOverviewPage": {
            "expression": "data.selectedTab.tabKey === 'Awp0ClsNodesOverview'"
        },
        "showAppclassPage": {
            "expression": "data.selectedTab.tabKey === 'Awp0ClsNodesAppClass'"
        }
    },
    "i18n": {
        "applicationClass": [
            "ClassificationAdminMessages"
        ],
        "attributes": [
            "ClassificationAdminMessages"
        ],
        "overview": [
            "ClassificationAdminMessages"
        ],
        "parent": [
            "ClassificationAdminMessages"
        ],
        "preview": [
            "ClassificationAdminMessages"
        ],
        "propertiesTitle": [
            "ClassificationAdminMessages"
        ]
    },
    "_viewModelId": "Awp0ClsNodesSecWorkArea",
    "_uniqueViewModelId": "Awp0ClsNodesSecWorkArea",
    "ctx": {}
};

    /*
    <aw-panel-body class="aw-clsadmin-nodes">
    <aw-tab-set tabs="data.tabModels" sub-panel-context="props.subPanelContext">
        <awp0-cls-nodes-overview sub-panel-context="props.subPanelContext" visible-when="conditions.showOverviewPage"></awp0-cls-nodes-overview>
        <awp0-cls-nodes-app-class sub-panel-context="props.subPanelContext" visible-when="conditions.showAppclassPage"></awp0-cls-nodes-app-class>
    </aw-tab-set>
</aw-panel-body>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanelBody className="aw-clsadmin-nodes">
        <AwTabSet  updateHook={AwParseService.instance( "{data, conditions,dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} tabs={AwParseService.instance( "data.tabModels" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "props.subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <Awp0ClsNodesOverviewVisibleWhen subPanelContext={AwParseService.instance( "props.subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} visibleWhen={AwParseService.instance( "conditions.showOverviewPage" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </Awp0ClsNodesOverviewVisibleWhen>
            <Awp0ClsNodesAppClassVisibleWhen subPanelContext={AwParseService.instance( "props.subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} visibleWhen={AwParseService.instance( "conditions.showAppclassPage" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </Awp0ClsNodesAppClassVisibleWhen>
        </AwTabSet>
    </AwPanelBody>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;