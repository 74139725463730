// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * This service will do the following
 * 1. Inflate occurrrence properties for cpd0design_element_id and cpd0source_object
 * 2. Inflate cpd0source_object(T4B_DesignItemRevision) for t4b_comp_DOKU_ID
 * 3. Create a Map with key as cpd0design_element_id and value as t4b_comp_DOKU_ID
 *
 * TODO: Some design elements have same DOKU_ID. This seems a valid case but check with Markus - Markus confirmed that there could be same DoKu Id for different DEs
 *
 * @module js/G4B_ShowHidePartitionsInVaaSService
 *
 */

import eventBus from "js/eventBus";
import soaSvc from "soa/kernel/soaService";
import appCtxService from "js/appCtxService";
import cdm from "soa/kernel/clientDataModel";
import g4bSelectPartationAndVisuliseToVaasService from "js/G4B_SelectPartationAndVisuliseToVaasService";

let exports = {};

// eslint-disable-next-line no-unused-vars
function getVaaSCacheByDokuIDandTransformMatrix(
  param_dokuId,
  param_transformationMatrix
) {
  let cacheElement = undefined;

  let vaasVisulalisationList = JSON.parse(
    sessionStorage.getItem("vaasInVisulationList")
  );
  for (let i = 0; i < vaasVisulalisationList.length; i++) {
    if (
      vaasVisulalisationList[i].dokuId == param_dokuId &&
      vaasVisulalisationList[i].transformMatrix == param_transformationMatrix
    ) {
      cacheElement = vaasVisulalisationList[i];
      break;
    }
  }

  return cacheElement;
}

// eslint-disable-next-line no-unused-vars
function hash(str) {
  let hash = 5381,
    i = str.length;

  while (i) {
    // eslint-disable-next-line no-extra-parens
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
}

function goToPartitionHideShow(data, ctx) {
  //console.log("Type ", "goToPartitionHideShow");
  let mselected = ctx.mselected;

  let vaaslist = JSON.parse(
    sessionStorage.getItem("vaasInVisulationListOfPartitions")
  );
  if (vaaslist != null) {
    g4bSelectPartationAndVisuliseToVaasService.getAllPartationsFromSelection(
      ctx,
      mselected
    );
  } else {
    // eslint-disable-next-line no-console
    console.log("Storage is Empty", " <<<<<<");
  }
}

function goToDesignElementHideShow(data, ctx) {
  let inputData = {};
  let selectedObject = [];
  // eslint-disable-next-line no-unused-vars
  let vaasInput;
  let selectedElements = ctx.mselected;
  for (let count = 0; count < selectedElements.length; count++) {
    if (
      ctx.selected.type == "Fgb0PartUsage" &&
      selectedElements[count].props.fgb0DesignComponent != undefined
    ) {
      selectedObject.push(
        cdm.getObject(
          selectedElements[count].props.fgb0DesignComponent.dbValues[0]
        )
      );
    } else if (
      ctx.selected.type == "Fgd0DesignElement" &&
      selectedElements[count].props.awb0UnderlyingObject != undefined
    ) {
      selectedObject.push(
        cdm.getObject(
          selectedElements[count].props.awb0UnderlyingObject.dbValues[0]
        )
      );
    }
  }

  inputData.objects = selectedObject;
  let vaaslist = JSON.parse(sessionStorage.getItem("vaasInVisulationList"));
  //TODO: Once the data Model is updated on V-Cloud we can directly get the DoKu Id from g4b_PEP_DOKID. So we do not need to inflate cpd0source_object for t4b_comp_DOKU_ID and save server call.
  inputData.attributes = [
    "cpd0design_element_id",
    "g4b_PEP_DOKID",
    "mdl0absolute_transform",
  ];
  //console.log("input data->", inputData)
  soaSvc
    .post("Core-2006-03-DataManagement", "getProperties", inputData)
    .then(function () {
      for (let i = 0; i < selectedObject.length; i++) {
        if (
          selectedObject[i].props.cpd0design_element_id.dbValues[0] != null &&
          selectedObject[i].props.mdl0absolute_transform.dbValues != null &&
          selectedObject[i].props.g4b_PEP_DOKID.dbValues[0] != null
        ) {
          vaasInput = {
            elementId:
              selectedObject[i].props.cpd0design_element_id.dbValues[0],
            transformMatrix:
              selectedObject[i].props.mdl0absolute_transform.dbValues,
            dokuId: selectedObject[i].props.g4b_PEP_DOKID.dbValues[0],
            nodeId: "",
          };
          //console.log("VaasInput->", vaasInput);
          try {
            for (
              let storedElement = 0;
              storedElement < vaaslist.length;
              storedElement++
            ) {
              if (
                vaaslist[storedElement].dokuId ==
                  selectedObject[i].props.g4b_PEP_DOKID.dbValues[0] &&
                vaaslist[storedElement].transformMatrix ==
                  selectedObject[i].props.mdl0absolute_transform.dbValues
              ) {
                if (vaaslist[storedElement].visibility == true) {
                  window.vaasPopupWindow.webvis.setProperty(
                    vaaslist[storedElement].value,
                    "enabled",
                    0
                  );
                  //document.getElementById("g4b-vaasFrame").contentWindow.webvis.setProperty(vaaslist[storedElement].value, "localTransform", vaasInput.transformMatrix);

                  vaaslist[storedElement].visibility = false;
                  sessionStorage.setItem(
                    "vaasInVisulationList",
                    JSON.stringify(vaaslist)
                  );
                  break;
                } else {
                  window.vaasPopupWindow.webvis.setProperty(
                    vaaslist[storedElement].value,
                    "enabled",
                    1
                  );
                  //document.getElementById("g4b-vaasFrame").contentWindow.webvis.setProperty(vaaslist[storedElement].value, "localTransform", vaasInput.transformMatrix);

                  vaaslist[storedElement].visibility = true;
                  sessionStorage.setItem(
                    "vaasInVisulationList",
                    JSON.stringify(vaaslist)
                  );
                  break;
                }
              }
            }
          } catch (ex) {
            // eslint-disable-next-line no-console
            console.error(
              "Error while Hide / Show in Visualization for Design Element data to webvis!"
            );
          }
        }
      }
      // eslint-disable-next-line no-console
      console.log("Loading Complete for ", "Hide / Show in Visualization");
    });
}

export let getAllProductsUnderContext6 = function (data, ctx) {
  if (ctx.selected.type == "Fgf0PartitionElement") {
    goToPartitionHideShow(data, ctx);
  } else if (
    ctx.selected.type == "Fgd0DesignElement" ||
    ctx.selected.type == "Fgb0PartUsage"
  ) {
    goToDesignElementHideShow(data, ctx);
  } else {
    // eslint-disable-next-line no-console
    console.log(
      ctx.selected.type + "is not valid for showing / hiding in visualization"
    );
  }
};

let factoryInitialize_G4B_ShowHidePartitionsInVaaSService = function () {
  eventBus.subscribe(
    "gwt.SubLocationContentSelectionChangeEvent",
    function () {
      try {
        let vaasFrame = document.getElementById("g4b-vaasFrame");
        if (vaasFrame == null) {
          if (appCtxService.getCtx("isIframeVisible") == undefined) {
            appCtxService.registerCtx("isIframeVisible", false);
          }
        }
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.log(ex);
      }
    },
    "G4B_ShowHidePartitionsInVaaSService"
  );
};

factoryInitialize_G4B_ShowHidePartitionsInVaaSService();

exports = {
  getAllProductsUnderContext6,
};

export default exports;
