// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwLayoutSlot from 'viewmodel/AwLayoutSlotViewModel';
import UiView from 'viewmodel/UiViewViewModel';
    import { ShowWhen,AwClass } from 'js/hocCollection';
const DivShowWhen = ShowWhen('div');
const DivAwClass = AwClass('div');
DivShowWhen.displayName = 'DivShowWhen';
DivAwClass.displayName = 'DivAwClass';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/aw.narrowMode.service', () => import('js/aw.narrowMode.service'));
registerDynImportEntry('js/defaultLocationService', () => import('js/defaultLocationService'));
registerDynImportEntry('js/appCtxService', () => import('js/appCtxService'));
registerDynImportEntry('js/keyboardService', () => import('js/keyboardService'));
registerDynImportEntry('js/awHeaderService', () => import('js/awHeaderService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "props": {
        "sub-panel-context": {
            "type": "object"
        }
    },
    "data": {
        "locationPanelStyle": "",
        "layoutSummaryOnly": "false",
        "preNarrowTitle": null
    },
    "actions": {
        "checkNarrowMode": {
            "actionType": "JSFunction",
            "method": "checkNarrowMode",
            "deps": "js/aw.narrowMode.service"
        },
        "getLocationName": {
            "actionType": "JSFunction",
            "method": "normalizeStateName",
            "deps": "js/defaultLocationService",
            "outputData": {
                "normalizeStateName": ""
            }
        },
        "registerLocationName": {
            "actionType": "JSFunction",
            "method": "updateCtx",
            "deps": "js/appCtxService",
            "inputData": {
                "name": "locationContext",
                "value": {
                    "ActiveWorkspace:Location": "{{data.normalizeStateName}}",
                    "ActiveWorkspace:SubLocation": "{{ctx.locationContext['ActiveWorkspace:SubLocation']}}"
                }
            }
        },
        "registerKeyBoardEvents": {
            "actionType": "JSFunction",
            "method": "registerKeyDownEvent",
            "deps": "js/keyboardService"
        },
        "subscribeForUnloadEvent": {
            "actionType": "JSFunction",
            "method": "subscribeForLocationUnloadEvent",
            "inputData": {
                "name": "MfeCommonLocation"
            },
            "deps": "js/defaultLocationService"
        },
        "constructHeader": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "getLocationName"
                },
                {
                    "action": "registerLocationName"
                },
                {
                    "action": "registerKeyBoardEvents"
                },
                {
                    "action": "subscribeForUnloadEvent"
                },
                {
                    "action": "constructTitles"
                },
                {
                    "action": "setTitlesInCtx"
                }
            ]
        },
        "constructTitles": {
            "actionType": "JSFunctionAsync",
            "method": "getTitles",
            "deps": "js/awHeaderService",
            "outputData": {
                "browserTitle": "result.browserTitle",
                "browserSubTitle": "result.browserSubTitle",
                "headerTitle": "result.headerTitle"
            }
        },
        "setTitlesInCtx": {
            "actionType": "JSFunction",
            "method": "updateCtx",
            "deps": "js/appCtxService",
            "inputData": {
                "name": "location.titles",
                "value": {
                    "browserTitle": "{{data.browserTitle}}",
                    "browserSubTitle": "{{data.browserSubTitle}}",
                    "headerTitle": "{{data.headerTitle}}"
                }
            }
        },
        "updateBreadcrumbAction": {
            "actionType": "JSFunction",
            "method": "updateBreadCrumb",
            "deps": "js/awHeaderService",
            "inputData": {
                "eventData": "{{parameters.eventData}}"
            },
            "outputData": {
                "breadCrumbProvider": "result.breadCrumbProvider",
                "breadcrumbConfig": "result.breadcrumbConfig",
                "objFound": "result.objFound"
            }
        },
        "resetBreadcrumbAction": {
            "actionType": "JSFunction",
            "method": "resetBreadCrumb",
            "deps": "js/awHeaderService",
            "outputData": {
                "breadCrumbProvider": "result.breadCrumbProvider"
            }
        },
        "updateTitles": {
            "actionType": "JSFunction",
            "method": "updateDocumentTitles",
            "deps": "js/awHeaderService"
        },
        "narrowModeChangeAction": {
            "actionType": "JSFunction",
            "method": "narrowModeChange",
            "deps": "js/aw.narrowMode.service",
            "inputData": {
                "eventData": "{{parameters.eventData}}",
                "layoutSummaryOnlyStyle": "{{data.layoutSummaryOnlyStyle}}",
                "headerTitle": "{{data.headerTitle}}",
                "preNarrowTitle": "{{data.preNarrowTitle}}"
            },
            "outputData": {
                "layoutSummaryOnlyStyle": "result.layoutSummaryOnlyStyle",
                "headerTitle": "result.headerTitle",
                "preNarrowTitle": "result.preNarrowTitle"
            }
        },
        "narrowSummaryLocationTitleClickAction": {
            "actionType": "JSFunction",
            "method": "narrowSummaryLocationTitleClick",
            "deps": "js/aw.narrowMode.service",
            "inputData": {
                "layoutSummaryOnlyStyle": "{{data.layoutSummaryOnlyStyle}}",
                "headerTitle": "{{data.headerTitle}}",
                "preNarrowTitle": "{{data.preNarrowTitle}}"
            },
            "outputData": {
                "layoutSummaryOnlyStyle": "result.layoutSummaryOnlyStyle",
                "headerTitle": "result.headerTitle",
                "preNarrowTitle": "result.preNarrowTitle"
            }
        }
    },
    "onEvent": [
        {
            "eventId": "aw.windowResize",
            "action": "checkNarrowMode"
        },
        {
            "eventId": "refreshBreadCrumb",
            "inputArgs": {
                "eventData": "{{eventData}}"
            },
            "action": "updateBreadcrumbAction"
        },
        {
            "eventId": "resetBreadCrumb",
            "action": "resetBreadcrumbAction"
        },
        {
            "eventId": "appCtx.update",
            "condition": "name === 'location.titles'",
            "action": "updateTitles"
        },
        {
            "eventId": "narrowModeChangeEvent",
            "inputArgs": {
                "eventData": "{{eventData}}"
            },
            "action": "narrowModeChangeAction"
        },
        {
            "eventId": "narrowSummaryLocationTitleClickEvent",
            "action": "narrowSummaryLocationTitleClickAction"
        }
    ],
    "conditions": {
        "isTaskbarFullScreen": {
            "expression": "ctx.taskbarfullscreen && ctx.taskbarfullscreen === 'true'"
        }
    },
    "lifecycleHooks": {
        "onMount": "constructHeader"
    },
    "_viewModelId": "MfeCommonLocation",
    "_uniqueViewModelId": "MfeCommonLocation",
    "ctx": {
        "locationContext": {
            "type": "object"
        },
        "taskbarfullscreen": {
            "type": "object"
        },
        "sidenavCommandId": {
            "type": "object"
        }
    }
};

    /*
    <div class="sw-column w-12 h-12">
    <div show-when="!conditions.isTaskbarFullScreen">
        <aw-layout-slot name="mfe_header" context="data" class="aw-layout-fit">
        </aw-layout-slot>
    </div>
    <aw-layout-slot name="mfe_taskBar" context="data" class="aw-layout-fit"></aw-layout-slot>
    <div class="sw-row aw-layout-subLocation aw-mfe-subpageContent">
        <div class="sw-column w-12" aw-class="[ctx.sidenavCommandId ? 'aw-mfe-subpageContentWithLeftPanelOpened':'']">
            <ui-view class="sw-column"></ui-view>
        </div>
    </div>
</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="sw-column w-12 h-12">
        <DivShowWhen showWhen={AwParseService.instance( "!conditions.isTaskbarFullScreen" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwLayoutSlot name="mfe_header" context={AwParseService.instance( "data" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-layout-fit">
            </AwLayoutSlot>
        </DivShowWhen>
        <AwLayoutSlot name="mfe_taskBar" context={AwParseService.instance( "data" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-layout-fit">
        </AwLayoutSlot>
        <div className="sw-row aw-layout-subLocation aw-mfe-subpageContent">
            <DivAwClass className="sw-column w-12" awClass={AwParseService.instance( "[ctx.sidenavCommandId ? 'aw-mfe-subpageContentWithLeftPanelOpened':'']" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <UiView className="sw-column">
                </UiView>
            </DivAwClass>
        </div>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;