// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwEmptyWorkarea from 'viewmodel/AwEmptyWorkareaViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const DivVisibleWhen = VisibleWhen('div');
DivVisibleWhen.displayName = 'DivVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "conditions": {
        "checkIfOnlySWAIsEmpty": {
            "expression": "props.subPanelContext.context.searchState.totalFound>0"
        },
        "checkIfPWAIsEmpty": {
            "expression": "!props.subPanelContext.context.searchState.totalFound"
        }
    },
    "i18n": {
        "printOutsLocationSwaHint": [
            "ReportChartMessages"
        ]
    },
    "_viewModelId": "PrintOutsEmptyState",
    "_uniqueViewModelId": "PrintOutsEmptyState",
    "ctx": {}
};

    /*
    <div visible-when="conditions.checkIfOnlySWAIsEmpty" style="width: 100%;">
    <aw-empty-workarea image="graEmptyReportSummary128" hint="i18n.printOutsLocationSwaHint"></aw-empty-workarea>
</div>
<div visible-when="conditions.checkIfPWAIsEmpty" style="width: 100%;">
    <aw-empty-workarea image="graEmptyReportSummary128"></aw-empty-workarea>
</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <DivVisibleWhen style={{width: '100%'}} visibleWhen={AwParseService.instance( "conditions.checkIfOnlySWAIsEmpty" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <AwEmptyWorkarea image="graEmptyReportSummary128" hint={i18n.printOutsLocationSwaHint}>
        </AwEmptyWorkarea>
    </DivVisibleWhen>
    <DivVisibleWhen style={{width: '100%'}} visibleWhen={AwParseService.instance( "conditions.checkIfPWAIsEmpty" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <AwEmptyWorkarea image="graEmptyReportSummary128">
        </AwEmptyWorkarea>
    </DivVisibleWhen>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;