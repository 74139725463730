// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "type": "search",
                "vm": "AwSearchBreadcrumb",
                "noUpdate": true,
                "popuplist": "/html/defaultbreadcrumblist.html",
                "overflowIndex": 0
            },
            "label": {
                "source": "/i18n/gepardMessages",
                "key": "G4B_DynamicConfiguredProductsTitle"
            },
            "clientScopeURI": "G4B_GepardSubLocations",
            "nameToken": "G4B_DynamicConfiguredProducts",
            "context": {
                "search": {
                    "loadDataAction": "loadSearchData",
                    "provider": "Awp0FullTextSearchProvider",
                    "sortType": "Priority",
                    "chartTitle": {
                        "source": "/i18n/gepardMessages",
                        "key": "G4B_DynamicConfiguredProductsChartTitle"
                    },
                    "hideInContentSearchBox": true
                }
            },
            "viewBase": "G4BDynamicConfiguredProducts",
            "viewModes": {
                "TableView": {
                    "primaryWorkArea": "table",
                    "secondaryWorkArea": false
                },
                "TableSummaryView": {
                    "primaryWorkArea": "table",
                    "secondaryWorkArea": true
                },
                "ListView": {
                    "primaryWorkArea": "list",
                    "secondaryWorkArea": false
                },
                "ListSummaryView": {
                    "primaryWorkArea": "list",
                    "secondaryWorkArea": true
                }
            },
            "defaultDisplayMode": "TableView"
        }
    },
    "_viewModelId": "G4BDynamicConfiguredProductsPage",
    "_uniqueViewModelId": "G4BDynamicConfiguredProductsPage",
    "ctx": {}
};

    /*
    <aw-search-page
  tabsdata="subPanelContext.tabsData"
  provider="data._provider"
></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;