// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwI18n from 'viewmodel/AwI18nViewModel';
import AwChipList from 'viewmodel/AwChipListViewModel';
import AwIcon from 'viewmodel/AwIconViewModel';
import AwScrollpanel from 'viewmodel/AwScrollpanelViewModel';
import AwPanelFooter from 'viewmodel/AwPanelFooterViewModel';
import AwButton from 'viewmodel/AwButtonViewModel';
    import { ExistWhen,ExtendedTooltip } from 'js/hocCollection';
const DivExistWhen = ExistWhen('div');
const AwChipListExistWhen = ExistWhen(AwChipList);
const DivExtendedTooltip = ExtendedTooltip('div');
const AwPanelFooterExistWhen = ExistWhen(AwPanelFooter);
DivExistWhen.displayName = 'DivExistWhen';
AwChipListExistWhen.displayName = 'AwChipListExistWhen';
DivExtendedTooltip.displayName = 'DivExtendedTooltip';
AwPanelFooterExistWhen.displayName = 'AwPanelFooterExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/ImportPreview', () => import('js/ImportPreview'));
registerDynImportEntry('js/Arm0DerivedAndMergeService', () => import('js/Arm0DerivedAndMergeService'));
registerDynImportEntry('js/Arm0ImportChangesInWordRoundTrip', () => import('js/Arm0ImportChangesInWordRoundTrip'));
registerDynImportEntry('js/popupService', () => import('js/popupService'));
registerDynImportEntry('js/Arm0ImportFromOffice', () => import('js/Arm0ImportFromOffice'));
registerDynImportEntry('js/appCtxService', () => import('js/appCtxService'));
registerDynImportEntry('js/locationNavigation.service', () => import('js/locationNavigation.service'));
registerDynImportEntry('js/Arm0DocumentationUpdateOnRoundtripService', () => import('js/Arm0DocumentationUpdateOnRoundtripService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {
        "previewInfotooltipView": {
            "view": "Arm0PreviewInfoTooltip"
        }
    },
    "actions": {
        "populateSecArea": {
            "actionType": "JSFunction",
            "inputData": {
                "data": "{{data}}"
            },
            "method": "populateSecArea",
            "deps": "js/ImportPreview",
            "events": {
                "success": [
                    {
                        "name": "progress.end",
                        "excludeLocalDataCtx": true
                    },
                    {
                        "name": "specificationSummaryDataProvider.reset"
                    }
                ],
                "failure": [
                    {
                        "name": "progress.end",
                        "excludeLocalDataCtx": true
                    }
                ]
            }
        },
        "registerCxtForActionsPanel": {
            "actionType": "JSFunction",
            "inputData": {
                "data": "{{data}}"
            },
            "method": "registerCxtForActionsPanel",
            "deps": "js/ImportPreview"
        },
        "setHeaderTitle": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "setHeaderTitleOnDerivedMerge",
                    "condition": "ctx.sublocation.clientScopeURI === 'RequirementMergePreviewURI'"
                },
                {
                    "action": "setHeaderTitleOnRoundTrip",
                    "condition": "ctx.sublocation.clientScopeURI === 'RequirementRoundTripPreviewURI'"
                }
            ]
        },
        "setHeaderTitleOnDerivedMerge": {
            "actionType": "JSFunction",
            "inputData": {
                "jsonMaster": "jsonMaster"
            },
            "method": "setHeaderTitle",
            "deps": "js/Arm0DerivedAndMergeService"
        },
        "setHeaderTitleOnRoundTrip": {
            "actionType": "JSFunction",
            "inputData": {
                "jsonMaster": "jsonMaster"
            },
            "method": "setHeaderTitleOnRoundTrip",
            "deps": "js/Arm0ImportChangesInWordRoundTrip"
        },
        "registerCxtForSettingsPanel": {
            "actionType": "JSFunction",
            "inputData": {
                "data": "{{data}}"
            },
            "method": "registerCxtForSettingsPanel",
            "deps": "js/ImportPreview"
        },
        "showActionsPanel": {
            "actionType": "JSFunction",
            "method": "show",
            "inputData": {
                "data": {
                    "declView": "Arm0ShowTypeChangePanel",
                    "options": {
                        "reference": "{{data.eventData.target}}",
                        "height": "200",
                        "placement": "right-start",
                        "hasArrow": true,
                        "draggable": false,
                        "clickOutsideToClose": true
                    }
                }
            },
            "outputData": {
                "actionsPopupId": "id",
                "actionsPopupElement": "panelEl"
            },
            "deps": "js/popupService"
        },
        "showActionsPanelMutiselectObject": {
            "actionType": "JSFunction",
            "method": "show",
            "inputData": {
                "data": {
                    "declView": "Arm0ShowTypeChangePanel",
                    "options": {
                        "reference": "button[button-id='Arm0ChangeTypeImportPreviewCmd']",
                        "toggleMode": true,
                        "placement": "bottom-end",
                        "draggable": false,
                        "clickOutsideToClose": true
                    }
                }
            },
            "outputData": {
                "actionsPopupId": "id",
                "actionsPopupElement": "panelEl"
            },
            "deps": "js/popupService"
        },
        "showSettingsPanel": {
            "actionType": "JSFunction",
            "method": "show",
            "inputData": {
                "data": {
                    "declView": "Arm0ShowSettingChangePanel",
                    "options": {
                        "reference": "{{data.eventData.target}}",
                        "width": "auto",
                        "placement": "right-start",
                        "hasArrow": true,
                        "draggable": false,
                        "clickOutsideToClose": true
                    }
                }
            },
            "outputData": {
                "actionsPopupId": "id",
                "actionsPopupElement": "panelEl"
            },
            "deps": "js/popupService"
        },
        "unRegisterData": {
            "actionType": "JSFunction",
            "method": "unRegisterPreviewData",
            "inputData": {
                "data": "{{data}}"
            },
            "deps": "js/Arm0ImportFromOffice"
        },
        "contentUnloadedPreview": {
            "actionType": "JSFunction",
            "method": "unRegisterCtx",
            "deps": "js/appCtxService",
            "inputData": {
                "name": "compareAndPreviewBtnClicked"
            }
        },
        "populateSpecificationSummaryDataForPreview": {
            "actionType": "JSFunction",
            "method": "populateSpecificationSummaryDataForPreview",
            "inputData": {
                "data": "{{data}}"
            },
            "outputData": {
                "summaryChips": "summaryChips"
            },
            "deps": "js/ImportPreview"
        },
        "previewContentUpdatedForObject": {
            "actionType": "JSFunction",
            "method": "previewContentUpdatedForObject",
            "inputData": {
                "eventData": "{{data.eventMap['importPreview.previewContentUpdatedForObject']}}"
            },
            "deps": "js/ImportPreview"
        },
        "mergeAction": {
            "actionType": "JSFunction",
            "method": "getJSONDataForMerge",
            "inputData": {
                "data": "{{data}}"
            },
            "outputData": {
                "treeData": "treeData",
                "mergeApplicationFormat": "mergeApplicationFormat"
            },
            "deps": "js/Arm0DerivedAndMergeService",
            "events": {
                "success": [
                    {
                        "name": "progress.start"
                    }
                ]
            }
        },
        "reviseAction": {
            "actionType": "JSFunction",
            "method": "getJSONDataForRevise",
            "inputData": {
                "data": "{{data}}"
            },
            "outputData": {
                "treeData": "treeData",
                "mergeApplicationFormat": "mergeApplicationFormat"
            },
            "deps": "js/Arm0DerivedAndMergeService",
            "events": {
                "success": [
                    {
                        "name": "progress.start"
                    }
                ]
            }
        },
        "getFMSFileTicketForMerge": {
            "actionType": "TcSoaService",
            "serviceName": "Core-2007-01-FileManagement",
            "method": "getTransientFileTicketsForUpload",
            "inputData": {
                "transientFileInfos": [
                    {
                        "fileName": "mergeJsonString.txt",
                        "isBinary": true
                    }
                ]
            },
            "outputData": {
                "jsonFmsTicketForMerge": "transientFileTicketInfos[0].ticket"
            },
            "events": {
                "success": [
                    {
                        "name": "importSpecification.updateFileContentInFormDataForMerge"
                    }
                ],
                "failure": [
                    {
                        "name": "progress.end",
                        "excludeLocalDataCtx": true
                    }
                ]
            }
        },
        "updateFileContentInFormDataForMerge": {
            "actionType": "JSFunction",
            "method": "updateFileContentInFormDataForMerge",
            "deps": "js/Arm0DerivedAndMergeService",
            "inputData": {
                "data": "{{data}}"
            }
        },
        "uploadImportFileForMerge": {
            "actionType": "RESTService",
            "serviceName": "",
            "method": "POST",
            "inputData": {
                "request": {
                    "method": "POST",
                    "url": "{{Constants.fmsUrl}}",
                    "headers": {
                        "Content-type": "undefined"
                    },
                    "data": "{{data.formDataForMerge}}"
                }
            },
            "events": {
                "success": [
                    {
                        "name": "importSpecification.setJSONDataForMerge"
                    }
                ],
                "failure": [
                    {
                        "name": "progress.end",
                        "excludeLocalDataCtx": true
                    }
                ]
            },
            "actionMessages": {},
            "deps": "js/Arm0DerivedAndMergeService"
        },
        "setJSONDataForMerge": {
            "actionType": "JSFunction",
            "method": "setJSONDataForMerge",
            "inputData": {
                "data": "{{data}}",
                "jsonFmsTicket": "{{data.jsonFmsTicketForMerge}}"
            },
            "deps": "js/Arm0DerivedAndMergeService"
        },
        "mergeUsingJSONData": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-ActiveWorkspaceReqMgmt-2018-05-ImportExport",
            "method": "importFromWord",
            "inputData": {
                "inputs": [
                    {
                        "selectedObject": "{{ctx.masterItemUid}}",
                        "transientFileWriteTicket": "{{data.jsonFmsTicket}}",
                        "applicationFormat": "{{data.mergeApplicationFormat}}",
                        "createSpecElementType": "derived",
                        "specificationType": "",
                        "isLive": false,
                        "isRunInBackground": false,
                        "isPermanentconvertToHtml": false,
                        "importAsSpec": true,
                        "pasteTopSpecRevisionUnderSelection": true,
                        "specDesc": "{{data.description.dbValue}}",
                        "keywordImportRules": null,
                        "importOptions": []
                    }
                ]
            },
            "outputData": {
                "importedObject": "output[0].resultObjects[0]",
                "partialErrors": "ServiceData.partialErrors"
            },
            "events": {
                "success": [
                    {
                        "name": "importSpecification.showObject"
                    },
                    {
                        "name": "progress.end",
                        "excludeLocalDataCtx": true
                    }
                ],
                "failure": [
                    {
                        "name": "progress.end",
                        "excludeLocalDataCtx": true
                    }
                ]
            },
            "actionMessages": {
                "failure": [
                    {
                        "condition": "partialErrors",
                        "message": "errorMessageOnAction"
                    }
                ]
            },
            "deps": "js/Arm0DerivedAndMergeService"
        },
        "showObject": {
            "actionType": "JSFunction",
            "method": "go",
            "deps": "js/locationNavigation.service",
            "inputData": {
                "showObject": "com_siemens_splm_clientfx_tcui_xrt_showObject",
                "toParams": {
                    "uid": "{{data.importedObject.uid}}"
                },
                "option": {
                    "inherit": false,
                    "reload": true
                }
            }
        },
        "unRegisterMergeContextData": {
            "actionType": "JSFunction",
            "method": "unRegisterMergeContextData",
            "inputData": {},
            "deps": "js/Arm0DerivedAndMergeService"
        },
        "importRoundTripAction": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "updateFileContentInFormDataForRoundTripImport"
                },
                {
                    "action": "uploadImportFileForRoundTrip"
                },
                {
                    "action": "importRoundTripSoaCall"
                }
            ]
        },
        "updateFileContentInFormDataForRoundTripImport": {
            "actionType": "JSFunction",
            "method": "updateFileContentInFormDataForRoundTripImport",
            "deps": "js/Arm0ImportChangesInWordRoundTrip",
            "inputData": {
                "data": "{{data}}"
            },
            "outputData": {
                "formDataForRoundTripImport": "formDataForRoundTripImport",
                "topLineToOpenOnRoundTrip": "topLineToOpenOnRoundTrip"
            }
        },
        "uploadImportFileForRoundTrip": {
            "actionType": "RESTService",
            "serviceName": "",
            "method": "POST",
            "inputData": {
                "request": {
                    "method": "POST",
                    "url": "{{Constants.fmsUrl}}",
                    "headers": {
                        "Content-type": "undefined"
                    },
                    "data": "{{data.formDataForRoundTripImport}}"
                }
            }
        },
        "importRoundTripSoaCall": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-ActiveWorkspaceReqMgmt-2018-05-ImportExport",
            "method": "importFromWord",
            "inputData": {
                "inputs": "{{function:getImportWordDocumentInputInPreviewMode}}"
            },
            "outputData": {
                "importedObject": "output[0].resultObjects[0]",
                "partialErrors": "ServiceData.partialErrors",
                "updatedobjects": "ServiceData.updated",
                "createdObjects": "ServiceData.created"
            },
            "events": {
                "success": [
                    {
                        "name": "importSpecification.checkUpdatedObjectsForDocTabAndOpenSpec"
                    }
                ]
            },
            "actionMessages": {
                "failure": [
                    {
                        "condition": "partialErrors",
                        "message": "errorMessageOnAction"
                    }
                ]
            },
            "deps": "js/Arm0ImportChangesInWordRoundTrip"
        },
        "checkUpdatedObjectsForDocTabAndOpenSpec": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "checkUpdatedObjectsForDocTab"
                },
                {
                    "action": "showObjectAfterRoundTrip"
                }
            ]
        },
        "checkUpdatedObjectsForDocTab": {
            "actionType": "JSFunction",
            "method": "checkUpdatedObjectsForDocTab",
            "inputData": {
                "updatedobjects": "{{data.updatedobjects}}",
                "createdObjects": "{{data.createdObjects}}"
            },
            "deps": "js/Arm0DocumentationUpdateOnRoundtripService"
        },
        "showObjectAfterRoundTrip": {
            "actionType": "JSFunction",
            "method": "go",
            "deps": "js/locationNavigation.service",
            "inputData": {
                "showObject": "com_siemens_splm_clientfx_tcui_xrt_showObject",
                "toParams": {
                    "uid": "{{data.topLineToOpenOnRoundTrip.uid}}"
                },
                "option": {
                    "inherit": false,
                    "reload": true
                }
            }
        }
    },
    "messages": {
        "errorMessageOnAction": {
            "messageType": "WARNING",
            "messageText": "{{data.partialErrors[0].errorValues[0].message}}"
        }
    },
    "dataProviders": {
        "specificationSummaryDataProvider": {
            "type": "Static",
            "action": "populateSpecificationSummaryDataForPreview",
            "response": "{{data.summaryChips}}",
            "totalFound": "{{data.summaryChips.length}}",
            "deps": "js/ImportPreview",
            "selectionModelMode": "none"
        }
    },
    "lifecycleHooks": {
        "onMount": "setHeaderTitle"
    },
    "onEvent": [
        {
            "eventId": "importPreview.populateSecArea",
            "cacheEventData": true,
            "action": "populateSecArea"
        },
        {
            "eventId": "importPreview.registerCxtForActionsPanel",
            "cacheEventData": true,
            "action": "registerCxtForActionsPanel"
        },
        {
            "eventId": "importPreview.registerCxtForSettingsPanel",
            "cacheEventData": true,
            "action": "registerCxtForSettingsPanel"
        },
        {
            "eventId": "importPreview.showActionsPanel",
            "action": "showActionsPanel",
            "cacheEventData": true
        },
        {
            "eventId": "importPreview.showActionsPanelMutiselectObject",
            "action": "showActionsPanelMutiselectObject",
            "cacheEventData": true
        },
        {
            "eventId": "importPreview.showSettingsPanel",
            "action": "showSettingsPanel",
            "cacheEventData": true
        },
        {
            "eventId": "ImportPreview.contentUnloaded",
            "action": "contentUnloadedPreview"
        },
        {
            "eventId": "ImportPreview.contentUnloaded",
            "action": "unRegisterData"
        },
        {
            "eventId": "ImportPreview.contentUnloaded",
            "action": "unRegisterMergeContextData",
            "condition": "ctx.sublocation.clientScopeURI === 'RequirementMergePreview'"
        },
        {
            "eventId": "importPreview.previewContentUpdatedForObject",
            "action": "previewContentUpdatedForObject",
            "cacheEventData": true
        },
        {
            "eventId": "importPreview.getFMSFileTicketForMerge",
            "action": "getFMSFileTicketForMerge"
        },
        {
            "eventId": "importSpecification.updateFileContentInFormDataForMerge",
            "action": "updateFileContentInFormDataForMerge"
        },
        {
            "eventId": "importSpecification.uploadImportFileForMerge",
            "action": "uploadImportFileForMerge"
        },
        {
            "eventId": "importSpecification.setJSONDataForMerge",
            "action": "setJSONDataForMerge"
        },
        {
            "eventId": "importSpecification.mergeUsingJSONData",
            "action": "mergeUsingJSONData"
        },
        {
            "eventId": "importSpecification.showObject",
            "action": "showObject"
        },
        {
            "eventId": "specificationSummaryDataProvider.reset",
            "action": "populateSpecificationSummaryDataForPreview"
        },
        {
            "eventId": "importSpecification.showObjectAfterRoundTrip",
            "action": "showObjectAfterRoundTrip"
        },
        {
            "eventId": "importSpecification.checkUpdatedObjectsForDocTabAndOpenSpec",
            "action": "checkUpdatedObjectsForDocTabAndOpenSpec"
        }
    ],
    "conditions": {},
    "functions": {
        "getImportWordDocumentInputInPreviewMode": {
            "functionName": "getImportWordDocumentInputInPreviewMode",
            "parameters": []
        }
    },
    "i18n": {
        "settingLabel": [
            "RequirementsCommandPanelsMessages"
        ],
        "revise": [
            "RequirementsCommandPanelsMessages"
        ],
        "mergeCommandTitle": [
            "RequirementsCommandPanelsMessages"
        ],
        "added": [
            "RequirementsCommandPanelsMessages"
        ],
        "removed": [
            "RequirementsCommandPanelsMessages"
        ],
        "modified": [
            "RequirementsCommandPanelsMessages"
        ],
        "existing": [
            "RequirementsCommandPanelsMessages"
        ],
        "import": [
            "RequirementsCommandPanelsMessages"
        ],
        "RejectedOverwrittenTooltip": [
            "RequirementsCommandPanelsMessages"
        ],
        "overwrittenTooltip": [
            "RequirementsCommandPanelsMessages"
        ],
        "FrozenToNonLatestTooltip": [
            "RequirementsCommandPanelsMessages"
        ],
        "FrozenToLatestTooltip": [
            "RequirementsCommandPanelsMessages"
        ],
        "UnfrozenTooltip": [
            "RequirementsCommandPanelsMessages"
        ]
    },
    "_viewModelId": "ImportPreview",
    "_uniqueViewModelId": "ImportPreview",
    "ctx": {
        "sublocation": {
            "type": "object"
        },
        "masterItemUid": {
            "type": "object"
        }
    }
};

    /*
    <div class="sw-column aw-requirement-importPreviewSWA">
    <div class='aw-requirement-chipList'>
        <div class="aw-requirement-chip">
            <div class="sw-row justify-right aw-requirement-previewMarginRight" exist-when="subPanelContext.context.clientScopeURI === 'RequirementMergePreviewURI' || subPanelContext.context.clientScopeURI === 'RequirementRoundTripPreviewURI'">
                <div class="aw-requirement-legendItem">
                    <div class="aw-requirement-legendItemMarker added"></div>
                    <div class="aw-requirement-legendItemLabel">
                        <aw-i18n>i18n.added</aw-i18n>
                    </div>
                </div>

                <div class="aw-requirement-legendItem">
                    <div class="aw-requirement-legendItemMarker removed"></div>
                    <div class="aw-requirement-legendItemLabel">
                        <aw-i18n>i18n.removed</aw-i18n>
                    </div>
                </div>

                <div class="aw-requirement-legendItem">
                    <div class="aw-requirement-legendItemMarker modified"></div>
                    <div class="aw-requirement-legendItemLabel">
                        <aw-i18n>i18n.modified</aw-i18n>
                    </div>
                </div>

                <div class="aw-requirement-legendItem" exist-when="subPanelContext.context.clientScopeURI !== 'RequirementRoundTripPreviewURI'">
                    <div class="aw-requirement-legendItemMarker existing"></div>
                    <div class="aw-requirement-legendItemLabel">
                        <aw-i18n>i18n.existing</aw-i18n>
                    </div>
                </div>
            </div>
            <div class="sw-row" exist-when="subPanelContext.context.clientScopeURI === 'ImportPreviewURI'">
                <div class="sw-column flex-auto" align-content="start">
                    <aw-chip-list dataprovider="data.dataProviders.specificationSummaryDataProvider"
                        exist-when="data.summaryChips.length > 0"></aw-chip-list>
                </div>
                <div class="sw-column justify-center" align-content="end">
                    <div class="aw-requirements-matrixTypeHelpIcon aw-aria-border" tabindex="0"
                        extended-tooltip="data.previewInfotooltipView">
                        <aw-icon icon-id="cmdShowInfoPanel"></aw-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sw-row">
        <div class='aw-richtexteditor-importPreviewPanel'>
            <aw-scrollpanel>
                <div id="rmWordImportPreviewDiv"
                    class='aw-richtexteditor-documentPaper aw-richtexteditor-document aw-richtexteditor-documentPanel '>
                </div>
            </aw-scrollpanel>
        </div>
    </div>
    <aw-panel-footer class="aw-requirement-previewMarginRight" exist-when="subPanelContext.context.clientScopeURI === 'RequirementMergePreviewURI'">
        <aw-action-section>
            <aw-button size="auto" action="mergeAction">
                <aw-i18n>i18n.mergeCommandTitle</aw-i18n>
            </aw-button>
            <aw-button size="auto" action="reviseAction">
                <aw-i18n>i18n.revise</aw-i18n>
            </aw-button>
        </aw-action-section>
    </aw-panel-footer>
    <aw-panel-footer class="aw-requirement-previewMarginRight" exist-when="subPanelContext.context.clientScopeURI === 'RequirementRoundTripPreviewURI'">
        <aw-action-section>
            <aw-button size="auto" action="importRoundTripAction">
                <aw-i18n>i18n.import</aw-i18n>
            </aw-button>
    </aw-panel-footer>
</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="sw-column aw-requirement-importPreviewSWA">
        <div className="aw-requirement-chipList">
            <div className="aw-requirement-chip">
                <DivExistWhen className="sw-row justify-right aw-requirement-previewMarginRight" existWhen={AwParseService.instance( "subPanelContext.context.clientScopeURI === 'RequirementMergePreviewURI' || subPanelContext.context.clientScopeURI === 'RequirementRoundTripPreviewURI'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <div className="aw-requirement-legendItem">
                        <div className="aw-requirement-legendItemMarker added">
                        </div>
                        <div className="aw-requirement-legendItemLabel">
                            <AwI18n>
                                {i18n.added}
                            </AwI18n>
                        </div>
                    </div>
                    <div className="aw-requirement-legendItem">
                        <div className="aw-requirement-legendItemMarker removed">
                        </div>
                        <div className="aw-requirement-legendItemLabel">
                            <AwI18n>
                                {i18n.removed}
                            </AwI18n>
                        </div>
                    </div>
                    <div className="aw-requirement-legendItem">
                        <div className="aw-requirement-legendItemMarker modified">
                        </div>
                        <div className="aw-requirement-legendItemLabel">
                            <AwI18n>
                                {i18n.modified}
                            </AwI18n>
                        </div>
                    </div>
                    <DivExistWhen className="aw-requirement-legendItem" existWhen={AwParseService.instance( "subPanelContext.context.clientScopeURI !== 'RequirementRoundTripPreviewURI'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        <div className="aw-requirement-legendItemMarker existing">
                        </div>
                        <div className="aw-requirement-legendItemLabel">
                            <AwI18n>
                                {i18n.existing}
                            </AwI18n>
                        </div>
                    </DivExistWhen>
                </DivExistWhen>
                <DivExistWhen className="sw-row" existWhen={AwParseService.instance( "subPanelContext.context.clientScopeURI === 'ImportPreviewURI'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <div className="sw-column flex-auto" align-content="start">
                        <AwChipListExistWhen  dataprovider={dataProviders.specificationSummaryDataProvider}  chipCondition={AwParseService.instance( "{conditions}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "data.summaryChips.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        </AwChipListExistWhen>
                    </div>
                    <div className="sw-column justify-center" align-content="end">
                        <DivExtendedTooltip className="aw-requirements-matrixTypeHelpIcon aw-aria-border" tabIndex="0" extTooltipData={AwParseService.instance( "data" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} extendedTooltip={AwParseService.instance( "data.previewInfotooltipView" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            <AwIcon iconId="cmdShowInfoPanel">
                            </AwIcon>
                        </DivExtendedTooltip>
                    </div>
                </DivExistWhen>
            </div>
        </div>
        <div className="sw-row">
            <div className="aw-richtexteditor-importPreviewPanel">
                <AwScrollpanel>
                    <div id="rmWordImportPreviewDiv" className="aw-richtexteditor-documentPaper aw-richtexteditor-document aw-richtexteditor-documentPanel ">
                    </div>
                </AwScrollpanel>
            </div>
        </div>
        <AwPanelFooterExistWhen className="aw-requirement-previewMarginRight" existWhen={AwParseService.instance( "subPanelContext.context.clientScopeURI === 'RequirementMergePreviewURI'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <aw-action-section>
                <AwButton size="auto" action={actions.mergeAction}>
                    <AwI18n>
                        {i18n.mergeCommandTitle}
                    </AwI18n>
                </AwButton>
                <AwButton size="auto" action={actions.reviseAction}>
                    <AwI18n>
                        {i18n.revise}
                    </AwI18n>
                </AwButton>
            </aw-action-section>
        </AwPanelFooterExistWhen>
        <AwPanelFooterExistWhen className="aw-requirement-previewMarginRight" existWhen={AwParseService.instance( "subPanelContext.context.clientScopeURI === 'RequirementRoundTripPreviewURI'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <aw-action-section>
                <AwButton size="auto" action={actions.importRoundTripAction}>
                    <AwI18n>
                        {i18n.import}
                    </AwI18n>
                </AwButton>
            </aw-action-section>
        </AwPanelFooterExistWhen>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;