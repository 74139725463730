// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchLocation from 'viewmodel/AwSearchLocationViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/aw.inbox.service', () => import('js/aw.inbox.service'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "lifecycleHooks": {
        "onMount": "initialize"
    },
    "actions": {
        "initialize": {
            "actionType": "JSFunctionAsync",
            "method": "getSearchCriteriaAndHeaderFromURL",
            "outputData": {
                "searchCriteria": "searchCriteria",
                "headerTitle": "headerTitle"
            },
            "deps": "js/aw.inbox.service"
        }
    },
    "_viewModelId": "AwInboxLocation",
    "_uniqueViewModelId": "AwInboxLocation",
    "ctx": {}
};

    /*
    <aw-search-location header-title="data.headerTitle" search-criteria="data.searchCriteria" ></aw-search-location>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchLocation headerTitle={AwParseService.instance( "data.headerTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchCriteria={AwParseService.instance( "data.searchCriteria" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchLocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;