// Copyright 2022 Siemens Product Lifecycle Management Software Inc.


import { ExistWhen } from 'js/hocCollection';
import MfeContentPanel from 'viewmodel/MfeContentPanelViewModel';
import selectionService from 'js/selection.service';
import mbmUtils from 'js/mbmUtils';

const DivExistWhen = ExistWhen( 'div' );
/**
 *
 * @param {Object} props props
 * @returns {Object} domelement
 */
export function detailContentPanelRenderFn( props ) {
    let tabConfigs = props.viewModel && props.viewModel.data ? props.viewModel.data.tabConfig : null;
    return (
        <DivExistWhen className='w-12 h-12' existWhen={tabConfigs}>
            <MfeContentPanel subPanelContext={tabConfigs} occContext={props.occContext}></MfeContentPanel>
        </DivExistWhen>
    );
}


/**
 *
 * @param {Array} tabConfig tabConfig
 * @param {Object} mbmContext mbmContext

 * @returns {Array} update tab config
 */
function initializeData( tabConfig, mbmContext ) {
    tabConfig.tabs.forEach( ( tab )=>{
        tab.mbmContext = mbmContext;
    } );
    return { ...tabConfig };
}
/**
 * update the selection and relation info
 * @param {*} selections selected node
 * @param {*} relationInfo relationInfo
 * @param {*} occContext occContext
 */
function updateSelection( selections, relationInfo, occContext ) {
    if ( selections && selections.length > 0 ) {
        return selectionService.updateSelection( selections, occContext.pwaSelection[0], relationInfo );
    }
    return selectionService.updateSelection( occContext.pwaSelection,  occContext.topElement );
}

/**
 * get the selection to check server visibility of command
 * @param {*} selections selected node
 * @param {*} occContext occContext
 */
function getSelectionInfoForServerVisibility( selection, occContext ) {
    let pselected = occContext.pwaSelection[0];
    if( !selection ||  !Array.isArray( selection ) || selection.length === 0 ) {
        selection = [ occContext.pwaSelection[0] ];
        pselected = occContext.topElement;
    }
    let selectionInfo = [];
    let selectedObj = [];
    selection.forEach( item => {
        selectedObj.push( {
            uid:item.uid,
            type:item.type
        } );
    } );
    selectionInfo.push( {
        contextName : '',
        parentSelectionIndex : 1,
        selectedObjects : selectedObj
    } );
    let pselectedObj = [];
    selectionInfo.push( {
        contextName : '',
        parentSelectionIndex : -1,
        selectedObjects : pselectedObj
    } );
    pselectedObj.push( {
        uid:pselected.uid,
        type:pselected.type
    } );
    return selectionInfo;
}


/**
 * To check visibility of server commands
 * @param {Object} response response of getVisibleCommands soa
 * @param {Array} commandInfo command information to check visibility
 */
function processVisibleServerCommandsResponse( response, commandInfo  ) {
    let commandIds = {};
    const visibleCommandIds = response.visibleCommandsInfo.map( x => x.commandId );
    commandIds = {
        [commandInfo.commandId] : visibleCommandIds.includes( commandInfo.commandId )
    };
    return commandIds;
}

/**
 * Update the xrtCommands visibility status in mbm context
 * @param {*} occContext occContext
 * @param {*} serverVisibilityInfo visibility info of server commands
 * @param {*} commandIds command ids whose sevrer visibiloty needs to check
 */
function updateXrtCommandContext( occContext, serverVisibilityInfo, commandIds  ) {
    let commandInfo = {};
    const visibleCommandIds = serverVisibilityInfo.visibleCommandsInfo.map( x => x.commandId );
    commandIds.forEach( commandId=>{
        commandInfo[commandId] = visibleCommandIds.includes( commandId );
    } );
    let xrtCommandState = mbmUtils.getContextValue( occContext, 'xrtCommandState' );
    if ( !xrtCommandState ) {
        xrtCommandState = {};
    }

    xrtCommandState = commandInfo;
    const objToUpdate = mbmUtils.getValueToUpdate( xrtCommandState, 'xrtCommandState' );
    mbmUtils.updateValueToContext( occContext, objToUpdate );
}

/**
 * Clear the selction once the selected node is removed
 * @param {*} selectionData selectionData
 */
const clearSelectionData = ( selectionData ) => {
    if( selectionData && selectionData.getValue() && selectionData.getValue().selected && selectionData.getValue().selected.length > 0 ) {
        selectionData.update( { selected: [], relationInfo: [] } );
    }
};
/**
 * clear the selection from mbm Context on destroy
 * @param {*} occContext occContext
 * @param {*} commandIds command ids whose sevrer visibiloty needs to check
 */
function clearselectionOnDestroy( occContext, commandIds  ) {
    let commandInfo = {};
    commandIds.forEach( commandId=>{
        commandInfo[commandId] = false;
    } );
    let xrtCommandState = mbmUtils.getContextValue( occContext, 'xrtCommandState' );
    if ( !xrtCommandState ) {
        xrtCommandState = {};
    }

    xrtCommandState = commandIds;
    const objToUpdate = mbmUtils.getValueToUpdate( xrtCommandState, 'xrtCommandState' );
    mbmUtils.updateValueToContext( occContext, objToUpdate );
}

export default {
    detailContentPanelRenderFn,
    initializeData,
    updateSelection,
    getSelectionInfoForServerVisibility,
    processVisibleServerCommandsResponse,
    updateXrtCommandContext,
    clearselectionOnDestroy,
    clearSelectionData
};
