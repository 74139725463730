// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwClsPropertyGroupTree from 'viewmodel/AwClsPropertyGroupTreeViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "ports": {},
    "lifecycleHooks": {},
    "props": {},
    "actions": {},
    "functions": {},
    "dataProviders": {},
    "columnProviders": {},
    "grids": {},
    "data": {
        "values": {
            "initialValues": {
                "attrs": [
                    {
                        "id": "sml0-430201",
                        "editable": true,
                        "name": "Test1",
                        "attributeId": "sml0-430201",
                        "type": "Block",
                        "visible": true,
                        "blockId": "sml0-430201",
                        "hasBlockChildren": true,
                        "children": [
                            {
                                "id": "sml0-430212",
                                "editable": true,
                                "name": "Test12",
                                "attributeId": "sml0-430212",
                                "type": "Block",
                                "visible": true,
                                "blockId": "sml0-430212",
                                "hasBlockChildren": false,
                                "children": []
                            }
                        ]
                    },
                    {
                        "id": "sml0-430202",
                        "editable": true,
                        "name": "Test2",
                        "attributeId": "sml0-430202",
                        "type": "Block",
                        "visible": true,
                        "blockId": "sml0-430202",
                        "hasBlockChildren": false,
                        "children": []
                    },
                    {
                        "id": "sml0-430203",
                        "editable": true,
                        "name": "Test3",
                        "attributeId": "sml0-430203",
                        "type": "Block",
                        "visible": true,
                        "blockId": "sml0-430203",
                        "hasBlockChildren": false,
                        "children": []
                    }
                ]
            },
            "meta": {}
        },
        "onEvent": [],
        "conditions": {},
        "i18n": {}
    },
    "_viewModelId": "ClsPropertyGroupTest",
    "_uniqueViewModelId": "ClsPropertyGroupTest",
    "ctx": {}
};

    /*
    <aw-command-panel-section caption="Test ClsPropertyGroup">
    <aw-panel-body class="aw-layout-panelBody aw-layout-flexColumnContainer">
        <aw-cls-property-group-tree classify-state="data.values"></aw-cls-property-group-tree>
    </aw-panel-body>
</aw-command-panel-section>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwCommandPanelSection caption="Test ClsPropertyGroup">
        <AwPanelBody className="aw-layout-panelBody aw-layout-flexColumnContainer">
            <AwClsPropertyGroupTree classifyState={AwParseService.instance( "fields.values" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwClsPropertyGroupTree>
        </AwPanelBody>
    </AwCommandPanelSection>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;