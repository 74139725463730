/**
 * Active Workspace baut eine Verbindung zum Messaging System (HiveMQ) auf und wartet danach auf eingehende Nachrichten 
 * auf der Anforderungskennung "requestId". Anschließend wird über den LAG angefordert, die Session aufzubauen. 
 * Dies geschieht über einen Aufruf der Form:

window.open("startappcommunicator://openSession?user=testUser&requestId=requestid-test&applicationId=$DPK_LAG_APPID&host=$DPK_LAG_HOST_NAME&port=$DPK_LAG_HOST_PORT"
 * 
 * Das LAG baut die Kommunikationswege über den Session Manager mit dem HiveMQ auf und liefert der WebApp die sessionId, 
 * sowie die machineId zurück, wo die Anwendung lokal ausgeführt werden soll.

onSubscribedMessageArrived(
    requstId,
    new ConfirmSessionObject(
        requestId,
        sessionId,
        machineId
    )
)
Durch die WebApp werden die Informationen zur zu öffnenden Datei in Form von Tag als ein String zusammengetragen, 
base64 enkodiert und über den MessageBroker abgeschickt.

publish(
    machineId+"/joinSession/"+requestId,
    new JoinSessionObject(
        requestId,
        userId,
        machineId,
        sessionId,
        applicationId,
        arguments,
        fileData
    )
)
 * 
 * @module js/k4bOpenFromPrismaCommandService
 */

import $ from "jquery";
import _ from "lodash";
import cdm from "soa/kernel/clientDataModel";
import prefSvc from "soa/preferenceService";
import appCtxService from "js/appCtxService";
import paho from "js/lib/pahomqtt31ver1.1.0";
import mqtt from "js/lib/DPKMqttMessagingService";
import AwPromiseService from "js/awPromiseService";

let exports = {};
let prefValue = null;
//let MQTTMessageClient = require('MqttMessagingService').Client();

const KOMMA = ",";
const UNDERSCORE = "_";
/**
 *
 * Starts the local application gateway (via opening a URL in a new window)
 */
let startLocalAppGateway = function (OEDEAttributes, GUID, userId) {
  //let deferred = AwPromiseService.instance.defer();
  let MQTTMessageClient = new mqtt.Client(paho);

  /*require([app.getBaseUrlPath() + '/js/lib/pahomqtt31ver1.1.0.js'], function (paho) {
         require([app.getBaseUrlPath() + '/js/lib/MqttMessagingService.js'], function (mqtt) {
             MQTTMessageClient = new mqtt.Client(paho);
             deferred.resolve("done.");
         });
     });*/

  let errorEventFunction = function (msg) {
    // eslint-disable-next-line no-console
    console.log("MQTT Error: ", msg.toString());
  };

  let successConnectEventFunction = function () {};
  let successSubscribedEventFunction = function () {};

  //$.when(deferred).done(function () {
  let startMqttJoinSessionObject = {};

  let MQTTUrlPromise = getLAGPreferences();
  startMqttJoinSessionObject.host = null;
  startMqttJoinSessionObject.mqttHost = null;
  startMqttJoinSessionObject.port = null;
  startMqttJoinSessionObject.userId = userId;
  startMqttJoinSessionObject.requestIdTopic = GUID;
  startMqttJoinSessionObject.applicationId = null;
  startMqttJoinSessionObject.oedeString = OEDEAttributes;

  let mqttEventListener = {};
  mqttEventListener.errorEventFunction = errorEventFunction;
  mqttEventListener.successConnectEventFunction = successConnectEventFunction;
  mqttEventListener.successSubscribedEventFunction =
    successSubscribedEventFunction;
  $.when(MQTTUrlPromise).done(function (returnURL) {
    let prefs = [];
    prefs = returnURL.split(KOMMA);
    _.forEach(prefs, function (prefValue) {});
    startMqttJoinSessionObject.mqttHost = prefs[0];
    startMqttJoinSessionObject.host = prefs[1];
    startMqttJoinSessionObject.port = prefs[2];
    startMqttJoinSessionObject.applicationId = prefs[3];
    MQTTMessageClient.startmqttJoinSession(
      startMqttJoinSessionObject,
      mqttEventListener
    );
    let startappLink =
      "startappcommunicator://openSession?userid=" +
      startMqttJoinSessionObject.userId +
      "&requestId=" +
      startMqttJoinSessionObject.requestIdTopic +
      "&applicationId=" +
      startMqttJoinSessionObject.applicationId +
      "&host=" +
      startMqttJoinSessionObject.host +
      "&port=" +
      startMqttJoinSessionObject.port;
    console.log("startappLink: " + startappLink);
    window.open(startappLink);
  });
};

/**
 * Starts startOpenCatiaFromPrisma
 */
export let startOpenCatiaFromPrisma = function (
  notificationObject,
  user,
  hasParent
) {
  let deferred;
  let OEDEString = null;
  let designRevision = null;
  let TEIL_SNR = null;
  let VERS_IND = null;
  let DOKU_ALT = null;
  let DOKU_TEIL = null;
  let DOKF_ID = null;
  let DOKT_ID = null;
  let product = "S";
  if (hasParent != null) {
    product = "P";
  }
  let UID = [];
  if (notificationObject.type === "T4B_DesignItemRevision") {
    UID.push(notificationObject.uid);
  } else {
    UID.push(notificationObject.props.awb0UnderlyingObject.dbValues[0]);
  }
  designRevision = cdm.getObject(UID);
  console.log("userID: " + user);
  console.log("UID: " + UID);
  if (
    designRevision.props.t4b_comp_TEIL_SNR == null ||
    designRevision.props.t4b_comp_TEIL_SNR == undefined ||
    designRevision.props.t4b_comp_TEIL_SNR.dbValues == null ||
    designRevision.props.t4b_comp_TEIL_SNR.dbValues == undefined ||
    designRevision.props.t4b_comp_TEIL_SNR.dbValues.length == 0 ||
    designRevision.props.t4b_comp_TEIL_SNR.dbValues[0] == null ||
    designRevision.props.t4b_comp_TEIL_SNR.dbValues[0] == undefined
  ) {
    TEIL_SNR = "";
  } else {
    TEIL_SNR = designRevision.props.t4b_comp_TEIL_SNR.dbValues[0];
    console.log("TEIL_SNR: " + TEIL_SNR);
  }
  if (
    designRevision.props.t4b_comp_VERS_IND == null ||
    designRevision.props.t4b_comp_VERS_IND == undefined ||
    designRevision.props.t4b_comp_VERS_IND.dbValues == null ||
    designRevision.props.t4b_comp_VERS_IND.dbValues == undefined ||
    designRevision.props.t4b_comp_VERS_IND.dbValues.length == 0 ||
    designRevision.props.t4b_comp_VERS_IND.dbValues[0] == null ||
    designRevision.props.t4b_comp_VERS_IND.dbValues[0] == undefined
  ) {
    VERS_IND = "";
  } else {
    VERS_IND = designRevision.props.t4b_comp_VERS_IND.dbValues[0];
    console.log("VERS_IND: " + VERS_IND);
  }
  if (
    designRevision.props.t4b_comp_DOKU_TEIL == null ||
    designRevision.props.t4b_comp_DOKU_TEIL == undefined ||
    designRevision.props.t4b_comp_DOKU_TEIL.dbValues == null ||
    designRevision.props.t4b_comp_DOKU_TEIL.dbValues == undefined ||
    designRevision.props.t4b_comp_DOKU_TEIL.dbValues.length == 0 ||
    designRevision.props.t4b_comp_DOKU_TEIL.dbValues[0] == null ||
    designRevision.props.t4b_comp_DOKU_TEIL.dbValues[0] == undefined
  ) {
    DOKU_TEIL = "";
  } else {
    DOKU_TEIL = designRevision.props.t4b_comp_DOKU_TEIL.dbValues[0];
    console.log("DOKU_TEIL: " + DOKU_TEIL);
  }
  if (
    designRevision.props.t4b_comp_DOKU_ALT == null ||
    designRevision.props.t4b_comp_DOKU_ALT == undefined ||
    designRevision.props.t4b_comp_DOKU_ALT.dbValues == null ||
    designRevision.props.t4b_comp_DOKU_ALT.dbValues == undefined ||
    designRevision.props.t4b_comp_DOKU_ALT.dbValues.length == 0 ||
    designRevision.props.t4b_comp_DOKU_ALT.dbValues[0] == null ||
    designRevision.props.t4b_comp_DOKU_ALT.dbValues[0] == undefined
  ) {
    DOKU_ALT = "";
  } else {
    DOKU_ALT = designRevision.props.t4b_comp_DOKU_ALT.dbValues[0];
    console.log("DOKU_ALT: " + DOKU_ALT);
  }
  if (
    designRevision.props.t4b_comp_DOKF_ID == null ||
    designRevision.props.t4b_comp_DOKF_ID == undefined ||
    designRevision.props.t4b_comp_DOKF_ID.dbValues == null ||
    designRevision.props.t4b_comp_DOKF_ID.dbValues == undefined ||
    designRevision.props.t4b_comp_DOKF_ID.dbValues.length == 0 ||
    designRevision.props.t4b_comp_DOKF_ID.dbValues[0] == null ||
    designRevision.props.t4b_comp_DOKF_ID.dbValues[0] == undefined
  ) {
    DOKF_ID = "";
  } else {
    DOKF_ID = designRevision.props.t4b_comp_DOKF_ID.dbValues[0];
    console.log("DOKF_ID: " + DOKF_ID);
  }
  if (
    designRevision.props.t4b_comp_DOKT_ID == null ||
    designRevision.props.t4b_comp_DOKT_ID == undefined ||
    designRevision.props.t4b_comp_DOKT_ID.dbValues == null ||
    designRevision.props.t4b_comp_DOKT_ID.dbValues == undefined ||
    designRevision.props.t4b_comp_DOKT_ID.dbValues.length == 0 ||
    designRevision.props.t4b_comp_DOKT_ID.dbValues[0] == null ||
    designRevision.props.t4b_comp_DOKT_ID.dbValues[0] == undefined
  ) {
    DOKT_ID = "";
  } else {
    DOKT_ID = designRevision.props.t4b_comp_DOKT_ID.dbValues[0];
    console.log("DOKT_ID: " + DOKT_ID);
  }
  console.log("Part of product or single: " + product);
  deferred = $.Deferred();
  deferred.resolve("done");

  $.when(deferred).done(function () {
    OEDEString =
      TEIL_SNR +
      UNDERSCORE +
      VERS_IND +
      UNDERSCORE +
      DOKU_TEIL +
      UNDERSCORE +
      DOKU_ALT +
      UNDERSCORE +
      DOKF_ID +
      UNDERSCORE +
      DOKT_ID +
      UNDERSCORE +
      product;
    console.log("OEDE Attributes: " + OEDEString);
    startLocalAppGateway(OEDEString, UID, user);
  });
};

export let loadObjects = function (loadObjects) {
  let designRevision = null;
  let UID = [];
  if (loadObjects.type === "T4B_DesignItemRevision") {
    UID.push(loadObjects.uid);
  } else {
    UID.push(loadObjects.props.awb0UnderlyingObject.dbValues[0]);
  }
  designRevision = cdm.getObject(UID);
  if (designRevision == null) {
    console.warn("No T4B_Design Revision could be loaded.");
    return;
  }
  return UID;
};
export let getObjectUID = function (selectedObjects) {
  let designRevision = [];
  let UID = [];
  if (selectedObjects.type === "T4B_DesignItemRevision") {
    UID.push(selectedObjects.uid);
  } else {
    UID.push(selectedObjects.props.awb0UnderlyingObject.dbValues[0]);
  }
  designRevision.push(cdm.getObject(UID[0]));

  return designRevision;
};
/**
 * returns a promise object that resolves to the value of the preference 'DPK_LAG_HOST_MQTT'
 * when prefService has fetched the preference value
 */
let getLAGPreferences = function () {
  let deferred = $.Deferred();
  const PREF_HOST_MQTT = "DPK_LAG_HOST_MQTT";
  const PREF_HOST_NAME = "DPK_LAG_HOST_NAME";
  const PREF_HOST_PORT = "DPK_LAG_HOST_PORT";
  const PREF_APPID = "DPK_LAG_APPID";
  const PREFERENCE_NAMES = [
    PREF_HOST_MQTT,
    PREF_HOST_NAME,
    PREF_HOST_PORT,
    PREF_APPID,
  ];
  prefSvc.getMultiStringValues(PREFERENCE_NAMES).then(function (prefValues) {
    if (
      prefValues == null ||
      prefValues == undefined ||
      prefValues.length == 0
    ) {
      console.log(
        'Please check the preference: DPK_LAG_HOST_MQTT. Using Fallback INT ["wss://lag-bmwgroup.net/mqtt, lag-bmwgroup.net/mqtt, 443, oede2catia]'
      );
      deferred.resolve([
        "wss://lag-bmwgroup.net/mqtt",
        "lag-bmwgroup.net/mqtt",
        443,
        "oede2catia",
      ]);
    } else {
      prefValue = prefValues[PREF_HOST_MQTT];
      console.log("LAG Preference " + PREF_HOST_MQTT + ": " + prefValue);
      let prefString = prefValue;
      prefValue = prefValues[PREF_HOST_NAME];
      console.log("LAG Preference " + PREF_HOST_NAME + ": " + prefValue);
      prefString = prefString + KOMMA + prefValue;
      prefValue = prefValues[PREF_HOST_PORT];
      console.log("LAG Preference " + PREF_HOST_PORT + ": " + prefValue);
      prefString = prefString + KOMMA + prefValue;
      prefValue = prefValues[PREF_APPID];
      console.log("LAG Preference " + PREF_APPID + ": " + prefValue);
      prefString = prefString + KOMMA + prefValue;
      deferred.resolve(prefString);
    }
  });
  return deferred;
};
export let getFnd0MessageObj = function () {
  let fnd0MessageObj = "";
  if (appCtxService.getCtx("selected.type") === "Fnd0Message") {
    fnd0MessageObj = appCtxService.getCtx("selected");
  } else if (
    appCtxService.getCtx("selected.type") === "Zip" &&
    appCtxService.getCtx("pselected.type") === "Fnd0Message"
  ) {
    fnd0MessageObj = appCtxService.getCtx("pselected");
  } else if (
    appCtxService.getCtx("locationContext.modelObject.type") === "Fnd0Message"
  ) {
    fnd0MessageObj = appCtxService.getCtx("locationContext.modelObject");
  }
  return fnd0MessageObj;
};

exports = {
  startOpenCatiaFromPrisma,
  getFnd0MessageObj,
  loadObjects,
  getObjectUID,
};

export default exports;
