// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import EpConfigurationChipArea from 'viewmodel/EpConfigurationChipAreaViewModel';
import AwSublocation from 'viewmodel/AwSublocationViewModel';
import MfeLoadingMessage from 'viewmodel/MfeLoadingMessageViewModel';
import MfeContentContainer from 'viewmodel/MfeContentContainerViewModel';
import EpNavigationArea from 'viewmodel/EpNavigationAreaViewModel';
import WiEditorArea from 'viewmodel/WiEditorAreaViewModel';
import EpDetailsArea from 'viewmodel/EpDetailsAreaViewModel';
import EpActivitiesArea from 'viewmodel/EpActivitiesAreaViewModel';
import EpValidation from 'viewmodel/EpValidationViewModel';
import EpGraphicsArea from 'viewmodel/EpGraphicsAreaViewModel';
import EpVisualsArea from 'viewmodel/EpVisualsAreaViewModel';
import MfeSyncManager from 'viewmodel/MfeSyncManagerViewModel';
import AwLayoutSlot from 'viewmodel/AwLayoutSlotViewModel';
    import { ExistWhen,AwClass,ShowWhen } from 'js/hocCollection';
const EpConfigurationChipAreaExistWhen = ExistWhen(EpConfigurationChipArea);
const MfeLoadingMessageExistWhen = ExistWhen(MfeLoadingMessage);
const MfeContentContainerExistWhen = ExistWhen(MfeContentContainer);
const EpNavigationAreaExistWhen = ExistWhen(EpNavigationArea);
const MfeContentContainerAwClass = AwClass(MfeContentContainer);
const MfeContentContainerShowWhen = ShowWhen(MfeContentContainer);
const MfeContentContainerAwClassShowWhen = ShowWhen(AwClass(MfeContentContainer));
const AwLayoutSlotExistWhen = ExistWhen(AwLayoutSlot);
EpConfigurationChipAreaExistWhen.displayName = 'EpConfigurationChipAreaExistWhen';
MfeLoadingMessageExistWhen.displayName = 'MfeLoadingMessageExistWhen';
MfeContentContainerExistWhen.displayName = 'MfeContentContainerExistWhen';
EpNavigationAreaExistWhen.displayName = 'EpNavigationAreaExistWhen';
MfeContentContainerAwClass.displayName = 'MfeContentContainerAwClass';
MfeContentContainerShowWhen.displayName = 'MfeContentContainerShowWhen';
MfeContentContainerAwClassShowWhen.displayName = 'MfeContentContainerAwClassShowWhen';
AwLayoutSlotExistWhen.displayName = 'AwLayoutSlotExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/epObjectPropertyCacheService', () => import('js/epObjectPropertyCacheService'));
registerDynImportEntry('js/mfeViewModelUtils', () => import('js/mfeViewModelUtils'));
registerDynImportEntry('js/appCtxService', () => import('js/appCtxService'));
registerDynImportEntry('js/epSetEffectivityService', () => import('js/epSetEffectivityService'));
registerDynImportEntry('js/epLoadService', () => import('js/epLoadService'));
registerDynImportEntry('js/epInitializationService', () => import('js/epInitializationService'));
registerDynImportEntry('js/epLeavePlaceHandler', () => import('js/epLeavePlaceHandler'));
registerDynImportEntry('js/epTabsService', () => import('js/epTabsService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "ports": {
        "WiClassicSubPage_updateIndicationContextPort": {
            "syncObject": {}
        }
    },
    "data": {
        "missingInSourceTableContext": {
            "tabs": []
        },
        "provider": {
            "label": "{{i18n.WorkInstructionsTitle}}",
            "priority": 3,
            "name": "workInstructions",
            "clientScopeURI": "workInstructions",
            "nameToken": "workInstructions"
        },
        "awSubLocationPref": {
            "hideRightWall": true
        },
        "occContext": {
            "initialValues": {
                "selectedModelObjects": [],
                "viewKey": "",
                "productContextInfo": null,
                "elementToPCIMapCount": null,
                "supportedFeatures": null,
                "skipReloadOnConfigParamChange": true
            },
            "meta": {}
        },
        "sharedSelectionData": {
            "initialValues": {
                "isIndicationToggleOn": null
            },
            "meta": {}
        },
        "configurationChipsData": {
            "initialValues": {},
            "meta": {}
        },
        "epNavigationTabsConfigForClassicBOP": {
            "tabs": [
                {
                    "name": "{{i18n.assemblyTitle}}",
                    "tabKey": "EpAssemblyTree"
                },
                {
                    "name": "{{i18n.processTitle}}",
                    "tabKey": "WiProcessTree"
                },
                {
                    "name": "{{i18n.backgroundPartsTitle}}",
                    "tabKey": "EpBackgroundPartsTree"
                }
            ]
        },
        "epNavigationTabsConfigForPlantBOP": {
            "tabs": [
                {
                    "name": "{{i18n.assemblyTitle}}",
                    "tabKey": "EpAssemblyTree"
                },
                {
                    "name": "{{i18n.processTitle}}",
                    "tabKey": "WiProcessTree"
                }
            ],
            "cmdDisplayOption": "toolbarBelowTabs"
        },
        "detailsTabsConfig": {
            "tabs": [
                {
                    "name": "{{i18n.partTitle}}",
                    "tabKey": "WiPartsTable",
                    "loadInputObject": {
                        "propertiesToLoad": [
                            "Mfg0all_material"
                        ]
                    }
                },
                {
                    "name": "{{i18n.resourcesTitle}}",
                    "tabKey": "WiResourcesTable",
                    "loadInputObject": {
                        "propertiesToLoad": [
                            "Mfg0used_equipment"
                        ]
                    }
                },
                {
                    "name": "{{i18n.workareaTitle}}",
                    "tabKey": "WiWorkareaTable",
                    "loadInputObject": {
                        "propertiesToLoad": [
                            "Mfg0assigned_workarea"
                        ]
                    }
                },
                {
                    "name": "{{i18n.assignedPmisTitle}}",
                    "tabKey": "WiAssignedPmiTable",
                    "loadInputObject": {
                        "propertiesToLoad": [
                            "epw0Inspections"
                        ]
                    }
                },
                {
                    "name": "{{i18n.summaryTitle}}",
                    "tabKey": "EpInformation",
                    "xrtType": "SUMMARY",
                    "noElementSelectedMessageForXRT": [
                        {
                            "text": "{{i18n.epInformationNoSelectionMessage}}"
                        }
                    ]
                },
                {
                    "name": "{{i18n.filesTitle}}",
                    "tabKey": "EpFiles",
                    "xrtType": "SUMMARY",
                    "loadInputObject": {
                        "propertiesToLoad": [
                            "mbc0AttachedFiles"
                        ]
                    }
                },
                {
                    "name": "{{i18n.documentsTitle}}",
                    "tabKey": "EpDocuments",
                    "xrtType": "SUMMARY",
                    "loadInputObject": {
                        "loadTypes": [
                            "GetWeblinks"
                        ],
                        "propertiesToLoad": [
                            "mbc0AssignedDocuments"
                        ],
                        "loadedObjectMapKeys": [
                            "WebLink"
                        ]
                    }
                },
                {
                    "name": "{{i18n.externalFlowsTitle}}",
                    "namePrefix": "{{i18n.externalFlowsTitle}}",
                    "tabKey": "EpExternalFlows",
                    "loadInputObject": {
                        "loadTypes": [
                            "GetScopeFlows"
                        ],
                        "relatedObjectMapKey": [
                            "ExternalPredecessors",
                            "ExternalSuccessors"
                        ]
                    }
                },
                {
                    "name": "{{i18n.inspectionTitle}}",
                    "tabKey": "EpInspectionDefinitionsTable",
                    "loadTypes": [
                        "GetInspectionDefinition"
                    ],
                    "relatedObjectMapKey": [
                        "InspectionDefinitions"
                    ],
                    "loadInputObject": {
                        "loadTypes": [
                            "GetInspectionDefinition"
                        ],
                        "relatedObjectMapKey": [
                            "InspectionDefinitions"
                        ]
                    }
                },
                {
                    "name": "{{i18n.dcdsTitle}}",
                    "tabKey": "EpDCDsTable",
                    "loadInputObject": {
                        "propertiesToLoad": [
                            "mes0DCDNodes"
                        ]
                    }
                }
            ]
        }
    },
    "actions": {
        "getIndicationsData": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "getImpactHandledData"
                },
                {
                    "action": "getMissingInSrcData"
                },
                {
                    "action": "showAllTabs",
                    "condition": "conditions.epShowAllTabs"
                },
                {
                    "action": "showMissingInSourceTabOnly",
                    "condition": "conditions.epShowMissingTabOnly"
                },
                {
                    "action": "showImpactHandledTabOnly",
                    "condition": "conditions.epShowImpactHandledTabOnly"
                },
                {
                    "action": "showNoTab",
                    "condition": "conditions.epShowNoTab"
                }
            ]
        },
        "getMissingInSrcData": {
            "actionType": "JSFunction",
            "method": "getProperty",
            "inputData": {
                "name": "{{ctx.epPageContext.loadedObject.uid}}",
                "propertyName": "accountabilityResponse"
            },
            "outputData": {
                "missingInSrc": "result.missingInSrc",
                "isMissingInSourceTabVisible": "result.missingInSrc.length"
            },
            "deps": "js/epObjectPropertyCacheService"
        },
        "showNoTab": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "missingInSourceTableContext": {
                    "tabs": []
                }
            },
            "outputData": {
                "missingInSourceTableContext": ""
            },
            "deps": "js/mfeViewModelUtils"
        },
        "getImpactHandledData": {
            "actionType": "JSFunction",
            "method": "getProperty",
            "inputData": {
                "name": "{{ctx.epPageContext.loadedObject.uid}}",
                "propertyName": "ImpactHandledObjects"
            },
            "outputData": {
                "impactHandled": "",
                "isImpactHandledTabVisible": ""
            },
            "deps": "js/epObjectPropertyCacheService"
        },
        "updateMissingInSourceTableContext": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "missingInSourceTableContext": {
                    "tabs": "{{props.missingInSourceTabsConfig.tabs}}",
                    "cmdDisplayOption": "menu"
                }
            },
            "outputData": {
                "missingInSourceTableContext": ""
            },
            "deps": "js/mfeViewModelUtils"
        },
        "clearImpactHandledCache": {
            "actionType": "JSFunction",
            "method": "clearPropertyKeyCache",
            "inputData": {
                "name": "{{ctx.epPageContext.loadedObject.uid}}",
                "propertyName": "ImpactHandledObjects"
            },
            "deps": "js/epObjectPropertyCacheService"
        },
        "showAllTabs": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "missingInSourceTableContext": {
                    "tabs": [
                        {
                            "name": "{{i18n.missingInSource}}",
                            "tabKey": "EpMissingInSource"
                        },
                        {
                            "name": "{{i18n.impactsHandled}}",
                            "tabKey": "EpImpactHandled"
                        }
                    ]
                }
            },
            "outputData": {
                "missingInSourceTableContext": ""
            },
            "deps": "js/mfeViewModelUtils"
        },
        "showMissingInSourceTabOnly": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "missingInSourceTableContext": {
                    "tabs": [
                        {
                            "name": "{{i18n.missingInSource}}",
                            "tabKey": "EpMissingInSource"
                        }
                    ]
                }
            },
            "outputData": {
                "missingInSourceTableContext": ""
            },
            "deps": "js/mfeViewModelUtils"
        },
        "showImpactHandledTabOnly": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "missingInSourceTableContext": {
                    "tabs": [
                        {
                            "name": "{{i18n.impactsHandled}}",
                            "tabKey": "EpImpactHandled"
                        }
                    ]
                }
            },
            "outputData": {
                "missingInSourceTableContext": ""
            },
            "deps": "js/mfeViewModelUtils"
        },
        "clearOccContextNClosePanelCommandAction": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "clearOccContext"
                },
                {
                    "action": "unRegisterPanelContext"
                },
                {
                    "action": "closeSetEffectivityPanel"
                }
            ]
        },
        "unRegisterPanelContext": {
            "actionType": "JSFunction",
            "method": "unRegisterCtx",
            "deps": "js/appCtxService",
            "inputData": {
                "path": "panelContext"
            }
        },
        "clearOccContext": {
            "actionType": "JSFunction",
            "method": "mergeValueInAtomicData",
            "inputData": {
                "targetAtomicData": "{{fields.occContext}}",
                "updatedValues": {
                    "selectedModelObjects": [
                        ""
                    ]
                }
            },
            "deps": "js/mfeViewModelUtils"
        },
        "closeSetEffectivityPanel": {
            "actionType": "JSFunction",
            "method": "closeSetEffectivityPanel",
            "inputData": {
                "id": "aw_toolsAndInfo"
            },
            "deps": "js/epSetEffectivityService"
        },
        "occEffectivityFailureSubscribeAction": {
            "actionType": "JSFunction",
            "method": "processPartialErrorsFromSOA",
            "inputData": {
                "value": "{{parameters.eventData.effResp.ServiceData}}"
            },
            "deps": "js/epLoadService"
        },
        "setSelectionInOccContextForEffectivitySubscribe": {
            "actionType": "JSFunctionAsync",
            "method": "initOccContext",
            "inputData": {
                "occContext": "{{fields.occContext}}",
                "value": [
                    "{{parameters.eventData.selectionForSetEffectivity}}"
                ]
            },
            "deps": "js/epSetEffectivityService"
        },
        "init": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "registerLeaveHandler"
                },
                {
                    "action": "initializeEPPage"
                },
                {
                    "action": "updateSharedSelectionPort"
                },
                {
                    "action": "initialiseConfigurationChips"
                },
                {
                    "action": "showBackgroundTab",
                    "condition": "conditions.isTCPlatformValidForDIPA"
                },
                {
                    "action": "epPageInitializeComplete"
                }
            ]
        },
        "epPageInitializeComplete": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "initialize": true
            },
            "outputData": {
                "pageIntializeComplete": ""
            },
            "deps": "js/mfeViewModelUtils"
        },
        "initializeEPPage": {
            "actionType": "JSFunctionAsync",
            "method": "initializeEPPage",
            "inputData": {
                "provider": "{{data.provider}}"
            },
            "outputData": {
                "stateContext": "stateContext",
                "propertyPolicyId": "propertyPolicyId"
            },
            "deps": "js/epInitializationService"
        },
        "registerLeaveHandler": {
            "actionType": "JSFunction",
            "method": "init",
            "deps": "js/epLeavePlaceHandler"
        },
        "destroy": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "destroyHandler"
                },
                {
                    "action": "clearImpactHandledCache"
                }
            ]
        },
        "destroyHandler": {
            "actionType": "JSFunction",
            "method": "destroy",
            "deps": "js/epLeavePlaceHandler"
        },
        "updateSharedSelectionPort": {
            "actionType": "Sync",
            "inputData": {
                "port": "WiClassicSubPage_updateIndicationContextPort",
                "syncObject": "{{fields.sharedSelectionData}}"
            }
        },
        "initialiseConfigurationChips": {
            "actionType": "JSFunction",
            "method": "mergeValueInAtomicData",
            "inputData": {
                "targetContext": "{{fields.configurationChipsData}}",
                "updatedValues": {
                    "ebomStructure": "{{ctx.epPageContext.ebomStructure}}",
                    "ebomPCI": "{{ctx.epTaskPageContext.ebomPCI}}",
                    "ebomStructureConfigFlags": "{{ctx.epTaskPageContext.ebomStructureConfigFlags}}",
                    "mbomStructure": "{{ctx.epPageContext.mbomStructure}}",
                    "mbomPCI": "{{ctx.epTaskPageContext.mbomPCI}}",
                    "mbomStructureConfigFlags": "{{ctx.epTaskPageContext.mbomStructureConfigFlags}}",
                    "processStructure": "{{ctx.epPageContext.processStructure}}",
                    "processPCI": "{{ctx.epTaskPageContext.processPCI}}",
                    "processStructureConfigFlags": "{{ctx.epTaskPageContext.processStructureConfigFlags}}",
                    "rootPlant": "{{ctx.epPageContext.rootPlant}}",
                    "plantPCI": "{{ctx.epTaskPageContext.plantPCI}}",
                    "rootPlantConfigFlags": "{{ctx.epTaskPageContext.rootPlantConfigFlags}}",
                    "functionalPlan": "{{ctx.epTaskPageContext.functionalPlan}}",
                    "functionalPlanPCI": "{{ctx.epTaskPageContext.functionalPlanPCI}}",
                    "functionalPlanConfigFlags": "{{ctx.epTaskPageContext.functionalPlanConfigFlags}}"
                }
            },
            "deps": "js/mfeViewModelUtils"
        },
        "handleProcessTabIcon": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "getChangeIndicationData"
                },
                {
                    "action": "getMissingInSrcData"
                },
                {
                    "action": "setMissingInSrcIconOnProcessTab",
                    "condition": "data.affectedUIds === '' &&  data.missingInSrc.length > 0 && data.sharedSelectionData.isIndicationToggleOn === true"
                },
                {
                    "action": "setMissingInSrcIconOnProcessTabForPlantBOP",
                    "condition": "data.affectedUIds === '' &&  data.missingInSrc.length > 0 && data.sharedSelectionData.isIndicationToggleOn === true && ctx.ep.scopeObject.modelType.typeHierarchyArray[0].includes('Mfg0BvrProcessStation')"
                },
                {
                    "action": "setImpactIconOnProcessTab",
                    "condition": "data.sharedSelectionData.isIndicationToggleOn === true &&  data.affectedUIds !== '' && ctx.state.params.tracking_cn !== null && ctx.state.params.tracking_cn !== undefined"
                },
                {
                    "action": "setImpactIconOnProcessTabForPlantBOP",
                    "condition": "data.affectedUIds !== ''  && ctx.ep.scopeObject.modelType.typeHierarchyArray[0].includes('Mfg0BvrProcessStation')"
                },
                {
                    "action": "resetChangeIndicationIconVisibility",
                    "condition": "data.sharedSelectionData.isIndicationToggleOn === false || (data.affectedUIds === '' && data.missingInSrc.length == 0 && !ctx.ep.scopeObject.modelType.typeHierarchyArray[0].includes('Mfg0BvrProcessStation'))"
                },
                {
                    "action": "resetChangeIndicationIconVisibilityForPlantBOP",
                    "condition": "data.sharedSelectionData.isIndicationToggleOn === false || (data.affectedUIds === '' && data.missingInSrc.length == 0 && ctx.ep.scopeObject.modelType.typeHierarchyArray[0].includes('Mfg0BvrProcessStation'))"
                }
            ]
        },
        "getChangeIndicationData": {
            "actionType": "JSFunction",
            "method": "getProperty",
            "inputData": {
                "name": "{{ctx.epTaskPageContext.loadedObject.uid}}",
                "propertyName": "ChangeLoadedProcessIndication"
            },
            "outputData": {
                "affectedUIds": ""
            },
            "deps": "js/epObjectPropertyCacheService"
        },
        "setMissingInSrcIconOnProcessTab": {
            "actionType": "JSFunction",
            "method": "setIconOnTab",
            "inputData": {
                "contentPanelData": "{{data.epNavigationTabsConfigForClassicBOP}}",
                "tabKey": "WiProcessTree",
                "shouldBeVisible": "{{data.sharedSelectionData.isIndicationToggleOn === true && data.missingInSrc.length > 0 && data.affectedUIds === '' }}",
                "iconName": "indicatorPanelContainsMissingInSource16"
            },
            "outputData": {
                "epNavigationTabsConfigForClassicBOP": ""
            },
            "deps": "js/epTabsService"
        },
        "setMissingInSrcIconOnProcessTabForPlantBOP": {
            "actionType": "JSFunction",
            "method": "setIconOnTab",
            "inputData": {
                "contentPanelData": "{{data.epNavigationTabsConfigForPlantBOP}}",
                "tabKey": "WiProcessTree",
                "shouldBeVisible": "{{data.sharedSelectionData.isIndicationToggleOn === true && data.missingInSrc.length > 0 && data.affectedUIds === '' }}",
                "iconName": "indicatorPanelContainsMissingInSource16"
            },
            "outputData": {
                "epNavigationTabsConfigForPlantBOP": ""
            },
            "deps": "js/epTabsService"
        },
        "setImpactIconOnProcessTab": {
            "actionType": "JSFunction",
            "method": "setIconOnTab",
            "inputData": {
                "contentPanelData": "{{data.epNavigationTabsConfigForClassicBOP}}",
                "tabKey": "WiProcessTree",
                "shouldBeVisible": true,
                "iconName": "indicatorImpacted"
            },
            "outputData": {
                "epNavigationTabsConfigForClassicBOP": ""
            },
            "deps": "js/epTabsService"
        },
        "setImpactIconOnProcessTabForPlantBOP": {
            "actionType": "JSFunction",
            "method": "setIconOnTab",
            "inputData": {
                "contentPanelData": "{{data.epNavigationTabsConfigForPlantBOP}}",
                "tabKey": "WiProcessTree",
                "shouldBeVisible": "{{data.sharedSelectionData.isIndicationToggleOn === true &&  data.affectedUIds !== '' && ctx.state.params.tracking_cn !== null && ctx.state.params.tracking_cn !== undefined}}",
                "iconName": "indicatorImpacted"
            },
            "outputData": {
                "epNavigationTabsConfigForPlantBOP": ""
            },
            "deps": "js/epTabsService"
        },
        "resetChangeIndicationIconVisibility": {
            "actionType": "JSFunction",
            "method": "setIconOnTab",
            "inputData": {
                "contentPanelData": "{{data.epNavigationTabsConfigForClassicBOP}}",
                "tabKey": "WiProcessTree",
                "shouldBeVisible": false
            },
            "outputData": {
                "epNavigationTabsConfigForClassicBOP": ""
            },
            "deps": "js/epTabsService"
        },
        "resetChangeIndicationIconVisibilityForPlantBOP": {
            "actionType": "JSFunction",
            "method": "setIconOnTab",
            "inputData": {
                "contentPanelData": "{{data.epNavigationTabsConfigForPlantBOP}}",
                "tabKey": "WiProcessTree",
                "shouldBeVisible": false
            },
            "outputData": {
                "epNavigationTabsConfigForPlantBOP": ""
            },
            "deps": "js/epTabsService"
        },
        "showBackgroundTab": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "epNavigationTabsConfigForPlantBOP": {
                    "tabs": [
                        {
                            "name": "{{i18n.assemblyTitle}}",
                            "tabKey": "EpAssemblyTree"
                        },
                        {
                            "name": "{{i18n.processTitle}}",
                            "tabKey": "WiProcessTree"
                        },
                        {
                            "name": "{{i18n.backgroundPartsTitle}}",
                            "tabKey": "EpDynamicInProcessAssemblyTree"
                        }
                    ],
                    "cmdDisplayOption": "toolbarBelowTabs"
                }
            },
            "outputData": {
                "epNavigationTabsConfigForPlantBOP": ""
            },
            "deps": "js/mfeViewModelUtils"
        }
    },
    "onEvent": [
        {
            "eventId": "setSelectionInOccContextForEffectivity",
            "action": "setSelectionInOccContextForEffectivitySubscribe",
            "inputArgs": {
                "eventData": "{{eventData}}"
            }
        },
        {
            "eventId": "failedToAuthorEffectivityEvent",
            "action": "occEffectivityFailureSubscribeAction",
            "inputArgs": {
                "eventData": "{{eventData}}"
            }
        },
        {
            "eventId": "complete",
            "action": "clearOccContextNClosePanelCommandAction"
        },
        {
            "eventId": "ep.pageContext.changed",
            "action": "initialiseConfigurationChips"
        },
        {
            "eventId": "epObjectPropertyCache.ImpactHandledObjectsUpdated",
            "action": "getIndicationsData"
        },
        {
            "eventId": "ep.publishAssignmentIndicationChange",
            "action": "getIndicationsData"
        },
        {
            "eventId": "epObjectPropertyCache.accountabilityResponseUpdated",
            "action": "getIndicationsData"
        },
        {
            "eventId": "epObjectPropertyCache.ChangeLoadedProcessIndicationUpdated",
            "action": "handleProcessTabIcon"
        }
    ],
    "conditions": {
        "isPlantBOPScope": {
            "expression": "ctx.ep.scopeObject.modelType.typeHierarchyArray.includes('Mfg0BvrProcessArea')"
        },
        "epShowAllTabs": {
            "expression": "data.missingInSrc.length > 0 && data.impactHandled.length > 0 && conditions.hasTracking_cn && conditions.checkPreferenceEPC_RemoveChangeImpactIndications"
        },
        "epShowMissingTabOnly": {
            "expression": "data.missingInSrc.length > 0 && ( !data.impactHandled || (data.impactHandled && data.impactHandled.length < 1) )"
        },
        "epShowImpactHandledTabOnly": {
            "expression": "data.missingInSrc.length < 1 && data.impactHandled.length > 0 && conditions.hasTracking_cn && conditions.checkPreferenceEPC_RemoveChangeImpactIndications"
        },
        "epShowNoTab": {
            "expression": "data.missingInSrc.length < 1 && data.impactHandled.length < 1"
        },
        "checkPreferenceEPC_RemoveChangeImpactIndications": {
            "expression": "ctx.preferences.EPC_RemoveChangeImpactIndications[0] === 'true'"
        },
        "hasTracking_cn": {
            "expression": "ctx.state.params.tracking_cn && ctx.state.params.tracking_cn !== '' && ctx.state.params.tracking_cn !== 'undefined'"
        },
        "isSyncedWithViewer": {
            "expression": "ctx.epWiSelectionMode === 'GRAPHICS'"
        },
        "isSyncedWithEditor": {
            "expression": "ctx.epWiSelectionMode === 'EDITOR'"
        },
        "isActivitiesOpen": {
            "expression": "ctx.epPageContext.showActivities === 'time'"
        },
        "shouldShowActivitiesOrValidationPanel": {
            "expression": {
                "$or": [
                    {
                        "$source": "ctx.epPageContext.showActivities",
                        "$query": {
                            "$eq": "time"
                        }
                    },
                    {
                        "$source": "ctx.epPageContext.showValidationPanel",
                        "$query": {
                            "$eq": true
                        }
                    }
                ]
            }
        },
        "isValidationMode": {
            "expression": {
                "$source": "ctx.epPageContext.showValidationPanel",
                "$query": {
                    "$eq": true
                }
            }
        },
        "hasIndicationToggleOn": {
            "expression": "data.sharedSelectionData.isIndicationToggleOn === true"
        },
        "showIndicatorOnProcessTab": {
            "expression": "conditions.hasIndicationToggleOn && conditions.epShowMissingTabOnly"
        },
        "isTCMajorVersionGreaterThan14": {
            "expression": {
                "$source": "ctx.tcSessionData.tcMajorVersion",
                "$query": {
                    "$gt": "14"
                }
            }
        },
        "isTCMajorVersionEqualTo14": {
            "expression": {
                "$source": "ctx.tcSessionData.tcMajorVersion",
                "$query": {
                    "$eq": "14"
                }
            }
        },
        "isTCMinorVersionGreaterThan2": {
            "expression": {
                "$source": "ctx.tcSessionData.tcMinorVersion",
                "$query": {
                    "$gt": "2"
                }
            }
        },
        "isTCMinorVersionEqualTo2": {
            "expression": {
                "$source": "ctx.tcSessionData.tcMinorVersion",
                "$query": {
                    "$eq": "2"
                }
            }
        },
        "isTCQRMNumberGreaterThanOrEqual1": {
            "expression": {
                "$source": "ctx.tcSessionData.tcQRMNumber",
                "$query": {
                    "$gte": "1"
                }
            }
        },
        "isReadOnlyMode": {
            "expression": "ctx.mfgReadOnlyMode.readOnlyMode"
        },
        "isTCPlatformValidForDIPA": {
            "expression": {
                "$or": [
                    {
                        "$source": "conditions.isTCMajorVersionGreaterThan14",
                        "$query": {
                            "$eq": true
                        }
                    },
                    {
                        "$source": "conditions.isTCVersionGreaterThan_14_2",
                        "$query": {
                            "$eq": true
                        }
                    },
                    {
                        "$source": "conditions.isTCVersionGreaterThanOrEqualTo_14_2_0_1",
                        "$query": {
                            "$eq": true
                        }
                    }
                ]
            }
        },
        "isTCVersionGreaterThan_14_2": {
            "expression": {
                "$and": [
                    {
                        "$source": "conditions.isTCMajorVersionEqualTo14",
                        "$query": {
                            "$eq": true
                        }
                    },
                    {
                        "$source": "conditions.isTCMinorVersionGreaterThan2",
                        "$query": {
                            "$eq": true
                        }
                    }
                ]
            }
        },
        "isTCVersionGreaterThanOrEqualTo_14_2_0_1": {
            "expression": {
                "$and": [
                    {
                        "$source": "conditions.isTCMajorVersionEqualTo14",
                        "$query": {
                            "$eq": true
                        }
                    },
                    {
                        "$source": "conditions.isTCMinorVersionEqualTo2",
                        "$query": {
                            "$eq": true
                        }
                    },
                    {
                        "$source": "conditions.isTCQRMNumberGreaterThanOrEqual1",
                        "$query": {
                            "$eq": true
                        }
                    }
                ]
            }
        }
    },
    "lifecycleHooks": {
        "onMount": "init",
        "onUnmount": "destroy",
        "onUpdate": [
            {
                "action": "updateMissingInSourceTableContext",
                "observers": [
                    "props.missingInSourceTabsConfig"
                ]
            },
            {
                "action": "handleProcessTabIcon",
                "observers": [
                    "viewModel.atomicData.sharedSelectionData.isIndicationToggleOn"
                ]
            }
        ]
    },
    "i18n": {
        "assemblyTitle": [
            "InstructionsMessages"
        ],
        "processTitle": [
            "InstructionsMessages"
        ],
        "backgroundPartsTitle": [
            "BackgroundPartsMessages"
        ],
        "partTitle": [
            "DetailsMessages"
        ],
        "resourcesTitle": [
            "DetailsMessages"
        ],
        "workareaTitle": [
            "DetailsMessages"
        ],
        "summaryTitle": [
            "DetailsMessages"
        ],
        "filesTitle": [
            "DetailsMessages"
        ],
        "documentsTitle": [
            "DetailsMessages"
        ],
        "externalFlowsTitle": [
            "DetailsMessages"
        ],
        "inspectionTitle": [
            "DetailsMessages"
        ],
        "assignedPmisTitle": [
            "DetailsMessages"
        ],
        "dcdsTitle": [
            "DetailsMessages"
        ],
        "impactsHandled": [
            "InstructionsMessages"
        ],
        "missingInSource": [
            "assignmentIndicationMessages"
        ]
    },
    "_viewModelId": "WorkInstructionsClassicSubpage",
    "_uniqueViewModelId": "WorkInstructionsClassicSubpage",
    "ctx": {
        "epPageContext": {
            "type": "object"
        },
        "epTaskPageContext": {
            "type": "object"
        },
        "ep": {
            "type": "object"
        },
        "state": {
            "type": "object"
        },
        "preferences": {
            "type": "object"
        },
        "epWiSelectionMode": {
            "type": "object"
        },
        "tcSessionData": {
            "type": "object"
        },
        "mfgReadOnlyMode": {
            "type": "object"
        }
    }
};

    /*
    <ep-configuration-chip-area exist-when="ctx.epTaskPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" configuration-chips-data = "data.configurationChipsData"></ep-configuration-chip-area>
<aw-sublocation sub-location-preference="data.awSubLocationPref" sub-panel-context="{provider : data.provider, occContext:fields.occContext, sharedSelectionData:data.sharedSelectionData}">
    <mfe-loading-message exist-when="!ctx.epPageContext.loadedObject.uid || !ctx.ep.scopeObject.uid"></mfe-loading-message>
    <mfe-content-container container-direction="HORIZONTAL" container-name="WorkInstructionsAuthorPage" class="h-12" exist-when="ctx.epPageContext.loadedObject.uid && ctx.ep.scopeObject.uid">
        <mfe-content-container container-direction="VERTICAL" container-name="WorkInstructionsAuthorPageLeftArea" class="w-3">
            <ep-navigation-area exist-when="conditions.isPlantBOPScope" sub-panel-context="{sharedSelectionData:data.sharedSelectionData}" missing-in-source-tabs-config="data.missingInSourceTableContext" assembly-tabs-config="data.epNavigationTabsConfigForPlantBOP" occ-context="fields.occContext"></ep-navigation-area>
            <ep-navigation-area exist-when="!conditions.isPlantBOPScope" sub-panel-context="{sharedSelectionData:data.sharedSelectionData}" missing-in-source-tabs-config="data.missingInSourceTableContext" assembly-tabs-config="data.epNavigationTabsConfigForClassicBOP" occ-context="fields.occContext"></ep-navigation-area>
        </mfe-content-container>

        <mfe-content-container container-direction="VERTICAL" container-name="WorkInstructionsAuthorPageMiddleArea" class="w-4" aw-class="[conditions.isSyncedWithEditor ? 'aw-ep-wiEditorArea aw-mfe-activeContentPanel' : 'aw-ep-wiEditorArea']">
            <mfe-content-container container-direction="HORIZONTAL" container-name="WorkInstructionsAuthorPageMiddleTopArea" class="h-7">
                <wi-editor-area sub-panel-context="{sharedSelectionData:data.sharedSelectionData, isPlantBOPScope: conditions.isPlantBOPScope}" occ-context="fields.occContext"></wi-editor-area>
            </mfe-content-container>
            <mfe-content-container container-direction="HORIZONTAL" container-name="WorkInstructionsAuthorPageMiddleButtomArea" class="h-5">
                <ep-details-area sub-panel-context="{sharedSelectionData:data.sharedSelectionData}" details-tabs-config="data.detailsTabsConfig" occ-context="fields.occContext"></ep-details-area>
            </mfe-content-container>
        </mfe-content-container>

        <mfe-content-container container-direction="VERTICAL" container-name="WorkInstructionsAuthorPageRightArea" class="flex-auto">
            <mfe-content-container container-direction="HORIZONTAL" container-name="WorkInstructionsAuthorPageActivitiesArea" class="h-12" exist-when="conditions.isActivitiesOpen">
            <ep-activities-area></ep-activities-area>
            </mfe-content-container>
            <mfe-content-container container-direction="HORIZONTAL" container-name="WorkInstructionsAuthorPageValidationArea" class="h-12" show-when="conditions.isValidationMode">
                <ep-validation></ep-validation>
            </mfe-content-container>
            <mfe-content-container container-direction="HORIZONTAL" container-name="WorkInstructionsAuthorPageGraphicsArea" class="aw-epBvrGraphics-minHeight flex-auto" aw-class="[conditions.isSyncedWithViewer ? 'aw-ep-epGraphicsArea aw-mfe-activeContentPanel' : 'aw-ep-epGraphicsArea']" show-when="!conditions.shouldShowActivitiesOrValidationPanel">
                <ep-graphics-area></ep-graphics-area>
            </mfe-content-container>
            <mfe-content-container container-direction="HORIZONTAL" container-name="WorkInstructionsAuthorPageVisualsArea" class="aw-epImageViewer-minHeight" show-when="!conditions.shouldShowActivitiesOrValidationPanel">
                <ep-visuals-area sub-panel-context="{sharedSelectionData:data.sharedSelectionData}" occ-context="fields.occContext"></ep-visuals-area>
            </mfe-content-container>
        </mfe-content-container>
    </mfe-content-container>
    <mfe-sync-manager view-id="epAssemblyTreeSelectionManager"></mfe-sync-manager>
    <mfe-sync-manager view-id="epSelectedProcessInWIPageSyncManager"></mfe-sync-manager>
    <aw-layout-slot exist-when="data.pageIntializeComplete" context="{sharedSelectionData:data.sharedSelectionData}" name="ep_singleVisGraphicsViewer"></aw-layout-slot>
</aw-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <EpConfigurationChipAreaExistWhen configurationChipsData={AwParseService.instance( "fields.configurationChipsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "ctx.epTaskPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </EpConfigurationChipAreaExistWhen>
    <AwSublocation subLocationPreference={AwParseService.instance( "data.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{provider : data.provider, occContext:fields.occContext, sharedSelectionData:fields.sharedSelectionData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <MfeLoadingMessageExistWhen existWhen={AwParseService.instance( "!ctx.epPageContext.loadedObject.uid || !ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </MfeLoadingMessageExistWhen>
        <MfeContentContainerExistWhen containerDirection="HORIZONTAL" containerName="WorkInstructionsAuthorPage" className="h-12" existWhen={AwParseService.instance( "ctx.epPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <MfeContentContainer containerDirection="VERTICAL" containerName="WorkInstructionsAuthorPageLeftArea" className="w-3">
                <EpNavigationAreaExistWhen subPanelContext={AwParseService.instance( "{sharedSelectionData:fields.sharedSelectionData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} missingInSourceTabsConfig={AwParseService.instance( "data.missingInSourceTableContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} assemblyTabsConfig={AwParseService.instance( "data.epNavigationTabsConfigForPlantBOP" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} occContext={AwParseService.instance( "fields.occContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "conditions.isPlantBOPScope" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </EpNavigationAreaExistWhen>
                <EpNavigationAreaExistWhen subPanelContext={AwParseService.instance( "{sharedSelectionData:fields.sharedSelectionData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} missingInSourceTabsConfig={AwParseService.instance( "data.missingInSourceTableContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} assemblyTabsConfig={AwParseService.instance( "data.epNavigationTabsConfigForClassicBOP" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} occContext={AwParseService.instance( "fields.occContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "!conditions.isPlantBOPScope" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </EpNavigationAreaExistWhen>
            </MfeContentContainer>
            <MfeContentContainerAwClass containerDirection="VERTICAL" containerName="WorkInstructionsAuthorPageMiddleArea" className="w-4" awClass={AwParseService.instance( "[conditions.isSyncedWithEditor ? 'aw-ep-wiEditorArea aw-mfe-activeContentPanel' : 'aw-ep-wiEditorArea']" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <MfeContentContainer containerDirection="HORIZONTAL" containerName="WorkInstructionsAuthorPageMiddleTopArea" className="h-7">
                    <WiEditorArea subPanelContext={AwParseService.instance( "{sharedSelectionData:fields.sharedSelectionData, isPlantBOPScope: conditions.isPlantBOPScope}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} occContext={AwParseService.instance( "fields.occContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </WiEditorArea>
                </MfeContentContainer>
                <MfeContentContainer containerDirection="HORIZONTAL" containerName="WorkInstructionsAuthorPageMiddleButtomArea" className="h-5">
                    <EpDetailsArea subPanelContext={AwParseService.instance( "{sharedSelectionData:fields.sharedSelectionData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} detailsTabsConfig={AwParseService.instance( "data.detailsTabsConfig" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} occContext={AwParseService.instance( "fields.occContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </EpDetailsArea>
                </MfeContentContainer>
            </MfeContentContainerAwClass>
            <MfeContentContainer containerDirection="VERTICAL" containerName="WorkInstructionsAuthorPageRightArea" className="flex-auto">
                <MfeContentContainerExistWhen containerDirection="HORIZONTAL" containerName="WorkInstructionsAuthorPageActivitiesArea" className="h-12" existWhen={AwParseService.instance( "conditions.isActivitiesOpen" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <EpActivitiesArea>
                    </EpActivitiesArea>
                </MfeContentContainerExistWhen>
                <MfeContentContainerShowWhen containerDirection="HORIZONTAL" containerName="WorkInstructionsAuthorPageValidationArea" className="h-12" showWhen={AwParseService.instance( "conditions.isValidationMode" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <EpValidation>
                    </EpValidation>
                </MfeContentContainerShowWhen>
                <MfeContentContainerAwClassShowWhen containerDirection="HORIZONTAL" containerName="WorkInstructionsAuthorPageGraphicsArea" className="aw-epBvrGraphics-minHeight flex-auto" awClass={AwParseService.instance( "[conditions.isSyncedWithViewer ? 'aw-ep-epGraphicsArea aw-mfe-activeContentPanel' : 'aw-ep-epGraphicsArea']" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} showWhen={AwParseService.instance( "!conditions.shouldShowActivitiesOrValidationPanel" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <EpGraphicsArea>
                    </EpGraphicsArea>
                </MfeContentContainerAwClassShowWhen>
                <MfeContentContainerShowWhen containerDirection="HORIZONTAL" containerName="WorkInstructionsAuthorPageVisualsArea" className="aw-epImageViewer-minHeight" showWhen={AwParseService.instance( "!conditions.shouldShowActivitiesOrValidationPanel" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <EpVisualsArea subPanelContext={AwParseService.instance( "{sharedSelectionData:fields.sharedSelectionData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} occContext={AwParseService.instance( "fields.occContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </EpVisualsArea>
                </MfeContentContainerShowWhen>
            </MfeContentContainer>
        </MfeContentContainerExistWhen>
        <MfeSyncManager viewId="epAssemblyTreeSelectionManager" viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </MfeSyncManager>
        <MfeSyncManager viewId="epSelectedProcessInWIPageSyncManager" viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </MfeSyncManager>
        <AwLayoutSlotExistWhen context={AwParseService.instance( "{sharedSelectionData:fields.sharedSelectionData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} name="ep_singleVisGraphicsViewer" existWhen={AwParseService.instance( "data.pageIntializeComplete" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwLayoutSlotExistWhen>
    </AwSublocation>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;