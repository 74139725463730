// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * Service for ep Single Vis Viewer
 *
 * @module js/epSingleVisViewerService
 */

import {
    constants as mfeVisConstants
} from 'js/constants/mfeVisConstants';
import epGraphicsServiceProxy from 'js/epGraphicsServiceProxy';
import appCtxService from 'js/appCtxService';
import epGraphicsService from 'js/epGraphicsService';
import epPmiGraphicsSvc from 'js/epPmiGraphicsService';
import mfeTypeUtils from 'js/utils/mfeTypeUtils';
import {
    constants as epBvrConstants
} from 'js/epBvrConstants';
import dataManagementService from 'soa/dataManagementService';
import cdm from 'soa/kernel/clientDataModel';
import policySvc from 'soa/kernel/propertyPolicyService';
import epGraphicsVisibilityService from 'js/epGraphicsVisibilityService';
import epSingleViewerCacheService from 'js/epSingleViewerCacheService';
import epGraphicsAssignmentIndicationService from 'js/epGraphicsAssignmentIndicationService';
import {
    constants as epGraphicsConstants
} from 'js/epGraphicsConstants';
import dataMgmtService from 'soa/dataManagementService';
import epLoadService from 'js/epLoadService';
import epLoadInputHelper from 'js/epLoadInputHelper';
import eventBus from 'js/eventBus';
const EP_LOADED_RESOURCE_OBJECT_KEY = 'ep.loadedResourceObject';
import AwPromiseService from 'js/awPromiseService';

'use strict';

const TOGGLE_ACTION = {
    SHOW: 'show',
    HIDE: 'hide'
};

const BACKGROUND_PARTS = 'backgroundParts';
const CTX_PATH_SUBLOCATION_NAMETOKEN = 'sublocation.nameToken';
const PMI = 'PMI';
const ORIGINAL = 'ORIGINAL';
const CTX_PATH_EBOMSTRUCTURE = 'epPageContext.ebomStructure';
const CTX_LOADED_PRODUCT_OBJECT = 'ep.loadedProductObject';
const CTX_LOADED_OBJECT = 'epPageContext.loadedObject';
const SHOW_MBOM_IN_VIEWER = 'backgroundPartsPageCtx.showMBOMInViewer';

/**
 *
 * @param {Object} vmos vmos
 * @param {*} toggleAction
 * @param {*} viewerInstanceId
 * @returns
 */
export function loadAllStructuresAndSetEpGraphicsVisibilityStateForAffectedNodes( vmos, viewerInstanceId, toggleAction, pagesSupportingWorkAreas ) {
    const loadedResource = appCtxService.getCtx( EP_LOADED_RESOURCE_OBJECT_KEY );
    let loadedProcessUid = appCtxService.getCtx( CTX_LOADED_OBJECT ).uid;
    const sublocation = appCtxService.getCtx( CTX_PATH_SUBLOCATION_NAMETOKEN );
    if( !loadedResource && !epSingleViewerCacheService.getSkipLoadingWorkAreaData() && pagesSupportingWorkAreas.includes( sublocation ) ) {
        const additionalLoadParams = getLoadParamsForAssociatedResource();

        //subscribe to loadedResourceObject event
        const updatedResourceEvent = eventBus.subscribe( 'appCtx.update', function( context ) {
            if( context.name === 'ep' && context.target === 'loadedResourceObject' ) {
                eventBus.unsubscribe( updatedResourceEvent );
                return setEpGraphicsVisibilityStateForAffectedNodes( vmos, viewerInstanceId, toggleAction );
            }
        } );
        const loadTypeInput = epLoadInputHelper.getLoadTypeInputs( 'CommonExpand', loadedProcessUid, '', '', additionalLoadParams );
        return epLoadService.loadObject( loadTypeInput, false ).then( function( result ) {
            if( !( result.relatedObjectsMap && result.relatedObjectsMap[ loadedProcessUid ] && result.relatedObjectsMap[ loadedProcessUid ].additionalPropertiesMap2 &&
                    result.relatedObjectsMap[ loadedProcessUid ].additionalPropertiesMap2.associatedResources ) ) {
                epSingleViewerCacheService.setSkipLoadingWorkAreaData( true );
                return setEpGraphicsVisibilityStateForAffectedNodes( vmos, viewerInstanceId, toggleAction );
            }
        } );
    }
    setEpGraphicsVisibilityStateForAffectedNodes( vmos, viewerInstanceId, toggleAction );
    return AwPromiseService.instance.resolve();
}

/**
 *
 * @returns{Object} additionalLoadParams
 */
function getLoadParamsForAssociatedResource() {
    return [ {
        tagName: 'expandType',
        attributeName: 'type',
        attributeValue: 'ExpandResourcesDetailedPlanning'
    },
    {
        tagName: 'expandInfo',
        attributeName: 'level',
        attributeValue: 'TOP'
    },
    {
        tagName: 'expandInfo',
        attributeName: 'rootsProperty',
        attributeValue: 'associatedResources'
    }
    ];
}
/**
 *
 * @param {ViewModelObjects} vmos view model objects
 * @param {string} viewerInstanceId - the viewer instance id
 * @param {String} toggleAction toggle action show/hide
 */
function setEpGraphicsVisibilityStateForAffectedNodes( vmos, viewerInstanceId, toggleAction ) {
    if( !Array.isArray( vmos ) ) {
        vmos = [ vmos ];
    }
    epGraphicsVisibilityService.clearUpdatedModelObjectsUidsToVisibilityStateMap();
    const pmiUid = epPmiGraphicsSvc.getAssemblyPmiUidFromRelatedObject( vmos[ 0 ] );
    const uids = pmiUid ? [ pmiUid ] : vmos.map( vmo => vmo.uid );

    let currentVisibilityStateForUid = epSingleViewerCacheService.getVisibilityStateForUid( uids[ 0 ] );

    //If object is packed line then check if at least one from packedline or children is visible
    // If at least one is visible then visibilty will be visible.
    if( !toggleAction && epSingleViewerCacheService.isPacked( uids[ 0 ] ) && currentVisibilityStateForUid === mfeVisConstants.VISIBILITY_STATUS.NONE ) {
        currentVisibilityStateForUid = epGraphicsVisibilityService.getVisibilityStateForObjectForPackedLine( uids[ 0 ] );
    }
    if( currentVisibilityStateForUid === mfeVisConstants.VISIBILITY_STATUS.LOADING ) {
        // If already loading ignore clicks for some time
        return;
    }

    const nonDeletedPmiVmoList = [];
    if( pmiUid ) {
        //if user tries to toggle a deleted PMI
        vmos.forEach( vmo => {
            if( !epPmiGraphicsSvc.isPmiDeleted( vmo ) ) {
                nonDeletedPmiVmoList.push( vmo );
            }
        } );
        if( nonDeletedPmiVmoList.length === 0 ) {
            return;
        }
    }

    // Make the node graphics visible/hidden
    let visibilityStateToggle = toggleAction;
    if( !visibilityStateToggle ) {
        visibilityStateToggle = currentVisibilityStateForUid === mfeVisConstants.VISIBILITY_STATUS.NONE ? TOGGLE_ACTION.SHOW : TOGGLE_ACTION.HIDE;
    }

    if( !pmiUid && visibilityStateToggle === TOGGLE_ACTION.HIDE ) {
        vmos = vmos.filter( obj => {
            const children = epSingleViewerCacheService.getNumberOfChildrenOfObject( obj.uid );
            if( !( children.length === 0 && !epSingleViewerCacheService.hasJT( obj.uid ) ) ) {
                return 1;
            } else if( !( children.length === 0 || !epSingleViewerCacheService.hasJT( obj.uid ) ) ) { return 0; }
        } );

        if( vmos.length === 0 ) {
            return;
        }
    }

    // Update the cache state & render in Column as Loading ...
    eventBus.publish( 'progress.start' );
    uids.forEach( uid => {
        epSingleViewerCacheService.updateVisibilityStateForData( uid, mfeVisConstants.VISIBILITY_STATUS.LOADING );
    } );
    let eventObject = {};
    vmos.forEach( vmo => {
        eventObject[ vmo.uid ] = [ 'graphicVisibility' ];
    } );
    eventBus.publish( 'viewModelObject.propsUpdated', eventObject );

    //Update visibility state for VMO and render indication
    if( pmiUid ) {
        let pmiUidvmoMap = new Map();
        for( let i = 0; i < nonDeletedPmiVmoList.length; i++ ) {
            const uid = epPmiGraphicsSvc.getAssemblyPmiUidFromRelatedObject( nonDeletedPmiVmoList[ i ] );
            pmiUidvmoMap.set( uid, nonDeletedPmiVmoList[ i ] );
        }
        togglePmiVisibilityState( pmiUidvmoMap, viewerInstanceId, visibilityStateToggle );
    } else {
        toggleBomOrBopLineVisibilityState( vmos, viewerInstanceId, visibilityStateToggle );
    }
}

/**
 *
 * @param {modelObject} modelObjects - a given vmo
 * @param {string} viewerInstanceId - the viewer instance id
 * @param {string} visibilityStateToggle -
 */
function toggleBomOrBopLineVisibilityState( modelObjects, viewerInstanceId, visibilityStateToggle ) {
    if( visibilityStateToggle === TOGGLE_ACTION.SHOW ) {
        const objectToLoad = [];
        const alreadyLoaded = [];
        modelObjects.forEach( ( obj ) => {
            if( epGraphicsVisibilityService.shouldLoadGraphicsData( obj.uid ) ) {
                objectToLoad.push( obj );
            } else {
                alreadyLoaded.push( obj );
            }
        } );
        if( objectToLoad.length > 0 ) {
            loadEpGraphicsData( objectToLoad, viewerInstanceId, visibilityStateToggle );
        }

        if( alreadyLoaded.length > 0 ) {
            epGraphicsVisibilityService.toggleEpGraphicsVisiblity( alreadyLoaded, viewerInstanceId, visibilityStateToggle );
        }
    } else {
        epGraphicsVisibilityService.toggleEpGraphicsVisiblity( modelObjects, viewerInstanceId, visibilityStateToggle );
    }
}

/**
 *
 * @param {string[]} uids - a given array of uids
 * @param {string} type - the type to load
 * @return {Promise} a promise object
 */
function loadVisStructure( uids, type ) {
    const loadedProcess = appCtxService.getCtx( CTX_LOADED_OBJECT );
    const vmo = cdm.getObject( uids[ 0 ] );
    const sourceStructureTypeStr = getSourceStructureType( vmo );
    let associatedAssembly = [];
    getAssociatedAssembly( associatedAssembly );

    const input = {
        objectsToLoad: uids,
        exclusions: [],
        type,
        contextData: {
            context: getContext( associatedAssembly, loadedProcess,vmo ),
            transformationContext: getTransformationContext( type, associatedAssembly[ 0 ] ), //Transformation context from toggle
            sourceStructureType: sourceStructureTypeStr
        }
    };
    return epGraphicsServiceProxy.loadStructure( input );
}

/**
 *
 * @param {Object} associatedAssembly associatedAssembly
 * @param {Object} loadedProcess loadedProcess
 * @returns {Array} context
 */
function getContext( associatedAssembly, loadedProcess,vmo ) {
    let context = [ loadedProcess.uid ];
    const isBackgroundPartsPanel = appCtxService.getCtx( 'isBackgroundPartsPanel' );
    if(isBackgroundPartsPanel && mfeTypeUtils.isOfTypes(vmo, [ epBvrConstants.MFG_BVR_PART ] )  && isPartInBackgroundPartsAssembly(vmo)){
        return context;
    }
    associatedAssembly.forEach( ( bom ) => {
        bom && bom.uid && context.push( bom.uid );
    } );

    const loadedResource = appCtxService.getCtx( EP_LOADED_RESOURCE_OBJECT_KEY );
    loadedResource && loadedResource.uid && context.push( loadedResource.uid );
    return context;
}

function isPartInBackgroundPartsAssembly(vmo){
    let isPartInBackgroundPart = false;
    if(vmo.props.bl_occ_type && vmo.props.bl_occ_type.dbValues[0] === "Mbc0Background"){
        isPartInBackgroundPart = true;
        
    }else{
        const parent = vmo.props.bl_parent && vmo.props.bl_parent.dbValues[0] && cdm.getObject( vmo.props.bl_parent.dbValues[0] );
        //check if its parent is part then check if its occ type is Mbc0Background
        if(parent && mfeTypeUtils.isOfTypes( parent, [ epBvrConstants.MFG_BVR_PART] )){            
            return isPartInBackgroundPartsAssembly(parent);
        }
    }
    return isPartInBackgroundPart;    
}

/**
 *
 * @param {Object} vmo vmo
 * @returns{String} sourceStructureType
 */
function getSourceStructureType( vmo ) {
    let sourceStructureType = 'Mbom';
    const sublocation = appCtxService.getCtx( CTX_PATH_SUBLOCATION_NAMETOKEN );

    if( sublocation && sublocation === BACKGROUND_PARTS ) {
        sourceStructureType = 'BackgroundPartsPage';
    } else if( mfeTypeUtils.isOfTypes( vmo, [ epBvrConstants.MFG_BVR_PROCESS,
        epBvrConstants.MFG_BVR_OPERATION, epBvrConstants.MFG_BVR_PART,
        epBvrConstants.MFG_BVR_EQUIPMENT, epBvrConstants.MFG_BVR_PART,
        epBvrConstants.MFG_BVR_BOP_WORKAREA, epBvrConstants.MFG_PROCESS_AREA,
        epBvrConstants.MFG_PRODUCT_BOP
    ] ) ) {
        sourceStructureType = 'Process';
    }  else if( mfeTypeUtils.isOfTypes( vmo, [ epBvrConstants.MFG_BVR_WORKAREA, 'Mfg0BvrPlantResource' ] ) ) {
        sourceStructureType = 'Plant';
    }
    return sourceStructureType;
}

/**
 *
 * @params {Object} associatedAssembly
 */
function getAssociatedAssembly( associatedAssembly ) {
    const sublocation = appCtxService.getCtx( CTX_PATH_SUBLOCATION_NAMETOKEN );
    if( sublocation && sublocation === BACKGROUND_PARTS ) {
        associatedAssembly.push( appCtxService.getCtx( CTX_PATH_EBOMSTRUCTURE ) );
    }
    associatedAssembly.push( appCtxService.getCtx( CTX_LOADED_PRODUCT_OBJECT ) );
}

/**
 *
 * @param {string} type type
 * @param {Object} associatedAssembly assembly
 * @returns {string} transformation context
 */
function getTransformationContext( type, associatedAssembly ) {
    return type === PMI ? associatedAssembly ? associatedAssembly.uid : '' : ORIGINAL;
}

/**
 *
 * @param {Object} associatedAssembly assembly
 * @returns {objects} bl_child_lines
 */

function getChildLines( associatedAssembly ) {
    let assembleData;
    return dataMgmtService.getProperties( [ associatedAssembly.uid ], [ 'bl_child_lines' ] ).then( function() {
        assembleData = cdm.getObject( associatedAssembly.uid );
        let ctxLoadedProductObj = appCtxService.getCtx( CTX_LOADED_PRODUCT_OBJECT );
        if( !ctxLoadedProductObj ) {
            appCtxService.updatePartialCtx( CTX_LOADED_PRODUCT_OBJECT, assembleData );
        }
    } );
}

/**
 *
 * @param {ModelObject} modelObjects - a set of modelObjects
 * @param {string} viewerInstanceId - the viewer instance id
 * @param {String} visibilityStateToggle - SHOW/HIDE visibility toggle
 */
function loadEpGraphicsData( modelObjects, viewerInstanceId, visibilityStateToggle ) {
    //Load structure and update cacheData
    //for now let's assume that we receive an array of BOMs or array of BOPs (not mized)
    const loadType = mfeVisConstants.BOP_LOAD_TYPE;
    const uids = modelObjects.map( ( obj ) => obj.uid );
    loadType && loadVisStructure( uids, loadType ).then( function( response ) {
        let associatedAssembly = appCtxService.getCtx( 'ep' ).loadedProductObject;
        if( associatedAssembly && !associatedAssembly.props.hasOwnProperty( 'bl_child_lines' ) ) {
            getChildLines( associatedAssembly );
        }
        if( response.data && response.data.relatedObjectsMap ) {
            const propertyPolicy = policySvc.register( epPmiGraphicsSvc.getPMIPolicy() );
            dataManagementService.loadObjects( Object.keys( response.data.ServiceData.modelObjects ) ).then( function() {
                if( propertyPolicy ) {
                    policySvc.unregister( propertyPolicy );
                }
                epSingleViewerCacheService.updateEpSingleViewerDataFromLoadedResponse( response.data.relatedObjectsMap, response.data.ServiceData.modelObjects );
                viewerInstanceId && epGraphicsService.setVisibilityByTransactionId( viewerInstanceId, response.data.transactionId, ( result ) => {
                    if( Array.isArray( result.errors ) && result.errors.length === 0 ) {
                        let uid = [];
                        Object.keys( response.data.relatedObjectsMap ).map( ( val ) => {
                            if( response.data.relatedObjectsMap[ val ].additionalPropertiesMap2.hasOwnProperty( 'isBackgroundPart' ) && response.data.relatedObjectsMap[ val ]
                                .additionalPropertiesMap2.isBackgroundPart.includes( 'true' ) ) {
                                uid.push( val );
                            }
                        } );
                        if( uid.length > 0 ) {
                            const vmoIds = getVisbileNodesFromViewer( uid, viewerInstanceId );
                            setTransparencyForObjects( viewerInstanceId, vmoIds );
                        }
                        let modelObjUids = [];

                        modelObjects.forEach( ( modelObj ) => {
                            modelObjUids.push( modelObj.uid );
                            epGraphicsVisibilityService.calculateEpGraphicsVisibilityStateForAffectedObjects( modelObj, visibilityStateToggle );
                        } );
                        epGraphicsAssignmentIndicationService.handleAccountibilityUpdateEvents( viewerInstanceId, modelObjUids, result.modelIdToVisibilityMap );
                        epGraphicsVisibilityService.updateVisibilityStatesInCacheAndPublishUpdateEvent();
                    }
                } );
            } );
        }
    } );
}

/**
 *
 * @param {String[]} objectUidsToToggleVisiblity affected object Uids
 *  @param {boolean} visibility true/false
 * @returns {String[]} affected object uids to toggle visiblity input for setVisibilityOfNodes
 */
function getNodesToUpdateDisplayState( objectUidsToToggleVisiblity, visibility ) {
    return objectUidsToToggleVisiblity.map( objectUid => {
        let node = {
            modelId: objectUid,
            visibility: visibility,
            type: 'PART'
        };
        if( epSingleViewerCacheService.getObjectType( objectUid ) === epBvrConstants.MCI_PMI_META_DATA ) {
            node.type = 'PMI';
        }
        return node;
    } );
}

/**
 * Clear cacheData on unloading page
 */
function clearEpGraphicsStructure() {
    epGraphicsVisibilityService.clearEpGraphicsStructure();
}

/**
 *
 * @param {string} viewerInstanceId - the viewer instance id
 */
function unloadGraphics( viewerInstanceId ) {
    epGraphicsVisibilityService.unloadGraphics( viewerInstanceId );
    const sublocation = appCtxService.getCtx( CTX_PATH_SUBLOCATION_NAMETOKEN );
    if( sublocation && sublocation === BACKGROUND_PARTS ) {
        appCtxService.updatePartialCtx( SHOW_MBOM_IN_VIEWER, true );
    }
}

/**
 *
 * @param {String} objectUid object uid
 * @returns {Boolean} true if object is loaded in the viewer
 */
function isPmiCurrentlyVisibleInViewer( objectUid ) {
    return epSingleViewerCacheService.getVisibilityStateForUid( objectUid ) === mfeVisConstants.VISIBILITY_STATUS.ALL;
}
/**
 *
 * @param {string} pmiUid - the pmi uid of the given vmo
 * @param {ViewModelObject} vmo - a given viewModelObject
 * @param {string} viewerInstanceId - the viewer instance id
 * @param {enum/boolean} show - wether to show or not
 */
function togglePmiVisibilityState( pmiUidvmoMap, viewerInstanceId, show ) {
    let pmisTobeLoaded = [];
    pmiUidvmoMap.forEach( ( vmo, pmiUid ) => {
        const pmiData = epSingleViewerCacheService.getCachedDataForObject( pmiUid );
        if( !pmiData || !pmiData.alreadyLoadedStructure ) {
            pmisTobeLoaded.push( pmiUid );
        } else {
            const nodesDisplayState = [ {
                modelId: pmiUid,
                visibility: show === TOGGLE_ACTION.SHOW,
                type: 'PMI'
            } ];
            epGraphicsService.setVisibilityOfNodes( viewerInstanceId, nodesDisplayState, epGraphicsVisibilityService.updatePmiVisibilityState.bind( this, viewerInstanceId, pmiUid, vmo ) );
            toggleConnectedPartsOfDatumPmi( vmo, viewerInstanceId, show );
            epGraphicsVisibilityService.calcualtePMIParentHierarchy( vmo, show );
        }
    } );
    if( pmisTobeLoaded.length > 0 ) {
        loadVisStructure( pmisTobeLoaded, 'PMI' ).then(
            ( response ) => {
                epGraphicsService.setVisibilityByTransactionId( viewerInstanceId, response.data.transactionId, toggleDatumWithPmiVisibilityState.bind( this, pmiUidvmoMap, viewerInstanceId,
                    show ) );
                pmiUidvmoMap.forEach( ( vmo, pmiUid ) => {
                    epSingleViewerCacheService.updateEpSingleViewerDataFromLoadedResponse( response.data.relatedObjectsMap, response.data.ServiceData.modelObjects );
                    epSingleViewerCacheService.setAlreadyLoadedStructureForObject( pmiUid, true );

                    epGraphicsVisibilityService.calcualtePMIParentHierarchy( vmo, show );
                } );
            }
        );
    }
    //show or hide connected parts of datum
}

/**
 *
 * @param {string} pmiUid  - the pmi uid of the given vmo
 * @param {ViewModelObject} vmo - a given viewModelObject
 * @param {string} viewerInstanceId - the viewer instance id
 * @param {enum/boolean} show - wether to show or not
 * @param {object} pmiIdToVisibilityMap - the updated visibility map object
 */
function toggleDatumWithPmiVisibilityState( pmiUidvmoMap, viewerInstanceId, show, pmiIdToVisibilityMap ) {
    pmiUidvmoMap.forEach( ( vmo, pmiUid ) => {
        epGraphicsVisibilityService.updatePmiVisibilityState( viewerInstanceId, pmiUid, vmo, pmiIdToVisibilityMap );
        toggleConnectedPartsOfDatumPmi( vmo, viewerInstanceId, show );
    } );
}

/**
 *
 * @param {ViewModelObject} vmo - a given view model object
 * @param {string} viewerInstanceId - the viewer instance id
 * @param {string} show - visibility toggle state to set
 */
function toggleConnectedPartsOfDatumPmi( vmo, viewerInstanceId, show ) {
    const partUids = epPmiGraphicsSvc.getConnectedPartsOfDatum( vmo );
    if( Array.isArray( partUids ) ) {
        const partObjects = partUids.map( ( uid ) => cdm.getObject( uid ) ).filter( ( obj ) => Boolean( obj ) );
        if( partObjects.length > 0 ) {
            toggleBomOrBopLineVisibilityState( partObjects, viewerInstanceId, show );
        }
    }
}

/**
 *
 * @param {string[]} saveResults - save results
 *  @return {string} part to source Map
 */
function getPartToSourceMap( saveResults ) {
    let partToSourceMap = new Map();
    if( saveResults && Array.isArray( saveResults ) ) {
        saveResults.forEach( el => {
            //When part is assigned from MBOM to operation source line is a BOM line
            //when part is moved from one operation to other operation, source line is previous occurence(Mfg0BvrPart)
            if( el.clientID.includes( 'BOMLine' ) || el.saveResultObject.uid.includes( epBvrConstants.MFG_BVR_EQUIPMENT ) ||
                el.saveResultObject.uid.includes( epBvrConstants.MFG_BVR_PART ) || el.saveResultObject.uid.includes( epBvrConstants.MFG_BVR_BOP_WORKAREA ) ) {
                partToSourceMap.set( el.saveResultObject.uid, el.clientID );
            }
        } );
    }
    return partToSourceMap;
}

/**
 *
 * @param {string} removedNodes
 */
function removeNodesFromViewer( removedModelObjects, viewerInstanceId ) {
    let affectedObjectsUids = [];
    removedModelObjects.forEach( ( removedModelObject ) => {
        if( !epSingleViewerCacheService.getComposites( removedModelObject.uid ) ) {
            const {
                affectedChildObjects,
                affectedCompositesObjects
            } = epGraphicsVisibilityService.calculateEpGraphicsVisibilityStateForAffectedObjects( removedModelObject, TOGGLE_ACTION.HIDE );
            affectedObjectsUids.push( removedModelObject.uid, ...affectedChildObjects, ...affectedCompositesObjects );
        }
    } );
    affectedObjectsUids = affectedObjectsUids.filter( objectUid => epSingleViewerCacheService.getJTFileTicket( objectUid ) );
    affectedObjectsUids.length > 0 && epGraphicsService.unloadNodes( viewerInstanceId, affectedObjectsUids );
}

function getRelatedPMIsForRemovedObject( removedObjectUids ) {
    removedObjectUids.forEach( ( removedObjectUid ) => {
        const removedObject = cdm.getObject( removedObjectUid );
        if( removedObject.type === 'Mci0InspectionRevision' ) {
            const pmiUid = epPmiGraphicsSvc.getAssemblyPmiUidFromRelatedObject( removedObject );
            removedObjectUids.splice( removedObjectUids.indexOf( removedObjectUid ), 1 );
            epSingleViewerCacheService.setInspectionObjUid( pmiUid, removedObjectUid );
            removedObjectUids.push( pmiUid );
        }
    } );
    return removedObjectUids;
}

function handleRemovedPMIs( parentUid, removedObjectUids ) {
    const removedPMIs = getRelatedPMIsForRemovedObject( removedObjectUids );
    epSingleViewerCacheService.removePMIFromParentCache( parentUid, removedPMIs );
    let affectedParentObjectUids = [];
    if( epSingleViewerCacheService.getCachedDataForObject( removedPMIs[ 0 ] ) ) {
        epGraphicsVisibilityService.calculateVisibilityStateForParentHierarchy( removedObjectUids[ 0 ], TOGGLE_ACTION.HIDE, affectedParentObjectUids );
    } else if( epSingleViewerCacheService.getCachedDataForObject( parentUid ) ) {
        epGraphicsVisibilityService.calculateVisibilityStateForObject( parentUid, TOGGLE_ACTION.HIDE, false );
        affectedParentObjectUids.push( parentUid );
        epGraphicsVisibilityService.calculateVisibilityStateForParentHierarchy( parentUid, TOGGLE_ACTION.HIDE, affectedParentObjectUids );
    }
    epGraphicsVisibilityService.updateVisibilityStatesInCache();
    epGraphicsVisibilityService.clearUpdatedModelObjectsUidsToVisibilityStateMap();
}

/**
 *
 * @param {string[]} mciInspectionList - inspection rev objects
 *  @return {string} inspection to assembly PMI Map
 */
function getMciInspectionToAssemblyPMIMap( mciInspectionList ) {
    let mciInspectionToAssemblyPMIMap = new Map();
    mciInspectionList.forEach( pmi => {
        const vmo = cdm.getObject( pmi );
        mciInspectionToAssemblyPMIMap.set( pmi, epPmiGraphicsSvc.getAssemblyPmiUidFromRelatedObject( vmo ) );
    } );
    return mciInspectionToAssemblyPMIMap;
}

/**
 *
 * @param {string} saveEventData - save event data object

 */
function handleSaveEvents( saveEventData, viewerInstanceId ) {
    const removedObjectUids = [];
    const addedObjectUids = [];
    let parentUid = {};
    let removedObjVisibilityMap = {};

    if( saveEventData.length > 0 ) {
        saveEventData[ 0 ].saveEvents.forEach( event => {
            //step 1: get all removed objects from event data and calculate its visibity state
            if( event.eventType === 'removedFromRelation' && event.eventObjectUid !== '' ) {
                let uid = event.eventObjectUid;
                if( uid && uid !== '' ) {
                    removedObjectUids.push( uid );
                }
            }
            //step 2: get all added objects from event data and calculate its visibity state
            if( event.eventType === 'addedToRelation' ) {
                let uid = event.eventObjectUid;
                addedObjectUids.push( uid );
            }
            //step 3: get Parent object to which object is added/removed from event data
            if( event.eventType === 'modifyRelations' ) {
                // Special case for Background parts handled separately, so that it will not affect WI & AP page.
                // Case -  Assigning visible assembly as beackground part
                const sublocation = appCtxService.getCtx( CTX_PATH_SUBLOCATION_NAMETOKEN );
                if( sublocation && sublocation === BACKGROUND_PARTS ) {
                    parentUid = event.eventObjectUid;
                } else if( event.eventData.length > 0 && event.eventData[ 0 ] !== epBvrConstants.MBC_ASSIGNED_DOCUMENTS ) {
                    parentUid = event.eventObjectUid;
                }
            }
        } );

        if( parentUid &&
            Object.keys( parentUid ).length === 0 &&
            Object.getPrototypeOf( parentUid ) === Object.prototype ) {
            return;
        }
        const partsToSourceMap = getPartToSourceMap( saveEventData[ 0 ].saveResults );
        //update the parent object  in cache
        if( removedObjectUids.length > 0 ) {
            removedObjVisibilityMap = handleRemovedEvent( removedObjectUids, parentUid, viewerInstanceId, partsToSourceMap );
        }

        if( addedObjectUids.length > 0 ) {
            const sourceBOMLines = [];
            addedObjectUids.forEach( ( addedObjectUid ) => { sourceBOMLines.push( partsToSourceMap.get( addedObjectUid ) ); } );

            updateChildrenToParentCache( parentUid, mfeVisConstants.BOP_LOAD_TYPE, getPartToSourceMap( saveEventData[ 0 ].saveResults ) );
            let addedPMIs = addedObjectUids;
            addedPMIs = addedPMIs.filter( objectUid => {
                return mfeTypeUtils.isOfTypes( cdm.getObject( objectUid ), [ epGraphicsConstants.MCI_PMI_CHARACTERISTIC, epGraphicsConstants.MCI_INSPECTION_REVISION ] );
            } );
            if( addedPMIs && addedPMIs.length > 0 ) {
                updateChildrenToParentCache( parentUid, mfeVisConstants.PMI_LOAD_TYPE, getMciInspectionToAssemblyPMIMap( addedPMIs ) );
            }
            epGraphicsAssignmentIndicationService.handleAccountibilityUpdateEvents( viewerInstanceId, sourceBOMLines );
        }

        epGraphicsVisibilityService.updateVisibilityStatesInCache();
        //delete entries of removed objects from cache
        epSingleViewerCacheService.removeObjectsFromCache( removedObjectUids );

        epGraphicsVisibilityService.clearUpdatedModelObjectsUidsToVisibilityStateMap();
    }
}

/**
 *
 * @param {Array} removedObjectUids uids
 * @param {String} parentUid parent uid
 * @param {String} viewerInstanceId instance ID
 * @param {String} partsToSourceMap partsToSourceMap
 * @returns {Object} removedObjVisibilityMap

 */
function handleRemovedEvent( removedObjectUids, parentUid, viewerInstanceId, partsToSourceMap ) {
    let removedObjVisibilityMap = {};
    let removedPMIs = removedObjectUids;
    removedPMIs = removedPMIs.filter( objectUid => {
        return mfeTypeUtils.isOfTypes( cdm.getObject( objectUid ), [ epGraphicsConstants.MCI_PMI_CHARACTERISTIC, epGraphicsConstants.MCI_INSPECTION_REVISION ] );
    } );
    //check if removed object was PMI
    if( removedPMIs && removedPMIs.length > 0 ) {
        handleRemovedPMIs( parentUid, removedObjectUids );
        return;
    }
    let removedParts = removedObjectUids.filter( objectUid => {
        return mfeTypeUtils.isOfTypes( cdm.getObject( objectUid ), [ epBvrConstants.MFG_BVR_PART, epBvrConstants.MFG_BVR_EQUIPMENT, epBvrConstants.MFG_BVR_BOP_WORKAREA ] );
    } );
    //get visibility of parts and then store it
    removedParts.forEach( ( part ) => {
        removedObjVisibilityMap[ part ] = epSingleViewerCacheService.getVisibilityStateForUid( part ) === mfeVisConstants.VISIBILITY_STATUS.NONE ?
            TOGGLE_ACTION.HIDE :
            TOGGLE_ACTION.SHOW;
    } );
    //required in move parts scenario
    removeNodesFromViewer( cdm.getObjects( removedObjectUids ), viewerInstanceId );
    epSingleViewerCacheService.removeChildrenFromParentCache( parentUid, removedObjectUids );
    let affectedParentObjectUids = [];
    let sourceBOMLinesOfRemovedUids = [];
    partsToSourceMap.size > 0 && removedObjectUids.forEach( ( removedObjectUid ) => { sourceBOMLinesOfRemovedUids.push( partsToSourceMap.get( removedObjectUid ) ); } );
    if( epSingleViewerCacheService.getCachedDataForObject( removedObjectUids[ 0 ] ) ) {
        epGraphicsVisibilityService.calculateVisibilityStateForParentHierarchy( removedObjectUids[ 0 ], TOGGLE_ACTION.HIDE, affectedParentObjectUids );
    } else if( epSingleViewerCacheService.getCachedDataForObject( parentUid ) ) {
        epGraphicsVisibilityService.calculateVisibilityStateForObject( parentUid, TOGGLE_ACTION.HIDE, false );
        affectedParentObjectUids.push( parentUid );
        epGraphicsVisibilityService.calculateVisibilityStateForParentHierarchy( parentUid, TOGGLE_ACTION.HIDE, affectedParentObjectUids );
    }
    epGraphicsAssignmentIndicationService.handleAccountibilityUpdateEvents( viewerInstanceId, sourceBOMLinesOfRemovedUids );
    return removedObjVisibilityMap;
}

/**
 *
 * @param {string} viewerInstanceId - the viewer instance id
 */
function hideAllPMIs( viewerInstanceId ) {
    epGraphicsVisibilityService.hideAllPMIs( viewerInstanceId );
}

/**
 *
 * @param {string} parentUid - parent uid
 * @param {string} loadType - load type
 * @param {string} objectToSourceMap - object to source map
 *
 */
function updateChildrenToParentCache( parentUid, loadType, objectToSourceMap ) {
    const objectsToLoad = [];
    objectToSourceMap.forEach( ( sourceObject, assignedObject ) => {
        // When source bomline is not in cache, and parent is in the cache
        //Don't load the graphics data, just increased the number of children and update visibility of parent object
        const parentData = epSingleViewerCacheService.getCachedDataForObject( parentUid );
        const sourceBomlineData = epSingleViewerCacheService.getCachedDataForObject( sourceObject );
        if( parentData && !sourceBomlineData ) {
            epSingleViewerCacheService.updateNumberOfChildrenForObject( parentUid );
            const parentVmo = cdm.getObject( parentUid );
            epGraphicsVisibilityService.calculateEpGraphicsVisibilityStateForAffectedObjects( parentVmo,
                epSingleViewerCacheService.getVisibilityStateForUid( parentUid ) === mfeVisConstants.VISIBILITY_STATUS.NONE ?
                    TOGGLE_ACTION.HIDE : TOGGLE_ACTION.SHOW );
            epGraphicsVisibilityService.updateVisibilityStatesInCache();
        } else if( sourceBomlineData || parentData ) {
            //call ep load structure

            // Special case for Background parts handled separately, so that it will not affect WI & AP page.
            // Case -  Assigning visible assembly as beackground part
            const sublocation = appCtxService.getCtx( CTX_PATH_SUBLOCATION_NAMETOKEN );
            if( sublocation && sublocation === BACKGROUND_PARTS || loadType === mfeVisConstants.PMI_LOAD_TYPE ) {
                objectsToLoad.push( sourceObject );
            } else {
                objectsToLoad.push( assignedObject );
            }
        }
    } );

    if( objectsToLoad.length > 0 && loadType ) {
        loadObjectAndUpdateVisibilityStateForSorceObject( objectsToLoad, loadType, objectToSourceMap );
    }
}

/**
 *
 * @param {Array} objectsToLoad objects to load
 * @param {String} loadType - load type
 * @param {Object} objectToSourceMap - object to source map
 */
function loadObjectAndUpdateVisibilityStateForSorceObject( objectsToLoad, loadType, objectToSourceMap ) {
    loadVisStructure( objectsToLoad, loadType ).then( function( response ) {
        if( response.data && response.data.relatedObjectsMap ) {
            epSingleViewerCacheService.updateEpSingleViewerDataFromLoadedResponse( response.data.relatedObjectsMap, response.data.ServiceData.modelObjects );
            objectToSourceMap.forEach( ( sourceObject, assignedObject ) => {
                if( objectsToLoad.indexOf( assignedObject ) !== -1 || objectsToLoad.indexOf( sourceObject ) !== -1 ) {
                    let visibilityState = epSingleViewerCacheService.getVisibilityStateForUid( sourceObject );
                    if( loadType === mfeVisConstants.PMI_LOAD_TYPE ) {
                        epPmiGraphicsSvc.getPMIParentHierarchy( cdm.getObject( sourceObject ), visibilityState === mfeVisConstants.VISIBILITY_STATUS.NONE ? TOGGLE_ACTION.HIDE : TOGGLE_ACTION
                            .SHOW );
                    } else if( visibilityState === mfeVisConstants.VISIBILITY_STATUS.SOME ) {
                        epGraphicsVisibilityService.setVisibilityStateForANode( assignedObject, mfeVisConstants.VISIBILITY_STATUS.SOME );
                        epSingleViewerCacheService.getParent( assignedObject ) &&
                            epGraphicsVisibilityService.calculateVisibilityStateForParentHierarchy(
                                assignedObject, epSingleViewerCacheService.getVisibilityStateForUid( assignedObject ) ===
                                mfeVisConstants.VISIBILITY_STATUS.NONE ? TOGGLE_ACTION.HIDE : TOGGLE_ACTION.SHOW, [] );
                    } else {
                        epGraphicsVisibilityService.calculateEpGraphicsVisibilityStateForAffectedObjects( cdm.getObject( sourceObject ),
                            visibilityState === mfeVisConstants.VISIBILITY_STATUS.NONE ? TOGGLE_ACTION.HIDE : TOGGLE_ACTION.SHOW );
                    }
                }
                epGraphicsVisibilityService.updateVisibilityStatesInCacheAndPublishUpdateEvent();
            } );
        }
    } );
}

/**
 *
 * @param {ModelObject} modelObjects - a set of modelObjects
 * @param {string} viewerInstanceId - the viewer instance id
 * @param {String} visibilityStateToggle - SHOW/HIDE visibility toggle
 */
function loadEpGraphicsData2( modelObjects, viewerInstanceId, visibilityStateToggle ) {
    //Load structure and update cacheData
    //for now let's assume that we receive an array of BOMs or array of BOPs (not mized)
    const loadType = mfeVisConstants.BOP_LOAD_TYPE;
    const uids = modelObjects.map( ( obj ) => obj.uid );
    loadType && loadMbomStructure( uids, loadType ).then( function( response ) {
        if( response.data && response.data.relatedObjectsMap ) {
            const propertyPolicy = policySvc.register( epPmiGraphicsSvc.getPMIPolicy() );
            dataManagementService.loadObjects( Object.keys( response.data.ServiceData.modelObjects ) ).then( function() {
                if( propertyPolicy ) {
                    policySvc.unregister( propertyPolicy );
                }
                epSingleViewerCacheService.updateEpSingleViewerDataFromLoadedResponse( response.data.relatedObjectsMap, response.data.ServiceData.modelObjects );
                viewerInstanceId && epGraphicsService.setVisibilityByTransactionId( viewerInstanceId, response.data.transactionId, ( result ) => {
                    if( Array.isArray( result.errors ) && result.errors.length === 0 ) {
                        let ebomObjectUids = [];
                        let mbom = appCtxService.getCtx( CTX_LOADED_PRODUCT_OBJECT );
                        epSingleViewerCacheService.getChildCompositesHierarchy( mbom.uid, ebomObjectUids );
                        if( ebomObjectUids.length > 0 ) {
                            let vmosToSelect2 = getVisbileNodesFromViewer( ebomObjectUids, viewerInstanceId );
                            setTransparencyForObjects( viewerInstanceId, vmosToSelect2 );
                        }
                        let modelObjUids = [];

                        modelObjects.forEach( ( modelObj ) => {
                            modelObjUids.push( modelObj.uid );
                            epGraphicsVisibilityService.calculateEpGraphicsVisibilityStateForAffectedObjects( modelObj, visibilityStateToggle );
                        } );
                        epGraphicsAssignmentIndicationService.handleAccountibilityUpdateEvents( viewerInstanceId, modelObjUids, result.modelIdToVisibilityMap );
                        epGraphicsVisibilityService.updateVisibilityStatesInCacheAndPublishUpdateEvent();
                    }
                } );
            } );
        }
    } );
}

/**
 *
 * @param {modelObject} modelObjects - a given vmo
 * @param {string} viewerInstanceId - the viewer instance id
 * @param {string} visibilityStateToggle -
 */
function toggleMBomOrEBomLineVisibilityState( modelObjects, viewerInstanceId, visibilityStateToggle ) {
    let mbom = appCtxService.getCtx( CTX_LOADED_PRODUCT_OBJECT );
    if( visibilityStateToggle === TOGGLE_ACTION.SHOW ) {
        const objectToLoad = [];
        const alreadyLoaded = [];
        modelObjects.forEach( ( obj ) => {
            if( epGraphicsVisibilityService.shouldLoadGraphicsData( obj.uid ) ) {
                objectToLoad.push( obj );
            } else {
                let composites = epSingleViewerCacheService.getComposites( obj.uid );
                if( Array.isArray( composites ) && composites.length === 1 ) {
                    alreadyLoaded.push( cdm.getObject( composites[ 0 ] ) );
                } else{
                    alreadyLoaded.push( obj );
                }
            }
        } );
        if( objectToLoad.length > 0 ) {
            loadEpGraphicsData2( objectToLoad, viewerInstanceId, visibilityStateToggle );
        }

        if( alreadyLoaded.length > 0 ) {
            epGraphicsVisibilityService.toggleEpGraphicsVisiblity( alreadyLoaded, viewerInstanceId, visibilityStateToggle, true );
            let ebomObjectUids = [];
            epSingleViewerCacheService.getChildCompositesHierarchy( mbom.uid, ebomObjectUids );
        }
    } else {
        let ebomObjectUids = [];
        epSingleViewerCacheService.getChildCompositesHierarchy( mbom.uid, ebomObjectUids );
        let vmosToSelect2 = getVisbileNodesFromViewer( ebomObjectUids, viewerInstanceId );
        vmosToSelect2.length > 0 && epGraphicsService.setTransparencyById( viewerInstanceId, vmosToSelect2, 1 );
        epGraphicsVisibilityService.toggleEpGraphicsVisiblity( modelObjects, viewerInstanceId, visibilityStateToggle );
    }
}

/**
 * toggle between show and hide mbom
 * @param { Object } visContext - vis viewer context.
 * @param { Object } visibilityStateToggle -show/hide for displaying mbom in vis viewer.
 * @returns {Object} promise
 */
function toggleShowHideMbomAction( visContext, visibilityStateToggle ) {
    let showMbom = appCtxService.getCtx( SHOW_MBOM_IN_VIEWER );
    appCtxService.updatePartialCtx( SHOW_MBOM_IN_VIEWER, !showMbom );

    let mbom = appCtxService.getCtx( CTX_LOADED_PRODUCT_OBJECT );

    return toggleMBomOrEBomLineVisibilityState( [ mbom ], visContext, visibilityStateToggle );
}

/**
 * @param {string[]} uids - a given array of uids
 * @param {string} type - the type to load
 * @return {Promise} a promise object
 */
function loadMbomStructure( uids, type ) {
    let mbom = appCtxService.getCtx( CTX_LOADED_PRODUCT_OBJECT );
    let ebom = appCtxService.getCtx( CTX_PATH_EBOMSTRUCTURE );
    const loadedProcess = appCtxService.getCtx( CTX_LOADED_OBJECT );

    const input = {
        objectsToLoad: uids,
        exclusions: [],
        type,
        contextData: {
            context: [ ebom.uid, mbom.uid, loadedProcess.uid ],
            transformationContext: 'EBOM',
            sourceStructureType: 'Mbom'
        }
    };
    return epGraphicsServiceProxy.loadStructure( input );
}

/*
 * @param {String} viewerInstanceId viewer Instance id
 * @param {*} nodesOutOfFocus objects to be transparent in viewer
 */

/**
 *
 * @param {String} viewerInstanceId vis instance id
 * @param {Array} nodesOutOfFocus nodes to focus
 */
function setTransparencyForObjects( viewerInstanceId, nodesOutOfFocus ) {
    nodesOutOfFocus.length > 0 && epGraphicsService.setTransparencyById( viewerInstanceId, nodesOutOfFocus, 0.3 );
}

/**
 * get all nodes visible in viewer
 * @param {Array} uids EBOM Line uids
 * @param {String} viewerInstanceId viewer instance id
 * @return {Array} vmosToSelect
 */
function getVisbileNodesFromViewer( uids, viewerInstanceId ) {
    const idToVisibilityMap = epGraphicsService.getAllTheVisibleNodesInViewer( viewerInstanceId );
    const vmosToSelect = [];
    for( let [ uid, visibility ] of idToVisibilityMap.entries() ) {
        if( visibility === true ) {
            uids.indexOf( uid ) > -1 && vmosToSelect.push( uid );
        }
    }
    return vmosToSelect;
}

export default {
    setEpGraphicsVisibilityStateForAffectedNodes,
    clearEpGraphicsStructure,
    unloadGraphics,
    handleSaveEvents,
    hideAllPMIs,
    isPmiCurrentlyVisibleInViewer,
    loadAllStructuresAndSetEpGraphicsVisibilityStateForAffectedNodes,
    toggleShowHideMbomAction
};
