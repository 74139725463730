/* eslint-disable max-lines */
//@<COPYRIGHT>@
//==================================================
//Copyright 2021.
//==================================================
//@<COPYRIGHT>@
/*global
 define
 */

/**
 * @module js/VimContainerService
 */

import AwPromiseService from "js/awPromiseService";
import cdmSvc from "soa/kernel/clientDataModel";
import appCtxSvc from "js/appCtxService";
import _ from "lodash";
import viewModelSvc from "js/viewModelService";
import soaSvc from "soa/kernel/soaService";
import $ from "jquery";
import VimVaasConService from "js/VimVaasConService";
import msgSvc from "js/messagingService";
import eventBus from "js/eventBus";

let exports = {};

/**
 *
 * @param {Object} data this function prepare the data for the Issue table
 */
export let getContainerIssues = function (resp) {
  let vmoList = [];
  let structureIssueAnnotationData = [];
  _.forEach(resp.ServiceData.modelObjects, function (element) {
    if (element.type === "V4B_FTMTKB_IssueRevision") {
      vmoList.push(element);
    }
  });

  //default: sort ascending by running number
  let sortCriteria = [
    {
      fieldName: "v4b_running_number",
      sortDirection: "ASC",
    },
  ];
  vmoList = applySort(sortCriteria, vmoList);
  return vmoList;
};
export let getStructureAnnotationData = function (resp) {
  let structureIssueAnnotationData = [];
  _.forEach(resp.ServiceData.modelObjects, function (element) {
    if (element.type === "V4B_VaasAnnotationData") {
      structureIssueAnnotationData.push(element);
    }
  });
  return structureIssueAnnotationData;
};
export let getfilteredIssueList = function (IssueList, columnFilters, sortCriteria) {
  let filteredIssueList =  _.clone(IssueList);

  if (columnFilters && columnFilters.length > 0) {
    // Apply filtering
    _.forEach(columnFilters, function (columnFilter) {
      filteredIssueList = applyFilters(columnFilter, filteredIssueList);
    });
  }

  filteredIssueList = applySort(sortCriteria, filteredIssueList);

  return AwPromiseService.instance.resolve(filteredIssueList);
};

let applyFilters = function (columnFilter, inputIssureRevs) {
  let filterValue = columnFilter.values[0];
  let propName = columnFilter.columnName;
  let propStr = "props." + propName + ".uiValues[0]";
  switch (columnFilter.operation) {
    case "contains":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        return propVal.toLowerCase().includes(filterValue.toLowerCase());
      });
      break;
    case "equals":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        return columnFilter.values.includes(propVal);
      });
      break;
    case "notEquals":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        return !columnFilter.values.includes(propVal);
      });
      break;
    case "lt":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        let filterdVals = columnFilter.values.filter(
          (filterValue) => Number(propVal) < Number(filterValue)
        );
        return filterdVals.length > 0;
      });
      break;
    case "gt":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        let filterdVals = columnFilter.values.filter(
          (filterValue) => Number(propVal) > Number(filterValue)
        );
        return filterdVals.length > 0;
      });
      break;
    case "range":
      inputIssureRevs = inputIssureRevs.filter(function (issueRev) {
        let propVal = _.get(issueRev, propStr, "");
        let fromValue = columnFilter.values[0];
        let toValue = columnFilter.values[1];
        let filterdVals = columnFilter.values.filter(function () {
          return (
            Number(propVal) >= Number(fromValue) &&
            Number(propVal) <= Number(toValue)
          );
        });
        return filterdVals.length > 0;
      });
      break;
  }
  return inputIssureRevs;
};

let applySort = function (sortCriteria, issues) {
  if (sortCriteria && sortCriteria.length > 0 && issues.length > 0) {
    let criteria = sortCriteria[0];
    let sortDirection = criteria.sortDirection;
    let sortColName = criteria.fieldName;
    let propType = issues[0].props[sortColName].propertyDescriptor.valueType;

    if (sortDirection === "ASC") {
      issues.sort(function (a, b) {
        if (propType == 8) {
          // 8= string prop
          if (
            a.props[sortColName].uiValues[0] <= b.props[sortColName].uiValues[0]
          ) {
            return -1;
          }
        } else if (propType == 5) {
          // 5 = integer prop
          if (
            Number(a.props[sortColName].uiValues[0]) <=
            Number(b.props[sortColName].uiValues[0])
          ) {
            return -1;
          }
        }
        return 1;
      });
    } else if (sortDirection === "DESC") {
      issues.sort(function (a, b) {
        if (propType == 8) {
          // 8= string prop
          if (
            a.props[sortColName].uiValues[0] >= b.props[sortColName].uiValues[0]
          ) {
            return -1;
          }
        } else if (propType == 5) {
          // 5 = integer prop
          if (
            Number(a.props[sortColName].uiValues[0]) >=
            Number(b.props[sortColName].uiValues[0])
          ) {
            return -1;
          }
        }
        return 1;
      });
    }
  }
  return issues;
};

export let updateFilterFacet = function (
  columnFilters,
  filteredIssues,
  filterFacetInput
) {
  let updateFilters = filterFacetInput.columnFilters;
  let columnName = filterFacetInput.column.field;
  let facetsToReturn = [];
  let facetValues = {
    values: [],
    totalFound: 0,
  };

  if (columnFilters) {
    _.forEach(columnFilters, function (columnFilter) {
      if (columnName !== columnFilter.columnName) {
        filteredIssues = applyFilters(columnFilter, filteredIssues);
      }
    });
  }

  if (updateFilters.length > 0) {
    _.forEach(updateFilters, function (columnFilter) {
      filteredIssues = applyFilters(columnFilter, filteredIssues);
    });
  }
  // prepare facets data
  _.forEach(filteredIssues, function (issue) {
    let propStr = "props." + columnName + ".uiValues[0]";
    let propVal = _.get(issue, propStr, "");
    facetsToReturn.push(propVal);
  });

  facetsToReturn = _.uniq(facetsToReturn);
  facetValues.values = facetsToReturn;
  facetValues.totalFound = facetsToReturn.length;
  return facetValues;
};

let getAnchorPosOrLblOff = function (fTMIssueVmos, setValue, data) {
  let IssueTransMatrix_Anchor_LblOff;

  if (_.isNull(fTMIssueVmos.props[setValue].dbValues[0])) {
    console.log("Find Structure annotation data");
    _.forEach(data.FTMStructureAnnotationData, (element) => {
      if (
        element.props.v4b_label.dbValues[0] ===
        fTMIssueVmos.props.awp0Item_item_id.dbValues[0]
      ) {
        if (setValue === "v4b_IssueTransMatrix_Anchor") {
          IssueTransMatrix_Anchor_LblOff =
            element.props.v4b_vec_center_to_issue_pos.dbValues;
        }
        if (setValue === "v4b_IssueTransMatrix_LblOff") {
          console.log("LblOff : " + IssueTransMatrix_Anchor_LblOff);
        }
      }
    });
  } else {
    IssueTransMatrix_Anchor_LblOff = fTMIssueVmos.props[setValue].dbValues[0];
  }
  return IssueTransMatrix_Anchor_LblOff;
};
let isStructureIssue = function (fTMIssueVmos, setValue, data) {
  let ftmIsStructureIssue = false;
  //Incase of FTM Structure and duplicate FTM issue v4b_IssueTransMatrix_Anchor and v4b_IssueTransMatrix_LblOff is empty
  //if we found an Structure AnnotationData then in FTMStructureAnnotationData then we will set ftmIsStructureIssue=true
  //if issue(awp0Item_item_id) not found in FTMStructureAnnotationData(v4b_label) then we will set ftmIsStructureIssue=false meaning  input FTM issue is duplicated FTM issue or its ftm issue created in context of VCON and not a Structure
  if (_.isNull(fTMIssueVmos.props[setValue].dbValues[0])) {
    console.log(
      "check fTMIssueVmos is a StructureIssue or duplicated FTM issue"
    );
    _.forEach(data.FTMStructureAnnotationData, (element) => {
      if (
        element.props.v4b_label.dbValues[0] ===
        fTMIssueVmos.props.awp0Item_item_id.dbValues[0]
      ) {
        console.log(
          element.props.v4b_label.dbValues[0] + " Is a Structure Issue"
        );
        ftmIsStructureIssue = true;
      }
    });
  }
  if (!ftmIsStructureIssue) {
    console.log(
      fTMIssueVmos.props.awp0Item_item_id.dbValues[0] +
        " Is a duplicated FTM Issue"
    );
  }
  return ftmIsStructureIssue;
};
let v4b_isRrelative_Pos_Set = function (isRrelative_Pos_Set_DBvalue) {
  if (isRrelative_Pos_Set_DBvalue == "1") {
    return true;
  } else {
    return false;
  }
};
export let publishModelObjectsUpdated = function (dataProvider, data) {
  let vmos = dataProvider.getViewModelCollection().getLoadedViewModelObjects();
  let vconIssueTable = [];
  _.forEach(vmos, (element) => {
    let vconIssue = {
      uid: element.uid,
      awp0Item_item_id: _.get(
        element,
        "props.awp0Item_item_id.dbValues[0]",
        ""
      ),
      v4b_running_number: _.get(
        element,
        "props.v4b_running_number.dbValues[0]",
        ""
      ),
      v4b_gen_maturity: _.get(
        element,
        "props.v4b_gen_maturity.dbValues[0]",
        ""
      ),
      v4b_IssueTransMatrix_Anchor: getAnchorPosOrLblOff(
        element,
        "v4b_IssueTransMatrix_Anchor",
        data
      ),
      v4b_IssueTransMatrix_LblOff: getAnchorPosOrLblOff(
        element,
        "v4b_IssueTransMatrix_LblOff",
        data
      ),
      v4b_ftm_type: _.get(element, "props.v4b_ftm_type.dbValues[0]", ""),
      v4b_isStructureIssue: isStructureIssue(
        element,
        "v4b_IssueTransMatrix_Anchor",
        data
      ),
      v4b_isRrelative_Pos_Set: v4b_isRrelative_Pos_Set(
        _.get(element, "props.v4b_isRrelative_Pos_Set.dbValues[0]", "")
      ),
    };
    vconIssueTable.push(vconIssue);
  });

  let vimCtx = appCtxSvc.getCtx("vimCtx");
  if (vimCtx) {
    vimCtx.VconIssueTable = vconIssueTable;
    appCtxSvc.updateCtx("vimCtx", vimCtx);
  } else {
    appCtxSvc.registerCtx("vimCtx", {
      VconIssueTable: vconIssueTable,
    });
  }

  VimVaasConService.updateVaasAfterFiltering();
};

export let updateRelationContext = function (dataProvider) {
  let tableSelction = dataProvider.getSelectedObjects();
  if (tableSelction.length > 0) {
    let relInfo = tableSelction.map((eachSel) => {
      if(dataProvider.name.localeCompare("vimIssuesBasedOnPartNoDataProvider") == 0)
      {
        return {
          primaryObject: appCtxSvc.getCtx("xrtSummaryContextObject"),
          secondaryObject: eachSel
        };
      }
      else
      {
        return {
          primaryObject: appCtxSvc.getCtx("xrtSummaryContextObject"),
          secondaryObject: eachSel,
          relationType: "V4B_Container_VIM_FTM_Rel",
        };
      }
      
    });
    appCtxSvc.registerCtx("relationContext", {
      relationInfo: relInfo,
    });
  } else {
    appCtxSvc.unRegisterCtx("relationContext");
  }
};

export let doReload = function (data, ctx) {
  let vmoCtx = viewModelSvc.getViewModelUsingElement($("#VIMContainerIssues"));
  viewModelSvc.executeCommand(
    vmoCtx,
    "loadContainerIssues",
    vmoCtx._internal.origCtxNode
  );
};
export let getAttachedPart = function (response) {
  let vconObject = cdmSvc.getObject(response.plain[0]);
  let attachedPart = cdmSvc.getObject(
    vconObject.props.IMAN_reference.dbValues[0]
  );
  return attachedPart;
};

export let getSelectedPartFromDP = function (data) {
  let selectedPart =
    data.dataProviders.vconPartsDataProvider.selectedObjects[0];
  return selectedPart.uid;
};

/**
 * Retreives the list of parts based on given part name in filter box
 */

export let getFilteredParts = function (data) {
  let filteredPartList = [];
  if (data.filterBox.dbValue == "") {
    data.filteredParts = data.partsList;
  } else {
    data.partsList.forEach(function (element) {
      if (
        element.props.object_name.dbValues[0]
          .toLowerCase()
          .indexOf(data.filterBox.dbValue.toLowerCase()) !== -1
      ) {
        filteredPartList.push(element);
      }
    });
    data.filteredParts = filteredPartList;
  }
};

/**
 * Retreives Pluto application link from preferences
 */
export let getPlutoURL = function (data) {
  let plutoLink = "";
  console.log("Prepare pluto link : ");
  let listofPrefvalue = appCtxSvc.getCtx('preferences["V4B_VIM_Links_List"]');
  for (let ii = 0; ii < listofPrefvalue.length; ii++) {
    let splitofPrefValue = listofPrefvalue[ii].split("$");
    if (splitofPrefValue[0] == "Pluto") {
     
      plutoLink = splitofPrefValue[1] + appCtxSvc.getCtx("xrtSummaryContextObject.props.v4b_TEIL_SNR.dbValues");   
      document.getElementById( 'plutoLink' ).href= plutoLink;
      document.getElementById( 'plutoLink' ).title= plutoLink;
    }
  }
};

/**
 * checkInputForPartSearch function will check string length in searchbox if 3 or less numbers
 * are entered in searchbox it will enable warning note on panel.
 * @param {data} ViewModelData
 */
export let checkInputForPartSearch = function (data) {
  let searchboxInput = data.searchBox.dbValue;
  data.isMinFourCharReq = searchboxInput.length < 4 ? true : false;
  return data.isMinFourCharReq;
};

/**
 * sortPartsDescending function will sort parts in Descending order by t4b_comp_TEIL_SNR
 * @param {data} ViewModelData
 */
export let sortPartsDescending = function (response) {
  let partlist = [];
  let sortedParts = [];
  let filteredArray = [];

  if (response.nFound > 0) {
    partlist = response.objects;
    // Filter the array to remove objects where user don't have read access
    filteredArray = partlist.filter(function (obj) {
      return obj.type !== "unknownType";
    });
    if (filteredArray.length > 0) {
      sortedParts = filteredArray.sort(function (a, b) {
        return b.props.object_name.dbValues[0].localeCompare(
          a.props.object_name.dbValues[0],
          undefined,
          { numeric: true, sensitivity: "base" }
        );
      });
    }
    //console.log(sortedParts);
  }
  return sortedParts;
};

export let setFTMViewerData = function (response) {
  console.log(response);
  if (
    response.output.views[0].viewer != "Awp0ImageViewer" &&
    response.output.hasMoreDatasets == true
  ) {
    eventBus.publish("FtmVcon.next");
  } else {
    console.log("else block");
    // _eventBus.publish('viewer.dataFetched');
    // return response;
  }
  return response.output;
};

/**
 * Change part on VIM Container and Refresh comment section.
 * @param {data} ViewModelData
 */
export let changePartAction = function (data) {
  let deferred = AwPromiseService.instance.defer();
  let inputData = {
    changePartInput: {
      vimContainerUID: appCtxSvc.getCtx("xrtSummaryContextObject.uid"),
      partUID:
        data.dataProviders.filteredDesignItemDataProvider.selectedObjects[0]
          .uid,
    },
  };
  soaSvc
    .postUnchecked(
      "VIMC-2016-03-UpdateIssues",
      "changePartOnVIMContainer",
      inputData
    )
    .then(function (response) {
      if (response) {
        if (response.partialErrors) {
          let errorCode = response.partialErrors[0].errorValues[0].code;
          if (errorCode === 920265) {
            let vimContainerUID =
              response.partialErrors[0].errorValues[0].message;
            if (vimContainerUID) {
              response.vimConUID = vimContainerUID;
            }
          }
          deferred.resolve(response);
        } else {
          let closePanelEventData = {
            source: "toolAndInfoPanel",
          };

          eventBus.publish("cdm.relatedModified", {
            relatedModified: [appCtxSvc.getCtx("xrtSummaryContextObject")],
            refreshLocationFlag: true,
            relations: "",
          });
          eventBus.publish("complete", closePanelEventData);
          eventBus.publish("commentPanel.contentLoaded");
          msgSvc.showInfo(data.i18n.InfoPartChangeOnVIMConSuccessfully);
        }
      }
    });

  return deferred.promise;
};


export let getFTMAndVconCreateRelInputToAttachRelatedFTMIssue = function (data, xrtSummaryContextObject, commandContext) {
  var creRelInput = [{
      "clientId": "VIMContainer",
      "relationType": "V4B_Container_VIM_FTM_Rel",
      "primaryObject": xrtSummaryContextObject,
      "secondaryObject": commandContext.selected[0]
  }]
  //check Design Item revision
  var ftm = commandContext.selected[0];
  var ftmDsUID = _.get(ftm, "props.CMHasProblemItem.dbValues[0]");
  if (!ftmDsUID) {
      var vcon = data.getPropResp.modelObjects[xrtSummaryContextObject.uid];
      var dsUID = _.get(vcon, "props.IMAN_reference.dbValues[0]");
      if (dsUID) {
          var secInput = {
              "clientId": "FTM issue",
              "relationType": "CMHasProblemItem",
              "primaryObject": commandContext.selected[0],
              "secondaryObject": data.getPropResp.modelObjects[dsUID]
          }
          creRelInput.push(secInput);
      }
  }
  return creRelInput;
}

/**
 * 
 * @param {Object} data this function prepare the data for the Issue table list based on Part No on VIM container
 */
let getIssueListBasedOnPartNo = function (resp) {
  let vmoList = [];
  _.forEach(resp.ServiceData.modelObjects, function (element) {
      if (element.type === "V4B_FTMTKB_IssueRevision") {
          vmoList.push(element);
      }
  });
  //default: sort ascending by running number
  var sortCriteria = [{
      "fieldName": "awp0Item_item_id",
      "sortDirection": "ASC"
  }]
  vmoList = applySort(sortCriteria, vmoList);
  return vmoList;
};
export let getFTMIssueList = function (ctx) {
  var ftmIssueList = [];
  var duplicateObjIssueList = [];
  if (ctx.selected.type === "V4B_FTMTKB_IssueRevision") {
    ftmIssueList = ctx.mselected;
  } else {
    ftmIssueList = ctx.relationContext.relationInfo;
  }

  for (var i = 0; i < ftmIssueList.length; i++) {
    var ftmUID = "";
    if (ctx.selected.type === "V4B_FTMTKB_IssueRevision") {
      ftmUID = ftmIssueList[i].uid;
    } else {
      ftmUID = ftmIssueList[i].secondaryObject.uid;
    }
    var duplicateIssueObj =
    {
      objectUID: ftmUID,
      issueObjProperties: [
        {
          propertyName: "object_type",
          propertyValue: ["V4B_FTMTKB_IssueRevision"],
          propertyType: "String"
        }
      ],
      "isCreateIssue": false
    }

    duplicateObjIssueList.push(duplicateIssueObj);
  }


  return duplicateObjIssueList;
};

/**
 * @memberof NgServices
 * @member VimContainerService
 */

exports = {
  getContainerIssues,
  getStructureAnnotationData,
  getfilteredIssueList,
  updateFilterFacet,
  publishModelObjectsUpdated,
  updateRelationContext,
  doReload,
  getAttachedPart,
  getSelectedPartFromDP,
  getFilteredParts,
  getPlutoURL,
  checkInputForPartSearch,
  sortPartsDescending,
  changePartAction,
  getFTMIssueList,
  getFTMAndVconCreateRelInputToAttachRelatedFTMIssue,
  getIssueListBasedOnPartNo
};

export default exports;
