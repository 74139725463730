// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSublocation from 'viewmodel/AwSublocationViewModel';
import AwInclude from 'viewmodel/AwIncludeViewModel';
import AwSplitter from 'viewmodel/AwSplitterViewModel';
    import { AwClick,ExistWhen } from 'js/hocCollection';
const DivAwClick = AwClick('div');
const AwIncludeExistWhen = ExistWhen(AwInclude);
const AwSplitterExistWhen = ExistWhen(AwSplitter);
DivAwClick.displayName = 'DivAwClick';
AwIncludeExistWhen.displayName = 'AwIncludeExistWhen';
AwSplitterExistWhen.displayName = 'AwSplitterExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/occmgmtSplitViewService', () => import('js/occmgmtSplitViewService'));
registerDynImportEntry('js/structureCompareService', () => import('js/structureCompareService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {
        "view1": {
            "customEditContext": "leftXRTEditContext",
            "contextKey": "occmgmtContext",
            "tabSetId": "occmgmtContext",
            "dataProviderName": "occDataProvider",
            "gridId": "occTreeTable",
            "name": "com.siemens.splm.client.occmgmt:OccurrenceManagementSubLocation",
            "label": {
                "source": "/i18n/OccurrenceManagementConstants",
                "key": "occMgmtTitle"
            },
            "requestPref": {
                "savedSessionMode": "ignore",
                "splitMode": "true",
                "showMarkup": [
                    "false"
                ],
                "dataFilterMode": "compare"
            },
            "clientScopeURI": "Awb0OccurrenceManagement",
            "nameToken": "com.siemens.splm.client.occmgmt:OccurrenceManagementSubLocation",
            "policy": "/policies/occMgmtPropertyPolicy.json",
            "viewBase": "occMgmt",
            "editContext": "occDataProvider",
            "locationSupportsJitterFreePropLoad": false,
            "selectionQueryParamKey": "c_uid",
            "editSupportParamKeys": [
                "c_uid",
                "o_uid",
                "spageId",
                "pci_uid",
                "c_uid2",
                "o_uid2",
                "spageId2",
                "pci_uid2"
            ],
            "viewModes": {
                "TreeSummaryView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": true
                },
                "TreeView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": false
                }
            },
            "context": {
                "commandContextParameters": [
                    "filter",
                    "recipe",
                    "uid",
                    "uid2"
                ],
                "search": {
                    "hideInContentSearchBox": true
                }
            },
            "breadcrumbConfig": {
                "type": "navigate",
                "vm": "occMgmtBreadcrumb",
                "chevronDataProvider": "breadCrumbDataProvider",
                "crumbDataProvider": "aceBreadCrumbDataProvider",
                "noUpdate": true,
                "popuplist": "/html/occMgmtBreadcrumblist.html",
                "id": "occmgmtContext",
                "displayProperty": "object_string"
            },
            "urlParams": {
                "selectionQueryParamKey": "c_uid",
                "openStructureQueryParamKey": "o_uid",
                "rootQueryParamKey": "uid",
                "productContextQueryParamKey": "pci_uid",
                "csidQueryParamKey": "c_csid",
                "secondaryPageIdQueryParamKey": "spageId",
                "topElementQueryParamKey": "t_uid",
                "pageIdQueryParamKey": "pageId",
                "recipeParamKey": "recipe",
                "subsetFilterParamKey": "filter"
            },
            "headerPreference": {
                "hideGlobalSearch": true,
                "hideLogo": true
            },
            "awSubLocationPref": {
                "hideNavigationArea": true,
                "hideRightWall": true,
                "hideToolsAndInfoArea": true,
                "hideFooter": true,
                "orientation": "HORIZONTAL"
            }
        },
        "view2": {
            "customEditContext": "rightXRTEditContext",
            "contextKey": "occmgmtContext2",
            "tabSetId": "occmgmtContext2",
            "dataProviderName": "occDataProvider2",
            "gridId": "occTreeTable2",
            "name": "com.siemens.splm.client.occmgmt:OccurrenceManagementSubLocation",
            "label": {
                "source": "/i18n/OccurrenceManagementConstants",
                "key": "occMgmtTitle"
            },
            "requestPref": {
                "splitMode": "true",
                "showMarkup": [
                    "false"
                ],
                "dataFilterMode": "compare"
            },
            "clientScopeURI": "Awb0OccurrenceManagement",
            "nameToken": "com.siemens.splm.client.occmgmt:OccurrenceManagementSubLocation",
            "policy": "/policies/occMgmtPropertyPolicy.json",
            "viewBase": "occmgmtSplit",
            "editContext": "occDataProvider2",
            "locationSupportsJitterFreePropLoad": false,
            "selectionQueryParamKey": "c_uid2",
            "editSupportParamKeys": [
                "c_uid",
                "o_uid",
                "spageId",
                "pci_uid",
                "c_uid2",
                "o_uid2",
                "spageId2",
                "pci_uid2"
            ],
            "viewModes": {
                "TreeSummaryView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": true
                },
                "TreeView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": false
                }
            },
            "context": {
                "commandContextParameters": [
                    "filter",
                    "recipe",
                    "uid",
                    "uid2"
                ],
                "search": {
                    "hideInContentSearchBox": true
                }
            },
            "breadcrumbConfig": {
                "type": "navigate",
                "vm": "occMgmtBreadcrumb",
                "chevronDataProvider": "breadCrumbDataProvider2",
                "crumbDataProvider": "aceBreadCrumbDataProvider2",
                "noUpdate": true,
                "popuplist": "/html/occMgmtBreadcrumblist.html",
                "id": "occmgmtContext2",
                "displayProperty": "object_string"
            },
            "urlParams": {
                "selectionQueryParamKey": "c_uid2",
                "openStructureQueryParamKey": "o_uid2",
                "rootQueryParamKey": "uid2",
                "productContextQueryParamKey": "pci_uid2",
                "csidQueryParamKey": "c_csid2",
                "secondaryPageIdQueryParamKey": "spageId2",
                "topElementQueryParamKey": "t_uid2",
                "pageIdQueryParamKey": "pageId2",
                "recipeParamKey": "recipe2",
                "subsetFilterParamKey": "filter2"
            },
            "headerPreference": {
                "hideGlobalSearch": true,
                "hideLogo": true
            },
            "awSubLocationPref": {
                "hideNavigationArea": true,
                "hideRightWall": true,
                "hideToolsAndInfoArea": true,
                "hideFooter": true,
                "orientation": "HORIZONTAL"
            }
        },
        "hiddenCommands": {
            "isSaveWorkingContextNotSupported": true,
            "Awb0StructureFilter": true,
            "Awp0ShowColor": true,
            "Awb0ShowMarkup": true,
            "Awb0AddSubstitutes": true,
            "Awb0RemoveSubstitutes": true,
            "Awb0UseSubstitute": true,
            "ps0AddAlternate": true,
            "ps0RemoveAlternate": true
        },
        "view": {
            "keys": [
                "occmgmtContext",
                "occmgmtContext2"
            ]
        },
        "occContext": {
            "initialValues": {
                "configContext": {},
                "displayToggleOptions": {},
                "supportedFeatures": {},
                "productContextInfo": {},
                "currentState": {},
                "previousState": {},
                "transientRequestPref": {},
                "persistentRequestPref": {
                    "defaultClientScopeUri": "Awb0OccurrenceManagement",
                    "splitMode": "true"
                },
                "disabledFeatures": [],
                "BackGroundSoaDebug": "true",
                "LoadTreePropsTimerDebug": "true",
                "LoadTreePropsDebug": "false",
                "ExpandBelowDebug": "true",
                "ExpandOneDebug": "false",
                "ReconfigureDebug": "false",
                "viewKey": "occmgmtContext",
                "customEditContext": "leftXRTEditContext"
            },
            "meta": {}
        },
        "occContext2": {
            "initialValues": {
                "configContext": {},
                "displayToggleOptions": {},
                "supportedFeatures": {},
                "productContextInfo": {},
                "currentState": {},
                "previousState": {},
                "transientRequestPref": {},
                "persistentRequestPref": {
                    "defaultClientScopeUri": "Awb0OccurrenceManagement",
                    "splitMode": "true"
                },
                "disabledFeatures": [],
                "BackGroundSoaDebug": "true",
                "LoadTreePropsTimerDebug": "true",
                "LoadTreePropsDebug": "false",
                "ExpandBelowDebug": "true",
                "ExpandOneDebug": "false",
                "ReconfigureDebug": "false",
                "viewKey": "occmgmtContext2",
                "customEditContext": "rightXRTEditContext"
            },
            "meta": {}
        },
        "compareContext": {
            "initialValues": {},
            "meta": {}
        },
        "selectionData": {
            "initialValues": {},
            "meta": {}
        },
        "selectionData2": {
            "initialValues": {},
            "meta": {}
        },
        "showObjectContext": {
            "initialValues": {
                "displayName": ""
            },
            "meta": {}
        },
        "showObjectContext2": {
            "initialValues": {
                "displayName": ""
            },
            "meta": {}
        }
    },
    "actions": {
        "initializeSplitView": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeOccmgmtSplitView"
                },
                {
                    "action": "synchronizeSplitViewStateWithURL"
                },
                {
                    "action": "skipAutoBookmarkForSplitView"
                },
                {
                    "action": "splitViewLocationUpdated"
                }
            ]
        },
        "synchAndPublishSplitViewLocationUpdate": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "synchronizeSplitViewStateWithURL"
                },
                {
                    "action": "skipAutoBookmarkForSplitView"
                },
                {
                    "action": "splitViewLocationUpdated"
                }
            ]
        },
        "synchronizeSplitViewStateWithURL": {
            "actionType": "JSFunctionAsync",
            "method": "synchronizeSplitViewStateWithURL",
            "deps": "js/occmgmtSplitViewService",
            "inputData": {
                "objectsToOpen": "{{data.objectsToOpen}}",
                "activeState": "{{data.activeState}}",
                "occContext": "{{fields.occContext}}",
                "occContext2": "{{fields.occContext2}}",
                "data": "{{data}}",
                "showObjectContext": "{{fields.showObjectContext}}",
                "showObjectContext2": "{{fields.showObjectContext2}}"
            },
            "outputData": {
                "objectsToOpen": "objectsToOpen",
                "activeState": "activeState"
            }
        },
        "skipAutoBookmarkForSplitView": {
            "actionType": "JSFunction",
            "method": "skipAutoBookmarkForSplitView",
            "deps": "js/occmgmtSplitViewService",
            "inputData": {
                "objectsToOpen": "{{data.objectsToOpen}}"
            }
        },
        "splitViewLocationUpdated": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "splitViewLocationUpdated",
                        "excludeLocalDataCtx": true
                    }
                ]
            }
        },
        "initializeOccmgmtSplitView": {
            "actionType": "JSFunction",
            "method": "initializeOccmgmtSplitView",
            "deps": "js/occmgmtSplitViewService",
            "inputData": {
                "viewKeys": "{{data.view.keys}}",
                "hiddenCommands": "{{data.hiddenCommands}}"
            },
            "outputData": {
                "activateView": ""
            }
        },
        "activateLeftView": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "updatePageContext"
                },
                {
                    "action": "activateLeftViewEvent"
                }
            ]
        },
        "activateRightView": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "updatePageContext2"
                },
                {
                    "action": "activateRightViewEvent"
                }
            ]
        },
        "activateLeftViewEvent": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "ace.activateWindow",
                        "eventData": {
                            "key": "{{data.view1.contextKey}}"
                        }
                    }
                ]
            }
        },
        "activateRightViewEvent": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "ace.activateWindow",
                        "eventData": {
                            "key": "{{data.view2.contextKey}}"
                        }
                    }
                ]
            }
        },
        "updatePageContext": {
            "actionType": "JSFunction",
            "method": "updatePageContext",
            "deps": "js/occmgmtSplitViewService",
            "inputData": {
                "localSelectionData": "{{data.selectionData}}",
                "pageContext": "{{props.subPanelContext.pageContext}}",
                "keyOfActivatedView": "{{data.view1.contextKey}}"
            }
        },
        "updatePageContext2": {
            "actionType": "JSFunction",
            "method": "updatePageContext",
            "deps": "js/occmgmtSplitViewService",
            "inputData": {
                "localSelectionData": "{{data.selectionData2}}",
                "pageContext": "{{props.subPanelContext.pageContext}}",
                "keyOfActivatedView": "{{data.view2.contextKey}}"
            }
        },
        "destroyOccmgmtSplitView": {
            "actionType": "JSFunction",
            "method": "destroyOccmgmtSplitView",
            "deps": "js/occmgmtSplitViewService",
            "inputData": {
                "viewKeys": "{{data.view.keys}}"
            }
        },
        "performAutoCompare": {
            "actionType": "JSFunction",
            "method": "performAutoCompare",
            "inputData": {
                "compareContext": "{{fields.compareContext}}",
                "occContext": "{{fields.occContext}}",
                "occContext2": "{{fields.occContext2}}",
                "eventData": "{{data.eventData}}"
            },
            "deps": "js/structureCompareService"
        },
        "syncExpansionToRight": {
            "actionType": "JSFunction",
            "method": "syncExpansion",
            "inputData": {
                "vmc": "{{data.occContext2.vmc}}",
                "equivalentList": "{{data.compareContext.srcEquivalentList}}",
                "nodeToExpand": "{{data.eventData}}"
            },
            "deps": "js/structureCompareService"
        },
        "syncExpansionToLeft": {
            "actionType": "JSFunction",
            "method": "syncExpansion",
            "inputData": {
                "vmc": "{{data.occContext.vmc}}",
                "equivalentList": "{{data.compareContext.trgEquivalentList}}",
                "nodeToExpand": "{{data.eventData}}"
            },
            "deps": "js/structureCompareService"
        },
        "resetCompareContext": {
            "actionType": "JSFunction",
            "method": "resetCompareContext",
            "inputData": {
                "compareContext": "{{data.compareContext}}"
            },
            "outputData": {
                "compareContext": ""
            },
            "deps": "js/structureCompareService"
        },
        "resetProductListener": {
            "actionType": "JSFunction",
            "method": "resetProductListener",
            "inputData": {
                "savedSessionMode": "{{fields.occContext.transientRequestPref.savedSessionMode}}",
                "compareContext": "{{data.compareContext}}"
            },
            "outputData": {
                "compareContext": ""
            },
            "deps": "js/structureCompareService"
        },
        "resetProductListener2": {
            "actionType": "JSFunction",
            "method": "resetProductListener",
            "inputData": {
                "savedSessionMode": "{{fields.occContext2.transientRequestPref.savedSessionMode}}",
                "compareContext": "{{data.compareContext}}"
            },
            "outputData": {
                "compareContext": ""
            },
            "deps": "js/structureCompareService"
        },
        "setResetOnProductChangeFlag": {
            "actionType": "JSFunction",
            "method": "setResetOnProductChangeFlag",
            "inputData": {
                "compareContext": "{{data.compareContext}}"
            },
            "outputData": {
                "compareContext": ""
            },
            "deps": "js/structureCompareService"
        },
        "syncSelectionToRight": {
            "actionType": "JSFunction",
            "method": "syncSelection",
            "inputData": {
                "occContext": "{{fields.occContext}}",
                "occContext2": "{{fields.occContext2}}",
                "compareContext": "{{fields.compareContext}}",
                "grid": 1
            },
            "deps": "js/structureCompareService"
        },
        "syncSelectionToLeft": {
            "actionType": "JSFunction",
            "method": "syncSelection",
            "inputData": {
                "occContext": "{{fields.occContext}}",
                "occContext2": "{{fields.occContext2}}",
                "compareContext": "{{fields.compareContext}}",
                "grid": 2
            },
            "deps": "js/structureCompareService"
        },
        "resetGridSelectionModel": {
            "actionType": "JSFunction",
            "method": "resetGridSelectionModel",
            "inputData": {
                "occContext": "{{fields.occContext}}",
                "occContext2": "{{fields.occContext2}}",
                "isInCompareMode": "{{fields.compareContext.isInCompareMode}}"
            },
            "deps": "js/structureCompareService"
        },
        "reinitializeCompare": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "resetGridSelectionModel"
                },
                {
                    "action": "syncSelectionToRight"
                }
            ]
        },
        "handleSelectionChange": {
            "actionType": "JSFunction",
            "method": "handleSelectionChange",
            "inputData": {
                "localSelectionData": "{{data.selectionData}}",
                "pageContext": "{{props.subPanelContext.pageContext}}",
                "selectionInfos": "{{data.selectionInfos}}"
            },
            "outputData": {
                "selectionInfos": ""
            },
            "deps": "js/occmgmtSplitViewService"
        },
        "handleSelectionChange2": {
            "actionType": "JSFunction",
            "method": "handleSelectionChange",
            "inputData": {
                "localSelectionData": "{{data.selectionData2}}",
                "pageContext": "{{props.subPanelContext.pageContext}}",
                "selectionInfos": "{{data.selectionInfos}}"
            },
            "outputData": {
                "selectionInfos": ""
            },
            "deps": "js/occmgmtSplitViewService"
        }
    },
    "lifecycleHooks": {
        "onMount": "initializeSplitView",
        "onUnmount": "destroyOccmgmtSplitView",
        "onUpdate": [
            {
                "action": "syncSelectionToRight",
                "observers": [
                    "viewModel.atomicData.occContext.selectedModelObjects"
                ]
            },
            {
                "action": "syncSelectionToLeft",
                "observers": [
                    "viewModel.atomicData.occContext2.selectedModelObjects"
                ]
            },
            {
                "action": "reinitializeCompare",
                "observers": [
                    "viewModel.atomicData.compareContext.isInCompareMode"
                ]
            },
            {
                "action": "handleSelectionChange",
                "observers": [
                    "viewModel.atomicData.selectionData"
                ]
            },
            {
                "action": "handleSelectionChange2",
                "observers": [
                    "viewModel.atomicData.selectionData2"
                ]
            },
            {
                "action": "resetProductListener",
                "observers": [
                    "fields.occContext.transientRequestPref.savedSessionMode"
                ]
            },
            {
                "action": "resetProductListener2",
                "observers": [
                    "fields.occContext2.transientRequestPref.savedSessionMode"
                ]
            }
        ]
    },
    "onEvent": [
        {
            "eventId": "LOCATION_CHANGE_COMPLETE",
            "action": "synchAndPublishSplitViewLocationUpdate"
        },
        {
            "eventId": "occMgmt.visibilityStateChanged",
            "condition": "!data.compareContext.resetOnProductChange && (data.compareContext.isInCompareMode === true || (ctx.compareContext.autoOpenComparePanel === true && ctx.compareContext.isMultiStructureFirstLaunch === true))",
            "cacheEventData": true,
            "action": "performAutoCompare"
        },
        {
            "eventId": "occTreeTable.plTable.toggleTreeNode",
            "condition": "data.compareContext.isInCompareMode === true",
            "cacheEventData": true,
            "action": "syncExpansionToRight"
        },
        {
            "eventId": "occTreeTable2.plTable.toggleTreeNode",
            "condition": "data.compareContext.isInCompareMode === true",
            "cacheEventData": true,
            "action": "syncExpansionToLeft"
        },
        {
            "eventId": "configurationChangeStarted",
            "action": "resetCompareContext",
            "condition": "data.compareContext.isInCompareMode"
        },
        {
            "eventId": "productChangedEvent",
            "action": "setResetOnProductChangeFlag",
            "condition": "data.compareContext.isInCompareMode"
        },
        {
            "eventId": "occMgmt.visibilityStateChanged",
            "action": "resetCompareContext",
            "condition": "data.compareContext.isInCompareMode && data.compareContext.resetOnProductChange"
        }
    ],
    "ctx": {
        "aceActiveContext": {
            "key": "occmgmtContext"
        }
    },
    "_viewModelId": "OccmgmtSplit",
    "_uniqueViewModelId": "OccmgmtSplit"
};

    /*
    <aw-sublocation
    sub-panel-context="{provider : {...ctx.aceActiveContext.key === 'occmgmtContext' ? data.view1 : data.view2},
                        occContext: ctx.aceActiveContext.key === 'occmgmtContext' ? data.occContext : data.occContext2,
                        inactiveContext : ctx.aceActiveContext.key === 'occmgmtContext' ? data.occContext2 : data.occContext,
                        contextKey: ctx.aceActiveContext.key === 'occmgmtContext' ? data.view1.contextKey : data.view2.contextKey,
                        compareContext: data.compareContext}"
    sub-location-preference="{}">
    <div class="sw-row h-12 afx-content-background aw-occmgmt-split">
        <div class="sw-column w-6 aw-occmgmt-split-left" aw-click="actions.activateLeftView">
            <aw-include name="occurrenceManagement"
                sub-panel-context="{...subPanelContext, openedObject : data.objectsToOpen[0], provider : data.view1, occContext : data.occContext, contextKey: data.view1.contextKey, selectionData: fields.selectionData, showObjectContext: fields.showObjectContext}"
                exist-when="data.objectsToOpen[0].uid">
            </aw-include>
        </div>
        <aw-splitter min-size-1="0" min-size-2="200" exist-when="data.objectsToOpen.length > 1"></aw-splitter>
        <div class="sw-column w-6 aw-occmgmt-split-right" aw-click="actions.activateRightView">
            <aw-include name="occurrenceManagement"
                sub-panel-context="{...subPanelContext, openedObject : data.objectsToOpen[1], provider : data.view2, occContext : data.occContext2, contextKey: data.view2.contextKey, selectionData: fields.selectionData2, showObjectContext: fields.showObjectContext2}"
                exist-when="data.objectsToOpen.length > 1 && data.objectsToOpen[1].uid">
            </aw-include>
        </div>
    </div>
</aw-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSublocation subPanelContext={AwParseService.instance( "{provider : {...ctx.aceActiveContext.key === 'occmgmtContext' ? data.view1 : data.view2},\n                        occContext: ctx.aceActiveContext.key === 'occmgmtContext' ? fields.occContext : fields.occContext2,\n                        inactiveContext : ctx.aceActiveContext.key === 'occmgmtContext' ? fields.occContext2 : fields.occContext,\n                        contextKey: ctx.aceActiveContext.key === 'occmgmtContext' ? data.view1.contextKey : data.view2.contextKey,\n                        compareContext: fields.compareContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subLocationPreference={AwParseService.instance( "{}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <div className="sw-row h-12 afx-content-background aw-occmgmt-split">
            <DivAwClick className="sw-column w-6 aw-occmgmt-split-left" awClick={AwParseService.instance( "actions.activateLeftView" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwIncludeExistWhen viewId="occurrenceManagement" viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} name="occurrenceManagement" subPanelContext={AwParseService.instance( "{...subPanelContext, openedObject : data.objectsToOpen[0], provider : data.view1, occContext : fields.occContext, contextKey: data.view1.contextKey, selectionData: fields.selectionData, showObjectContext: fields.showObjectContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "data.objectsToOpen[0].uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwIncludeExistWhen>
            </DivAwClick>
            <AwSplitterExistWhen minSize1="0" minSize2="200" existWhen={AwParseService.instance( "data.objectsToOpen.length > 1" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwSplitterExistWhen>
            <DivAwClick className="sw-column w-6 aw-occmgmt-split-right" awClick={AwParseService.instance( "actions.activateRightView" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwIncludeExistWhen viewId="occurrenceManagement" viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} name="occurrenceManagement" subPanelContext={AwParseService.instance( "{...subPanelContext, openedObject : data.objectsToOpen[1], provider : data.view2, occContext : fields.occContext2, contextKey: data.view2.contextKey, selectionData: fields.selectionData2, showObjectContext: fields.showObjectContext2}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "data.objectsToOpen.length > 1 && data.objectsToOpen[1].uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwIncludeExistWhen>
            </DivAwClick>
        </div>
    </AwSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;