// @<COPYRIGHT>@
// ==================================================
// Copyright 2019.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/* global
 define
 */

/**
 * @module js/mbmCompareService
 */

import AwPromiseService from 'js/awPromiseService';
import appCtxService from 'js/appCtxService';
import soaSvc from 'soa/kernel/soaService';
import _ from 'lodash';
import eventBus from 'js/eventBus';
import mbmUtils from 'js/mbmUtils';
import { constants as mbmConstants } from 'js/mbmConstants';

/**
 * performCompare
 * @param {mbmContext} mbmContext mbmContext
 * @param {Boolean} isOpenWithCsp isOpenWithCsp
 * @param {Object} changeNoticeInfo changeNoticeInfo
 * @return {Promise} promise of  soa response
 */
function performCompare(mbmContext, isOpenWithCsp, changeNoticeInfo, mode, contextKey, taskBarContext, partialCompareObjects) {
    const topElements = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_TOP_ELEMENT);
    const pcis = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_PRODUCT_CONTEXT_INFO);
    const vmcs = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_VMC);


    let partialComapareInfo = mbmUtils.getContextValue(taskBarContext, mbmConstants.MBM_PARTIAL_COMPARE);

    let isPartialCompareOn = partialComapareInfo && partialComapareInfo.showPartialCompareBaner;
    if (isPartialCompareOn) {
        partialCompareObjects = partialComapareInfo.selectedNodes;
    }

    let sourceContextKey = appCtxService.getCtx('splitView.viewKeys')[0];
    let targetContextKey = appCtxService.getCtx('splitView.viewKeys')[1];

    let srcElement = topElements ? topElements[sourceContextKey] : null;
    let trgElement = topElements ? topElements[targetContextKey] : null;

    let srcPciObject = pcis ? pcis[sourceContextKey] : null;
    let trgPciObject = pcis ? pcis[targetContextKey] : null;

    let srcVmo;
    let trgVmo;

    if (contextKey === sourceContextKey && partialCompareObjects) {
        srcVmo = vmcs && vmcs[sourceContextKey] ? vmcs[sourceContextKey].getLoadedViewModelObjects() : null;
    } else if (contextKey === targetContextKey && partialCompareObjects) {
        trgVmo = vmcs && vmcs[targetContextKey] ? vmcs[targetContextKey].getLoadedViewModelObjects() : null;
    } else {
        srcVmo = vmcs && vmcs[sourceContextKey] ? vmcs[sourceContextKey].getLoadedViewModelObjects() : null;
        trgVmo = vmcs && vmcs[targetContextKey] ? vmcs[targetContextKey].getLoadedViewModelObjects() : null;
    }

    let deferred = AwPromiseService.instance.defer();

    if (mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_MODIFICATION_PROGRESS)) {
        let objToUpdate = mbmUtils.getValueToUpdate(false, mbmConstants.MBM_MODIFICATION_PROGRESS);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
        return deferred.resolve();
    }
    if (isValidCompare(srcElement, srcPciObject, srcVmo, trgElement, trgPciObject, trgVmo, isOpenWithCsp, changeNoticeInfo, partialCompareObjects)
        && !mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_COMPARE_PROGRESS)) {
        let objToUpdate = mbmUtils.getValueToUpdate(true, mbmConstants.MBM_COMPARE_PROGRESS);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
        let srcInput = getInputFor(srcElement, srcPciObject, srcVmo);
        let trgInput = getInputFor(trgElement, trgPciObject, trgVmo);
        let accCriteria = {};
        updateSelectedScopeInAccCriteria(mbmContext, accCriteria, 'strStrVecMap', sourceContextKey, 'EbomScopes');
        updateSelectedScopeInAccCriteria(mbmContext, accCriteria, 'strStrVecMap', targetContextKey, 'MbomScopes');

        if (mode) {
            addValueInAccCriteria(accCriteria, 'strBoolMap', 'DoFreshCompare', true);
        }
        if (contextKey === sourceContextKey && partialCompareObjects) {
            updateSelectedScopeInAccCriteria(mbmContext, accCriteria, 'strStrVecMap', sourceContextKey, 'selectedNodes', partialCompareObjects);
            addValueInAccCriteria(accCriteria, 'strBoolMap', 'partialCompareEbom', true);

        } else if (contextKey === targetContextKey && partialCompareObjects) {
            updateSelectedScopeInAccCriteria(mbmContext, accCriteria, 'strStrVecMap', targetContextKey, 'selectedNodes', partialCompareObjects);
            addValueInAccCriteria(accCriteria, 'strBoolMap', 'partialCompareMbom', true);
        }

        let compareInput = getSoaInput(srcInput, trgInput, changeNoticeInfo || {}, accCriteria);
        invokeCompareSoa(compareInput).then(function (response) {

            let sourceDifference;
            let targetDifference;
            let updatedCompareObject;

            if (contextKey === sourceContextKey && partialCompareObjects) {
                sourceDifference = processElementsStatus(response.eBOMElementsStatus);
                updateStatusInVmos(srcVmo, sourceDifference);
                updatedCompareObject = {
                    sourceIdsToUpdate: getVisibleUidsFor(srcVmo)
                };
            } else if (contextKey === targetContextKey && partialCompareObjects) {
                targetDifference = processElementsStatus(response.mBOMElementsStatus);
                updateStatusInVmos(trgVmo, targetDifference);
                updatedCompareObject = {
                    targetIdsToUpdate: getVisibleUidsFor(trgVmo)
                };

            } else {
                sourceDifference = processElementsStatus(response.eBOMElementsStatus);
                targetDifference = processElementsStatus(response.mBOMElementsStatus);
                updateStatusInVmos(srcVmo, sourceDifference);
                updateStatusInVmos(trgVmo, targetDifference);
                updatedCompareObject = {
                    sourceIdsToUpdate: getVisibleUidsFor(srcVmo),
                    targetIdsToUpdate: getVisibleUidsFor(trgVmo)
                };
            }

            eventBus.publish('mbm.compareComplete', updatedCompareObject);
            if (isOpenWithCsp) {
                eventBus.publish('mbm.changeRecordsStatusLoadedEvent', { changeRecordsStatus: response.changeRecordsStatus });
            }
            let objToUpdate = mbmUtils.getValueToUpdate(false, mbmConstants.MBM_COMPARE_PROGRESS);
            mbmUtils.updateValueToContext(mbmContext, objToUpdate);
            deferred.resolve({ selectedNodes: partialCompareObjects, contextKey: contextKey });
        });
    } else {
        deferred.resolve();
    }
    return deferred.promise;
}

/**
 * Update compare status in vmo
 * @param {Array} vmos vmos
 * @param {Object} statuses  statuses
 */
function updateStatusInVmos(vmos, statuses) {
    _.forEach(vmos, function (vmo) {
        let uid = vmo.uid;
        let statusInfo = statuses[uid];
        vmo.compareStatus = statusInfo ? statusInfo.status : null;
        vmo.targetInfo = statusInfo ? statusInfo.mappingUids : null;
    });
}

/**
 *
 * @param {Array} vmos vmos
 * @returns {Array} array of uid
 */
function getUidsFor(vmos) {
    if (vmos) {
        return vmos.map(function (vmo) {
            return vmo.uid;
        });
    }
    return null;
}

/**
 * @param {Object} mbmContext mbmContext
 * @param {Object} accCriteria accCriteria
 * @param {String} criteriaName criteriaName
 * @param {String} contextKey contextKey
 * @param {String} accCriteriaScopeKey accCriteriaScopeKey
 */
function updateSelectedScopeInAccCriteria(mbmContext, accCriteria, criteriaName, contextKey, accCriteriaScopeKey, partialCompareObjects) {
    let selectedScope = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTED_SCOPE);
    let selectedScopeUids;
    if (partialCompareObjects) {
        selectedScopeUids = getUidsFor(partialCompareObjects);
    } else if (selectedScope && selectedScope[contextKey]) {
        selectedScopeUids = getUidsFor(selectedScope[contextKey]);
    }
    if (selectedScopeUids) {
        updateAccCriteria(accCriteria, criteriaName, accCriteriaScopeKey, selectedScopeUids);
    }
}
/**
 * Update the given criteria values in acc criteria
 * @param {Object} accCriteria accCriteria
 * @param {String} criteriaName criteria name
 * @param {String} key criteria prop name
 * @param {Array} values array of values of criteria
 */
function updateAccCriteria(accCriteria, criteriaName, key, values) {
    if (accCriteria && criteriaName && key) {
        if (accCriteria.hasOwnProperty(criteriaName)) {
            let criteria = accCriteria[criteriaName];
            if (criteria && criteria.key) {
                let criteriaValues = criteria[key];
                if (criteriaValues) {
                    criteriaValues = criteriaValues.concat(values);
                } else {
                    criteria[key] = values;
                }
            } else {
                criteria[key] = values;
            }
        } else {
            accCriteria[criteriaName] = {};
            accCriteria[criteriaName][key] = values;
        }
    }
}

/**
 * Process the differences of given parameter
 * @param {Object} elementsStatus elementsStatus
 * @return {Object} differences
 */
function processElementsStatus(elementsStatus) {
    let statusMaping = {};
    if (elementsStatus) {
        for (let key in elementsStatus) {
            let elemStatus = {
                status: elementsStatus[key].status
            };
            if (elementsStatus[key].equivalentElements) {
                elemStatus.mappingUids = [];
                elementsStatus[key].equivalentElements.forEach(element => {
                    if (element.indexOf('##OUT_OF_SCOPE') !== -1) {
                        elemStatus.mappingUids.push({ uid: element.replace('##OUT_OF_SCOPE', ''), outOfScope: true });
                    } else {
                        elemStatus.mappingUids.push({ uid: element, outOfScope: false });
                    }
                });
            }
            statusMaping[key] = elemStatus;
        }
    }
    return statusMaping;
}

/**
 * Get input of given object
 * @param {Object} element comparable object
 * @param {Object} pci product context information
 * @param {Array} visibleVmos array of view model objects
 * @return {Object}  object
 */
function getInputFor(element, pci, visibleVmos) {
    return {
        scopeObject: {
            uid: element.uid,
            type: element.type
        },
        configInfo: {
            uid: pci.uid,
            type: pci.type
        },
        objectsToProcess: getVisibleUidsFor(visibleVmos)
    };
}

/**
 * Get the  input for getAssignmentAndMismatchStatus soa
 * @param {Object} sourceInfo source object
 * @param {Object} targetInfo target object
 * @param {Object} changeNoticeInfo change notice info
 * @param {Object} acCriteria acc criteria
 * @return {Object} input data
 */
function getSoaInput(sourceInfo, targetInfo, changeNoticeInfo, acCriteria) {
    return {
        input: {
            eBOMInfo: sourceInfo,
            mBOMInfo: targetInfo,
            changeNoticeInfo: changeNoticeInfo,
            acCriteria: acCriteria
        }
    };
}


/**
 * Check the  validity of compare of given parameter
 * @param {Object} sourceElement source top object
 * @param {Object} srcPciObject source pci object
 * @param {Array} sourceVmosToUpdate Array of source vmo
 * @param {Object} targetElement target top object
 * @param {Object} trgPciObject target pci object
 * @param {Array} targetVmosToUpdate Array of target vmo
 * @param {boolean} isOpenWithCsp isOpenWithCsp
 * @param {Object} changeNoticeInfo changeNoticeInfo
 * @returns {boolean} true if valid otherwise false
 */
function isValidCompare(sourceElement, srcPciObject, sourceVmosToUpdate, targetElement, trgPciObject, targetVmosToUpdate, isOpenWithCsp, changeNoticeInfo, partialCompareObjects) {

    if (partialCompareObjects && (sourceVmosToUpdate || targetVmosToUpdate)) {
        return partialCompareObjects.length > 0;
    }

    if (!sourceElement || !targetElement || sourceElement && targetElement && sourceElement.uid === targetElement.uid) {
        return false;
    }

    if (!srcPciObject || !trgPciObject) {
        return false;
    }

    if (isOpenWithCsp && !changeNoticeInfo) {
        return false;
    }

    if (sourceVmosToUpdate && targetVmosToUpdate) {
        return sourceVmosToUpdate.length > 0 || targetVmosToUpdate.length > 0;
    }

    return false;
}
/**
 *
 * @param {Object} soaInput soaInput
 * @returns {Promise} promise
 */
function invokeCompareSoa(soaInput) {
    return soaSvc.postUnchecked('Internal-MultiBomManager-2020-05-MultiBOMChangeMgmt', 'getAssignmentAndMismatchStatus', soaInput);
}

/**
 *
 * @param {Array} vmos  array of view model object
 * @return {Array} array of uids
 */
function getVisibleUidsFor(vmos) {
    let uids = [];
    _.forEach(vmos, function (vmo) {
        uids.push(vmo.uid);
    });

    return uids;
}

/**
 * Update the given criteria values in acc criteria
 * @param {Object} accCriteria
 * @param {String} key name of the criteria
 * @param {Array} values array of values of criteria
 */
function addValueInAccCriteria(accCriteria, criteriaName, key, value) {
    if (!accCriteria[criteriaName]) {
        accCriteria[criteriaName] = {};
    }
    accCriteria[criteriaName][key] = value;
}

export default {
    performCompare
};
