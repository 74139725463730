// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */
/**
 * @module js/K4BDispatcherConsoleService
 */

 import _ from "lodash";
 import appCtxSvc from 'js/appCtxService';
 import appCtxService from "js/appCtxService";
 import viewModelObjectService from "js/viewModelObjectService";
 import awSearchService from 'js/awSearchService';
 ("use strict");
 
 function getUpdateRequestInput(nextState) {
   let requestInput = [];
   for (let i = 0; i < appCtxService.getCtx("mselected").length; i++) {
     let req = {
       "requestToUpdate": appCtxSvc.ctx.mselected[i],
       "currentState":
       appCtxSvc.ctx.mselected[i].props.currentState.dbValues[0],
       "nextState": nextState,
     };
     requestInput.push(req);
   }
 
   return requestInput;
 }
 
 let exports = {};
 export let getDispatcherRequestInput = function (data) {
   let states = [
     "INITIAL",
     "SCHEDULED",
     "PREPARING",
     "TRANSLATING",
     "LOADING",
     "COMPLETE",
     "TERMINAL",
     "DUPLICATE",
     "SUPERSEDED",
     "NO_TRANS",
     "TIMEOUT",
     "SUPERSEDING",
   ];
 
   let services = appCtxService.getCtx("preferences").DPK_Dispatcher_Services;
 
   if (
     data.columnProviders.dispatcherReqColProvider.columnFilters &&
     data.columnProviders.dispatcherReqColProvider.columnFilters.length > 0
   ) {
     for (
       let i = 0;
       i < data.columnProviders.dispatcherReqColProvider.columnFilters.length;
       i++
     ) {
       if (
         data.columnProviders.dispatcherReqColProvider.columnFilters[i]
           .columnName == "serviceName"
       ) {
         services =
           data.columnProviders.dispatcherReqColProvider.columnFilters[i].values;
       }
       if (
         data.columnProviders.dispatcherReqColProvider.columnFilters[i]
           .columnName == "currentState"
       ) {
         states =
           data.columnProviders.dispatcherReqColProvider.columnFilters[i].values;
       }
     }
   }
 
   let response = [];
   let req = {
     states: states,
     services: services,
   };
   response.push(req);
   return response;
 };
 export let getDispatcherRefreshInput = function () {
   let taskIDs = [];
   for (var i = 0; i < appCtxService.getCtx("mselected").length; i++) {
     taskIDs.push(appCtxSvc.ctx.mselected[i].props.taskID.dbValue);
   }
 
   var response = [];
   var req = {
     taskID: taskIDs,
   };
   response.push(req);
   return response;
 };
 
 /**
  * This function is responsible for handling sorting and pagination of the data.
  *
  * @param {ObjectArray} dispatcherRequests response from GET call
  * @param {Object} sortCriteria Sort criteria
  * @param {int} startIndex start index for pagination
  * @param {int} pageSize Total number of rows to return for pagination
  *
  * @return {ObjectArray}  paginated filtered result
  */
 export let parseDispatcherRequests = function (
   dispatcherRequests,
   sortCriteria,
   startIndex,
   pageSize
 ) {
   //let endIndex = startIndex + pageSize;
   let response = [];
   for (let key in dispatcherRequests.ServiceData.modelObjects) {
     if (
       dispatcherRequests.ServiceData.modelObjects[key].type ==
       "DispatcherRequest"
     ) {
       response.push(
         viewModelObjectService.createViewModelObject(
           dispatcherRequests.ServiceData.modelObjects[key].uid,
           "EDIT"
         )
       );
     }
   }
   if (sortCriteria && sortCriteria.length > 0) {
     let criteria = sortCriteria[0];
     let sortDirection = criteria.sortDirection;
     let sortColName = criteria.fieldName;
 
     if (sortDirection === "ASC") {
       response.sort(function (a, b) {
         if (a.props[sortColName].value <= b.props[sortColName].value) {
           return -1;
         }
         return 1;
       });
     } else if (sortDirection === "DESC") {
       response.sort(function (a, b) {
         if (a.props[sortColName].value >= b.props[sortColName].value) {
           return -1;
         }
         return 1;
       });
     }
   }
 
   let outputData = {
     outputFilteredObjects: response,
     totalFound: response.length,
   };
   // This is required to have the total results found in Subocation breadcumb.
 
   appCtxService.updateCtx("search".totalFound, response.length);
   return outputData;
 };
 export let getDeleteRequestInput1 = function () {
   console.log("getDeleteRequestInput1");
   return getUpdateRequestInput("DELETE");
 };
 export let getResubmitRequestInput = function () {
   return getUpdateRequestInput("INITIAL");
 };
 export let getFilterFacets = function (filterFacetInput, contextData) {
   if (filterFacetInput.column.field == "currentState") {
     let states = [
       "INITIAL",
       "SCHEDULED",
       "PREPARING",
       "TRANSLATING",
       "LOADING",
       "COMPLETE",
       "TERMINAL",
       "DUPLICATE",
       "SUPERSEDED",
       "NO_TRANS",
       "TIMEOUT",
       "SUPERSEDING",
     ];
 
     let facets = {
       values: states,
       totalFound: states.length,
     };
     return facets;
   }
   if (filterFacetInput.column.field == "serviceName") {
     let services = appCtxService.getCtx("preferences").DPK_Dispatcher_Services;
 
     let factes = {
       values: services,
       totalFound: services.length,
     };
     return factes;
   }
 };
 
 
 let getFilteredSearchFilterCategories = (searchResponse) => {
   //logic to get the controlled list of filters from preference.
   let locationName = appCtxService.getCtx('locationContext')['ActiveWorkspace:Location'];
   let sublocationName = appCtxService.getCtx('locationContext')['ActiveWorkspace:SubLocation'];
   let ctxPrefObject = appCtxService.getCtx("preferences");
 
   let filterList = ctxPrefObject[sublocationName + "_AW_FiltersList"];
 
   if (filterList == undefined) {
       filterList = null;
       filterList = ctxPrefObject[locationName + "_AW_FiltersList"];
   }
 
   //clone the searchFilterCategories from search response
   let searchFilterCategories = searchResponse.searchFilterCategories.slice();
 
   if (filterList == undefined || searchFilterCategories == undefined) {
     return searchFilterCategories;
   }
   else {
     //if the filter proprty is not found in the searchFilterCategories, discard it
     searchFilterCategories = searchFilterCategories.filter(category => filterList.includes(category.internalName));
     searchFilterCategories.sort((a,b) => filterList.indexOf(a.internalName) - filterList.indexOf(b.internalName));
   }
 
   return searchFilterCategories;  
 }
 
 let getPropertyNameForSortCriteria = function( sortCriteria, columnConfig ) {
   if( sortCriteria && sortCriteria.length > 0 && columnConfig && columnConfig.columns ) {
       let fieldName = sortCriteria[ 0 ].fieldName.substring( sortCriteria[ 0 ].fieldName.indexOf( "." ) + 1);
       let index = _.findIndex( columnConfig.columns, function( o ) {
           return o.propertyName === fieldName;
       } );
       if( index > -1 ) {
           sortCriteria[ 0 ].fieldName = columnConfig.columns[ index ].propertyName;
           return sortCriteria;
       }
   } else if(sortCriteria && sortCriteria.length > 0) {
     let fieldName = sortCriteria[ 0 ].fieldName.substring( sortCriteria[ 0 ].fieldName.indexOf( "." ) + 1);
     if(fieldName) {
       sortCriteria[ 0 ].fieldName = fieldName;
       return sortCriteria;
     }
   }
   return sortCriteria;
 };
 
 let updateSearchSortCtx = (searchState, provider) => {
     let defaultSortCriteria =   [{fieldName: "awp0Item_item_id", sortDirection: "DESC" }];
     let sublocationCtx = appCtxService.getCtx( 'sublocation' );
 
     let currentSearchSortCriteria = sublocationCtx && sublocationCtx.sortCriteria && sublocationCtx.sortCriteria.length > 0 ?
     sublocationCtx.sortCriteria : [];
 
     if(currentSearchSortCriteria?.length > 0) {
       let updatedSortCriteria = getPropertyNameForSortCriteria( currentSearchSortCriteria, searchState.columnConfig );
     
       sublocationCtx.sortCriteria = updatedSortCriteria ;
       appCtxService.updatePartialCtx( 'sublocation', sublocationCtx );
   
       let clientScopeURI = provider.clientScopeURI;
       appCtxService.updatePartialCtx( ''+clientScopeURI, {sortCriteria: sublocationCtx.sortCriteria} );
       return updatedSortCriteria;
     }
     return defaultSortCriteria;
 }
 
 let updateSortDirectionInColumnConfig = (columnConfig, sortCriteria) => {
   let columns = columnConfig && columnConfig.columns || [ ];
   if( columns?.length > 0 && sortCriteria?.length > 0 ) {
     let fieldName = sortCriteria[ 0 ].fieldName;
     let index = _.findIndex( columnConfig.columns, function( o ) {
       return o.propertyName === fieldName;
     });
     if(index > -1) {
       columns[ index ].sortDirection = sortCriteria[ 0 ].sortDirection;
       columns[ index ].sortPriority = 1;
     }
   }
 }
 
 export let processOutput = function (searchResponse, dataCtxNode, searchState, provider) {  
   try {
     //logic to assign the default value for "chart by" listbox.
     if(searchResponse && searchResponse.objectsGroupedByProperty != null) {
         if(searchState.objectsGroupedByProperty == null && searchResponse.searchFilterCategories && searchResponse.searchFilterCategories.length > 0) {
           searchResponse.objectsGroupedByProperty.internalPropertyName = getChartCategoryInternalName(searchResponse.searchFilterCategories);
         } else {
           searchResponse.objectsGroupedByProperty.internalPropertyName = searchState.objectsGroupedByProperty && searchState.objectsGroupedByProperty.internalPropertyName || '';
         } 
       }      
       //fetch filtered categories
       searchResponse.searchFilterCategories = getFilteredSearchFilterCategories(searchResponse);
       
       //setting sort criteria in ctx => this is used in showing the current state of ascending or descending in the column sort menu
       let sortCriteria = updateSearchSortCtx(searchState, provider);
 
       updateSortDirectionInColumnConfig(searchResponse.columnConfig, sortCriteria);
   } catch (error) {
     console.error("Error while applying chartby / filter categories: "+error);
   }
   return awSearchService.processOutput(searchResponse, dataCtxNode, searchState);
 };
 
 export let getSearchSortCriteria = function(sortCriteria, searchState) {
 
    //Fix for enabling sort direction selection in the column menu
    if(searchState?.columnConfig?.columns?.length > 0){
       return awSearchService.setFieldNameForSortCriteria( sortCriteria, searchState.columnConfig );
    } 
   let updatedSortCriteria = [{"fieldName": "ItemRevision.awp0Item_item_id", "sortDirection": "DESC"}];
   return updatedSortCriteria
 }
 
 export let getActualTotalFound = function( soaResponse, searchState ) {
   return awSearchService.getActualTotalFound(soaResponse, searchState);
 };
 
 export let updateFilterMap = function( filterMap ) {
   return awSearchService.updateFilterMap(filterMap);
 };
 
 
 export let getInternalPropertyName = function( provider ) {
   return awSearchService.getInternalPropertyName(provider)
 };
  
 export let setColumnConfig = function( response ) {
   return awSearchService.setColumnConfig(response);
 };
 export let getHighlightKeywords = function( data ) {
   return awSearchService.getHighlightKeywords( data );
 };
 
 export let loadData = function( columnConfigInput, saveColumnConfigData, searchInput ) {
   if(saveColumnConfigData && saveColumnConfigData.columns && saveColumnConfigData.columns.length > 0) {
     // Fix for: Arrange column action is distorting the exisitng column sort.
     saveColumnConfigData.columns = saveColumnConfigData.columns.map( column => ({...column, sortPriority: 0, sortDirection: ""}) );
   }
   return awSearchService.loadData( columnConfigInput, saveColumnConfigData, searchInput );
 };
 
 /**
  * @memberof NgServices
  * @member K4BDispatcherConsoleService
  */
 exports = {
   parseDispatcherRequests,
   getFilterFacets,
   getDispatcherRequestInput,
   getDeleteRequestInput1,
   getResubmitRequestInput,
   getDispatcherRefreshInput,
   getUpdateRequestInput,
   processOutput,
   getSearchSortCriteria,
   getActualTotalFound,
   updateFilterMap,
   getInternalPropertyName,
   setColumnConfig,
   loadData,
   getHighlightKeywords
 };
 
 export default exports;
 