/*global define, document, window */

/**
 *
 * @module js/V4B_EscalateIssue
 *
 * @namespace V4B_EscalateIssue
 */
import app from 'app';
import _ from 'lodash';
import appCtxSvc from 'js/appCtxService';
import soaSvc from 'soa/kernel/soaService';
import messagingService from 'js/messagingService';
import modelPropertyService from 'js/modelPropertyService';
import localeService from 'js/localeService';
import policySvc from 'soa/kernel/propertyPolicyService';
import eventBus from 'js/eventBus';
import AwPromiseService from 'js/awPromiseService';
import uwPropertyService from 'js/uwPropertyService';

let exports = {};

export let getLOVObjects = function (response) {
    let listOfValues = [];
    response.lovValues.forEach(function (LovObjUid) {
        let LOV = {
            "propDisplayValue": LovObjUid.propDisplayValues.lov_values[0],
            "propDisplayDescription": "",
            "propInternalValue": LovObjUid.propDisplayValues.lov_values[0],
            "uid": "",
            "dbValue": LovObjUid.propDisplayValues.lov_values[0],
            "dbValues": [LovObjUid.propDisplayValues.lov_values[0]]
        };
        listOfValues.push(LOV);
    });
    return listOfValues;
};


export let rejectSuggestions = function( data, selectedLOVEntries, suggestion, filteredLOVEntries ) {
    var valid = true;
    var message = '';
  
    if( suggestion !== null && ( _.isNil( filteredLOVEntries ) || filteredLOVEntries.length === 0 ) ) {
        valid = false;
        message = data.i18n.enteredValueIsNotValid;
    } else if( suggestion !== null && !_.isNil( filteredLOVEntries ) && filteredLOVEntries.length > 0 ) {
        let isMatchingEntryFound = false;
        for( const filteredLOVEntry of filteredLOVEntries ) {
          //|| filteredLOVEntry.propDisplayValue?.includes( suggestion )
            if( suggestion === filteredLOVEntry.propDisplayValue  ) {
                isMatchingEntryFound = true;
                break;
            }
        }
        //No matching
        if( isMatchingEntryFound === false ) {
            valid = false;
            message = data.i18n.enteredValueIsNotValid;
        }
    }
    return {
        valid: valid,
        message: "Property is required"
    };
  };

/**
 * Gets the user names filled up in properties specified in preference.
 * @param {String} data - data.
 */
export let getUsersData = function (data) {
    let deferred = AwPromiseService.instance.defer();
    let ctx = appCtxSvc.getCtx();
    if (!ctx.selected) {
        return;
    }
    let prefAll = undefined;
    if (!!ctx.preferences && !!ctx.preferences.AWC_V4B_EscalateIssueProperties) {
        prefAll = ctx.preferences.AWC_V4B_EscalateIssueProperties;
    }

    let objTypeProp = undefined;
    let objProp = undefined;
    for (let ii = 0; ii < prefAll.length; ii++) {
        objTypeProp = prefAll[ii].split(":");
        if (objTypeProp[0] == ctx.selected.type) {
            objProp = objTypeProp[1].split(",");
            break;
        }
    }

    if (objProp != undefined && objProp.indexOf('v4b_owning_user') !== -1) {
        objProp.push('owning_user');
    }
    data.chkbxArrArr = [];

    if (objProp != undefined && objProp.length > 0) {
        loadObject(ctx.selected.uid, objProp).then(function (response) {
            let userArrArr = [];
            let userObjToLoadArrArr = [];
            data.propArr = [];
            objProp = objProp.filter(function (value) {
                return value != "owning_user";
            });
            for (let ii = 0; ii < objProp.length; ii++) {

                if (response.props[objProp[ii]]) {

                    let prop = response.props[objProp[ii]];

                    if (prop.uiValues && prop.propertyDescriptor.displayName) {

                        for (let u = 0; u < prop.dbValues.length; u++) {
                            let userUID = objProp[ii] == 'v4b_owning_user' ? response.props['owning_user'].dbValues[u] : prop.dbValues[u];
                            if (userUID != null) {
                                let user = {
                                    "uid": userUID,
                                    "type": "User"
                                }
                                userObjToLoadArrArr.push(user);
                            }
                        }

                    }
                }
            }

            let policyId = policySvc.register({
                types: [{
                    name: 'User',
                    properties: [
                        {
                            name: 'user_id'
                        },
                        {
                            name: 'user_name'
                        }
                    ]
                },
                {
                    name: 'TaskInbox',
                    properties: [{
                        name: 'owning_user',
                        modifiers: [{
                            name: 'withProperties',
                            Value: 'true'
                        }]
                    }]
                }
                ]
            });
            let inputDataGetProperties = {
                "objects": userObjToLoadArrArr,
                "attributes": ["user_id", "user_name"]
            };
            soaSvc.post("Core-2006-03-DataManagement", "getProperties", inputDataGetProperties).then(function (responsePropertyLoad) {

                policySvc.unregister(policyId);
                let userObjArrArr = [];
                data.propArr = [];
                for (let ii = 0; ii < objProp.length; ii++) {
                    if (response.props[objProp[ii]]) {
                        let prop = response.props[objProp[ii]];
                        if (prop.uiValues && prop.propertyDescriptor.displayName) {
                            let usrModelObj = [];
                            let surrogateUsrModelObj = [];
                            for (let u = 0; u < prop.dbValues.length; u++) {
                                let userUID = objProp[ii] == 'v4b_owning_user' ? response.props['owning_user'].dbValues[u] : prop.dbValues[u];
                                if (userUID != null) {
                                    let user = {
                                        "uid": userUID,
                                        "type": "User"
                                    }
                                    usrModelObj.push(user);
                                }
                            }
                            userObjArrArr.push(usrModelObj);
                            if (prop.propertyDescriptor.anArray || prop.uiValues[0] != "")
                                userArrArr.push(prop.uiValues);
                            else
                                userArrArr.push([]);

                            data.propArr.push(prop.propertyDescriptor.displayName);
                        }
                    }
                }
                for (let ii = 0; ii < data.propArr.length; ii++) {
                    data.chkbxArrArr.push([]);
                    let props = [];
                    for (let jj = 0; jj < userArrArr[ii].length; jj++) {                    
                        let displayName =userArrArr[ii][jj];
                        let checkBoxProperty = uwPropertyService.createViewModelProperty( displayName, displayName, 'BOOLEAN', '', displayName );
                        checkBoxProperty.isEditable = true;
                        checkBoxProperty.dbValue = "";
                        checkBoxProperty.labelPosition = 'PROPERTY_LABEL_AT_RIGHT';
                        checkBoxProperty.propInternalVal = userObjArrArr[ii][jj];
                        checkBoxProperty.userObj = userObjArrArr[ii][jj];
                        checkBoxProperty.dispValue = displayName;
                        checkBoxProperty.uiValue = displayName;
                        checkBoxProperty.displayValues = [displayName];
                        checkBoxProperty.fielddata = {};
                        checkBoxProperty.fielddata = checkBoxProperty;
                        props.push(checkBoxProperty);

                    }
                    data.chkbxArrArr[ii].propRName = data.propArr[ii];
                    data.chkbxArrArr[ii].userChkboxproperty = props;
                }
                deferred.resolve({chkbxArrArr: data.chkbxArrArr, propArr: data.propArr})
            });
        }, function (errObj) {
            let msg = errObj;
            errObj && errObj.message && (msg = errObj.message),
                console.log(msg);
            messagingService.showError(msg);
            deferred.resolve({chkbxArrArr: [], propArr: []});
        });

    } else {
        localeService.getLocalizedText(app.getBaseUrlPath() + '/i18n/VIMToolInfoCommandsMessages', 'noObjectPropFound').then(
            function (msg) {
                messagingService.showError(msg);
            });
    }
    return deferred.promise;
};

export let EscalateIssueAndSendMail = function (data) {
    let ctx = appCtxSvc.getCtx();
    let sEscLevel="";
    let awLang = appCtxSvc.getCtx("userSession").props.fnd0locale.dbValue.substring(0,2);
    if(awLang == "de")
    {
        sEscLevel = data.v4b_ref_genftm_escalation.dbValue == "Stufe 1" ? "step-1" : data.v4b_ref_genftm_escalation.dbValue == "Stufe 2" ? "step-2" : "no";

    }else{
        sEscLevel = data.v4b_ref_genftm_escalation.dbValue == "step 1" ? "step-1" : data.v4b_ref_genftm_escalation.dbValue == "step 2" ? "step-2" : "no";
    }
    
    let info = [];
    info.push({
        object: {
            uid: ctx.selected.uid,
            type: "V4B_FTMTKB_IssueRevision"
        },
        vecNameVal: [
            {
                name: "v4b_str_escalation_level",
                values: [
                    sEscLevel
                ]
            },
            {
                name: "v4b_ref_genftm_esc_partner",
                values: data.v4b_ref_genftm_escalation_partner.dbValue
            }
        ]
    });
    let inputData = {
        info: info
    };
    soaSvc.post("Core-2010-09-DataManagement", "setProperties", inputData).then(function (response) {
    }, function (errObj) {
        let msg = errObj;
        errObj && errObj.message && (msg = errObj.message);
        console.log(msg);
        messagingService.showError(msg);
    });
    let userList = [];
    // for (let ii = 0; ii < data.chkbxArrArr.length; ii++) {
    //     for (let jj = 0; jj < data.chkbxArrArr[ii].length; jj++) {
    //         if (data.chkbxArrArr[ii][jj].dbValue)
    //             userList.push(data.chkbxArrArr[ii][jj].userObj);
    //     }
    // }
    for (let i = 0; i < data.selectedUsers.userList.length; i++) {
        userList.push(data.selectedUsers.userList[i].userObj);
    }
    for (let indexofpartner = 0; indexofpartner < data.v4b_ref_genftm_escalation_partner.dbValue.length; indexofpartner++) {

        if (data.v4b_ref_genftm_escalation_partner.dbValue[indexofpartner]) {
            let tempuser = {
                "uid": data.v4b_ref_genftm_escalation_partner.dbValue[indexofpartner],
                "type": "User"
            }
            userList.push(tempuser);
        }
    }
    let input = {
        "targetObjectUid": ctx.selected.uid,
        "sUserComment": data.userInput.dbValue,
        "tSenderUser": ctx.user,
        "vecToEmailList": userList,
        "sEmailNotificationPurpose": data.v4b_ref_genftm_escalation.dbValue
    }
    let uploadData = {
        "emailNotificationInput": input
    }
    soaSvc.post("VIMC-2016-03-SendEmailNotification", "emailNotification", uploadData).then(function (response) {
        return response;
    }, function (errObj) {
        let msg = errObj;
        errObj && errObj.message && (msg = errObj.message);
        console.log(msg);
        messagingService.showError(msg);
    });
};
let loadObject = function (uid, propsToLoad) {
    if (!uid) return null;

    let props = [],
        propertyPolicy;
    if (!!propsToLoad && propsToLoad.length > 0) {
        propsToLoad.forEach(function (propName) {
            props.push({
                "name": propName
            });
        });
        propertyPolicy = {
            "types": [{
                "name": "BusinessObject",
                "properties": props
            }]
        };

        policySvc.register(propertyPolicy);
    }

    return soaSvc.post("Core-2007-09-DataManagement", "loadObjects", {
        "uids": [uid]
    }).then(function (loadResponse) {
        return loadResponse.modelObjects[uid];
    },
        function (errObj) {
            let msg = errObj;
            errObj && errObj.message && (msg = errObj.message);
            messagingService.showError(msg);
            return;
        });
};

export let DeEscalateIssue = function (ctx) {

    let issueDetail = {
        issueUID: ctx.selected.uid,
        issueType: "",
        issueProperties: [{
            propertyName: "v4b_str_escalation_level",
            propertyValue: []
        }, {
            propertyName: "v4b_ref_genftm_esc_partner",
            propertyValue: []
        }]
    }
    let uploadData = {
        "issueDetails":[issueDetail],
        "updateIssueOperationType":"DEESCALATE_FTM_ISSUE"
    };
    return soaSvc.post("VIMC-2016-03-UpdateIssues", "updateIssues", uploadData).then(function (response) {
        eventBus.publish('primaryWorkarea.reset');
        eventBus.publish('cdm.relatedModified', {
            relatedModified:
                [
                    appCtxSvc.getCtx('xrtSummaryContextObject')
                ],
            refreshLocationFlag: false,
            relations: "",

        });
        return response;
    },function (errObj) {
            let msg = errObj;
            errObj && errObj.message && (msg = errObj.message);
            messagingService.showError(msg);
        });
};

export let validateEscalationlevelValue = function (data) {
    return {validEsc: true, isvalidatedOnce: !data.isvalidatedOnce}
};

export let setEscalationStep1 = function (data) {
    let awLang = appCtxSvc.getCtx("userSession").props.fnd0locale.dbValue.substring(0,2);
    let v4b_ref_genftm_escalation = {...data.v4b_ref_genftm_escalation};
    if(awLang == "de")
    {
        v4b_ref_genftm_escalation.uiValue="Stufe 1";
        v4b_ref_genftm_escalation.dbValue="step-1";

    }else{
        v4b_ref_genftm_escalation.uiValue="Step 1";
        v4b_ref_genftm_escalation.dbValue="step-1";
    }
    
    return v4b_ref_genftm_escalation;
};

export let setEscalationNo = function (data) {
    let v4b_ref_genftm_escalation = {...data.v4b_ref_genftm_escalation};
    let awLang = appCtxSvc.getCtx("userSession").props.fnd0locale.dbValue.substring(0,2);
    if(awLang == "de")
    {
        v4b_ref_genftm_escalation.uiValue="Nein";
        v4b_ref_genftm_escalation.dbValue="no";

    }else{
        v4b_ref_genftm_escalation.uiValue="no";
        v4b_ref_genftm_escalation.dbValue="no";
    }
    return v4b_ref_genftm_escalation;
};

export let ConfirmEscalationSelected = function (data) {
    return true;
};

export default exports = {
    getLOVObjects,
    getUsersData,
    EscalateIssueAndSendMail,
    DeEscalateIssue,
    validateEscalationlevelValue,
    setEscalationStep1,
    setEscalationNo,
    ConfirmEscalationSelected,
    rejectSuggestions
};