// Copyright 2022 Siemens Product Lifecycle Management Software Inc.

/**
 * @module js/mbmSubstituteService
 */
import eventBus from 'js/eventBus';
import cdmSvc from 'soa/kernel/clientDataModel';
import occmgmtVMTNodeCreateService from 'js/occmgmtViewModelTreeNodeCreateService';
import mbmUtils from 'js/mbmUtils';
import appCtxSvc from 'js/appCtxService';

var getNumberOfChildRows = function( loadedVmosList, removalNodeInfo, nodeIndexInLoadedVmosList ) {
    var totalNumberOfRowsIncludingParentRow = 0;
    var currentNodeLevel = removalNodeInfo.levelNdx;

    for( var i = nodeIndexInLoadedVmosList + 1; i < loadedVmosList.length; i++ ) {
        if( loadedVmosList[ i ].levelNdx > currentNodeLevel ) {
            ++totalNumberOfRowsIncludingParentRow;
        } else {
            break;
        }
    }
    return totalNumberOfRowsIncludingParentRow;
};

/**
 *
 * @param {Array} targetUids This will provide data provider name
 * @param {object} occContext occContext
 * This function replace the node in the tree
 */
function replaceSourceNodeWithTargetNode( targetUids, occContext ) {
    var vmc = occContext.vmc;
    var objectReplaced = false;
    var treeDataProvider = occContext.treeDataProvider;
    var loadedVMObjects = vmc.getLoadedViewModelObjects();
    let srcUids = [ occContext.selectedModelObjects[0].uid ];
    if( srcUids && srcUids.length > 0 ) {
        for( var i = 0; i < srcUids.length; i++ ) {
            var target = targetUids ? targetUids[ i ] : srcUids[ i ];
            var srcIndex = vmc.findViewModelObjectById( srcUids[ i ] );
            if( srcIndex > -1 ) {
                var srcNode = vmc.getViewModelObject( srcIndex );
                var numberOfChildRows = getNumberOfChildRows( loadedVMObjects, srcNode, srcIndex );
                var targetMO = cdmSvc.getObject( target );
                var targetNode = occmgmtVMTNodeCreateService.createVMNodeUsingModelObjectInfo( targetMO, srcNode.childNdx, srcNode.levelNdx );
                //LCS-436500 (manually constructed vmo misses out on selected=true prop)
                if( srcNode.selected ) {
                    targetNode.selected = true;
                }
                updateParentsChildrenListWithNewChildNode( vmc, srcNode, targetNode );
                loadedVMObjects.splice( srcIndex, 1 + numberOfChildRows );
                loadedVMObjects.splice( srcIndex, 0, targetNode );
                objectReplaced = true;
            }
        }
        treeDataProvider.update( loadedVMObjects );
    }
    eventBus.publish( 'reRenderTableOnClient' );
    return objectReplaced;
}
var updateParentsChildrenListWithNewChildNode = function( vmc, srcNode, childNodeToAdd ) {
    if( srcNode.props ) {
        let parentUid = srcNode.props.awb0Parent.dbValues[ 0 ];
        // get parent to updates its children
        var parentVmoIndex = vmc.findViewModelObjectById( parentUid );
        if( parentVmoIndex > -1 ) {
            var parentVMO = vmc.getViewModelObject( parentVmoIndex );
            parentVMO.children.splice( srcNode.childNdx, 1, childNodeToAdd );
        }
    }
};

/**
 *
 * @param {object} occContext occContext
 * This function update editincontextmode flag in occcontext
 */
function updateValueInContext( occContext ) {
    let editInProgress = mbmUtils.getContextValue( occContext, 'editInProgress' );
    let treeEditingMode = appCtxSvc.getCtx( 'mbomDataProvider' );
    if ( !editInProgress && !treeEditingMode._editing ) {
        editInProgress = true;
    }else{
        editInProgress = false;
    }
    const objToUpdate = mbmUtils.getValueToUpdate( editInProgress, 'editInProgress' );
    mbmUtils.updateValueToContext( occContext, objToUpdate );
}
export default {
    replaceSourceNodeWithTargetNode,
    updateValueInContext
};
