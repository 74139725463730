// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import visWebInstanceProvider from 'js/visWebInstanceProvider';
import epSecurityMarkingService from 'js/epSecurityMarkingService';
import preferenceSvc from 'soa/preferenceService';
import { constants as epGraphicsConstants } from 'js/epGraphicsConstants';
import AwPromiseService from 'js/awPromiseService';
import appCtxSvc from 'js/appCtxService';

/**
 * EP Graphics Service
 *
 * @module js/epGraphicsService
 */
'use strict';
const NAVIGATION_ACTION_PAN = 1;
const NAVIGATION_MASK_MMB = 2;

/**
 *
 * @param {String} visViewerInstanceId - the vis viewer instance id
 */
function init( visViewerInstanceId ) {
    if( visViewerInstanceId ) {
        visWebInstanceProvider.getVisWebInstance( visViewerInstanceId ).Viewer.viewerManager.navigation.navSettings.zoom.toCursor = true;

        visWebInstanceProvider.getVisWebInstance( visViewerInstanceId ).Viewer.viewerManager.overrideMouseNavigationInput( NAVIGATION_ACTION_PAN, NAVIGATION_MASK_MMB );

        visWebInstanceProvider.getVisWebInstance( visViewerInstanceId ).Viewer.viewerManager.setSelectionToggleMode( false );

        visWebInstanceProvider.getVisWebInstance( visViewerInstanceId ).Viewer.viewerManager.getNavigationManager().navSettings.rotate.aroundTarget = true;

        visWebInstanceProvider.getVisWebInstance( visViewerInstanceId ).onWarning = epSecurityMarkingService.securityMarkingCallBackFunc;

        enableGraphicsCulling( visViewerInstanceId );

        return setCustomSelectionColor( visViewerInstanceId );
    }
    return AwPromiseService.instance.resolve();
}
/**
 *
 * @param {String} visViewerInstanceId visViewerInstanceId
 */
function setCustomSelectionColor( visViewerInstanceId ) {
    return preferenceSvc.getStringValues( [ epGraphicsConstants.EP_GraphicsSelectionColor ] ).then( function( values ) {
        values && values.length > 0 && values[ 0 ] !== '' && visWebInstanceProvider.getVisWebInstance( visViewerInstanceId ).Viewer.viewerManager.setSelectionMaterial( { diffuse: values[ 0 ] } );
    } );
}

/**
 * User needs to add "EP_EnableGraphicsCulling" preference with below details to enable/disable culling:
 * Name : EP_EnableGraphicsCulling
 * Category : Easy Plan
 * Type : Logical
 * Multiple : Single
 * Value : true - to enable culling / false - to disable culling
 *
 * Add entry of "EP_EnableGraphicsCulling" in pref "AWC_StartupPreferences"
 *
 * By default culling is disabled.
 *
 * @param {String} visViewerInstanceId visViewerInstanceId
 */
function enableGraphicsCulling( visViewerInstanceId ) {
    preferenceSvc.getStringValues( [ 'EP_EnableGraphicsCulling' ] ).then( function( values ) {
        const value = ( values && values.length > 0 && values[0] !== '' && values[0].toLowerCase() ) === 'true';

        visWebInstanceProvider.getVisWebInstance( visViewerInstanceId ).Viewer.cullingManager.enableCulling( value );
    } );
}

/**
 *
 * @param {String} visViewerInstanceId - the vis viewer instance id
 * @param {String} transactionId - transaction id
 * @param {Function} callback - callback function, returns a map of modelID and visibility state
 */
function setVisibilityByTransactionId( visViewerInstanceId, transactionId, callback ) {
    visWebInstanceProvider.getVisWebInstance( visViewerInstanceId ).Viewer.setVisibilityByTransactionId( transactionId, callback );
}

/**
 *
 * @param {String} visViewerInstanceId - the vis viewer instance id
 * @param {Object} nodesToUpdateDisplayState - nodes to update visibility state
 * @param {Function} callback - callback function, returns a map of modelID and visibility state
 */
function setVisibilityOfNodes( visViewerInstanceId, nodesToUpdateDisplayState, callback ) {
    visWebInstanceProvider.getVisWebInstance( visViewerInstanceId ).Viewer.setVisibilityOfNodes( nodesToUpdateDisplayState, callback );
}

/**
 *
 * @param {String} visViewerInstanceId - the vis viewer instance id
 * @param {Object} nodes - nodes to unload from viewer
 * @param {Function} callback - callback function, returns a map of modelID and visibility state
 */
function unloadNodes( visViewerInstanceId, nodes ) {
    visWebInstanceProvider.getVisWebInstance( visViewerInstanceId ).Viewer.unloadNodes( nodes );
}

/**
 *  zooms to selected objects in the graphic viewer
 * @param {String} visViewerInstanceId - the vis viewer instance id
 */
function zoomToSelection( visInstanceId ) {
    visWebInstanceProvider.getVisWebInstance( visInstanceId ).Viewer.fitToSelection();
}

function selectByVolume( visInstanceId ) {
    let cc_uid = appCtxSvc.getCtx( 'selectByVolumeCommandCtx' );

    if( !cc_uid ) {
        visWebInstanceProvider.getVisWebInstance( visInstanceId ).Viewer.enableSelectionByVolume();
        appCtxSvc.updatePartialCtx( 'selectByVolumeCommandCtx', true );
    } else {
        visWebInstanceProvider.getVisWebInstance( visInstanceId ).Viewer.doSelectionByVolume();
        appCtxSvc.updatePartialCtx( 'selectByVolumeCommandCtx', false );
    }
}

/**
 *  hide all PMIs in the graphic viewer
 * @param {String} visInstanceId - the vis viewer instance id
 */
function hideAllPmis( visInstanceId ) {
    visWebInstanceProvider.getVisWebInstance( visInstanceId ).PMI.hideAllPmis();
}

/**
 * Allows user to draw reactangular selection area on graphics panel
 * Drawing from left to right will select only fully covered objects (red indication).
 * Drawing from right to left will select objects even if they are partially covered (blue indication).
 * @param {String} visInstanceId - the vis viewer instance id
 */
function enableSelectByRectangle( visInstanceId ) {
    visWebInstanceProvider.getVisWebInstance( visInstanceId ).Viewer.viewerManager.setAreaSelect( true );
}

/**
 * @param {String} visInstanceId - the vis viewer instance id
 * @return {Object} Selected parts
 */
function getSelectedParts( visInstanceId ) {
    return visWebInstanceProvider.getVisWebInstance( visInstanceId ).Viewer.getSelectedParts();
}

/**
 * Sets transparency of node
 * @param {String} visInstanceId
 * @param {Array} nodes
 * @param {String} opacity
 */
function setTransparencyById( visInstanceId, nodes, opacity ) {
    visWebInstanceProvider.getVisWebInstance( visInstanceId ).Viewer.setMaterialById( nodes, { opacity: opacity }, false, () => {} );
}

/**
 * Returns idToVisibilityMap
 * @param {String} visInstanceId
 * @returns all the visible nodes
 */
function getAllTheVisibleNodesInViewer( visInstanceId ) {
    return visWebInstanceProvider.getVisWebInstance( visInstanceId ).Viewer.getVisibilityStateOfAllParts();
}
/**
 *
 * @param {*} visInstanceId visInstanceId
 * @returns all loaded PMIs and their visibility state
 */
function getAllTheVisiblePMIsInViewer( visInstanceId ) {
    return visWebInstanceProvider.getVisWebInstance( visInstanceId ).PMI.getVisibilityStateMap();
}

/**
 *
 * @param {*} visInstanceId : visInstanceId
 * @param {*} nodes : nodes
 * @param {*} opacity : opacity
 * @returns
 */
function setPMITransparencyById( visInstanceId, nodes, opacity ) {
    return visWebInstanceProvider.getVisWebInstance( visInstanceId ).PMI.setMaterialById( nodes, { opacity: opacity }, false, () => {} );
}

export default {
    init,
    setVisibilityByTransactionId,
    setVisibilityOfNodes,
    zoomToSelection,
    hideAllPmis,
    unloadNodes,
    setTransparencyById,
    getAllTheVisibleNodesInViewer,
    getAllTheVisiblePMIsInViewer,
    setPMITransparencyById,
    enableSelectByRectangle,
    getSelectedParts,
    selectByVolume
};
