// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwScrollpanel from 'viewmodel/AwScrollpanelViewModel';
import AwToolbar from 'viewmodel/AwToolbarViewModel';
import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import AwWidget from 'viewmodel/AwWidgetViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwTree from 'viewmodel/AwTreeViewModel';
    import { ExistWhen,VisibleWhen,AwClass } from 'js/hocCollection';
const AwToolbarExistWhen = ExistWhen(AwToolbar);
const AwCommandPanelSectionVisibleWhen = VisibleWhen(AwCommandPanelSection);
const DivVisibleWhen = VisibleWhen('div');
const AwPanelSectionVisibleWhen = VisibleWhen(AwPanelSection);
const AwPanelSectionVisibleWhenAwClass = AwClass(VisibleWhen(AwPanelSection));
AwToolbarExistWhen.displayName = 'AwToolbarExistWhen';
AwCommandPanelSectionVisibleWhen.displayName = 'AwCommandPanelSectionVisibleWhen';
DivVisibleWhen.displayName = 'DivVisibleWhen';
AwPanelSectionVisibleWhen.displayName = 'AwPanelSectionVisibleWhen';
AwPanelSectionVisibleWhenAwClass.displayName = 'AwPanelSectionVisibleWhenAwClass';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/classifyAdminService', () => import('js/classifyAdminService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "lifecycleHooks": {
        "onInit": "initializePropertiesSWA",
        "onUpdate": {
            "action": "initializePropertiesSWA",
            "observers": [
                "props.subPanelContext.context.searchState.propertiesSWA"
            ]
        }
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        }
    },
    "data": {
        "headerProperties": [
            {
                "propertyName": "ID"
            },
            {
                "propertyName": "IRDI"
            },
            {
                "propertyName": "owning_user"
            },
            {
                "propertyName": "dateModified"
            },
            {
                "propertyName": "Status"
            },
            {
                "propertyName": "object_type"
            }
        ],
        "clsadminPropCommands": "clsadmin_propCommands"
    },
    "actions": {
        "initializePropertiesSWA": {
            "actionType": "JSFunction",
            "method": "initializeSWA",
            "inputData": {
                "subPanelContext": "{{subPanelContext.context.searchState}}",
                "subLocationName": "Properties"
            },
            "outputData": {
                "currentSecData": "currentSecData",
                "dataTypeMetric": "dataTypeMetric",
                "dataTypeNonMetric": "dataTypeNonMetric",
                "keyLOVTreeDataMetric": "keyLOVTreeDataMetric",
                "keyLOVTreeDataNonMetric": "keyLOVTreeDataNonMetric",
                "multiSiteData": "multiSiteData",
                "referenceAttrData": "referenceAttrData",
                "isAvailable": "isAvailable"
            },
            "deps": "js/classifyAdminService"
        }
    },
    "conditions": {
        "showKeyLovMetric": {
            "expression": "props.subPanelContext.context.searchState.value.propertiesSWA.keyLOVTreeDataMetric"
        },
        "showKeyLovNonMetric": {
            "expression": "props.subPanelContext.context.searchState.value.propertiesSWA.keyLOVTreeDataNonMetric"
        },
        "isSummaryHeaderHidden": {
            "expression": "!(ctx.state && ctx.state.urlAttributes && ctx.state.urlAttributes.flexibleLayouts !== undefined)"
        }
    },
    "i18n": {
        "dataType": [
            "ClassificationAdminMessages"
        ],
        "entries": [
            "ClassificationAdminMessages"
        ],
        "keylovTitle": [
            "ClassificationAdminMessages"
        ],
        "metric": [
            "ClassificationAdminMessages"
        ],
        "multiSite": [
            "ClassificationAdminMessages"
        ],
        "nonMetric": [
            "ClassificationAdminMessages"
        ],
        "propertiesTitle": [
            "ClassificationAdminMessages"
        ],
        "referenceAttribute": [
            "ClassificationAdminMessages"
        ]
    },
    "_viewModelId": "Awp0ClsPropertiesSecWorkArea",
    "_uniqueViewModelId": "Awp0ClsPropertiesSecWorkArea",
    "ctx": {
        "state": {
            "type": "object"
        }
    }
};

    /*
    <aw-scrollpanel>
    <div class="aw-clsadmin-properties w-12">
        <aw-toolbar second-anchor="aw_summaryHeader" exist-when="conditions.isSummaryHeaderHidden" context="props.subPanelContext.context"></aw-toolbar>
        <div class="sw-row" >
            <aw-command-panel-section caption="{{i18n.propertiesTitle}}" visible-when="data.currentSecData.length > 0"
                collapsed="false" anchor="data.clsadminPropCommands" context="props.subPanelContext.context"
                class="h-12 aw-clsadmin-prop-properties">
                    <div class="aw-clspadmin-prop-section" aw-repeat="item : data.currentSecData" visible-when="item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true">
                        <aw-widget prop="item"></aw-widget>
                    </div>
            </aw-command-panel-section>
        </div>
        <aw-panel-section caption="{{i18n.dataType}}" visible-when=" data.dataTypeMetric.length > 0" collapsed="false">
            <div class="sw-row">
                <aw-panel-section aw-class="[{'w-6' : data.dataTypeNonMetric.length > 0},{'w-12' : data.dataTypeNonMetric.length === 0}]"
                    caption="{{i18n.metric}}" visible-when=" data.dataTypeMetric.length > 0" collapsed="false">
                    <div class="aw-clspadmin-prop-section" aw-repeat="item : data.dataTypeMetric" visible-when="item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true">
                        <aw-widget prop="item"></aw-widget>
                    </div>
                    <!-- Add KeyLOV entries under Metric datatype. Replaces KeyLOV setcion -->
                    <aw-panel-section caption="{{i18n.entries}}" visible-when="conditions.showKeyLovMetric"  collapsed="false">
                        <aw-tree name="propertiesKeyLOVMetric" tree="subPanelContext.context.searchState.value.propertiesSWA.keyLOVTreeDataMetric">
                            {{node.label}}
                        </aw-tree>
                    </aw-panel-section>
                </aw-panel-section>

                <aw-panel-section class="w-12" caption="{{i18n.nonMetric}}" visible-when="data.dataTypeNonMetric.length > 0" collapsed="false">
                    <div class="aw-clspadmin-prop-section" aw-repeat="item : data.dataTypeNonMetric" visible-when="item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true">
                        <aw-widget prop="item"  ></aw-widget>
                    </div>
                    <!-- Add KeyLOV entries under NonMetric datatype. Replaces KeyLOV setcion -->
                    <aw-panel-section caption="{{i18n.entries}}" visible-when="conditions.showKeyLovNonMetric" collapsed="false">
                        <aw-tree name="propertiesKeyLOVNonMetric" tree="subPanelContext.context.searchState.value.propertiesSWA.keyLOVTreeDataNonMetric">
                            {{node.label}}
                        </aw-tree>
                    </aw-panel-section>
                </aw-panel-section>
            </div>
        </aw-panel-section>
        <aw-panel-section class="w-12" caption="{{i18n.multiSite}}" visible-when="data.multiSiteData.length > 0" collapsed="false">
            <div class="aw-clspadmin-prop-section" aw-repeat="item : data.multiSiteData" visible-when="item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true">
                <aw-widget prop="item"></aw-widget>
            </div>
        </aw-panel-section>
        <aw-panel-section class="w-12" caption="{{i18n.referenceAttribute}}" visible-when="data.referenceAttrData.length > 0" collapsed="false">
            <div class="aw-clspadmin-prop-section" aw-repeat="item : data.referenceAttrData" visible-when="item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true">
                <aw-widget prop="item"></aw-widget>
            </div>
        </aw-panel-section>
    </div>
</aw-scrollpanel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwScrollpanel>
        <div className="aw-clsadmin-properties w-12">
            <AwToolbarExistWhen secondAnchor="aw_summaryHeader" context={AwParseService.instance( "props.subPanelContext.context" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "conditions.isSummaryHeaderHidden" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwToolbarExistWhen>
            <div className="sw-row">
                <AwCommandPanelSectionVisibleWhen caption={AwParseService.instance( "i18n.propertiesTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" anchor={AwParseService.instance( "data.clsadminPropCommands" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} context={AwParseService.instance( "props.subPanelContext.context" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="h-12 aw-clsadmin-prop-properties" visibleWhen={AwParseService.instance( "data.currentSecData.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    { Object.entries( AwParseService.instance( "data.currentSecData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                        <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                            <AwWidget {...getField("item", fields, $index, 'data.currentSecData', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                            </AwWidget>
                        </DivVisibleWhen>
                    ) ) }
                </AwCommandPanelSectionVisibleWhen>
            </div>
            <AwPanelSectionVisibleWhen caption={AwParseService.instance( "i18n.dataType" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "data.dataTypeMetric.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <div className="sw-row">
                    <AwPanelSectionVisibleWhenAwClass caption={AwParseService.instance( "i18n.metric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "data.dataTypeMetric.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} awClass={AwParseService.instance( "[{'w-6' : data.dataTypeNonMetric.length > 0},{'w-12' : data.dataTypeNonMetric.length === 0}]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        { Object.entries( AwParseService.instance( "data.dataTypeMetric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                            <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                                <AwWidget {...getField("item", fields, $index, 'data.dataTypeMetric', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                                </AwWidget>
                            </DivVisibleWhen>
                        ) ) }
                        <AwPanelSectionVisibleWhen caption={AwParseService.instance( "i18n.entries" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "conditions.showKeyLovMetric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            <AwTree  name="propertiesKeyLOVMetric" tree={AwParseService.instance( "subPanelContext.context.searchState.value.propertiesSWA.keyLOVTreeDataMetric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            {({node, index})=>{ return <>                                {AwParseService.instance( "node.label" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, node, index } )}</> } }
                            </AwTree>
                        </AwPanelSectionVisibleWhen>
                    </AwPanelSectionVisibleWhenAwClass>
                    <AwPanelSectionVisibleWhen className="w-12" caption={AwParseService.instance( "i18n.nonMetric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "data.dataTypeNonMetric.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        { Object.entries( AwParseService.instance( "data.dataTypeNonMetric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                            <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                                <AwWidget {...getField("item", fields, $index, 'data.dataTypeNonMetric', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                                </AwWidget>
                            </DivVisibleWhen>
                        ) ) }
                        <AwPanelSectionVisibleWhen caption={AwParseService.instance( "i18n.entries" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "conditions.showKeyLovNonMetric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            <AwTree  name="propertiesKeyLOVNonMetric" tree={AwParseService.instance( "subPanelContext.context.searchState.value.propertiesSWA.keyLOVTreeDataNonMetric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            {({node, index})=>{ return <>                                {AwParseService.instance( "node.label" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, node, index } )}</> } }
                            </AwTree>
                        </AwPanelSectionVisibleWhen>
                    </AwPanelSectionVisibleWhen>
                </div>
            </AwPanelSectionVisibleWhen>
            <AwPanelSectionVisibleWhen className="w-12" caption={AwParseService.instance( "i18n.multiSite" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "data.multiSiteData.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                { Object.entries( AwParseService.instance( "data.multiSiteData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                    <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                        <AwWidget {...getField("item", fields, $index, 'data.multiSiteData', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                        </AwWidget>
                    </DivVisibleWhen>
                ) ) }
            </AwPanelSectionVisibleWhen>
            <AwPanelSectionVisibleWhen className="w-12" caption={AwParseService.instance( "i18n.referenceAttribute" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "data.referenceAttrData.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                { Object.entries( AwParseService.instance( "data.referenceAttrData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                    <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                        <AwWidget {...getField("item", fields, $index, 'data.referenceAttrData', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                        </AwWidget>
                    </DivVisibleWhen>
                ) ) }
            </AwPanelSectionVisibleWhen>
        </div>
    </AwScrollpanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;