// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import Awp0ClassificationTree from 'viewmodel/Awp0ClassificationTreeViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "ports": {},
    "lifecycleHooks": {},
    "props": {},
    "actions": {},
    "functions": {},
    "dataProviders": {},
    "columnProviders": {},
    "grids": {},
    "data": {},
    "onEvent": [],
    "conditions": {},
    "i18n": {},
    "_viewModelId": "ClsTreeTest",
    "_uniqueViewModelId": "ClsTreeTest",
    "ctx": {}
};

    /*
    <aw-command-panel-section caption="Test ClsTree">
    <aw-panel-body class="aw-layout-panelBody aw-layout-flexColumnContainer">
        <awp0-classification-tree search-string="" releases-string=""></awp0-classification-tree>
    </aw-panel-body>
</aw-command-panel-section>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwCommandPanelSection caption="Test ClsTree">
        <AwPanelBody className="aw-layout-panelBody aw-layout-flexColumnContainer">
            <Awp0ClassificationTree>
            </Awp0ClassificationTree>
        </AwPanelBody>
    </AwCommandPanelSection>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;