// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwCommandPanel from 'viewmodel/AwCommandPanelViewModel';
import AwCommandSubPanel from 'viewmodel/AwCommandSubPanelViewModel';
import AwNavigatePanel from 'viewmodel/AwNavigatePanelViewModel';
    import { ExistWhen,ShowWhen } from 'js/hocCollection';
const AwCommandSubPanelExistWhen = ExistWhen(AwCommandSubPanel);
const AwNavigatePanelExistWhen = ExistWhen(AwNavigatePanel);
const AwNavigatePanelShowWhen = ShowWhen(AwNavigatePanel);
AwCommandSubPanelExistWhen.displayName = 'AwCommandSubPanelExistWhen';
AwNavigatePanelExistWhen.displayName = 'AwNavigatePanelExistWhen';
AwNavigatePanelShowWhen.displayName = 'AwNavigatePanelShowWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Ac0ConversationService', () => import('js/Ac0ConversationService'));
registerDynImportEntry('js/Ac0DiscussionTileService', () => import('js/Ac0DiscussionTileService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "actions": {
        "doUnivConvPanelInit": {
            "actionType": "JSFunction",
            "method": "initUniversalConvPanel",
            "inputData": {
                "data": "{{data}}",
                "panelContext": "{{subPanelContext}}"
            },
            "outputData": {
                "universalData": "universalData",
                "sharedData": "sharedData"
            },
            "deps": "js/Ac0ConversationService"
        },
        "doUnivConvPanelUnmount": {
            "actionType": "JSFunction",
            "method": "teardownUniversalConvPanel",
            "deps": "js/Ac0ConversationService"
        },
        "backToDiscussionsAction": {
            "actionType": "JSFunction",
            "method": "backToDiscussionsActionData",
            "inputData": {
                "sharedData": "{{data.sharedData}}"
            },
            "outputData": {
                "sharedData": ""
            },
            "deps": "js/Ac0DiscussionTileService"
        },
        "backToCreateAction": {
            "actionType": "JSFunction",
            "method": "backToCreateActionData",
            "inputData": {
                "sharedData": "{{data.sharedData}}"
            },
            "outputData": {
                "sharedData": ""
            },
            "deps": "js/Ac0DiscussionTileService"
        },
        "addUserObjects": {
            "actionType": "JSFunction",
            "method": "updateSharedDataWithUsersAndActiveView",
            "inputData": {
                "sharedData": "{{data.sharedData}}",
                "userObjects": "{{addUserPanelState.selectedUsers}}"
            },
            "outputData": {
                "sharedData": ""
            },
            "deps": "js/Ac0DiscussionTileService"
        },
        "Ac0ConvCreateNewConvPanel": {
            "actionType": "JSFunction",
            "method": "navigateToCreateCollabObjPanel",
            "inputData": {
                "sharedData": "{{data.sharedData}}"
            },
            "deps": "js/Ac0DiscussionTileService"
        },
        "doAddPartcipantsToParticipantList": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "addUserObjects"
                },
                {
                    "action": "Ac0ConvCreateNewConvPanel"
                }
            ]
        }
    },
    "data": {
        "sharedData": {
            "initialValues": {
                "activeView": "",
                "addedSourceObjects": [],
                "addedUserObjects": [],
                "ckeText": "",
                "isTracked": false,
                "isPrivate": false,
                "trackedStatus": "",
                "trackedPriority": "",
                "snapshotBeingEdit": null,
                "renderTextbox": false,
                "currentSelectedSnapshot": null,
                "updateSnapshotOnDiscussion": false,
                "beingEdited": false
            },
            "meta": {
                "activeView": {
                    "field": "true",
                    "type": "STRING"
                },
                "addedSourceObjects": {
                    "field": true,
                    "type": "OBJECTARRAY"
                },
                "addedUserObjects": {
                    "field": true,
                    "type": "OBJECTARRAY"
                },
                "ckeText": {
                    "field": true,
                    "type": "STRING"
                },
                "isTracked": {
                    "field": true,
                    "type": "BOOLEAN"
                },
                "isPrivate": {
                    "field": true,
                    "type": "BOOLEAN"
                },
                "trackedStatus": {
                    "field": true,
                    "type": "STRING"
                },
                "trackedPriority": {
                    "field": true,
                    "type": "STRING"
                },
                "beingEdited": {
                    "field": true,
                    "type": "BOOLEAN"
                }
            }
        },
        "addUserPanelState": {
            "initialValues": {
                "provider": "Awp0PeoplePickerProvider",
                "selectionModelMode": "multiple",
                "criteria": {
                    "providerContentType": "User",
                    "searchString": "*"
                },
                "selectedUsers": "",
                "isAddButtonNeeded": true
            },
            "meta": {
                "selectedUsers": {
                    "field": "true",
                    "type": "OBJECTARRAY"
                }
            }
        },
        "universalData": {
            "selectedObj": {}
        }
    },
    "functions": {},
    "dataProviders": {},
    "onEvent": [],
    "lifecycleHooks": {
        "onMount": "doUnivConvPanelInit",
        "onUnmount": "doUnivConvPanelUnmount",
        "onUpdate": {
            "action": "doAddPartcipantsToParticipantList",
            "observers": [
                "viewModel.atomicData.addUserPanelState.selectedUsers"
            ]
        }
    },
    "i18n": {
        "conversation": [
            "ActiveCollabDeclarativeMessages"
        ],
        "editProductSnapshot": [
            "ActiveCollabDeclarativeMessages"
        ],
        "addSource": [
            "ActiveCollabDeclarativeMessages"
        ],
        "addParticipant": [
            "ActiveCollabDeclarativeMessages"
        ],
        "newDiscussion": [
            "ActiveCollabDeclarativeMessages"
        ],
        "editHeaderDiscussion": [
            "ActiveCollabDeclarativeMessages"
        ]
    },
    "conditions": {
        "isHostedComponent": {
            "expression": "ctx.aw_hosting_state.currentHostedComponentId === 'com.siemens.splm.clientfx.tcui.xrt.published.Discussion'"
        }
    },
    "_viewModelId": "Ac0UniversalConversationPanel",
    "_uniqueViewModelId": "Ac0UniversalConversationPanel",
    "ctx": {
        "aw_hosting_state": {
            "type": "object"
        },
        "Ac0ConvCtx": {
            "type": "object"
        },
        "panelContext": {
            "type": "object"
        }
    }
};

    /*
    <aw-command-panel caption="i18n.conversation" context="subPanelContext" hide-title="conditions.isHostedComponent">
    <aw-command-sub-panel panel-id="Ac0UnivConvPanelSub" exist-when="data.sharedData.value.activeView === 'Ac0UnivConvPanelSub'" sub-panel-context='{sharedData:data.sharedData, selectionData:props.subPanelContext.selectionData}'></aw-command-sub-panel>
    <aw-navigate-panel title="i18n.newDiscussion" exist-when="data.sharedData.value.activeView === 'Ac0CreateNewCollabObj' && !ctx.Ac0ConvCtx.editConvCtx" panel-id='Ac0CreateNewCollabObj' sub-panel-context='{sharedData: data.sharedData, selectionData:props.subPanelContext.selectionData, createNewConversation: true, occmgmtContext: props.subPanelContext.occContext, subpanelCtx: props.subPanelContext}' action='backToDiscussionsAction'>
    </aw-navigate-panel>
    <aw-navigate-panel title="i18n.editHeaderDiscussion" exist-when="data.sharedData.value.activeView === 'Ac0CreateNewCollabObj' && ctx.Ac0ConvCtx.editConvCtx" panel-id='Ac0CreateNewCollabObj' sub-panel-context='{sharedData: data.sharedData, selectionData:props.subPanelContext.selectionData, createNewConversation: true, occmgmtContext: props.subPanelContext.occContext, subpanelCtx: props.subPanelContext}' action='backToDiscussionsAction'>
    </aw-navigate-panel>
    <aw-navigate-panel title="i18n.addSource"  exist-when="data.sharedData.value.activeView === 'Ac0AddSourceObjectsSub'" panel-id='Ac0AddSourceObjectsSub' sub-panel-context='{sharedData:data.sharedData}' action='backToCreateAction'>
    </aw-navigate-panel>
    <aw-navigate-panel title="i18n.addParticipant" exist-when="data.sharedData.value.activeView === 'AwPeoplePicker'"
    panel-id="AwPeoplePicker" sub-panel-context="{ ...subPanelContext, sharedData:data.sharedData, addUserPanelState : data.addUserPanelState }" action='backToCreateAction'>
    </aw-navigate-panel>
<aw-navigate-panel title='i18n.editProductSnapshot' show-when="data.sharedData.value.activeView === 'ProductSnapshotEditSub'" panel-id='ProductSnapshotEditSub' sub-panel-context='{...ctx.panelContext, snapshotPanelData:data.sharedData}' action='backToCreateAction'>
</aw-navigate-panel>
</aw-command-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwCommandPanel revealAction={actions.reveal} hideTitle={conditions.isHostedComponent} context={subPanelContext} updateActiveView={AwParseService.instance( "{activeView: data.activeView, dispatch: dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} caption={i18n.conversation}>
        <AwCommandSubPanelExistWhen panelId="Ac0UnivConvPanelSub" subPanelContext={AwParseService.instance( "{sharedData:fields.sharedData, selectionData:props.subPanelContext.selectionData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "fields.sharedData.value.activeView === 'Ac0UnivConvPanelSub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwCommandSubPanelExistWhen>
        <AwNavigatePanelExistWhen title={i18n.newDiscussion} panelId="Ac0CreateNewCollabObj" subPanelContext={AwParseService.instance( "{sharedData: fields.sharedData, selectionData:props.subPanelContext.selectionData, createNewConversation: true, occmgmtContext: props.subPanelContext.occContext, subpanelCtx: props.subPanelContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.backToDiscussionsAction} existWhen={AwParseService.instance( "fields.sharedData.value.activeView === 'Ac0CreateNewCollabObj' && !ctx.Ac0ConvCtx.editConvCtx" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwNavigatePanelExistWhen>
        <AwNavigatePanelExistWhen title={i18n.editHeaderDiscussion} panelId="Ac0CreateNewCollabObj" subPanelContext={AwParseService.instance( "{sharedData: fields.sharedData, selectionData:props.subPanelContext.selectionData, createNewConversation: true, occmgmtContext: props.subPanelContext.occContext, subpanelCtx: props.subPanelContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.backToDiscussionsAction} existWhen={AwParseService.instance( "fields.sharedData.value.activeView === 'Ac0CreateNewCollabObj' && ctx.Ac0ConvCtx.editConvCtx" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwNavigatePanelExistWhen>
        <AwNavigatePanelExistWhen title={i18n.addSource} panelId="Ac0AddSourceObjectsSub" subPanelContext={AwParseService.instance( "{sharedData:fields.sharedData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.backToCreateAction} existWhen={AwParseService.instance( "fields.sharedData.value.activeView === 'Ac0AddSourceObjectsSub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwNavigatePanelExistWhen>
        <AwNavigatePanelExistWhen title={i18n.addParticipant} panelId="AwPeoplePicker" subPanelContext={AwParseService.instance( "{ ...subPanelContext, sharedData:fields.sharedData, addUserPanelState : fields.addUserPanelState }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.backToCreateAction} existWhen={AwParseService.instance( "fields.sharedData.value.activeView === 'AwPeoplePicker'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwNavigatePanelExistWhen>
        <AwNavigatePanelShowWhen title={i18n.editProductSnapshot} panelId="ProductSnapshotEditSub" subPanelContext={AwParseService.instance( "{...ctx.panelContext, snapshotPanelData:fields.sharedData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.backToCreateAction} showWhen={AwParseService.instance( "fields.sharedData.value.activeView === 'ProductSnapshotEditSub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwNavigatePanelShowWhen>
    </AwCommandPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;