// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwScrollpanel from 'viewmodel/AwScrollpanelViewModel';
import AwLogo from 'viewmodel/AwLogoViewModel';
import AwForm from 'viewmodel/AwFormViewModel';
import AwCheckbox from 'viewmodel/AwCheckboxViewModel';
import AwButton from 'viewmodel/AwButtonViewModel';
    import { EnableWhen } from 'js/hocCollection';
const AwButtonEnableWhen = EnableWhen(AwButton);
AwButtonEnableWhen.displayName = 'AwButtonEnableWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/geographyData.service', () => import('js/geographyData.service'));
registerDynImportEntry('js/sessionManager.service', () => import('js/sessionManager.service'));
registerDynImportEntry('js/gdprConsentData.service', () => import('js/gdprConsentData.service'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-checkbox.directive",
        "js/aw-button.directive",
        "js/aw-form.directive",
        "js/aw-logo.directive",
        "js/aw-scrollpanel.directive"
    ],
    "actions": {
        "NextPage": {
            "actionType": "JSFunction",
            "method": "continueToNextPage",
            "deps": "js/geographyData.service",
            "events": {}
        },
        "signOut": {
            "actionType": "JSFunction",
            "method": "terminateSession",
            "deps": "js/sessionManager.service",
            "events": {}
        },
        "continueSignin": {
            "actionType": "TcSoaService",
            "serviceName": "Administration-2018-11-OrganizationManagement",
            "method": "recordUserConsent",
            "inputData": {
                "userConsent": "{{data.agreeCheck.dbValue}}"
            },
            "events": {
                "success": [
                    {
                        "name": "nextPage"
                    }
                ]
            }
        },
        "reveal": {
            "actionType": "TcSoaService",
            "serviceName": "Administration-2018-11-OrganizationManagement",
            "method": "getUserConsentStatement",
            "headerState": {
                "unloadObjects": true
            },
            "inputData": {},
            "outputData": {
                "userConsentStatement": "{{function:setConsentStatement}}"
            },
            "events": {},
            "deps": "js/gdprConsentData.service"
        }
    },
    "lifecycleHooks": {
        "onMount": "reveal"
    },
    "functions": {},
    "onEvent": [
        {
            "eventId": "nextPage",
            "action": "NextPage"
        }
    ],
    "data": {
        "consentStatement": {},
        "agreeCheck": {
            "displayName": "{{i18n.GDPR_CHECKBOX_LABEL}}",
            "type": "BOOLEAN",
            "isRequired": "true",
            "dbValue": "false",
            "dispValue": "{{i18n.GDPR_CHECKBOX_LABEL}}",
            "labelPosition": "PROPERTY_LABEL_AT_RIGHT"
        }
    },
    "messages": {},
    "conditions": {},
    "i18n": {
        "BRAND_NAME": [
            "geographyMessages"
        ],
        "SIGN_OUT_TEXT": [
            "geographyMessages"
        ],
        "CONTINUE_TEXT": [
            "geographyMessages"
        ],
        "GEOGRAPHY_SECTION_TITLE": [
            "geographyMessages"
        ],
        "GEOGRAPHY_SECTION_PROMPT": [
            "geographyMessages"
        ],
        "CONFIDENTIAL_SECTION_TITLE": [
            "geographyMessages"
        ],
        "CONFIDENTIAL_CHECKBOX_LABEL": [
            "geographyMessages"
        ],
        "GDPR_CHECKBOX_LABEL": [
            "geographyMessages"
        ],
        "INITIAL_LIST_VALUE": [
            "geographyMessages"
        ]
    },
    "dataProviders": {},
    "_viewModelId": "gdprConsent",
    "_uniqueViewModelId": "gdprConsent",
    "ctx": {}
};

    /*
    <aw-scrollpanel>
    <div class="aw-geography-mainView">
        <div class="aw-geography-pagesHeader">
            <h2>{{i18n.BRAND_NAME}}</h2>
        </div>
        <div class="aw-geography-logoImage">
            <aw-logo></aw-logo>
        </div>
        <div class ="aw-layout-subLocation afx-content-background aw-flex-row aw-layout-flexbox afx-base-parentElement aw-geography-cornerRadius">
            <aw-form name="myForm">
                <div class="aw-consent-section">
                    <div id="ConsentStatement" class="aw-consent-statement aw-base-scrollPanel"></div>
                </div>
                <div class="aw-layout-panelFooter aw-geographyFooter-buttons">
                    <div class="aw-geography-checkbox">
                        <aw-checkbox prop="data.agreeCheck"></aw-checkbox>
                    </div>
                    <aw-button enable-when="data.listBox.dbValue !== '' && data.agreeCheck.dbValue===true" action ="continueSignin" >{{i18n.CONTINUE_TEXT}}</aw-button>
                    <aw-button action = "signOut">{{i18n.SIGN_OUT_TEXT}}</aw-button>
                </div>
            </aw-form>
        </div>
    </div>
</aw-scrollpanel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwScrollpanel>
        <div className="aw-geography-mainView">
            <div className="aw-geography-pagesHeader">
                <h2>
                    {AwParseService.instance( "i18n.BRAND_NAME" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </h2>
            </div>
            <div className="aw-geography-logoImage">
                <AwLogo>
                </AwLogo>
            </div>
            <div className="aw-layout-subLocation afx-content-background aw-flex-row aw-layout-flexbox afx-base-parentElement aw-geography-cornerRadius">
                <AwForm name="myForm">
                    <div className="aw-consent-section">
                        <div id="ConsentStatement" className="aw-consent-statement aw-base-scrollPanel">
                        </div>
                    </div>
                    <div className="aw-layout-panelFooter aw-geographyFooter-buttons">
                        <div className="aw-geography-checkbox">
                            <AwCheckbox {...getField("data.agreeCheck", fields, $index, null, null )}>
                            </AwCheckbox>
                        </div>
                        <AwButtonEnableWhen action={actions.continueSignin} enableWhen={AwParseService.instance( "data.listBox.dbValue !== '' && data.agreeCheck.dbValue===true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            {AwParseService.instance( "i18n.CONTINUE_TEXT" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                        </AwButtonEnableWhen>
                        <AwButton action={actions.signOut}>
                            {AwParseService.instance( "i18n.SIGN_OUT_TEXT" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                        </AwButton>
                    </div>
                </AwForm>
            </div>
        </div>
    </AwScrollpanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;