// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwCommandPanel from 'viewmodel/AwCommandPanelViewModel';
import ObjectInfoPrimarySub from 'viewmodel/ObjectInfoPrimarySubViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const ObjectInfoPrimarySubVisibleWhen = VisibleWhen(ObjectInfoPrimarySub);
ObjectInfoPrimarySubVisibleWhen.displayName = 'ObjectInfoPrimarySubVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {
        "xrtState": {
            "initialValues": {},
            "meta": {
                "xrtVMO": {
                    "field": "true",
                    "type": "viewModelObject"
                }
            }
        },
        "activeView": "Awp0HostedObjectInfoPrimarySub"
    },
    "editHandlers": {
        "Awp0HostedObjectInfoPrimary": {
            "context": "INFO_PANEL_CONTEXT",
            "dataSource": {
                "xrtVMO": "{{data.xrtState}}"
            }
        }
    },
    "i18n": {
        "objectInfoLabel": [
            "XRTMessages"
        ]
    },
    "_viewModelId": "Awp0HostedObjectInfo",
    "_uniqueViewModelId": "Awp0HostedObjectInfo",
    "ctx": {}
};

    /*
    <aw-command-panel caption="i18n.objectInfoLabel" hide-title="true">
    <object-info-primary-sub visible-when="data.activeView == 'Awp0HostedObjectInfoPrimarySub'" xrt-state="data.xrtState"
            edit-handler="editHandlers.Awp0HostedObjectInfoPrimary" editing="{{editHandlers.Awp0HostedObjectInfoPrimary._editing}}"></object-info-primary-sub>
</aw-command-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwCommandPanel revealAction={actions.reveal} hideTitle={true} updateActiveView={AwParseService.instance( "{activeView: data.activeView, dispatch: dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} caption={i18n.objectInfoLabel}>
        <ObjectInfoPrimarySubVisibleWhen xrtState={AwParseService.instance( "fields.xrtState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} editHandler={AwParseService.instance( "editHandlers.Awp0HostedObjectInfoPrimary" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} editing={AwParseService.instance( "editHandlers.Awp0HostedObjectInfoPrimary._editing" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} visibleWhen={AwParseService.instance( "data.activeView == 'Awp0HostedObjectInfoPrimarySub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </ObjectInfoPrimarySubVisibleWhen>
    </AwCommandPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;