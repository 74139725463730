// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/tcmaGlobalSearchService', () => import('js/tcmaGlobalSearchService'));
registerDynImportEntry('js/commandPanel.service', () => import('js/commandPanel.service'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "lifecycleHooks": {
        "onMount": "getAllowedObjectTypesAndActivateTcmaGlobalSearch"
    },
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "type": "search",
                "vm": "TcmaSearchBreadcrumb"
            },
            "clientScopeURI": "Awp0SearchResults",
            "nameToken": "tcmaGlobalSearchLocation",
            "viewBase": "AwSearchResults",
            "context": {
                "search": {
                    "provider": "Awp0FullTextSearchProvider",
                    "maxToLoad": 50,
                    "maxToReturn": 50,
                    "showChartColorBars": false,
                    "hideInContentSearchBox": true,
                    "showLoadingText": true,
                    "showNoCriteriaMessage": true,
                    "criteria": {
                        "searchFromLocation": "global"
                    }
                },
                "typeFilterString": ""
            },
            "viewModes": {
                "ListView": {
                    "primaryWorkArea": "list",
                    "secondaryWorkArea": false
                },
                "TableView": {
                    "primaryWorkArea": "table",
                    "secondaryWorkArea": false
                }
            },
            "defaultDisplayMode": "ListView",
            "isXrtApplicable": true,
            "label": {
                "source": "/i18n/tcmaClassificationMessages",
                "key": "globalSearchTitle"
            },
            "headerPreference": {
                "hideGlobalSearch": true,
                "hideLogo": true
            },
            "awSubLocationPref": {
                "hideRightWall": true,
                "hideFooter": true,
                "embeddedLocationView": true
            }
        }
    },
    "actions": {
        "getAllowedObjectTypesAndActivateTcmaGlobalSearch": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "getAllowedObjectTypes"
                },
                {
                    "action": "activateTcmaGlobalSearch"
                }
            ]
        },
        "getAllowedObjectTypes": {
            "actionType": "JSFunctionAsync",
            "method": "getAllowedObjectTypes",
            "inputData": {},
            "outputData": {
                "_provider.context.typeFilterString": ""
            },
            "deps": "js/tcmaGlobalSearchService"
        },
        "activateTcmaGlobalSearch": {
            "actionType": "JSFunction",
            "method": "activateCommandPanel",
            "deps": "js/commandPanel.service",
            "inputData": {
                "commandId": "TcmaGlobalSearch",
                "location": "aw_navigation",
                "context": "{{commandContext.context.typeFilterString}}"
            }
        }
    },
    "_viewModelId": "TcmaGlobalSearchSubLoc",
    "_uniqueViewModelId": "TcmaGlobalSearchSubLoc",
    "ctx": {}
};

    /*
    <aw-search-page class="aw-tcmaClassification-header" tabsdata="subPanelContext.tabsData" provider="data._provider" search-state="data.searchState"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage className="aw-tcmaClassification-header" tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchState={AwParseService.instance( "data.searchState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;