// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "vm": "AwSearchBreadcrumb"
            },
            "priority": 2,
            "label": {
                "source": "/i18n/SubscriptionMessages",
                "key": "mySubscriptionAddedAsFollowersSubLocationTitle"
            },
            "clientScopeURI": "Sub0FollowedSubscriptions",
            "nameToken": "com.siemens.splm.client.subscription.follow.internal.xrt:mySubscriptionsSubLocation",
            "context": {
                "search": {
                    "hideInContentSearchBox": true,
                    "provider": "Sub0SubscriptionProvider",
                    "criteria": {
                        "ImanSubscription": "AddedAsFollowers",
                        "searchFromLocation": "global"
                    },
                    "sortType": "Alphabetical",
                    "sortCriteria": [
                        {
                            "fieldName": "fnd0Name",
                            "sortDirection": "ASC"
                        }
                    ],
                    "maxToLoad": 50,
                    "maxToReturn": 200,
                    "tableViewProperties": [
                        "fnd0Name",
                        "target",
                        "event_type",
                        "subscriber",
                        "expiration_date",
                        "fnd0Frequency",
                        "fnd0Priority",
                        "fnd0IsActive"
                    ],
                    "showLoadingText": true
                }
            },
            "viewBase": "subscription",
            "viewModes": {
                "SummaryView": {
                    "primaryWorkArea": "list",
                    "secondaryWorkArea": true
                },
                "TableSummaryView": {
                    "primaryWorkArea": "table",
                    "secondaryWorkArea": true
                }
            }
        }
    },
    "_viewModelId": "FollowedSubscriptionsPage",
    "_uniqueViewModelId": "FollowedSubscriptionsPage",
    "ctx": {}
};

    /*
    <aw-search-page tabsdata="subPanelContext.tabsData" provider="data._provider"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;