// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Awp0SearchHighlightingService', () => import('js/Awp0SearchHighlightingService'));
registerDynImportEntry('js/searchCommonUtils', () => import('js/searchCommonUtils'));
registerDynImportEntry('js/awSearchLocationFilterPanelService', () => import('js/awSearchLocationFilterPanelService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "lifecycleHooks": {
        "onMount": "activateSearchFilterActionBatchJob",
        "onUnmount": "handleUnmount",
        "onUpdate": {
            "action": "openCloseFilterPanelAction",
            "observers": [
                "viewModel.searchState.categories"
            ]
        }
    },
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "type": "search",
                "vm": "AwSearchBreadcrumb"
            },
            "clientScopeURI": "Awp0SearchResults",
            "nameToken": "com.siemens.splm.client.search:SearchResultsSubLocation",
            "context": {
                "search": {
                    "loadDataAction": "loadSearchData",
                    "provider": "Awp0FullTextSearchProvider",
                    "sortType": "Priority",
                    "chartTitle": {
                        "source": "/i18n/SearchMessages",
                        "key": "SearchResultsChartTitle"
                    },
                    "criteria": {
                        "forceThreshold": "true",
                        "searchFromLocation": "global",
                        "dcpSortByDataProvider": "true",
                        "getGroupedCategories": "true"
                    },
                    "showLoadingText": true,
                    "showChartColorBars": true,
                    "selectFirstObjectPreference": "AWC_select_firstobject_inSearchLocation",
                    "showNoCriteriaMessage": true,
                    "numberOfCategoriesShownInGroupPreference": "AWC_Search_Filter_Panel_Number_Of_Categories_Shown_Inside_Each_Group",
                    "numberOfMaxGroupsShownPreference": "AWC_Search_Filter_Panel_Number_Of_Groups_Shown"
                }
            },
            "viewBase": "AwSearchResults"
        },
        "searchState": {
            "initialValues": {
                "filterString": "",
                "selectedFiltersString": ""
            },
            "meta": {
                "categories": [
                    {
                        "filterValues": [
                            {
                                "selected": {
                                    "field": "true",
                                    "type": "BOOLEAN"
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    "actions": {
        "activateSearchFilterActionBatchJob": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "validateCriteriaAndFilterString"
                },
                {
                    "action": "readAutoOpenFilterPanelPrefValue",
                    "condition": "data.openFilterPanel"
                },
                {
                    "action": "activateSearchFilterAction",
                    "condition": "data.openFilterPanel && data.autoOpenFilterPanel"
                },
                {
                    "action": "initHighlight"
                }
            ]
        },
        "initHighlight": {
            "actionType": "JSFunction",
            "method": "initHighlight",
            "inputData": {},
            "deps": "js/Awp0SearchHighlightingService"
        },
        "handleUnmount": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "resetHighlight"
                },
                {
                    "action": "fireClpEventOnSearchUnmount",
                    "condition": "ctx.isCLPInstalled === true"
                }
            ]
        },
        "fireClpEventOnSearchUnmount": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "clp.customerListeningSurveyEvent",
                        "eventData": {
                            "source": "AwSearchResults"
                        }
                    }
                ]
            }
        },
        "resetHighlight": {
            "actionType": "JSFunction",
            "method": "resetHighlight",
            "inputData": {},
            "deps": "js/Awp0SearchHighlightingService"
        },
        "validateCriteriaAndFilterString": {
            "actionType": "JSFunction",
            "method": "validateCriteriaAndFilterString",
            "inputData": {},
            "outputData": {
                "openFilterPanel": ""
            },
            "deps": "js/searchCommonUtils"
        },
        "openCloseFilterPanelAction": {
            "actionType": "JSFunction",
            "method": "openCloseFilterPanelAction",
            "inputData": {
                "searchState": "{{data.searchState}}"
            },
            "deps": "js/awSearchLocationFilterPanelService"
        },
        "readAutoOpenFilterPanelPrefValue": {
            "actionType": "JSFunction",
            "method": "readAutoOpenFilterPanelPrefValue",
            "inputData": {},
            "outputData": {
                "autoOpenFilterPanel": ""
            },
            "deps": "js/awSearchLocationFilterPanelService"
        },
        "activateSearchFilterAction": {
            "actionType": "activateCommandPanel",
            "inputData": {
                "commandId": "Awp0SearchFilter",
                "location": "aw_navigation"
            }
        }
    },
    "_viewModelId": "AwSearchResults",
    "_uniqueViewModelId": "AwSearchResults",
    "ctx": {
        "isCLPInstalled": {
            "type": "object"
        }
    }
};

    /*
    <aw-search-page showsearchbox="subPanelContext.showsearchbox" tabsdata="subPanelContext.tabsData" search-box="data.searchBox" search-state="data.searchState" provider="data._provider"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage showsearchbox={AwParseService.instance( "subPanelContext.showsearchbox" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchBox={AwParseService.instance( "data.searchBox" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchState={AwParseService.instance( "fields.searchState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;