// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import Pca0FullScreenConfiguration from 'viewmodel/Pca0FullScreenConfigurationViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "_viewModelId": "Pca0VariantConfiguration",
    "_uniqueViewModelId": "Pca0VariantConfiguration",
    "ctx": {}
};

    /*
    <!-- View created to wrap FSC view and to fix changes required for XRT view
As when we adds FSC view using XRT, XRT adds extra DOM elements which affects visibility of FSC as component
It adds scroll bar on full component, i.e. on FSC, instead of Models, Features and Summary View -->
<pca0-full-screen-configuration sub-panel-context="props.subPanelContext"></pca0-full-screen-configuration>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <Pca0FullScreenConfiguration subPanelContext={AwParseService.instance( "props.subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </Pca0FullScreenConfiguration>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;