/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/V4B_MaturityGreenObjectService
 */

/**
 * Dummy alert.
 * @param {String} text - text to display
 */

import appCtxService from "js/appCtxService";
import eventBus from "js/eventBus";

let exports = {};

export let MaturityGreenObject = function (data) {
  let valueboolean = data ? false : true;
  appCtxService.updateCtx("FilterOutGreenMaturity", valueboolean);
  eventBus.publish("loadContentForGreenMaturity");
};

export let MaturityGreenObjects = function (data) {
  let valueboolean = !data;
  appCtxService.updateCtx("FilterOutGreenMaturity", valueboolean);
  eventBus.publish("loadContentForGreenMaturity");
};

export let registerSubPanelContext = function (subPanelContext) {
  let tabId;
  if (subPanelContext.subPanelContext && subPanelContext.subPanelContext.activeTab && subPanelContext.subPanelContext.activeTab.pageId) {
    tabId = subPanelContext.subPanelContext.activeTab.pageId;
  } else if (subPanelContext.activeTab && subPanelContext.activeTab.pageId) {
    tabId = subPanelContext.activeTab.pageId;
  } else{
    tabId= "tc_xrt_details";
  }
  return appCtxService.updateCtx("locationContext.secondaryActiveTabId", tabId);
};

export let unRegisterSubPanelContext = function (subPanelContext) {
  let tabId =subPanelContext.activeTab.pageId;
  return appCtxService.unRegisterCtx("locationContext.secondaryActiveTabId", tabId);
};

exports = {
  MaturityGreenObject,
  MaturityGreenObjects,
  registerSubPanelContext,
  unRegisterSubPanelContext
};

export default exports;
