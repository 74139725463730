// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/advancedSearchService', () => import('js/advancedSearchService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "type": "search",
                "vm": "AwAdvancedSearchBreadcrumb",
                "noBreadCrumb": "true",
                "contextName": "advancedSearch",
                "noCriteriaSpecifiedMessage": {
                    "source": "/i18n/SearchMessages",
                    "key": "advancedSearchPWADefaultMessage"
                },
                "noResultsFoundMessage": {
                    "source": "/i18n/SearchMessages",
                    "key": "advancedSearchNoResultsFound"
                },
                "resultsFoundMessage": {
                    "source": "/i18n/SearchMessages",
                    "key": "advancedSearchResultsFound"
                }
            },
            "source": "primaryWorkArea",
            "searchPrompt": {
                "source": "/i18n/SearchMessages",
                "key": "advancedSearchPWADefaultMessage"
            },
            "clientScopeURI": "Awp0AdvancedSearch",
            "nameToken": "com.siemens.splm.client.search:AdvancedSearchSubLocation",
            "context": {
                "search": {
                    "chartTitle": {
                        "source": "/i18n/SearchMessages",
                        "key": "SearchResultsChartTitle"
                    },
                    "hideInContentSearchBox": true
                }
            },
            "viewBase": "AwAdvancedSearch"
        },
        "searchState": {
            "initialValues": {
                "provider": "Awp0SavedQuerySearchProvider",
                "advancedSearchCriteria": "",
                "savedQuery": {
                    "name": "",
                    "value": ""
                },
                "selectFirstObjectPreference": "AWC_select_firstobject_inSearchLocation"
            },
            "meta": {}
        }
    },
    "actions": {
        "initialize": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeSearchState"
                },
                {
                    "action": "activateAdvancedSearchAction"
                }
            ]
        },
        "initializeSearchState": {
            "actionType": "JSFunction",
            "method": "createStateFromUrl",
            "inputData": {
                "searchState": "{{data.searchState}}",
                "searchStateUpdater": "{{data.updateAtomicData}}"
            },
            "deps": "js/advancedSearchService"
        },
        "activateAdvancedSearchAction": {
            "actionType": "activateCommandPanel",
            "inputData": {
                "commandId": "Awp0AdvancedSearch",
                "location": "aw_navigation"
            }
        }
    },
    "lifecycleHooks": {
        "onMount": "initialize"
    },
    "_viewModelId": "AwAdvancedSearch",
    "_uniqueViewModelId": "AwAdvancedSearch",
    "ctx": {}
};

    /*
    <aw-search-page showsearchbox="subPanelContext.showsearchbox" search-state="data.searchState" skip-url="true" tabsdata="subPanelContext.tabsData" provider="data._provider"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage showsearchbox={AwParseService.instance( "subPanelContext.showsearchbox" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchState={AwParseService.instance( "fields.searchState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} skipUrl={AwParseService.instance( "true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;