// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwCommandPanel from 'viewmodel/AwCommandPanelViewModel';
import AwCommandSubPanel from 'viewmodel/AwCommandSubPanelViewModel';
import AwNavigatePanel from 'viewmodel/AwNavigatePanelViewModel';
    import { ShowWhen,ExistWhen } from 'js/hocCollection';
const AwCommandSubPanelShowWhen = ShowWhen(AwCommandSubPanel);
const DivExistWhen = ExistWhen('div');
const AwNavigatePanelExistWhen = ExistWhen(AwNavigatePanel);
AwCommandSubPanelShowWhen.displayName = 'AwCommandSubPanelShowWhen';
DivExistWhen.displayName = 'DivExistWhen';
AwNavigatePanelExistWhen.displayName = 'AwNavigatePanelExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/addObjectUtils', () => import('js/addObjectUtils'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-command-panel.directive",
        "js/aw-command-sub-panel.directive",
        "js/visible-when.directive"
    ],
    "data": {
        "activeState": {
            "initialValues": {
                "activeView": "Cm1CreateChangeSub",
                "context": {}
            },
            "meta": {
                "activeView": {
                    "field": "true",
                    "type": "STRING"
                },
                "context": {
                    "field": "true",
                    "type": "OBJECT"
                }
            }
        },
        "projectState": {
            "initialValues": {
                "selectedProjects": [],
                "triggerAssign": false
            },
            "meta": {
                "selectedProjects": {
                    "field": "true",
                    "type": "OBJECT"
                },
                "triggerAssign": {
                    "field": "true",
                    "type": "BOOLEAN"
                }
            }
        },
        "attachmentsState": {
            "initialValues": {
                "addAttachments": [],
                "updateAttachmentsSection": false
            },
            "meta": {
                "addAttachments": {
                    "field": "true",
                    "type": "OBJECT"
                },
                "updateAttachmentsSection": {
                    "field": "true",
                    "type": "BOOLEAN"
                }
            }
        },
        "addUserPanelState": {
            "initialValues": {
                "provider": "Awp0PeoplePickerProvider",
                "selectionModelMode": "single",
                "criteria": {
                    "providerContentType": "GroupMember",
                    "searchString": "*",
                    "selectedObject": "",
                    "searchSubGroup": "true",
                    "group": "",
                    "role": "",
                    "participantType": ""
                },
                "defaultCriteria": {
                    "providerContentType": "User",
                    "searchSubGroup": "true",
                    "group": "",
                    "role": "",
                    "participantType": ""
                },
                "selectedUsers": "",
                "isAddButtonNeeded": true,
                "triggerUpdateSearchCriteria": false
            },
            "meta": {
                "selectedUsers": {
                    "field": "true",
                    "type": "OBJECTARRAY"
                }
            }
        }
    },
    "actions": {
        "closePanel": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "complete",
                        "eventData": {
                            "source": "toolAndInfoPanel"
                        }
                    }
                ]
            }
        },
        "updateActiveView": {
            "actionType": "JSFunction",
            "method": "setActiveView",
            "inputData": {
                "stateName": "{{fields.activeState}}",
                "destPanelId": "Cm1CreateChangeSub"
            },
            "deps": "js/addObjectUtils"
        }
    },
    "onEvent": [
        {
            "eventId": "primaryWorkArea.selectionChangeEvent",
            "action": "closePanel"
        }
    ],
    "conditions": {
        "isHostedComponent": {
            "expression": "ctx.aw_hosting_state.currentHostedComponentId === 'com.siemens.splm.client.change.CreateChangeComponent'"
        }
    },
    "i18n": {
        "CreateChange": [
            "CreateChangeMessages"
        ],
        "DeriveChange": [
            "CreateChangeMessages"
        ],
        "BACK_BUTTON_TITLE": [
            "BaseMessages"
        ]
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        }
    },
    "_viewModelId": "Cm1ShowCreateChange",
    "_uniqueViewModelId": "Cm1ShowCreateChange",
    "ctx": {
        "aw_hosting_state": {
            "type": "object"
        }
    }
};

    /*
    <aw-command-panel caption="i18n.CreateChange" context="subPanelContext" hide-title="conditions.isHostedComponent">
    <aw-command-sub-panel panel-id="Cm1CreateChangeSub" show-when="data.activeState.value.activeView=='Cm1CreateChangeSub'" sub-panel-context="{ ...subPanelContext, activeState: data.activeState, projectState: data.projectState, attachmentsState: data.attachmentsState, addUserPanelState: data.addUserPanelState }" ></aw-command-sub-panel>
    <div class="aw-layout-flexColumn" exist-when="data.activeState.value.activeView==='AddReferenceSub'">
        <aw-navigate-panel title="i18n.BACK_BUTTON_TITLE" pre-panel-id="Cm1ShowCreateChange" panel-id="AddReferenceSub" action="updateActiveView" sub-panel-context="fields.activeState.value.context" active-state="data.activeState"></aw-navigate-panel>
    </div>
    <div class="aw-layout-flexColumn" exist-when="data.activeState.value.activeView==='Cm1AssignProjectSub'">
        <aw-navigate-panel title="i18n.BACK_BUTTON_TITLE" pre-panel-id="Cm1ShowCreateChange" panel-id="Cm1AssignProjectSub" action="updateActiveView" sub-panel-context="{ ...subPanelContext, projectState: data.projectState }" active-state="data.activeState"></aw-navigate-panel>
    </div>
    <aw-navigate-panel title="i18n.BACK_BUTTON_TITLE" exist-when="data.activeState.value.activeView==='Cm1AddAttachmentSub'" pre-panel-id="Cm1ShowCreateChange" panel-id="Cm1AddAttachmentSub"
        action="updateActiveView" sub-panel-context="{ ...subPanelContext, attachmentsState: data.attachmentsState }"
        active-state="data.activeState"></aw-navigate-panel>
    <aw-navigate-panel title="i18n.BACK_BUTTON_TITLE" exist-when="data.activeState.value.activeView === 'AwPeoplePicker'" 
        active-state="data.activeState" action="updateActiveView" pre-panel-id="Cm1ShowCreateChange" panel-id="AwPeoplePicker" sub-panel-context="{ ...subPanelContext, addUserPanelState : data.addUserPanelState }"></aw-navigate-panel>

</aw-command-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwCommandPanel revealAction={actions.reveal} hideTitle={conditions.isHostedComponent} context={subPanelContext} updateActiveView={AwParseService.instance( "{activeView: data.activeView, dispatch: dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} caption={i18n.CreateChange}>
        <AwCommandSubPanelShowWhen panelId="Cm1CreateChangeSub" subPanelContext={AwParseService.instance( "{ ...subPanelContext, activeState: fields.activeState, projectState: fields.projectState, attachmentsState: fields.attachmentsState, addUserPanelState: fields.addUserPanelState }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} showWhen={AwParseService.instance( "fields.activeState.value.activeView=='Cm1CreateChangeSub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwCommandSubPanelShowWhen>
        <DivExistWhen className="aw-layout-flexColumn" existWhen={AwParseService.instance( "fields.activeState.value.activeView==='AddReferenceSub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwNavigatePanel title={i18n.BACK_BUTTON_TITLE} prePanelId="Cm1ShowCreateChange" panelId="AddReferenceSub" action={actions.updateActiveView} subPanelContext={AwParseService.instance( "fields.activeState.value.context" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} activeState={AwParseService.instance( "fields.activeState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwNavigatePanel>
        </DivExistWhen>
        <DivExistWhen className="aw-layout-flexColumn" existWhen={AwParseService.instance( "fields.activeState.value.activeView==='Cm1AssignProjectSub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwNavigatePanel title={i18n.BACK_BUTTON_TITLE} prePanelId="Cm1ShowCreateChange" panelId="Cm1AssignProjectSub" action={actions.updateActiveView} subPanelContext={AwParseService.instance( "{ ...subPanelContext, projectState: fields.projectState }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} activeState={AwParseService.instance( "fields.activeState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwNavigatePanel>
        </DivExistWhen>
        <AwNavigatePanelExistWhen title={i18n.BACK_BUTTON_TITLE} prePanelId="Cm1ShowCreateChange" panelId="Cm1AddAttachmentSub" action={actions.updateActiveView} subPanelContext={AwParseService.instance( "{ ...subPanelContext, attachmentsState: fields.attachmentsState }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} activeState={AwParseService.instance( "fields.activeState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "fields.activeState.value.activeView==='Cm1AddAttachmentSub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwNavigatePanelExistWhen>
        <AwNavigatePanelExistWhen title={i18n.BACK_BUTTON_TITLE} activeState={AwParseService.instance( "fields.activeState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.updateActiveView} prePanelId="Cm1ShowCreateChange" panelId="AwPeoplePicker" subPanelContext={AwParseService.instance( "{ ...subPanelContext, addUserPanelState : fields.addUserPanelState }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "fields.activeState.value.activeView === 'AwPeoplePicker'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwNavigatePanelExistWhen>
    </AwCommandPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;