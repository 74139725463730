// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwScrollpanel from 'viewmodel/AwScrollpanelViewModel';
import AwTabSet from 'viewmodel/AwTabSetViewModel';
import Awp0ClsClassesOverview from 'viewmodel/Awp0ClsClassesOverviewViewModel';
import Awp0ClsClassesAttributes from 'viewmodel/Awp0ClsClassesAttributesViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const Awp0ClsClassesOverviewVisibleWhen = VisibleWhen(Awp0ClsClassesOverview);
const Awp0ClsClassesAttributesVisibleWhen = VisibleWhen(Awp0ClsClassesAttributes);
Awp0ClsClassesOverviewVisibleWhen.displayName = 'Awp0ClsClassesOverviewVisibleWhen';
Awp0ClsClassesAttributesVisibleWhen.displayName = 'Awp0ClsClassesAttributesVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/classifyAdminService', () => import('js/classifyAdminService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "ctx": {
        "clsAdmin.classSystem": {
            "type": "object"
        }
    },
    "lifecycleHooks": {
        "onMount": "initializeClassesSWA",
        "onUpdate": [
            {
                "action": "updateClassesSWA",
                "observers": [
                    "props.subPanelContext.context.searchState.propertiesSWA"
                ]
            }
        ]
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        }
    },
    "data": {
        "tabModels": [
            {
                "tabKey": "Awp0ClsClassesOverview",
                "name": "{{i18n.overview}}"
            },
            {
                "tabKey": "Awp0ClsClassesAttributes",
                "name": "{{i18n.classAttributes}}"
            }
        ]
    },
    "actions": {
        "updateClassesSWA": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeClassesSWA"
                }
            ]
        },
        "initializeClassesSWA": {
            "actionType": "JSFunction",
            "method": "initializeSWA",
            "inputData": {
                "subPanelContext": "{{subPanelContext.context.searchState}}",
                "subLocationName": "Classes",
                "optionsTitle": "{{i18n.optionsTitle}}",
                "aliasTitle": "{{i18n.aliasTitle}}"
            },
            "outputData": {
                "currentSecData": "currentSecData",
                "aspects": "aspects",
                "property": "property",
                "classAttributes": "classAttributes",
                "hasClassAttributes": "hasClassAttributes",
                "referenceLinks": "referenceLinks",
                "attrprop": "attrprop",
                "attrSelected": false,
                "optionsTitle": "optionsTitle",
                "aliasTitle": "aliasTitle",
                "options": "options",
                "hasOptions": "hasOptions",
                "alias": "alias",
                "hasAlias": "hasAlias",
                "hasImages": "hasImages",
                "selectedUid": "selectedUid",
                "multiSiteData": "multiSiteData"
            },
            "deps": "js/classifyAdminService"
        }
    },
    "dataProviders": {},
    "columnProviders": {},
    "grids": {},
    "onEvent": [],
    "conditions": {
        "showOverviewPage": {
            "expression": "data.selectedTab.tabKey === 'Awp0ClsClassesOverview'"
        },
        "showAttributesPage": {
            "expression": "data.selectedTab.tabKey === 'Awp0ClsClassesAttributes'"
        }
    },
    "i18n": {
        "classAttributes": [
            "ClassificationAdminMessages"
        ],
        "overview": [
            "ClassificationAdminMessages"
        ]
    },
    "_viewModelId": "Awp0ClsClassesSWA",
    "_uniqueViewModelId": "Awp0ClsClassesSWA"
};

    /*
    <aw-scrollpanel class="aw-clsadmin-classes">
    <aw-tab-set tabs="data.tabModels" sub-panel-context="props.subPanelContext">
        <awp0-cls-classes-overview sub-panel-context="props.subPanelContext" visible-when="conditions.showOverviewPage"></awp0-cls-classes-overview>
        <awp0-cls-classes-attributes sub-panel-context="props.subPanelContext" visible-when="conditions.showAttributesPage"></awp0-cls-classes-attributes>
    </aw-tab-set>
</aw-scrollpanel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwScrollpanel className="aw-clsadmin-classes">
        <AwTabSet  updateHook={AwParseService.instance( "{data, conditions,dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} tabs={AwParseService.instance( "data.tabModels" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "props.subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <Awp0ClsClassesOverviewVisibleWhen subPanelContext={AwParseService.instance( "props.subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} visibleWhen={AwParseService.instance( "conditions.showOverviewPage" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </Awp0ClsClassesOverviewVisibleWhen>
            <Awp0ClsClassesAttributesVisibleWhen subPanelContext={AwParseService.instance( "props.subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} visibleWhen={AwParseService.instance( "conditions.showAttributesPage" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </Awp0ClsClassesAttributesVisibleWhen>
        </AwTabSet>
    </AwScrollpanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;