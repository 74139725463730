// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import Ac0DiscussionTile from 'viewmodel/Ac0DiscussionTileViewModel';
import AwDialog from 'viewmodel/AwDialogViewModel';
import AwList from 'viewmodel/AwListViewModel';
import AwDefaultCell from 'viewmodel/AwDefaultCellViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const AwCommandPanelSectionVisibleWhen = VisibleWhen(AwCommandPanelSection);
AwCommandPanelSectionVisibleWhen.displayName = 'AwCommandPanelSectionVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Ac0ConversationService', () => import('js/Ac0ConversationService'));
registerDynImportEntry('js/Ac0ConversationUtils', () => import('js/Ac0ConversationUtils'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "actions": {
        "reveal": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initActionableFeedSummary"
                },
                {
                    "action": "initActionableFeedSummary2"
                }
            ]
        },
        "initActionableFeedSummary": {
            "actionType": "dataProvider",
            "method": "convSourceObjectsDataProvider"
        },
        "initActionableFeedSummary2": {
            "actionType": "dataProvider",
            "method": "convParticipantsDataProvider"
        },
        "initActionableFeedSummary3": {
            "actionType": "dataProvider",
            "method": "convRelatedObjDataProvider"
        },
        "selectionChange": {
            "actionType": "JSFunction",
            "method": "feedPrimaryWorkspaceSelection",
            "inputData": {
                "vmData": "{{data}}",
                "eventData": "{{data.eventData}}",
                "ctx": "{{ctx}}"
            },
            "events": {
                "success": [
                    {
                        "name": "ac0ActionableFeedSummary.selectionChangeComplete"
                    }
                ]
            },
            "deps": "js/Ac0ConversationService"
        },
        "clickObjChipAction": {
            "actionType": "Navigate",
            "navigateTo": "com_siemens_splm_clientfx_tcui_xrt_showObject",
            "navigationParams": {
                "uid": "{{chip.objUid}}"
            }
        },
        "noop": {
            "actionType": "JSFunction",
            "method": "getRandObjId",
            "deps": "js/Ac0ConversationService"
        },
        "loadConvSourceObjects": {
            "actionType": "JSFunctionAsync",
            "method": "loadConvSrcObjs",
            "inputData": {
                "ctx": "{{ctx}}"
            },
            "outputData": {
                "sourceObjectList": "sourceObjectList",
                "numberOfSourceObjects": "numberOfSourceObjects"
            },
            "deps": "js/Ac0ConversationUtils"
        },
        "loadConvParticipants": {
            "actionType": "JSFunctionAsync",
            "method": "loadParticipants",
            "inputData": {
                "ctx": "{{ctx}}"
            },
            "outputData": {
                "participantsList": "participantsList",
                "numberOfParticipants": "numberOfParticipants"
            },
            "deps": "js/Ac0ConversationUtils"
        },
        "loadConvRelatedObjs": {
            "actionType": "JSFunctionAsync",
            "method": "loadRelatedObjs",
            "inputData": {
                "ctx": "{{ctx}}"
            },
            "outputData": {
                "relatedObjectList": "relatedObjectList",
                "numberOfRelatedObjects": "numberOfRelatedObjects"
            },
            "deps": "js/Ac0ConversationUtils"
        },
        "openAc0UniversalConvPanel": {
            "actionType": "dialog",
            "inputData": {
                "options": {}
            }
        }
    },
    "data": {
        "sharedData": {
            "initialValues": {
                "activeView": "",
                "addedSourceObjects": [],
                "addedUserObjects": [],
                "ckeText": "",
                "isTracked": false,
                "isPrivate": false,
                "trackedStatus": "",
                "trackedPriority": "",
                "snapshotBeingEdit": null,
                "renderTextbox": false,
                "currentSelectedSnapshot": null,
                "updateSnapshotOnDiscussion": false,
                "beingEdited": false
            },
            "meta": {
                "activeView": {
                    "field": "true",
                    "type": "STRING"
                },
                "addedSourceObjects": {
                    "field": true,
                    "type": "OBJECTARRAY"
                },
                "addedUserObjects": {
                    "field": true,
                    "type": "OBJECTARRAY"
                },
                "ckeText": {
                    "field": true,
                    "type": "STRING"
                },
                "isTracked": {
                    "field": true,
                    "type": "BOOLEAN"
                },
                "isPrivate": {
                    "field": true,
                    "type": "BOOLEAN"
                },
                "trackedStatus": {
                    "field": true,
                    "type": "STRING"
                },
                "trackedPriority": {
                    "field": true,
                    "type": "STRING"
                },
                "beingEdited": {
                    "field": true,
                    "type": "BOOLEAN"
                }
            }
        },
        "commandPanelSectionData": {
            "displayName": "{{i18n.description}}",
            "type": "STRING",
            "isRequired": "false",
            "isEditable": "false"
        },
        "staticChip": {
            "chipType": "STATIC",
            "labelDisplayName": "Conv 1",
            "labelInternalName": "conv1"
        },
        "placeHolderTextBox": {
            "displayName": "",
            "type": "STRING",
            "isRequired": "true",
            "isEditable": "true"
        },
        "oneStringMsg": {
            "displayName": "",
            "type": "STRING",
            "dbValue": "1"
        },
        "replyPlaceHolder": {
            "displayName": "{{i18n.addReply}}",
            "type": "STRING",
            "isRequired": "false",
            "isEditable": "true"
        },
        "cmdMore": "cmdMore",
        "updateFrom": "ConvLocation",
        "collabStatus": {
            "name": "collabStatus",
            "displayName": "Status",
            "value": "{{props.subPanelContext.selection[0].props.collabStatus}}",
            "displayValue": "{{props.subPanelContext.selection[0].props.collabStatus.displayValues[0]}}",
            "type": "STRING"
        },
        "collabStatusChangeUser": {
            "name": "collabStatusChangeUser",
            "displayName": "StatusChangeUser",
            "value": "{{props.subPanelContext.selection[0].props.collabStatusChangeUser}}",
            "displayValue": "{{props.subPanelContext.selection[0].props.collabStatusChangeUser.displayValues[0]}}",
            "type": "STRING"
        },
        "convPriority": {
            "name": "convPriority",
            "displayName": "Priority",
            "value": "{{props.subPanelContext.selection[0].props.convPriority}}",
            "displayValue": "{{props.subPanelContext.selection[0].props.convPriority.displayValues[0]}}",
            "type": "STRING"
        }
    },
    "ctx": {
        "selected": {
            "type": "object"
        },
        "newConvObj": {
            "type": "object"
        },
        "Ac0ConvCtx": {
            "type": "object"
        }
    },
    "dataParseDefinitions": {},
    "dataProviders": {
        "convSourceObjectsDataProvider": {
            "action": "loadConvSourceObjects",
            "response": "{{data.sourceObjectList}}",
            "totalFound": "{{data.numberOfSourceObjects}}",
            "commandsAnchor": "com.siemens.splm.clientfx.tcui.commands.modelObjectCellListActionCommands"
        },
        "convParticipantsDataProvider": {
            "action": "loadConvParticipants",
            "response": "{{data.participantsList}}",
            "totalFound": "{{data.numberOfParticipants}}",
            "commandsAnchor": "com.siemens.splm.clientfx.tcui.commands.modelObjectCellListActionCommands"
        },
        "convRelatedObjDataProvider": {
            "action": "loadConvRelatedObjs",
            "response": "{{data.relatedObjectList}}",
            "totalFound": "{{data.numberOfRelatedObjects}}",
            "commandsAnchor": "com.siemens.splm.clientfx.tcui.commands.modelObjectCellListActionCommands"
        }
    },
    "functions": {},
    "onEvent": [
        {
            "eventId": "primaryWorkArea.selectionChangeEvent",
            "cacheEventData": true,
            "action": "selectionChange"
        },
        {
            "eventId": "ac0ActionableFeedSummary.selectionChangeComplete",
            "action": "initActionableFeedSummary"
        },
        {
            "eventId": "ac0ActionableFeedSummary.selectionChangeComplete",
            "action": "initActionableFeedSummary2"
        },
        {
            "eventId": "ac0ActionableFeedSummary.selectionChangeComplete",
            "action": "initActionableFeedSummary3"
        },
        {
            "eventId": "awPanel.reveal",
            "action": "reveal"
        }
    ],
    "lifecycleHooks": {
        "onMount": "selectionChange"
    },
    "conditions": {
        "lovStatusValueUpdated": {
            "expression": "data.eventData.property.dbValues !== data.eventData.previousSelect"
        },
        "isSnapVisible": {
            "expression": "ctx.preferences.Ac0SnapshotDiscussionsEnabled[0] === 'true'"
        }
    },
    "i18n": {
        "overview": [
            "ActiveCollabDeclarativeMessages"
        ],
        "more": [
            "ActiveCollabDeclarativeMessages"
        ],
        "less": [
            "ActiveCollabDeclarativeMessages"
        ],
        "discussion": [
            "ActiveCollabDeclarativeMessages"
        ],
        "sourceObjects": [
            "ActiveCollabDeclarativeMessages"
        ],
        "participants": [
            "ActiveCollabDeclarativeMessages"
        ],
        "status": [
            "ActiveCollabDeclarativeMessages"
        ],
        "convUpdateErrorMsg": [
            "ActiveCollabDeclarativeMessages"
        ],
        "save": [
            "ActiveCollabDeclarativeMessages"
        ],
        "discard": [
            "ZeroCompileCommandMessages"
        ],
        "snapshot": [
            "ActiveCollabDeclarativeMessages"
        ],
        "addReply": [
            "ActiveCollabDeclarativeMessages"
        ],
        "description": [
            "ActiveCollabDeclarativeMessages"
        ],
        "priorityLabel": [
            "ActiveCollabDeclarativeMessages"
        ],
        "author": [
            "ActiveCollabDeclarativeMessages"
        ]
    },
    "_viewModelId": "ac0ActionableFeedSummary",
    "_uniqueViewModelId": "ac0ActionableFeedSummary"
};

    /*
    <aw-panel-body>
    <aw-command-panel-section caption="i18n.discussion" collapsed="false">
        <ac0-discussion-tile conv-item="{discussionItem: props.subPanelContext.selection[0]}"  open-dialog="{openDockedPanel: props.actions.openAc0UniversalConvPanel}" shared-data-obj='{sharedData: data.sharedData}' contents="{switch: { meta: { showMeta: true, clickableLinks: true }, showDetails: true, showList: true, showReply: true, showActions: true, showMoreCommands: true } }" search-state="{searchStateData: props.subPanelContext.context.searchState}"></ac0-discussion-tile>
        <aw-dialog action="openAc0UniversalConvPanel"></aw-dialog>
    </aw-command-panel-section>
</aw-panel-body>

<!--Source Objects-->
<aw-panel-body>
    <aw-command-panel-section caption="i18n.sourceObjects" collapsed="false">
        <aw-list dataprovider = "data.dataProviders.convSourceObjectsDataProvider" >
            <aw-default-cell vmo="item"></aw-default-cell>
        </aw-list>
    </aw-command-panel-section>
    <!--Participants List-->
    <aw-command-panel-section caption="i18n.participants" collapsed="false">
        <aw-list dataprovider = "data.dataProviders.convParticipantsDataProvider" >
            <aw-default-cell vmo="item"></aw-default-cell>
        </aw-list>
    </aw-command-panel-section>
    <aw-command-panel-section caption="i18n.status" collapsed="false">
        <div class="sw-row">
            <div class="sw-column w-3">i18n.status</div>
            <div class="sw-column w-3">{{ctx.newConvObj.props.collabStatus.displayValues[0]}}</div>
        </div>
        <div class="sw-row">
            <div class="sw-column w-3">i18n.author</div>
            <div class="sw-column w-3">{{ctx.newConvObj.props.collabStatusChangeUser.displayValues[0]}}</div>
        </div>
        <div class="sw-row">
            <div class="sw-column w-3">i18n.priorityLabel</div>
            <div class="sw-column w-3">{{ctx.newConvObj.props.convPriority.displayValues[0]}} </div>
        </div>
    </aw-command-panel-section>
    <aw-command-panel-section visible-when="conditions.isSnapVisible" caption="i18n.snapshot" collapsed="false" >
        <aw-list dataprovider = "data.dataProviders.convRelatedObjDataProvider" >
            <aw-default-cell vmo="item"></aw-default-cell>
        </aw-list>
    </aw-command-panel-section>
</aw-panel-body>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <AwPanelBody>
        <AwCommandPanelSection caption={i18n.discussion} collapsed="false">
            <Ac0DiscussionTile convItem={AwParseService.instance( "{discussionItem: props.subPanelContext.selection[0]}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} openDialog={AwParseService.instance( "{openDockedPanel: props.actions.openAc0UniversalConvPanel}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} sharedDataObj={AwParseService.instance( "{sharedData: fields.sharedData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} contents={AwParseService.instance( "{switch: { meta: { showMeta: true, clickableLinks: true }, showDetails: true, showList: true, showReply: true, showActions: true, showMoreCommands: true } }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchState={AwParseService.instance( "{searchStateData: props.subPanelContext.context.searchState}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </Ac0DiscussionTile>
            <AwDialog action={actions.openAc0UniversalConvPanel}>
            </AwDialog>
        </AwCommandPanelSection>
    </AwPanelBody>
    <AwPanelBody>
        <AwCommandPanelSection caption={i18n.sourceObjects} collapsed="false">
            <AwList dataprovider={dataProviders.convSourceObjectsDataProvider} showDropArea={null} dndHandler={props.viewModel.dndHandler}>{({item, index})=>{ return <>                <AwDefaultCell vmo={AwParseService.instance( "item" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, index } )}>
                </AwDefaultCell></> } }
            </AwList>
        </AwCommandPanelSection>
        <AwCommandPanelSection caption={i18n.participants} collapsed="false">
            <AwList dataprovider={dataProviders.convParticipantsDataProvider} showDropArea={null} dndHandler={props.viewModel.dndHandler}>{({item, index})=>{ return <>                <AwDefaultCell vmo={AwParseService.instance( "item" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, index } )}>
                </AwDefaultCell></> } }
            </AwList>
        </AwCommandPanelSection>
        <AwCommandPanelSection caption={i18n.status} collapsed="false">
            <div className="sw-row">
                <div className="sw-column w-3">
                    {i18n.status}
                </div>
                <div className="sw-column w-3">
                    {AwParseService.instance( "ctx.newConvObj.props.collabStatus.displayValues[0]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
            </div>
            <div className="sw-row">
                <div className="sw-column w-3">
                    {i18n.author}
                </div>
                <div className="sw-column w-3">
                    {AwParseService.instance( "ctx.newConvObj.props.collabStatusChangeUser.displayValues[0]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
            </div>
            <div className="sw-row">
                <div className="sw-column w-3">
                    {i18n.priorityLabel}
                </div>
                <div className="sw-column w-3">
                    {AwParseService.instance( "ctx.newConvObj.props.convPriority.displayValues[0]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </div>
            </div>
        </AwCommandPanelSection>
        <AwCommandPanelSectionVisibleWhen caption={i18n.snapshot} collapsed="false" visibleWhen={AwParseService.instance( "conditions.isSnapVisible" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwList dataprovider={dataProviders.convRelatedObjDataProvider} showDropArea={null} dndHandler={props.viewModel.dndHandler}>{({item, index})=>{ return <>                <AwDefaultCell vmo={AwParseService.instance( "item" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, index } )}>
                </AwDefaultCell></> } }
            </AwList>
        </AwCommandPanelSectionVisibleWhen>
    </AwPanelBody>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;