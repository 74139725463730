// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwRow from 'viewmodel/AwRowViewModel';
import AwColumn from 'viewmodel/AwColumnViewModel';
import AwServerVisibilityCommandBar from 'viewmodel/AwServerVisibilityCommandBarViewModel';
import AwPanel from 'viewmodel/AwPanelViewModel';
import AwSplmTable from 'viewmodel/AwSplmTableViewModel';
    import { ExistWhen } from 'js/hocCollection';
const AwPanelBodyExistWhen = ExistWhen(AwPanelBody);
AwPanelBodyExistWhen.displayName = 'AwPanelBodyExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Vm1AssignPartnerContractService', () => import('js/Vm1AssignPartnerContractService'));
registerDynImportEntry('js/Vm1SortFilterUtilService', () => import('js/Vm1SortFilterUtilService'));
registerDynImportEntry('js/columnArrangeService', () => import('js/columnArrangeService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-panel-body.directive",
        "js/aw-row.directive",
        "js/aw-column.directive",
        "js/aw-command-bar.directive",
        "js/aw-splm-table.directive",
        "js/aw-panel.directive"
    ],
    "actions": {
        "refreshAssignedDMSTable": {
            "actionType": "JSFunction",
            "method": "refreshDataProviders",
            "inputData": {
                "data": "{{data}}",
                "subPanelContext": "{{subPanelContext}}"
            },
            "deps": "js/Vm1AssignPartnerContractService"
        },
        "reveal": {
            "actionType": "JSFunction",
            "method": "checkGroupSecurityOfUser",
            "inputData": {},
            "deps": "js/Vm1AssignPartnerContractService"
        },
        "loadPartnerContractsColumnsBatch": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "loadPartnerContractsColumns"
                },
                {
                    "action": "getPreferenceValuePCColumns"
                },
                {
                    "action": "processColumnsAction"
                }
            ]
        },
        "loadPartnerContractsColumns": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-VendorManagementAW-2019-12-VendorManagement",
            "method": "getColumnInfo",
            "inputData": {
                "input": {
                    "tableType": "PartnersStructure",
                    "inputObject": "{{function:getColumnInfoInput}}"
                }
            },
            "outputData": {
                "columnInfo": "columnInfo"
            },
            "actionMessages": {
                "failure": [
                    {
                        "message": "soaOperationFailure"
                    }
                ]
            },
            "deps": "js/Vm1AssignPartnerContractService"
        },
        "loadPartnerContractsResults": {
            "actionType": "JSFunction",
            "method": "loadPartners",
            "inputData": {
                "ctx": "{{ctx}}",
                "data": "{{data}}"
            },
            "outputData": {
                "totalFound": "totalFound",
                "searchResults": "{{function:getViewModelRows}}",
                "partnerContracts": "{{function:getRowsFromResponse}}"
            },
            "events": {
                "success": [
                    {
                        "name": "publishCellPropsEvent",
                        "condition": "ctx.isRedLineMode === 'true'"
                    },
                    {
                        "name": "sortAndFilterViewModelRows",
                        "eventData": {
                            "partnerContracts": "{{data.partnerContracts}}"
                        }
                    },
                    {
                        "name": "getColumnConfigurationEvent"
                    }
                ]
            },
            "actionMessages": {
                "failure": [
                    {
                        "message": "soaOperationFailure"
                    }
                ]
            },
            "deps": "js/Vm1AssignPartnerContractService"
        },
        "loadPartnerContractsResultsForSelected": {
            "actionType": "JSFunction",
            "method": "loadPartners",
            "inputData": {
                "ctx": "{{ctx}}",
                "data": "{{data}}"
            },
            "outputData": {
                "totalFound": "totalFound",
                "searchResults": "{{function:getViewModelRows}}",
                "partnerContracts": "{{function:getRowsFromResponse}}"
            },
            "events": {
                "success": [
                    {
                        "name": "publishCellPropsEvent",
                        "condition": "ctx.isRedLineMode === 'true'"
                    },
                    {
                        "name": "sortAndFilterViewModelRows",
                        "eventData": {
                            "partnerContracts": "{{data.partnerContracts}}"
                        }
                    },
                    {
                        "name": "Vm1PartnerContractsOfObjectTable.refreshPartnersTable"
                    }
                ]
            },
            "actionMessages": {
                "failure": [
                    {
                        "message": "soaOperationFailure"
                    }
                ]
            },
            "deps": "js/Vm1AssignPartnerContractService"
        },
        "loadSortedAndFilteredPartnerContractsResults": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-VendorManagementAW-2020-12-VendorManagement",
            "method": "sortAndFilterViewModelRows",
            "inputData": {
                "input": {
                    "viewModelRows": "{{data.partnerContracts}}",
                    "columnFilters": "{{data.columnProviders.PartnerContractsColumnProvider.columnFilters}}",
                    "sortCriteria": "{{function:getSortCriteria}}"
                }
            },
            "outputData": {
                "sortedFilteredPCRevs": "{{function:getViewModelRows}}"
            },
            "events": {
                "success": [
                    {
                        "name": "refreshDataProvider"
                    }
                ]
            },
            "actionMessages": {
                "failure": [
                    {
                        "message": "soaOperationFailure"
                    }
                ]
            },
            "deps": "js/Vm1AssignPartnerContractService"
        },
        "processColumnsAction": {
            "actionType": "JSFunction",
            "method": "processColumns",
            "inputData": {
                "columns": "{{data.dataProviders.PartnerContractsDataProvider}}",
                "columnInfo": "{{data.columnInfo}}",
                "prefValues": "{{data.partnerContractColumnsShown}}"
            },
            "outputData": {
                "dataProviders.PartnerContractsDataProvider.columnConfig": "columnConfig"
            },
            "deps": "js/Vm1AssignPartnerContractService"
        },
        "doGridDataProvider": {
            "actionType": "dataProvider",
            "method": "PartnerContractsDataProvider"
        },
        "getPreferenceValuePCColumns": {
            "actionType": "TcSoaService",
            "serviceName": "Administration-2012-09-PreferenceManagement",
            "method": "getPreferences",
            "inputData": {
                "preferenceNames": [
                    "PatnerContractColumnsShownPref"
                ],
                "includePreferenceDescriptions": false
            },
            "outputData": {
                "partnerContractColumnsShown": "response[0].values.values"
            }
        },
        "storeSelectionList": {
            "actionType": "JSFunction",
            "method": "storePartnerContractSelections",
            "inputData": {
                "data": "{{data.dataProviders.PartnerContractsDataProvider}}"
            },
            "deps": "js/Vm1AssignPartnerContractService"
        },
        "publishCellProps": {
            "actionType": "JSFunction",
            "method": "publishCellPropsJs",
            "inputData": {
                "columns": "{{data.dataProviders.PartnerContractsDataProvider}}"
            },
            "outputData": {},
            "deps": "js/Vm1AssignPartnerContractService"
        },
        "getFilterFacets": {
            "actionType": "JSFunction",
            "method": "getFilterFacetValues",
            "inputData": {
                "columnName": "{{filterFacetInput.column.field}}",
                "objects": "{{data.dataProviders.PartnerContractsDataProvider.viewModelCollection.loadedVMObjects}}",
                "data": "{{data}}"
            },
            "outputData": {
                "filterFacetResults": "{{data.filterFacetResults}}"
            },
            "deps": "js/Vm1SortFilterUtilService"
        },
        "updateSortFilterResultInTable": {
            "actionType": "JSFunction",
            "method": "updateSortFilterResultInTable",
            "inputData": {
                "dataProvider": "{{data.dataProviders.PartnerContractsDataProvider}}",
                "result": "{{data.sortedFilteredPCRevs}}"
            },
            "outputData": {},
            "deps": "js/Vm1SortFilterUtilService"
        },
        "saveColumnConfig": {
            "actionType": "JSFunction",
            "method": "saveColumnConfig",
            "deps": "js/Vm1AssignPartnerContractService",
            "inputData": {
                "dataProvider": "{{data.dataProviders.PartnerContractsDataProvider}}",
                "columns": "{{data.dataProviders.PartnerContractsDataProvider.newColumns}}",
                "columnInfo": "{{data.columnInfo}}",
                "preferenceName": "PatnerContractColumnsShownPref"
            },
            "events": {
                "success": [
                    {
                        "name": "sortAndFilterViewModelRowsAfterSaveConfig",
                        "eventData": {
                            "partnerContracts": "{{data.partnerContracts}}"
                        }
                    }
                ]
            }
        },
        "resetColumnConfig": {
            "actionType": "JSFunction",
            "method": "resetColumnConfig",
            "deps": "js/Vm1AssignPartnerContractService",
            "inputData": {
                "dataProvider": "{{data.dataProviders.PartnerContractsDataProvider}}",
                "columnInfo": "{{data.columnInfo}}",
                "preferenceName": "PatnerContractColumnsShownPref"
            }
        },
        "doArrangeEvent": {
            "actionType": "JSFunctionAsync",
            "method": "arrangeColumns",
            "inputData": {
                "declViewModel": "{{data}}",
                "eventData": "{{data.eventData}}"
            },
            "deps": "js/columnArrangeService"
        },
        "refreshPartnerContractTable": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "Vm1PartnerContractsOfObjectTable.plTable.reload"
                    }
                ]
            }
        }
    },
    "onEvent": [
        {
            "eventId": "getColumnConfigurationEvent",
            "action": "loadPartnerContractsColumnsBatch"
        },
        {
            "eventId": "Vm1PartnerContractsOfObjectTable.refreshPartnersTable",
            "action": "refreshAssignedDMSTable"
        },
        {
            "eventId": "primaryWorkArea.selectionChangeEvent",
            "cacheEventData": true,
            "action": "loadPartnerContractsResultsForSelected"
        },
        {
            "eventId": "publishCellPropsEvent",
            "action": "publishCellProps"
        },
        {
            "eventId": "Vm1PartnerContractsOfObjectTable.gridSelection",
            "action": "storeSelectionList"
        },
        {
            "eventId": "Vm1PartnerContractsOfObjectTable.selectionChangeEvent",
            "action": "storeSelectionList"
        },
        {
            "eventId": "sortAndFilterViewModelRows",
            "action": "loadSortedAndFilteredPartnerContractsResults",
            "condition": "conditions.checkOrderCriteria",
            "cacheEventData": true
        },
        {
            "eventId": "refreshDataProvider",
            "action": "updateSortFilterResultInTable"
        },
        {
            "eventId": "sortAndFilterViewModelRowsAfterSaveConfig",
            "action": "loadSortedAndFilteredPartnerContractsResults",
            "cacheEventData": true
        },
        {
            "eventId": "columnArrange",
            "cacheEventData": true,
            "action": "doArrangeEvent"
        },
        {
            "eventId": "Vm1PartnerContractOfObjects.refreshTable",
            "action": "refreshPartnerContractTable"
        }
    ],
    "lifecycleHooks": {
        "onMount": "reveal"
    },
    "functions": {
        "getPartnerContractsOfSelectedObjsInput": {
            "functionName": "getPartnerContractsOfSelectedObjsInput",
            "parameters": [
                "{{ctx}}",
                "{{data}}"
            ]
        },
        "getPartnerContractsOfChangedInput": {
            "functionName": "getPartnerContractsOfChangedInput",
            "parameters": [
                "{{eventData}}"
            ]
        },
        "getColumnInfoInput": {
            "functionName": "getColumnInfoInput",
            "parameters": [
                "{{ctx}}"
            ]
        },
        "getViewModelRows": {
            "functionName": "getViewModelRows",
            "parameters": [
                "{{data.dataProviders.PartnerContractsDataProvider}}"
            ]
        },
        "getSortCriteria": {
            "functionName": "getSortCriteria",
            "parameters": [
                "{{data.columnProviders.PartnerContractsColumnProvider.sortCriteria}}"
            ]
        },
        "loadPartners": {
            "functionName": "loadPartners",
            "parameters": [
                "{{ctx}}",
                "{{data}}"
            ]
        }
    },
    "conditions": {
        "checkOrderCriteria": {
            "expression": "data.columnProviders.PartnerContractsColumnProvider.sortCriteria.length > 0 || data.columnProviders.PartnerContractsColumnProvider.columnFilters.length > 0"
        },
        "isTCVersionAfter143": {
            "expression": "(ctx.tcSessionData.tcMajorVersion > 14) || (ctx.tcSessionData.tcMajorVersion === 14 && ctx.tcSessionData.tcMinorVersion >= 3 )"
        },
        "isTCVersionBefore143": {
            "expression": "!((ctx.tcSessionData.tcMajorVersion > 14) || (ctx.tcSessionData.tcMajorVersion === 14 && ctx.tcSessionData.tcMinorVersion >= 3 ))"
        }
    },
    "i18n": {
        "vm1Partners": [
            "VendorManagementMessages"
        ]
    },
    "dataProviders": {
        "PartnerContractsDataProvider": {
            "action": "loadPartnerContractsResults",
            "editContext": "TABLE_CONTEXT",
            "response": "{{data.searchResults}}",
            "totalFound": "{{data.totalFound}}",
            "selectionModelMode": "multiple",
            "commandsAnchor": "com.siemens.splm.clientfx.tcui.commands.modelObjectCellListActionCommands",
            "filterFacetAction": "getFilterFacets",
            "filterFacetResults": "{{data.filterFacetResults}}",
            "enablePropEdit": "false",
            "inputData": {
                "selectionData": "{{subPanelContext.selectionData}}"
            }
        }
    },
    "columnProviders": {
        "PartnerContractsColumnProvider": {
            "loadColumnAction": "loadPartnerContractsColumnsBatch",
            "columnInfos": "{{data.columns}}",
            "columnFilters": "{{filterFacetInput.columnFilters}}",
            "saveColumnAndLoadAction": "saveColumnConfig",
            "resetColumnAction": "resetColumnConfig"
        }
    },
    "grids": {
        "Vm1PartnerContractsOfObjectTable": {
            "dataProvider": "PartnerContractsDataProvider",
            "columnProvider": "PartnerContractsColumnProvider",
            "addIconColumn": true,
            "enableArrangeMenu": true,
            "gridOptions": {
                "maxRowsToShow": 5,
                "isFilteringEnabled": "true",
                "enableSorting": "true",
                "useStaticFirstCol": true
            }
        }
    },
    "messages": {
        "soaOperationFailure": {
            "messageType": "ERROR",
            "messageText": "{{errorCode}}"
        }
    },
    "_viewModelId": "Vm1PartnerContractsOfObject",
    "_uniqueViewModelId": "Vm1PartnerContractsOfObject"
};

    /*
    <aw-panel-body exist-when="conditions.isTCVersionAfter143">
    <aw-panel-section caption="i18n.vm1Partners">
    <aw-row height="3f">
        <aw-column width="fill">
            <div>
                <aw-server-visibility-command-bar anchor="aw_partnersTableCmdbar" class="aw-commands-commandBarHorizontalRight"
                    alignment="HORIZONTAL"></aw-server-visibility-command-bar>
                <aw-panel class="aw-layout-workareaCommandbar">
                    <aw-server-visibility-command-bar anchor="aw_EditSPLMTableCommandAnchor" class="aw-commands-commandBarHorizontalRight"
                         alignment="HORIZONTAL"></aw-server-visibility-command-bar>
                </aw-panel>
            </div>
        </aw-column>
    </aw-row>
    <aw-row height="35f">
        <aw-column width="fill">
            <aw-splm-table gridid="Vm1PartnerContractsOfObjectTable" ></aw-splm-table>
        </aw-column>
    </aw-row>
</aw-panel-section>
</aw-panel-body>
<aw-panel-body exist-when="conditions.isTCVersionBefore143">
    <aw-row  height="3f">
        <aw-column width="fill">
            <div>
                <aw-server-visibility-command-bar anchor="aw_partnersTableCmdbar" class="aw-commands-commandBarHorizontalRight"
                    alignment="HORIZONTAL"></aw-server-visibility-command-bar>
                <aw-panel class="aw-layout-workareaCommandbar">
                    <aw-server-visibility-command-bar anchor="aw_EditSPLMTableCommandAnchor" class="aw-commands-commandBarHorizontalRight"
                         alignment="HORIZONTAL"></aw-server-visibility-command-bar>
                </aw-panel>
            </div>
        </aw-column>
    </aw-row>
    <aw-row height="35f">
        <aw-column width="fill">
            <aw-splm-table gridid="Vm1PartnerContractsOfObjectTable" ></aw-splm-table>
        </aw-column>
    </aw-row>
</aw-panel-body>


    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <AwPanelBodyExistWhen existWhen={AwParseService.instance( "conditions.isTCVersionAfter143" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <AwPanelSection caption={i18n.vm1Partners}>
            <AwRow height="3f">
                <AwColumn width="fill">
                    <div>
                        <AwServerVisibilityCommandBar anchor="aw_partnersTableCmdbar" className="aw-commands-commandBarHorizontalRight" alignment="HORIZONTAL">
                        </AwServerVisibilityCommandBar>
                        <AwPanel  revealAction={actions.reveal}  className="aw-layout-workareaCommandbar">
                            <AwServerVisibilityCommandBar anchor="aw_EditSPLMTableCommandAnchor" className="aw-commands-commandBarHorizontalRight" alignment="HORIZONTAL">
                            </AwServerVisibilityCommandBar>
                        </AwPanel>
                    </div>
                </AwColumn>
            </AwRow>
            <AwRow height="35f">
                <AwColumn width="fill">
                    <AwSplmTable {...grids.Vm1PartnerContractsOfObjectTable}>
                    </AwSplmTable>
                </AwColumn>
            </AwRow>
        </AwPanelSection>
    </AwPanelBodyExistWhen>
    <AwPanelBodyExistWhen existWhen={AwParseService.instance( "conditions.isTCVersionBefore143" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <AwRow height="3f">
            <AwColumn width="fill">
                <div>
                    <AwServerVisibilityCommandBar anchor="aw_partnersTableCmdbar" className="aw-commands-commandBarHorizontalRight" alignment="HORIZONTAL">
                    </AwServerVisibilityCommandBar>
                    <AwPanel  revealAction={actions.reveal}  className="aw-layout-workareaCommandbar">
                        <AwServerVisibilityCommandBar anchor="aw_EditSPLMTableCommandAnchor" className="aw-commands-commandBarHorizontalRight" alignment="HORIZONTAL">
                        </AwServerVisibilityCommandBar>
                    </AwPanel>
                </div>
            </AwColumn>
        </AwRow>
        <AwRow height="35f">
            <AwColumn width="fill">
                <AwSplmTable {...grids.Vm1PartnerContractsOfObjectTable}>
                </AwSplmTable>
            </AwColumn>
        </AwRow>
    </AwPanelBodyExistWhen>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;