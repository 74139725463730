// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSublocation from 'viewmodel/AwSublocationViewModel';
import Cm1MergeCandidates from 'viewmodel/Cm1MergeCandidatesViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import Cm1MergeChangesAce from 'viewmodel/Cm1MergeChangesAceViewModel';
import Cm1MergeUsageChanges from 'viewmodel/Cm1MergeUsageChangesViewModel';
import AwSplitter from 'viewmodel/AwSplitterViewModel';
    import { AwClick,VisibleWhen,ExistWhen } from 'js/hocCollection';
const AwPanelBodyAwClick = AwClick(AwPanelBody);
const Cm1MergeChangesAceVisibleWhenExistWhen = ExistWhen(VisibleWhen(Cm1MergeChangesAce));
const Cm1MergeUsageChangesExistWhen = ExistWhen(Cm1MergeUsageChanges);
AwPanelBodyAwClick.displayName = 'AwPanelBodyAwClick';
Cm1MergeChangesAceVisibleWhenExistWhen.displayName = 'Cm1MergeChangesAceVisibleWhenExistWhen';
Cm1MergeUsageChangesExistWhen.displayName = 'Cm1MergeUsageChangesExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Cm1ChangeSummaryService', () => import('js/Cm1ChangeSummaryService'));
registerDynImportEntry('js/occmgmtSplitViewService', () => import('js/occmgmtSplitViewService'));
registerDynImportEntry('js/cbaTaskbarCommandService', () => import('js/cbaTaskbarCommandService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-sublocation.directive",
        "js/aw-panel-body.directive",
        "js/aw-splitter.directive",
        "js/aw-include.directive"
    ],
    "conditions": {
        "isUsageMerge": {
            "expression": "ctx.state.params.srcPartUsgRevUID !== undefined"
        }
    },
    "data": {
        "_awSublocationPref": {
            "hideRightWall": true
        },
        "usageMergeColumns": {
            "columns": [
                "ELEMENT",
                "ID",
                "REVISION",
                "NAME",
                "object_desc"
            ]
        },
        "mergeSrcSublocation": {
            "viewKey": "occmgmtContext",
            "name": "com.siemens.splm.client.change:mergeChangesSubLocation",
            "label": {
                "source": "/i18n/ChangeMessages",
                "key": "Cm1MergeViewTitle"
            },
            "requestPref": {
                "savedSessionMode": "ignore",
                "splitMode": "true",
                "showChange": "true",
                "showMarkup": [
                    "false"
                ]
            },
            "clientScopeURI": "Awb0OccurrenceManagement",
            "nameToken": "com.siemens.splm.client.change:mergeChangesSubLocation",
            "policy": "/policies/occMgmtPropertyPolicy.json",
            "viewBase": "occMgmt",
            "selectionQueryParamKey": "c_uid",
            "contextKey": "occmgmtContext",
            "splitterLayout": "HORIZONTAL",
            "breadcrumbConfig": {
                "type": "navigate",
                "vm": "occMgmtBreadcrumb",
                "chevronDataProvider": "breadCrumbDataProvider",
                "crumbDataProvider": "aceBreadCrumbDataProvider",
                "noUpdate": true,
                "popuplist": "/html/occMgmtBreadcrumblist.html",
                "id": "occmgmtContext",
                "displayProperty": "object_string"
            },
            "headerPreference": {
                "hideGlobalSearch": true,
                "hideLogo": true
            },
            "viewModes": {
                "TreeView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": false
                }
            },
            "defaultDisplayMode": "TreeView",
            "context": {
                "commandContextParameters": [
                    "filter",
                    "recipe",
                    "uid",
                    "uid2"
                ],
                "search": {
                    "hideInContentSearchBox": true
                }
            },
            "urlParams": {
                "selectionQueryParamKey": "c_uid",
                "openStructureQueryParamKey": "o_uid",
                "rootQueryParamKey": "uid",
                "productContextQueryParamKey": "pci_uid",
                "csidQueryParamKey": "c_csid",
                "secondaryPageIdQueryParamKey": "spageId",
                "topElementQueryParamKey": "t_uid",
                "pageIdQueryParamKey": "pageId",
                "recipeParamKey": "recipe",
                "subsetFilterParamKey": "filter"
            },
            "awSubLocationPref": {
                "hideRightWall": true
            },
            "data": {
                "searchBox": {
                    "displayName": "",
                    "type": "STRING",
                    "isRequired": "true",
                    "dbValue": "",
                    "isEnabled": "true"
                }
            },
            "displayLabel": {
                "text": "{{i18n.Cm1MergeSourceTitle}}",
                "separator": "|"
            },
            "baseSelection": "{{ctx.mergeChangesCtx.srcStructure}}"
        },
        "mergeTrgSublocation": {
            "viewKey": "occmgmtContext2",
            "name": "com.siemens.splm.client.change:mergeChangesSubLocation",
            "label": {
                "source": "/i18n/ChangeMessages",
                "key": "Cm1MergeViewTitle"
            },
            "requestPref": {
                "savedSessionMode": "ignore",
                "splitMode": "true",
                "showChange": "true",
                "showMarkup": [
                    "false"
                ]
            },
            "clientScopeURI": "Awb0OccurrenceManagement",
            "nameToken": "com.siemens.splm.client.change:mergeChangesSubLocation",
            "policy": "/policies/occMgmtPropertyPolicy.json",
            "viewBase": "occmgmtSplit",
            "selectionQueryParamKey": "c_uid",
            "contextKey": "occmgmtContext2",
            "splitterLayout": "HORIZONTAL",
            "breadcrumbConfig": {
                "type": "navigate",
                "vm": "occMgmtSplitBreadcrumb",
                "chevronDataProvider": "breadCrumbDataProvider2",
                "crumbDataProvider": "aceBreadCrumbDataProvider2",
                "noUpdate": true,
                "popuplist": "/html/occMgmtBreadcrumblist.html",
                "id": "occmgmtContext2",
                "displayProperty": "object_string"
            },
            "headerPreference": {
                "hideGlobalSearch": true,
                "hideLogo": true
            },
            "viewModes": {
                "TreeView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": false
                }
            },
            "defaultDisplayMode": "TreeView",
            "context": {
                "commandContextParameters": [
                    "filter",
                    "recipe",
                    "uid",
                    "uid2"
                ],
                "search": {
                    "hideInContentSearchBox": true
                }
            },
            "urlParams": {
                "selectionQueryParamKey": "c_uid2",
                "openStructureQueryParamKey": "o_uid2",
                "rootQueryParamKey": "uid2",
                "productContextQueryParamKey": "pci_uid2",
                "csidQueryParamKey": "c_csid2",
                "secondaryPageIdQueryParamKey": "spageId2",
                "topElementQueryParamKey": "t_uid2",
                "pageIdQueryParamKey": "pageId2",
                "recipeParamKey": "recipe2",
                "subsetFilterParamKey": "filter2"
            },
            "awSubLocationPref": {
                "hideRightWall": true
            },
            "data": {
                "searchBox": {
                    "displayName": "",
                    "type": "STRING",
                    "isRequired": "true",
                    "dbValue": "",
                    "isEnabled": "true"
                }
            },
            "displayLabel": {
                "text": "{{i18n.Cm1MergeTargetTitle}}",
                "separator": "|"
            },
            "baseSelection": "{{ctx.mergeChangesCtx.trgStructure}}"
        },
        "hiddenCommands": {
            "isSaveWorkingContextNotSupported": true,
            "Awb0StructureFilter": true,
            "Awp0ShowColor": true,
            "Awb0ShowMarkup": true,
            "Awb0AddSubstitutes": true,
            "Awb0RemoveSubstitutes": true,
            "Awb0UseSubstitute": true,
            "ps0AddAlternate": true,
            "ps0RemoveAlternate": true
        },
        "view": {
            "keys": [
                "occmgmtContext",
                "occmgmtContext2"
            ]
        },
        "showObjectContext": {
            "initialValues": {
                "displayName": ""
            },
            "meta": {}
        },
        "showObjectContext2": {
            "initialValues": {
                "displayName": ""
            },
            "meta": {}
        },
        "occContext": {
            "initialValues": {
                "configContext": {},
                "displayToggleOptions": {},
                "supportedFeatures": {},
                "productContextInfo": {},
                "currentState": {},
                "previousState": {},
                "transientRequestPref": {},
                "persistentRequestPref": {},
                "disabledFeatures": [],
                "viewKey": "occmgmtContext"
            },
            "meta": {}
        },
        "occContext2": {
            "initialValues": {
                "configContext": {},
                "displayToggleOptions": {},
                "supportedFeatures": {},
                "productContextInfo": {},
                "currentState": {},
                "previousState": {},
                "transientRequestPref": {},
                "persistentRequestPref": {},
                "disabledFeatures": [],
                "viewKey": "occmgmtContext2"
            },
            "meta": {}
        }
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        },
        "base-selection": {
            "type": "object"
        }
    },
    "i18n": {
        "Cm1MergeSourceTitle": [
            "ChangeMessages"
        ],
        "Cm1MergeTargetTitle": [
            "ChangeMessages"
        ]
    },
    "lifecycleHooks": {
        "onMount": "initializeMergeView",
        "onUnmount": "destroyMergeSplitView",
        "onUpdate": [
            {
                "action": "update",
                "observers": [
                    "viewModel.atomicData.occContext",
                    "viewModel.atomicData.occContext2"
                ]
            }
        ]
    },
    "actions": {
        "getSourceECN": {
            "actionType": "TcSoaService",
            "serviceName": "Core-2007-09-DataManagement",
            "method": "expandGRMRelationsForSecondary",
            "inputData": {
                "secondaryObjects": [
                    {
                        "uid": "{{ctx.state.params.srcPartUsgRevUID}}"
                    }
                ],
                "pref": {
                    "expItemRev": false,
                    "returnRelations": true,
                    "info": [
                        {
                            "relationTypeName": "CMHasSolutionItem",
                            "otherSideObjectTypes": ""
                        }
                    ]
                }
            },
            "outputData": {
                "srcEcn": "output[0].relationshipData[0].relationshipObjects[0].otherSideObject.uid"
            },
            "deps": "js/Cm1ChangeSummaryService"
        },
        "mergeViewStateUpdated": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "updateStateForMerge",
                        "excludeLocalDataCtx": true
                    }
                ]
            }
        },
        "initializeMergeView": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "getSourceECN",
                    "condition": "conditions.isUsageMerge"
                },
                {
                    "action": "initializeChangeMergeView"
                },
                {
                    "action": "synchronizeMergeViewStateWithURL"
                },
                {
                    "action": "mergeViewStateUpdated"
                }
            ]
        },
        "synchronizeMergeViewStateWithURL": {
            "actionType": "JSFunctionAsync",
            "method": "synchronizeSplitViewStateWithURL",
            "deps": "js/occmgmtSplitViewService",
            "inputData": {
                "objectsToOpen": "{{data.objectsToOpen}}",
                "activeState": "{{data.activeState}}",
                "occContext": "{{fields.occContext}}",
                "occContext2": "{{fields.occContext2}}",
                "data": "{{data}}",
                "showObjectContext": "{{fields.showObjectContext}}",
                "showObjectContext2": "{{fields.showObjectContext2}}"
            },
            "outputData": {
                "objectsToOpen": "objectsToOpen",
                "activeState": "activeState"
            }
        },
        "initializeChangeMergeView": {
            "actionType": "JSFunction",
            "method": "initializeOccmgmtSplitView",
            "deps": "js/occmgmtSplitViewService",
            "inputData": {
                "viewKeys": "{{data.view.keys}}",
                "hiddenCommands": "{{data.hiddenCommands}}"
            },
            "outputData": {
                "activateView": ""
            }
        },
        "destroyMergeSplitView": {
            "actionType": "JSFunction",
            "method": "destroyOccmgmtSplitView",
            "deps": "js/occmgmtSplitViewService",
            "inputData": {
                "viewKeys": "{{data.view.keys}}"
            }
        },
        "update": {
            "actionType": "JSFunction",
            "method": "updateContextForTaskbarCmds",
            "deps": "js/cbaTaskbarCommandService",
            "inputData": {
                "srcContext": "{{occContext}}",
                "trgContext": "{{occContext2}}"
            }
        },
        "activateLeftViewEvent": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "ace.activateWindow",
                        "eventData": {
                            "key": "{{data.mergeSrcSublocation.contextKey}}"
                        }
                    }
                ]
            }
        },
        "activateRightViewEvent": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "ace.activateWindow",
                        "eventData": {
                            "key": "{{data.mergeTrgSublocation.contextKey}}"
                        }
                    }
                ]
            }
        }
    },
    "_viewModelId": "Cm1MergeChangesSplit",
    "_uniqueViewModelId": "Cm1MergeChangesSplit",
    "ctx": {
        "state": {
            "type": "object"
        },
        "mergeChangesCtx": {
            "type": "object"
        },
        "aceActiveContext": {
            "type": "object"
        }
    }
};

    /*
    <aw-sublocation
sub-panel-context="{contextKey: ctx.aceActiveContext.key === 'occmgmtContext' ? data.mergeSrcSublocation.contextKey : data.mergeTrgSublocation.contextKey,
    occContext:ctx.aceActiveContext.key === 'occmgmtContext' ? data.occContext : data.occContext2,
    inactiveContext : ctx.aceActiveContext.key === 'occmgmtContext' ? data.occContext2 : data.occContext}"
sub-location-preference="data._awSublocationPref">
    <div class="sw-row flex-grow w-3 ">
        <cm1-merge-candidates name="Cm1MergeCandidates" sub-panel-context="{...subPanelContext, isUsageMerge: conditions.isUsageMerge}"></cm1-merge-candidates>
   </div>
    <div class="sw-row h-12 afx-content-background aw-occmgmt-split">
        <div class="aw-layout-flexRow aw-base-scrollPanel">
            <aw-panel-body class="aw-layout-flexColumnContainer aw-taskbasedpage-leftPanelMergeChanges" aw-click="actions.activateLeftViewEvent">
                <cm1-merge-changes-ace name="Cm1MergeChangesAce" sub-panel-context="{openedObject : data.objectsToOpen[0], provider : data.mergeSrcSublocation, occContext : data.occContext, contextKey: data.mergeSrcSublocation.contextKey}"
                exist-when="!conditions.isUsageMerge" visible-when="!conditions.isUsageMerge"></cm1-merge-changes-ace>
                <cm1-merge-usage-changes name="Cm1MergeUsageChanges" sub-panel-context="{rootObj:data.rootObj, ecn:data.srcEcn, columns:data.usageMergeColumns.columns}"
                reference="Source" exist-when="conditions.isUsageMerge && data.srcEcn !== undefined"></cm1-merge-usage-changes>
            </aw-panel-body>
            <aw-splitter min-size-1="0" min-size-2="200" direction="vertical">
            </aw-splitter>
            <aw-panel-body class="aw-layout-flexColumnContainer aw-taskbasedpage-rightPanelMergeChanges" aw-click="actions.activateRightViewEvent">
                <cm1-merge-changes-ace name="Cm1MergeChangesAce" sub-panel-context="{openedObject : data.objectsToOpen[1], provider : data.mergeTrgSublocation, occContext : data.occContext2, contextKey: data.mergeTrgSublocation.contextKey}"
                exist-when="!conditions.isUsageMerge" visible-when="!conditions.isUsageMerge"></cm1-merge-changes-ace>
               <cm1-merge-usage-changes name="Cm1MergeUsageChanges" sub-panel-context="{rootObj:data.rootObj, ecn:ctx.state.params.ecn_uid, columns:data.usageMergeColumns.columns}"
               reference="Target" exist-when="conditions.isUsageMerge"></cm1-merge-usage-changes>
            </aw-panel-body>
        </div>
    </div>
</aw-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSublocation subPanelContext={AwParseService.instance( "{contextKey: ctx.aceActiveContext.key === 'occmgmtContext' ? data.mergeSrcSublocation.contextKey : data.mergeTrgSublocation.contextKey,\n    occContext:ctx.aceActiveContext.key === 'occmgmtContext' ? fields.occContext : fields.occContext2,\n    inactiveContext : ctx.aceActiveContext.key === 'occmgmtContext' ? fields.occContext2 : fields.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subLocationPreference={AwParseService.instance( "data._awSublocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <div className="sw-row flex-grow w-3 ">
            <Cm1MergeCandidates name="Cm1MergeCandidates" subPanelContext={AwParseService.instance( "{...subPanelContext, isUsageMerge: conditions.isUsageMerge}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </Cm1MergeCandidates>
        </div>
        <div className="sw-row h-12 afx-content-background aw-occmgmt-split">
            <div className="aw-layout-flexRow aw-base-scrollPanel">
                <AwPanelBodyAwClick className="aw-layout-flexColumnContainer aw-taskbasedpage-leftPanelMergeChanges" awClick={AwParseService.instance( "actions.activateLeftViewEvent" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <Cm1MergeChangesAceVisibleWhenExistWhen name="Cm1MergeChangesAce" subPanelContext={AwParseService.instance( "{openedObject : data.objectsToOpen[0], provider : data.mergeSrcSublocation, occContext : fields.occContext, contextKey: data.mergeSrcSublocation.contextKey}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "!conditions.isUsageMerge" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} visibleWhen={AwParseService.instance( "!conditions.isUsageMerge" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </Cm1MergeChangesAceVisibleWhenExistWhen>
                    <Cm1MergeUsageChangesExistWhen name="Cm1MergeUsageChanges" subPanelContext={AwParseService.instance( "{rootObj:data.rootObj, ecn:data.srcEcn, columns:data.usageMergeColumns.columns}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} reference="Source" existWhen={AwParseService.instance( "conditions.isUsageMerge && data.srcEcn !== undefined" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </Cm1MergeUsageChangesExistWhen>
                </AwPanelBodyAwClick>
                <AwSplitter minSize1="0" minSize2="200" direction="vertical">
                </AwSplitter>
                <AwPanelBodyAwClick className="aw-layout-flexColumnContainer aw-taskbasedpage-rightPanelMergeChanges" awClick={AwParseService.instance( "actions.activateRightViewEvent" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <Cm1MergeChangesAceVisibleWhenExistWhen name="Cm1MergeChangesAce" subPanelContext={AwParseService.instance( "{openedObject : data.objectsToOpen[1], provider : data.mergeTrgSublocation, occContext : fields.occContext2, contextKey: data.mergeTrgSublocation.contextKey}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "!conditions.isUsageMerge" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} visibleWhen={AwParseService.instance( "!conditions.isUsageMerge" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </Cm1MergeChangesAceVisibleWhenExistWhen>
                    <Cm1MergeUsageChangesExistWhen name="Cm1MergeUsageChanges" subPanelContext={AwParseService.instance( "{rootObj:data.rootObj, ecn:ctx.state.params.ecn_uid, columns:data.usageMergeColumns.columns}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} reference="Target" existWhen={AwParseService.instance( "conditions.isUsageMerge" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </Cm1MergeUsageChangesExistWhen>
                </AwPanelBodyAwClick>
            </div>
        </div>
    </AwSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;