/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/VIMXrtCommandService
 */

import _ from "lodash";
import cdmSvc from "soa/kernel/clientDataModel";
import commandService from "js/command.service";
import clipboardService from "js/clipboardService";
import AwPromiseService from "js/awPromiseService";
import appCtxSvc from "js/appCtxService";
import vaasService from "js/BMWVaaSIntegrationService";
import eventBus from "js/eventBus";

let exports = {};

("use strict");

export let getPrismaJDBSinfo = function () {
  let dbUrl = appCtxSvc.getCtx(
    "preferences['V4B_VIM_DiC_CATIA_INTEG_JDBC_URL']"
  ),
    username = appCtxSvc.getCtx(
      "preferences['V4B_VIM_DiC_CATIA_INTEG_SYS_USER']"
    ),
    userpassword = appCtxSvc.getCtx(
      "preferences['V4B_VIM_DiC_CATIA_INTEG_SYS_USERPASS']"
    );
  userpassword = b64EncodeUnicodeUTF8(userpassword);
  return {
    dbUrl: dbUrl[0],
    username: username[0],
    userpassword: userpassword,
  };
};
function b64EncodeUnicodeUTF8(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
}
export let getStringPropsForVimContainer = function (vimCtx) {
  let designItemReVObject = appCtxSvc.getCtx("xrtSummaryContextObject");
  let stringProps = {
    v4b_TEIL_SNR: designItemReVObject.props.t4b_comp_TEIL_SNR.dbValues[0],
    v4b_VERS_IND: designItemReVObject.props.t4b_comp_Part_RevID.dbValues[0],
    v4b_DOKU_ALT: designItemReVObject.props.t4b_comp_DOKU_ALT.dbValues[0],
    v4b_DOKF_ID: "JT",
    v4b_DOKT_ID: designItemReVObject.props.t4b_comp_DOKT_ID.dbValues[0],
    v4b_DOKU_ID: vimCtx.prismaFindDocResponse.data.oede[0].v4b_ZBS_DOKU_ID,
    v4b_TEIL_BEN: vimCtx.prismaFindDocResponse.data.oede[0].v4b_ZBS_TEIL_BEN,
  };
  return stringProps;
};
let getOedeFromZAForm = function (zaForms) {
  let oedeArray = zaForms.map(function (eachZAForm) {
    //the below conditional line is required becuase 1 property (version index) is different in ZAForm and IssueRev
    let versionInd =
      _.get(eachZAForm.props, "v4b_VERS_IND.dbValues[0]") ||
      _.get(eachZAForm.props, "v4b_ZBS_ZI.dbValues[0]");

    let parentOedeStr =
      eachZAForm.props.v4b_ZBS_SNR.dbValues[0] +
      "  " +
      versionInd.trim() +
      " " +
      eachZAForm.props.v4b_ZBS_DOKU_TEIL.dbValues[0] +
      " " +
      eachZAForm.props.v4b_ZBS_DOKU_ALT.dbValues[0] +
      " " +
      eachZAForm.props.v4b_ZBS_DOKF_ID.dbValues[0] +
      " " +
      eachZAForm.props.v4b_ZBS_DOKT_ID.dbValues[0];

    let oedeObj = {
      oEDEAttributeValue: parentOedeStr,
    };
    let matrix = _.get(
      eachZAForm.props,
      "v4b_PRISMA_TRANS_MATRIX.dbValues[0]",
      null
    );
    if (matrix !== null && matrix !== "null") {
      matrix = matrix.replace(/[\[\]]+/g, "");
      oedeObj.matrixForSession = matrix.split(/[\s,]+/).map(Number);
    }

    return oedeObj;
  });
  return oedeArray;
};

export let getQueryInputForDIR = function () {
  let tokens = appCtxSvc.getCtx(
    "vimCtx.catiaResponse.Items[0].OEDE.split(/s+/)"
  );

  let queryInputs = [];
  let qryInput = {
    query: appCtxSvc.getCtx("vimCtx.savedQueryForDesignRevision"),
    //entries: ["PartItemID", "PartRevisionID", "DocumentPart", "Alternative", "DocumentFormat", "DocumentType"],
    //values: [tokens[0], tokens[1].trim(), tokens[2], tokens[3], tokens[4], tokens[5]],
    entries: [
      "PartItemID",
      "VersionID",
      "DocumentPart",
      "Alternative",
      "DocumentFormat",
      "DocumentType",
    ],
    values: [tokens[0], tokens[1], tokens[2], tokens[3], tokens[4], tokens[5]],
    maxNumToReturn: 50,
    clientId: "AWC",
  };
  queryInputs.push(qryInput);
  return queryInputs;
};

export let getOEDEAttributeValueAndCallCatia = function (output) {
  let data = [];
  data.push({
    Action: "OpenInCATIA",
    UserId: appCtxSvc.getCtx("userSession.props.user_id.dbValues[0]"),
    Items: [
      {
        OEDE: output.data.oede[0].oEDEAttributeValue,
      },
    ],
  });
  return data;
};

export let getZAFormPropAndCallCatia = function (zaForms) {
  let uids = zaForms.map((eachZAForm) => {
    return eachZAForm.uid;
  });
  zaForms = cdmSvc.getObjects(uids);
  let catInputs = getOedeFromZAForm(zaForms).map((eachOede) => {
    let data = {
      Action: "OpenInCATIA",
      UserId: appCtxSvc.getCtx("userSession.props.user_id.dbValues[0]"),
      Items: [
        {
          OEDE: eachOede.oEDEAttributeValue,
        },
      ],
    };
    return data;
  });

  return catInputs;
};

export let callPrismaToGetOEDEArray = function (vimCtx) {
  let zaForms = vimCtx.parentZAForms;
  let vmo = vimCtx.parentIssueRevision;
  let oedeArray = [];
  //Create oede input
  let docID = {
    documentId: appCtxSvc.getCtx("preferences.V4B_VIM_VGA_COLLECTION[0]"),
  };

  //Create servlet Input
  let second_level_st_node = {
    comment: "Aus VIM Client erzeugt",
    documentAlternative: "A",
    documentFormat: "ST",
    documentPart: 1,
    documentType: "PACKAG",
    module: "ARSTR",
    version: " A",
    partName: "Common geometry",
    objectName: "Common geometry",
    projectName: vmo.props.project_ids.uiValues[0].split(",")[0],
    qNumberCreator: appCtxSvc.getCtx("userSession.props.user_id.dbValues[0]"),
  };

  oedeArray.push(docID);
  oedeArray.push(second_level_st_node);

  //Create OEDE objects for all V4B_VGA_Forms.
  zaForms.forEach((eachForm) => {
    //Collect oede attributes of each form.
    let modelObjectODEDEAttr = {
      version: eachForm.props.v4b_VERS_IND.dbValues[0],
      documentFormat: eachForm.props.v4b_ZBS_DOKF_ID.dbValues[0],
      documentType: eachForm.props.v4b_ZBS_DOKT_ID.dbValues[0],
      documentAlternative: eachForm.props.v4b_ZBS_DOKU_ALT.dbValues[0],
      documentPart: eachForm.props.v4b_ZBS_DOKU_TEIL.dbValues[0],
      partNumber: eachForm.props.v4b_ZBS_SNR.dbValues[0],
    };

    //get matrix info
    let newMatrix = [];
    let matrix = eachForm.props.v4b_PRISMA_TRANS_MATRIX.dbValues[0];
    if (matrix && matrix !== null && matrix !== "null") {
      matrix = matrix.replace(/[\[\]]+/g, "");
      matrix = matrix.split(/[ ,]+/).map(Number);

      for (let inx = 1; inx <= matrix.length; inx++) {
        if (inx % 4 == 0) {
          console.log("skip index:" + inx);
        } else {
          newMatrix.push(matrix[inx - 1]);
        }
      }
      modelObjectODEDEAttr.matrixForSession = newMatrix;
    }
    //Push oede of each form to array.
    oedeArray.push(modelObjectODEDEAttr);
  });

  return oedeArray;
};

export let getAndRemoveFirstCatiaInput = function (vimCtx) {
  let catInput = [];
  if (vimCtx.catiaInputsForRecursiveCall.length > 0) {
    catInput = vimCtx.catiaInputsForRecursiveCall[0];
    vimCtx.catiaInputsForRecursiveCall.shift();
  }
  return catInput;
};

export let openGeometryInVaaSWrapper = function (vimCtx) {
  vimCtx.prismaResponse.forEach(function (eachOede) {
    vaasService.openGeometryInVaaS(vimCtx.parentIssueRevision.type, [
      eachOede.v4b_ZBS_DOKU_ID,
    ]);
  });
};

export let getOEDEForSelectedZAforms = function (selectionInfo) {
  let selectedZAForms = selectionInfo.map(function (eachInfo) {
    return cdmSvc.getObject(eachInfo.secondaryObject.uid);
  });
  let zaFormOede = getOedeFromZAForm(selectedZAForms);
  return zaFormOede;
};

export let getOEDEForDeleteRelation = function (parentZAForms, selInfo) {
  let zaFormOede = getOedeFromZAForm(parentZAForms);

  let childZAFormOede = selInfo.map(function (eachInfo) {
    let eachZAForm = cdmSvc.getObject(eachInfo.secondaryObject.uid);
    return {
      oEDEAttributeValue: eachZAForm.props.object_name.dbValues[0],
      instanceID: eachZAForm.props.v4b_ZBS_INST_ID.dbValues[0],
    };
  });

  return zaFormOede.concat(childZAFormOede);
};

export let isKonzSelectedInCatia = function (catiaHttpRep) {
  let konzFound = false;

  if (catiaHttpRep.data.Items && catiaHttpRep.data.Items != null) {
    catiaHttpRep.data.Items.forEach(function (catiaItem) {
      if (catiaItem.OEDE && catiaItem.OEDE.length >= 66) {
        // we are validating characters 63 to 66, so length should be at least 66 chars, otherwise ignore.
        // Validate for KONZ from character 63 to 66
        let oedeType = catiaItem.OEDE.substr(62, 4);
        if ("KONZ" === oedeType.toUpperCase()) {
          konzFound = true;
          return;
        }
      }
    });
  }
  return konzFound;
};

export let removeKonzModelsFromCatiaResp = function (catiaResp) {
  let newItems = catiaResp.Items.filter(function (catiaItem) {
    if (catiaItem.OEDE && catiaItem.OEDE.length >= 66) {
      // we are validating characters 63 to 66, so length should be at least 66 chars, otherwise ignore.
      // Validate for KONZ from character 63 to 66
      let oedeType = catiaItem.OEDE.substr(62, 4);
      return "KONZ" !== oedeType.toUpperCase();
    }
    return false;
  });

  catiaResp.Items = newItems;
  return catiaResp;
};

export let getOEDEForAddCatiaSelection = function (vimCtx) {
  let parentObj =
    vimCtx.relationName === "IsM0SnapshotBeforeFix"
      ? [vimCtx.parentIssueRevision]
      : vimCtx.parentZAForms;

  let zaFormOede = getOedeFromZAForm(parentObj);
  let catiaSelections = vimCtx.catiaResponse.Items.map(function (eachItem) {
    return {
      oEDEAttributeValue: eachItem.OEDE,
      matrixForSession: eachItem.Matrix,
      documentId: eachItem.DocumentId,
    };
  });

  return zaFormOede.concat(catiaSelections);
};
/**
 *
 * @param {Object} vimCtx vim context Object
 * @returns [] oededataArray
 */
export let getOEDEForAssignSERights = function (vimCtx) {
  let oedeData = {
    v4b_ZBS_DOKU_TEIL_INT: 1,
    oEDEAttributeValue: _.get(vimCtx, "topLevelOedeNode", undefined),
    v4b_ZBS_DOKF_ID: _.get(
      vimCtx,
      "parentIssueRevision.props.v4b_ZBS_DOKF_ID.dbValues[0]",
      undefined
    ),
    v4b_ZBS_DOKT_ID: _.get(
      vimCtx,
      "parentIssueRevision.props.v4b_ZBS_DOKT_ID.dbValues[0]",
      undefined
    ),
    v4b_ZBS_DOKU_ALT: _.get(
      vimCtx,
      "parentIssueRevision.props.v4b_ZBS_DOKU_ALT.dbValues[0]",
      undefined
    ),
    v4b_ZBS_DOKU_TEIL: _.get(
      vimCtx,
      "parentIssueRevision.props.v4b_ZBS_DOKU_TEIL.dbValues[0]",
      undefined
    ),
    v4b_ZBS_SNR: _.get(
      vimCtx,
      "parentIssueRevision.props.v4b_ZBS_SNR.dbValues[0]",
      undefined
    ),
    qNumberSolutionResponsible: _.get(
      appCtxSvc,
      "ctx.userSession.props.user_id.dbValue",
      undefined
    ),
  };
  return [oedeData];
};
export let getSuccessfulPrismaOede = function (prismaResp) {
  let passedOede = prismaResp.data.oede.filter(function (eachOede) {
    return eachOede.lastError === undefined || eachOede.lastError === "";
  });
  return passedOede;
};

export let getPrismaErrorMessage = function (prismaResp) {
  let errMsg = "";
  prismaResp.data.oede.forEach(function (eachOede) {
    if (eachOede.lastError && eachOede.lastError !== "") {
      errMsg = "<br>" + eachOede.v4b_ZBS_SNR + " : " + eachOede.lastError;
    }
  });
  return errMsg;
};

export let createAndAttachFormInput = function (
  createRelPrismaResp,
  catiaResponse,
  targetObject,
  relationName
) {
  //merge the prisma and catia output. Assuming the length and order will be the same
  //v4b_PRISMA_TRANS_MATRIX matrix value set as null beacuse "Add CATIA session" service return a MATRIX value as null
  let newOedeArray = createRelPrismaResp.map(function (eachOede, inx) {
    return Object.assign({}, eachOede, catiaResponse[inx]);
  });
  let createFromInputs = newOedeArray.map(function (eachOede) {
    let matrix =
      eachOede.Matrix === undefined || eachOede.Matrix === null
        ? []
        : eachOede.Matrix;
    let module = eachOede.Module || "";
    let attrMap = {
      v4b_ZBS_DOKU_ALT: [eachOede.v4b_ZBS_DOKU_ALT],
      v4b_ZBS_DOKF_ID: [eachOede.v4b_ZBS_DOKF_ID],
      v4b_VERS_IND: [eachOede.v4b_VERS_IND],
      v4b_ZBS_DOKT_ID: [eachOede.v4b_ZBS_DOKT_ID],
      v4b_ZBS_DOKU_TEIL: [eachOede.v4b_ZBS_DOKU_TEIL],
      v4b_ZBS_SNR: [eachOede.v4b_ZBS_SNR],
      v4b_ZBS_INST_ID: [eachOede.v4b_ZBS_INST_ID],
      v4b_PRISMA_TRANS_MATRIX: [matrix.join()],
      v4b_geometry_module: [module],
      v4b_PA_ITEM_ID: [targetObject.props.awp0Item_item_id.dbValues[0]],
    };

    let creFromInput = {
      clientId: "CreateForm",
      parentObject: targetObject,
      relationName: relationName,
      saveDB: true,
      name: eachOede.oEDEAttributeValue,
      description: "",
      formType: "V4B_DIC_ZA",
      attributesMap: attrMap,
    };

    return creFromInput;
  });
  return createFromInputs;
};

export let getValidLocalSelection = function (
  selectionInfo,
  localRelationName
) {
  let localSelection = [];
  let selectedvalue = appCtxSvc.getCtx("mselected[0]");
  if (selectedvalue.type == 'V4B_DIC_ZA' || selectedvalue.type == 'V4B_VGA_ZA' || selectedvalue.type == 'DirectModelAssembly' || selectedvalue.type == 'V4B_PrismaDirectMdlAssembly') {
    if (selectionInfo) {
      selectionInfo.forEach((eachInfo) => {
        if (eachInfo.relationType === localRelationName) {
          localSelection.push(eachInfo.secondaryObject);
        }
      });
    }
  }

  return localSelection;
};

export let getRemoveZAFormInput = function (prismaOede, selectionInfo) {
  //remove the selectios for which prisma has error
  let delRelInput = [];
  selectionInfo.forEach(function (eachSel, inx) {
    if (
      prismaOede[inx].lastError === undefined ||
      prismaOede[inx].lastError === ""
    ) {
      delRelInput.push({
        relationType: eachSel.relationType,
        primaryObject: eachSel.primaryObject,
        secondaryObject: eachSel.secondaryObject,
      });
    }
  });
  return delRelInput;
};
export let getOedeStringForIssue = function (getPropResp) {
  let issueRev = cdmSvc.getObject(
    appCtxSvc.getCtx("vimCtx.parentIssueRevision.uid")
  );
  let issueOede =
    _.get(issueRev.props, "v4b_ZBS_SNR.dbValues[0]") +
    "  " +
    _.get(issueRev.props, "v4b_ZBS_ZI.dbValues[0]") +
    " " +
    _.get(issueRev.props, "v4b_ZBS_DOKU_TEIL.dbValues[0]") +
    " " +
    _.get(issueRev.props, "v4b_ZBS_DOKU_ALT.dbValues[0]") +
    " " +
    _.get(issueRev.props, "v4b_ZBS_DOKF_ID.dbValues[0]") +
    " " +
    _.get(issueRev.props, "v4b_ZBS_DOKT_ID.dbValues[0]");

  console.log(issueOede);

  // if any property is not set/undefined, return null
  if (
    issueOede.indexOf("undefined") !== -1 ||
    issueOede.indexOf("null") !== -1
  ) {
    return null;
  }
  return issueOede;
};

export let getCatiaCreateSessionInput = function (vimCtx) {
  let catiaInput = {};
  if (vimCtx.activeCommand === "V4B_AddCatiaSession") {
    catiaInput = {
      Action: "CreateSession",
      UserId: appCtxSvc.getCtx("userSession.props.user_id.dbValues[0]"),
      TCParentID: "",
      ParentID: vimCtx.topLevelOedeNode,
      Project: appCtxSvc.getCtx("selected.props.project_ids.dbValues[0]"),
      Module: "51.00",
      SystemUserId: appCtxSvc.getCtx(
        'preferences["V4B_VIM_DiC_CATIA_INTEG_SYS_USER"][0]'
      ),
    };
  } else if (vimCtx.activeCommand === "V4B_AddOwnCatiaSession") {
    catiaInput = {
      Action: "UseExistingSession",
      UserId: appCtxSvc.getCtx("userSession.props.user_id.dbValues[0]"),
      ParentID: vimCtx.topLevelOedeNode,
      DocumentLimit: 100,
    };
  }

  return catiaInput;
};

export let getCreAndAttach3DFormInput = function (
  catiaResponse,
  targetObj,
  relName
) {
  let cre3DFormInputs;

  let sessionOede = catiaResponse.Items.map((eachItem) => {
    let tokens = eachItem.OEDE.split(/\s+/);
    let instID = eachItem.InstanceId || "0";
    let oedeOBj = {
      v4b_ZBS_SNR: tokens[0],
      v4b_VERS_IND: tokens[1],
      v4b_ZBS_DOKU_TEIL: tokens[2],
      v4b_ZBS_DOKU_ALT: tokens[3],
      v4b_ZBS_DOKF_ID: tokens[4],
      v4b_ZBS_DOKT_ID: tokens[5],
      v4b_ZBS_INST_ID: instID + "",
      oEDEAttributeValue: eachItem.OEDE,
    };
    return oedeOBj;
  });

  cre3DFormInputs = exports.createAndAttachFormInput(
    sessionOede,
    catiaResponse.Items,
    targetObj,
    relName
  );
  return cre3DFormInputs;
};

export let getDummyCatiaRespForPaste = function (ctx) {
  // registering the context here

  let catItems = ctx.awClipBoardProvider.map((eachVMO) => {
    let item = {};

    let eachZAForm = cdmSvc.getObject(eachVMO.uid);
    item.OEDE = eachZAForm.props.object_name.dbValues[0];
    item.Module = eachZAForm.props.v4b_geometry_module.dbValues[0];
    item.documentId = "";

    let matrix = eachZAForm.props.v4b_PRISMA_TRANS_MATRIX.dbValues[0];
    if (matrix && matrix !== null && matrix !== "null") {
      matrix = matrix.replace(/[\[\]]+/g, "");
      item.Matrix = matrix.split(",").map(Number);
    }

    return item;
  });

  let catResp = {
    data: {
      Action: "AddCatiaSelection",
      Error: [0],
      Items: catItems,
    },
  };

  return catResp;
};

export let pasteZAForm = function (pasteCtx) {
  let cmdCtx = {
    vmo: pasteCtx.targetObject,
    objectSetSource:
      pasteCtx.relationType + "." + pasteCtx.sourceObject[0].type,
  };

  clipboardService.copyHyperlinkToClipboard(pasteCtx.sourceObject);
  //ClipboardService.instance.copyHyperlinkToClipboard(pasteCtx.sourceObject);

  commandService.executeCommand("V4B_Paste", null, null, cmdCtx);
};

export let getVirtualCars = function (getPropResp) {
  let issueRev = cdmSvc.getObject(getPropResp.plain[0]);
  return issueRev.props.v4b_virt_fzg.dbValues;
};

export let getModuleName2DescMap = function (lovResp) {
  let map = {};
  lovResp.lovValues.forEach((eachLOVData) => {
    map[eachLOVData.propDisplayValues.lov_values[0]] =
      eachLOVData.propDisplayValues.lov_value_descriptions[0];
  });
  return map;
};

export let getValidPasteObjects = function (
  pasteRel2ObjMap,
  relName,
  targetObj
) {
  let validObjs = [];
  let validObjTypes = pasteRel2ObjMap[targetObj.type + "_" + relName] || [];
  if (validObjTypes.length > 0) {
    let sourceObjects = clipboardService.getContents();
    validObjs = sourceObjects.filter((eachMO) => {
      return validObjTypes.includes(eachMO.type);
    });
  }
  return validObjs;
};

export let getOEDEForZBSOperation = function (vimCtx) {
  let issueRev = cdmSvc.getObject(vimCtx.parentIssueRevision.uid);
  let oedeInput = [];

  //prepare solution geometries OEDE
  let allSolZAForms = cdmSvc.getObjects(
    issueRev.props.V4B_ZA_Solution_Temp.dbValues
  );
  allSolZAForms.forEach((eachZAForm) => {
    let input = {};
    input.oEDEAttributeValue = eachZAForm.props.object_name.dbValues[0];
    input.transMatrix =
      eachZAForm.props.v4b_PRISMA_TRANS_MATRIX.dbValues[0].replace(
        /[\[\]]+/g,
        ""
      );
    input.department = eachZAForm.props.v4b_FB.dbValues[0];
    input.module = _.get(
      eachZAForm.props,
      "v4b_geometry_module.dbValues[0]",
      ""
    ).substr(0, 2);
    input.moduleDesc =
      input.module === ""
        ? ""
        : _.get(vimCtx.moduleName2DescMap, input.module, "");
    input.moduleDesc = btoa(input.moduleDesc);

    oedeInput.push(input);
  });

  //prepare virtual car OEDE
  let allVirCar = cdmSvc.getObjects(issueRev.props.v4b_virt_fzg.dbValues);
  allVirCar.forEach((eachVirCar) => {
    let input = {};
    input.oEDEAttributeValue = eachVirCar.props.v4b_zbs_oede.dbValues[0];
    input.projectName = eachVirCar.props.project_ids.dbValues[0].split(",")[0];
    input.virtualCarName = eachVirCar.props.object_name.dbValues[0];
    input.bauphase = eachVirCar.props.v4b_bauphase_lov.dbValues[0];

    let loginUser = appCtxSvc.getCtx(
      'userSession.props.user.displayValues[0].split(" (")[1]'
    );
    loginUser = loginUser.split(")")[0];
    input.qNumberCreator = loginUser;

    oedeInput.push(input);
  });

  return oedeInput;
};

//return the oede string to find doc id.
export let getOEDEString = function (data) {
  let OEDEString;
  let contextObject = _.get(appCtxSvc, "ctx.xrtSummaryContextObject");
  if (
    contextObject &&
    contextObject.modelType.typeHierarchyArray.indexOf(
      "T4B_DesignItemRevision"
    ) > -1
  ) {
    OEDEString =
      contextObject.props.t4b_comp_TEIL_SNR.dbValue +
      " " +
      contextObject.props.t4b_comp_Part_RevID.dbValue +
      " " +
      contextObject.props.t4b_comp_DOKU_TEIL.dbValue +
      " " +
      contextObject.props.t4b_comp_DOKU_ALT.dbValue +
      " " +
      "JT" +
      " " +
      contextObject.props.t4b_comp_DOKT_ID.dbValue;
  } else {
    //Vim Container creation
    let selectedObject =
      data.dataProviders.filteredDesignItemDataProvider.selectedObjects[0];
    OEDEString =
      selectedObject.props.t4b_comp_TEIL_SNR.dbValue +
      "  " +
      selectedObject.props.t4b_comp_Part_RevID.dbValue +
      " " +
      selectedObject.props.t4b_comp_DOKU_TEIL.dbValue +
      " " +
      selectedObject.props.t4b_comp_DOKU_ALT.dbValue +
      " " +
      "JT" +
      " " +
      selectedObject.props.t4b_comp_DOKT_ID.dbValue;
  }
  return OEDEString;
};

export let getDP = function (data) {
  let docuteil;
  let contextObject = appCtxSvc.getCtx("xrtSummaryContextObject");
  if (
    contextObject.modelType.typeHierarchyArray.indexOf(
      "T4B_DesignItemRevision"
    ) > -1
  ) {
    docuteil = contextObject.props.t4b_comp_DOKU_TEIL.dbValue;
  } else {
    docuteil = data.DOKUTEILInput.dbValue;
  }
  return docuteil;
};

//check VCON V4B_TEIL_SNR is match with FTM Issue Part Number.
export let checkVCONSNRMatchWithFTMIssuePartNumber = function (data) {
  let isVCONSNRMatchWithFTMIssueSNR = "NoMatchFound";
  let ftmIssuueObj = cdmSvc.getObject(data.savedQueryResult.objectUIDS[0]);
  let ftmIssuePartNumbers = _.get(
    ftmIssuueObj.props,
    "v4b_ftm_oede_attributes"
  );
  let vconSNR = _.get(
    appCtxSvc.getCtx("xrtSummaryContextObject.props"),
    "v4b_TEIL_SNR"
  );
  //compare part number
  for (let j = 0; j < ftmIssuePartNumbers.dbValues.length; j++) {
    if (vconSNR.dbValue === ftmIssuePartNumbers.dbValues[j]) {
      isVCONSNRMatchWithFTMIssueSNR = "FullMatchFound";

      break;
    }
  }

  // check if user has write access to the issue
  if (ftmIssuueObj.props.is_modifiable.dbValues[0] == "0") {
    isVCONSNRMatchWithFTMIssueSNR = "IssueNotModifiable";
  }

  return isVCONSNRMatchWithFTMIssueSNR;
};

//check get the Part Number of Design Item attached to FTM .
export let getFTMAttachedDesignItemPartNumber = function (data) {
  let isVCONSNRMatchWithFTMDesignSNR;
  let ftmIssuueObj = cdmSvc.getObject(data.savedQueryResult.objectUIDS[0]);
  let ftmDsUID = _.get(ftmIssuueObj.props, "CMHasProblemItem");
  if (ftmDsUID.dbValues.length != 0) {
    let ftmDsObject = cdmSvc.getObject(ftmDsUID.dbValues[0]);
    let ftmDsPartNumber = _.get(ftmDsObject.props, "t4b_comp_TEIL_SNR");
    let vconSNR = _.get(
      appCtxSvc.getCtx("xrtSummaryContextObject.props"),
      "v4b_TEIL_SNR"
    );
    if (vconSNR.dbValue === ftmDsPartNumber.dbValues[0]) {
      isVCONSNRMatchWithFTMDesignSNR = "FullMatchFound";
    } else {
      isVCONSNRMatchWithFTMDesignSNR = "MisMatchFound";
    }
  } else {
    isVCONSNRMatchWithFTMDesignSNR = "NoMatchFound";
  }
  return isVCONSNRMatchWithFTMDesignSNR;
};

//This function save the changes
export let vimSaveEditsFunction = function () {
  let deferred = AwPromiseService.instance.defer();

  appCtxSvc
    .getCtx("NONE")
    .saveEdits()
    .then(function () {
      appCtxSvc.updateCtx("vimCtx.vimIssueWasInEditMode", true);
      deferred.resolve();
    });

  return deferred.promise;
};
//This function start the edit
export let vimStartEditSummaryAction = function () {

  var ctx = appCtxSvc.getCtx("selected");
  eventBus.publish("cdm.relatedModified", {
    relatedModified: [appCtxSvc.getCtx("selected")],
    refreshLocationFlag: false,
    relations: "",
  });
  var deferred = AwPromiseService.instance.defer();
  appCtxSvc.getCtx("NONE").startEdit().then(function () {
    //appCtxSvc.updateCtx("vimCtx.vimIssueWasInEditMode", false);
    deferred.resolve();
  });

  return deferred.promise;
};
//This function start the save, refresh and start edit
export let saveIssueAfterUpdateRefreshAndStartEdit = function () {
  let deferred = AwPromiseService.instance.defer();

  appCtxSvc.getCtx("NONE").saveEdits().then(function () {
    eventBus.publish("cdm.relatedModified", {
      relatedModified: [appCtxSvc.getCtx("selected")],
      refreshLocationFlag: false,
      relations: "",
    });
    vimStartEditSummaryAction();
    deferred.resolve();
  });

  return deferred.promise;
};


// This function gets the project assignment input for current logged in user's project
export let getProjectListFromVimContainer = function (response, data) {
  let projectList = [];
  let ftmUID;
  if (data.savedQueryResult != undefined) {
    ftmUID = data.savedQueryResult.objectUIDS[0];
  } else {
    if (data.createdFTMObject != undefined) {
      ftmUID = data.createdFTMObject.uid;
    }
  }
  if (!ftmUID) {
    ftmUID = appCtxSvc.getCtx("relationContext.relationInfo[0].secondaryObject.uid");
  }
  let vimContainerprojectUIDs = appCtxSvc.getCtx(
    "xrtSummaryContextObject.props.project_list.dbValues"
  );
  for (let i = 0; i < response.userProjectInfos[0].projectsInfo.length; i++) {
    let userProject = response.userProjectInfos[0].projectsInfo[i].project.uid;
    let filteredProject = vimContainerprojectUIDs.filter(function (each) {
      return userProject == each;
    });
    if (filteredProject[0] != undefined) {
      let project = {
        uid: filteredProject[0],
        type: "TC_Project",
      };
      projectList.push(project);
    }
  }
  let input = [
    {
      projectsToAssign: projectList,
      objectsForAssignment: [
        {
          uid: ftmUID,
          type: "V4B_FTMTKB_IssueRevision",
        },
      ],
      projectsForRemoval: [],
      objectsToRemoveFromProjects: [],
      contextInfo: {
        selectedTopLevelObject: {
          uid: ftmUID,
          type: "V4B_FTMTKB_IssueRevision",
        },
        variantRule: {},
        revisionRule: {},
        typeOption: 1,
        depth: 0,
      },
      processAsynchronously: false,
    },
  ];
  return input;
};

export let getFTMandVconCreateRelInput = function (data, ctx) {
  let creRelInput = [
    {
      clientId: "VIMContainer",
      relationType: "V4B_Container_VIM_FTM_Rel",
      primaryObject: ctx.xrtSummaryContextObject,
      secondaryObject: {
        uid: data.savedQueryResult.objectUIDS[0],
        type: "V4B_FTMTKB_IssueRevision",
      },
    },
  ];

  //check Design Item revision
  let ftm =
    data.VIMCONIServiceData.modelObjects[data.savedQueryResult.objectUIDS[0]];
  let ftmDsUID = _.get(ftm, "props.CMHasProblemItem.dbValues[0]");
  if (!ftmDsUID) {
    let vcon = data.getPropResp.modelObjects[ctx.xrtSummaryContextObject.uid];
    let dsUID = _.get(vcon, "props.IMAN_reference.dbValues[0]");
    if (dsUID) {
      let secInput = {
        clientId: "FTM issue",
        relationType: "CMHasProblemItem",
        primaryObject: {
          uid: data.savedQueryResult.objectUIDS[0],
          type: "V4B_FTMTKB_IssueRevision",
        },
        secondaryObject: data.getPropResp.modelObjects[dsUID],
      };
      creRelInput.push(secInput);
    }
  }
  return creRelInput;
};
let getMessageString = function (messages, msgObj) {
  _.forEach(messages, function (object) {
    if (msgObj.msg.length > 0) {
      msgObj.msg += "<BR/>";
    }
    msgObj.msg += object.message;
    msgObj.level = _.max([msgObj.level, object.level]);
  });
};

export let processPartialErrors = function (serviceData) {
  let msgObj = {
    msg: "",
    level: 0,
  };
  if (serviceData.partialErrors) {
    getMessageString(serviceData.partialErrors[0].errorValues, msgObj);
  }

  return msgObj.msg;
};

export let getattachedFTMIssue = function (serviceData) {
  let attachedFTMIssueUID;
  let attachedFTMIssueName;
  if (serviceData.updated) {
    attachedFTMIssueUID = serviceData.updated[0];
    attachedFTMIssueName =
      serviceData.modelObjects[attachedFTMIssueUID].props.object_name
        .dbValues[0];
  }

  return attachedFTMIssueName;
};

export let getParentZAForm = function (resp) {
  let parentZAForms = resp.output[0].otherSideObjData[0].otherSideObjects;
  let issueObj = resp.output[0].inputObject;
  if (
    issueObj.type === "V4B_FTMTKB_IssueRevision" &&
    parentZAForms == undefined
  ) {
    // take the 4th index form which is the 3D session form
    console.log(
      "==== Selected FTM Issue seems to be old issue. Using the the 3D-Session form as a parent ZA form ===="
    );
    parentZAForms = resp.output[0].otherSideObjData[4].otherSideObjects;
  }

  return parentZAForms;
};

export let getParentAndChildRefZAForm = function (data) {
  let childZAForms = null;
  //getDesignPart oede
  let designPartOedeStr = getDesignPartOEDE();
  let parentZAForms = data.output[0].otherSideObjData[1].otherSideObjects[0];
  if (data.output[0].otherSideObjData[0].otherSideObjects != undefined) {
    data.output[0].otherSideObjData[0].otherSideObjects.forEach((eachForm) => {
      let childFormOedeStr = eachForm.props.object_name.dbValues[0];
      if (childFormOedeStr.localeCompare(designPartOedeStr) == 0) {
        childZAForms = eachForm;
      }
    });
  }
  if (appCtxSvc.getCtx("vimCtx.childDiCZAForm")) {
    appCtxSvc.updateCtx("vimCtx.childDiCZAForm", childZAForms);
    appCtxSvc.updateCtx("vimCtx.parentZAForms", parentZAForms);
  }
  else {
    appCtxSvc.registerCtx("vimCtx.childDiCZAForm", childZAForms);
    appCtxSvc.registerCtx("vimCtx.parentZAForms", parentZAForms);
  }
  var ctxData = appCtxSvc.ctx;
  console.log(ctxData)
  //oede string for delete relation in prisma
  let oedeDetails = [];
  if (childZAForms != null) {
    oedeDetails = [
      {
        oEDEAttributeValue: parentZAForms.props.object_name.dbValues[0],
      },
      {
        oEDEAttributeValue: designPartOedeStr,
        instanceID: childZAForms.props.v4b_ZBS_INST_ID.dbValues[0],
      },
    ];
  }
  return oedeDetails;
};

export let getDesignPartOEDE = function () {

  let designPart = appCtxSvc.getCtx("selected");
  let designPartOedeStr = designPart.props.t4b_comp_TEIL_SNR.dbValues[0] +
    " " +
    designPart.props.t4b_comp_Part_RevID.dbValues[0] +
    " " +
    designPart.props.t4b_comp_DOKU_TEIL.dbValues[0] +
    " " +
    designPart.props.t4b_comp_DOKU_ALT.dbValues[0] +
    " " +
    designPart.props.t4b_comp_DOKF_ID.dbValues[0] +
    " " +
    designPart.props.t4b_comp_DOKT_ID.dbValues[0];
  console.log(designPartOedeStr);
  return designPartOedeStr;
};

export let callV4B_AddDesignItemRevToIssueBridgeCommand = function (
  selectedDIR,
  vimCtx,
  runActionWithViewModel
) {
  //prepare fake catia selection input
  let odedStr = selectedDIR.props.object_name.uiValues[0]
    .split("_")
    .slice(0, 6)
    .join(" ");
  let dokuID = _.get(selectedDIR, "props.t4b_comp_DOKU_ID.dbValues[0]", "0");
  let module = _.get(
    selectedDIR,
    "props.t4b_comp_Part_MODUL_NR.dbValues[0]",
    ""
  );

  if (vimCtx.relationName == "CMHasProblemItem") {
    vimCtx.commandContext.objectSetSource = "V4B_ZA_Problem_Temp.V4B_DIC_ZA";
  }
  vimCtx.commandContext.catiaResponse = {
    Action: "AddCatiaSelection",
    Error: [0],
    Items: [
      {
        DocumentId: Number(dokuID),
        Matrix: null,
        Module: module,
        OEDE: odedStr,
      },
    ],
  };

  // execute bridge command
  commandService.executeCommand(
    "V4B_AddDesignItemRevToIssueBridge",
    null,
    null,
    vimCtx.commandContext,
    runActionWithViewModel
  );
};

export let validateFTMTypeTU = function (data, vimCtx) {
  let isValidFTM = true;

  //validation is only for TU FTM type and Reference geometry section
  if (
    vimCtx.parentIssueRevision.props.v4b_ftm_type.dbValues[0] === "TU" &&
    vimCtx.relationName === "V4B_ZA_Problem_Temp"
  ) {
    //check if there are already forms attached and how many catia geometries are selected
    let currentZAForms = vimCtx.allSecondaryZAForms || []; // this could be undefined
    let catiaSelection = vimCtx.catiaResponse.Items || [];
    // invalid when more than zero forms found or more than 1 catia selection found
    isValidFTM = !(currentZAForms.length > 0 || catiaSelection.length > 1);
  }

  return isValidFTM;
};
exports = {
  getPrismaJDBSinfo,
  getStringPropsForVimContainer,
  getQueryInputForDIR,
  getOEDEAttributeValueAndCallCatia,
  getZAFormPropAndCallCatia,
  callPrismaToGetOEDEArray,
  getAndRemoveFirstCatiaInput,
  openGeometryInVaaSWrapper,
  getOEDEForSelectedZAforms,
  getOEDEForDeleteRelation,
  isKonzSelectedInCatia,
  removeKonzModelsFromCatiaResp,
  getOEDEForAddCatiaSelection,
  getOEDEForAssignSERights,
  getSuccessfulPrismaOede,
  getPrismaErrorMessage,
  createAndAttachFormInput,
  getValidLocalSelection,
  getRemoveZAFormInput,
  getOedeStringForIssue,
  getCatiaCreateSessionInput,
  getCreAndAttach3DFormInput,
  getDummyCatiaRespForPaste,
  pasteZAForm,
  getVirtualCars,
  getModuleName2DescMap,
  getValidPasteObjects,
  getOEDEForZBSOperation,
  getOEDEString,
  getDP,
  checkVCONSNRMatchWithFTMIssuePartNumber,
  getFTMAttachedDesignItemPartNumber,
  vimSaveEditsFunction,
  vimStartEditSummaryAction,
  saveIssueAfterUpdateRefreshAndStartEdit,
  getProjectListFromVimContainer,
  getFTMandVconCreateRelInput,
  processPartialErrors,
  getattachedFTMIssue,
  getParentZAForm,
  callV4B_AddDesignItemRevToIssueBridgeCommand,
  validateFTMTypeTU,
  getParentAndChildRefZAForm,
  getDesignPartOEDE
};
export default exports;
