// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "vm": "AwSearchBreadcrumb"
            },
            "viewModes": {
                "SummaryView": {
                    "primaryWorkArea": "list",
                    "secondaryWorkArea": true
                },
                "ImageView": {
                    "primaryWorkArea": "image",
                    "secondaryWorkArea": false
                }
            },
            "defaultDisplayMode": "ImageView",
            "priority": 1,
            "label": {
                "source": "/i18n/ReportsConstants",
                "key": "myDashboard"
            },
            "clientScopeURI": "Awp0ReportBuilder",
            "nameToken": "com.siemens.splm.reports:showMyDashboard",
            "selectionMode": "single",
            "viewBase": "showMyDashboard",
            "context": {
                "search": {
                    "hideInContentSearchBox": true
                }
            }
        },
        "searchBox": {
            "displayName": "",
            "type": "STRING",
            "isRequired": "true",
            "dbValue": "{{ctx.awp0SummaryReports.reportFilter}}",
            "dispValue": "{{ctx.awp0SummaryReports.reportFilter}}"
        }
    },
    "_viewModelId": "showMyDashboard",
    "_uniqueViewModelId": "showMyDashboard",
    "ctx": {
        "awp0SummaryReports": {
            "type": "object"
        }
    }
};

    /*
    <aw-search-page tabsdata="subPanelContext.tabsData" provider="data._provider"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;