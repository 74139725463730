// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwWidget from 'viewmodel/AwWidgetViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwClsReleaseBoxService', () => import('js/AwClsReleaseBoxService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-widget.directive"
    ],
    "lifecycleHooks": {
        "onMount": "releases",
        "onUpdate": {
            "action": "releases",
            "observers": [
                "props.releasesState.value.releasesString"
            ]
        }
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        },
        "releases-state": {
            "type": "object"
        }
    },
    "actions": {
        "initialize": {
            "actionType": "JSFunction",
            "method": "initialize",
            "inputData": {
                "eventData": "{{data.eventData}}",
                "release": "{{props.releasesState}}",
                "releases": "{{ctx.preferences.CST_supported_eclass_releases}}"
            },
            "deps": "js/AwClsReleaseBoxService"
        },
        "releases": {
            "actionType": "JSFunction",
            "method": "getReleases",
            "inputData": {
                "isClsActive": "{{ctx.preferences.CLS_is_presentation_hierarchy_active}}",
                "releases": "{{ctx.preferences.CST_supported_eclass_releases}}",
                "filter": "{{data.releasesLOV.filterString}}",
                "prevReleases": "{{props.releasesState}}",
                "releaseLOV": "{{data.releasesLOV}}"
            },
            "outputData": {
                "releases": "releases",
                "releasesLOV": "releasesLOV"
            },
            "deps": "js/AwClsReleaseBoxService"
        }
    },
    "functions": {},
    "dataProviders": {
        "listProvider": {
            "action": "releases",
            "response": "{{data.releases}}",
            "totalFound": "{{data.releases.length}}"
        }
    },
    "columnProviders": {},
    "grids": {},
    "data": {
        "releasesLOV": {
            "displayName": "{{i18n.release}}",
            "type": "CHECKBOX",
            "isArray": true,
            "dataProvider": "listProvider",
            "renderingHint": "checkboxoptionlov"
        }
    },
    "onEvent": [
        {
            "eventId": "listProvider.validSelectionEvent",
            "cacheEventData": true,
            "action": "initialize"
        }
    ],
    "conditions": {},
    "i18n": {
        "release": [
            "ClassificationPanelMessages"
        ]
    },
    "_viewModelId": "AwClsReleaseBox",
    "_uniqueViewModelId": "AwClsReleaseBox",
    "ctx": {
        "preferences": {
            "type": "object"
        }
    }
};

    /*
    <aw-widget prop ="data.releasesLOV"></aw-widget>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwWidget {...getField("data.releasesLOV", fields, $index, null, null )}>
    </AwWidget>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;