/* eslint-disable valid-jsdoc */
// Copyright 2020 Siemens Product Lifecycle Management Software Inc.

import appCtxSvc from 'js/appCtxService';
import messagingSvc from 'js/messagingService';
import AwPromiseService from 'js/awPromiseService';
import _ from 'lodash';
import eventBus from 'js/eventBus';
import AwTimeoutService from 'js/awTimeoutService';
import localeSvc from 'js/localeService';
import dmsSvc from 'soa/dataManagementService';
import tcooSvc from 'js/tcooService';
import logger from 'js/logger';
import notyService from 'js/NotyModule';
import cdm from 'soa/kernel/clientDataModel';
import adapterService from 'js/adapterService';
import browserUtils from 'js/browserUtils';

/**
 * Holds array of dom element id's that need to be hidden for protecting tcoo viewer from
 * potential loss of edits due to viewer discarding current file.
 */
var _idsToHide = [ 'Awp0CancelCheckout', 'Awp0Checkin', 'Awp0LeftChevron', 'Awp0RightChevron' ];

/**
 * Subscribe to preCheckin.failure event
 */
var checkInFailureEventSub;
/**
 * Subscribe to cdm.updated event
 */
var cdmUpdatedEventSub;

const showProgressString = 'data.data.showProgress';
const dataString = 'data.data';
const refreshViewerProp = 'tcooViewerInfo.refreshViewer';

var hostPageHandle;

export const hostedInOfficeClient = function() {
    var hostedInOC = false;
    var hosted = appCtxSvc.getCtx( 'aw_hosting_enabled' );
    if( hosted && hosted === true ) {
        var hostType = appCtxSvc.getCtx( 'aw_host_type' );
        if( hostType && hostType === 'OC' ) {
            hostedInOC = true;
        }
    }

    return hostedInOC;
};

export const setupEventSubscription = function( viewModelData, dispatch, elementRefList ) {
    /**
     * The React way to register to the precheckin event callback function, i.e., setting it on the viewModel.data.
     * UV will look for all the registered "preCheckin" function there. The triggering of the callback function
     * will only pass in the vmo. To use viewModel.data, it has to be from the parent function. DO NOT add viewModel.data as
     * a param in the following function, it will be undefined.
     */
    viewModelData.preCheckin = function( vmo ) {
        var deferred = AwPromiseService.instance.defer();
        if( hostPageHandle ) {
            hostPageHandle.close();
            hostPageHandle = null;
        }
        exports.reloadIframe( dispatch, deferred, 30, 3000, vmo );
        return deferred.promise;
    };

    /**
     * There is no action tcoo needs to do before cancel-checkout. Just need to send users confirmation so that
     * users won't lose their changes by accident.
     */
    viewModelData.preCancelCheckout = function() {
        var deferred = AwPromiseService.instance.defer();
        var fileName = viewModelData.viewerData.fileData ? viewModelData.viewerData.fileData.file.cellHeader1 : 'File';
        var message = viewModelData.viewerMessages.cancelCheckoutMessageText.format( fileName );
        var buttonArray = [];
        // cancel button
        buttonArray.push( createButton( viewModelData.viewerMessages.cancel, function( notyService ) {
            notyService.close();
        } ) );
        // continue button
        buttonArray.push( createButton( viewModelData.viewerMessages.continue, function( notyService ) {
            notyService.close();
            if( hostPageHandle ) {
                hostPageHandle.close();
                hostPageHandle = null;
            }
            deferred.resolve();
            return deferred.promise;
        } ) );

        notyService.showWarning( message, buttonArray );

        return deferred.promise;
    };

    /**
     * Subscribe to preCheckin.failure event for keeping the viewer in edit mode
     */
    checkInFailureEventSub = eventBus.subscribe( 'preCheckin.failure', function() {
        return exports.revealViewer( viewModelData, dispatch, elementRefList, 'edit', true );
    } );

    /**
     * Subscribe to cdm.updated event. In case parent checkout results in child dataset checkout,
     * need to perform action to prevent data loss due to explicit check in / cancel check out
     */
    cdmUpdatedEventSub = eventBus.subscribe( 'cdm.updated', ( eventData ) => {
        if( viewModelData.viewerContext && viewModelData.viewerContext.vmo ) {
            if( eventData && eventData.updatedObjects && eventData.updatedObjects.length > 0 ) {
                var result = _.some( eventData.updatedObjects, function( object ) {
                    return object ? object.uid === viewModelData.viewerContext.vmo.uid : false;
                } );
                if( result ) {
                    AwTimeoutService.instance( function() {
                        var isCheckedOut = viewModelData.viewerContext.vmo.props && viewModelData.viewerContext.vmo.props.checked_out &&
                            viewModelData.viewerContext.vmo.props.checked_out.dbValues[ 0 ] === 'Y';
                        var isModifiable = viewModelData.viewerContext.vmo.props &&
                            viewModelData.viewerContext.vmo.props.is_modifiable &&
                            viewModelData.viewerContext.vmo.props.is_modifiable.dbValues[ 0 ] === '1';

                        if( isCheckedOut && isModifiable ) {
                            return exports.revealViewer( viewModelData, dispatch, elementRefList, 'edit', true );
                        }

                        return exports.revealViewer( viewModelData, dispatch, elementRefList, 'view', false );
                    }, 2000 );
                }
            }
        }
    } );
};

export const eventUnsubscription = function() {
    eventBus.unsubscribe( checkInFailureEventSub );
    eventBus.unsubscribe( cdmUpdatedEventSub );
};

export const preparelaunchUrl = function( viewModelData, dispatch, elementRefList ) {
    AwTimeoutService.instance( function() {
        var output = buildLaunchInputs( viewModelData );
        var action = output.action;
        var revealViewerOnError = output.revealViewerOnError;

        // check if user is sponsorable first. Only when user is sponsorable, we proceed to load the viewer.
        var sponsorablePromise = exports.isUserSponsored( viewModelData );
        if( sponsorablePromise ) {
            sponsorablePromise.then( function( sponsorable ) {
                if( sponsorable ) {
                    exports.getLaunchUrl( viewModelData, dispatch, elementRefList, action, revealViewerOnError );
                }
            }, function( errorMsg ) {
                viewModelData.hasError = true;
                viewModelData.errorMsg = errorMsg;
                dispatch( { path: dataString, value: { ...viewModelData } } );
                messagingSvc.showError( errorMsg );
                logger.error( errorMsg );
            } );
        }
    }, 2000 );
};

export const buildLaunchInputs = function( viewModelData ) {
    var action = 'view';
    var revealViewerOnError = false;
    if( viewModelData.viewerContext && viewModelData.viewerContext.vmo ) {
        var isCheckedOut = viewModelData.viewerContext.vmo.props && viewModelData.viewerContext.vmo.props.checked_out &&
            viewModelData.viewerContext.vmo.props.checked_out.dbValues[ 0 ] === 'Y';
        var isModifiable = viewModelData.viewerContext.vmo.props && viewModelData.viewerContext.vmo.props.is_modifiable &&
            viewModelData.viewerContext.vmo.props.is_modifiable.dbValues[ 0 ] === '1';

        if( isCheckedOut && isModifiable ) {
            action = 'edit';
            revealViewerOnError = true;
            exports.manageDataLossElements( true );
        } else {
            exports.manageDataLossElements( false );
        }
    }

    return { action, revealViewerOnError };
};

export const manageDataLossElements = function( hide ) {
    _.forEach( _idsToHide, function( id ) {
        let element = document.getElementById( id );
        if( element ) {
            if( hide ) {
                element.classList.add( 'aw-viewerjs-hideContent' );
            } else {
                element.classList.remove( 'aw-viewerjs-hideContent' );
            }
        }
    } );
};

var blockEditingForCoAuthoringDocument = function( parentObj, action, errorMsg ) {
    if( action !== 'view' ) {
        // make sure the current selected parent is not in a co-authoring task. If it is, change action to view
        if( parentObj.props.fnd0MyWorkflowTasks &&
            parentObj.props.fnd0MyWorkflowTasks.uiValues &&
            parentObj.props.fnd0MyWorkflowTasks.uiValues[ 0 ] === 'Co-Author Document' ) {
            action = 'view';
            messagingSvc.showError( errorMsg );
        }
    }

    return action;
};

export const getLaunchUrl = function( viewModelData, dispatch, elementRefList, action, revealViewerOnError ) {
    /*
     * -------------DO NOT REMOVE ------------------ Developer code for wopi agnostic debugging.
     * ---------------------------------------------
     */
    //if( action ){ return; }
    dispatch( { path: showProgressString, value: true } );

    // input for invokeService method
    var dataset = viewModelData.viewerData.datasetData;
    var selectedObj = appCtxSvc.getCtx( 'selected' );
    var parentObj = adapterService.getAdaptedObjectsSync( [ selectedObj ] );
    var location = browserUtils.getWindowLocation();
    var ctx = appCtxSvc.getCtx();

    action = exports.blockEditingForCoAuthoringDocument( parentObj[0], action, viewModelData.viewerMessages.blockEditingOfCoAuthoringDoc );

    var launchInfoInput = {
        clientId: action + dataset.uid,
        objectUid: dataset.uid,
        objectClass: dataset.modelType.uid,
        action: action,
        locale: localeSvc.getLocale(),
        extraInfo: {
            correlationID: logger.getCorrelationID(),
            userId: ctx.userSession.props.user_id.value,
            group: ctx.userSession.props.group.uiValues[ 0 ],
            role: ctx.userSession.props.role.uiValues[ 0 ],
            parentObjUid: parentObj[0].uid,
            parentObjType: parentObj[0].type,
            clientGatewayUrl: browserUtils.getBaseURL(),
            redirectUrl: location.href
        }
    };

    //get the launch url and relation name
    var tcooPromise = tcooSvc.getLaunchUrl( launchInfoInput );
    if( tcooPromise ) {
        tcooPromise.then( function( response ) {
            exports.processLaunchInfoResponse( viewModelData, dispatch, elementRefList, response, action, revealViewerOnError );
        }, function( err ) {
            viewModelData.showProgress = false;
            viewModelData.hasError = true;
            viewModelData.errorMsg = viewModelData.viewerMessages.tcoowebConnectionFailed.format( err );
            dispatch( { path: dataString, value: { ...viewModelData } } );
            messagingSvc.showError( viewModelData.errorMsg );
        } );
    } else {
        logger.error( 'tcooSvc.getLaunchUrl failed to return promise' );
    }
};

/**
 * checks whether user is sponsorable or not and show error if not sponsorable.
 */
export const isUserSponsored = function( viewModelData ) {
    var deferred = AwPromiseService.instance.defer();
    var isSponsorable = false;

    var userVmo = appCtxSvc.getCtx( 'user' );
    const propName = 'fnd0Sponsorable';
    var fileName = viewModelData.viewerData.fileData.file.cellHeader1 ? viewModelData.viewerData.fileData.file.cellHeader1 : 'File';
    var errorMsg = viewModelData.viewerMessages.nonSponsoredUserMessage.format( fileName, userVmo.props.user_name.dbValues[ 0 ] );
    // userVmo in appCtx doesn't get updated after new props are loaded. Need to get the user modelObject to check the prop.
    var userMo = cdm.getObject( userVmo.uid );

    if( !userMo.props.hasOwnProperty( propName ) ) {
        var isUserSponsorablePromise = dmsSvc.getProperties( [ userVmo.uid ], [ propName ] );
        isUserSponsorablePromise.then( function( response ) {
            if( response && response.modelObjects && response.modelObjects[ userVmo.uid ].props[ propName ].dbValues[ 0 ] === '1' ) {
                isSponsorable = true;
                deferred.resolve( isSponsorable );
            } else {
                deferred.reject( errorMsg );
            }
        } );
    } else {
        if( userMo.props.fnd0Sponsorable.dbValues[ 0 ] === '1' ) {
            isSponsorable = true;
            deferred.resolve( isSponsorable );
        } else {
            deferred.reject( errorMsg );
        }
    }
    return deferred.promise;
};

export const processLaunchInfoResponse = function( viewModelData, dispatch, elementRefList, response, action, revealViewerOnError ) {
    if( response && response.data ) {
        var data = response.data;
        if( data.errorString ) {
            var fileName = viewModelData.viewerData.fileData ? viewModelData.viewerData.fileData.file.props.object_string.uiValues[0] : 'File';
            let errMsg = viewModelData.viewerMessages.fileOpenSystemError.format( fileName );
            viewModelData.errorMsg = errMsg;
            dispatch( { path: showProgressString, value: false } );
            logger.error( data.errorString );
            messagingSvc.showError( viewModelData.errorMsg );
            if( revealViewerOnError ) {
                exports.revealViewer( viewModelData, dispatch, elementRefList, action, false );
            }
        } else {
            if( data.oosUrlString ) {
                exports.createViewerIframe( viewModelData, dispatch, elementRefList, data );
            } else {
                logger.error( 'getting null response from service.' );
            }

            var refreshViewer = exports.ifToRefreshViewer( viewModelData, data.relationType );

            // this is to set the varialbe in global ctx for the primary toolbar to use because that toolbar doesn't have access to the viewer context
            appCtxSvc.registerCtx( refreshViewerProp, refreshViewer );
        }
    } else {
        logger.error( 'tcooSvc.getLaunchUrl failed to return any response' );
    }
};

export const ifToRefreshViewer = function( viewModelData, relation ) {
    var refreshViewer =  false;
    // only move on when the dataset is checked out
    // use viewModelData.viewerContext.vmo instead of viewModelData.viewerData.datasetData, which is NOT up-to-date on props
    if( viewModelData.viewerContext.vmo.props.checked_out.dbValues[ 0 ] === 'Y' ) {
        // get the relation from response and set tcooViewerInfo.refreshViewer flag in viewerContext
        // when the relation is TC_Attaches

        if( relation ) {
            if( relation === 'TC_Attaches' ) {
                refreshViewer = true;
            }
        } else {
            // there is no relation object in the response, that means the selected obj is a dataset
            // so the checkin will act on the dataset, so pre-refresh is needed.
            refreshViewer = true;
        }
    }
    return refreshViewer;
};

export const revealViewer = function( viewModelData, dispatch, elementRefList, mode, hideDataLossElements ) {
    exports.getLaunchUrl( viewModelData, dispatch, elementRefList, mode, mode === 'edit' ); //send the reveal in view on error as true only for edit mode
    //hide the carousel chevrons
    exports.manageDataLossElements( hideDataLossElements );

    return tcooSvc.getResolvedPromise();
};

/**
 * creating iframe for MS OO
 *
 * @param {Object} response of a soa call
 */
export const createViewerIframe = function( viewModelData, dispatch, elementRefList, responseData ) {
    dispatch( { path: showProgressString, value: false } );

    viewModelData.tcooParams = {
        accessToken: responseData.accessToken,
        accessTokenTtl: responseData.accessTokenTtl
    };
    dispatch( { path: 'data.data.tcooParams', value: { ...viewModelData.tcooParams } } );

    let officeFormElem = elementRefList.get( 'officeFormRef' );
    if( officeFormElem.current !== undefined && officeFormElem.current !== null ) {
        removeHostPageInfoDiv();
        if( !responseData.launchInNewPage ) {
            AwTimeoutService.instance( function() {
                officeFormElem.current.action = responseData.oosUrlString;
                officeFormElem.current.submit();
            } );
        } else {
            let hostPageWindowName = 'tcOOHostPage_' + viewModelData.viewerData.datasetData.uid;
            hostPageHandle = window.open( responseData.oosUrlString, hostPageWindowName );
            officeFormElem.current.parentNode.insertBefore( getHostPageInfoDiv( viewModelData ), officeFormElem.current );
        }
    }
};

const getHostPageInfoDiv = function( viewModelData ) {
    // Provide useful guidance to user in empty viewer space
    var hostPageInfoDiv = document.createElement( 'div' );
    hostPageInfoDiv.id = 'hostPageInfoDiv';

    var hostPageInfoBreak = document.createElement( 'br' );

    var hostPageInfoHeader = document.createElement( 'h4' );
    var hostPageInfoText = document.createTextNode( viewModelData.viewerMessages.hostPageInfoLine1 );
    hostPageInfoHeader.appendChild( hostPageInfoText );

    var hostPageInfoList = document.createElement( 'ul' );

    var hostPageInfoListItem = document.createElement( 'li' );
    var hostPageInfoListItemText = document.createTextNode( viewModelData.viewerMessages.hostPageInfoLine2 );
    hostPageInfoListItem.appendChild( hostPageInfoListItemText );
    hostPageInfoList.appendChild( hostPageInfoListItem );
   
    hostPageInfoListItem = document.createElement( 'li' );
    hostPageInfoListItemText = document.createTextNode( viewModelData.viewerMessages.hostPageInfoLine3 );
    hostPageInfoListItem.appendChild( hostPageInfoListItemText );
    hostPageInfoList.appendChild( hostPageInfoListItem );

    hostPageInfoListItem = document.createElement( 'li' );
    hostPageInfoListItemText = document.createTextNode( viewModelData.viewerMessages.hostPageInfoLine4 );
    hostPageInfoListItem.appendChild( hostPageInfoListItemText );
    hostPageInfoList.appendChild( hostPageInfoListItem );

    hostPageInfoDiv.appendChild( hostPageInfoBreak );
    hostPageInfoDiv.appendChild( hostPageInfoHeader );
    hostPageInfoDiv.appendChild( hostPageInfoList );

    return hostPageInfoDiv;
};

const removeHostPageInfoDiv = function () {
    var hostPageInfoDiv = document.getElementById( 'hostPageInfoDiv' );
    if ( hostPageInfoDiv ) {
        hostPageInfoDiv.remove();
    }
};

/**
 * Reloads the iFrame when one of two conditions is satisfied: 1. The last modified date of vmo
 * has been updated OR 2. Recurse timeout break after completing # iterations
 *
 * @param {deferred} Deferred promise that needs to be resolved or rejected
 * @param {Integer} counter of iterations before reloading the frame
 * @param {Integer} Timeout in milliseconds to wait
 * @param {Object} Context ViewModelObject used by the viewer
 */
export const reloadIframe = function( dispatch, deferred, counter, interval, vmo ) {
    dispatch( { path: showProgressString, value: true } );
    var currLMD = vmo.props.last_mod_date.dbValues[ 0 ];
    exports.recurseTimeout( dispatch, counter, interval, deferred, vmo, currLMD );
};

/**
 * Recurse function that breaks when one of two conditions is satisfied: 1. The last modified
 * date of vmo has been updated OR 2. Recurse timeout break after completing # iterations
 *
 * @param {Object} viewModel of the current viewer
 * @param {Integer} counter of iterations before reloading the frame
 * @param {Integer} Timeout in milliseconds to wait
 * @param {deferred} Deferred promise that needs to be resolved or rejected
 * @param {Object} Context ViewModelObject used by the viewer
 * @param {String} Current last modified date
 */
export const recurseTimeout = function( dispatch, counter, timeout, deferred, vmo, currLMD ) {
    AwTimeoutService.instance( function() {
        eventBus.publish( 'preCheckinGetProperties' );
        var recurse = true;
        var serverLMD = vmo.props.last_mod_date.dbValues[ 0 ];
        if( currLMD !== serverLMD ) {
            //this means WOPI updated the dataset named reference
            recurse = false;
        }

        if( counter === 0 ) {
            recurse = false; //this means that even after n iterations, the lmd is not updated. Keep viewer in edit mode
            deferred.reject();
        }

        if( recurse ) {
            counter--;
            exports.recurseTimeout( dispatch, counter, timeout, deferred, vmo, currLMD );
        } else {
            dispatch( { path: showProgressString, value: false } );
            deferred.resolve();
        }
    }, timeout );
};

var createButton = function( label, callback ) {
    return {
        addClass: 'btn btn-notify',
        text: label,
        onClick: callback
    };
};

export const cleanupControls = function( dispatch ) {
    // Clean up external host page window, if any
    if( hostPageHandle ) {
        // Note: Don't close window here so that AW can support multiple external document windows with unique names
        //hostPageHandle.close();
        hostPageHandle = null;
    }

    manageDataLossElements( false );
    dispatch( { path: 'data.data.hasError', value: false } );
    _idsToHide = null;
};

const exports = {
    setupEventSubscription,
    hostedInOfficeClient,
    preparelaunchUrl,
    cleanupControls,
    eventUnsubscription,
    // for here on, all the functions are exported for testing purpose
    revealViewer,
    getLaunchUrl,
    manageDataLossElements,
    reloadIframe,
    recurseTimeout,
    isUserSponsored,
    buildLaunchInputs,
    blockEditingForCoAuthoringDocument,
    processLaunchInfoResponse,
    createViewerIframe,
    ifToRefreshViewer
};

export default exports;
