// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * service for manage consumption view
 *
 * @module js/epManageConsumptionService
 */
'use strict';

import app from 'app';
import cdm from 'soa/kernel/clientDataModel';
import epGraphicsService from 'js/epGraphicsService';
import viewModelObjectSvc from 'js/viewModelObjectService';
import { constants as epBvrConstants } from 'js/epBvrConstants';
import epSaveService from 'js/epSaveService';
import saveInputWriterService from 'js/saveInputWriterService';
import mfeFilterAndSortService from 'js/mfeFilterAndSortService';
import epSingleViewerCacheService from 'js/epSingleViewerCacheService';
import eventBus from 'js/eventBus';
import mfeTypeUtils from 'js/utils/mfeTypeUtils';
import mfeTableService from 'js/mfeTableService';
import epTableService from 'js/epTableService';
import AwPromiseService from 'js/awPromiseService';
import epPackUnpackService from 'js/epPackUnpackService';
import messagingService from 'js/messagingService';
import localeService from 'js/localeService';
import awPromiseService from 'js/awPromiseService';
import epLoadService from 'js/epLoadService';
import epLoadInputHelper from 'js/epLoadInputHelper';
import { constants as epLoadConstants } from 'js/epLoadConstants';

let partsForManageConsumption = [];
const CONSUMED_IN_PROP = 'ConsumedIn';
const CONSUMED_IN_FIND_NO_PROP = 'consumedInFindNo';
const SOURCE_BOMLINE = 'sourceBomlineUid';
let partSelectedList = [];

/**
 *  @param {String} visInstanceId - the vis viewer instance id
 */
function getSelectedPartsfromGraphics( visInstanceId ) {
    let selectedParts = [];
    selectedParts = epGraphicsService.getSelectedParts( visInstanceId );

    let modelObjects = [];
    modelObjects = selectedParts.map( elem => cdm.getObject( elem ) );
    return modelObjects.filter( ( obj, index ) => {
        return modelObjects.indexOf( obj ) === index && mfeTypeUtils.isOfType( obj, 'BOMLine' );
    } );
}

/**
 * @return {Object} Selected parts
*/
function getManageConsumptionData( selectedParts ) {
    const consumedLinesWithDetails = getConsumedLineDetails( selectedParts );
    addCustomProperties( consumedLinesWithDetails );

    //Parts are sorted according to the bl_sequence_no property of its parent operation
    const sortedPartsForManageConsumption = mfeFilterAndSortService.sortModelObjectsByNumericProp( partsForManageConsumption, CONSUMED_IN_FIND_NO_PROP, true );
    partsForManageConsumption = sortedPartsForManageConsumption;
    return {
        partsForManageConsumption: sortedPartsForManageConsumption
    };
}

/**
 * @param {String} visInstanceId - the vis viewer instance id
 * @return {Object} Selected parts
 */
function getSelectedParts( visInstanceId ) {
    partsForManageConsumption = getSelectedPartsfromGraphics( visInstanceId );
    partSelectedList = partsForManageConsumption;
    const data = getManageConsumptionData( partSelectedList );

    var uniqueSetOfObjects = new Set( data.partsForManageConsumption );
    uniqueSetOfObjects.add( ...partSelectedList );
    unpackRequiredLines( uniqueSetOfObjects );
    return data;
}

/**
  *
  *@param {Objects} partToOpsMap map of part to operations
  *@param {Array} partNotConsumed part not consumed
  */
function addCustomProperties( consumedLinesWithDetails ) {
    let newVmos = [];
    consumedLinesWithDetails.forEach( consumedLineDetails => {
        const consumedPart = consumedLineDetails.consumedPart;
        const parent = consumedLineDetails.consumedIn ? consumedLineDetails.consumedIn : null;
        var tmpVmo = viewModelObjectSvc.constructViewModelObjectFromModelObject( consumedPart, 'EDIT' );
        if( parent ) {
            const operationProperty = {
                isArray: true,
                value: [ parent.props.object_string.dbValues[ 0 ] ],
                displayValue: [ parent.props.object_string.dbValues[ 0 ] ]
            };


            tmpVmo.props.ConsumedIn = viewModelObjectSvc.constructViewModelProperty( operationProperty, CONSUMED_IN_PROP, tmpVmo );
            tmpVmo.props.opUid = viewModelObjectSvc.constructViewModelProperty( {

                isArray: false,
                value: parent.uid,
                displayValue: parent.uid
            },
            'OpUid', tmpVmo
            );


            if( consumedLineDetails.sourceBomlineUid ) {
                addSourceBomlineToVmo( tmpVmo, consumedLineDetails.sourceBomlineUid );
            }
        } else {
            tmpVmo.props.ConsumedIn = viewModelObjectSvc.constructViewModelProperty( {
                isArray: true,
                value: [],
                displayValue: [],
                opUid: ''
            }, CONSUMED_IN_PROP, tmpVmo );
        }


        //If bl_line_name is not exist then use object_string

        if( tmpVmo.props.bl_line_name === undefined ) {
            tmpVmo.props.bl_line_name = tmpVmo.props.object_string;
        }

        //add find in number property to VMO
        addConsumedInOpFindNoPropToVmos( tmpVmo, parent );
        newVmos.push( tmpVmo );
    } );
    partsForManageConsumption = newVmos;
}

/**
 *
 * @param {Object} selectedParts selectedParts
 * @returns {Object} part details
 */
function getConsumedLineDetails( selectedParts ) {
    let consumedLinesWithDetails = [];
    let consumedLineDetails = {};
    selectedParts.forEach( vmo => {
        let composites = epSingleViewerCacheService.getAllCompositesForObjects( [ vmo.uid ] );
        const compositeObjects = composites.map( uid => cdm.getObject( uid ) );
        composites = compositeObjects.filter( item => mfeTypeUtils.isOfType( item, epBvrConstants.MFG_BVR_PART ) );
        if( composites && composites.length > 0 ) {
            let atLeastOneValidComposite = false;
            composites.forEach( ( composite ) => {
                consumedLineDetails = {};
                consumedLineDetails.consumedPart = composite;
                consumedLineDetails.sourceBomlineUid = vmo.uid;
                const parentId = consumedLineDetails.consumedPart.props.bl_parent ? consumedLineDetails.consumedPart.props.bl_parent.dbValues[ 0 ] : epSingleViewerCacheService.getParent(
                    composite.uid );
                const parentObj = cdm.getObject( parentId );
                if( mfeTypeUtils.isOfTypes( parentObj, [ epBvrConstants.MFG_BVR_PROCESS, epBvrConstants.MFG_BVR_OPERATION ] ) ) {
                    consumedLineDetails.consumedIn = parentObj;
                    consumedLinesWithDetails.push( consumedLineDetails );
                    atLeastOneValidComposite = true;
                }
            } );
            //If all composites are not directly consumed under operation or process then add assembly bomline
            if( !atLeastOneValidComposite ) {
                consumedLineDetails = {};
                consumedLineDetails.consumedPart = vmo;
                consumedLinesWithDetails.push( consumedLineDetails );
            }
        } else {
            let consumedLineDetails = {};
            consumedLineDetails.consumedPart = vmo;
            consumedLinesWithDetails.push( consumedLineDetails );
        }
    } );
    return consumedLinesWithDetails;
}

/**
  *
  * @param {Object} vmo Model Object
  * @param {Object} parentOp Consumed In operation
  */
function addConsumedInOpFindNoPropToVmos( vmo, parentOp ) {
    if( parentOp && parentOp.props.bl_sequence_no ) {
        let consumedInFindNo = parentOp.props.bl_sequence_no.dbValues[ 0 ];

        const consumedInFindInNoProperty = {
            isArray: true,
            value: [ consumedInFindNo ],
            displayValue: [ consumedInFindNo ],
            dbValues: [ consumedInFindNo ]
        };
        vmo.props.consumedInFindNo = viewModelObjectSvc.constructViewModelProperty( consumedInFindInNoProperty, CONSUMED_IN_FIND_NO_PROP, vmo );
    }
}

/**
  * Clear cache for manage consumption
  */
function clearPartsForManageConsumption() {
    partsForManageConsumption = [];
}

/**
  *This method returns cache for manage consumption
  * @returns{Map} Parts for manage consumption
  */
function getPartsForManageConsumptionData() {
    return { partsForManageConsumption };
}

/**
  *
  * @param {Objects} selectedParts selected parts to be reassigned
  * @param {Objects} target target process or operation
  *  @returns {Object} soperationToParts
  */
function getSourceOperationsToParts( selectedParts, target ) {
    let objectsToAdd = [];
    let sourceToParts = {};
    let objectToReassign = [];
    selectedParts.forEach( part => {
        if( part.props.opUid ) {
            const parentId = part.props.opUid.dbValue;


            if( parentId !== target[ 0 ].uid ) {
                sourceToParts[ parentId ] = sourceToParts[ parentId ] || [];
                sourceToParts[ parentId ].push( part.uid );
                objectToReassign.push( part.uid );
            }
        } else {
            objectsToAdd.push( part.uid );
        }
    } );

    return {
        sourceToParts,
        objectsToAdd,
        objectToReassign
    };
}

/**
  *
  * @param {Objects} target target process or operation
  * @param {Objects} saveInputWriter save input writer
  * @param {Objects} relatedObject related objects
  * @param {Objects} objectsToAdd objects to add
  * @returns {Promise} promise
  */
function performReassign( target, saveInputWriter, relatedObject, objectsToAdd ) {
    let deferred = awPromiseService.instance.defer();
    return epSaveService.saveChanges( saveInputWriter, true, relatedObject ).then( response => {
        const addedToRelationEvents = response.saveEvents.filter( event => event.eventType === 'addedToRelation' );
        const newAssignements = addedToRelationEvents.map( event => event.eventObjectUid );
        if( newAssignements.length > 0 ) {
            //get properties which are not in packunpack soa
            const loadTypes = [ epLoadConstants.GET_PROPERTIES ];
            const loadTypeInputs = epLoadInputHelper.getLoadTypeInputs( loadTypes, newAssignements, [ epBvrConstants.BL_IS_PACKED, 'bl_line_name', epBvrConstants.OBJECT_STRING ] );
            return epLoadService.loadObject( loadTypeInputs, false ).then( function( response ) {
                const newAssignementsObjects = addedToRelationEvents.map( event => cdm.getObject( event.eventObjectUid ) );
                unpackRequiredLines( newAssignementsObjects );

                let reassignData = {
                    assigned: newAssignements,
                    unassigned: objectsToAdd,
                    target: target[ 0 ]
                };
                eventBus.publish( 'ep.manageConsumption', { reassignData: reassignData } );

                return deferred.resolve();
            } );
        }
    } );
}

/**
 * getConsumedPartsRelatedToSourceBomlineFromDataProvider
 *
 * @param {Object} dataProvider - the save events as json object
 * @param {Object} sourceBomlineUid - uid of object
 * @return {Number} count - count
 */
function getNumberOfConsumedPartsRelatedToSourceBomlineFromDataProvider( dataProvider, sourceBomlineUid ) {
    let count = 0;
    const vmos = dataProvider.viewModelCollection.getLoadedViewModelObjects();
    vmos.forEach( vmo => {
        if( vmo.sourceBomlineUid && vmo.sourceBomlineUid.dbValue === sourceBomlineUid ) {
            count++;
        }
    } );

    return count;
}

/**
 * Removes unassigned rows and adds assigned rows in dataprovider
 * @param {Objects} dataProvider dataProvider
 * @param {Objects} reassignData data about reassigned parts
 */
function updateDataproviderAfterReassign( dataProvider, reassignData ) {
    if( reassignData.unassigned && reassignData.unassigned.length > 0 ) {
        mfeTableService.removeFromDataProvider( reassignData.unassigned, dataProvider );
    }

    if( reassignData.assigned && reassignData.assigned.length > 0 ) {
        mfeTableService.addToDataProvider( reassignData.assigned, dataProvider, true );
    }

    const target = reassignData.target;
    reassignData.assigned && target && reassignData.assigned.forEach( assignedPartUid => {
        let consumedInVal = [];
        consumedInVal.push( target.props.object_string.dbValues[ 0 ] );

        const operationProperty = {
            isArray: true,
            value: consumedInVal,
            displayValue: consumedInVal
        };

        const assignedPartObject = cdm.getObject( assignedPartUid );
        let vmo = epTableService.getTreeNode( dataProvider, assignedPartObject );
        vmo.props.ConsumedIn = viewModelObjectSvc.constructViewModelProperty( operationProperty, CONSUMED_IN_PROP, vmo );
        vmo.props.opUid = viewModelObjectSvc.constructViewModelProperty( {
            isArray: false,
            value: target.uid,
            displayValue: target.uid
        },
        'OpUid', vmo
        );

        // If bl_line_name is not exist then use object_string
        if( vmo.props.bl_line_name === undefined ) {
            vmo.props.bl_line_name = vmo.props.object_string;
        }

        getAllCompositesAndAddSourceBomlineToVmo( vmo );
        addConsumedInOpFindNoPropToVmos( vmo, target );
    } );
}


/**
 * Adds a property for sourceBomline in VMO
 * @param {Object} vmo input vmo
 * @param {string} sourceBomlineUid bomlineUid
 */
function addSourceBomlineToVmo( vmo, sourceBomlineUid ) {
    const sourceBomlineProperty = {
        isArray: false,
        value: sourceBomlineUid,
        displayValue: sourceBomlineUid
    };
    vmo.sourceBomlineUid = viewModelObjectSvc.constructViewModelProperty( sourceBomlineProperty, SOURCE_BOMLINE, vmo );
}

/**
 * Updates source bomline from composite data for assigned parts
 * @param {Objects} dataProvider dataProvider
 */
function updateSourceObjects( dataProvider ) {
    const vmos = dataProvider.viewModelCollection.getLoadedViewModelObjects();
    vmos.forEach( vmo => {
        if( vmo.props.opUid !== undefined ) {
            getAllCompositesAndAddSourceBomlineToVmo( vmo );
            // If bl_line_name is not exist then use object_string
            if( vmo.props.bl_line_name === undefined ) {
                vmo.props.bl_line_name = vmo.props.object_string;
            }
        }
    } );
}
/**
 * Removes unassigned rows from dataprovide and add new one if require
 * @param {Objects} dataProvider dataProvider
 * @param {Objects} unassignData data about unassign parts
 */
function updateDataproviderAfterUnassign( dataProvider, unassignData ) {
    const objectUidsToBeAddedSet = new Set();
    unassignData.forEach( objUid => {
        const object = cdm.getObject( objUid );
        let vmo = epTableService.getTreeNode( dataProvider, object );
        if( vmo.sourceBomlineUid ) {
            const countOfConsumedLines = getNumberOfConsumedPartsRelatedToSourceBomlineFromDataProvider( dataProvider, vmo.sourceBomlineUid.dbValue );
            let countFromRemoved = 0;
            unassignData.forEach( removedUid => {
                const removedObject = cdm.getObject( removedUid );
                const vmoRemoved = epTableService.getTreeNode( dataProvider, removedObject );
                if( vmoRemoved.sourceBomlineUid && vmoRemoved.sourceBomlineUid.dbValue === vmo.sourceBomlineUid.dbValue ) {
                    countFromRemoved++;
                }
            } );

            if( countOfConsumedLines === countFromRemoved ) {
                objectUidsToBeAddedSet.add( vmo.sourceBomlineUid.dbValue );
            }
        }
    } );

    if( unassignData && unassignData.length > 0 ) {
        mfeTableService.removeFromDataProvider( unassignData, dataProvider );
    }

    objectUidsToBeAddedSet.size > 0 && mfeTableService.addToDataProvider( Array.from( objectUidsToBeAddedSet ), dataProvider, true );
}

/**
 * Sort dataprovider
 * @param {Objects} dataProvider dataProvider
 */
function sortDataprovider( dataProvider ) {
    partsForManageConsumption = dataProvider.viewModelCollection.getLoadedViewModelObjects();
    partsForManageConsumption = mfeFilterAndSortService.sortModelObjectsByNumericProp( partsForManageConsumption, CONSUMED_IN_FIND_NO_PROP, true );
    dataProvider.update( partsForManageConsumption, partsForManageConsumption.length );
}

/**
 * Updates data provider after reassign or unassign events
 * @param {Objects} dataProvider dataProvider
 * @param {Objects} eventData data about assigned and unassigned parts
 */
function updateDataproviderAfterAssignmentUnassignment( dataProvider, eventData ) {
    if( eventData.unassigned && eventData.unassigned.length > 0 ) {
        updateDataproviderAfterUnassign( dataProvider, eventData.unassigned );
    }

    const reassignData = eventData.reassignData;
    if( reassignData ) {
        updateDataproviderAfterReassign( dataProvider, reassignData );
    }

    sortDataprovider( dataProvider );
}

/**
 *
 * @param {Objects} selectedParts selected parts to be reassigned
 * @param {Objects} target target process or operation
 * @returns {Promise} promise
 */
function reassignParts( selectedParts, target ) {
    let ret = getSourceOperationsToParts( selectedParts, target );
    const sourceToParts = ret.sourceToParts;
    const objectToReassign = ret.objectToReassign;
    const objectsToAdd = ret.objectsToAdd;

    const partsToAddOrReassign = [ ...objectToReassign, ...objectsToAdd ];

    if( objectToReassign.length === 0 && objectsToAdd.length === 0 ) {
        return;
    }

    let relatedObject = [];
    const saveInputWriter = saveInputWriterService.get();

    //add to related objects
    if( Object.entries( sourceToParts ).length > 0 ) {
        for( const [ key, value ] of Object.entries( sourceToParts ) ) {
            value.forEach( val => relatedObject.push( cdm.getObject( val ) ) );
            const obj = cdm.getObject( key );
            obj && relatedObject.push( obj );
        }
    }

    relatedObject.push( target[ 0 ] );

    //add reassign input
    objectToReassign.forEach( obj => {
        saveInputWriter.addMoveObject( { id: [ obj ] }, { bl_parent: [ target[ 0 ].uid ] } );
    } );

    //add new operation
    if( objectsToAdd.length > 0 ) {
        // Part Assignment should pick Occurance based on MEAssignCustomizedOccurrenceType
        saveInputWriter.addRemoveOrAddObjects( 'Add', target[ 0 ].uid, objectsToAdd, 'AssignedParts', null );
        objectsToAdd.forEach( val => {
            const obj = cdm.getObject( val );
            obj && relatedObject.push( obj );
        } );
    }

    return performReassign( target, saveInputWriter, relatedObject, partsToAddOrReassign );
}

/**
 *
 * @param {Objects} params drag data
 */
function dragStart( params ) {
    params.event.preventDefault();
}

/**
 *
 * @param {Objects} selectedParts selected parts to be unassign
 */
export function unassignParts( selectedParts ) {
    let operationToParts = new Map();


    selectedParts.forEach( ( part ) => {
        if(  part.props.opUid && part.props.opUid !== null ) {
            const opUid = part.props.opUid.dbValue;


            if( operationToParts.has( opUid ) ) {
                let values = operationToParts.get( opUid );
                values.push( part );
                operationToParts.set( opUid, values );
            } else {
                operationToParts.set( opUid, [ part ] );
            }
        }
    } );
    operationToParts.forEach( ( parts, operation ) => {
        let relatedObjects = [];
        const partUids = parts.map( elem => elem.uid );
        relatedObjects.push( cdm.getObject( operation ), ...parts );
        const saveInputWriter = saveInputWriterService.get();
        saveInputWriter.addRemoveOrAddObjects( 'Remove', operation, partUids, 'AssignedParts', epBvrConstants.ME_CONSUMED );
        return epSaveService.saveChanges( saveInputWriter, true, relatedObjects ).then( response => {
            eventBus.publish( 'ep.manageConsumption', { unassigned: partUids } );
        } );
    } );
    return AwPromiseService.instance.resolve();
}

/**
 *  @param {Objects} vmo selected viewModelObject
 */

export function getAllCompositesAndAddSourceBomlineToVmo( vmo ) {
    const composites = epSingleViewerCacheService.getAllCompositesForObjects( [ vmo.uid ] );
    if( composites.length > 0 ) {
        addSourceBomlineToVmo( vmo, composites[ 0 ] );
    }
}

/**
 * Checks packed state and unpack if required
 * @param {Object} partsForManageConsumption partsForManageConsumption
 */
function unpackRequiredLines( partsForManageConsumption ) {
    let objectsToBeUnpacked = new Set();
    partsForManageConsumption.forEach( object => {
        const isPacked = epPackUnpackService.isPacked( object.uid );
        if( isPacked ) {
            objectsToBeUnpacked.add( object );
        } else {
            const packSourceUid = epSingleViewerCacheService.getPackSource( object.uid );
            if( packSourceUid !== null && epPackUnpackService.isPacked( packSourceUid ) &&
                epSingleViewerCacheService.getPackagedLineOfObject( packSourceUid ).length > 0 ) {
                objectsToBeUnpacked.add( cdm.getObject( packSourceUid ) );
            }
        }
    } );

    if( objectsToBeUnpacked.size > 0 ) {
        epPackUnpackService.packOrUnpack( Array.from( objectsToBeUnpacked ), false );
        const resource = localeService.getLoadedText( app.getBaseUrlPath() + '/i18n/ManageConsumptionMessages' );
        messagingService.showInfo( resource.epManageConsumptionUnpackNotificationMessage );
    }
}
const exports = {
    getSelectedParts,
    reassignParts,
    getPartsForManageConsumptionData,
    clearPartsForManageConsumption,
    dragStart,
    updateDataproviderAfterAssignmentUnassignment,
    unassignParts,
    updateSourceObjects
};

export default exports;
