import app from "app";
import appCtxSvc from "js/appCtxService";
import localeService from "js/localeService";

("use strict");
let exports = {};

/**
 * This funtion get selcted objects to populate in Delete Prototype or Subset ui.
 * @param {*} data
 * @returns
 */
export let getSelection = function (data) {
  let objuids = [];
  let subLocName =
    appCtxSvc.getCtx("locationContext")["ActiveWorkspace:SubLocation"];

  switch (subLocName) {
    case "G4B.PrototypeSpecifications":
      objuids = getSelectedObjUid(data, false);
      break;
    case "com.siemens.splm.client.occmgmt:OccurrenceManagementSubLocation":
      objuids = getSelectedObjUid(data, true);
      break;
    default:
      console.error(
        'Error: Criteria for sublocation "' + subLocName + '" is not defined.'
      );
  }

  return objuids;
};

/**
 * This function return UID's array of selcted objects(ProtoType or Subset).
 * @param {*} data
 * @param {*} isOccMgmtSublocation
 * @returns
 */
let getSelectedObjUid = function (data, isOccMgmtSublocation) {
  let objString = "";
  let protuids = [];
  let selectedModelObjects = appCtxSvc.getCtx("mselected");
  let arrayLength = selectedModelObjects.length;

  let localTextBundle = localeService.getLoadedText(
    app.getBaseUrlPath() + "/i18n/TaKonfigToolsInfoCommandsMessages"
  );

  for (let i = 0; i < arrayLength; i++) {
    let sobject_string = "";
    if (isOccMgmtSublocation) {
      sobject_string =
        selectedModelObjects[i].props.awb0UnderlyingObject.uiValues[0];
      protuids.push(
        selectedModelObjects[i].props.awb0UnderlyingObject.dbValues[0]
      );
    } else {
      sobject_string = selectedModelObjects[i].props.object_string.dbValue;
      protuids.push(selectedModelObjects[i].uid);
    }

    objString += sobject_string;
    if (i === appCtxSvc.getCtx("mselected.length - 1")) {
      objString += "";
    } else {
      objString += ";";
      objString += "\n";
    }
  }
  if (data.objectstring) {
    data.objectstring.dbValue = objString;
    let errStr = localTextBundle["selectioncountlabel"].replace(
      "{0}",
      String(arrayLength)
    );
    data.selectioncount.dispValue = errStr;
    data.selectioncount.dbValue = errStr;
    data.selectioncount.uiValue = errStr;
  }

  return protuids;
};

/**
 * This function print all undeleted Object string and error on console.
 * And return a string to shown in message ui.
 * @param {*} data
 * @returns
 */
export let getAndPrintUndeletedObjsString = function (data) {
  let undeletedObjectString = "";

  if (data.mapOfUnDeletedTakoObjs) {
    console.log("Undeleted prototype are: \n ");
    let mapKeys = Object.keys(data.mapOfUnDeletedTakoObjs);
    for (let index = 0; index < mapKeys.length; index++) {
      let value = data.mapOfUnDeletedTakoObjs[mapKeys[index]];
      console.log(mapKeys[index] + " = " + value);
      if (undeletedObjectString.length <= 0) {
        undeletedObjectString = "\n" + mapKeys[index] + " : " + value;
      } else {
        undeletedObjectString += "\n";
        undeletedObjectString += mapKeys[index] + " : " + value;
      }
    }
  }
  return undeletedObjectString;
};

/**
 * To registered the service
 */

exports = {
  getSelection,
  getAndPrintUndeletedObjsString,
};

export default exports;
