// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwListbox from 'viewmodel/AwListboxViewModel';
import AwColumnChart from 'viewmodel/AwColumnChartViewModel';
import AwInclude from 'viewmodel/AwIncludeViewModel';
    import { ShowWhen,ExistWhen } from 'js/hocCollection';
const DivShowWhen = ShowWhen('div');
const DivExistWhen = ExistWhen('div');
const AwColumnChartExistWhen = ExistWhen(AwColumnChart);
const AwIncludeExistWhen = ExistWhen(AwInclude);
DivShowWhen.displayName = 'DivShowWhen';
DivExistWhen.displayName = 'DivExistWhen';
AwColumnChartExistWhen.displayName = 'AwColumnChartExistWhen';
AwIncludeExistWhen.displayName = 'AwIncludeExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/awChartDataProviderService', () => import('js/awChartDataProviderService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "actions": {
        "setChartVisible": {
            "actionType": "JSFunction",
            "method": "setChartVisible",
            "inputData": {
                "searchState": "{{props.subPanelContext.context.searchState}}",
                "forceToggleOff": false
            },
            "deps": "js/awChartDataProviderService"
        },
        "setChartInvisible": {
            "actionType": "JSFunction",
            "method": "setChartVisible",
            "inputData": {
                "searchState": "{{props.subPanelContext.context.searchState}}",
                "forceToggleOff": "{{conditions.forceNoChart}}"
            },
            "deps": "js/awChartDataProviderService"
        },
        "createChartProvider": {
            "actionType": "JSFunction",
            "method": "createChartProvider",
            "inputData": {
                "searchState": "{{props.subPanelContext.context.searchState}}",
                "chartListboxPropData": "{{data.chartListboxPropData}}"
            },
            "deps": "js/awChartDataProviderService"
        },
        "triggerSelectCategoryHeaderEvent": {
            "actionType": "JSFunction",
            "method": "triggerSearchFilterTargetCategoryEvent",
            "inputData": {
                "internalPropertyNameToGroupOn": "{{data.chartListboxPropData.dbValue}}",
                "searchState": "{{subPanelContext.context.searchState}}"
            },
            "deps": "js/awChartDataProviderService"
        }
    },
    "functions": {},
    "data": {
        "chartListboxPropData": {
            "displayName": "{{i18n.SearchResultsChartSelectorTitle}}",
            "type": "STRING",
            "isRequired": "false",
            "hasLov": "true",
            "dbValue": "",
            "uiValue": "",
            "dataProvider": "listDataProvider"
        },
        "chartTitleValue": {
            "titleValue": "{{i18n.SearchResultsChartTitle}} {{subPanelContext.context.searchState.chartProvider.chartListboxPropData.dispValue}}"
        }
    },
    "onEvent": [],
    "dataProviders": {
        "listDataProvider": {
            "response": "{{props.subPanelContext.context.searchState.chartProvider.chartListboxListData.dbValue}}"
        }
    },
    "messages": {},
    "conditions": {
        "defaultCategoryType": {
            "expression": "subPanelContext.context.searchState && subPanelContext.context.searchState.chartProvider && subPanelContext.context.searchState.chartProvider.category && (subPanelContext.context.searchState.chartProvider.category.type === 'StringFilter' || subPanelContext.context.searchState.chartProvider.category.type === 'NumericFilter' || subPanelContext.context.searchState.chartProvider.category.type === 'DateFilter' || subPanelContext.context.searchState.chartProvider.category.type === 'RadioFilter' || subPanelContext.context.searchState.chartProvider.category.type === 'ObjectFilter')"
        },
        "showChartsForCategory": {
            "expression": "subPanelContext.context.searchState.totalLoaded > 0 && subPanelContext.context.searchState.chartProvider && subPanelContext.context.searchState.chartProvider.category && subPanelContext.context.searchState.searchFilterCategories.length > 0 && subPanelContext.context.searchState.chartProvider.chartListboxListData.dbValue.length > 0"
        },
        "showChartByDropDown": {
            "expression": "subPanelContext.context.searchState.chartProvider && subPanelContext.context.searchState.totalLoaded > 0 && subPanelContext.context.searchState.searchFilterCategories.length > 0 && subPanelContext.context.searchState.chartProvider.chartListboxListData.dbValue.length > 0"
        },
        "showChartByTitle": {
            "expression": "subPanelContext.context.searchState.chartProvider.chartTitleString.length > 0 && subPanelContext.context.searchState.totalLoaded > 0 && subPanelContext.context.searchState.searchFilterCategories.length > 0 && subPanelContext.context.searchState.chartProvider.chartListboxListData.dbValue.length > 0"
        },
        "forceNoChart": {
            "expression": "subPanelContext.context.searchState.forceNoChart !== false"
        }
    },
    "i18n": {
        "SearchResultsChartTitle": [
            "SearchMessages"
        ],
        "SearchResultsChartSelectorTitle": [
            "SearchMessages"
        ]
    },
    "lifecycleHooks": {
        "onUpdate": {
            "action": "createChartProvider",
            "observers": [
                "props.subPanelContext.context.searchState.recreateChartProvider"
            ]
        },
        "onMount": "setChartVisible",
        "onUnmount": "setChartInvisible"
    },
    "_viewModelId": "AwSearchChart",
    "_uniqueViewModelId": "AwSearchChart",
    "ctx": {}
};

    /*
    <div class="aw-search-searchResultsChart">
    <div class="aw-search-searchResultsChartTitle"
        show-when="conditions.showChartByTitle">
        {{subPanelContext.context.searchState.chartProvider.chartTitleString}}
    </div>
    <div class="aw-search-searchResultsChartSelector"
        exist-when="conditions.showChartByDropDown">
        <aw-listbox prop="data.chartListboxPropData"
            action="triggerSelectCategoryHeaderEvent"></aw-listbox>
    </div>
    <aw-column-chart class="aw-search-columnChart"
        exist-when="conditions.defaultCategoryType && conditions.showChartsForCategory"
        chart-provider="subPanelContext.context.searchState.chartProvider"></aw-column-chart>
    <aw-include exist-when="!conditions.defaultCategoryType && conditions.showChartsForCategory" 
        name="{{'AwSearchChart'+subPanelContext.context.searchState.chartProvider.category.type}}"
        sub-panel-context="subPanelContext"
    ></aw-include>
</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="aw-search-searchResultsChart">
        <DivShowWhen className="aw-search-searchResultsChartTitle" showWhen={AwParseService.instance( "conditions.showChartByTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            {AwParseService.instance( "subPanelContext.context.searchState.chartProvider.chartTitleString" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
        </DivShowWhen>
        <DivExistWhen className="aw-search-searchResultsChartSelector" existWhen={AwParseService.instance( "conditions.showChartByDropDown" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwListbox {...getField("data.chartListboxPropData", fields, $index, null, null )} action={actions.triggerSelectCategoryHeaderEvent}>
            </AwListbox>
        </DivExistWhen>
        <AwColumnChartExistWhen className="aw-search-columnChart" chartProvider={AwParseService.instance( "subPanelContext.context.searchState.chartProvider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "conditions.defaultCategoryType && conditions.showChartsForCategory" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwColumnChartExistWhen>
        <AwIncludeExistWhen viewId={AwParseService.instance( "'AwSearchChart'+subPanelContext.context.searchState.chartProvider.category.type" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} name={AwParseService.instance( "'AwSearchChart'+subPanelContext.context.searchState.chartProvider.category.type" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "!conditions.defaultCategoryType && conditions.showChartsForCategory" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwIncludeExistWhen>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;