/* eslint-disable no-console */
// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * Note: This module does not return an API object. The API is only available when the service defined this module is
 * injected by AngularJS.
 *
 * @module js/G4B_configuratorService
 */

import app from "app";
import $ from "jquery";
import eventBus from "js/eventBus";
import appCtxSvc from "js/appCtxService";
import viewModelObjSvc from "js/viewModelObjectService";
import messagingSvc from "js/messagingService";
import tcSessionData from "js/TcSessionData";
import cdm from "soa/kernel/clientDataModel";
import soaSvc from "soa/kernel/soaService";
import tcServerVersion from "js/TcServerVersion";
import addObjectUtils from "js/addObjectUtils";
import exportConfExcelSvc from "js/G4BExportConfigurationToExcelService";
import localeSvc from "js/localeService";
import _ from "lodash";
import dataManagementSvc from "soa/dataManagementService";
import occmgmtUtils from "js/occmgmtUtils";
import occmgmtGetSvc from "js/occmgmtGetService";
import AwPromiseService from "js/awPromiseService";
import ngUtils from "js/ngUtils";
import g4bSortGroupsSvc from "js/G4B_SortGroups";
import viewModelSvc from "js/viewModelService";
import localeService from "js/localeService";

let exports = {};
let optionGroups = null;
let summaryGroup = null;

let handleInvalidConfiguration = function (context) {
  // We can have invalid configuration in two case
  // 1. Switching to Guided Mode from Manual Mode with imprecise configuration
  // 2. Selecting an option value when there are invalid rules defined
  if (context.switchingToGuidedMode) {
    eventBus.publish("G4B_CFG_VariantData.showValidationErrorMessage", {
      switchingToGuidedMode: "true",
    });
  } else {
    if (
      context.userSelectionMap !== undefined &&
      context.family !== undefined
    ) {
      // Remove the last selection from user selection list and retain existing option groups
      let optionValueSelections =
        context.userSelectionMap[context.family.familyStr];
      if (optionValueSelections !== undefined) {
        let indexToRemove = null;
        for (let j = 0; j < optionValueSelections.length; j++) {
          if (
            optionValueSelections[j].optionValue === context.value.optValueStr
          ) {
            indexToRemove = j;
            break;
          }
        }
        if (indexToRemove !== null && indexToRemove > -1) {
          optionValueSelections.splice(indexToRemove, 1);
        }
      }
    }
    //Fire event to show error message
    if (context.family !== undefined && context.value !== undefined) {
      context.validationErrorFamily = context.family.familyDisplayName;
      context.validationErrorValue = context.value.valueDisplayName;
      appCtxSvc.updateCtx("variantConfigContext", context);
      appCtxSvc.updateCtx("storeContext", context);
      eventBus.publish("G4B_CFG_VariantData.showValidationErrorMessage", {
        switchingToGuidedMode: "false",
      });
    }
  }
};

/**
 * This is a comment in a file to demonstrate the merge scenario
 */

/**
 * GEPA-15015 - Show information that in VKBG not all rules are set yet and link to current limitations
 */
//new Implementation: getting the build phase value from effectivity string
export let getbuildPhase = function (data, ctx) {
  let  showBuildPhaseWarning = data.showBuildPhaseWarning;
  let effectivityString = appCtxSvc.getCtx(
    "occmgmtContext.productContextInfo.props.fgf0EffectivityDisplay.dbValues[0]"
  );
  let g4b_BP = "";
  if (
    effectivityString.indexOf("VKBG") != -1 ||
    effectivityString.indexOf("VBG") != -1 ||
    effectivityString.indexOf("VPBG") != -1
  ) {
    showBuildPhaseWarning.flag = true;
    if (effectivityString.indexOf("VKBG") != -1) {
      g4b_BP = "VKBG";
    }
    if (effectivityString.indexOf("VBG") != -1) {
      g4b_BP = "VBG";
    }
    if (effectivityString.indexOf("VPBG") != -1) {
      g4b_BP = "VPBG";
    }
    showBuildPhaseWarning.g4b_BP = g4b_BP;
    showBuildPhaseWarning.prefLink =
      ctx.preferences["G4B_AWC_HyperlinkToVirtualBuildPhaseLimitations"];
  }
  return {showBuildPhaseWarning:showBuildPhaseWarning}
};

/**
 ************* END GEPA-15015 ******************
 */

/**
 * Create group structure to display in view
 */
export let populateScopes = function (response, getPropResponse) {
  let langCode = localeSvc.getLanguageCode();
  let grpNamePref = appCtxSvc.getCtx(
    "preferences.G4B_AWC_ConfiguratorGroupLocalizations"
  );

  let tmpScopes = [];
  for (let i = 0; i < response.allScopes.length; i++) {
    let tmpScope = {};
    tmpScope.optGroup = response.allScopes[i].nodeID;
    for (let j = 0; j < grpNamePref.length; j++) {
      let prefValue = grpNamePref[j].split(":");
      if (
        response.allScopes[i].scopeObj.displayName.indexOf(prefValue[0]) > -1
      ) {
        if (langCode == "de") {
          tmpScope.group_DisplayName = prefValue[1];
        } else if (langCode == "en") {
          tmpScope.group_DisplayName = prefValue[2];
        }
      }
    }
    tmpScope.groupDisplayName = response.allScopes[i].scopeObj.displayName;
    if (getPropResponse != undefined) {
      //    tmpScope.group_DisplayName = getPropResponse.modelObjects[tmpScope.optGroup].props.object_name.dbValues[0];
    }

    if (
      response.allScopes[i].families.length > 0 ||
      response.scopes.indexOf(response.allScopes[i].nodeID) > -1
    ) {
      // Case: Open panel- show OR
      // Case: There are valid values for a group OR
      // Case: There are no valid values in the expanded group
      tmpScope.expand = true;
    } else {
      tmpScope.expand = false;
    }
    tmpScope.families = getFamiliesForGroup(
      response.allScopes[i],
      response.allScopes[i].families,
      response.labels,
      getPropResponse
    );

    let totalFeatureCount = 0;
    for (let inx = 0; inx < tmpScope.families.length; inx++) {
      if (tmpScope.families[inx].values) {
        totalFeatureCount += tmpScope.families[inx].values.length;
      }
    }
    if (totalFeatureCount > 15) {
      tmpScope.showFilter = true;
    }

    tmpScopes.push(tmpScope);
  }
  return tmpScopes;
};

export let saveAllSolverProfiles = function (response) {
  let g4bConfiguratorSolverProfiles = [
    JSON.parse(response.responseInfo.pca0SolverProfilesLOV[0]),
  ];
  if (appCtxSvc.getCtx("g4bConfiguratorSolverProfiles") == undefined) {
    appCtxSvc.registerCtx(
      "g4bConfiguratorSolverProfiles",
      g4bConfiguratorSolverProfiles
    );
  } else {
    appCtxSvc.updateCtx(
      "g4bConfiguratorSolverProfiles",
      g4bConfiguratorSolverProfiles
    );
  }

  let configPerspective = cdm.getObject(response.configPerspective.uid);
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
  if (variantConfigContext == undefined) {
    variantConfigContext = {};
    variantConfigContext.configPerspective = configPerspective;
    appCtxSvc.registerCtx("variantConfigContext", variantConfigContext);
  } else {
    variantConfigContext.configPerspective = configPerspective;
    appCtxSvc.updateCtx("variantConfigContext", variantConfigContext);
  }
};

export let setConfigSettings = function (response) {
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
  let occmgmtContext = appCtxSvc.getCtx("occmgmtContext");
  let userSettings = "";

  //Case: If we have an existing variantConfigContext for the selected element
  if (
    variantConfigContext &&
    variantConfigContext.userSummaryBackup &&
    variantConfigContext.userSummaryBackup.length > 0 &&
    variantConfigContext.rootElement &&
    variantConfigContext.rootElement.uid == occmgmtContext.rootElement.uid &&
    variantConfigContext.savedModelConfigContext ==
      occmgmtContext.productContextInfo.props.fgf0ModelConfigContext.dbValues[0]
  ) {
    userSettings = JSON.parse(
      response.responseInfo.activeSolverProfileSettings
    );

    variantConfigContext.userSettings = [JSON.stringify(userSettings)];
    variantConfigContext.configPerspective = cdm.getObject(
      response.configPerspective.uid
    );
    appCtxSvc.updateCtx("variantConfigContext", variantConfigContext);
    //eventBus.publish('G4B_CFG_VariantData.updateUserSelectionSummary', variantConfigContext.userSummaryBackup);

    //GEPA-7721 : Save 'isAppliedVariantConfigurationModified' context in case the CFSC is reloaded due to Reciepe change or selection change
    let isAppliedVariantConfigurationModified = appCtxSvc.getCtx(
      "isAppliedVariantConfigurationModified"
    );
    if (isAppliedVariantConfigurationModified == true) {
      appCtxSvc.registerCtx(
        "isAppliedVariantConfigurationModified_Backup",
        isAppliedVariantConfigurationModified
      );
    }
    //End GEPA-7721

    eventBus.publish("callLoadVariantData");
  }
  //Case: When a new variantConfigContext is to be registered
  else {
    if (
      appCtxSvc.getCtx("isAppliedVariantConfigurationModified_Backup") !=
      undefined
    ) {
      appCtxSvc.unRegisterCtx("isAppliedVariantConfigurationModified_Backup");
    }
    userSettings = JSON.parse(
      response.responseInfo.activeSolverProfileSettings
    );

    variantConfigContext = {};
    variantConfigContext.savedModelConfigContext = appCtxSvc.getCtx(
      "occmgmtContext.productContextInfo.props.fgf0ModelConfigContext.dbValues[0]"
    );
    variantConfigContext.configPerspective = cdm.getObject(
      response.configPerspective.uid
    );
    variantConfigContext.rootElement = appCtxSvc.getCtx(
      "occmgmtContext.rootElement"
    );
    variantConfigContext.userSettings = [JSON.stringify(userSettings)];
    appCtxSvc.registerCtx("variantConfigContext", variantConfigContext);

    eventBus.publish("getCustomVariant");
  }
  //eventBus.publish("G4B_CFG_VariantData.fetchAllSolverProfiles");
};

export let getExistingUserSelectionsOnReload = function (data) {
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
  let userSummary = data.userSummary;
  if (
    variantConfigContext &&
    variantConfigContext.userSummaryBackup &&
    variantConfigContext.userSummaryBackup.length > 0 &&
    variantConfigContext.rootElement &&
    variantConfigContext.rootElement.uid ==
      appCtxSvc.getCtx("occmgmtContext.rootElement.uid")
  ) {
    userSummary = variantConfigContext.userSummaryBackup;
  }
  return {userSummary:userSummary};
};

export let getSolverProfile = function () {
  let variantConfigCtx = appCtxSvc.getCtx("variantConfigContext");
  let solverProfile = variantConfigCtx.userSettings[0];

  let isGuidedMode = variantConfigCtx.guidedMode;
  let solverProfileArr = appCtxSvc.getCtx("g4bConfiguratorSolverProfiles")[0];

  for (let inx = 0; inx < solverProfileArr.length; inx++) {
    if (isGuidedMode && solverProfileArr[inx].pca0ProfileName == "g4bOrder") {
      solverProfile = JSON.stringify(solverProfileArr[inx]);
      break;
    } else if (
      !isGuidedMode &&
      solverProfileArr[inx].pca0ProfileName == "g4bOverlay"
    ) {
      solverProfile = JSON.stringify(solverProfileArr[inx]);
      break;
    }
  }
  return solverProfile;
};

// using "g4bOrder" profile for projected and expand calls in manual mode
export let getOrderSolverProfile = function () {
  let solverProfile = null;
  let solverProfileArr = appCtxSvc.getCtx("g4bConfiguratorSolverProfiles")[0];

  for (let inx = 0; inx < solverProfileArr.length; inx++) {
    if (solverProfileArr[inx].pca0ProfileName == "g4bOrder") {
      solverProfile = JSON.stringify(solverProfileArr[inx]);
    }
  }
  return solverProfile;
};

// get "g4bOverlay" profile
export let getOverlaySolverProfile = function () {
  let solverProfile = null;
  let solverProfileArr = appCtxSvc.getCtx("g4bConfiguratorSolverProfiles")[0];

  for (let inx = 0; inx < solverProfileArr.length; inx++) {
    if (solverProfileArr[inx].pca0ProfileName == "g4bOverlay") {
      solverProfile = JSON.stringify(solverProfileArr[inx]);
    }
  }
  return solverProfile;
};

export let formatGroupsAndFamilies = function (_response) {
  let context = appCtxSvc.getCtx("variantConfigContext");
  let responseInContext = appCtxSvc.getCtx("responseInContext");
  if (responseInContext == undefined) {
    appCtxSvc.registerCtx("responseInContext", _response);
  } else {
    appCtxSvc.updateCtx("responseInContext", _response);
  }

  //Update isValid property in context
  context.isValid = _response.responseInfo.isValid[0];

  let completeness = undefined;
  if (_response.responseInfo.criteriaStatus != undefined) {
    if (context.configurationControlMode != "projected") {
      context.criteriaStatus = _response.responseInfo.criteriaStatus[0];
    }

    completeness = _response.responseInfo.criteriaStatus[0].includes(
      "InComplete"
    )
      ? false
      : _response.responseInfo.criteriaStatus[0].includes("Complete")
      ? true
      : undefined;
    context.completeness = completeness;
    appCtxSvc.updateCtx("variantConfigContext", context);
  }

  let allResponseUids = [];
  allResponseUids = extractUids(_response);

  let inputData = {
    objects: allResponseUids,
    attributes: ["object_name", "object_desc"],
  };

  soaSvc
    .post("Internal-Core-2007-12-Session", "getProperties", inputData)
    .then(function (response) {
      let groups = exports.getGroups(_response, response);
      if (groups.length > 0) {
        //Check if we need to highlight a particular family
        if (
          appCtxSvc.getCtx("autoExpandRequiredFamilyUidToHighlight") !=
          undefined
        ) {
          let autoExpandRequiredFamilyUidToHighlight = appCtxSvc.getCtx(
            "autoExpandRequiredFamilyUidToHighlight"
          );
          //rearrange the families for the current expanded group as per the vsv soa output (as shown on the UI)
          let actionType = appCtxSvc.getCtx("variantConfigContext.actionType");
          let requiredFamilyUidToHighlight = rearrangeRequiredFamiliesOfGroup(
            autoExpandRequiredFamilyUidToHighlight,
            _response,
            actionType
          );
          context.currentRequiredFamily = requiredFamilyUidToHighlight;
          exports.highlightIncompleteFamilyCFSC(
            requiredFamilyUidToHighlight,
            appCtxSvc.getCtx("variantConfigContext.multiFeature")
          );
          appCtxSvc.unRegisterCtx("autoExpandRequiredFamilyUidToHighlight");
        }

        let groupsInContext = appCtxSvc.getCtx("groupsInContext");
        if (groupsInContext == undefined) {
          appCtxSvc.registerCtx("groupsInContext", []);
          groupsInContext = appCtxSvc.getCtx("groupsInContext");
        }
        appCtxSvc.updateCtx("groupsInContext", groups);
        eventBus.publish("populateConfigView");

        return groups;
      }
    });
};

let extractUids = function (response) {
  let allScopeUid = [];
  let allFamiliesUid = [];
  let allOptionValueUid = [];
  let i, j, k, obj;

  for (i = 0; i < response.allScopes.length; i++) {
    allScopeUid.push(response.allScopes[i].nodeID);
    if (response.allScopes[i].families.length > 0) {
      let families = response.allScopes[i].families;
      for (j = 0; j < families.length; j++) {
        allFamiliesUid.push(families[j].familyObj.sourceUid);
        let features = families[j].features;
        for (k = 0; k < features.length; k++) {
          allOptionValueUid.push(features[k].featureObject.sourceUid);
        }
      }
    }
  }

  let allUids = [];

  for (j = 0; j < allScopeUid.length; j++) {
    obj = {};
    obj.uid = allScopeUid[j];
    obj.type = "";
    allUids.push(obj);
  }
  for (j = 0; j < allFamiliesUid.length; j++) {
    obj = {};
    obj.uid = allFamiliesUid[j];
    obj.type = "";
    allUids.push(obj);
  }
  for (j = 0; j < allOptionValueUid.length; j++) {
    obj = {};
    obj.uid = allOptionValueUid[j];
    obj.type = "";
    allUids.push(obj);
  }

  return allUids;
};

/**
 *
 *This function makes the changes in standalone option values when any option value is selected from package groups
 */

export let subscribeChangeInState = function (data) {
  let eventData = data.eventData.group;
  let groups = data.getsortedGroup;
  for (let i = 0; i < groups.length; i++) {
    for (let j = 0; j < groups[i].families.length; j++) {
      for (let p = 0; p < eventData.families.length; p++) {
        let family = groups[i].families[j];
        if (family.familyStr == eventData.families[p].familyStr) {
          for (let k = 0; k < family.values.length; k++) {
            for (let q = 0; q < eventData.families[p].values.length; q++) {
              if (
                family.values[k].optValueStr ==
                eventData.families[p].values[q].optValueStr
              ) {
                if (
                  eventData.families[p].values[q].selectionState == 1 ||
                  eventData.families[p].values[q].selectionState == 5 ||
                  eventData.families[p].values[q].selectionState == 9
                ) {
                  // If a selection is present in package and not in standalone make the selection in standalone
                  if (
                    data.getsortedGroup[i].families[j].values[k]
                      .selectionState == 0 ||
                    data.getsortedGroup[i].families[j].values[k]
                      .selectionState == 6 ||
                    data.getsortedGroup[i].families[j].values[k]
                      .selectionState == 10
                  ) {
                    if (eventData.families[p].values[q].selectionState == 1) {
                      data.getsortedGroup[i].families[j].values[
                        k
                      ].selectionState = 1;
                    }
                  }
                  // Remove any other selections in the family in standalone
                  for (let l = 0; l < family.values.length; l++) {
                    if (
                      family.singleSelect &&
                      k != l &&
                      (family.values[l].selectionState == 1 ||
                        family.values[l].selectionState == 5 ||
                        family.values[l].selectionState == 6 ||
                        family.values[l].selectionState == 9 ||
                        family.values[l].selectionState == 10)
                    ) {
                      if (eventData.families[p].values[q].selectionState == 1) {
                        data.getsortedGroup[i].families[j].values[
                          l
                        ].selectionState = 0;
                      }
                    }
                  }
                } else {
                  // If selection not present in package then remove it from standalone as well
                  data.getsortedGroup[i].families[j].values[
                    k
                  ].selectionState = 0;
                }
              }
            }
          }
        }
      }
    }
  }
};
/**
 * Returns groups from SOA response
 *
 * @param {Object} response the response from the variant configuration SOA
 *
 * @returns {ObjectArray} The array of option groups to be displayed.
 */
export let getGroups = function (response, getPropResponse) {
  let context = appCtxSvc.getCtx("variantConfigContext");

  //This is the case when user tries to switch to Guided mode with invalid configuration.
  //Server should not send any groups in the response
  //       if (response.responseInfo.isValid[0] === 'false' && response.allScopes.length === 0) {
  if (response.responseInfo.isValid != undefined) {
    if (
      response.responseInfo.isValid[0] === "false" &&
      response.allScopes.length == 0
    ) {
      handleInvalidConfiguration(context);
      // _configuratorUtils.processPartialErrors(response.ServiceData);
      if (context.switchingToGuidedMode == true) {
        exports.showViolationsOnValidation(
          response.labels,
          response.responseInfo,
          response.payloadStrings
        );
      }
    } else {
      // Populate option group structure
      let tmpScopes = exports.populateScopes(response, getPropResponse);

      // Update application context
      if (response.scopes.length !== 0) {
        context.currentGroup = response.scopes[0];
      }
      context.configPerspective = response.configPerspective;
      context.payloadStrings = response.payloadStrings;

      if (context.guidedMode === undefined || context.guidedMode === true) {
        context.guidedMode = true;
        context.selections = response.selections;
      }

      appCtxSvc.updateCtx("variantConfigContext", context);
      appCtxSvc.updateCtx("storeContext", context);
      optionGroups = tmpScopes;
    }
  }

  if (response.responseInfo.isValid != undefined) {
    if (
      response.responseInfo.isValid[0] === "false" &&
      context.guidedMode === true
    ) {
      eventBus.publish("fullScreenConfig_updateVariantMode", response);
    }
  }

  getSystemSelectionsFromCurrentGroup();

  return optionGroups;
};

/**
 * Displays the violation message if validation is invoked in manual mode.
 *
 * @param {ObjectArray} labels - The violationLabels returned by SOA service which will be rendered on features
 * @param {Object} responseInfo - Additional information about response returned by SOA service
 * @param {String} payloadStrings -Map of <string,String[]> returned by SOA service
 *
 */
export let g4b_showViolationsOnValidation = function (
  labels,
  responseInfo,
  payloadStrings
) {
  let context = appCtxSvc.getCtx("variantConfigContext");
  let currentGroup = context.currentGroup;

  if (currentGroup !== undefined) {
    parseResponseAndExtractViolations(labels, context.currentGroup);

    if (context.validatePostImportConfigurationFlag) {
      context.violationLabel = undefined;

      let violationsArrPromise = extractViolationsForImportConfig(
        labels,
        payloadStrings
      );
      $.when(violationsArrPromise).done(function (violationNamesArr) {
        let importConfigPanelVM = viewModelSvc.getViewModelUsingElement(
          $("#G4B_ImportConfigurationPanelId")
        );
        if (importConfigPanelVM && violationNamesArr.length > 0) {
          let g4bViolationsText =
            localeService.getLoadedText(
              app.getBaseUrlPath() + "/i18n/GepardToolsInfoCommandsMessages"
            ).g4bInvalidFeatureMessage + "\n";
          for (let inx = 0; inx < violationNamesArr.length; inx++) {
            g4bViolationsText += " &#8226; " + violationNamesArr[inx];
            if (inx < violationNamesArr.length - 1) {
              g4bViolationsText += "\n";
            }
          }

          importConfigPanelVM.g4bImportInformationBoxTextarea.dbValue =
            g4bViolationsText;
          importConfigPanelVM.g4bImportInformationBoxTextarea.uiValue =
            g4bViolationsText;
        }

        delete context.validatePostImportConfigurationFlag;
        eventBus.publish("G4B_CFG_VariantDataView.callselectValue", {});
      });
    } else {
      if (
        responseInfo !== undefined &&
        responseInfo.validationErrorMessage !== undefined
      ) {
        context.violationLabel = responseInfo.validationErrorMessage[0];
      } else {
        context.violationLabel = getLocaleTextBundle().noViolations;
      }
    }

    context.payloadStrings = payloadStrings;
    appCtxSvc.updateCtx("variantConfigContext", context);

    eventBus.publish("G4B_CFG_familyDirective.updateViolationIcon", {});
  }
};

export let reloadOnValidate = function (response) {
  //Set 'isValidatedByUser' to true and Update isValid property in context
  let context = appCtxSvc.getCtx("variantConfigContext");
  context.isValid = response.responseInfo.isValid[0];
  context.isValidatedByUser = "true";
  if (response.responseInfo.criteriaStatus != undefined) {
    if (context.configurationControlMode != "projected") {
      context.criteriaStatus = response.responseInfo.criteriaStatus[0];
    }
    context.completeness = response.responseInfo.criteriaStatus[0].includes(
      "InComplete"
    )
      ? false
      : response.responseInfo.criteriaStatus[0].includes("Complete")
      ? true
      : undefined;
  } else {
    context.completeness = undefined;
  }

  appCtxSvc.updateCtx("variantConfigContext", context);

  if (
    response.responseInfo.isValid[0] == "true" &&
    response.selections.length > 0 &&
    response.selections[0].selections != undefined
  ) {
    eventBus.publish("showValidConfiguration", {});
  } else if (!context.validatePostImportConfigurationFlag) {
    eventBus.publish("showInvalidConfiguration", response);
  }
};

let getSelectedRadio = function (propName) {
  let radioSelected = "";
  if (
    appCtxSvc.getCtx(
      "g4b_TimeSliceContext.timeSliceAttrSelected[propName].length"
    ) > 0
  ) {
    radioSelected = appCtxSvc.getCtx(
      "g4b_TimeSliceContext.timeSliceAttrSelected[propName]"
    );
  } else {
    let tsInfoRadioButtonData = appCtxSvc.getCtx(
      "g4b_TimeSliceContext.tsInfoRadioButtonData"
    );
    for (let inx = 0; inx < tsInfoRadioButtonData[propName].length; inx++) {
      if (tsInfoRadioButtonData[propName][inx].radioSelected == true) {
        radioSelected = tsInfoRadioButtonData[propName][inx].propDisplayValue;
      }
    }
  }
  if (radioSelected == "") {
    radioSelected = appCtxSvc.getCtx(
      "g4b_PreviousTimeSliceOnCD[propName.toLowerCase()]"
    );
  }

  return radioSelected;
};

export let setRevRuleEffectivityInput_forTimeSlicePanel = function (
  isPrevTSHyperlink
) {
  //tsCtx can be an array with sequential data of opt.values
  let der, bp, sop;
  let tsCtx = appCtxSvc.getCtx("g4b_TimeSliceContext");
  if (isPrevTSHyperlink) {
    der = appCtxSvc.getCtx("g4b_PreviousTimeSliceOnCD.g4b_der");
    bp = appCtxSvc.getCtx("g4b_PreviousTimeSliceOnCD.g4b_bp");
    sop = appCtxSvc.getCtx("g4b_PreviousTimeSliceOnCD.g4b_sop");
  } else {
    der = getSelectedRadio("g4b_DER");
    bp = getSelectedRadio("g4b_BP");
    sop = getSelectedRadio("g4b_SOP");
  }

  let TSgroupSelections = [];
  let family = ["BP", "DER", "SOP"];
  tsCtx = [bp, der, sop];

  for (let index = 0; index < family.length; index++) {
    let familyAndOptionValue = {
      familyName: family[index],
      optionValue: tsCtx[index],
    };
    TSgroupSelections.push(familyAndOptionValue);
  }
  if (appCtxSvc.getCtx("revRuleEffectivityData") != undefined) {
    appCtxSvc.updateCtx("revRuleEffectivityData", TSgroupSelections);
    exports.createRevisionEffectivityIntentFormula();
  } else {
    appCtxSvc.registerCtx("revRuleEffectivityData", TSgroupSelections);
    exports.createRevisionEffectivityIntentFormula();
  }
};

/**
 * Used to generate the Revision Effectivity intent formula based on the time-slice selected
 */
export let createRevisionEffectivityIntentFormula = function () {
  let tsCtx = appCtxSvc.getCtx("g4b_TimeSliceContext");
  let collaborativeDesignUid = tsCtx.collaborativeDesign.uid;
  let inputData = {
    objects: [
      {
        uid: collaborativeDesignUid,
        type: "G4B_CollaborativeDesign",
      },
    ],
    attributes: [
      "mdl0config_product_name",
      "mdl0config_prod_namespace",
      "g4b_EffProductNameSpace",
    ],
  };

  soaSvc
    .post("Internal-Core-2007-12-Session", "getProperties", inputData)
    .then(function (response) {
      if (response) {
        let context = appCtxSvc.getCtx("revRuleEffectivityData");
        if (context != undefined) {
          let objectId =
            response.modelObjects[collaborativeDesignUid].props
              .g4b_EffProductNameSpace.dbValues[0];
          let formula = "";
          let concateAmp = "&";

          for (let i = 0; i < context.length - 1; i++) {
            let _formula =
              "[" +
              objectId +
              "]" +
              context[i].familyName +
              "=" +
              context[i].optionValue +
              concateAmp;
            formula = formula + _formula;
          }

          formula =
            formula +
            "[" +
            objectId +
            "]" +
            context[context.length - 1].familyName +
            "=" +
            context[context.length - 1].optionValue;

          applyRevisionEffectivityIntentToProductLine(formula);

          let currentTSselected =
            appCtxSvc.getCtx(
              "g4b_TimeSliceContext.timeSliceAttrSelected.g4b_DER"
            ) +
            "_" +
            appCtxSvc.getCtx(
              "g4b_TimeSliceContext.timeSliceAttrSelected.g4b_BP"
            ) +
            "_" +
            appCtxSvc.getCtx(
              "g4b_TimeSliceContext.timeSliceAttrSelected.g4b_SOP"
            );
          let prevTSselected = appCtxSvc.getCtx("g4b_PreviousTimeSliceOnCD")
            ? appCtxSvc.getCtx("g4b_PreviousTimeSliceOnCD.g4b_quickSelect")
            : "";
          let resetFlag = currentTSselected != prevTSselected ? true : false;

          /* NOTE: In case a new time-slice is selected on the open time-slice panel then we reset the Product line first to get rid of the subset filters
           *  and variant configuration.
           **/

          let resetPromise = resetProductLine(resetFlag);
          $.when(resetPromise)
            .done()
            .then(function () {
              eventBus.publish("openProductLine_Event");
            });
        }
      }
    });
};

/**
 * This function updates the product line with the new effectivity intent formula
 * @param {String} formula
 */
let applyRevisionEffectivityIntentToProductLine = function (formula) {
  let eventSubscription = eventBus.subscribe(
    "Fgf0OrganizationSchemeFeature.contentLoaded",
    function () {
      let productContextInfo =
        appCtxSvc.getCtx("aceActiveContext").context.productContextInfo;

      if (productContextInfo != undefined) {
        let path = "aceActiveContext.context.configContext";
        let value = {
          r_uid: productContextInfo.props.awb0CurrentRevRule.dbValues[0],
          var_uids: productContextInfo.props.awb0CurrentVariantRules.dbValues,
          fromUnit: 1,
          toUnit: 1,
          startDate: productContextInfo.props.awb0StartEffDates.dbValues[0],
          endDate: productContextInfo.props.awb0EndEffDates.dbValues[0],
          intentFormula: formula,
          startFreshNavigation: true,
        };

        appCtxSvc.updatePartialCtx(path, value);
        eventBus.unsubscribe(eventSubscription);
      }
    }
  );
};

/**
 * Resets the Product Line if resetFlag is true
 * @param {Boolean} resetFlag
 */
let resetProductLine = function (resetFlag) {
  let deferred = AwPromiseService.instance.defer();
  if (resetFlag === true) {
    let inputData = {
      inputData: {
        product: {
          uid: appCtxSvc.getCtx("selected.uid"),
          type: appCtxSvc.getCtx("selected.type"),
        },
        requestPref: {
          calculateFilters: ["true"],
          displayMode: ["Tree"],
          includePath: ["true"],
          savedSessionMode: ["reset"],
          startFreshNavigation: ["true"],
          useGlobalRevRule: ["false"],
          showMarkup: ["false"],
        },
      },
    };
    soaSvc
      .post(
        "Internal-ActiveWorkspaceBom-2019-12-OccurrenceManagement",
        "getOccurrences3",
        inputData
      )
      .then(function (response) {
        deferred.resolve(response.rootProductContext.uid);
      });
  } else {
    deferred.resolve(null);
  }
  return deferred.promise;
};

/**
 * Displays the violation message if validation is invoked in manual mode.
 *
 * @param {ObjectArray} labels - The violationLabels returned by SOA service which will be rendered on features
 * @param {Object} responseInfo - Additional information about response returned by SOA service
 * @param {String} payloadStrings -Map of <string,String[]> returned by SOA service
 *
 */
export let showViolationsOnValidation = function (
  labels,
  responseInfo,
  payloadStrings
) {
  let context = appCtxSvc.getCtx("variantConfigContext");
  let currentGroup = context.currentGroup;

  if (currentGroup !== undefined) {
    parseResponseAndExtractViolations(labels, context.currentGroup);
    //let violationMsg;

    if (
      responseInfo !== undefined &&
      responseInfo.validationErrorMessage !== undefined
    ) {
      context.violationLabel = responseInfo.validationErrorMessage[0];
    } else {
      context.violationLabel = getLocaleTextBundle().noViolations;
    }
    context.payloadStrings = payloadStrings;
    appCtxSvc.updateCtx("variantConfigContext", context);
    appCtxSvc.updateCtx("storeContext", context);
    //  eventBus.publish( "awCustomVariantPanel.updateViolationIcon", {} );
  }
};

export let getPreferenceValue = function (data) {
  let isAssignedAndApplied = data.isAssignedAndApplied;
  let inputData = {
    requestedPrefs: [
      {
        scope: "user",
        names: [
          "Cfg0UseRuleForContentConfigure",
          "G4B_AWC_ConfiguratorUserFavorites",
          "G4B_AWC_ConfiguratorCheckboxValue",
        ],
      },
    ],
  };

  soaSvc
    .post("Core-2007-01-Session", "getPreferences", inputData)
    .then(function (response) {
      //setInitialPreferenceValue(response);
      if (response != undefined && response.preferences.length > 0) {
        if (
          appCtxSvc.getCtx("prefG4B_AWC_ConfiguratorUserFavorites") == undefined
        ) {
          appCtxSvc.registerCtx(
            "prefG4B_AWC_ConfiguratorUserFavorites",
            response.preferences[1]
          );
        } else {
          appCtxSvc.updateCtx(
            "prefG4B_AWC_ConfiguratorUserFavorites",
            response.preferences[1]
          );
        }
        // storing value for configurator Checkbox
        if (
          appCtxSvc.getCtx("prefG4B_AWC_ConfiguratorCheckboxValue") == undefined
        ) {
          appCtxSvc.registerCtx(
            "prefG4B_AWC_ConfiguratorCheckboxValue",
            JSON.parse(response.preferences[2].values[0])
          );
          isAssignedAndApplied.dbValue = JSON.parse(
            response.preferences[2].values[0]
          );
        } else {
          appCtxSvc.updateCtx(
            "prefG4B_AWC_ConfiguratorCheckboxValue",
            JSON.parse(response.preferences[2].values[0])
          );
          isAssignedAndApplied.dbValue = JSON.parse(
            response.preferences[2].values[0]
          );
        }
      }
    });
    return {isAssignedAndApplied:isAssignedAndApplied};
};

/**
 * Parse the violations from response and return values which have the violations.
 *
 * @param {ObjectArray} labels - The violationLabels returned by SOA service which will be rendered on features
 * @param {String} activeGroup - Currently active option group in variant panel
 *
 */
function parseResponseAndExtractViolations(labels, activeGroup) {
  let group = {};

  for (let i = 0; i < optionGroups.length; i++) {
    if (optionGroups[i].optGroup === activeGroup) {
      group = optionGroups[i];
      break;
    }
  }

  if (
    labels !== undefined &&
    labels.violationMap[0] !== undefined &&
    labels.violationMap[0].nodeMap !== undefined
  ) {
    // Get the violation ids from the labels map and extract the keys.
    let violationIds = Object.keys(labels.violationMap[0].nodeMap);

    if (
      group !== undefined &&
      violationIds !== undefined &&
      violationIds.length > 0
    ) {
      for (let inx = 0; inx < group.families.length; inx++) {
        if (group.families[inx].values.length > 0) {
          for (
            let valueIdx = 0;
            valueIdx < group.families[inx].values.length;
            valueIdx++
          ) {
            let valueObj = group.families[inx].values[valueIdx];
            // Process this value if its ID exist in the violationIds.
            if (violationIds.indexOf(valueObj.optValueStr) > -1) {
              let violationsInfo = buildViolationString(
                valueObj.optValueStr,
                labels
              );
              if (
                violationsInfo !== null &&
                violationsInfo.violationMessage !== undefined
              ) {
                // Update the violations directly onto the value object which is present in the UI model.
                valueObj.violationsInfo = violationsInfo;
                valueObj.hasViolation = true;
              } else {
                valueObj.hasViolation = false;
              }
            }
          }
        }
      }
    }
  }
}

function extractViolationsForImportConfig(labels, payloadStrings) {
  let deferred = AwPromiseService.instance.defer();

  let allViolationsUidArr = [];
  let violationFeatureNamesArr = [];

  //Get the violation with highest priority from payload data
  let payloadJSON = JSON.parse(payloadStrings.violationResponse[0]);
  if (
    payloadJSON.vpcResponse &&
    payloadJSON.vpcResponse.length > 0 &&
    payloadJSON.vpcResponse[0].violationConflicts
  ) {
    let conflictsArr = payloadJSON.vpcResponse[0].violationConflicts;
    for (let jnx = 0; jnx < conflictsArr.length; jnx++) {
      if (conflictsArr[jnx].violations[0].violationObjectUid) {
        allViolationsUidArr.push(
          conflictsArr[jnx].violations[0].violationObjectUid
        );
      }
    }
  }

  //Get other violations
  let violationsMapArr = Object.keys(labels.violationMap[0].nodeMap);
  for (let inx = 0; inx < violationsMapArr.length; inx++) {
    let violationSplit = violationsMapArr[inx].split(":");
    if (violationSplit.length > 1 && violationSplit[1].length > 0) {
      allViolationsUidArr.push(violationSplit[1]);
    }
  }

  let objsToLoad = [];
  for (let inx = 0; inx < allViolationsUidArr.length; inx++) {
    objsToLoad.push({
      uid: allViolationsUidArr[inx],
      type: "",
    });
  }

  let inputData = {
    objects: objsToLoad,
    attributes: ["object_name"],
  };

  soaSvc
    .post("Internal-Core-2007-12-Session", "getProperties", inputData)
    .then(function (response) {
      for (let knx = 0; knx < allViolationsUidArr.length; knx++) {
        let featureObj = cdm.getObject(allViolationsUidArr[knx]);
        if (
          featureObj != null &&
          featureObj.props &&
          featureObj.props.object_name &&
          violationFeatureNamesArr.indexOf(
            featureObj.props.object_name.dbValues[0]
          ) < 0
        ) {
          violationFeatureNamesArr.push(
            featureObj.props.object_name.dbValues[0]
          );
        }
      }
      deferred.resolve(violationFeatureNamesArr);
    });

  return deferred.promise;
}

/**
 * This method finds and returns an instance for the locale resource defined under 'EffectivityMessages.JSON'
 * file.
 *
 * @return {Object} : The instance of locale resource if found, null otherwise.
 */
function getLocaleTextBundle() {
  let resource = app.getBaseUrlPath() + "/i18n/ConfiguratorMessages";
  let localeTextBundle = localeSvc.getLoadedText(resource);
  if (localeTextBundle) {
    return localeTextBundle;
  }
  return null;
}
/**
 * Function invoked when validation is requested.
 */
export let validateConfiguration = function () {
  let context = appCtxSvc.getCtx("variantConfigContext");
  removeViolationsFromCurrentGroup(context);
  context.violationLabel = getLocaleTextBundle().validationInProgress;
  appCtxSvc.updateCtx("variantConfigContext", context);

  eventBus.publish("G4B_CFG_VariantData.validate", {});
};

/**
 * This function removes the violations from the current group.
 *
 * @param {Object} context - The 'VariantConfigContext' object
 */
function removeViolationsFromCurrentGroup(context) {
  //iterate over the groups
  for (let i = 0; i < optionGroups.length; i++) {
    //If the group is the currently expanded group then iterate through its value selections and remove the system selections
    if (optionGroups[i].optGroup === context.currentGroup) {
      let group = optionGroups[i];
      //Itearate over the families of currently expanded group
      for (let j = 0; j < group.families.length; j++) {
        let family = group.families[j];
        //Iterate over the values of currently expanded group
        for (let k = 0; k < family.values.length; k++) {
          let value = family.values[k];
          //If the value is not available in user selection map then reset its selection state to 0
          delete value.violationsInfo;
          value.hasViolation = false;
        }
      }
    }
  }
}

export let getFamilyData = function (response) {
  let completeGroup;
  let familyData = appCtxSvc.getCtx("familyData");
  if (familyData == undefined) {
    appCtxSvc.registerCtx("familyData", []);
    familyData = appCtxSvc.getCtx("familyData");
  }
  let storeFamilyDataContext = appCtxSvc.getCtx("storeFamilyData");
  console.log("storeFamilyDataContext", storeFamilyDataContext);
  //let tempFamilyData =[];
  let flag = 0;
  for (let i = 0; i < response.allScopes.length; i++) {
    if (response.allScopes[i].families.length > 0) {
      if (familyData.length == 0) {
        completeGroup = response.allScopes[i];
        familyData = completeGroup;
        appCtxSvc.updateCtx("familyData", familyData);

        storeFamilyDataContext.push(familyData);
        appCtxSvc.updateCtx("storeFamilyData", storeFamilyDataContext);
      } else {
        for (let j = 0; j < storeFamilyDataContext.length; j++) {
          let groupName = response.allScopes[i].scopeObj.displayName;

          if (groupName != storeFamilyDataContext[j].scopeObj.displayName) {
            flag++;
          }

          if (storeFamilyDataContext.length == flag) {
            completeGroup = response.allScopes[i];
            familyData = completeGroup;
            appCtxSvc.updateCtx("familyData", familyData);

            storeFamilyDataContext.push(familyData);
            appCtxSvc.updateCtx("storeFamilyData", storeFamilyDataContext);
          }
        }
        flag = 0;
      }
    }
  }
};

/**
 * Getting all the groups and related data in a single list.
 */
/**
 * Returns groups from SOA response
 *
 * @param {Object} response the response from the variant configuration SOA
 *
 * @returns {ObjectArray} The array of option groups to be displayed.
 */

export let listAllGroupData = function (response) {
  let i;
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
  if (
    response.selectionsSummary.summaryOfSelections.length > 0 ||
    variantConfigContext.guidedMode == false
  ) {
    let selected = appCtxSvc.getCtx("selected");
    let selectedObj_uid = selected.uid;
    let selectedObj_type = selected.type;

    let allScopes = response.allScopes;

    let listAllGroupData = appCtxSvc.getCtx("listAllGroupData");
    if (listAllGroupData == undefined) {
      appCtxSvc.registerCtx("listAllGroupData", []);
      listAllGroupData = appCtxSvc.getCtx("listAllGroupData");
    } else {
      listAllGroupData.length = 0; //empty the array contents
      appCtxSvc.updateCtx("listAllGroupData", listAllGroupData);
    }

    for (i = 0; i < allScopes.length; i++) {
      let inputData = {
        input: {
          configPerspective: variantConfigContext.configPerspective,
          selectedObject: {
            type: selectedObj_type,
            uid: selectedObj_uid,
          },

          payloadStrings: variantConfigContext.payloadStrings,
          scopes: [allScopes[i].nodeID],
          requestInfo: {
            requestType: ["getConfig"],
            isFsc: ["true"],
            profileSettings: [exports.getSolverProfile()],
            configurationControlMode: ["manual"],
            switchingToGuidedMode: [""],
          },
        },
      };

      if (
        cdm
          .getObject(allScopes[i].nodeID)
          .props.object_string.dbValues[0].indexOf("SLP") > -1
      ) {
        inputData.input.requestInfo.configurationControlMode = ["guided"];
        inputData.input.selections = variantConfigContext.selections;
      }

      soaSvc
        .post(
          "Internal-ProductConfiguratorAw-2022-12-ConfiguratorManagement",
          "variantConfigurationView3",
          inputData
        )
        .then(function (_response) {
          //console.log("iteration_", i, "_", _response.allScopes);

          listAllGroupData.push(_response.allScopes);
          appCtxSvc.updateCtx("listAllGroupData", listAllGroupData);

          if (listAllGroupData.length == allScopes.length) {
            eventBus.publish("callPopulateSummary", response);
          }
        });
    }
    return "";
  }
};

export let createSystemSelectionMap = function (data) {
  let selectionInPersistentSummary = appCtxSvc.getCtx(
    "selectionInPersistentSummary"
  );
  if (selectionInPersistentSummary == undefined) {
    appCtxSvc.registerCtx("selectionInPersistentSummary", []);
    selectionInPersistentSummary = appCtxSvc.getCtx(
      "selectionInPersistentSummary"
    );
  }

  appCtxSvc.updateCtx("selectionInPersistentSummary", data.eventData);
  let activeToolsAndInfoCommand = appCtxSvc.getCtx("activeToolsAndInfoCommand");
  if (
    activeToolsAndInfoCommand != undefined &&
    activeToolsAndInfoCommand.commandId == "g4bshowSystemSelections"
  ) {
    eventBus.publish("updatepanel");
  }

  console.log(data.eventData);
};

export let showSystemSelections = function (data) {
  data.systemSelectionMap = [];
  let selectionInPersistentSummary = appCtxSvc.getCtx(
    "selectionInPersistentSummary"
  );

  let groupsInContext = appCtxSvc.getCtx("groupsInContext");
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
  let list = [];
  let count = 0;
  for (let i = 0; i < groupsInContext.length; i++) {
    let inputData = {
      input: {
        configPerspective: variantConfigContext.configPerspective,
        selections: selectionInPersistentSummary,
        scopes: [groupsInContext[i].optGroup],
        requestInfo: {
          requestType: ["getConfig"],
          isFsc: ["true"],
          profileSettings: [exports.getSolverProfile()],
          configurationControlMode: [exports.getConfigurationMode()],
          switchingToGuidedMode: [""],
        },
      },
    };

    soaSvc
      .post(
        "Internal-ProductConfiguratorAw-2022-12-ConfiguratorManagement",
        "variantConfigurationView3",
        inputData
      )
      .then(function (response) {
        count++;
        for (let j = 0; j < response.allScopes.length; j++) {
          if (response.allScopes[j].families.length > 0) {
            let families = response.allScopes[j].families;
            for (let k = 0; k < families.length; k++) {
              let family = families[k];
              for (let l = 0; l < family.features.length; l++) {
                let feature = family.features[l];
                if (feature.selectionInfo.selectionState != 0) {
                  let obj = {};
                  //  if(selectionInPersistentSummary[0].selections[family.familyObj.sourceUid])
                  obj.familyDisplayName = family.familyObj.displayName;
                  obj.familyUid = family.familyObj.sourceUid;
                  obj.valueDisplayName =
                    feature.featureObject.wsObject.props.object_name.dbValues[0];
                  if (
                    cdm.isValidObjectUid(feature.featureObject.wsObject.uid)
                  ) {
                    obj.optValue = viewModelObjSvc.createViewModelObject(
                      feature.featureObject.wsObject
                    );
                  }
                  obj.optValueStr = feature.selectionInfo.nodeID;

                  list.push(obj);
                }
              }
            }
          }
        }

        console.log(count);
        if (count == groupsInContext.length) {
          console.log("Map:", list);
          data.systemSelections = list;
          eventBus.publish("checkfordata");
        }
      });
  }
};

/**
 * This function uses user selections to get system selections while saving the variant rule
 */
export let setSelectionsForVariantRules = function (data) {
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
  let selections = JSON.parse(JSON.stringify(variantConfigContext.selections));
  selections[0].affectedObject = "";

  let groupsInContext = appCtxSvc.getCtx("groupsInContext");

  if (
    variantConfigContext.guidedMode &&
    data.saveSystemSelectionsinSvr.dbValue
  ) {
    let slpGroupUid = "";
    for (let i = 0; i < groupsInContext.length; i++) {
      let slpGroupName = groupsInContext[i].groupDisplayName.split("_");
      if (slpGroupName[0] == "SLP") {
        slpGroupUid = groupsInContext[i].optGroup;
      }
    }
    let inputData = {
      input: {
        configPerspective: variantConfigContext.configPerspective,
        selections: appCtxSvc.getCtx("variantConfigContext.selections"),
        scopes: [slpGroupUid],
        requestInfo: {
          requestType: ["expand"],
          isFsc: ["true"],
          profileSettings: [exports.getSolverProfile()],
          configurationControlMode: ["manual"],
          switchingToGuidedMode: [""],
        },
      },
    };

    soaSvc
      .post(
        "Internal-ProductConfiguratorAw-2022-12-ConfiguratorManagement",
        "variantConfigurationView3",
        inputData
      )
      .then(function (response) {
        let selectionsCopy2 = JSON.parse(JSON.stringify(response.selections));
        for (let key in selectionsCopy2[0].selections) {
          for (
            let inx = 0;
            selectionsCopy2[0].selections[key] != undefined &&
            inx < selectionsCopy2[0].selections[key].length;
            inx++
          ) {
            //let obj = {};
            //  if(selectionInPersistentSummary[0].selections[family.familyObj.sourceUid])

            if (
              selectionsCopy2[0].selections[key][inx].nodeID.split(":")[1]
                .length > 0
            ) {
              if (
                selectionsCopy2[0].selections[key][inx].selectionState == 5 ||
                selectionsCopy2[0].selections[key][inx].selectionState == 9
              ) {
                //Convert selectionState=9(System Positive) to selectionState=1(User Positive)
                selectionsCopy2[0].selections[key][inx].selectionState = 1;
              } else if (
                selectionsCopy2[0].selections[key][inx].selectionState == 6 ||
                selectionsCopy2[0].selections[key][inx].selectionState == 10
              ) {
                //Convert selectionState=10(System Negative) to selectionState=2(User Negative)
                selectionsCopy2[0].selections[key][inx].selectionState = 2;
              }
            } else {
              selectionsCopy2[0].selections[key].splice(inx, 1);
              if (selectionsCopy2[0].selections[key].length == 0) {
                delete selectionsCopy2[0].selections[key];
              } else {
                inx--;
              }
            }
          }
        }

        variantConfigContext.selectionsForSaveSVR = selectionsCopy2;
        appCtxSvc.updateCtx("variantConfigContext", variantConfigContext);
        eventBus.publish(
          "g4bSaveCustomVariantRuleViewModel.updateCreateVariantRule"
        );
      });
  } else {
    //Remove any system selections when saving in unguided mode
    if (
      appCtxSvc.getCtx("variantConfigContext.guidedMode") == false &&
      !data.saveSystemSelectionsinSvr.dbValue
    ) {
      let selectionsCopy = JSON.parse(
        JSON.stringify(variantConfigContext.selections)
      );
      for (let key in selectionsCopy[0].selections) {
        for (
          let inx = 0;
          selectionsCopy[0].selections[key] != undefined &&
          inx < selectionsCopy[0].selections[key].length;
          inx++
        ) {
          if (
            selectionsCopy[0].selections[key][inx].nodeID.split(":")[1].length >
            0
          ) {
            if (
              selectionsCopy[0].selections[key][inx].selectionState != 1 &&
              selectionsCopy[0].selections[key][inx].selectionState != 2
            ) {
              selectionsCopy[0].selections[key].splice(inx, 1);
              inx--;
            }
          } else {
            selectionsCopy[0].selections[key].splice(inx, 1);
            if (selectionsCopy[0].selections[key].length == 0) {
              delete selectionsCopy[0].selections[key];
            } else {
              inx--;
            }
          }
        }
      }
      variantConfigContext.selectionsForSaveSVR = selectionsCopy;
    } else if (
      appCtxSvc.getCtx("variantConfigContext.guidedMode") == false &&
      data.saveSystemSelectionsinSvr.dbValue
    ) {
      let selectionsCopy2 = JSON.parse(
        JSON.stringify(variantConfigContext.selections)
      );
      for (let key in selectionsCopy2[0].selections) {
        for (
          let inx = 0;
          selectionsCopy2[0].selections[key] != undefined &&
          inx < selectionsCopy2[0].selections[key].length;
          inx++
        ) {
          if (
            selectionsCopy2[0].selections[key][inx].nodeID.split(":")[1]
              .length > 0
          ) {
            if (
              selectionsCopy2[0].selections[key][inx].selectionState == 5 ||
              selectionsCopy2[0].selections[key][inx].selectionState == 9
            ) {
              selectionsCopy2[0].selections[key][inx].selectionState = 1;
            } else if (
              selectionsCopy2[0].selections[key][inx].selectionState == 6 ||
              selectionsCopy2[0].selections[key][inx].selectionState == 10
            ) {
              selectionsCopy2[0].selections[key][inx].selectionState = 2;
            }
          } else {
            selectionsCopy2[0].selections[key].splice(inx, 1);
            if (selectionsCopy2[0].selections[key].length == 0) {
              delete selectionsCopy2[0].selections[key];
            } else {
              inx--;
            }
          }
        }
      }
      variantConfigContext.selectionsForSaveSVR = selectionsCopy2;
    } else {
      variantConfigContext.selectionsForSaveSVR = selections;
    }

    appCtxSvc.updateCtx("variantConfigContext", variantConfigContext);
    eventBus.publish(
      "g4bSaveCustomVariantRuleViewModel.updateCreateVariantRule"
    );
  }
};

export let importConfigSetVariantRuleProps = function (data) {
  let variantRuleObj = cdm.getObject(data.variantRule.uid);

  let TsInfo = appCtxSvc.getCtx("g4bCurrentTS");

  let configMode = exports.getConfigurationMode();
  let inputData = {
    info: [
      {
        object: variantRuleObj,
        vecNameVal: [
          {
            name: "g4b_ConfigurationMode",
            values: ["1"],
          },
          {
            name: "g4b_DER",
            values: [TsInfo.g4b_DER],
          },
          {
            name: "g4b_SOP",
            values: [TsInfo.g4b_SOP],
          },
          {
            name: "g4b_BP",
            values: [TsInfo.g4b_BP],
          },
          {
            name: "g4b_Archived",
            values: ["true"],
          },
          {
            name: "object_name",
            values: [
              variantRuleObj.props.object_name.dbValues[0] +
                appCtxSvc.getCtx("userSession.props.user.uiValues[0]"),
            ],
          },
        ],
      },
    ],
    options: [],
  };

  let isValidBool = appCtxSvc.getCtx("variantConfigContext").isValid;

  if (configMode == "guided") {
    
    if (isValidBool != undefined && isValidBool == "true") {
      inputData.info[0].vecNameVal.push({
        name: "g4b_IsValid",
        values: ["true"],
      });
      inputData.info[0].vecNameVal.push({
        name: "g4b_LastValidityCheck",
        values: [new Date().toISOString()],
      });
    }
  }

  soaSvc
    .post("Core-2010-09-DataManagement", "setProperties", inputData)
    .then(function () {});
};

export let returnResponseToDataProvider = function (data) {
  let systemSelectionslist = data.systemSelections;
  let familyInfo = [];
  let i, j, k;

  let familyFlag = 0;
  let formatedlist = [];
  if (systemSelectionslist.length != 0) {
    for (i = 0; i < systemSelectionslist.length; i++) {
      if (systemSelectionslist[i].familyDisplayName != undefined) {
        let name = systemSelectionslist[i].familyDisplayName;

        for (j = 0; j < familyInfo.length; j++) {
          if (name != familyInfo[j]) {
            familyFlag++;
          }
        }
        if (familyFlag == familyInfo.length) {
          familyInfo.push(name);
        }
      }
      familyFlag = 0;
    }

    let selection = appCtxSvc.getCtx("selectionInPersistentSummary");
    for (j = 0; j < familyInfo.length; j++) {
      let obj = {};
      obj.isFiltered = true;
      obj.familyDisplayName = familyInfo[j];
      obj.values = [];
      for (k = 0; k < systemSelectionslist.length; k++) {
        if (systemSelectionslist[k].familyDisplayName == familyInfo[j]) {
          if (
            selection[0].selections[systemSelectionslist[k].familyUid] ==
            undefined
          ) {
            obj.values.push(systemSelectionslist[k].optValue);
          } else if (
            systemSelectionslist[k].optValueStr !=
            selection[0].selections[systemSelectionslist[k].familyUid][0].nodeID
          ) {
            obj.values.push(systemSelectionslist[k].optValue);
          } else {
            data.selectionName.uiValue =
              systemSelectionslist[k].optValue.cellHeader1 +
              " " +
              systemSelectionslist[k].optValue.cellHeader2;
          }
        }
      }
      formatedlist.push(obj);
    }

    console.log("data", data);
    console.log("formatedlist", formatedlist);
  }

  data.systemSelectionMap = formatedlist;

  return data;
};

export let actioncheckfordata = function (data) {
  console.log(data);
};

export let setManualConfigurationMode = function (data) {
  let context = appCtxSvc.getCtx("variantConfigContext");
  // Identify the case when we should stay in manual mode and
  // no SOA call to fetch new content should be made
  context.stayInManualMode = true;

  // set value of the toggle mode property
  data.isGuidedMode.dbValue = false;
};

export let showUnableToSwitchToGuidedModeMessage = function (data) {
  messagingSvc.reportNotyMessage(
    data,
    data._internal.messages,
    "G4B_validationErrorOnSwitchingToGuidedMode"
  );
  if (
    appCtxSvc.getCtx("activeToolsAndInfoCommand") != undefined &&
    appCtxSvc.getCtx("activeToolsAndInfoCommand.commandId") ==
      "G4B_ImportConfiguration"
  ) {
    eventBus.publish(
      "G4B_ImportConfiguration.getViolationsFromImportedConfiguration"
    );
  }
  setManualMode();
};

let setManualMode = function () {
  let context = appCtxSvc.getCtx("variantConfigContext");
  if (context.switchingToGuidedMode) {
    // Stay in Manual mode if there are errors
    // while switching from manual to guided mode
    context.guidedMode = false;
    context.switchingToGuidedMode = false;
    eventBus.publish("fullScreenConfig.setManualConfigurationMode", {});
  }
};

export let stayInManualMode = function () {
  // setManualMode();
  eventBus.publish("fullScreenConfig_updateVariantMode", {});
};

export let showValidationErrorMessage = function (data) {
  messagingSvc.reportNotyMessage(
    data,
    data._internal.messages,
    "validationErrorOnSelection"
  );

  // Remove error family and value from context
  let context = appCtxSvc.getCtx("variantConfigContext");
  delete context.validationErrorFamily;
  delete context.validationErrorValue;
};

/**
 * This method will save relative position of the node being clicked (Y offset in pixels) which is clicked
 *
 * Configuration Panel ...PanelTop.....______________________________....................................
 * ...........................|....... |..............................|..................................
 * ...........................|....... |Group A.......................|..................................
 * ...........................|....... |...Fam1 ......................|..................................
 * ........................nodeOffset..|.....v1.......................|..................................
 * ...........................|.......
 * |.....v2.......................|..........................................
 * ....................elementoffset...|.....v3.......................|..................................
 * ................................... |..............................|..................................
 * ................................... |..............................|..................................
 *
 * PanelTop is position of Configuration panel relative to document. ElementTop is position of element clicked
 * relative to document. Diffrence between ElementTop and PanelTop is stored as nodeOffset. This nodeOffset is
 * later used to adjust the scroll ( refer setScrollPosition ) so that v3 remains at same position after data in
 * panel is refreshed
 *
 * @param {Object} Option Family Group
 * @param {family} Option Family
 * @param {Object} Option value
 *
 */
export let updateScrollInfo = function (group, family, value) {
  let context = appCtxSvc.getCtx("variantConfigContext");
  context.group = group;
  context.family = family;
  context.value = value;
  let selector = getSelector(group, family, value);
  let element = $(selector);
  let panelBody = $(
    "[panel-id='Pca0DefineCustomVariantRule'] > .aw-base-scrollPanel"
  )[0];
  if (element && panelBody) {
    let panelTop = $(panelBody).offset().top;
    let elemTop = $(element).offset().top;
    let nodeOffset = elemTop - panelTop;
    context.nodeOffset = nodeOffset;
  }
};

/**
 * Here after v3 is clicked document gets refreshed and v3 goes outside visible area configuration panel.
 * Earlier V3 was at position panelTop + nodeOffset as shown below. In order to get v3 back to the same position
 * as it was earlier we must adjust scroll by a value equal to (panelTop - elementTop) + nodeOffset
 *
 *
 * .................................. Group A.........................................................
 * .................................. ...Fam1 ........................................................
 * ........................................v1.........................................................
 * .................................. .....v2.........................................................
 * ...........................elementTop...v3.........................................................
 * .................................................................................................
 * .................................................................................................
 * ...............................................................................................................
 * Configuration Panel ........paneltop ______________________________....................................
 * ............................|...... |....V10.......................|..................................
 * ............................|...... |....V11.......................|..................................
 * ............................|...... |....V12.......................|..................................
 * ......................nodeOffset....|....V13.......................|..................................
 * ............................|......
 * |....V14.......................|.............................................
 * ....................................|....V15.......................|..................................
 * ................................... |..............................|..................................
 * ................................... |..............................|..................................
 *
 *
 *
 */
export let setScrollPosition = function () {
  let context = appCtxSvc.getCtx("variantConfigContext");

  let selector = getSelector(context.group, context.family, context.value);
  if (selector && context.nodeOffset) {
    let element = $(selector)[0];
    let panelBody = $(
      "[panel-id='Pca0DefineCustomVariantRule'] > .aw-base-scrollPanel"
    )[0];
    if (element && panelBody) {
      let panelTop = $(panelBody).offset().top;
      let elemTop = $(element).offset().top;
      let delta = context.nodeOffset + (panelTop - elemTop);

      // Currently Not using isVisible propery to adjust scroll.
      // We will adjust scroll always
      let currentScrollTop = $(panelBody).scrollTop();
      let scrollTop = currentScrollTop - delta;
      $(panelBody).scrollTop(scrollTop);
    }
  }
  // clear the scroll info
  delete context.group;
  delete context.family;
  delete context.value;
  delete context.nodeOffset;
};

export let resetGroupCompleteness = function () {
  //let groupCompleteness = {};
};

export let updateVariantMode = function (data) {
  let context = appCtxSvc.getCtx("variantConfigContext");
  if (context.guidedMode != data.isGuidedMode.dbValue) {
    if (context.isManualConfiguration === true) {
      //this means we are in manual configuration mode and we are switching to guided mode.
      context.switchingToGuidedMode = true;
      context.guidedMode = true;
      context.isManualConfiguration = false;

      delete context.violationLabel;
    } else {
      //this means we are in guided mode and we are switching to manual configuration mode.
      context.guidedMode = false;
      context.switchingToGuidedMode = false;
      context.isManualConfiguration = true;

      /*Ref GEPA-12454 : Validation not happening after Config import
            If the system automatically switches to manual after 'Config import' or 'Apply invalid SVR' means the system is swiching to standard 
            manual mode and not pinned mode*/

      context.isStandardManualMode = true;
    }
  } else if (
    data.eventMap != undefined &&
    data.eventMap.fullScreenConfig_updateVariantMode != undefined &&
    data.eventMap.fullScreenConfig_updateVariantMode.responseInfo !=
      undefined &&
    data.eventMap.fullScreenConfig_updateVariantMode.responseInfo.isValid[0] ==
      "false" &&
    context.selections.length != 0
  ) {
    context.guidedMode = false;
    context.switchingToGuidedMode = false;
    context.isManualConfiguration = true;
    data.isGuidedMode.dbValue = false;

    //Ref GEPA-12454 : Validation not happening after Config import
    context.isStandardManualMode = true;

    appCtxSvc.updateCtx("variantConfigContext", context);
    appCtxSvc.updateCtx("storeContext", context);
    //If the cfsc is switched to manual mode we need to update the cfsc UI to as per pinned mode usecase
    eventBus.publish("G4B_CFG_VariantDataView.callselectValue");

    return;
  }

  appCtxSvc.updateCtx("variantConfigContext", context);
  appCtxSvc.updateCtx("storeContext", context);

  if (context.isManualConfiguration === true) {
    //We do not want to reload the data when user switches from guided to manual mode
    manualModeSwitched(context);
  }

  let productContextInfo = appCtxSvc.getCtx(
    "aceActiveContext.context.productContextInfo"
  );

  if (
    productContextInfo.props.awb0VariantRules.uiValues[0] !=
      "Configuration Based On Selection(s)" &&
    context.guidedMode == true
  ) {
    eventBus.publish("G4B_CFG_VariantDataView.callselectValue");
    //eventBus.publish("callLoadVariantData");
  }
  /* //For Workaround: GEPA-6730
	if(context.switchingToGuidedMode == true && appCtxSvc.ctx.importConfigurationCtx != undefined){
		appCtxSvc.ctx.importConfigurationCtx.userTriedGuidedModeSwitch = 'true';
	} */
};

/* //For Workaround: GEPA-6730
export let importConfigurationWorkaroundFunc = function(response){
	let importConfigurationCtx = appCtxSvc.ctx.importConfigurationCtx;
	if(importConfigurationCtx != undefined && response.responseInfo.isValid[0] == 'true'){
		if(importConfigurationCtx.isImportedConfiguration == 'true' && importConfigurationCtx.userTriedGuidedModeSwitch == 'true'){
			appCtxSvc.ctx.importConfigurationCtx.isImportedConfigurationFixed = 'true';
		}
	}
}
//// */

export let doChecksBeforeApplyConfiguration = function (ctx) {
  /*let importConfigCtx = ctx.importConfigurationCtx;
	let variantConfigCtx = ctx.variantConfigContext;
	if(variantConfigCtx.guidedMode == true && importConfigCtx != undefined && importConfigCtx.importedVariantRuleUid != undefined && importConfigCtx.isImportedConfigurationFixed == 'true')
	{
		eventBus.publish('G4B_importConfigurationUpdateCreateVariantRule');
	}
	else
	{
		eventBus.publish('G4B_callConfigure');
	}*/
  eventBus.publish("G4B_callConfigure");
};
export let adjustConfiguratorTab = function () {
  document
    .getElementById("adjustConfiguratorTab")
    .classList.remove("full-screen-outerDiv");
  document
    .getElementById("adjustConfiguratorTab")
    .classList.add("new-full-screen-outerDiv");
};

export let getSelectionForVariantContext = function () {
  if (appCtxSvc.getCtx("selected.type") == "VariantRule") {
    return appCtxSvc.getCtx("pselected");
  } else {
    let multiSelections = appCtxSvc.getCtx("mselected");
    if (multiSelections && multiSelections.length > 1) {
      // return last selected object in case of multiple selections
      return multiSelections[multiSelections.length - 1];
    } else {
      if (appCtxSvc.getCtx("selected.type") == "Fgf0ApplicationModel") {
        return appCtxSvc.getCtx("selected");
      } else {
        return multiSelections[multiSelections.length - 1];
      }
    }
  }
};

/**
 * This API returns current variant mode i.e. Guide/Manual
 *
 * @returns {String} variantMode - Returns the current variant mode i.e. Guide/Manual
 */
export let getConfigurationMode = function () {
  let variantMode = "guided";
  let context = appCtxSvc.getCtx("variantConfigContext");
  if (!context.guidedMode) {
    variantMode = "manual";
  }
  context.configurationControlMode = variantMode;
  return variantMode;
};
export let allVariantAction = function (data) {
  data.tabModels[0].selectedTab = true;
  data.tabModels[1].selectedTab = false;
};

export let ComparisonResultAction = function (data) {
  data.tabModels[1].selectedTab = true;
  data.tabModels[0].selectedTab = false;
};

/**
 * This API returns current variant mode i.e. Guide/Projected
 *
 * @returns {String} variantMode - Returns the current variant mode i.e. Guide/Manual
 */
export let getConfigurationMode2 = function () {
  let variantMode = "guided";
  let context = appCtxSvc.getCtx("variantConfigContext");
  if (!context.guidedMode) {
    variantMode = "projected";
  }
  //store the variant mode in ctx
  context.configurationControlMode = variantMode;
  return variantMode;
};

export let getIgnoreConstraints = function () {
  let ignoreConstraints = "false";
  let context = appCtxSvc.getCtx("variantConfigContext");
  if (!context.guidedMode) {
    ignoreConstraints = "true";
  }
  return ignoreConstraints;
};

/**
 * Post processing when user switches from guided to manual mode
 */
function manualModeSwitched(context) {
  if (summaryGroup !== null && context.currentGroup === summaryGroup.optGroup) {
    //In case of Summary group we want to fetch the data from server
    eventBus.publish("G4B_CFG_VariantData.contentLoaded", {});
  }
  /* else {
                    removeSystemSelectionsFromCurrentGroup(context);
                    // Fire event to update the value selection icon from radio button to check mark 
                    // eventBus.publish( "awCustomVariantPanel.updateValueSelectionIcon", {} );
                }*/
  if (context.violationLabel == undefined) {
    eventBus.publish("G4B_modeChangedToUnguided", {});
  }
}

export let configuredBasedOnSelectionAction = function (data) {
  let context = appCtxSvc.getCtx("variantConfigContext");
  context.guidedMode = false;
  appCtxSvc.updateCtx("variantConfigContext", context);
  appCtxSvc.updateCtx("storeContext", context);
  data.isGuidedMode.dbValue = false;
  //    export let updateVariantMode(data);
  //    eventBus.publish("callLoadVariantData");
};

/**
 * This function adds  boolean property 'isSystemSelection' to value object.
 * sets it to 'true' if value has selectionState !=0 and is not a part of userSelectionMap
 *
 */
function getSystemSelectionsFromCurrentGroup() {
  let context = appCtxSvc.getCtx("variantConfigContext");

  //iterate over the groups
  for (let i = 0; i < optionGroups.length; i++) {
    if (optionGroups[i].optGroup === context.currentGroup) {
      let group = optionGroups[i];
      //Itearate over the families of currently expanded group
      for (let j = 0; j < group.families.length; j++) {
        let family = group.families[j];
        //Iterate over the values of currently expanded group
        for (let k = 0; k < family.values.length; k++) {
          let value = family.values[k];

          /**
                     *  if value is a system selection add this property
                     
                    if (!isValueAvailableInUserSelectionMap(context, family, value)) {
                        if (value.selectionState != 0) {
                            Object.defineProperty(family.values[k], 'isSystemSelection', {
                                value: true,
                                writable: false
                            });

                        }
                    }

                    */
          if (
            value.selectionState != 0 &&
            value.selectionState != 1 &&
            value.selectionState != 2
          ) {
            Object.defineProperty(family.values[k], "isSystemSelection", {
              value: true,
              writable: false,
            });
          } else {
            Object.defineProperty(family.values[k], "isSystemSelection", {
              value: false,
              writable: false,
            });
          }
        }
      }
    }
  }
}

/**
 * This API returns initialVariantRule only when custom variant panel is being initialized
 */
export let getInitialVariantRule = function () {
  let context = appCtxSvc.getCtx("variantConfigContext");
  if (context.customVariantPanelInitializing) {
    delete context.customVariantPanelInitializing;
    return context.initialVariantRule;
  }
  return null;
};

//Build summary group to show selected family values
export let buildSummary = function (response) {
   let summaryGroup = {};
  if (response.selectionsSummary.summaryOfSelections.length > 0) {
    summaryGroup.optGroup = response.selectionsSummary.internalName;
    summaryGroup.groupDisplayName = response.selectionsSummary.displayName;
    summaryGroup.complete = true;

    //Ensure we don't compare empty string with summary group name -
    // In case of invalid configuration, server doesn't populate any group information in SOA response
    if (
      response.currentGroup !== "" &&
      response.currentGroup === response.selectionsSummary.internalName
    ) {
      summaryGroup.expand = true;
      // Update application context
      let context = appCtxSvc.getCtx("variantConfigContext");
      context.currentGroup = response.currentGroup;
      context.configPerspective = response.configPerspective;
      appCtxSvc.updateCtx("variantConfigContext", context);
      appCtxSvc.updateCtx("storeContext", context);
    } else {
      summaryGroup.expand = false;
    }


    return summaryGroup;
  }
};

export let setSavedVariantRule = function (data) {
  //copying savedSummary into userSummary
  data.userSummary = [];
  data.userSummary = _.cloneDeep(data.savedSummary);
  checkForPackageView(data.userSummary);

  // Handle Delayed content solve scenario
  if (data.isAssignedAndApplied.dbValue == false) {
    appCtxSvc.updatePartialCtx("aceActiveContext.context.requestPref", {
      updateConfigurationOnly: appCtxSvc.getCtx(
        "aceActiveContext.context.rootElement.props.awb0UnderlyingObject.dbValues[0]"
      ),
    });
  }
  //Apply saved variant rule
  let selected = appCtxSvc.getCtx("selected");
  let importConfigCtx = appCtxSvc.getCtx("importConfigurationCtx");
  if (selected.type == "VariantRule") {
    let context = appCtxSvc.getCtx("variantConfigContext");
    context.customVariantRule = selected.uid;

    appCtxSvc.updateCtx("variantConfigContext", context);
    appCtxSvc.updateCtx("storeContext", context);
    /*let eventData = {
            variantRule: selected.uid
        }*/

    //Clear selection from SVR panel after svr is applied
    if (appCtxSvc.getCtx("viewButtonGroup") == "saved") {
      let header = document.getElementById("savedVariantRule");
      let rows = header.getElementsByClassName("svrRowClass");
      for (let pnx = 0; pnx < rows.length; pnx++) {
        rows[pnx].className = "svrRowClass";
      }
      if (appCtxSvc.getCtx("svrSelected") != undefined) {
        appCtxSvc.unRegisterCtx("svrSelected");
      }
      appCtxSvc.updateCtx("selected", appCtxSvc.getCtx("pselected"));
    }
    //avoid PWA refresh in assign mode for Gepard group (For Kova pwa refresh is required)
    if (
      appCtxSvc.getCtx("occmgmtContext.rootElement.type") ==
        "Fgd0DesignSubsetElement" &&
      data.isAssignedAndApplied.dbValue == false &&
      appCtxSvc
        .getCtx("userSession.props.group.uiValues[0]")
        .indexOf("GEPARD.MUNICH.BMW") > -1
    ) {
      saveApplyUpdatedConfiguration(data, false);
    } else {
      eventBus.publish("fullScreenConfig.handleCustomVariantInfoChange");
    }
  }
  if (
    importConfigCtx != undefined &&
    importConfigCtx.importedVariantRuleUid != undefined &&
    importConfigCtx.isImportedConfigurationFixed == "true"
  ) {
    let context1 = appCtxSvc.getCtx("variantConfigContext");
    context1.customVariantRule = importConfigCtx.importedVariantRuleUid;

    appCtxSvc.updateCtx("variantConfigContext", context1);
    appCtxSvc.updateCtx("storeContext", context1);
    let eventData1 = {
      variantRule: importConfigCtx.importedVariantRuleUid,
    };
    eventBus.publish(
      "fullScreenConfig.configureWithSavedVariantRule",
      eventData1
    );
  }
};

export let formatSelectionsForClearSelections = function (data) {
  let i,
    selections,
    tempSummarySelectionList,
    newSelections,
    variantConfigContext;
  let userSession = appCtxSvc.getCtx("userSession");
  let userGroup = userSession.props.group_name.dbValue;

  let context = appCtxSvc.getCtx("variantConfigContext");
  //GEPA-27939 - All Incomplete families not showing correctly after Reset configuration in CFSC
  if (context.requiredFamiliesData) {
    delete context.allFamilyListInSeries;
    delete context.multiFeatures;
    delete context.currentRequiredFamily;
    delete context.requiredFamiliesData;
  }
  if (context.resetConfigurationStart == undefined) {
    context.resetConfigurationStart = true;
  }
  //clear pinned selections except time-slice
  if (context.pinnedSelections) {
    delete context.pinnedSelections[0].selections;
    let currentTimeSliceSelection = appCtxSvc.getCtx(
      "g4b_ImportConfigurationPanelCtx[0].TimeSliceOptionSelection[0].selections"
    );
    context.pinnedSelections[0].selections = _.clone(currentTimeSliceSelection);
  }

  //Clear existing expanded selections
  if (context.manualModeExpandedSelections) {
    delete context.manualModeExpandedSelections;
  }

  //Clear existing expanded flag
  if (context.isStandardManualMode) {
    delete context.isStandardManualMode;
  }

  //clear user selectioncache
  if (context.g4bUserSelectionCache) {
    delete context.g4bUserSelectionCache;
  }

  //clear user configValidation status
  if (context.configValidation) {
    delete context.configValidation;
  }

  //Clear selection from SVR panel after Clear coniguration is initiated
  if (appCtxSvc.getCtx("viewButtonGroup") == "saved") {
    let header = document.getElementById("savedVariantRule");
    let rows = header.getElementsByClassName("svrRowClass");
    for (let pnx = 0; pnx < rows.length; pnx++) {
      rows[pnx].className = "svrRowClass";
    }
    if (appCtxSvc.getCtx("svrSelected") != undefined) {
      appCtxSvc.unRegisterCtx("svrSelected");
    }
    appCtxSvc.updateCtx("selected", appCtxSvc.getCtx("pselected"));
  }

  if (appCtxSvc.getCtx("variantConfigContext.userSummaryBackup ")) {
    appCtxSvc.updateCtx("variantConfigContext.userSummaryBackup", []);
  }

  if (userGroup == "TeilaufbautenKonfiguration") {
    selections = appCtxSvc.getCtx("variantConfigContext.selections");
    tempSummarySelectionList = data.userSummary;
    newSelections = JSON.parse(JSON.stringify(selections));
    newSelections[0].selections = {};
    for (i = 0; i < tempSummarySelectionList.length; i++) {
      if (
        tempSummarySelectionList[i].groupDisplayName == "Time-Slice" ||
        tempSummarySelectionList[i].groupDisplayName == "CFG" ||
        tempSummarySelectionList[i].groupDisplayName == "Products" ||
        tempSummarySelectionList[i].groupDisplayName == "Produkte" ||
        tempSummarySelectionList[i].groupDisplayName == "ATT" ||
        tempSummarySelectionList[i].groupDisplayName == "EBR" ||
        tempSummarySelectionList[i].groupDisplayName == "MBR"
      ) {
        if (selections[0].selections[tempSummarySelectionList[i].familyUid]) {
          newSelections[0].selections[tempSummarySelectionList[i].familyUid] =
            selections[0].selections[tempSummarySelectionList[i].familyUid];
        }
      }
    }
    // console.log(newSelections);
    variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
    variantConfigContext.selections = newSelections;
    // appCtxSvc.updateCtx('variantConfigContext', variantConfigContext);
  } else {
    selections = appCtxSvc.getCtx("variantConfigContext.selections");
    tempSummarySelectionList = data.userSummary;
    newSelections = JSON.parse(JSON.stringify(selections));
    newSelections[0].selections = {};
    for (i = 0; i < tempSummarySelectionList.length; i++) {
      if (
        tempSummarySelectionList[i].groupDisplayName == "Time-Slice" ||
        tempSummarySelectionList[i].groupDisplayName == "CFG"
      ) {
        if (selections[0].selections[tempSummarySelectionList[i].familyUid]) {
          newSelections[0].selections[tempSummarySelectionList[i].familyUid] =
            selections[0].selections[tempSummarySelectionList[i].familyUid];
        }
      }
    }
    // console.log(newSelections);
    variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
    variantConfigContext.selections = newSelections;

    //GEPA-7722 After "Re-set config": validation-error-display is not re-set
    variantConfigContext.payloadStrings = {};
    delete variantConfigContext.violationLabel;
    //end: GEPA-7722

    appCtxSvc.updateCtx("variantConfigContext", variantConfigContext);
  }
  //data.storedContext = variantConfigContext;
};

export let resetModeForclearSelections = function (response) {
  let context = appCtxSvc.getCtx("variantConfigContext");
  // context.guidedMode=true;
  // context.switchingToGuidedMode=true;
  context.selections = response.selections;
  appCtxSvc.updateCtx("variantConfigContext", context);
  appCtxSvc.updateCtx("storeContext", context);
  // eventBus.publish("clearVariantConfigCFSC");
};

export let setNoVariantRule = function (data) {
  //
  let userSession = appCtxSvc.getCtx("userSession");
  let userGroup = userSession.props.group_name.dbValue;
  let eventData = {
    variantRule: [],
  };
  let context = appCtxSvc.getCtx("variantConfigContext");
  // let variantRuleToSet= appCtxSvc.getCtx('revisionRuleToSet');
  if (userGroup == "TeilaufbautenKonfiguration") {
    // context.customVariantRule=variantRuleToSet;

    eventBus.publish("G4B_callConfigure");
    return;
  } else {
    data.isGuidedMode.dbValue = true;
    context.customVariantRule = null;
    eventData.variantRule = [];
  }

  appCtxSvc.updateCtx("variantConfigContext", context);
  appCtxSvc.updateCtx("storeContext", context);

  eventBus.publish("fullScreenConfig.handleCustomVariantInfoChange", eventData);
  data.saveButtonId.dbValue = "type";

  try {
    let header = document.getElementById("button-group");
    let btns = header.getElementsByClassName("g4b-cfsc-btn");
    let current = document.getElementsByClassName("active");
    current[0].className = current[0].className.replace(" active", "");
    btns[0].className += " active";
  } catch (ex) {
    console.log(ex);
  }

  eventBus.publish("populateConfigView");
};
/**
 * The function below displays user selections in unguided mode
 */
export let userSelectionForUnguidedMode = function (data) {
  let i, j, allUids;
  let response = data.eventData;

  let userSelections = JSON.parse(JSON.stringify(response.selections));
  userSelections[0].selections = {};

  for (let node in response.selections[0].selections) {
    for (i = 0; i < response.selections[0].selections[node].length; i++) {
      let arr = response.selections[0].selections[node][i].nodeID.split(":"); //adding this statement to remove all nodeids without feature id in it
      if (arr[1] != "") {
        if (
          response.selections[0].selections[node][i].selectionState == 1 ||
          response.selections[0].selections[node][i].selectionState == 2
        ) {
          if (userSelections[0].selections[node]) {
            userSelections[0].selections[node].push(
              response.selections[0].selections[node][i]
            );
          } else {
            userSelections[0].selections[node] = [
              response.selections[0].selections[node][i],
            ];
          }
        }
      }
    }
  }

  let userSelectionsfamily =
    userSelections.length > 0 ? Object.keys(userSelections[0].selections) : [];
  if (userSelectionsfamily.length == 0) {
    //reset persistent summary if no selection is made
    data.persistentselections = [];
    return;
  }
  let userSelectionsoptionValue =
    userSelections.length > 0
      ? Object.keys(userSelections[0].selections).map(function (e) {
          return userSelections[0].selections[e];
        })
      : [];
  let groupsInContext = appCtxSvc.getCtx("groupsInContext");
  let tempSummarySelectionList = appCtxSvc.getCtx("tempSummarySelectionList");
  if (tempSummarySelectionList == undefined) {
    appCtxSvc.registerCtx("tempSummarySelectionList", []);
    tempSummarySelectionList = appCtxSvc.getCtx("tempSummarySelectionList");
  }
  let selections = [];
  //let selection = {};
  //let families = [];
  let selectedValues = [];
  for (i = 0; i < userSelectionsoptionValue.length; i++) {
    let optionValue = userSelectionsoptionValue[i];
    for (j = 0; j < optionValue.length; j++) {
      selections.push(optionValue[j]);
    }
  }

  for (i = 0; i < selections.length; i++) {
    let array = selections[i].nodeID.split(":");

    let valueData = {
      familyUid: array[0],
      valueUid: array[1],
      valueSelectionState: selections[i].selectionState,
    };
    selectedValues.push(valueData);
  }

  let listOfAllUids = [];
  let listOfFamilies = [];
  let listOfOptionValues = [];
  //let finalPersistentSelectionList = [];

  for (i = 0; i < userSelectionsfamily.length; i++) {
    allUids = {};
    (allUids.uid = userSelectionsfamily[i]),
      (allUids.type = ""),
      listOfAllUids.push(allUids);
  }
  for (i = 0; i < selectedValues.length; i++) {
    allUids = {};
    (allUids.uid = selectedValues[i].valueUid), (allUids.type = "");
    listOfAllUids.push(allUids);
  }

  let inputData = {
    objects: listOfAllUids,
    attributes: [
      "object_name",
      "object_desc",
      "object_type",
      "cfg0FamilyGroups",
    ],
  };
  soaSvc
    .post("Internal-Core-2007-12-Session", "getProperties", inputData)
    .then(function (_response) {
      let i, j;
      //  if (_response.plain.length == listOfAllUids.length) {
      for (j = 0; j < userSelectionsfamily.length; j++) {
        let object = {};

        if (_response.modelObjects[userSelectionsfamily[j]] != undefined) {
          object.familyUid = userSelectionsfamily[j];
          object.familyName =
            _response.modelObjects[
              userSelectionsfamily[j]
            ].props.object_name.dbValues[0];
          object.familyDesc =
            _response.modelObjects[
              userSelectionsfamily[j]
            ].props.object_desc.dbValues[0];

          if (
            _response.modelObjects[userSelectionsfamily[j]].props
              .cfg0FamilyGroups.uiValues.length > 0
          ) {
            let group_name =
              _response.modelObjects[
                userSelectionsfamily[j]
              ].props.cfg0FamilyGroups.uiValues[0].split("/");
            object.groupName = group_name[0];
          } else if (
            _response.modelObjects[userSelectionsfamily[j]].props.object_name
              .dbValues[0] == "TYP"
          ) {
            object.groupName =
              _response.modelObjects[
                userSelectionsfamily[j]
              ].props.object_name.dbValues[0];
          }
          console.log("object.groupName", object.groupName);
          listOfFamilies.push(object);
        }
      }

      for (i = 0; i < selectedValues.length; i++) {
        let valueData = selectedValues[i];
        valueData.name =
          _response.modelObjects[
            selectedValues[i].valueUid
          ].props.object_name.dbValues[0];
        valueData.desc =
          _response.modelObjects[
            selectedValues[i].valueUid
          ].props.object_desc.dbValues[0];
        listOfOptionValues.push(valueData);
      }

      console.log("families", listOfFamilies);

      for (j = 0; j < listOfFamilies.length; j++) {
        for (i = 0; i < listOfOptionValues.length; i++) {
          if (listOfFamilies[j].familyUid == listOfOptionValues[i].familyUid) {
            listOfOptionValues[i].familyName = listOfFamilies[j].familyName;
            listOfOptionValues[i].familyDesc = listOfFamilies[j].familyDesc;
            if (listOfFamilies[j].groupName) {
              if (listOfFamilies[j].groupName == "TYP") {
                listOfOptionValues[i].groupDisplayName =
                  listOfFamilies[j].groupName;
              } else {
                listOfOptionValues[i].groupDisplayName = listOfFamilies[
                  j
                ].groupName.slice(0, -3);
              }
            }

            listOfOptionValues[i].groupName = listOfFamilies[j].groupName;
            if (groupsInContext != undefined) {
              for (let k = 0; k < groupsInContext.length; k++) {
                let groupDisplayName = groupsInContext[k].groupDisplayName;
                let _groupDisplayName = [];

                if (groupDisplayName != "TYP") {
                  _groupDisplayName = groupDisplayName.split("_");
                }

                if (
                  (listOfOptionValues[i].groupDisplayName == groupDisplayName &&
                    listOfOptionValues[i].groupDisplayName == "TYP") ||
                  (listOfOptionValues[i].groupDisplayName ==
                    _groupDisplayName[0] &&
                    listOfOptionValues[i].groupDisplayName == "ATT")
                ) {
                  listOfOptionValues[i].groupName =
                    groupsInContext[k].group_DisplayName;
                }
              }
            }
          }
        }
      }

      listOfOptionValues = rearrangeSummary(listOfOptionValues);
      data.persistentselections = listOfOptionValues;
      appCtxSvc.updateCtx("tempSummarySelectionList", listOfOptionValues);
    });
  // eventBus.publish("G4B_CFG_VariantDataView.callselectValue");
};

export let getUserSelectionsForSavedVariant = function (response) {
  let userSelectionsSummary = [];
  let savedSummary = [];
  if (
    response &&
    response.responseInfo &&
    response.responseInfo.summaryOfSelections
  ) {
    let summaryOfSelections = JSON.parse(
      response.responseInfo.summaryOfSelections[0]
    );
    for (let i = 0; i < summaryOfSelections.summaryOfSelections.length; i++) {
      let userSelection = {
        familyUID: summaryOfSelections.summaryOfSelections[i].familyUID,
        familyDisplayName:
          summaryOfSelections.summaryOfSelections[i].familyDisplayName,
        familyDesc:
          summaryOfSelections.summaryOfSelections[i].familyDisplayName,

        nodeID:
          summaryOfSelections.summaryOfSelections[i].familyUID +
          ":" +
          summaryOfSelections.summaryOfSelections[i].featureUID,
        featureDisplayName:
          summaryOfSelections.summaryOfSelections[i].featureDisplayName,

        selectionState:
          summaryOfSelections.summaryOfSelections[i].selectionState,

        groupUID: summaryOfSelections.summaryOfSelections[i].groupUID,
        groupDisplayName:
          summaryOfSelections.summaryOfSelections[i].groupDisplayName,
      };

      if (
        summaryOfSelections.summaryOfSelections[i].featureUID != "" &&
        viewModelObjSvc.createViewModelObject(
          summaryOfSelections.summaryOfSelections[i].featureUID
        )
      ) {
        userSelection.vmo = viewModelObjSvc.createViewModelObject(
          summaryOfSelections.summaryOfSelections[i].featureUID
        );
        userSelection.featureDescription = userSelection.vmo.cellHeader2;
      }

      if (summaryOfSelections.summaryOfSelections[i].featureUID != "") {
        userSelectionsSummary.push(userSelection);
      }
    }
    //re-ordering selections to show TS option at first position
    let tsIndex;
    for (let i = 0; i < userSelectionsSummary.length; i++) {
      if (userSelectionsSummary[i].groupDisplayName.indexOf("CFG_") != -1) {
        tsIndex = i;
        break;
      }
    }
    let tsElement = userSelectionsSummary[tsIndex];

    userSelectionsSummary.splice(tsIndex, 1);
    userSelectionsSummary.unshift(tsElement);

    let userSelections = userSelectionsSummary;

    for (let i = 0; i < userSelections.length; i++) {
      let selectionSummary = {
        desc: userSelections[i].featureDescription,
        name: userSelections[i].featureDisplayName,
        valueSelectionState: userSelections[i].selectionState,
        nodeID: userSelections[i].nodeID,
        familyDesc: userSelections[i].familyDesc,
        familyName: userSelections[i].familyDisplayName,
        familyUid: userSelections[i].familyUID,
        groupDisplayName: userSelections[i].groupDisplayName,
      };

      if (
        selectionSummary.groupDisplayName != "Products" &&
        selectionSummary.groupDisplayName != "Produkte"
      ) {
        selectionSummary.groupDisplayName =
          selectionSummary.groupDisplayName.split("_")[0];
      }
      savedSummary.push(selectionSummary);
    }
  }
  savedSummary = rearrangeSummary(savedSummary);
  savedSummary = removeDuplicates(savedSummary);

  if (appCtxSvc.getCtx("mselected[0].type") === "VariantRule") {
    appCtxSvc.registerCtx("currentRuleSelections", response.selections);
  }

  if (appCtxSvc.getCtx("variantConfigContext.guidedMode") == false) {
    eventBus.publish(
      "G4B_CFG_VariantData.updateUserSelectionSummary",
      savedSummary
    );
  }

  return savedSummary;
};

export let updateUserSelectionSummary = function (data) {
  let userSelections = null;
  let userSummary = null;
  if (!_.isUndefined(data.userSummary)) {
    userSummary = _.cloneDeep(data.userSummary);
  } else {
    userSummary = [];
  }

  if (!_.isUndefined(data.eventData)) {
    userSelections = data.eventData;
  }
  let context = appCtxSvc.getCtx("variantConfigContext");
  let selectionsinContext = context.selections[0].selections;

  if (selectionsinContext != undefined) {
    for (let i = 0; i < userSelections.length; i++) {
      let flag = 0;
      for (let idx in selectionsinContext) {
        for (let j = 0; j < selectionsinContext[idx].length; j++) {
          if (
            selectionsinContext[idx][j].nodeID == userSelections[i].nodeID &&
            selectionsinContext[idx][j].selectionState !=
              userSelections[i].selectionState
          ) {
            if (userSelections[i].selectionState != 0) {
              flag = 2;
              break;
            }
          } else if (
            selectionsinContext[idx][j].nodeID == userSelections[i].nodeID &&
            selectionsinContext[idx][j].selectionState ==
              userSelections[i].selectionState
          ) {
            flag = 1;
            break;
          }
        }
        if (flag == 2 || flag == 1) {
          break;
        }
      }
      switch (flag) {
        /*  case 0:
                     for (let k = 0; k < userSummary.length; k++) {
                         if (userSummary[k].nodeID == userSelections[i].nodeID) {
                             userSummary.splice(k, 1);
                         }
                     }
 
                     break; */

        case 1:
          let selectionSummary = {
            desc: userSelections[i].featureDescription,
            name: userSelections[i].featureDisplayName,
            valueSelectionState: userSelections[i].selectionState,
            nodeID: userSelections[i].nodeID,
            familyDesc: userSelections[i].familyDesc,
            familyName: userSelections[i].familyDisplayName,
            familyUid: userSelections[i].familyUID,
            groupDisplayName: userSelections[i].groupDisplayName,
          };

          if (
            selectionSummary.groupDisplayName != "Products" &&
            selectionSummary.groupDisplayName != "Produkte"
          ) {
            selectionSummary.groupDisplayName =
              selectionSummary.groupDisplayName.split("_")[0];
          }
          userSummary.push(selectionSummary);
          break;

        case 2:
          for (let k = 0; k < userSummary.length; k++) {
            if (userSummary[k].nodeID == userSelections[i].nodeID) {
              userSummary[k].selectionState = userSelections[i].selectionState;
            }
          }

          break;
      }
    }
    /**
     * Removing non existent selections from summary
     */
    for (let i = 0; i < userSummary.length; i++) {
      let flag = 0;
      for (let idx in selectionsinContext) {
        for (let j = 0; j < selectionsinContext[idx].length; j++) {
          if (
            selectionsinContext[idx][j].nodeID == userSummary[i].nodeID &&
            selectionsinContext[idx][j].selectionState ==
              userSummary[i].valueSelectionState
          ) {
            flag = 1;
            break;
          }
        }
      }
      if (flag == 0) {
        userSummary.splice(i, 1);
        i--;
      }
    }
  } else {
    for (let i = 0; i < userSelections.length; i++) {
      let selectionSummary = {
        desc: userSelections[i].featureDescription,
        name: userSelections[i].featureDisplayName,
        valueSelectionState: userSelections[i].selectionState,
        nodeID: userSelections[i].nodeID,
        familyDesc: userSelections[i].familyDesc,
        familyName: userSelections[i].familyDisplayName,
        familyUid: userSelections[i].familyUID,
        groupDisplayName: userSelections[i].groupDisplayName,
      };

      if (
        selectionSummary.groupDisplayName != "Products" &&
        selectionSummary.groupDisplayName != "Produkte"
      ) {
        selectionSummary.groupDisplayName =
          selectionSummary.groupDisplayName.split("_")[0];
      }
      userSummary.push(selectionSummary);
    }
  }

  userSummary = rearrangeSummary(userSummary);
  userSummary = removeDuplicates(userSummary);
  checkForPackageView(userSummary);

  context.userSummaryBackup = userSummary;
  data.userSummary = userSummary;
  appCtxSvc.updateCtx("variantConfigContext", context);
};

let removeDuplicates = function (userSummary) {
  let _userSummary = [];
  let flag = 0;
  for (let i = 0; i < userSummary.length; i++) {
    if (_userSummary.length == 0) {
      _userSummary.push(userSummary[i]);
    } else {
      flag = 0;
      for (let j = 0; j < _userSummary.length; j++) {
        if (userSummary[i].nodeID == _userSummary[j].nodeID) {
          flag = 1;
          break;
        }
      }
      if (flag == 0) {
        _userSummary.push(userSummary[i]);
      }
    }
  }

  return _userSummary;
};

let rearrangeSummary = function (listOfOptionValues) {
  let arr = [];
  let includes = [];
  let excludes = [];
  let listOfValues = [];

  for (let i = 0; i < listOfOptionValues.length; i++) {
    if (
      listOfOptionValues[i].groupDisplayName == "CFG" ||
      listOfOptionValues[i].groupDisplayName == "TYP" ||
      listOfOptionValues[i].groupDisplayName == "Products" ||
      listOfOptionValues[i].groupDisplayName == "Produkte"
    ) {
      arr.push(listOfOptionValues[i]);
    }
  }

  for (let i = 0; i < listOfOptionValues.length; i++) {
    if (
      listOfOptionValues[i].groupDisplayName != "CFG" &&
      listOfOptionValues[i].groupDisplayName != "TYP" &&
      listOfOptionValues[i].groupDisplayName != "Products" &&
      listOfOptionValues[i].groupDisplayName != "Produkte"
    ) {
      if (
        listOfOptionValues[i].valueSelectionState == 1 ||
        listOfOptionValues[i].valueSelectionState == 5 ||
        listOfOptionValues[i].valueSelectionState == 9
      ) {
        includes.push(listOfOptionValues[i]);
      } else if (
        listOfOptionValues[i].valueSelectionState == 2 ||
        listOfOptionValues[i].valueSelectionState == 6 ||
        listOfOptionValues[i].valueSelectionState == 10
      ) {
        excludes.push(listOfOptionValues[i]);
      }
    }
  }
  includes = sortOptionValues(includes);
  excludes = sortOptionValues(excludes);

  for (let i = 0; i < arr.length; i++) {
    if (arr[i].groupDisplayName == "CFG") {
      listOfValues[0] = arr[i];
      arr.splice(i, 1);
      break;
    }
  }
  listOfValues.push(...arr);

  listOfValues.push(...includes);
  listOfValues.push(...excludes);

  return listOfValues;
};

let sortOptionValues = function (array) {
  let nameArr = [];
  let sortedArray = [];

  for (let i = 0; i < array.length; i++) {
    if (array[i].groupDisplayName == "ATT") {
      nameArr.push(array[i].familyDesc);
    } else {
      nameArr.push(array[i].name);
    }
  }
  nameArr.sort();
  for (let i = 0; i < nameArr.length; i++) {
    for (let j = 0; j < array.length; j++) {
      if (array[j].groupDisplayName != "ATT") {
        if (nameArr[i] == array[j].name) {
          sortedArray.push(array[j]);
        }
      } else {
        if (nameArr[i] == array[j].familyDesc) {
          sortedArray.push(array[j]);
        }
      }
    }
  }
  return sortedArray;
};

export let clearUserSummaryforImport = function (data) {
  if (!_.isUndefined(data.userSummary)) {
    data.userSummary = [];
  }

  eventBus.publish(
    "G4B_CFG_VariantData.updateUserSelectionSummary",
    appCtxSvc.getCtx("responseInfoSummary")
  );
};

export let clearUserSummary = function (data) {
  let tsOption = [];
  if (!_.isUndefined(data.userSummary)) {
    let userSession = appCtxSvc.getCtx("userSession");
    let userGroup = userSession.props.group_name.dbValue;

    if (userGroup == "TeilaufbautenKonfiguration") {
      tsOption.push(data.userSummary[0]);
      for (let i = 0; i < data.userSummary.length; i++) {
        if (
          data.userSummary[i].groupDisplayName == "Products" ||
          data.userSummary[i].groupDisplayName == "Produkte"
        ) {
          tsOption.push(data.userSummary[i]);
          appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", true);
        }
      }
    } else {
      tsOption.push(data.userSummary[0]);
    }

    data.userSummary = [];
    data.userSummary = tsOption;
  }
};
export let updateVariantConfigContext = function (response) {
  let allgroups = [];
  let persistentSummary = [];
  if (
    response.selections.length == 0 ||
    (response.selections[0].selections == undefined &&
      response.scopes[0] != "Package Group")
  ) {
    //Edit: Fix issue with package display
    persistentSummary = [];
    checkForPackageView(persistentSummary);
    return persistentSummary;
  }
  let promise = createPersistentSummary(response, allgroups);
  $.when(promise).done(function (persistentSummary) {
    console.log("persistentSummary", persistentSummary);
    let tempSummarySelectionList = appCtxSvc.getCtx("tempSummarySelectionList");
    if (tempSummarySelectionList == undefined) {
      appCtxSvc.registerCtx("tempSummarySelectionList", []);
      tempSummarySelectionList = appCtxSvc.getCtx("tempSummarySelectionList");
    }

    tempSummarySelectionList = persistentSummary;
    appCtxSvc.updateCtx("tempSummarySelectionList", tempSummarySelectionList);

    let context = appCtxSvc.getCtx("variantConfigContext"); //Update variantConfigContext with missing values
    //let selected = appCtxSvc.getCtx("selected");
    // if (response.ServiceData.modelObjects != undefined) {
    //     context.customVariantRule = response.ServiceData.modelObjects[response.plain];
    // }

    appCtxSvc.updateCtx("variantConfigContext", context);

    return persistentSummary;
  });

  return persistentSummary;
};

let checkForPackageView = function (persistentSummary) {
  let flag = 0;
  let context = appCtxSvc.getCtx("variantConfigContext");
  if (persistentSummary.length > 0) {
    for (let i = 0; i < persistentSummary.length; i++) {
      if (
        persistentSummary[i].groupDisplayName == "Products" ||
        persistentSummary[i].groupDisplayName == "Produkte" ||
        persistentSummary[i].groupDisplayName == "TYP"
      ) {
        flag = 1;
      }
    }
  }

  if (flag == 1) {
    context.showPackage = true;
  } else {
    context.showPackage = false;
  }
  appCtxSvc.updateCtx("variantConfigContext", context);
};

export let populatePersistentSummaryWhileTabSwitch = function () {
  let tempSummarySelectionList = appCtxSvc.getCtx("tempSummarySelectionList");

  return tempSummarySelectionList;
};

export let persistentSummaryforPacakgeValues = function (data) {
  data.persistentselections = exports.updateVariantConfigContext(
    data.eventData.response
  );

  return;
};

let createPersistentSummary = function (response) {
  let deferred = AwPromiseService.instance.defer();
  let i, j, inx;
  let persistentSummary = [];
  //Edit: Fix issue with package display
  if (
    response.selections.length > 0 &&
    response.selections[0].selections == undefined &&
    response.scopes[0] == "Package Group"
  ) {
    persistentSummary =
      appCtxSvc.getCtx("tempSummarySelectionList") != undefined
        ? appCtxSvc.getCtx("tempSummarySelectionList")
        : [];
    return persistentSummary;
  }
  let userSelections = JSON.parse(JSON.stringify(response.selections));
  userSelections[0].selections = {};

  for (let node in response.selections[0].selections) {
    for (i = 0; i < response.selections[0].selections[node].length; i++) {
      if (
        response.selections[0].selections[node][i].selectionState == 1 ||
        response.selections[0].selections[node][i].selectionState == 2
      ) {
        if (userSelections[0].selections[node]) {
          userSelections[0].selections[node].push(
            response.selections[0].selections[node][i]
          );
        } else {
          userSelections[0].selections[node] = [
            response.selections[0].selections[node][i],
          ];
        }
      }
    }
  }

  //
  let userSelectionsfamily = Object.keys(userSelections[0].selections);
  let userSelectionsoptionValue = Object.keys(userSelections[0].selections).map(
    function (e) {
      return userSelections[0].selections[e];
    }
  );
  console.log(userSelectionsoptionValue);
  let groupsInContext = appCtxSvc.getCtx("groupsInContext");
  let tempSummarySelectionList = appCtxSvc.getCtx("tempSummarySelectionList");
  if (tempSummarySelectionList == undefined) {
    appCtxSvc.registerCtx("tempSummarySelectionList", []);
    tempSummarySelectionList = appCtxSvc.getCtx("tempSummarySelectionList");
  }
  let selections = [];
  //let selection = {};
  //let families = [];
  let selectedValues = [];
  for (i = 0; i < userSelectionsoptionValue.length; i++) {
    let optionValue = userSelectionsoptionValue[i];
    for (j = 0; j < optionValue.length; j++) {
      selections.push(optionValue[j]);
    }
  }

  for (i = 0; i < selections.length; i++) {
    let array = selections[i].nodeID.split(":");

    let valueData = {
      familyUid: array[0],
      valueUid: array[1],
      valueSelectionState: selections[i].selectionState,
    };
    selectedValues.push(valueData);
  }

  let listOfAllUids = [];
  let listOfFamilies = [];
  let listOfOptionValues = [];
  //let finalPersistentSelectionList = [];

  let listOfAllUids_tempArr = [];
  for (inx = 0; inx < userSelectionsfamily.length; inx++) {
    if (listOfAllUids_tempArr.indexOf(userSelectionsfamily[inx]) == -1) {
      //push only if unique
      listOfAllUids_tempArr.push(userSelectionsfamily[inx]);
    }
  }
  for (inx = 0; inx < selectedValues.length; inx++) {
    if (listOfAllUids_tempArr.indexOf(selectedValues[inx].valueUid) == -1) {
      //push only if unique
      listOfAllUids_tempArr.push(selectedValues[inx].valueUid);
    }
  }

  for (inx = 0; inx < listOfAllUids_tempArr.length; inx++) {
    let allUids = {};
    (allUids.uid = listOfAllUids_tempArr[inx]), (allUids.type = "");
    listOfAllUids.push(allUids);
  }

  let inputData = {
    objects: listOfAllUids,
    attributes: [
      "object_name",
      "object_desc",
      "object_type",
      "cfg0FamilyGroups",
    ],
  };
  soaSvc
    .post("Internal-Core-2007-12-Session", "getProperties", inputData)
    .then(function (response) {
      let i, j;
      if (response.plain.length == listOfAllUids.length) {
        for (j = 0; j < userSelectionsfamily.length; j++) {
          let object = {};
          object.familyUid = userSelectionsfamily[j];
          object.familyName =
            response.modelObjects[
              userSelectionsfamily[j]
            ].props.object_name.dbValues[0];
          object.familyDesc =
            response.modelObjects[
              userSelectionsfamily[j]
            ].props.object_desc.dbValues[0];
          if (
            response.modelObjects[userSelectionsfamily[j]].props
              .cfg0FamilyGroups.uiValues.length > 0
          ) {
            let group_name =
              response.modelObjects[
                userSelectionsfamily[j]
              ].props.cfg0FamilyGroups.uiValues[0].split("/");
            object.groupName = group_name[0];
          } else if (
            response.modelObjects[userSelectionsfamily[j]].props.object_name
              .dbValues[0] == "TYP"
          ) {
            object.groupName =
              response.modelObjects[
                userSelectionsfamily[j]
              ].props.object_name.dbValues[0];
          }
          console.log("object.groupName", object.groupName);
          listOfFamilies.push(object);
        }

        for (i = 0; i < selectedValues.length; i++) {
          let valueData = selectedValues[i];
          valueData.name =
            response.modelObjects[
              selectedValues[i].valueUid
            ].props.object_name.dbValues[0];
          valueData.desc =
            response.modelObjects[
              selectedValues[i].valueUid
            ].props.object_desc.dbValues[0];
          listOfOptionValues.push(valueData);
        }

        console.log("families", listOfFamilies);

        for (j = 0; j < listOfFamilies.length; j++) {
          for (i = 0; i < listOfOptionValues.length; i++) {
            if (
              listOfFamilies[j].familyUid == listOfOptionValues[i].familyUid
            ) {
              listOfOptionValues[i].familyName = listOfFamilies[j].familyName;
              listOfOptionValues[i].familyDesc = listOfFamilies[j].familyDesc;
              if (listOfFamilies[j].groupName == "TYP") {
                listOfOptionValues[i].groupDisplayName =
                  listOfFamilies[j].groupName;
              } else {
                listOfOptionValues[i].groupDisplayName =
                  listOfFamilies[j].groupName == undefined
                    ? ""
                    : listOfFamilies[j].groupName.slice(0, -3);
              }

              listOfOptionValues[i].groupName = listOfFamilies[j].groupName;

              if (groupsInContext != undefined) {
                for (let k = 0; k < groupsInContext.length; k++) {
                  let groupDisplayName = groupsInContext[k].groupDisplayName;
                  let _groupDisplayName = [];

                  if (groupDisplayName != "TYP") {
                    _groupDisplayName = groupDisplayName.split("_");
                  }

                  if (
                    (listOfOptionValues[i].groupDisplayName ==
                      groupDisplayName &&
                      listOfOptionValues[i].groupDisplayName == "TYP") ||
                    (listOfOptionValues[i].groupDisplayName ==
                      _groupDisplayName[0] &&
                      listOfOptionValues[i].groupDisplayName == "ATT")
                  ) {
                    listOfOptionValues[i].groupName =
                      groupsInContext[k].group_DisplayName;
                  }
                }
              }
            }
          }
        }
        console.log("optValues New", listOfOptionValues);
        checkForPackageView(listOfOptionValues);
        deferred.resolve(listOfOptionValues);
      }
    });

  console.log("selectedValues", selectedValues);

  return deferred.promise;
};
/**
 * Returns created variant rule from SOA response
 *
 * @param {Object} response the response from the variant configuration view SOA
 *
 * @returns {Object} Created variant rule.
 */
export let fetchCreatedVariantRule = function (response) {
  //Registering flag for applying custom configuration in ctx
  appCtxSvc.registerCtx("isApplyingNewConfigurationCFSC", true);
  if (response.ServiceData.created) {
    let variantRuleUID = response.ServiceData.created[0];
    //  return response.ServiceData.modelObjects[variantRuleUID];
    return variantRuleUID;
  }
};

export let resetVariantRuleSelection = function (data) {
  if (
    data.savedSummary != undefined ||
    data.savedpersistentselections != undefined
  ) {
    data.savedpersistentselections = [];
    data.savedSummary = [];
  }
};

//Build summary group to show selected family values
export let populateSelections = function (data) {
  //let i, j;

  console.log("data:", data);
  let familiesUid = [];
  let _familiesUid = [];
  let allGroups = [];
  let selections = [];
  let final_selections = [];
  let context = appCtxSvc.getCtx("variantConfigContext");

  let listAllGroupData = appCtxSvc.getCtx("listAllGroupData");
  if (listAllGroupData != undefined) {
    for (let i = 0; i < listAllGroupData.length; i++) {
      if (listAllGroupData[i].families != undefined) {
        allGroups.push(listAllGroupData[i]);
      } else {
        for (let j = 0; j < listAllGroupData[i].length; j++) {
          if (listAllGroupData[i][j].families.length > 0) {
            allGroups.push(listAllGroupData[i][j]);
          }
        }
      }
    }
  }

  for (let i = 0; i < allGroups.length; i++) {
    if (allGroups[i].families.length > 0) {
      let families = allGroups[i].families;
      for (let j = 0; j < families.length; j++) {
        let familyUidObject = {};
        familyUidObject.uid = families[j].familyObj.sourceUid;
        familyUidObject.type = "";
        familiesUid.push(familyUidObject);
      }
    }
  }

  _familiesUid = familiesUid;
  let inputData = {
    objects: _familiesUid,
    attributes: ["object_name", "object_desc"],
  };

  if (context.guidedMode == true) {
    soaSvc
      .post("Internal-Core-2007-12-Session", "getProperties", inputData)
      .then(function (response) {
        let summary;
        let sortedArray = [];

        appCtxSvc.updateCtx("listAllGroupData", allGroups);
        if (data.eventData.selectionsSummary.summaryOfSelections) {
          for (
            let i = 0;
            i < data.eventData.selectionsSummary.summaryOfSelections.length;
            i++
          ) {
            summary = {};
            let selectionInfo =
              data.eventData.selectionsSummary.summaryOfSelections[i];

            if (
              (selectionInfo.selectionState == 1 ||
                selectionInfo.selectionState == 2) &&
              (selectionInfo.valueDisplayName.length == 0 ||
                selectionInfo.valueDisplayName == "")
            ) {
              let foundOptionFamilyWSObject = checkIfOptionPresentInSLPGroup(
                selectionInfo.familyDisplayName,
                allGroups
              );
              if (JSON.stringify(foundOptionFamilyWSObject) === "{}") {
                continue;
              } else {
                summary.familyDisplayName =
                  foundOptionFamilyWSObject.familyObject.props.object_name.dbValues[0];
                summary.valueDisplayValue =
                  foundOptionFamilyWSObject.featureObject.props.object_name.dbValues[0];
                summary.selectionState = selectionInfo.selectionState;
                summary.isSystemSelection = false;
              }
            } else {
              summary.familyDisplayName = selectionInfo.familyDisplayName;
              summary.selectionState = selectionInfo.selectionState;
              summary.valueDisplayValue = selectionInfo.valueDisplayName;
              summary.isSystemSelection = true; //Adding a variable in summary to distinguish between system and user selections
            }
            /**
             * fetching all details of values present in selection from allGroups
             */
            for (let p = 0; p < allGroups.length; p++) {
              let families = allGroups[p].families;
              for (let q = 0; q < families.length; q++) {
                let optionValues = families[q].features;
                for (let r = 0; r < optionValues.length; r++) {
                  if (
                    summary.familyDisplayName ==
                      optionValues[r].featureObject.displayName ||
                    summary.valueDisplayValue ==
                      optionValues[r].featureObject.wsObject.props.object_name
                        .dbValues[0]
                  ) {
                    summary.groupName = allGroups[p].scopeObj.displayName;
                    if (summary.groupName != "TYP") {
                      let groupdispName = summary.groupName.split("_");
                      summary.groupDisplayName = groupdispName[0];
                    } else {
                      summary.groupDisplayName = summary.groupName;
                    }
                    summary.familyName = families[q].familyObj.displayName;
                    summary.parentGroup = "";
                    summary.optName =
                      optionValues[
                        r
                      ].featureObject.wsObject.props.object_name.dbValues[0];

                    summary.summaryFamilyOptValue = summary.optName;

                    let obj = viewModelObjSvc.createViewModelObject(
                      optionValues[r].featureObject.wsObject.uid
                    );
                    let desc = obj.cellHeader2;

                    summary.summaryNameOptValue = desc;
                    summary._optDesc = summary.summaryNameOptValue;
                    summary.familySourceUid =
                      allGroups[p].families[q].familyObj.sourceUid;
                    summary.familySelectionState =
                      allGroups[p].families[q].selectionInfo.selectionState;
                    if (response != undefined) {
                      summary.technicalFamilyName =
                        response.modelObjects[
                          summary.familySourceUid
                        ].props.object_desc.dbValues[0];
                    }

                    if (
                      summary.optName != summary.valueDisplayValue &&
                      summary.valueDisplayValue != ""
                    ) {
                      summary.summaryFamilyOptValue = summary.valueDisplayValue;
                    }

                    if (
                      summary.familyDisplayName == "_TYP" ||
                      summary.familyDisplayName == "TYP"
                    ) {
                      summary.summaryNameOptValue = summary.valueDisplayValue;
                      summary.summaryFamilyOptValue = summary.familyDisplayName;
                    }
                  }
                }
              }
            }
            selections.push(summary);
          }
        }
        //sarwan: send the summary to G4B_ExportConfigurationToExcelService for creating input for dispatcher request
        // exportConfExcelSvc.createInputForJavaExcelExportUtility(selections, data);

        let includes = [];
        let excludes = [];

        for (let i = 0; i < selections.length; i++) {
          if (
            selections[i].selectionState == 5 ||
            selections[i].selectionState == 9 ||
            selections[i].selectionState == 1 ||
            selections[i].selectionState == -2
          ) {
            includes.push(selections[i]);
          }
          if (
            selections[i].selectionState == 2 ||
            selections[i].selectionState == 10 ||
            selections[i].selectionState == 6
          ) {
            excludes.push(selections[i]);
          }
        }

        let groupInfo = [];
        let groupFlag = 0;
        if (selections.length != 0) {
          for (let i = 0; i < selections.length; i++) {
            if (selections[i].groupName != undefined) {
              let name = selections[i].groupName;
              for (let j = 0; j < groupInfo.length; j++) {
                if (name != groupInfo[j]) {
                  groupFlag++;
                }
              }
              if (groupFlag == groupInfo.length) {
                groupInfo.push(name);
              }
            }
            groupFlag = 0;
          }
        }

        for (let i = 0; i < groupInfo.length; i++) {
          summary = {};
          let parentGroupName;
          parentGroupName = groupInfo[i];
          let context = appCtxSvc.getCtx("groupsInContext");

          for (let j = 0; j < context.length; j++) {
            if (parentGroupName == context[j].groupDisplayName) {
              if (context[j].group_DisplayName) {
                summary.group_DisplayName = context[j].group_DisplayName;
                summary.parentGroup = context[j].group_DisplayName;
              } else {
                summary.parentGroup = context[j].groupDisplayName;
              }
              summary.groupName = "";
              summary.optName = "";
              summary._optDesc = "";

              selections.push(summary);
              final_selections.push(summary);

              //Add 'Include' and 'Exclude' subgroup in 'SLP' group
              let parentGroupName;
              parentGroupName = parentGroupName.split("_");
              if (parentGroupName[0] == "SLP") {
                summary = {};
                summary.includes = "Include";
                summary.groupOfIncludes = parentGroupName;
                selections.push(summary);

                summary = {};
                summary.excludes = "exclude";
                summary.groupOfExcludes = parentGroupName;
                selections.push(summary);

                summary = {};
                summary.notValued = "notValued";
                summary.groupOfNotValued = parentGroupName;
                selections.push(summary);
              }
            }
          }
          let propArray = [];
          for (let j = 0; j < selections.length; j++) {
            if (
              parentGroupName == selections[j].groupName &&
              parentGroupName[0] != "SLP"
            ) {
              propArray.push(selections[j].summaryFamilyOptValue);
            } else if (
              parentGroupName == selections[j].groupName &&
              parentGroupName[0] == "SLP"
            ) {
              propArray.push();
            }
            propArray = propArray.sort();
          }

          //let m, index, index2, k;
          let prevFamilyName = "";
          for (let m = 0; m < propArray.length; m++) {
            for (let index2 = 0; index2 < selections.length; index2++) {
              if (
                propArray[m] == selections[index2].summaryFamilyOptValue &&
                prevFamilyName !== selections[index2].familyDisplayName
              ) {
                prevFamilyName = selections[index2].familyDisplayName;
                sortedArray.push(selections[index2]);
                break;
              }
            }
          }

          for (let index = 0; index < sortedArray.length; index++) {
            final_selections.push(sortedArray[index]);
          }
          sortedArray = [];

          //Adding included features
          for (let j = 0; j < selections.length; j++) {
            propArray = [];
            if (
              selections[j].includes != undefined &&
              selections[j].groupOfIncludes == parentGroupName
            ) {
              final_selections.push(selections[j]);

              for (let k = 0; k < includes.length; k++) {
                if (parentGroupName == includes[k].groupName) {
                  propArray.push(includes[k].summaryFamilyOptValue);
                }
                propArray = propArray.sort();
              }
              for (let m = 0; m < propArray.length; m++) {
                for (let index2 = 0; index2 < selections.length; index2++) {
                  if (
                    propArray[m] == selections[index2].summaryFamilyOptValue
                  ) {
                    sortedArray.push(selections[index2]);
                    break;
                  }
                }
              }
              // sorting the family name according to include feature
              let familyName = [];
              for (let a = 0; a < sortedArray.length; a++) {
                familyName.push(sortedArray[a].technicalFamilyName);
              }
              let finalFamilyInSeries = [...new Set(familyName)];
              let sortInSeriesFamily = [];
              let getFamilyObj = {};
              for (let b = 0; b < finalFamilyInSeries.length; b++) {
                let flag = true;
                for (let b1 = 0; b1 < sortedArray.length; b1++) {
                  if (
                    finalFamilyInSeries[b] ==
                      sortedArray[b1].technicalFamilyName &&
                    flag == true
                  ) {
                    if (sortedArray[b1].familySourceUid) {
                      getFamilyObj = cdm.getObject(
                        sortedArray[b1].familySourceUid
                      );
                    }
                    sortInSeriesFamily.push({
                      includeFamilyName: finalFamilyInSeries[b],
                      isSystemSelection: true,
                      selectionState: sortedArray[b1].familySelectionState,
                      familyDisplayName:
                        getFamilyObj.props.object_name.dbValues[0],
                    });
                    flag = false;
                  }
                }
              }
              if (sortInSeriesFamily.length) {
                for (let c = 0; c < sortInSeriesFamily.length; c++) {
                  final_selections.push(sortInSeriesFamily[c]);
                  for (let d = 0; d < sortedArray.length; d++) {
                    if (
                      sortInSeriesFamily[c].includeFamilyName ==
                      sortedArray[d].technicalFamilyName
                    ) {
                      final_selections.push(sortedArray[d]);
                    }
                  }
                }
              }
              sortedArray = [];
            }
          }

          if (parentGroupName[0] == "SLP") {
            //get SLP group
            let slpGroup = [];
            for (i = 0; i < allGroups.length; i++) {
              let slpGroupName = allGroups[i].scopeObj.displayName.split("_");
              if (slpGroupName[0] == "SLP") {
                slpGroup = allGroups[i];
              }
            }
            //Considering Families having non-zero selection states needs to
            //get propagated to its individual features, hence those features cannot be considered 'Not Valued'
            for (let i = 0; i < slpGroup.families.length; i++) {
              let familySlp = slpGroup.families[i];

              if (familySlp.selectionInfo.selectionState != 0) {
                //case: When we have 'Select None' i.e. selectionState=5  on family level
                if (familySlp.selectionInfo.selectionState == 5) {
                  for (let j = 0; j < familySlp.features.length; j++) {
                    summary = {};

                    summary.isSystemSelection = true;
                    summary.summaryFamilyOptValue =
                      familySlp.features[
                        j
                      ].featureObject.wsObject.props.object_name.dbValues[0];
                    summary.optName =
                      familySlp.features[
                        j
                      ].featureObject.wsObject.props.object_name.dbValues[0];
                    // eslint-disable-next-line no-useless-escape
                    let summaryNameOptValue =
                      familySlp.features[
                        j
                      ].featureObject.wsObject.props.awp0CellProperties.dbValues[1].split(
                        ":"
                      );

                    summary.summaryNameOptValue = summaryNameOptValue[1];
                    summary.selectionState = 6;
                    summary.parentGroup = "";
                    summary.groupDisplayName =
                      slpGroup.scopeObj.displayName.split("_")[0];
                    summary.groupName = slpGroup.scopeObj.displayName;
                    summary.familyDisplayName = familySlp.familyObj.displayName;
                    summary.familySourceUid = familySlp.familyObj.sourceUid;
                    summary.familySelectionState =
                      familySlp.selectionInfo.selectionState;
                    let getExcludeObj = cdm.getObject(summary.familySourceUid);
                    summary.technicalFamilyName =
                      getExcludeObj.props.object_desc.dbValues[0];
                    excludes.push(summary);
                  }
                }
              }
            }
          }

          //Adding excluded features
          for (let j = 0; j < selections.length; j++) {
            propArray = [];

            if (
              selections[j].excludes != undefined &&
              selections[j].groupOfExcludes == parentGroupName
            ) {
              final_selections.push(selections[j]);

              for (let k = 0; k < excludes.length; k++) {
                if (parentGroupName == excludes[k].groupName) {
                  propArray.push(excludes[k].summaryFamilyOptValue);
                  propArray = propArray.sort();
                }
              }
              sortedArray = [];
              let arrToFetch = selections;
              if (parentGroupName[0] == "SLP") {
                arrToFetch = excludes;
              }
              for (let m = 0; m < propArray.length; m++) {
                for (let index2 = 0; index2 < arrToFetch.length; index2++) {
                  if (
                    propArray[m] == arrToFetch[index2].summaryFamilyOptValue
                  ) {
                    sortedArray.push(arrToFetch[index2]);
                  }
                }
              }
              // sorting the family according the exclude features
              let excludeFamily = [];
              for (let a = 0; a < sortedArray.length; a++) {
                excludeFamily.push(sortedArray[a].technicalFamilyName);
              }
              let excludeFamilyInSeries = [...new Set(excludeFamily)];
              let sortExcludeFamilyInSeries = [];
              let getExcludeFamilyObj;
              for (let b = 0; b < excludeFamilyInSeries.length; b++) {
                let flag = true;
                for (let b1 = 0; b1 < sortedArray.length; b1++) {
                  if (
                    excludeFamilyInSeries[b] ==
                      sortedArray[b1].technicalFamilyName &&
                    flag == true
                  ) {
                    if (sortedArray[b1].familySourceUid) {
                      getExcludeFamilyObj = cdm.getObject(
                        sortedArray[b1].familySourceUid
                      );
                    }
                    sortExcludeFamilyInSeries.push({
                      excludeFamilyName: excludeFamilyInSeries[b],
                      isSystemSelection: true,
                      selectionState: sortedArray[b1].familySelectionState,
                      familyDisplayName:
                        getExcludeFamilyObj.props.object_name.dbValues[0],
                    });
                    flag = false;
                  }
                }
              }
              if (sortExcludeFamilyInSeries.length) {
                for (let c = 0; c < sortExcludeFamilyInSeries.length; c++) {
                  final_selections.push(sortExcludeFamilyInSeries[c]);
                  for (let d = 0; d < sortedArray.length; d++) {
                    if (
                      sortExcludeFamilyInSeries[c].excludeFamilyName ==
                      sortedArray[d].technicalFamilyName
                    ) {
                      final_selections.push(sortedArray[d]);
                    }
                  }
                }
              }
              // for (let index = 0; index < sortedArray.length; index++) {
              //     final_selections.push(sortedArray[index])
              // }
              sortedArray = [];
            }
          }

          //Adding Not valued features
          for (let m = 0; m < selections.length; m++) {
            propArray = [];

            if (
              selections[m].groupOfNotValued == parentGroupName &&
              selections[m].notValued != undefined
            ) {
              final_selections.push(selections[m]);
              //for non selected values to be shown in summary
              let slpGroup = [];
              for (let i = 0; i < allGroups.length; i++) {
                let slpGroupName = allGroups[i].scopeObj.displayName.split("_");
                if (slpGroupName[0] == "SLP") {
                  slpGroup = allGroups[i];
                }
              }
              let nonSelectedOptVal = [];
              for (let i = 0; i < slpGroup.families.length; i++) {
                let familySlp = slpGroup.families[i];
                /*Considering only families that have selectionState = 0 on family level. Families having non-zero selection states needs to 
                            get propagated to its individual features, hence those features cannot be considered 'Not Valued'*/
                if (familySlp.selectionInfo.selectionState == 0) {
                  for (let j = 0; j < familySlp.features.length; j++) {
                    summary = {};
                    let iFlag = 0;

                    for (let k = 0; k < selections.length; k++) {
                      if (
                        selections[k].summaryFamilyOptValue ==
                        familySlp.features[j].featureObject.wsObject.props
                          .object_name.dbValues[0]
                      ) {
                        iFlag = 1;
                      }
                    }

                    if (iFlag == 0) {
                      summary.isSystemSelection = false;
                      summary.summaryFamilyOptValue =
                        familySlp.features[
                          j
                        ].featureObject.wsObject.props.object_name.dbValues[0];

                      // eslint-disable-next-line no-useless-escape
                      let summaryNameOptValue =
                        familySlp.features[
                          j
                        ].featureObject.wsObject.props.awp0CellProperties.dbValues[1].split(
                          ":"
                        );

                      summary.summaryNameOptValue = summaryNameOptValue[1];
                      summary.selectionState = 0;
                      summary.parentGroup = "";
                      summary.groupDisplayName = parentGroupName;
                      summary.familySourceUid = familySlp.familyObj.sourceUid;
                      summary.familySelectionState =
                        familySlp.selectionInfo.selectionState;
                      let getNotValuedObj = cdm.getObject(
                        familySlp.familyObj.sourceUid
                      );
                      summary.technicalFamilyName =
                        getNotValuedObj.props.object_desc.dbValues[0];
                      nonSelectedOptVal.push(summary);
                    }
                  }
                }
              }
              propArray = [];
              for (i = 0; i < nonSelectedOptVal.length; i++) {
                if (
                  propArray.indexOf(
                    nonSelectedOptVal[i].summaryFamilyOptValue
                  ) == -1
                ) {
                  propArray.push(nonSelectedOptVal[i].summaryFamilyOptValue);
                }
              }
              propArray = propArray.sort();
              sortedArray = [];
              for (let j = 0; j < propArray.length; j++) {
                for (let k = 0; k < nonSelectedOptVal.length; k++) {
                  if (
                    nonSelectedOptVal[k].summaryFamilyOptValue == propArray[j]
                  ) {
                    sortedArray.push(nonSelectedOptVal[k]);
                  }
                }
              }
            }
          }
        }
        // sorting the family name according to not valued feature
        let notValuedFamilyName = [];
        for (let a = 0; a < sortedArray.length; a++) {
          notValuedFamilyName.push(sortedArray[a].technicalFamilyName);
        }
        let notValuedFamilyInSeries = [...new Set(notValuedFamilyName)];
        let sortNotValuedInSeries = [];
        let getNotValuedFamilyObj;
        for (let b = 0; b < notValuedFamilyInSeries.length; b++) {
          let flag = true;
          for (let b1 = 0; b1 < sortedArray.length; b1++) {
            if (
              notValuedFamilyInSeries[b] ==
                sortedArray[b1].technicalFamilyName &&
              flag == true
            ) {
              if (sortedArray[b1].familySourceUid) {
                getNotValuedFamilyObj = cdm.getObject(
                  sortedArray[b1].familySourceUid
                );
              }
              sortNotValuedInSeries.push({
                notValuedFamilyName: notValuedFamilyInSeries[b],
                isSystemSelection: true,
                selectionState: sortedArray[b1].familySelectionState,
                familyDisplayName:
                  getNotValuedFamilyObj.props.object_name.dbValues[0],
              });
              flag = false;
            }
          }
        }
        if (sortNotValuedInSeries.length) {
          for (let c = 0; c < sortNotValuedInSeries.length; c++) {
            final_selections.push(sortNotValuedInSeries[c]);
            for (let d = 0; d < sortedArray.length; d++) {
              if (
                sortNotValuedInSeries[c].notValuedFamilyName ==
                sortedArray[d].technicalFamilyName
              ) {
                final_selections.push(sortedArray[d]);
              }
            }
          }
        }
        // for (let i = 0; i < sortedArray.length; i++) {
        //     final_selections.push(sortedArray[i]);
        // }

        let populateSummary = {};
        populateSummary.selections = final_selections;
        populateSummary.groupInfo = groupInfo;
        exportConfExcelSvc.createInputForJavaExcelExportUtility(
          final_selections,
          data
        );
        data.populateSummary = populateSummary;
        return data.populateSummary;
      });
  }
};

// Before expanding summary, reset existing information
export let resetSummaryInfo = function (data) {
  if (data.populateSummary && data.populateSummary.selections.length > 0) {
    data.populateSummary = {};
  }
};

export let pageReload = function () {
  //   angular.element('aw-occmgmt-secondary-workarea').scope().$evalAsync();
  //  location.reload();
  setTimeout(function () {
    //let context = appCtxSvc.getCtx("aceActiveContext");

    location.reload();
  }, 5000);
};

/**
 * To check the platform server version is equals or more than TC 11.4 then only it will return true else it
 * will return false.
 * @function isPlatformVersionSupported
 */

let isPlatformVersionSupported = function () {
  let majorVersion = tcSessionData.getTCMajorVersion();
  let minorVersion = tcSessionData.getTCMinorVersion();
  let qrmNumber = tcSessionData.getTCQRMNumber();

  // If major version is greater than 11 .e.g TC12x onwards, then set true
  if (majorVersion > 11) {
    return true;
  }

  // Major version is 11
  // 11.4 version's internal name is 11.2.5
  // If we find an API which returns display version instead of internal version, then we'll have to fix this
  return majorVersion === 11 && minorVersion >= 2 && qrmNumber >= 5;
};

//Render Toggle mode appropriately
export let renderToggleMode = function () {
  // Hide Toggle mode if required minimum platform version is not greater than or equal to 11.4
  // We'll hold this information in variantConfigContext in appcontext so that main view where toggle
  // is displayed can access this.

  // Update application context
  let context = appCtxSvc.getCtx("variantConfigContext");
  if (context) {
    context.isManualModeSupported = isPlatformVersionSupported();
    appCtxSvc.updateCtx("variantConfigContext", context);
    appCtxSvc.updateCtx("storeContext", context);

    // uncomment the platform support check for validation, once baseline is available
    context.isPlatformGreaterThan11503 = isPlatformGreaterThan11503();
  }

  if (context.isManualModeSupported && context.guidedMode) {
    // we initialize isManualConfiguration to false when we know manual configuration is supported.
    // it is initialized to false because we land to the configuration panel in guided mode.
    context.isManualConfiguration = false;
  }
};

/**
 * To check the platform server version is equals or more than TC 11.5.0.x then only it will return true else it
 * will return false.
 * @function isPlatformVersionSupportedForValidation
 */

let isPlatformGreaterThan11503 = function () {
  let majorVersion = tcSessionData.getTCMajorVersion();
  let minorVersion = tcSessionData.getTCMinorVersion();
  let qrmNumber = tcSessionData.getTCQRMNumber();

  // If major version is greater than 11 .e.g TC12x onwards, then set true
  if (majorVersion > 11) {
    return true;
  }

  // Major version is 11
  // 11.5 version's internal name is 11.2.6
  // If we find an API which returns display version instead of internal version, then we'll have to fix this
  if (majorVersion === 11 && minorVersion === 2) {
    if (qrmNumber === 6) {
      // In case of TC 11.5.0.x server version string like Server Version: V.11.2.6.60_20180616.00
      // while in case of TC 11.5.0.x server version string like Server Version: V.11.2.6.60_20180616.00
      // So we have below code to split based on "_" and then get the first value and then use the
      // character present at 1st index to identify the patch version.
      let phase = tcServerVersion.phase;
      if (phase) {
        let stringArray = phase.split("_");
        if (
          stringArray !== null &&
          stringArray.length >= 2 &&
          stringArray[0] !== null
        ) {
          let str = stringArray[0].charAt(1);
          let phaseVersion = parseInt(str, 10);
          if (phaseVersion >= 1) {
            return true;
          }
        }
      }
    }
    if (qrmNumber > 6) {
      return true;
    }
  }
  return majorVersion === 11 && minorVersion > 2;
};

/**
 * Will return html selector for given group, family and value
 */
function getSelector(group, family, value) {
  let selector = undefined;
  if (group && family && value) {
    if (family.isThumbnailDisplay) {
      let title = value.optValue.cellHeader1;
      selector =
        ".aw-ui-filterCategory[title='" +
        group.groupDisplayName +
        "'] " +
        "> div.ng-scope > aw-cfg-family > div.ng-scope >  aw-cfg-value > div.aw-cfg-value[title='" +
        title +
        "']";
    } else {
      selector =
        ".aw-ui-filterCategory[title='" +
        group.groupDisplayName +
        "'] " +
        "> div.ng-scope > aw-cfg-family > div.aw-cfg-familyLabel[title='" +
        family.familyDisplayName +
        "']+" +
        "div.ng-scope >  aw-cfg-value > div.aw-cfg-value > [title='" +
        value.valueDisplayName +
        "']";
    }
  } else if (group) {
    selector = ".aw-ui-filterCategory[title='" + group.groupDisplayName + "'] ";
  }
  return selector;
}

/**
 * Returns option families for a group *
 *
 * @param {Object} optionGroup - option group from SOA response
 * @param {ObjectArray} families - option families list for a group from SOA response
 * @param {ObjectArray} labels - Labels to be shown on feature objects like violations etc.
 *
 * @returns {ObjectArray} The array of option families for option group
 */
function getFamiliesForGroup(optionGroup, families, labels, getPropResponse) {
  let i;
  let tmpfamilies = [];
  let tmpFavFamily = {}; 
  //let familyDescMap = [];
  /**
   *  Code to read existing pref value and separate out the optionvalue and family
   */
  if (families.length > 0) {
    let prefG4B_AWC_ConfiguratorUserFavorites = appCtxSvc.getCtx(
      "prefG4B_AWC_ConfiguratorUserFavorites"
    );
    let favouriteList = [];
    for (i = 0; i < prefG4B_AWC_ConfiguratorUserFavorites.values.length; i++) {
      let array = prefG4B_AWC_ConfiguratorUserFavorites.values[i].split("/");
      if (
        appCtxSvc.getCtx("selected.props.awb0UnderlyingObjectType") != undefined
      ) {
        if (
          (appCtxSvc.getCtc(
            "selected.props.awb0UnderlyingObjectType.dbValues[0]"
          ) == "G4B_CollaborativeDesign" &&
            array[0] ==
              appCtxSvc.getCtx(
                "selected.props.awb0UnderlyingObject.dbValues[0]"
              )) ||
          (appCtxSvc.getCtx(
            "selected.props.awb0UnderlyingObjectType.dbValues[0]"
          ) == "Cpd0DesignSubsetElement" &&
            array[0] ==
              appCtxSvc.getCtx("selected.props.fgd0ModelObject.dbValues[0]") &&
            optionGroup.scopeObj.displayName.indexOf(array[1]) > -1)
        ) {
          favouriteList.push(array[2]);
        }
      }
    }
    /**
     * Creating Favourite Family
     */
    let langCode = localeSvc.getLanguageCode();
    if (langCode == "de") {
      tmpFavFamily.familyDisplayName = "Favoriten";
      tmpFavFamily.familyDesc = "Favoriten";
    } else if (langCode == "en") {
      tmpFavFamily.familyDisplayName = "Favourites";
      tmpFavFamily.familyDesc = "Favourites";
    }
    tmpFavFamily.isFiltered = true;
    tmpFavFamily.familyType = "favourite";
    tmpFavFamily.values = getValuesForFavouriteFamily(
      favouriteList,
      getPropResponse
    );
    tmpfamilies.push(tmpFavFamily);
  }

  /**
   * Creating general Families
   */
  for (i = 0; i < families.length; i++) {
    let tmpFamily = {};
    tmpFamily.familyDisplayName = families[i].familyObj.displayName;
    tmpFamily.familyStr = families[i].selectionInfo.nodeID;
    tmpFamily.selectionState = families[i].selectionInfo.selectionState;
    if (getPropResponse != undefined) {
      tmpFamily.familyDesc =
        getPropResponse.modelObjects[
          tmpFamily.familyStr
        ].props.object_desc.dbValues[0];
    }

    tmpFamily.familyType = families[i].familyObj.sourceType;
    tmpFamily.complete = families[i].familyObj.props.isComplete[0] === "true";
    tmpFamily.isFiltered = true;
    tmpFamily.isThumbnailDisplay =
      families[i].familyObj.props.isThumbnailDisplay[0] === "true";

    tmpFamily.singleSelect =
      families[i].familyObj.props.isSingleSelect[0] === "true";
    if (families[i].familyObj.props.cfg0IsDiscretionary) {
      tmpFamily.cfg0IsDiscretionary =
        families[i].familyObj.props.cfg0IsDiscretionary[0] === "true";
    }

    //GEPA-22791: Workaround for Incompleteness issue
    // show 'Selection Required' when family and all individual features inside it have a selection state of 0
    if (
      appCtxSvc.getCtx("variantConfigContext") &&
      appCtxSvc.getCtx("variantConfigContext.guidedMode")
    ) {
      let completeness = false;
      if (
        tmpFamily.complete == true &&
        tmpFamily.singleSelect == true &&
        families[i].selectionInfo.selectionState != 0
      ) {
        completeness = true;
      }
      if (!completeness) {
        let flag = false;
        for (let inx = 0; inx < families[i].features.length; inx++) {
          if (
            tmpFamily.complete == true &&
            tmpFamily.singleSelect == true &&
            families[i].features[inx].selectionInfo.selectionState != 0
          ) {
            completeness = true;
          }
          if (
            tmpFamily.singleSelect == false &&
            families[i].features[inx].selectionInfo.selectionState == 0
          ) {
            flag = true;
          }
        }
        if (flag == false && tmpFamily.singleSelect == false) {
          tmpFamily.complete = true;
        } else if (flag == true && tmpFamily.singleSelect == false) {
          tmpFamily.complete = false;
        }
      }
      if (tmpFamily.complete == true && tmpFamily.singleSelect == true) {
        tmpFamily.complete = completeness;
      }
    }
    //END GEPA-22791: Workaround for Incompleteness issue

    tmpFamily.values = getValuesForFamily(
      families[i].features,
      labels,
      getPropResponse,
      tmpFavFamily.values,
      tmpFamily.familyType,
      tmpFamily.singleSelect
    );
    tmpfamilies.push(tmpFamily);
  }
  return tmpfamilies;
}

/**
 * Returns option values for a family
 *
 * @param {ObjectArray} option values list for a families from SOA response
 * @param {ObjectArray} labels - Labels to be shown on feature objects like violations etc.
 *
 * @returns {ObjectArray} The array of values for option family
 */
function getValuesForFamily(
  optionValues,
  labels,
  getPropResponse,
  tmpFavValueList,
  familyType,
  singleSelect
) {
  let varConfigCtx = appCtxSvc.getCtx("variantConfigContext");
  let tmpValues = [];
  for (let i = 0; i < optionValues.length; i++) {
    if (
      varConfigCtx.guidedMode ||
      (!varConfigCtx.guidedMode &&
        varConfigCtx.projectedModeData[optionValues[i].selectionInfo.nodeID] !=
          undefined)
    ) {
      let tmpValue = {};
      tmpValue.index = tmpValues.length % 2;
      tmpValue.isFavourite = false;

      if (cdm.isValidObjectUid(optionValues[i].featureObject.wsObject.uid)) {
        tmpValue.optValue = viewModelObjSvc.createViewModelObject(
          optionValues[i].featureObject.wsObject
        );
      }
      tmpValue.valueDisplayName =
        optionValues[i].featureObject.wsObject.props.object_name.dbValues[0];
      if (tmpValue.valueDisplayName == "") {
        tmpValue.valueDisplayName = tmpValue.optValue.cellHeader1;
      }
      if (tmpValue.optValue == undefined) {
        tmpValue.valueDescription =
          getPropResponse.modelObjects[
            optionValues[i].featureObject.sourceUid
          ].props.object_desc.dbValues[0];
        tmpValue.optValue = {};
        tmpValue.optValue.cellHeader2 = tmpValue.valueDescription;
        tmpValue.optValue.cellHeader1 = tmpValue.valueDisplayName;
      } else {
        tmpValue.valueDescription = tmpValue.optValue.cellHeader2;
      }

      tmpValue.isFiltered = true;
      tmpValue.selectionState = optionValues[i].selectionInfo.selectionState;
      tmpValue.allowedSelectionStates = optionValues[i].allowedSelectionStates;

      //Adjust the selection states based on the 'isStandardManualMode' flag
      //'isStandardManualMode' == true : system selections are displayed from the standard selections data
      //'isStandardManualMode' == false : system selections are displayed from the projected mode data
      if (!varConfigCtx.guidedMode) {
        if (varConfigCtx.isStandardManualMode == false) {
          //Check if existing selections are available for single-select families
          if (
            singleSelect &&
            varConfigCtx.selections[0].selections[
              optionValues[i].selectionInfo.nodeID.split(":")[0]
            ] == undefined
          ) {
            tmpValue.selectionState =
              varConfigCtx.projectedModeData[
                optionValues[i].selectionInfo.nodeID
              ].selectionState;
          }
          //Check if existing selections are available for multi-select families
          else if (!singleSelect) {
            if (
              varConfigCtx.selections[0].selections[
                optionValues[i].selectionInfo.nodeID.split(":")[0]
              ] == undefined
            ) {
              tmpValue.selectionState =
                varConfigCtx.projectedModeData[
                  optionValues[i].selectionInfo.nodeID
                ].selectionState;
            } else {
              //We need to check sel states for individual features for multi-select families
              let selState = getSelectionStateForUnguidedMode(
                optionValues[i].selectionInfo.nodeID
              );
              if (selState != null) {
                tmpValue.selectionState = selState;
              } else {
                tmpValue.selectionState =
                  varConfigCtx.projectedModeData[
                    optionValues[i].selectionInfo.nodeID
                  ].selectionState;
              }
            }
          }
        }

        if (
          varConfigCtx.projectedModeData[optionValues[i].selectionInfo.nodeID]
            .isInvalid
        ) {
          tmpValue.isInvalid =
            varConfigCtx.projectedModeData[
              optionValues[i].selectionInfo.nodeID
            ].isInvalid;
        }

        //Selection state 2 is not required for TYP group in Manual mode
        //Ref: GEPA-10680 Unguided mode: double click needed to deselect a model code
        if (
          cdm.getObject(varConfigCtx.currentGroup).type ==
          "G4B_ProductModelFamily"
        ) {
          tmpValue.allowedSelectionStates = [0, 1];
        }
      }

      //Handle pinning use-case to show user-selected Unpinned features in Un-guided mode
      // if(!varConfigCtx.guidedMode && varConfigCtx.group){

      //     let groupName = varConfigCtx.group.groupDisplayName.split("_")[0];
      //     if(groupName == "SLP" || groupName == "LCK" ||groupName == "POL" ||groupName == "PLG" ||groupName == "ATT" ||groupName == "EBR" ||groupName == "MBR"){
      //         //In projected mode the allowedSelectionStates are as per the guided mode. We need to convert this to [0,1,2].. as per the manual mode.
      //         tmpValue.allowedSelectionStates = [0,1,2];

      //         //Check 1st if any selectionState available in varConfigCtx.selections
      //         if(varConfigCtx.selections){
      //             let selStateUpdated = getSelectionStateForUnguidedMode(optionValues[i].selectionInfo.nodeID);
      //             if(selStateUpdated != null){
      //                 tmpValue.selectionState = selStateUpdated;
      //             }
      //         }

      //         //Check if any selectionState available in g4bUserSelectionCache. This has higher priority
      //         if(varConfigCtx.g4bUserSelectionCache && varConfigCtx.g4bUserSelectionCache[optionValues[i].selectionInfo.nodeID] > -1){
      //             tmpValue.selectionState = _.clone(varConfigCtx.g4bUserSelectionCache[optionValues[i].selectionInfo.nodeID]);
      //         }

      //         //Check if a family already has a feature selected. We need to have selection state 0 for features belonging to singleSelect families that already have another user selection
      //         /*if(singleSelect && ( tmpValue.selectionState == 5 ||  tmpValue.selectionState == 6 ||  tmpValue.selectionState ==9 || tmpValue.selectionState == 10)){
      //             let isExist = checkIfAnotherSelectionExistForSingleSelectFamily(optionValues[i].selectionInfo.nodeID);
      //             if(isExist){
      //                 tmpValue.selectionState = 0;
      //             }
      //         }*/
      //     }
      // }

      tmpValue.isPackage =
        optionValues[i].featureObject.props.isPackage[0] === "true";
      if (tmpValue.isPackage) {
        tmpValue.packageOpened = false;
      }
      tmpValue.isThumbnailDisplay =
        optionValues[i].featureObject.props.isThumbnailDisplay[0] === "true";

      let violationsInfo = buildViolationString(
        optionValues[i].selectionInfo.nodeID,
        labels
      );

      if (
        violationsInfo !== null &&
        violationsInfo.violationMessage !== undefined
      ) {
        tmpValue.violationsInfo = violationsInfo;
        tmpValue.hasViolation = true;
      } else {
        tmpValue.hasViolation = false;
      }
      tmpValue.optValueStr = optionValues[i].selectionInfo.nodeID;

      //Adding Missing properties to favourite optionValues
      if (tmpFavValueList.length > 0) {
        for (let j = 0; j < tmpFavValueList.length; j++) {
          if (
            tmpFavValueList[j].optValueStr ==
            optionValues[i].selectionInfo.nodeID
          ) {
            tmpValue.isFavourite = true;
            tmpFavValueList[j].isFavourite = true;
            tmpFavValueList[j].optValue = _.clone(tmpValue.optValue);
            tmpFavValueList[j].selectionState = tmpValue.selectionState;
            tmpFavValueList[j].isPackage = tmpValue.isPackage;
            if (tmpFavValueList[j].isPackage) {
              tmpFavValueList[j].packageOpened = false;
            }
            tmpFavValueList[j].isThumbnailDisplay = tmpValue.isThumbnailDisplay;
            tmpFavValueList[j].violationsInfo = tmpValue.violationsInfo;
            tmpFavValueList[j].hasViolation = tmpValue.hasViolation;
            tmpFavValueList[j].underlyingfamilyType = familyType;
            tmpFavValueList[j].allowedSelectionStates =
              tmpValue.allowedSelectionStates;
            tmpFavValueList[j].underlyingfamilySingleSelect = singleSelect;
          }
        }
      }
      //Case: Pinned features scenario - We dont want to show the 'invalid' features for the Type characteristics groups when in unguided mode
      if (varConfigCtx.group) {
        let groupName = varConfigCtx.group.groupDisplayName;
        if (
          !varConfigCtx.guidedMode &&
          tmpValue.isInvalid &&
          (groupName.indexOf("ATT_") > -1 ||
            groupName.indexOf("EBR_") > -1 ||
            groupName.indexOf("MBR_") > -1 ||
            groupName.indexOf("ESP_") > -1)
        ) {
          continue;
        } else {
          tmpValues.push(tmpValue);
        }
      } else {
        tmpValues.push(tmpValue);
      }
    }
  }
  return tmpValues;
}

function getValuesForFavouriteFamily(optionValues, getPropResponse) {
  let tmpValues = [];
  for (let i = 0; i < optionValues.length; i++) {
    let arr = optionValues[i].split(":");
    let tmpValue = {};
    if (
      getPropResponse != undefined &&
      getPropResponse.modelObjects[arr[1]] != undefined
    ) {
      tmpValue.index = i % 2;
      tmpValue.valueDisplayName =
        getPropResponse.modelObjects[arr[1]].props.object_name.dbValues[0];
      tmpValue.valueDescription =
        getPropResponse.modelObjects[arr[1]].props.object_desc.dbValues[0];
      tmpValue.optValue = {};
      tmpValue.optValue.cellHeader2 = tmpValue.valueDescription;
      tmpValue.optValue.cellHeader1 = tmpValue.valueDisplayName;
      tmpValue.isFiltered = true;
      tmpValue.selectionState = "";
      tmpValue.allowedSelectionStates = [];
      tmpValue.isPackage = false;
      tmpValue.optValueStr = optionValues[i];

      tmpValues.push(tmpValue);
    }
  }
  return tmpValues;
}

let getSelectionStateForUnguidedMode = function (nodeID) {
  let selState = null;
  let varConfigCtx = appCtxSvc.getCtx("variantConfigContext");
  let selections = varConfigCtx.selections[0].selections;

  let familyNodeArr = nodeID.split(":");

  if (familyNodeArr[0] in selections) {
    for (let inx = 0; inx < selections[familyNodeArr[0]].length; inx++) {
      if (selections[familyNodeArr[0]][inx].nodeID == nodeID) {
        selState = selections[familyNodeArr[0]][inx].selectionState;
        break;
      }
    }
  }

  return selState;
};

let checkIfAnotherSelectionExistForSingleSelectFamily = function (nodeID) {
  let isExist = false;
  let varConfigCtx = appCtxSvc.getCtx("variantConfigContext");
  let selections = varConfigCtx.selections[0].selections;
  let userSelectionCache = varConfigCtx.g4bUserSelectionCache;

  let familyUid = nodeID.split(":")[0];

  //check in selections
  if (familyUid in selections) {
    for (let inx = 0; inx < selections[familyUid].length; inx++) {
      if (
        selections[familyUid][inx].selectionState == 0 ||
        selections[familyUid][inx].selectionState == 1 ||
        selections[familyUid][inx].selectionState == 2
      ) {
        isExist = true;
        break;
      }
    }
  }

  //check in userSelectionCache
  for (const nodeID in userSelectionCache) {
    let famUidLocal = nodeID.split(":")[0];
    if (
      famUidLocal == familyUid &&
      (userSelectionCache[nodeID] == 0 ||
        userSelectionCache[nodeID] == 1 ||
        userSelectionCache[nodeID] == 2)
    ) {
      isExist = true;
      break;
    }
  }
  return isExist;
};
/**
 * This function will set the preference value for preference "G4B_AWC_ConfiguratorUserFavorites" on selection from UI
 * @param {data} data
 */

export let updatePrefForfavouriteGroup = function (data) {
  // console.log(data.eventData);

  getPrefForFavouriteGroup(data.eventData, data);
};

let getPrefForFavouriteGroup = function (eventData, data) {
  let inputData = {
    requestedPrefs: [
      {
        scope: "user",
        names: ["G4B_AWC_ConfiguratorUserFavorites"],
      },
    ],
  };

  soaSvc
    .post("Core-2007-01-Session", "getPreferences", inputData)
    .then(function (response) {
      if (response) {
        let newValues;
        if (eventData.favourite == true) {
          newValues = [];
          newValues = response.preferences[0].values;
          if (newValues.indexOf(eventData.prefValue) == -1) {
            newValues.push(eventData.prefValue);
          }
          // newValues.push(eventData.prefValue);
        } else if (eventData.favourite == false) {
          let nodeId = eventData.prefValue.split("/");
          newValues = [];
          newValues = response.preferences[0].values;
          let index;
          for (let i = 0; i < newValues.length; i++) {
            if (newValues[i].indexOf(nodeId[2]) != -1) {
              index = i;
              break;
            }
          }
          if (index != undefined) {
            newValues.splice(index, 1);
          }
        }
        setPreferenceValue(newValues, data);
      }
    });
};

function setPreferenceValue(newValues, data) {
  let inputData2 = {
    setPreferenceIn: [
      {
        location: {
          location: "user",
        },
        preferenceInputs: {
          preferenceName: "G4B_AWC_ConfiguratorUserFavorites",
          values: newValues,
        },
      },
    ],
  };

  let pref = JSON.parse(
    JSON.stringify(appCtxSvc.getCtx("prefG4B_AWC_ConfiguratorUserFavorites"))
  );
  pref.values = newValues;

  soaSvc
    .post(
      "Administration-2012-09-PreferenceManagement",
      "setPreferencesAtLocations",
      inputData2
    )
    .then(function () {
      setTimeout(function () {
        let responseInContext = appCtxSvc.getCtx("responseInContext");
        if (responseInContext != undefined) {
          if (data.eventData.favourite == true) {
            appCtxSvc.updateCtx("prefG4B_AWC_ConfiguratorUserFavorites", pref);
            updateGroupsWithFavourites(data, pref);
          } else if (data.eventData.favourite == false) {
            updateGroupsWithFavourites(data, pref);
            appCtxSvc.updateCtx("prefG4B_AWC_ConfiguratorUserFavorites", pref);
          }
        }
      }, 5);
    });
}

export let setPreferenceCheckboxValue = function (data) {
  if (data.isAssignedAndApplied.dbValue == true) {
    let inputData3 = {
      setPreferenceIn: [
        {
          location: {
            location: "user",
          },
          preferenceInputs: {
            preferenceName: "G4B_AWC_ConfiguratorCheckboxValue",
            values: ["true"],
          },
        },
      ],
    };

    soaSvc
      .post(
        "Administration-2012-09-PreferenceManagement",
        "setPreferencesAtLocations",
        inputData3
      )
      .then(function () {});
  } else if (data.isAssignedAndApplied.dbValue == false) {
    let inputData3 = {
      setPreferenceIn: [
        {
          location: {
            location: "user",
          },
          preferenceInputs: {
            preferenceName: "G4B_AWC_ConfiguratorCheckboxValue",
            values: ["false"],
          },
        },
      ],
    };

    soaSvc
      .post(
        "Administration-2012-09-PreferenceManagement",
        "setPreferencesAtLocations",
        inputData3
      )
      .then(function () {});
  }
};

let updateGroupsWithFavourites = function (data) {
  let _groupInContext = appCtxSvc.getCtx("groupsInContext");
  if (_groupInContext) {
    for (let i = 0; i < _groupInContext.length; i++) {
      if (
        _groupInContext[i].families.length > 0 &&
        data.eventData.optGroup == _groupInContext[i].optGroup
      ) {
        let prefG4B_AWC_ConfiguratorUserFavorites = appCtxSvc.getCtx(
          "prefG4B_AWC_ConfiguratorUserFavorites"
        );
        let favouriteList = [];
        let tmpFavFamily = {};
        for (
          let p = 0;
          p < prefG4B_AWC_ConfiguratorUserFavorites.values.length;
          p++
        ) {
          let array =
            prefG4B_AWC_ConfiguratorUserFavorites.values[p].split("/");
          if (
            (appCtxSvc.getCtx(
              "selected.props.awb0UnderlyingObjectType.dbValues[0]"
            ) == "G4B_CollaborativeDesign" &&
              array[0] ==
                appCtxSvc.getCtx(
                  "selected.props.awb0UnderlyingObject.dbValues[0]"
                )) ||
            (appCtxSvc.getCtx(
              "selected.props.awb0UnderlyingObjectType.dbValues[0]"
            ) == "Cpd0DesignSubsetElement" &&
              array[0] ==
                appCtxSvc.getCtx(
                  "selected.props.fgd0ModelObject.dbValues[0]"
                ) &&
              _groupInContext[i].groupDisplayName.indexOf(array[1]) > -1)
          ) {
            favouriteList.push(array[2]);
          }
        }

        //Traversing through families to update associated optionValues and favourite group

        if (_groupInContext[i].families[0].familyType != "favourite") {
          /**
           * Creating Favourite Family
           */

          let langCode = localeSvc.getLanguageCode();
          if (langCode == "de") {
            tmpFavFamily.familyDisplayName = "Favoriten";
            tmpFavFamily.familyDesc = "Favoriten";
          } else if (langCode == "en") {
            tmpFavFamily.familyDisplayName = "Favourites";
            tmpFavFamily.familyDesc = "Favourites";
          }
          tmpFavFamily.isFiltered = true;
          tmpFavFamily.familyType = "favourite";
          tmpFavFamily.values = [];
          _groupInContext[i].families.unshift(tmpFavFamily);
        }

        if (_groupInContext[i].families[0].values.length > 0) {
          _groupInContext[i].families[0].values.splice(
            0,
            _groupInContext[i].families[0].values.length
          );
        }
        for (let j = 0; j < favouriteList.length; j++) {
          let splitArray = favouriteList[j].split(":");
          for (let k = 1; k < _groupInContext[i].families.length; k++) {
            if (splitArray[0] == _groupInContext[i].families[k].familyStr) {
              for (
                let l = 0;
                l < _groupInContext[i].families[k].values.length;
                l++
              ) {
                if (
                  _groupInContext[i].families[k].values[l].optValueStr ==
                  favouriteList[j]
                ) {
                  _groupInContext[i].families[k].values[l].isFavourite = true;
                  let object = JSON.parse(
                    JSON.stringify(_groupInContext[i].families[k].values[l])
                  );
                  _groupInContext[i].families[0].values.push(object);
                  let length = _groupInContext[i].families[0].values.length;
                  _groupInContext[i].families[0].values[length - 1].index =
                    (length - 1) % 2;
                  _groupInContext[i].families[0].values[
                    length - 1
                  ].underlyingfamilyType =
                    _groupInContext[i].families[k].familyType;
                  _groupInContext[i].families[0].values[
                    length - 1
                  ].underlyingfamilySingleSelect =
                    _groupInContext[i].families[k].singleSelect;

                  //Add for system selctions
                  if (
                    _groupInContext[i].families[0].values[length - 1]
                      .selectionState != 0 &&
                    _groupInContext[i].families[0].values[length - 1]
                      .selectionState != 1 &&
                    _groupInContext[i].families[0].values[length - 1]
                      .selectionState != 2
                  ) {
                    Object.defineProperty(
                      _groupInContext[i].families[0].values[length - 1],
                      "isSystemSelection",
                      {
                        value: true,
                        writable: false,
                      }
                    );
                  } else {
                    Object.defineProperty(
                      _groupInContext[i].families[0].values[length - 1],
                      "isSystemSelection",
                      {
                        value: false,
                        writable: false,
                      }
                    );
                  }
                }
              }
            }
            if (data.eventData.favourite == false) {
              //If option is unfavourite
              let nodeID = data.eventData.prefValue.split("/");
              if (
                nodeID[2].split(":")[0] ==
                _groupInContext[i].families[k].familyStr
              ) {
                for (
                  let l = 0;
                  l < _groupInContext[i].families[k].values.length;
                  l++
                ) {
                  if (
                    _groupInContext[i].families[k].values[l].optValueStr ==
                    nodeID[2]
                  ) {
                    _groupInContext[i].families[k].values[
                      l
                    ].isFavourite = false;
                    //Removing un-favorited value from favorites family
                    for (
                      let jnx = 0;
                      jnx < _groupInContext[i].families[0].values.length;
                      jnx++
                    ) {
                      if (
                        _groupInContext[i].families[0].values[jnx]
                          .optValueStr == nodeID[2]
                      ) {
                        _groupInContext[i].families[0].values.splice(jnx, 1);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    appCtxSvc.updateCtx("groupsInContext", _groupInContext);
  }
};

/**
 * Builds the violation string.
 *
 * @param {String} featureId - UID of a feature
 * @param {ObjectArray} labels - Labels to be shown on feature objects like violations etc.
 *
 * @returns {ObjectArray} The array of values for option family
 */

function buildViolationString(featureId, labels) {
  let violationInfo = {};
  let violationMessage = "";
  let violationIds = [];
  let violationObj;

  if (labels !== undefined) {
    let nodeMap = labels.violationMap[0].nodeMap;
    if (nodeMap !== undefined) {
      violationObj = nodeMap[featureId];
      if (violationObj !== undefined) {
        violationIds = violationObj[0].props.violationIDs;
      }
    }
  }

  if (violationIds.length > 0) {
    for (let i = 0; i < violationIds.length; i++) {
      let violationId = violationIds[i];
      let violationObj = labels.violations[0].nodeMap[violationId];

      if (violationObj[0].displayName.indexOf("Paket") > -1) {
        let formattedViolation1 = violationObj[0].displayName.split(",");
        let index = formattedViolation1[1].indexOf(" ");
        let formattedViolation2 = formattedViolation1[1].slice(index);
        violationMessage =
          violationMessage +
          (i + 1) +
          ". " +
          formattedViolation1[0] +
          formattedViolation2 +
          "\n";
      } else if (violationObj[0].displayName.indexOf("Zeitschreibe") > -1) {
        violationMessage = violationMessage + "";
      } else {
        violationMessage =
          violationMessage +
          (i + 1) +
          ". " +
          violationObj[0].displayName +
          "\n";
      }

      if (i === 0) {
        // Server will always contain first violation id with highest severity.
        // We can rely on it to decide the icon to be shown for violation.
        violationInfo.violationSeverity = violationObj[0].props.serverity[0];
      }
    }
    if (violationMessage.length > 0) {
      violationInfo.violationMessage = violationMessage.slice(0, -1);
    }
  }
  return violationInfo;
}

/**
 *
 * Code by : Dhiraj
 *
 */

export let g4bGetSelectionForVariantContext = function () {
  let multiSelections = appCtxSvc.getCtx("mselected");
  if (multiSelections && multiSelections.length > 1) {
    // return last selected object in case of multiple selections
    return multiSelections[multiSelections.length - 1];
  } else {
    return appCtxSvc.getCtx("selected");
  }
};

/**
 * Returns created variant rule from SOA response
 *
 * @param {Object} response the response from the variant configuration view SOA
 *
 * @returns {Object} Created variant rule.
 */
export let g4bGetVariantRuleVMO = function (response) {
  if (response.ServiceData.created) {
    return viewModelObjSvc.createViewModelObject(
      response.ServiceData.created[0]
    );
  }
};

/**
 * Returns created variant rule from SOA response
 *
 * @param {Object} response the response from the variant configuration view SOA
 *
 * @returns {Object} Created variant rule.
 */
export let g4bGetCreatedVariantRule = function (response) {
  if (response.ServiceData.created) {
    let variantRuleUID = response.ServiceData.created[0];

    if (appCtxSvc.getCtx("saveVariantName") == undefined) {
      appCtxSvc.registerCtx(
        "saveVariantName",
        response.ServiceData.modelObjects[variantRuleUID].props.object_name
          .dbValues[0]
      );
    } else {
      appCtxSvc.updateCtx(
        "saveVariantName",
        response.ServiceData.modelObjects[variantRuleUID].props.object_name
          .dbValues[0]
      );
    }

    return response.ServiceData.modelObjects[variantRuleUID];
  }
};

/**
 * This api will return input required for createRelateAndSubmitObjects for the required variant rule.
 *
 * @param {object} data the view model data object
 * @return {inputs} create input.
 */
export let g4bGetConfigCreateInput = function (data) {
  return addObjectUtils.getCreateInput(data);
};

/** Ajinkya start */

export let toggleConfigure = function () {
  let context = appCtxSvc.getCtx("isAppliedVariantConfigurationModified");
  let isAppliedVariantConfigurationModified_Backup = appCtxSvc.getCtx(
    "isAppliedVariantConfigurationModified_Backup"
  );
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");

  if (context == undefined) {
    appCtxSvc.registerCtx("isAppliedVariantConfigurationModified", false);
  } else if (
    isAppliedVariantConfigurationModified_Backup == true &&
    variantConfigContext != undefined
  ) {
    //GEPA-7721
    appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", true);
    appCtxSvc.unRegisterCtx("isAppliedVariantConfigurationModified_Backup");
  } else {
    appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", false);
  }
  //End:GEPA-7721
};

/**
 *Toggle configuration button to enable or disable according to various case on selection of optionValue/SVR etc.
 */

export let isModifiedSelectionForGuided = function (response) {
  let ctxSelctionList, responseSelctionList, index1, index2;
  let flag = false;
  let variantConfigCtx = appCtxSvc.getCtx("variantConfigContext");

  //Switch modified flag to true in case a feature is clicked in CFSC
  if (
    appCtxSvc.getCtx("isFeatureClickedCFSC") != undefined &&
    appCtxSvc.getCtx("isFeatureClickedCFSC") == true
  ) {
    appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", true);
    appCtxSvc.unRegisterCtx("isFeatureClickedCFSC");
  }

  //Skip flag evaluation in case configuration mode was switched
  else if (variantConfigCtx.switchingToGuidedMode == true) {
    variantConfigCtx.switchingToGuidedMode = false;
  }

  //Skip flag evaluation in case group expansion is performed inside CFSC
  else if (
    variantConfigCtx.isExpandedGroupAction != undefined &&
    variantConfigCtx.isExpandedGroupAction == true
  ) {
    delete variantConfigCtx.isExpandedGroupAction;
  }

  //Skip flag evaluation in case Reset configuration is performed in CFSC
  else if (variantConfigCtx.resetConfigurationStart) {
    appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", false);
    delete variantConfigCtx.resetConfigurationStart;
  } else if (
    response.selections.length > 0 &&
    response.selections[0].selections != undefined
  ) {
    let setSelection = Object.keys(response.selections[0].selections).map(
      function (e) {
        return response.selections[0].selections[e];
      }
    );
    if (
      appCtxSvc.getCtx("isAppliedVariantConfiguration") != undefined &&
      appCtxSvc.getCtx("isAppliedVariantConfiguration") == true &&
      appCtxSvc.getCtx("isAppliedVariantConfigurationModified") == false
    ) {
      //let isAppliedVariantConfiguration = appCtxSvc.getCtx("isAppliedVariantConfiguration");

      ctxSelctionList = appCtxSvc.getCtx("appliedVariantConfiguration");
      responseSelctionList = [];
      responseSelctionList = setSelection;

      if (appCtxSvc.getCtx("appliedVariantConfiguration") != undefined) {
        if (ctxSelctionList.length == responseSelctionList.length) {
          for (index1 = 0; index1 < responseSelctionList.length; index1++) {
            for (index2 = 0; index2 < ctxSelctionList.length; index2++) {
              for (
                let index3 = 0;
                index3 < responseSelctionList[index2].length;
                index3++
              ) {
                if (
                  ctxSelctionList[index1] ==
                  responseSelctionList[index2][index3].nodeID
                ) {
                  flag = false;
                  break;
                } else {
                  flag = true;
                }
              }
            }
            if (flag == true) {
              appCtxSvc.updateCtx(
                "isAppliedVariantConfigurationModified",
                true
              );
              break;
            }
          }
        } else {
          flag = true;
        }
        if (flag == true) {
          appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", true);
        }
      }
    } else {
      ctxSelctionList = appCtxSvc.getCtx("appliedVariantConfiguration");
      responseSelctionList = [];
      responseSelctionList = setSelection;

      //For TaKo prototypes, reset configuration also retains the TYP along with time-slice. Hence 'Configure' button should be active after reset config.
      if (
        appCtxSvc.getCtx(
          "aceActiveContext.context.openedElement.props.awb0UnderlyingObjectType.dbValues[0]"
        ) == "G4B_ProtTypeSpecRevision" &&
        responseSelctionList.length == 2 &&
        appCtxSvc.getCtx(
          "aceActiveContext.context.productContextInfo.props.awb0CurrentVariantRule.dbValues[0]"
        ) == null
      ) {
        appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", true);
        return;
      }

      if (
        appCtxSvc.getCtx("appliedVariantConfiguration") != undefined &&
        (appCtxSvc.getCtx("isApplyingNewSvr") == false ||
          appCtxSvc.getCtx("isApplyingNewSvr") == undefined) &&
        (appCtxSvc.getCtx("isApplyingNewConfigurationCFSC") == false ||
          appCtxSvc.getCtx("isApplyingNewConfigurationCFSC") == undefined)
      ) {
        if (ctxSelctionList.length == responseSelctionList.length) {
          for (index1 = 0; index1 < responseSelctionList.length; index1++) {
            for (index2 = 0; index2 < ctxSelctionList.length; index2++) {
              for (
                let index3 = 0;
                index3 < responseSelctionList[index2].length;
                index3++
              ) {
                if (
                  ctxSelctionList[index1] ==
                  responseSelctionList[index2][index3].nodeID
                ) {
                  flag = false;
                  break;
                } else {
                  flag = true;
                }
              }
            }
            if (flag == true) {
              appCtxSvc.updateCtx(
                "isAppliedVariantConfigurationModified",
                true
              );
              break;
            }
          }
        } else if (
          responseSelctionList.length > 1 &&
          appCtxSvc.getCtx("variantConfigContext.guidedMode") == true
        ) {
          flag = true;
        }

        appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", flag);
      } else {
        if (
          appCtxSvc.getCtx(
            "aceActiveContext.context.productContextInfo.props.awb0CurrentVariantRule.dbValues[0]"
          ) != null &&
          (appCtxSvc.getCtx("isApplyingNewSvr") == true ||
            appCtxSvc.getCtx("isApplyingNewConfigurationCFSC") == true)
        ) {
          appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", false);
          if (appCtxSvc.getCtx("isApplyingNewSvr") != undefined) {
            appCtxSvc.unRegisterCtx("isApplyingNewSvr");
          }
          if (appCtxSvc.getCtx("isApplyingNewConfigurationCFSC") != undefined) {
            appCtxSvc.unRegisterCtx("isApplyingNewConfigurationCFSC");
          }
        } else if (
          appCtxSvc.getCtx("variantConfigContext.family") != undefined
        ) {
          //NOTE: Added this condition for checking if new selection is made to the Applied SVR/Not.
          appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", true);
        }
      }
      // else if(responseSelctionList.length>0) {
      //     appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", true);

      // }
    }
  }
};

/**
 * This function checks if the current configuartion is valid, complete, Incomplete or Invalid
 */
export let isConfigurationValid = function (response) {
  let criteriaStatus = null;
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");

  if (response.responseInfo.criteriaStatus) {
    variantConfigContext.criteriaStatus =
      response.responseInfo.criteriaStatus[0];
    if (
      response.responseInfo.criteriaStatus &&
      response.responseInfo.criteriaStatus[0] == "ValidAndInComplete"
    ) {
      criteriaStatus = 0;
    }
    if (
      response.responseInfo.criteriaStatus &&
      response.responseInfo.criteriaStatus[0] == "ValidAndComplete"
    ) {
      criteriaStatus = 1;
    }
    if (
      response.responseInfo.criteriaStatus &&
      response.responseInfo.criteriaStatus[0] == "InValid"
    ) {
      criteriaStatus = 2;
    }
  }
  //if(response.responseInfo.isValid && response.responseInfo.isValid[0] == 'false'){
  //    criteriaStatus = 2;
  //}
  if (
    response.responseInfo.isCompletedWithError &&
    response.responseInfo.isCompletedWithError[0] == "true"
  ) {
    criteriaStatus = 2;
  }
  return criteriaStatus;
};

export let isModifiedSelectionForUnGuided = function () {
  let flag = false;

  if (
    (appCtxSvc.getCtx("isCustomConfigurationApplied") != undefined &&
      appCtxSvc.getCtx("isCustomConfigurationApplied") == true) ||
    (appCtxSvc.getCtx("isAppliedVariantConfiguration") != undefined &&
      appCtxSvc.getCtx("isAppliedVariantConfiguration") == true) ||
    appCtxSvc.getCtx("isAppliedVariantConfigurationModified") == false
  ) {
    let guidedMode = appCtxSvc.getCtx("variantConfigContext.guidedMode");

    if (guidedMode != undefined && guidedMode == false) {
      let userSelection = appCtxSvc.getCtx(
        "variantConfigContext.selections[0].selections"
      );

      let userSelectionsoptionValue = Object.keys(userSelection).map(function (
        e
      ) {
        return userSelection[e];
      });
      let ctxSelctionList = appCtxSvc.getCtx("appliedVariantConfiguration");
      let responseSelctionList = [];
      responseSelctionList = userSelectionsoptionValue;

      if (appCtxSvc.getCtx("appliedVariantConfiguration") != undefined) {
        if (ctxSelctionList.length == responseSelctionList.length) {
          for (let index1 = 0; index1 < responseSelctionList.length; index1++) {
            if (guidedMode == false) {
              let group = responseSelctionList[index1];

              for (let index3 = 0; index3 < group.length; index3++) {
                flag = false;
                for (
                  let index2 = 0;
                  index2 < ctxSelctionList.length;
                  index2++
                ) {
                  if (
                    ctxSelctionList[index2] ==
                    responseSelctionList[index1][index3].nodeID
                  ) {
                    flag = true;
                    break;
                  }
                } // End of For 3

                if (flag == false) {
                  break;
                }
              } // End of For 2
              if (flag == false) {
                break;
              }
            }
            if (flag == false) {
              appCtxSvc.updateCtx(
                "isAppliedVariantConfigurationModified",
                true
              );
              break;
            }
          } // End of For 1
        } else {
          flag = false;
        }
        console.log("Final Verification flagSelection is Modified ", flag);
        if (flag == false) {
          appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", true);
        }
      } else {
        appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", true);
      }
    }
  }
};

export let setAppliedSelection = function (response) {
  //Register flag to signal that SVR is being applied
  appCtxSvc.registerCtx("isApplyingNewSvr", true);

  if (response != undefined) {
    let guidedMode = appCtxSvc.getCtx("variantConfigContext.guidedMode");

    let userSelectionsoptionValue = Object.keys(response).map(function (e) {
      return response[e];
    });

    let selectionList = [];
    for (let index = 0; index < userSelectionsoptionValue.length; index++) {
      if (guidedMode == true) {
        selectionList.push(userSelectionsoptionValue[index][0].nodeID);
      } else {
        let group = userSelectionsoptionValue[index];
        for (let index2 = 0; index2 < group.length; index2++) {
          selectionList.push(userSelectionsoptionValue[index][index2].nodeID);
        }
      }
    }

    if (appCtxSvc.getCtx("appliedVariantConfiguration") != undefined) {
      appCtxSvc.registerCtx("appliedVariantConfiguration", selectionList);
    } else {
      appCtxSvc.updateCtx("appliedVariantConfiguration", selectionList);
    }

    if (appCtxSvc.getCtx("isAppliedVariantConfiguration") != undefined) {
      appCtxSvc.registerCtx("isAppliedVariantConfiguration", true);
    } else {
      appCtxSvc.updateCtx("isAppliedVariantConfiguration", true);
    }

    if (
      appCtxSvc.getCtx("isAppliedVariantConfigurationModified") != undefined
    ) {
      appCtxSvc.registerCtx("isAppliedVariantConfigurationModified", false);
    } else {
      appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", false);
    }
  }
};

export let setCustomSelection = function () {
  let guidedMode = appCtxSvc.getCtx("variantConfigContext.guidedMode");

  if (appCtxSvc.getCtx("savedGuidedMode") != undefined) {
    console.log("in if guidedMode");
    appCtxSvc.registerCtx("savedGuidedMode", guidedMode);
  } else {
    console.log("in else guidedMode");

    appCtxSvc.updateCtx("savedGuidedMode", guidedMode);
  }

  let userSelection = appCtxSvc.getCtx(
    "variantConfigContext.selections[0].selections"
  );

  let userSelectionsoptionValue = Object.keys(userSelection).map(function (e) {
    return userSelection[e];
  });

  let selectionList = [];
  for (let index = 0; index < userSelectionsoptionValue.length; index++) {
    if (guidedMode == true) {
      selectionList.push(userSelectionsoptionValue[index][0].nodeID);
    } else {
      let group = userSelectionsoptionValue[index];
      for (let index2 = 0; index2 < group.length; index2++) {
        selectionList.push(userSelectionsoptionValue[index][index2].nodeID);
      }
    }
  }

  if (appCtxSvc.getCtx("appliedVariantConfiguration") != undefined) {
    appCtxSvc.registerCtx("appliedVariantConfiguration", selectionList);
  } else {
    appCtxSvc.updateCtx("appliedVariantConfiguration", selectionList);
  }

  if (appCtxSvc.getCtx("isCustomConfigurationApplied") != undefined) {
    appCtxSvc.registerCtx("isCustomConfigurationApplied", true);
  } else {
    appCtxSvc.updateCtx("isCustomConfigurationApplied", true);
  }

  if (appCtxSvc.getCtx("isAppliedVariantConfigurationModified") != undefined) {
    appCtxSvc.registerCtx("isAppliedVariantConfigurationModified", false);
  } else {
    appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", false);
  }
};

export let removeModifyBasedOnSelection = function () {
  if (appCtxSvc.getCtx("isAppliedVariantConfigurationModified") != undefined) {
    appCtxSvc.registerCtx("isAppliedVariantConfigurationModified", false);
  } else {
    appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", false);
  }
};
/** Ajinkya  End */

export let subscribeToPartialCtxChange = function (data) {
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");

  //clearselection - Ts option to retain
  if (data.storedContext) {
    appCtxSvc.updateCtx("variantConfigContext", data.storedContext);
    delete data.storedContext;
  }

  //Clear unwanted context variable if reset configuration is initiated
  else if (variantConfigContext.resetConfigurationStart) {
    if (appCtxSvc.getCtx("appliedVariantConfiguration") != undefined) {
      appCtxSvc.unRegisterCtx("appliedVariantConfiguration");
    }

    if (appCtxSvc.getCtx("isAppliedVariantConfiguration") != undefined) {
      appCtxSvc.unRegisterCtx("isAppliedVariantConfiguration", true);
    }
  }

  //saved variant rule selections
  else if (!data.storedContext) {
    let selections = appCtxSvc.getCtx("variantConfigContext.selections");
    if (
      appCtxSvc.getCtx("mselected[0].type") === "VariantRule" &&
      appCtxSvc.getCtx("currentRuleSelections") != undefined
    ) {
      selections = appCtxSvc.getCtx("currentRuleSelections");
    }
    //Remove Values from selections which have incomplete nodeId i.e family level selections
    let userSelections = JSON.parse(JSON.stringify(selections));

    //Sarwan: Commented 27-Jun-2023. Added sanitise function
    /*for (let key in userSelections[0].selections) {
            for (let inx = 0; userSelections[0].selections[key] != undefined && inx < userSelections[0].selections[key].length; inx++) {
                if(userSelections[0].selections[key][inx].nodeID.split(':')[1].length == 0){
                    userSelections[0].selections[key].splice(inx, 1);
                    if (userSelections[0].selections[key].length == 0) {
                        delete userSelections[0].selections[key];
                    }
                    else{
                        inx--;
                    }
                }
    
            }
        }*/

    userSelections = sanitiseVariantSelections(userSelections);

    //Remove any non-user selections from selections for guided mode
    if (appCtxSvc.getCtx("variantConfigContext.guidedMode") == true) {
      userSelections[0].selections = {};

      for (let node in selections[0].selections) {
        for (let i = 0; i < selections[0].selections[node].length; i++) {
          let arr = selections[0].selections[node][i].nodeID.split(":"); //adding this statement to remove all nodeids without feature id in it
          if (arr[1] != "") {
            if (
              selections[0].selections[node][i].selectionState == 1 ||
              selections[0].selections[node][i].selectionState == 2
            ) {
              if (userSelections[0].selections[node]) {
                userSelections[0].selections[node].push(
                  selections[0].selections[node][i]
                );
              } else {
                userSelections[0].selections[node] = [
                  selections[0].selections[node][i],
                ];
              }
            }
          }
        }
      }
    }

    variantConfigContext.selections = userSelections;
    appCtxSvc.updateCtx("variantConfigContext", variantConfigContext);
    appCtxSvc.unRegisterCtx("currentRuleSelections");
  }

  let eventSubscription = eventBus.subscribe(
    "productContextChangedEvent",
    function () {
      appCtxSvc.updateCtx("isAppliedVariantConfigurationModified", false);
      eventBus.publish("G4B_CFG_VariantDataView.callselectValue");
      eventBus.unsubscribe(eventSubscription);
    }
  );
};

/**
 * Function to start the Dispatcher Request for validation of all SVRs within the Product Line
 */
export let startSVRValidationDispatcher = function (ctx) {
  let createDispRqstInput = null;
  let productLineUid =
    appCtxSvc.getCtx("occmgmtContext").topElement.props.awb0UnderlyingObject
      .dbValues[0];
  let productLineObj = cdm.getObject(productLineUid);
  let getSecondaryObjsInput = {
    primaryObjects: [productLineObj],
    pref: {
      expItemRev: false,
      returnRelations: false,
      info: [
        {
          relationTypeName: "Mdl0HasVarConfiguratorCxt",
          otherSideObjectTypes: ["Cfg0ProductItem"],
        },
      ],
    },
  };

  soaSvc
    .post(
      "Core-2007-09-DataManagement",
      "expandGRMRelationsForPrimary",
      getSecondaryObjsInput
    )
    .then(function (response) {
      productLineObj = cdm.getObject(productLineUid);

      createDispRqstInput = {
        inputs: [
          {
            providerName: "GEPARD",
            serviceName: "G4B_GEP_Validate_Saved_Variant_Rules",
            type: "",
            primaryObjects: [
              {
                type: "Cfg0ProductItem",
                uid: response.output[0].relationshipData[0]
                  .relationshipObjects[0].otherSideObject.uid,
              },
            ],
            secondaryObjects: [],
            priority: 2,
            startTime: "",
            endTime: "",
            interval: 0,
            keyValueArgs: [
              {
                key: "pc_id",
                value:
                  response.output[0].relationshipData[0].relationshipObjects[0]
                    .otherSideObject.uid,
              },
              {
                key: "exclude_unofficial",
                value: "false",
              },
              {
                key: "exclude_archived",
                value: "true",
              },
              {
                key: "solve_profile",
                value: "Cfg0Order",
              },
              {
                key: "logfile",
                value: "validation_report.log",
              },
            ],
          },
        ],
      };

      soaSvc
        .post(
          "Core-2008-06-DispatcherManagement",
          "createDispatcherRequest",
          createDispRqstInput
        )
        .then(function (response) {
          console.log("A SVR validation dispatcher request for Prod Conf Ctx was created. ");
          /*console.log(
            "A SVR validation dispatcher request for Prod Conf Ctx " +
              response.output[0].relationshipData[0].relationshipObjects[0]
                .otherSideObject.uid +
              " was created."
          ); */
        });
    });
};

export let getSelectionsForConfigure = function () {
  let variantConfigCtx = appCtxSvc.getCtx("variantConfigContext");
  let isGuidedMode = variantConfigCtx.guidedMode;

  let selectionsArr = variantConfigCtx.selections[0].selections;
  if (!isGuidedMode) {
    for (let key in selectionsArr) {
      for (let inx = 0; inx < selectionsArr[key].length; inx++) {
        if (selectionsArr[key][inx].selectionState == 9) {
          selectionsArr[key][inx].selectionState = 1;
        }
        if (selectionsArr[key][inx].selectionState == 10) {
          selectionsArr[key][inx].selectionState = 2;
        }
      }
    }
  }
  return variantConfigCtx.selections;
};

export let refreshUserSummaryAfterConfigInManualMode = function (data) {
  if (appCtxSvc.getCtx("variantConfigContext.guidedMode") == false) {
    let eventSubscription = eventBus.subscribe(
      "populateConfigView",
      function () {
        /*let isUserSummaryAndSelectionEqual = checkIfUserSummaryAndSelectionEqual(data);
            if(!isUserSummaryAndSelectionEqual){
                eventBus.publish('refreshUserSelectionsForApplyConfigInManualMode.Event');
            }*/
        eventBus.publish(
          "refreshUserSelectionsForApplyConfigInManualMode.Event"
        );
        eventBus.unsubscribe(eventSubscription);
      }
    );
  }
};

let checkIfUserSummaryAndSelectionEqual = function (data) {
  let deferred = AwPromiseService.instance.defer();
  let isEqual = true;

  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
  let selections = variantConfigContext.selections[0].selections;
  let userSummary = data.userSummary;
  let userSummaryNodes = [];
  let selectionNodes = [];

  for (let inx = 0; inx < userSummary.length; inx++) {
    if (
      userSummary[inx].valueSelectionState == 1 ||
      userSummary[inx].valueSelectionState == 2
    ) {
      userSummaryNodes.push(userSummary[inx].nodeID);
    }
  }
  userSummaryNodes = userSummaryNodes.sort();

  for (let key in selections) {
    for (let jnx = 0; jnx < selections[key].length; jnx++) {
      if (
        selections[key][jnx].selectionState == 1 ||
        (selections[key][jnx].selectionState == 2 &&
          selections[key][jnx].nodeID.split(":")[1] != "")
      ) {
        selectionNodes.push(selections[key][jnx].nodeID);
      }
    }
  }

  selectionNodes = selectionNodes.sort();
  let diffSelectionNodes = $(selectionNodes).not(userSummaryNodes).get();

  let diffFamiliesUids = [];

  for (let inx = 0; inx < diffSelectionNodes.length; inx++) {
    diffFamiliesUids.push({ uid: diffSelectionNodes[inx].split(":")[0] });
  }

  if (diffFamiliesUids.length == 0) {
    isEqual = _.isEqual(userSummaryNodes, selectionNodes);
    deferred.resolve(isEqual);
  } else {
    dataManagementSvc
      .getPropertiesUnchecked(diffFamiliesUids, ["object_name"])
      .then(function (response) {
        for (let key in response.modelObjects) {
          if (
            response.modelObjects[key].props.object_name.dbValues[0] !=
              "_TYP" &&
            response.modelObjects[key].props.object_name.dbValues[0] !=
              "TYP_MOTOR"
          ) {
            isEqual = false;
            deferred.resolve(isEqual); 
            break;
          }
        }
      });
  }

  return deferred.promise;
};

export let updateModelConfigContext = function () {
  let modelConfigContext = appCtxSvc.getCtx(
    "occmgmtContext.productContextInfo.props.fgf0ModelConfigContext.dbValues[0]"
  );
  appCtxSvc.updateCtx(
    "variantConfigContext.savedModelConfigContext",
    modelConfigContext
  );
};

/**
 * @param {String} optionName Name of the SLP feature to search
 * @param {Array} allGroups Array of all family groups
 * @returns foundOptionFamilyWSObject
 */
let checkIfOptionPresentInSLPGroup = function (optionName, allGroups) {
  let foundOptionFamilyWSObject = {};
  for (let p = 0; p < allGroups.length; p++) {
    if (allGroups[p].scopeObj.displayName.indexOf("SLP_") > -1) {
      let families = allGroups[p].families;
      for (let q = 0; q < families.length; q++) {
        let optionValues = families[q].features;
        for (let r = 0; r < optionValues.length; r++) {
          let optionWsObject = cdm.getObject(
            optionValues[r].featureObject.sourceUid
          );
          if (optionName == optionWsObject.props.object_name.dbValues[0]) {
            foundOptionFamilyWSObject = {
              featureObject: optionWsObject,
              familyObject: cdm.getObject(families[q].familyObj.sourceUid),
            };
            break;
          }
        }
      }
    }
  }
  return foundOptionFamilyWSObject;
};

// Assign or apply configurarion With/without PWA refresh
export let saveApplyUpdatedConfiguration = function (
  data,
  clearVariantConfigFlag
) {
  //Clear if recipe/config delay preferences are defined
  if (appCtxSvc.getCtx("aceActiveContext.context.updateRecipeOnly")) {
    delete appCtxSvc.getCtx(
      "aceActiveContext.context.requestPref.updateRecipeOnly"
    );
    delete appCtxSvc.getCtx("aceActiveContext.context.updatedRecipe");
  }
  if (
    appCtxSvc.getCtx(
      "aceActiveContext.context.requestPref.updateConfigurationOnly"
    )
  ) {
    delete appCtxSvc.getCtx(
      "aceActiveContext.context.requestPref.updateConfigurationOnly"
    );
  }
  // set the updateConfigurationOnly to communicate server that it is delayed configuration mode
  if (
    data.isAssignedAndApplied.dbValue == false &&
    appCtxSvc.getCtx("aceActiveContext.context.rootElement.type") ==
      "Fgd0DesignSubsetElement"
  ) {
    appCtxSvc.updatePartialCtx(
      "aceActiveContext.context.requestPref.updateConfigurationOnly",
      appCtxSvc.getCtx(
        "aceActiveContext.context.rootElement.props.awb0UnderlyingObject.dbValues[0]"
      )
    );
  }

  let selections = appCtxSvc.getCtx("aceActiveContext.context.rootElement");
  let newProductUID = cdm.getObject(
    occmgmtUtils.getProductContextForProvidedObject(selections)
  ).props.awb0Product.dbValues[0];

  let treeLoadInput = {
    parentElement: newProductUID,
    startChildNdx: 0,
    displayMode: "Tree",
    addAfter: true,
  };

  //Prepare config ctx to update variant rule
  let configCtx = {
    r_uid: appCtxSvc.getCtx(
      "aceActiveContext.context.productContextInfo.props.awb0CurrentRevRule.dbValues[0]"
    ),
    var_uids: [""],
    iro_uid: appCtxSvc.getCtx(
      "aceActiveContext.context.productContextInfo.props.awb0Product.dbValues[0]"
    ),
    de: appCtxSvc.getCtx(
      "aceActiveContext.context.productContextInfo.props.awb0EffDate.dbValues[0]"
    ),
    ue: appCtxSvc.getCtx(
      "aceActiveContext.context.productContextInfo.props.awb0EffUnitNo.dbValues[0]"
    ),
    ei_uid: appCtxSvc.getCtx(
      "aceActiveContext.context.productContextInfo.props.awb0EffEndItem.dbValues[0]"
    ),
    startDate: appCtxSvc.getCtx(
      "aceActiveContext.context.productContextInfo.props.awb0StartEffDates.dbValues[0]"
    ),
    endDate: appCtxSvc.getCtx(
      "aceActiveContext.context.productContextInfo.props.awb0EndEffDates.dbValues[0]"
    ),
    fromUnit: appCtxSvc.getCtx(
      "aceActiveContext.context.productContextInfo.props.awb0StartEffUnits.dbValues[0]"
    ),
    toUnit: appCtxSvc.getCtx(
      "aceActiveContext.context.productContextInfo.props.awb0EndEffUnits.dbValues[0]"
    ),
  };
  if (!clearVariantConfigFlag) {
    configCtx.var_uids = [
      appCtxSvc.getCtx("variantConfigContext.customVariantRule"),
    ];
  }

  appCtxSvc.updateCtx("aceActiveContext.context.configContext", configCtx);
  ////

  let context = _.get(
    appCtxSvc.getCtx(),
    appCtxSvc.getCtx("aceActiveContext.key")
  );
  let soaInput = occmgmtGetSvc.getDefaultSoaInput();
  soaInput.inputData.focusOccurrenceInput.element = appCtxSvc.getCtx(
    "occmgmtContext.rootElement"
  );

  return occmgmtGetSvc
    .getOccurrences(treeLoadInput, soaInput, context)
    .then(function (response) {
      appCtxSvc.updateCtx("aceActiveContext.context.configContext", {});

      if (
        data.isAssignedAndApplied.dbValue == false &&
        appCtxSvc.getCtx("aceActiveContext.context.rootElement.type") ==
          "Fgd0DesignSubsetElement"
      ) {
        let subsetUid = appCtxSvc.getCtx(
          "aceActiveContext.context.rootElement.uid"
        );
        let subsetPciUid = appCtxSvc.getCtx(
          "aceActiveContext.context.productContextInfo.uid"
        );
        let newSubsetPciUid = response.parentProductContext.uid;

        let pciMap = appCtxSvc.getCtx(
          "aceActiveContext.context.elementToPCIMap"
        );
        if (
          cdm.isValidObjectUid(newSubsetPciUid) &&
          subsetPciUid != newSubsetPciUid
        ) {
          //update the elementToPCIMap in context
          for (let uid in pciMap) {
            if (uid == subsetUid) {
              pciMap[uid] = newSubsetPciUid;
              break;
            }
          }
          //appCtxSvc.updatePartialCtx(  appCtxSvc.ctx.aceActiveContext.key + '.productContextInfo', cdm.getObject( newSubsetPciUid ) );
          appCtxSvc.updateCtx(
            "aceActiveContext.context.productContextInfo",
            cdm.getObject(newSubsetPciUid)
          );
        }
        /*let newState = {
                "pci_uid": newSubsetPciUid
            }
            ctxStateMgmtService.syncContextState( "occmgmtContext", newState);*/
      }

      eventBus.publish("occDataLoadedEvent");

      //Refresh the PWA only if in apply mode for subset or inside CD/SubsetDefn
      if (
        (data.isAssignedAndApplied.dbValue == true &&
          appCtxSvc.getCtx("(aceActiveContext.context.rootElement.type") ==
            "Fgd0DesignSubsetElement") ||
        appCtxSvc.getCtx("aceActiveContext.context.rootElement.type") ==
          "Fgf0ApplicationModel"
      ) {
        eventBus.publish("acePwa.reset", {
          retainTreeExpansionStates: true,
        });
        if (
          appCtxSvc.getCtx("aceActiveContext.context.rootElement.type") ==
          "Fgd0DesignSubsetElement"
        ) {
          /**TCVis Appconnect issue Publish recipe updated event to trigger PWA event */
          eventBus.publish("occmgmt4gf.recipeUpdated");
        }
        //eventBus.publish('occTreeTable.plTable.reload');
      }
      if (appCtxSvc.getCtx("userSession.props.group_name.dbValue") === "KovA") {
        eventBus.publish("acePwa.reset", {
          retainTreeExpansionStates: true,
        });
        eventBus.publish("occTreeTable.plTable.clientRefresh");
      }

      if (clearVariantConfigFlag) {
        eventBus.publish("G4B_CFG_VariantData.clearUserSummary");
      }
      //Clear if recipe/config delay pref is active
      if (appCtxSvc.getCtx("aceActiveContext.context.updateRecipeOnly")) {
        delete appCtxSvc.getCtx(
          "aceActiveContext.context.requestPref.updateRecipeOnly"
        );
        delete appCtxSvc.getCtx("aceActiveContext.context.updatedRecipe");
      }
      if (
        appCtxSvc.getCtx(
          "aceActiveContext.context.requestPref.updateConfigurationOnly"
        )
      ) {
        delete appCtxSvc.getCtx(
          "aceActiveContext.context.requestPref.updateConfigurationOnly"
        );
      }
    });
};

export let getInputSelectionsForProjectedMode = function (data) {
  let varConfigCtx = appCtxSvc.getCtx("variantConfigContext");
  let selections = [];

  let currentTimeSliceSelection = appCtxSvc.getCtx(
    "g4b_ImportConfigurationPanelCtx[0].TimeSliceOptionSelection[0].selections"
  );
  //Time-slice is always pinned-> Adding time slice to both buckets
  selections = [
    {
      affectedObject: "",
      columnId: 0,
      selections: _.clone(currentTimeSliceSelection),
    },
    {
      affectedObject: "scopedExpression",
      columnId: 0,
      selections: _.clone(currentTimeSliceSelection),
    },
  ];
  if (varConfigCtx.selections) {
    //pin the TYP code feature from the list of existing selections -> add TYP code to 1st bucket
    let existingSelections = varConfigCtx.selections[0].selections;
    for (let familyUid in existingSelections) {
      //Only 1 TYP can be selected in any mode.
      let nodeID = existingSelections[familyUid][0].nodeID;
      let featureObj = cdm.getObject(nodeID.split(":")[1]);

      if (featureObj != null && featureObj.type == "G4B_ProductModel") {
        selections[0].selections[familyUid] = _.clone(
          existingSelections[familyUid]
        );
      }
    }
  }

  //store pinned selections in ctx
  varConfigCtx.pinnedSelections = _.clone(selections);

  return selections;
};

//We expand the configuration based on the pinned selections
export let getInputSelectionsForExpand = function (data) {
  let varConfigCtx = appCtxSvc.getCtx("variantConfigContext");
  let selections = [];
  //let expandedSelections = data.manualModeExpandedSelections;

  //Clear pinnedSelectionsModified flag. This flag is being checked on 'onSelectValue' action
  if (varConfigCtx.pinnedSelectionsModified) {
    delete varConfigCtx.pinnedSelectionsModified;
  }

  if (varConfigCtx.pinnedSelections) {
    selections = [
      {
        affectedObject: "",
        columnId: 0,
        selections: _.clone(varConfigCtx.pinnedSelections[0].selections),
      },
    ];
  } else {
    let currentTimeSliceSelection = appCtxSvc.getCtx(
      "g4b_ImportConfigurationPanelCtx[0].TimeSliceOptionSelection[0].selections"
    );
    selections = [
      {
        affectedObject: "",
        columnId: 0,
        selections: _.clone(currentTimeSliceSelection),
      },
    ];
    //Scope for R22.20: select/deselect Type code in Pin mode
    if (varConfigCtx.selections) {
      let existingSelections = varConfigCtx.selections[0].selections;
      for (let key in existingSelections) {
        let featureObj = cdm.getObject(key);
        if (
          featureObj != null &&
          featureObj.type == "G4B_ProductModelFamily" &&
          existingSelections[key].length == 1
        ) {
          selections[0].selections[key] = _.clone(existingSelections[key]);
        }
      }
    }
  }

  return selections;
};

export let dummyFunc = function () {};

export let changeShowFromOtherSVRs = function (data) {
  let showFromOtherSVRs = data.showFromOtherSVRs;
  if (appCtxSvc.getCtx("showFromOtherSVRs") != undefined) {
    showFromOtherSVRs.dbValue = appCtxSvc.getCtx("showFromOtherSVRs");
  }
  return {showFromOtherSVRs: showFromOtherSVRs};
};

/**
 * This functions formats the selections (removes family level selections) for 'createRule' input parameter
 * Ref issues:
 * GEPA-10407 [TAKO] PROD: Konfigurieren im ungefuhrter Modus + Falsche Anzeige im Konfigurator
 * GEPA-9309 Value "KENNZ" is selected multiple times when SVR is saved in unguided mode
 * @returns selections
 */

export let formatSelectionsForConfigureOrValidate = function () {
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
  let selectionsCopy = null;
  let selections = variantConfigContext.selections;

  if (
    variantConfigContext.guidedMode ||
    (!variantConfigContext.guidedMode &&
      variantConfigContext.isStandardManualMode == true)
  ) {
    selectionsCopy = JSON.parse(JSON.stringify(selections));
  }
  if (
    !variantConfigContext.guidedMode &&
    variantConfigContext.isStandardManualMode == false
  ) {
    selectionsCopy = JSON.parse(
      JSON.stringify(variantConfigContext.pinnedModeExpandedSelections)
    );
  }

  //Commented out due to: GEPA-8326 SVR is listed as "invalid", but is displayed as "valid" after it was loaded and "valid but incomplete" after manual validation in CFSC
  //Sarwan: Commented 27-Jun-2023. Added sanitise function

  /*for (let key in selectionsCopy[0].selections) {
        for (let inx = 0; selectionsCopy[0].selections[key] != undefined && inx < selectionsCopy[0].selections[key].length; inx++) {
            if(selectionsCopy[0].selections[key][inx].nodeID.split(':')[1].length == 0){
                selectionsCopy[0].selections[key].splice(inx, 1);
                if (selectionsCopy[0].selections[key].length == 0) {
                    delete selectionsCopy[0].selections[key];
                }
                else{
                    inx--;
                }
            }

        }
    }*/
  selectionsCopy = sanitiseVariantSelections(selectionsCopy);

  //*** variantConfigContext.selections = selectionsCopy

  //TODO: Format selections input for Manual mode
  //Case: When "isStandardManualMode" is false, We consider that the user is in Pinned mode
  //and the system selections are added from the 'pinnedModeExpandedSelections'

  if (
    !variantConfigContext.guidedMode &&
    variantConfigContext.isStandardManualMode == false
  ) {
    for (let key in selections[0].selections) {
      let obj = cdm.getObject(key);

      if (obj != null && obj.props.cfg0IsMultiselect.dbValues[0] == "0") {
        //if single-select family then update the family completely
        selectionsCopy[0].selections[key] = _.clone(
          selections[0].selections[key]
        );
      }
      if (obj != null && obj.props.cfg0IsMultiselect.dbValues[0] == "1") {
        //if Multi-select family then update individual features
        for (let inx = 0; inx < selections[0].selections[key].length; inx++) {
          if (
            selections[0].selections[key][inx].selectionState == "1" ||
            selections[0].selections[key][inx].selectionState == "2"
          ) {
            updateMultiselectFamilyFeature(
              selectionsCopy,
              selections[0].selections[key][inx]
            );
          }
        }
      }
    }
  }

  return selectionsCopy;
};

function updateMultiselectFamilyFeature(selectionsCopy, feature) {
  let updatedFlag = false;
  let familyUid = feature.nodeID.split(":")[0];
  for (
    let inx = 0;
    inx < selectionsCopy[0].selections[familyUid].length;
    inx++
  ) {
    if (selectionsCopy[0].selections[familyUid][inx].nodeID == feature.nodeID) {
      selectionsCopy[0].selections[familyUid][inx].selectionState =
        feature.selectionState;
      updatedFlag = true;
      break;
    }
  }
  if (!updatedFlag) {
    selectionsCopy[0].selections[familyUid].push(feature);
  }
}

export let getProjectedModeData = function (response) {
  let projectedModeData = {};

  for (let jnx = 0; jnx < response.allScopes.length; jnx++) {
    if (response.allScopes[jnx].nodeID == response.scopes[0]) {
      let families = response.allScopes[jnx].families;
      for (let knx = 0; knx < families.length; knx++) {
        let family = families[knx];
        for (let lnx = 0; lnx < family.features.length; lnx++) {
          let feature = family.features[lnx];
          let option = {};
          option.selectionState = feature.selectionInfo.selectionState;
          if (feature.featureObject.props.isInvalid) {
            option.isInvalid = feature.featureObject.props.isInvalid[0];
          }
          projectedModeData[feature.selectionInfo.nodeID] = option;
        }
      }
    }
  }
  return projectedModeData;
};

export let getIsExpandedSelectionsForManualMode = function () {
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");

  let isStandardManualMode = false;

  //Check if we have an existing feature selected when switching to Manual mode. In that case we will store the exapanded selections
  //and the system selections will be displayed from them otherwise the selection state come from the pinned mode
  if (Object.keys(variantConfigContext.selections[0].selections).length > 1) {
    isStandardManualMode = true;
  }
  variantConfigContext.isStandardManualMode = isStandardManualMode;
};

export let loadFamilySelectTypeInfo = function (data, actionType) {
  let deferred = AwPromiseService.instance.defer();
  data.actionType = actionType;
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");

  let familyUidsToLoad = [];
  let selectionsCopy = variantConfigContext.selections;
  for (let key in selectionsCopy[0].selections) {
    for (
      let inx = 0;
      selectionsCopy[0].selections[key] != undefined &&
      inx < selectionsCopy[0].selections[key].length;
      inx++
    ) {
      familyUidsToLoad.push({
        uid: selectionsCopy[0].selections[key][inx].nodeID.split(":")[0],
        type: "",
      });
    }
  }

  let inputData = {
    objects: familyUidsToLoad,
    attributes: ["cfg0IsMultiselect"],
  };
  //let descMap = {};

  soaSvc
    .post("Internal-Core-2007-12-Session", "getProperties", inputData)
    .then(function (response) {
      console.log(response);
      deferred.resolve();
    });

  return deferred.promise;
};

export let getFormattedSelections = function (response) {
  let selectionsCopy = response.selections;

  //Commented out due to: GEPA-8326 SVR is listed as "invalid", but is displayed as "valid" after it was loaded and "valid but incomplete" after manual validation in CFSC
  //Sarwan: Commented 27-Jun-2023. Added sanitise function

  /*for (let key in selectionsCopy[0].selections) {
        for (let inx = 0; selectionsCopy[0].selections[key] != undefined && inx < selectionsCopy[0].selections[key].length; inx++) {
            if(selectionsCopy[0].selections[key][inx].nodeID.split(':')[1].length == 0){
                selectionsCopy[0].selections[key].splice(inx, 1);
                if (selectionsCopy[0].selections[key].length == 0) {
                    delete selectionsCopy[0].selections[key];
                }
                else{
                    inx--;
                }
            }

        }
    }*/

  selectionsCopy = sanitiseVariantSelections(selectionsCopy);
  return selectionsCopy;
};

export let initiateNextOrPrevRequiredFamily = function (data, actionType) {
  let varConfigContext = appCtxSvc.getCtx("variantConfigContext");
  if (
    varConfigContext.requiredFamiliesData &&
    varConfigContext.requiredFamiliesData.incompleteFamiliesInfo &&
    varConfigContext.requiredFamiliesData.isIncompleteFamilyComputeRequired ==
      false
  ) {
    exports.getIncompleteFamilyInfo(data, actionType);
  } else {
    let eventData = {
      reqFamilyActionType: actionType,
    };
    eventBus.publish("computeIncompleteFamiliesAction.event", eventData);
  }
};

export let getIncompleteFamilyInfo = function (data, actionType) {
  let varConfigContext = appCtxSvc.getCtx("variantConfigContext");
  let reqActionType = actionType;
  if (reqActionType == undefined) {
    reqActionType = data.eventData.reqFamilyActionType;
  }
  varConfigContext.actionType = reqActionType;
  let incompleteFamiliesArr =
    varConfigContext.requiredFamiliesData.incompleteFamiliesInfo
      .incompleteFamilies;
  let treeData =
    varConfigContext.requiredFamiliesData.incompleteFamiliesInfo
      .incompleteFamiliesTreeData;
  let incompleteArrInSeries = varConfigContext.allFamilyListInSeries;

  //TODO: Start the index from features that already exist in the current expanded group
  if (
    (varConfigContext.currentRequiredFamily == undefined ||
      varConfigContext.currentRequiredFamily == "") &&
    incompleteFamiliesArr.length > 0
  ) {
    let allGroupsList = appCtxSvc.getCtx("groupsInContext");
    let groupsInSeries = [];
    let allDisplayValue = [
      "Products",
      "ATT",
      "EBR",
      "MBR",
      "SLP",
      "LCK",
      "POL",
      "PLG",
    ];
    for (let k = 0; k < allDisplayValue.length; k++) {
      for (let l = 0; l < allGroupsList.length; l++) {
        if (!allGroupsList[l].groupDisplayName.indexOf(allDisplayValue[k])) {
          groupsInSeries.push(allGroupsList[l]);
        }
      }
    }
    let allRequiredGroupsList = [];
    for (let typ = 0; typ < treeData.length; typ++) {
      if (treeData[typ].displayName == "TYP") {
        allRequiredGroupsList.push(treeData[typ]);
      }
    }
    for (let i = 0; i < groupsInSeries.length; i++) {
      for (let j = 0; j < treeData.length; j++) {
        if (treeData[j].parentUids) {
          if (treeData[j].parentUids[0] == groupsInSeries[i].optGroup) {
            allRequiredGroupsList.push(treeData[j]);
          }
        }
      }
    }
    let multiFeatures = [];
    for (let i = 0; i < allRequiredGroupsList.length; i++) {
      for (let j = 0; j < treeData.length; j++) {
        if (treeData[j].parentUids) {
          if (allRequiredGroupsList[i].nodeUid == treeData[j].nodeUid) {
            if (treeData[j].parentUids[0] !== "") {
              if (treeData[j].childrenUids) {
                multiFeatures.push(treeData[j]);
              }
            }
          }
        }
      }
    }
    let allFamilyAndFeatureInSeries = [];
    for (let i = 0; i < allRequiredGroupsList.length; i++) {
      allFamilyAndFeatureInSeries.push(
        allRequiredGroupsList[i].parentUids[0] +
          ":" +
          allRequiredGroupsList[i].nodeUid
      );
      for (let j = 0; j < multiFeatures.length; j++) {
        if (allRequiredGroupsList[i].nodeUid == multiFeatures[j].nodeUid) {
          if (multiFeatures[j].childrenUids.length) {
            for (let k = 0; k < multiFeatures[j].childrenUids.length; k++) {
              allFamilyAndFeatureInSeries.push(
                multiFeatures[j].parentUids[0] +
                  ":" +
                  multiFeatures[j].nodeUid +
                  ":" +
                  multiFeatures[j].childrenUids[k]
              );
            }
          }
        }
      }
    }
    varConfigContext.multiFeatures = multiFeatures;
    varConfigContext.allFamilyListInSeries = allFamilyAndFeatureInSeries;
    varConfigContext.currentRequiredFamily = allFamilyAndFeatureInSeries[0];

    //Check if we still have an incomplete family in the opened group
    for (let jnx = 0; jnx < allFamilyAndFeatureInSeries.length; jnx++) {
      let uidSplitArr = allFamilyAndFeatureInSeries[jnx].split(":");
      if (uidSplitArr[0] == varConfigContext.currentGroup) {
        //varConfigContext.currentRequiredFamily = allFamilyAndFeatureInSeries[jnx]
        let requiredFamilyUidToHighlight = rearrangeRequiredFamiliesOfGroup(
          allFamilyAndFeatureInSeries[jnx],
          appCtxSvc.getCtx("responseInContext"),
          varConfigContext.actionType
        );
        varConfigContext.currentRequiredFamily = requiredFamilyUidToHighlight;
        break;
      }
    }

    //allRequiredGroupsList = allRequiredGroupsList;
  } else {
    let currentidx = incompleteArrInSeries.indexOf(
      varConfigContext.currentRequiredFamily
    );
    if (reqActionType == "next") {
      if (currentidx + 1 < incompleteArrInSeries.length) {
        varConfigContext.currentRequiredFamily =
          incompleteArrInSeries[currentidx + 1];
      } else {
        //start from begining
        varConfigContext.currentRequiredFamily = incompleteArrInSeries[0];
      }
    }
    if (reqActionType == "prev") {
      if (currentidx - 1 >= 0) {
        varConfigContext.currentRequiredFamily =
          incompleteArrInSeries[currentidx - 1];
      } else {
        //go to end
        varConfigContext.currentRequiredFamily =
          incompleteArrInSeries[incompleteArrInSeries.length - 1];
      }
    }
  }
  varConfigContext.multiFeature = false;

  //
  if (varConfigContext.currentRequiredFamily.split(":").length == 3) {
    varConfigContext.multiFeature = true;
  }

  /*for (let i = 0; i < varConfigContext.multiFeatures.length; i++) {
        for (let j = 0; j < varConfigContext.multiFeatures[i].childrenUids.length; j++) {
            if (varConfigContext.multiFeatures[i].childrenUids[j] == varConfigContext.currentRequiredFamily) {
                varConfigContext.multiFeature = true;
                break;
            }
        }
    }*/

  let currentRequiredFamilyGroup = null;
  let currentRequiredFamilyGroupUID = null;

  for (let inx = 0; inx < treeData.length; inx++) {
    let currReqFamArr = varConfigContext.currentRequiredFamily.split(":");
    if (
      treeData[inx].displayName == "TYP" &&
      treeData[inx].nodeUid == currReqFamArr[currReqFamArr.length - 1]
    ) {
      currentRequiredFamilyGroup =
        appCtxSvc.getCtx("userSession.props.fnd0locale.dbValue") == "de_DE"
          ? "Produkte"
          : "Products";
      currentRequiredFamilyGroupUID = currReqFamArr[currReqFamArr.length - 1];
      break;
    } else if (
      treeData[inx].nodeUid == currReqFamArr[currReqFamArr.length - 1]
    ) {
      let parentGroupUid = treeData[inx].parentUids[0];
      for (let jnx = 0; jnx < treeData.length; jnx++) {
        if (treeData[jnx].nodeUid == parentGroupUid) {
          if (varConfigContext.multiFeature == true) {
            let parentUid = treeData[jnx].parentUids[0];
            for (let knx = 0; knx < treeData.length; knx++) {
              if (treeData[knx].nodeUid == parentUid) {
                currentRequiredFamilyGroup = treeData[knx].displayName;
                currentRequiredFamilyGroupUID = treeData[knx].nodeUid;
                break;
              }
            }
          } else {
            currentRequiredFamilyGroup = treeData[jnx].displayName; // e.g. displayName = POL_20
            currentRequiredFamilyGroupUID = treeData[jnx].nodeUid;
            break;
          }
        }
      }
      break;
    }
  }

  //Check if we are already in the target group for Required family
  if (varConfigContext.currentGroup == currentRequiredFamilyGroupUID) {
    exports.highlightIncompleteFamilyCFSC(
      varConfigContext.currentRequiredFamily,
      varConfigContext.multiFeature
    );
  } else {
    let configButtonId = null;
    if (
      ["SLP", "LCK", "POL", "PLG"].indexOf(
        currentRequiredFamilyGroup.split("_")[0]
      ) > -1
    ) {
      configButtonId = "optEqpt";
    } else {
      configButtonId = "type";
    }

    data.saveButtonId.dbValue = configButtonId;

    try {
      let header = document.getElementById("button-group");
      let btns = header.getElementsByClassName("g4b-cfsc-btn");
      let current = document.getElementsByClassName("active");
      current[0].className = current[0].className.replace(" active", "");

      if (configButtonId == "type") {
        btns[0].className += " active";
      } else {
        btns[1].className += " active";
      }
    } catch (ex) {
      console.log(ex);
    }
    g4bSortGroupsSvc.getButtonId(data, configButtonId);

    _.defer(function () {
      let g4bCfgGroupsInView = document.getElementsByClassName("g4bCfgGroup");
      for (let knx = 0; knx < g4bCfgGroupsInView.length; knx++) {
        if (g4bCfgGroupsInView[knx].title == currentRequiredFamilyGroup) {
          let scope = ngUtils.getElementScope(g4bCfgGroupsInView[knx]);
          appCtxSvc.registerCtx(
            "autoExpandRequiredFamilyUidToHighlight",
            varConfigContext.currentRequiredFamily
          );
          scope.$parent.toggleExpansion();
        }
      }
    });
  }
};
/**
 *This function modifies the content of the allFamilyListInSeries array based on the current open Family-Group
 * @param {string} firstRequiredFamilyUID
 */
let rearrangeRequiredFamiliesOfGroup = function (
  firstRequiredFamilyUID,
  vcvResponse,
  actionType
) {
  let varConfigContext = appCtxSvc.getCtx("variantConfigContext");
  let incompleteFamilyFeaturesArr = varConfigContext.allFamilyListInSeries;
  let currentGroupFamiliesAndFeaturesInOrder = [];
  let reqGroupUid = firstRequiredFamilyUID.split(":")[0];

  //Get sorted list of all feature from the current expanded group scope
  for (let inx = 0; inx < vcvResponse.allScopes.length; inx++) {
    if (vcvResponse.allScopes[inx].nodeID == vcvResponse.scopes[0]) {
      for (
        let jnx = 0;
        jnx < vcvResponse.allScopes[inx].families.length;
        jnx++
      ) {
        currentGroupFamiliesAndFeaturesInOrder.push(
          vcvResponse.allScopes[inx].scopeObj.sourceUid +
            ":" +
            vcvResponse.allScopes[inx].families[jnx].familyObj.sourceUid
        );
        for (
          let knx = 0;
          knx < vcvResponse.allScopes[inx].families[jnx].features.length;
          knx++
        ) {
          if (
            vcvResponse.allScopes[inx].families[jnx].familyObj.props
              .isSingleSelect[0] == "false"
          ) {
            currentGroupFamiliesAndFeaturesInOrder.push(
              vcvResponse.allScopes[inx].scopeObj.sourceUid +
                ":" +
                vcvResponse.allScopes[inx].families[jnx].familyObj.sourceUid +
                ":" +
                vcvResponse.allScopes[inx].families[jnx].features[knx]
                  .featureObject.sourceUid
            );
          }
        }
      }
      break;
    }
  }

  //Get sorted list of available features from the current group
  let orderedGroupArr = [];
  for (
    let inx = 0;
    inx < currentGroupFamiliesAndFeaturesInOrder.length;
    inx++
  ) {
    if (
      incompleteFamilyFeaturesArr.indexOf(
        currentGroupFamiliesAndFeaturesInOrder[inx]
      ) > -1
    ) {
      orderedGroupArr.push(currentGroupFamiliesAndFeaturesInOrder[inx]);
    }
  }

  for (let jnx = 0; jnx < incompleteFamilyFeaturesArr.length; jnx++) {
    let groupUid = incompleteFamilyFeaturesArr[jnx].split(":")[0];
    if (groupUid == reqGroupUid) {
      Array.prototype.splice.apply(
        incompleteFamilyFeaturesArr,
        [jnx, orderedGroupArr.length].concat(orderedGroupArr)
      );
      break;
    }
  }
  if (actionType == "next") {
    if (orderedGroupArr[0].split(":").length == 3) {
      varConfigContext.multiFeature = true;
    }
    return orderedGroupArr[0];
  }
  if (actionType == "prev") {
    if (orderedGroupArr[orderedGroupArr.length - 1].split(":").length == 3) {
      varConfigContext.multiFeature = true;
    }
    return orderedGroupArr[orderedGroupArr.length - 1];
  }
};

export let highlightIncompleteFamilyCFSC = function (
  familyUidToHighlight,
  multiFeature
) {
  setTimeout(function () {
    _.defer(function () {
      let g4bFamilyGroupViewport = document.getElementsByClassName(
        "g4bFamilyGroupViewport"
      )[0];
      $(g4bFamilyGroupViewport).scrollTop(0); //Reset scroll for viewport
      if (multiFeature == false) {
        let uid = familyUidToHighlight.split(":")[1];
        let g4bCfgFamilyInView =
          document.getElementsByClassName("g4bCfgFamily");
        for (let knx = 0; knx < g4bCfgFamilyInView.length; knx++) {
          let familyScope = ngUtils.getElementScope(g4bCfgFamilyInView[knx]);
          if (familyScope.$parent.family.familyStr == uid) {
            $(g4bFamilyGroupViewport).scrollTop(
              $(g4bCfgFamilyInView[knx]).position().top -
                $(g4bFamilyGroupViewport).position().top -
                75
            );
            $(g4bCfgFamilyInView[knx]).effect("highlight", {}, 3000);
          }
        }
      } else if (multiFeature == true) {
        let uid = familyUidToHighlight.split(":")[2];
        let g4bCfgFeatureFamilyInView =
          document.getElementsByClassName("g4bCfgValue");
        for (let k = 0; k < g4bCfgFeatureFamilyInView.length; k++) {
          let FeaturedfamilyScope = ngUtils.getElementScope(
            g4bCfgFeatureFamilyInView[k]
          );
          if (FeaturedfamilyScope.value.optValue.uid == uid) {
            $(g4bFamilyGroupViewport).scrollTop(
              $(g4bCfgFeatureFamilyInView[k]).offset().top -
                $(g4bFamilyGroupViewport).offset().top -
                75
            );
            $(g4bCfgFeatureFamilyInView[k]).addClass(
              "g4b-highlight-Features-cfsc"
            );
            setTimeout(function () {
              $($(g4bCfgFeatureFamilyInView[k])).removeClass(
                "g4b-highlight-Features-cfsc"
              );
            }, 2000);
          }
        }
      }
    });
  }, 100);
};

/**
 * Sanitise selection data
 * Check if a feature selection is separately defined as family level selection and remove those entries from selections data
 * @param {JSON} selectionsCopy
 * @returns selectionsCopy
 */

let sanitiseVariantSelections = function (selectionsCopy) {
  for (let key in selectionsCopy[0].selections) {
    for (
      let inx = 0;
      selectionsCopy[0].selections[key] != undefined &&
      inx < selectionsCopy[0].selections[key].length;
      inx++
    ) {
      if (
        selectionsCopy[0].selections[key][inx].nodeID.split(":")[1].length > 0
      ) {
        let featureUID =
          selectionsCopy[0].selections[key][inx].nodeID.split(":")[1];
        if (selectionsCopy[0].selections.hasOwnProperty(featureUID)) {
          delete selectionsCopy[0].selections[featureUID];
        }
      }
    }
  }
  return selectionsCopy;
};

export let getSLPFamilyGroupScope = function () {
  let _groupsInContext = appCtxSvc.getCtx("groupsInContext");
  let groupIdSLP;
  for (let i = 0; i < _groupsInContext.length; i++) {
    if (_groupsInContext[i].groupDisplayName.indexOf("SLP_") > -1) {
      groupIdSLP = _groupsInContext[i].optGroup;
    }
  }
  return [groupIdSLP];
};

export let checkAccess = function () {

  var pselected = appCtxSvc.getCtx("pselected");

  var userSession = appCtxSvc.getCtx("userSession");
  var userGroup = userSession.props.group_name.dbValue;
  if (userGroup == 'TeilaufbautenKonfiguration') {

      //add condition to restrict selections for TA role/user
      if (appCtxSvc.getCtx('userSession.props.role.uiValue') != "Verwender TA" && appCtxSvc.getCtx('userSession.props.role.uiValue') != 'Customer TA') {
          if (appCtxSvc.getCtx('xrtSummaryContextObject.props.g4b_CompTaKoSubsetType') && appCtxSvc.getCtx('xrtSummaryContextObject.props.g4b_CompTaKoSubsetType.dbValue') == '2') {
              appCtxSvc.registerCtx("enableAllSelections", true);
          } else if (appCtxSvc.getCtx('xrtSummaryContextObject.props.g4b_CompTaKoSubsetType') && appCtxSvc.getCtx('xrtSummaryContextObject.props.g4b_CompTaKoSubsetType.dbValue') == '1') {
              appCtxSvc.registerCtx("enableAllSelections", false);
          } else if (appCtxSvc.getCtx('xrtSummaryContextObject.props.g4b_CompTaKoSubsetType') && appCtxSvc.getCtx('xrtSummaryContextObject.props.g4b_CompTaKoSubsetType.dbValue') == null) {
              appCtxSvc.registerCtx("enableAllSelections", false);
          } else {
              appCtxSvc.registerCtx("enableAllSelections", false);
          }
      }



  } else {
      var userObject = cdm.getObject(userSession.props.fnd0groupmember.dbValue);
      var rule = cdm.getObject(pselected.props.awb0UnderlyingObject.dbValues[0]);

      var inputData = {
          groupMember: userObject,
          objects: [
              rule

          ],
          privilegeNames: ['WRITE']
      }


      soaSvc.post("Administration-2006-03-IRM", "checkAccessorsPrivileges", inputData).then(
          function (response) {

              if (response) {

                  if (response != undefined && response.privilegeReports[0].privilegeInfos[0].verdict == true) {

                      if (appCtxSvc.getCtx("accessorPrivilage") == undefined) {

                          appCtxSvc.registerCtx("accessorPrivilage", true);
                      } else {
                          appCtxSvc.updateCtx("accessorPrivilage", true);
                      }

                  } else if (response != undefined && response.privilegeReports[0].privilegeInfos[0].verdict == false) {
                      if (appCtxSvc.getCtx("accessorPrivilage") == undefined) {

                          appCtxSvc.registerCtx("accessorPrivilage", false);
                      } else {
                          appCtxSvc.updateCtx("accessorPrivilage", false);
                      }

                  }

              }

          });
  }

}

/**
 *
 * @memberof NgServices
 * @member G4B_configuratorService
 */

exports = {
  changeShowFromOtherSVRs,
  g4bGetCreatedVariantRule,
  createRevisionEffectivityIntentFormula,
  subscribeToPartialCtxChange,
  removeModifyBasedOnSelection,
  setCustomSelection,
  setAppliedSelection,
  isModifiedSelectionForUnGuided,
  isModifiedSelectionForGuided,
  toggleConfigure,
  g4bGetConfigCreateInput,
  g4bGetVariantRuleVMO,
  g4bGetSelectionForVariantContext,
  updatePrefForfavouriteGroup,
  renderToggleMode,
  pageReload,
  resetSummaryInfo,
  populateSelections,
  resetVariantRuleSelection,
  fetchCreatedVariantRule,
  persistentSummaryforPacakgeValues,
  populatePersistentSummaryWhileTabSwitch,
  updateVariantConfigContext,
  userSelectionForUnguidedMode,
  setNoVariantRule,
  resetModeForclearSelections,
  formatSelectionsForClearSelections,
  setSavedVariantRule,
  buildSummary,
  getInitialVariantRule,
  configuredBasedOnSelectionAction,
  getIgnoreConstraints,
  getConfigurationMode,
  getConfigurationMode2,
  getSelectionForVariantContext,
  updateVariantMode,
  resetGroupCompleteness,
  setScrollPosition,
  updateScrollInfo,
  showValidationErrorMessage,
  stayInManualMode,
  showUnableToSwitchToGuidedModeMessage,
  setManualConfigurationMode,
  actioncheckfordata,
  returnResponseToDataProvider,
  setSelectionsForVariantRules,
  showSystemSelections,
  createSystemSelectionMap,
  listAllGroupData,
  getFamilyData,
  validateConfiguration,
  getPreferenceValue,
  showViolationsOnValidation,
  setRevRuleEffectivityInput_forTimeSlicePanel,
  reloadOnValidate,
  g4b_showViolationsOnValidation,
  getGroups,
  subscribeChangeInState,
  formatGroupsAndFamilies,
  populateScopes,
  getbuildPhase,
  setConfigSettings,
  updateUserSelectionSummary,
  clearUserSummaryforImport,
  clearUserSummary,
  getUserSelectionsForSavedVariant,
  isConfigurationValid,
  importConfigSetVariantRuleProps,
  //importConfigurationWorkaroundFunc,
  doChecksBeforeApplyConfiguration,
  startSVRValidationDispatcher,
  saveAllSolverProfiles,
  getExistingUserSelectionsOnReload,
  getSolverProfile,
  getOrderSolverProfile,
  getOverlaySolverProfile,
  getSelectionsForConfigure,
  refreshUserSummaryAfterConfigInManualMode,
  updateModelConfigContext,
  setPreferenceCheckboxValue,
  saveApplyUpdatedConfiguration,
  getInputSelectionsForProjectedMode,
  getInputSelectionsForExpand,
  dummyFunc,
  adjustConfiguratorTab,
  formatSelectionsForConfigureOrValidate,
  getProjectedModeData,
  getIsExpandedSelectionsForManualMode,
  loadFamilySelectTypeInfo,
  getFormattedSelections,
  initiateNextOrPrevRequiredFamily,
  getIncompleteFamilyInfo,
  highlightIncompleteFamilyCFSC,
  allVariantAction,
  ComparisonResultAction,
  getSLPFamilyGroupScope,
  checkAccess
};

export default exports;
