// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwLabel from 'viewmodel/AwLabelViewModel';
import AwRadiobutton from 'viewmodel/AwRadiobuttonViewModel';
import AwTotalHours from 'viewmodel/AwTotalHoursViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwTotalHoursService', () => import('js/AwTotalHoursService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "sharedData": {
            "initialValues": {},
            "meta": {}
        },
        "dateDetails": {
            "displayName": "{{i18n.date}}",
            "type": "STRING",
            "isRequired": "false",
            "isEditable": "false",
            "dbValue": "",
            "dispValue": ""
        },
        "dayType": {
            "displayName": "{{i18n.type}}",
            "type": "STRING",
            "isEditable": "{{props.editMode}}",
            "dbValue": ""
        },
        "dayTypes": {
            "type": "STRING",
            "dbValue": [
                {
                    "propDisplayValue": "{{i18n.workingDayDefault}}",
                    "propInternalValue": "wdd"
                },
                {
                    "propDisplayValue": "{{i18n.nonWorkingDay}}",
                    "propInternalValue": "nwd"
                },
                {
                    "propDisplayValue": "{{i18n.workingDayCustom}}",
                    "propInternalValue": "wdc"
                }
            ]
        }
    },
    "props": {
        "selected-date": {
            "type": "object"
        },
        "selected-calendar-data": {
            "type": "object"
        },
        "edit-mode": {
            "type": "boolean"
        },
        "aw-calendar-atomic-data": {
            "type": "object"
        },
        "sub-panel-context": {
            "type": "object"
        },
        "event-info": {
            "type": "object"
        }
    },
    "actions": {
        "getSelectedDateDetails": {
            "actionType": "JSFunction",
            "method": "getSelectedDateDetails",
            "inputData": {
                "dateDetails": "{{data.dateDetails}}",
                "date": "{{props.selectedDate.dateApi}}",
                "selectedCalendarData": "{{props.selectedCalendarData}}",
                "calendarEvent": "{{props.eventInfo}}",
                "updatedEventRanges": "{{subPanelContext.updatedEventRanges}}"
            },
            "outputData": {
                "totalRanges": "totalRanges",
                "defaultRanges": "defaultRanges"
            },
            "events": {
                "success": [
                    {
                        "name": "updateRadioSelection"
                    }
                ]
            },
            "deps": "js/AwTotalHoursService"
        },
        "changeSelectionAction": {
            "actionType": "JSFunction",
            "method": "changeSelection",
            "inputData": {
                "dayType": "{{data.dayType.dbValue}}",
                "defaultRanges": "{{data.defaultRanges}}",
                "exceptionDate": "{{props.selectedDate.dateApi}}",
                "awCalendarAtomicData": "{{props.awCalendarAtomicData}}",
                "updatedEventRanges": "{{subPanelContext.updatedEventRanges}}"
            },
            "outputData": {
                "totalRanges": ""
            },
            "events": {
                "success": [
                    {
                        "name": "callTotalHoursDataProviderEvent"
                    }
                ]
            },
            "deps": "js/AwTotalHoursService"
        },
        "updateRadioSelection": {
            "actionType": "JSFunction",
            "method": "updateRadioSelection",
            "inputData": {
                "dayType": "{{data.dayType}}",
                "totalRanges": "{{data.totalRanges}}",
                "defaultRanges": "{{data.defaultRanges}}",
                "updatedRanges": "{{data.sharedData.newSharedData.updatedRanges}}",
                "fields": "{{fields}}",
                "awCalendarAtomicData": "{{props.awCalendarAtomicData}}",
                "updatedEventRanges": "{{subPanelContext.updatedEventRanges}}",
                "exceptionDate": "{{props.selectedDate.dateApi}}",
                "eventInfo": "{{props.eventInfo}}"
            },
            "outputData": {
                "totalRanges": "updatedRanges",
                "type": "type",
                "sharedData": "sharedData"
            },
            "events": {
                "success": [
                    {
                        "name": "callTotalHoursDataProviderEvent"
                    }
                ]
            },
            "deps": "js/AwTotalHoursService"
        },
        "updateEditModeAction": {
            "actionType": "JSFunction",
            "method": "updateEditMode",
            "inputData": {
                "dayType": "{{data.dayType}}",
                "editMode": "{{props.editMode}}"
            },
            "outputData": {
                "dayType": "dayType"
            },
            "deps": "js/AwTotalHoursService"
        }
    },
    "onEvent": [
        {
            "eventId": "updateRadioSelection",
            "action": "updateRadioSelection"
        },
        {
            "eventId": "getSelectedDateDetails",
            "action": "getSelectedDateDetails"
        }
    ],
    "lifecycleHooks": {
        "onUpdate": [
            {
                "action": "updateRadioSelection",
                "observers": [
                    "viewModel.atomicData.sharedData.newSharedData.updatedRanges"
                ]
            },
            {
                "action": "getSelectedDateDetails",
                "observers": [
                    "props.selectedDate.dbValue",
                    "props.eventInfo.eventDateToRangeMap"
                ]
            },
            {
                "action": "updateEditModeAction",
                "observers": [
                    "props.editMode"
                ]
            }
        ]
    },
    "i18n": {
        "date": [
            "CalendarManagementMessages"
        ],
        "type": [
            "CalendarManagementMessages"
        ],
        "nonWorkingDay": [
            "CalendarManagementMessages"
        ],
        "workingDayDefault": [
            "CalendarManagementMessages"
        ],
        "workingDayCustom": [
            "CalendarManagementMessages"
        ],
        "dayDetails": [
            "CalendarManagementMessages"
        ],
        "day": [
            "CalendarManagementMessages"
        ],
        "from": [
            "CalendarManagementMessages"
        ],
        "to": [
            "CalendarManagementMessages"
        ],
        "totalHours": [
            "CalendarManagementMessages"
        ]
    },
    "_viewModelId": "AwDetailsOfDay",
    "_uniqueViewModelId": "AwDetailsOfDay",
    "ctx": {}
};

    /*
    <aw-panel>
    <aw-panel-body>
        <aw-panel-section caption="i18n.dayDetails">
            <aw-label class="aw-detailsOfDay-label" prop="data.dateDetails"></aw-label>
            <aw-radiobutton class="aw-detailsOfDay-label" prop="data.dayType" list="data.dayTypes.dbValue" action="changeSelectionAction"></aw-radiobutton>
            <div class="sw-row">
                <div class="sw-column">
                    <aw-total-hours aw-calendar-atomic-data="props.awCalendarAtomicData" edit-mode="{{props.editMode}}" sub-panel-context="{sharedData:data.sharedData}" total-ranges="data.totalRanges"></aw-total-hours>
                </div>
            </div>
        </aw-panel-section>
    </aw-panel-body>
</aw-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanel  revealAction={actions.reveal}>
        <AwPanelBody>
            <AwPanelSection caption={i18n.dayDetails}>
                <AwLabel className="aw-detailsOfDay-label" {...getField("data.dateDetails", fields, $index, null, null )}>
                </AwLabel>
                <AwRadiobutton className="aw-detailsOfDay-label" {...getField("data.dayType", fields, $index, null, null )} list={AwParseService.instance( "data.dayTypes.dbValue" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.changeSelectionAction}>
                </AwRadiobutton>
                <div className="sw-row">
                    <div className="sw-column">
                        <AwTotalHours awCalendarAtomicData={AwParseService.instance( "props.awCalendarAtomicData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} editMode={AwParseService.instance( "props.editMode" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{sharedData:fields.sharedData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} totalRanges={AwParseService.instance( "data.totalRanges" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        </AwTotalHours>
                    </div>
                </div>
            </AwPanelSection>
        </AwPanelBody>
    </AwPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;