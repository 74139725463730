// @<COPYRIGHT>@
// ==================================================
// Copyright 2018.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import prefSvc from "soa/preferenceService";
var exports = {};

/** AW intermediate preference */
var URL_FOR_HELP_COMMAND_PREFERENCE = "G4B_HelpCommand_URL";
var URL_FOR_CONFIGURATOR_HELP_PREFERENCE = "G4B_ConfiguratorHelp_URL";

export let openHelpLink = function () {
  prefSvc
    .getStringValue(URL_FOR_HELP_COMMAND_PREFERENCE)
    .then(function (prefValue) {
      if (!prefValue || prefValue.length <= 0) {
        prefValue = "";
      }
      //window.open("https://plaza.bmwgroup.net/groups/gepard/pages/config");
      window.open(prefValue);
    });
};
export let OpenConfiguratorHelpLinkAction = function () {
  prefSvc
    .getStringValue(URL_FOR_CONFIGURATOR_HELP_PREFERENCE)
    .then(function (prefValue) {
      if (!prefValue || prefValue.length <= 0) {
        prefValue = "";
      }
      window.open(prefValue);
    });
};

exports = {
  openHelpLink,
  OpenConfiguratorHelpLinkAction,
};
export default exports;  