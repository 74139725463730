// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwFullScreen from 'viewmodel/AwFullScreenViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {},
    "i18n": {
        "Pca0Constraints": [
            "ConfiguratorExplorerMessages"
        ],
        "Pca0GridEditor": [
            "ConfiguratorExplorerMessages"
        ]
    },
    "_viewModelId": "Pca0ConstraintsGridFullScreen",
    "_uniqueViewModelId": "Pca0ConstraintsGridFullScreen",
    "ctx": {}
};

    /*
    <aw-full-screen include-component-name="Pca0ConstraintsGridEditor"
    header-title='{{subPanelContext.context.baseSelection.props.object_string.uiValues[0]}}'
    header-path="i18n.Pca0Constraints" header-context-title="i18n.Pca0GridEditor" 
    context="props.subPanelContext">
</aw-full-screen>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwFullScreen includeComponentName="Pca0ConstraintsGridEditor" headerTitle={AwParseService.instance( "subPanelContext.context.baseSelection.props.object_string.uiValues[0]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} headerPath={i18n.Pca0Constraints} headerContextTitle={i18n.Pca0GridEditor} context={AwParseService.instance( "props.subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwFullScreen>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;