// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import EpConfigurationChipArea from 'viewmodel/EpConfigurationChipAreaViewModel';
import AwSublocation from 'viewmodel/AwSublocationViewModel';
import EpInformation from 'viewmodel/EpInformationViewModel';
import EpManageTilesArea from 'viewmodel/EpManageTilesAreaViewModel';
import EpTasksNavigationPanel from 'viewmodel/EpTasksNavigationPanelViewModel';
    import { ExistWhen,AwClass } from 'js/hocCollection';
const EpConfigurationChipAreaExistWhen = ExistWhen(EpConfigurationChipArea);
const DivAwClass = AwClass('div');
const EpInformationExistWhen = ExistWhen(EpInformation);
const DivExistWhenAwClass = AwClass(ExistWhen('div'));
EpConfigurationChipAreaExistWhen.displayName = 'EpConfigurationChipAreaExistWhen';
DivAwClass.displayName = 'DivAwClass';
EpInformationExistWhen.displayName = 'EpInformationExistWhen';
DivExistWhenAwClass.displayName = 'DivExistWhenAwClass';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/mfePolicyService', () => import('js/mfePolicyService'));
registerDynImportEntry('js/epInitializationService', () => import('js/epInitializationService'));
registerDynImportEntry('js/mfeViewModelUtils', () => import('js/mfeViewModelUtils'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "provider": {
            "label": "{{i18n.manageTitleDecl}}",
            "priority": 1,
            "name": "manageWorkPackageNewSubLocation",
            "clientScopeURI": "manageWorkPackageNewSubLocation",
            "nameToken": "manageWorkPackageNewSubLocation"
        },
        "awSubLocationPref": {
            "hideRightWall": true
        },
        "occContext": {
            "initialValues": {
                "viewKey": "",
                "productContextInfo": null,
                "elementToPCIMapCount": null,
                "supportedFeatures": null,
                "skipReloadOnConfigParamChange": true
            },
            "meta": {}
        },
        "configurationChipsData": {
            "initialValues": {},
            "meta": {}
        }
    },
    "actions": {
        "registerWorkareaPolicy": {
            "actionType": "JSFunction",
            "method": "register",
            "inputData": {
                "name": "workareaPolicy",
                "policyObj": {
                    "types": [
                        {
                            "name": "Mfg0BvrWorkarea",
                            "properties": [
                                {
                                    "name": "Mfg0linkedPlantBOPObject"
                                }
                            ]
                        }
                    ]
                }
            },
            "deps": "js/mfePolicyService"
        },
        "initializeEPPage": {
            "actionType": "JSFunctionAsync",
            "method": "initializeEPPage",
            "inputData": {
                "provider": "{{data.provider}}"
            },
            "outputData": {
                "stateContext": "stateContext",
                "propertyPolicyId": "propertyPolicyId"
            },
            "deps": "js/epInitializationService"
        },
        "initialiseConfigurationChips": {
            "actionType": "JSFunction",
            "method": "mergeValueInAtomicData",
            "inputData": {
                "targetContext": "{{fields.configurationChipsData}}",
                "updatedValues": {
                    "ebomStructure": "{{ctx.epPageContext.ebomStructure}}",
                    "ebomPCI": "{{ctx.epTaskPageContext.ebomPCI}}",
                    "ebomStructureConfigFlags": "{{ctx.epTaskPageContext.ebomStructureConfigFlags}}",
                    "mbomStructure": "{{ctx.epPageContext.mbomStructure}}",
                    "mbomPCI": "{{ctx.epTaskPageContext.mbomPCI}}",
                    "mbomStructureConfigFlags": "{{ctx.epTaskPageContext.mbomStructureConfigFlags}}",
                    "processStructure": "{{ctx.epPageContext.processStructure}}",
                    "processPCI": "{{ctx.epTaskPageContext.processPCI}}",
                    "processStructureConfigFlags": "{{ctx.epTaskPageContext.processStructureConfigFlags}}",
                    "rootPlant": "{{ctx.epPageContext.rootPlant}}",
                    "plantPCI": "{{ctx.epTaskPageContext.plantPCI}}",
                    "rootPlantConfigFlags": "{{ctx.epTaskPageContext.rootPlantConfigFlags}}",
                    "functionalPlan": "{{ctx.epTaskPageContext.functionalPlan}}",
                    "functionalPlanPCI": "{{ctx.epTaskPageContext.functionalPlanPCI}}",
                    "functionalPlanConfigFlags": "{{ctx.epTaskPageContext.functionalPlanConfigFlags}}"
                }
            },
            "deps": "js/mfeViewModelUtils"
        },
        "init": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "registerWorkareaPolicy"
                },
                {
                    "action": "initializeEPPage"
                },
                {
                    "action": "initialiseConfigurationChips"
                }
            ]
        },
        "unRegisterWorkareaPolicy": {
            "actionType": "JSFunction",
            "method": "unregister",
            "inputData": {
                "name": "workareaPolicy"
            },
            "deps": "js/mfePolicyService"
        }
    },
    "lifecycleHooks": {
        "onMount": "init",
        "onUnmount": "unRegisterWorkareaPolicy"
    },
    "conditions": {
        "isEpTaskPageContext": {
            "expression": "ctx.epTaskPageContext && ctx.epTaskPageContext.collaborationContext"
        }
    },
    "onEvent": [
        {
            "eventId": "ep.pageContext.changed",
            "action": "initialiseConfigurationChips"
        },
        {
            "eventId": "ep.addRemoveEvents",
            "action": "initialiseConfigurationChips"
        }
    ],
    "i18n": {
        "manageTitleDecl": [
            "AdminMessages"
        ],
        "overviewColumnTitle": [
            "AdminMessages"
        ],
        "contentColumnTitle": [
            "AdminMessages"
        ],
        "tasksColumnTitle": [
            "AdminMessages"
        ]
    },
    "_viewModelId": "EpManageWorkPackageSubpage",
    "_uniqueViewModelId": "EpManageWorkPackageSubpage",
    "ctx": {
        "epPageContext": {
            "type": "object"
        },
        "epTaskPageContext": {
            "type": "object"
        },
        "ep": {
            "type": "object"
        },
        "preferences": {
            "type": "object"
        }
    }
};

    /*
    <ep-configuration-chip-area exist-when="ctx.ep.scopeObject.uid" configuration-chips-data = "data.configurationChipsData"></ep-configuration-chip-area>
<aw-sublocation sub-location-preference="data.awSubLocationPref" sub-panel-context="{provider :data.provider, occContext:data.occContext}">
    <div class="sw-row w-12">
        <div class="sw-column aw-mfe-manageWpXrtContainer"
            aw-class="[ ctx.preferences['EP_ProductVariantsTiles'].includes('ConfiguratorContext') ? 'w-3' : 'w-4' ]">
            <span class="aw-ep-managePageColumnTitle aw-ep-managePageOverviewColumnTitle">
                {{i18n.overviewColumnTitle}}
            </span>
            <div class="sw-column">
                <ep-information view-path="viewPath" exist-when="ctx.ep && ctx.ep.scopeObject"
                    sub-panel-context="{scopeObject:ctx.ep.scopeObject,xrtType:'INFO'}"></ep-information>
            </div>
        </div>
        <div class="sw-column afx-content-background aw-ep-managePageColumn aw-ep-managePageTilesColumn"
            aw-class="[ ctx.preferences['EP_ProductVariantsTiles'].includes('ConfiguratorContext') ? 'w-6' : 'w-4' ]"
            exist-when="conditions.isEpTaskPageContext">
            <span
                class="aw-ep-managePageColumnTitle aw-ep-managePageTilesColumnTitle">{{i18n.contentColumnTitle}}</span>
            <ep-manage-tiles-area></ep-manage-tiles-area>
        </div>
        <div class="sw-column aw-ep-managePageColumn aw-ep-managePageColumnContainer"
            aw-class="[ ctx.preferences['EP_ProductVariantsTiles'].includes('ConfiguratorContext') ? 'w-3' : 'w-4' ]">
            <span class="aw-ep-managePageColumnTitle aw-ep-managePageTasksColumnTitle">{{i18n.tasksColumnTitle}}</span>
            <ep-tasks-navigation-panel></ep-tasks-navigation-panel>
        </div>
    </div>
</aw-sublocation>


    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <EpConfigurationChipAreaExistWhen configurationChipsData={AwParseService.instance( "fields.configurationChipsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </EpConfigurationChipAreaExistWhen>
    <AwSublocation subLocationPreference={AwParseService.instance( "data.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{provider :data.provider, occContext:fields.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <div className="sw-row w-12">
            <DivAwClass className="sw-column aw-mfe-manageWpXrtContainer" awClass={AwParseService.instance( "[ ctx.preferences['EP_ProductVariantsTiles'].includes('ConfiguratorContext') ? 'w-3' : 'w-4' ]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <span className="aw-ep-managePageColumnTitle aw-ep-managePageOverviewColumnTitle">
                    {AwParseService.instance( "i18n.overviewColumnTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </span>
                <div className="sw-column">
                    <EpInformationExistWhen viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{scopeObject:ctx.ep.scopeObject,xrtType:'INFO'}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "ctx.ep && ctx.ep.scopeObject" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </EpInformationExistWhen>
                </div>
            </DivAwClass>
            <DivExistWhenAwClass className="sw-column afx-content-background aw-ep-managePageColumn aw-ep-managePageTilesColumn" existWhen={AwParseService.instance( "conditions.isEpTaskPageContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} awClass={AwParseService.instance( "[ ctx.preferences['EP_ProductVariantsTiles'].includes('ConfiguratorContext') ? 'w-6' : 'w-4' ]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <span className="aw-ep-managePageColumnTitle aw-ep-managePageTilesColumnTitle">
                    {AwParseService.instance( "i18n.contentColumnTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </span>
                <EpManageTilesArea>
                </EpManageTilesArea>
            </DivExistWhenAwClass>
            <DivAwClass className="sw-column aw-ep-managePageColumn aw-ep-managePageColumnContainer" awClass={AwParseService.instance( "[ ctx.preferences['EP_ProductVariantsTiles'].includes('ConfiguratorContext') ? 'w-3' : 'w-4' ]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <span className="aw-ep-managePageColumnTitle aw-ep-managePageTasksColumnTitle">
                    {AwParseService.instance( "i18n.tasksColumnTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </span>
                <EpTasksNavigationPanel>
                </EpTasksNavigationPanel>
            </DivAwClass>
        </div>
    </AwSublocation>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;