// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "priority": 2,
            "label": {
                "source": "/i18n/ProjmgmtConstants",
                "key": "myProjectListTitle"
            },
            "clientScopeURI": "Aut0MyProjectList",
            "nameToken": "com.siemens.splm.client.projmgmt:myProjectListSubLocation",
            "context": {
                "search": {
                    "provider": "Aut0ProjectProvider",
                    "sortType": "Alphabetical",
                    "maxToLoad": 50,
                    "maxToReturn": 50,
                    "tableViewProperties": [
                        "relation",
                        "awp0Relationship"
                    ],
                    "criteria": {
                        "activeProjectsOnly": "true",
                        "clientId": "ProjectContext",
                        "privilegedProjectsOnly": "false",
                        "programsOnly": ""
                    },
                    "showLoadingText": true
                }
            },
            "breadcrumbConfig": {
                "type": "search",
                "vm": "AwSearchBreadcrumb"
            },
            "policy": "/policies/projectObjectPropertyPolicy.json",
            "viewBase": "myProjectList"
        }
    },
    "_viewModelId": "myFavProjectList",
    "_uniqueViewModelId": "myFavProjectList",
    "ctx": {}
};

    /*
    <aw-search-page tabsdata="subPanelContext.tabsData"  provider="data._provider"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;