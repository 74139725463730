// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSublocation from 'viewmodel/AwSublocationViewModel';
import MfeLoadingMessage from 'viewmodel/MfeLoadingMessageViewModel';
import AwInclude from 'viewmodel/AwIncludeViewModel';
import AwIcon from 'viewmodel/AwIconViewModel';
import AwI18n from 'viewmodel/AwI18nViewModel';
    import { ExistWhen,AwClick,AwClass,ShowWhen } from 'js/hocCollection';
const MfeLoadingMessageExistWhen = ExistWhen(MfeLoadingMessage);
const DivExistWhen = ExistWhen('div');
const AwIncludeExistWhen = ExistWhen(AwInclude);
const DivAwClickAwClassShowWhen = ShowWhen(AwClass(AwClick('div')));
MfeLoadingMessageExistWhen.displayName = 'MfeLoadingMessageExistWhen';
DivExistWhen.displayName = 'DivExistWhen';
AwIncludeExistWhen.displayName = 'AwIncludeExistWhen';
DivAwClickAwClassShowWhen.displayName = 'DivAwClickAwClassShowWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwBaseSublocationService', () => import('js/AwBaseSublocationService'));
registerDynImportEntry('js/lineLayoutService', () => import('js/lineLayoutService'));
registerDynImportEntry('js/popupService', () => import('js/popupService'));
registerDynImportEntry('viewmodel/AttachLineLayoutPopupViewModel', () => import('viewmodel/AttachLineLayoutPopupViewModel'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "provider": {
            "label": "{{i18n.lineLayoutPageTitle}}",
            "priority": 3,
            "name": "lineLayoutPage",
            "clientScopeURI": "lineLayoutPage",
            "nameToken": "lineLayoutPage"
        },
        "awSubLocationPref": {
            "hideNavigationArea": true,
            "hideRightWall": true,
            "hideToolsAndInfoArea": true
        },
        "loading": true
    },
    "actions": {
        "initializeEPPage": {
            "actionType": "JSFunctionAsync",
            "method": "initializePage",
            "inputData": {
                "provider": "{{data.provider}}"
            },
            "outputData": {
                "subLocationPreference": "subLocationPreference",
                "stateContext": "stateContext",
                "propertyPolicyId": "propertyPolicyId"
            },
            "deps": "js/AwBaseSublocationService"
        },
        "setLoading": {
            "actionType": "JSFunction",
            "method": "setLoading",
            "inputData": {},
            "outputData": {
                "loading": ""
            },
            "deps": "js/lineLayoutService"
        },
        "openPopup": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "openAttachPopup",
                    "condition": "!conditions.isReadOnlyMode"
                }
            ]
        },
        "openAttachPopup": {
            "actionType": "popup",
            "method": "show",
            "deps": "js/popupService",
            "inputData": {
                "options": {
                    "view": "AttachLineLayoutPopup",
                    "draggable": true,
                    "width": "400",
                    "caption": "{{i18n.attachFile}}",
                    "preset": "modal",
                    "subPanelContext": {
                        "lineModelObject": "{{fields.headerExample}}"
                    }
                }
            }
        }
    },
    "onEvent": [
        {
            "eventId": "lineLayoutPage.setLoading",
            "action": "setLoading"
        }
    ],
    "i18n": {
        "lineLayout": [
            "LayoutMessages"
        ],
        "addLayout": [
            "LayoutMessages"
        ],
        "attachFile": [
            "LayoutMessages"
        ]
    },
    "lifecycleHooks": {
        "onMount": "initializeEPPage"
    },
    "_viewModelId": "LineLayoutPage",
    "_uniqueViewModelId": "LineLayoutPage",
    "ctx": {
        "ep": {
            "type": "object"
        }
    }
};

    /*
    <aw-sublocation sub-location-preference="data.awSubLocationPref" sub-panel-context="{provider : data.provider}" class="aw-lineLayout-imageViewer afx-content-background">
    <mfe-loading-message class="w-12 h-12" exist-when="data.loading === true"></mfe-loading-message>

    <div class="w-12 h-12" exist-when="data.loading === false">
        <aw-include name="{{ctx.ep.lineLayoutViewerData.fileData.viewer}}" sub-panel-context="ctx.ep.lineLayoutViewerData" class="aw-workinstr-viewerStyle" exist-when="ctx.ep.lineLayoutViewerData"></aw-include>

        <div aw-click="actions.openPopup" aw-class="['aw-lineLayout-associatedElementCommandsPanel', {'aw-lineLayout-disabled':conditions.isReadOnlyMode}]" title="{{i18n.addLayout}}" show-when="!ctx.ep.lineLayoutViewerData">
            <aw-icon class="aw-commands-svg" icon-id="cmdAdd"></aw-icon>
            <aw-i18n class="aw-lineLayout-commandsLabel">i18n.addLayout</aw-i18n>
        </div>
    </div>
</aw-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSublocation subLocationPreference={AwParseService.instance( "data.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{provider : data.provider}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-lineLayout-imageViewer afx-content-background">
        <MfeLoadingMessageExistWhen className="w-12 h-12" existWhen={AwParseService.instance( "data.loading === true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </MfeLoadingMessageExistWhen>
        <DivExistWhen className="w-12 h-12" existWhen={AwParseService.instance( "data.loading === false" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwIncludeExistWhen viewId={AwParseService.instance( "ctx.ep.lineLayoutViewerData.fileData.viewer" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} name={AwParseService.instance( "ctx.ep.lineLayoutViewerData.fileData.viewer" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "ctx.ep.lineLayoutViewerData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-workinstr-viewerStyle" existWhen={AwParseService.instance( "ctx.ep.lineLayoutViewerData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwIncludeExistWhen>
            <DivAwClickAwClassShowWhen title={AwParseService.instance( "i18n.addLayout" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} awClick={AwParseService.instance( "actions.openPopup" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} awClass={AwParseService.instance( "['aw-lineLayout-associatedElementCommandsPanel', {'aw-lineLayout-disabled':conditions.isReadOnlyMode}]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} showWhen={AwParseService.instance( "!ctx.ep.lineLayoutViewerData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwIcon className="aw-commands-svg" iconId="cmdAdd">
                </AwIcon>
                <AwI18n className="aw-lineLayout-commandsLabel">
                    {i18n.addLayout}
                </AwI18n>
            </DivAwClickAwClassShowWhen>
        </DivExistWhen>
    </AwSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;