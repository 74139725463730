// @<COPYRIGHT>@
// ==================================================
// Copyright 2019.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import eventBus from "js/eventBus";
import $ from "jquery";
import soaSvc from "soa/kernel/soaService";
import appCtxSvc from "js/appCtxService";
import AwPromiseService from "js/awPromiseService";

let exports = {};

export let addPartitionFilter = function () {
  let promise = getExistingFilters();
  $.when(promise).done(function (savedRecipe) {
    let inx, jnx, knx, lnx, mnx;
    //console.log(JSON.stringify(savedRecipe));

    let currentPartnScheme = null;
    if (
      appCtxSvc.getCtx(
        "occmgmtContext.productContextInfo.props.fgf0PartitionScheme.dbValues[0]"
      ) != null
    ) {
      currentPartnScheme = appCtxSvc.getCtx(
        "occmgmtContext.productContextInfo.props.fgf0PartitionScheme.uiValues[0]"
      );
    }

    let newFilter = {
      recipe: savedRecipe.recipe,
      fetchUpdatedFilters: false,
      searchFilterFieldSortType: "Priority",
      searchSortCriteria: [],
      searchFilterMap: {},
    };

    let partitionRecipe = {
      criteriaOperatorType: "Include",
      criteriaType: "Partition",
      criteriaValues: [],
    };

    let partitionFilterMap_sample = {
      count: 0,
      endDateValue: "",
      endNumericValue: 0,
      hasChildren: true,
      searchFilterType: "Partition",
      selected: true,
      startDateValue: "",
      startEndRange: "",
      startNumericValue: 0,
      stringDisplayValue: "dummy",
      stringValue: "",
    };

    let partitionFilterCategories_sample = [
      {
        categoryType: "Partition",
        defaultFilterValueDisplayCount: 0,
        displayName: "", //get relevant partition filter from getSubsetInfo2 soa response
        editable: true,
        internalName: "",
        isHierarchical: false,
        isMultiSelect: false,
        quickSearchable: false,
      },
    ];

    if (currentPartnScheme != null) {
      for (inx = 0; inx < savedRecipe.searchFilterCategories.length; inx++) {
        if (
          savedRecipe.searchFilterCategories[inx].displayName ==
            currentPartnScheme &&
          savedRecipe.searchFilterCategories[inx].categoryType == "Partition"
        ) {
          //search filter category
          newFilter.searchFilterCategories = partitionFilterCategories_sample;
          newFilter.searchFilterCategories[0].internalName =
            savedRecipe.searchFilterCategories[inx].internalName;

          //search filter map
          newFilter.searchFilterMap[
            savedRecipe.searchFilterCategories[inx].internalName
          ] = [];
          for (jnx = 0; jnx < appCtxSvc.getCtx("mselected").length; jnx++) {
            partitionRecipe.criteriaValues.push(
              appCtxSvc.getCtx(
                "mselected["+jnx+"].props.awb0UnderlyingObject.dbValues[0]"
              )
            );
          }
          //remove any parent partitions, In case if both parent-child partitions are selected at the same time
          for (jnx = 0; jnx < appCtxSvc.getCtx("mselected").length; jnx++) {
            for (knx = 0; knx < partitionRecipe.criteriaValues.length; knx++) {
              if (
                appCtxSvc
                  .getCtx("mselected["+jnx+"].props.awb0Parent.dbValues[0]")
                  .indexOf(partitionRecipe.criteriaValues[knx]) > -1
              ) {
                partitionRecipe.criteriaValues.splice(knx, 1);
              }
            }
          }

          for (jnx = 0; jnx < partitionRecipe.criteriaValues.length; jnx++) {
            newFilter.searchFilterMap[
              savedRecipe.searchFilterCategories[inx].internalName
            ].push(JSON.parse(JSON.stringify(partitionFilterMap_sample)));
            newFilter.searchFilterMap[
              savedRecipe.searchFilterCategories[inx].internalName
            ][jnx].stringValue = partitionRecipe.criteriaValues[jnx];
          }

          partitionRecipe.criteriaValues.push("1");

          let recipeAlreadyExists = false;
          if (newFilter.recipe.length > 0) {
            for (knx = 0; knx < newFilter.recipe.length; knx++) {
              if (
                newFilter.recipe[knx].criteriaDisplayValue.split("_$CAT_")
                  .length > 0
              ) {
                if (
                  newFilter.recipe[knx].criteriaDisplayValue.split(
                    "_$CAT_"
                  )[0] == currentPartnScheme
                ) {
                  //check if recipe is already present for the partition scheme
                  recipeAlreadyExists = true;

                  /**
                   * USE CASE: If currently selected partitions(to be added to existing recipe) already has one or more parent partitons present inside the recipe
                   *          criteriaValues then the parent partition uids need to be removed from the recipe criteriaValues array... and the uids of the
                   *          selected partitions(children) needs to be added the criteriaValues array.
                   */
                  //create array of newly selected partitions to be added to the recipe
                  let currentSelectedPartitions = [];
                  for (
                    lnx = 0;
                    lnx < appCtxSvc.getCtx("mselected").length;
                    lnx++
                  ) {
                    currentSelectedPartitions.push(
                      appCtxSvc.getCtx("mselected["+lnx+"].uid")
                    );
                  }
                  //remove if there is any parent partition of the currentSelectedPartitions, already present inside criteria values of recipe
                  for (
                    lnx = 0;
                    lnx < newFilter.recipe[knx].criteriaValues.length;
                    lnx++
                  ) {
                    for (
                      mnx = 0;
                      mnx < currentSelectedPartitions.length;
                      mnx++
                    ) {
                      if (
                        currentSelectedPartitions[mnx].indexOf(
                          newFilter.recipe[knx].criteriaValues[lnx]
                        ) > -1
                      ) {
                        newFilter.recipe[knx].criteriaValues.splice(lnx, 1);
                      }
                    }
                  }
                  // Add the child partitions into the criteria values array

                  // Pop '1' from the end of the array before pushing new partition uids
                  if (
                    newFilter.recipe[knx].criteriaValues[
                      newFilter.recipe[knx].criteriaValues.length - 1
                    ] == "1"
                  ) {
                    newFilter.recipe[knx].criteriaValues.pop();
                  }
                  for (
                    lnx = 0;
                    lnx < appCtxSvc.getCtx("mselected").length;
                    lnx++
                  ) {
                    newFilter.recipe[knx].criteriaValues.push(
                      appCtxSvc.getCtx(
                        "mselected["+lnx+"].props.awb0UnderlyingObject.dbValues[0]"
                      )
                    );
                  }
                  //remove any parent partitions, In case if both parent-child partitions are selected at the same time
                  for (
                    mnx = 0;
                    mnx < appCtxSvc.getCtx("mselected").length;
                    mnx++
                  ) {
                    for (
                      let nnx = 0;
                      nnx < partitionRecipe.criteriaValues.length;
                      nnx++
                    ) {
                      if (
                        appCtxSvc.getCtx(
                          "mselected[mnx].props.awb0Parent.dbValues[0].indexOf(partitionRecipe.criteriaValues[nnx]"
                        ) > -1
                      ) {
                        partitionRecipe.criteriaValues.splice(nnx, 1);
                      }
                    }
                  }
                  newFilter.recipe[knx].criteriaValues.push("1");
                }
              }
            }
          }
          if (recipeAlreadyExists) {
            //console.log('do nothing');
          } else {
            newFilter.recipe.push(partitionRecipe);
          }

          applyPartitionfilter(newFilter);
          break;
        }
      }
    }
  });
};

let getExistingFilters = function () {
  let deferred = AwPromiseService.instance.defer();
  let savedRecipe = {};
  //let mselected = ctx.selected;

  let getSubsetInfo_inputData = {
    subsetInputs: [
      {
        productInfo: {
          type: "Awb0ProductContextInfo",
          uid: appCtxSvc.getCtx("occmgmtContext.productContextInfo.uid"),
        },
        recipe: [],
        requestPref: {
          populateFilters: ["true"],
        },
        searchFilterFieldSortType: "",
        searchSortCriteria: [],
      },
    ],
  };

  soaSvc
    .post(
      "Internal-ActiveWorkspaceBom-2016-03-OccurrenceManagement",
      "getSubsetInfo2",
      getSubsetInfo_inputData
    )
    .then(function (subsetInfoResponse) {
      if (subsetInfoResponse) {
        savedRecipe = subsetInfoResponse.filterOut[0];
        deferred.resolve(savedRecipe);
      }
    });

  return deferred.promise;
};

function applyPartitionfilter(newFilter) {
  let deferred = AwPromiseService.instance.defer();

  //Both Atrribute and Partition type filters can be handled in the same loop

  let inputData = {
    inputData: {
      config: {
        effectivityDate: "0001-01-01T00:00:00",
        unitNo: -1,
        productContext: {
          uid: appCtxSvc.getCtx("occmgmtContext.productContextInfo.uid"),
        },
      },
      filter: {},
      requestPref: {
        calculateFilters: ["false"],
        displayMode: ["Tree"],
        filterCriteriaOperatorType: ["Include"],
        includePath: ["true"],
        populateFilters: ["true"],
        savedSessionMode: ["restore"],
        startFreshNavigation: ["false"],
        useGlobalRevRule: ["false"],
        viewType: ["BOM"],
      },
      product: {
        uid: appCtxSvc.getCtx(
          "occmgmtContext.topElement.props.awb0UnderlyingObject.dbValues[0]"
        ),
      },
      //parentElement: ctx.selected.props.awb0Parent.dbValues[0]
      parentElement: appCtxSvc.getCtx("selected.props.awb0Parent.dbValues[0]"),
    },
  };

  inputData.inputData.filter = newFilter;

  soaSvc
    .post(
      "Internal-ActiveWorkspaceBom-2019-12-OccurrenceManagement",
      "getOccurrences3",
      inputData
    )
    .then(function () {
      eventBus.publish("acePwa.reset", {
        retainTreeExpansionStates: true,
      });
      deferred.resolve("filter applied ");
    });
  return deferred.promise;
}

exports = {
  addPartitionFilter,
};

export default exports;
