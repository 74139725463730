// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwBreak from 'viewmodel/AwBreakViewModel';
import AwLabel from 'viewmodel/AwLabelViewModel';
    import { ExistWhen } from 'js/hocCollection';
const DivExistWhen = ExistWhen('div');
DivExistWhen.displayName = 'DivExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-panel.directive",
        "js/aw-label.directive",
        "js/aw-break.directive",
        "js/aw-panel-section.directive",
        "js/aw-panel-body.directive"
    ],
    "data": {
        "adminMsg1": "{{i18n.adminMsg1}}",
        "allUserMsg1": [
            "{{i18n.allUserMsg1a}}",
            "{{i18n.allUserMsg1b}}"
        ],
        "allUserMsg2": {
            "displayName": "{{i18n.scopeLabel}}",
            "dispValue": "{{i18n.scopeHelpMessage}}"
        },
        "allUserMsg3": {
            "displayName": "{{i18n.locationLabel}}",
            "dispValue": "{{i18n.locationHelpMessage}}"
        },
        "allUserMsg4": {
            "displayName": "{{i18n.productAreaLabel}}",
            "dispValue": "{{i18n.productAreaHelpMessage}}"
        }
    },
    "i18n": {
        "adminMsg1": [
            "preferenceInfoMessages"
        ],
        "allUserMsg1a": [
            "preferenceInfoMessages"
        ],
        "allUserMsg1b": [
            "preferenceInfoMessages"
        ],
        "columns": [
            "preferenceInfoMessages"
        ],
        "scopeHelpMessage": [
            "preferenceInfoMessages"
        ],
        "locationHelpMessage": [
            "preferenceInfoMessages"
        ],
        "productAreaHelpMessage": [
            "preferenceInfoMessages"
        ],
        "scopeLabel": [
            "preferenceInfoMessages"
        ],
        "locationLabel": [
            "preferenceInfoMessages"
        ],
        "productAreaLabel": [
            "preferenceInfoMessages"
        ]
    },
    "conditions": {
        "isUserAdmin": {
            "expression": "ctx.tcadmconsole.preferences.isUserSystemAdmin === true || ctx.tcadmconsole.preferences.isUserGroupAdmin === true"
        }
    },
    "_viewModelId": "helpMessage",
    "_uniqueViewModelId": "helpMessage",
    "ctx": {
        "tcadmconsole": {
            "type": "object"
        }
    }
};

    /*
    <aw-panel>
    <aw-panel-body>
        <aw-panel-section>
            <div exist-when="conditions.isUserAdmin">{{data.adminMsg1}} </div>
            <aw-break></aw-break>
            <aw-break></aw-break>
            <div aw-repeat="message : data.allUserMsg1 ">
                <div> {{message}} </div>
                <aw-break></aw-break>
                <aw-break></aw-break>
            </div>
        </aw-panel-section>

        <aw-panel-section caption="i18n.columns">
            <aw-label prop="data.allUserMsg2"></aw-label>
            <aw-label prop="data.allUserMsg3"></aw-label>
            <aw-label prop="data.allUserMsg4"></aw-label>
        </aw-panel-section>
    </aw-panel-body>
</aw-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanel  revealAction={actions.reveal}>
        <AwPanelBody>
            <AwPanelSection>
                <DivExistWhen existWhen={AwParseService.instance( "conditions.isUserAdmin" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    {AwParseService.instance( "data.adminMsg1" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </DivExistWhen>
                <AwBreak>
                </AwBreak>
                <AwBreak>
                </AwBreak>
                { Object.entries( AwParseService.instance( "data.allUserMsg1" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, message ] ) => (
                    <div key={$index}>
                        <div>
                            {AwParseService.instance( "message" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, message, $index } )}
                        </div>
                        <AwBreak>
                        </AwBreak>
                        <AwBreak>
                        </AwBreak>
                    </div>
                ) ) }
            </AwPanelSection>
            <AwPanelSection caption={i18n.columns}>
                <AwLabel {...getField("data.allUserMsg2", fields, $index, null, null )}>
                </AwLabel>
                <AwLabel {...getField("data.allUserMsg3", fields, $index, null, null )}>
                </AwLabel>
                <AwLabel {...getField("data.allUserMsg4", fields, $index, null, null )}>
                </AwLabel>
            </AwPanelSection>
        </AwPanelBody>
    </AwPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;