// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwCommandPanel from 'viewmodel/AwCommandPanelViewModel';
import AwCommandSubPanel from 'viewmodel/AwCommandSubPanelViewModel';
import AwNavigatePanel from 'viewmodel/AwNavigatePanelViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const AwCommandSubPanelVisibleWhen = VisibleWhen(AwCommandSubPanel);
const AwNavigatePanelVisibleWhen = VisibleWhen(AwNavigatePanel);
AwCommandSubPanelVisibleWhen.displayName = 'AwCommandSubPanelVisibleWhen';
AwNavigatePanelVisibleWhen.displayName = 'AwNavigatePanelVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Ics1ClassificationTabService', () => import('js/Ics1ClassificationTabService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "lifecycleHooks": {
        "onMount": "initialize",
        "onUnmount": "cleanUpSelectionData"
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        }
    },
    "ctx": {
        "mselected": {
            "type": "object"
        }
    },
    "data": {
        "activeView": "Awp0ClassificationSub",
        "selectionData": {
            "initialValues": {},
            "meta": {}
        },
        "classifyState": {
            "initialValues": {
                "attrs": null,
                "expandAllBlocks": false,
                "isClassify": true,
                "panelMode": -1,
                "shouldSaveEdits": false,
                "showTabTree": true,
                "editProperties": false
            },
            "meta": {
                "panelMode": {
                    "type": "INTEGER",
                    "field": "true"
                },
                "attrs": [
                    {
                        "attr": {
                            "field": "true"
                        }
                    }
                ],
                "numOfAttrs": {
                    "type": "INTEGER",
                    "field": "true"
                },
                "currentUnitSystem": {
                    "type": "object",
                    "field": "true"
                },
                "editClassUID": {
                    "type": "STRING"
                },
                "expandAllBlocks": {
                    "type": "BOOLEAN"
                },
                "shouldRerenderForExpandCollapse": {
                    "type": "object"
                },
                "shouldSaveEdits": {
                    "field": "true",
                    "type": "BOOLEAN"
                },
                "shouldSave": {
                    "field": "true",
                    "type": "BOOLEAN"
                }
            }
        },
        "responseState": {
            "initialValues": {},
            "meta": {
                "blockDefinitionMap": {
                    "type": "object",
                    "field": "true"
                }
            }
        }
    },
    "actions": {
        "initialize": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "addWorkspaceObjectUidtoData"
                }
            ]
        },
        "addWorkspaceObjectUidtoData": {
            "actionType": "JSFunction",
            "method": "addWorkspaceObjectUidtoData",
            "inputData": {
                "subPanelContext": "{{props.subPanelContext}}",
                "mselected": "{{ctx.mselected}}"
            },
            "outputData": {
                "workspaceObjectUID": "result.uid",
                "selectedItem": "result.selectedItem"
            },
            "deps": "js/Ics1ClassificationTabService"
        },
        "initializeClassifyPanel": {
            "actionType": "JSFunction",
            "method": "initializeClassifyPanel",
            "inputData": {
                "classifyState": "{{data.classifyState}}",
                "classifyStateUpdater": "{{data.updateAtomicData}}"
            },
            "outputData": {
                "classifyState": "classifyState"
            },
            "deps": "js/Ics1ClassificationTabService"
        },
        "closePanel": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "complete",
                        "eventData": {
                            "source": "toolAndInfoPanel"
                        }
                    }
                ]
            }
        },
        "cleanUpSelectionData": {
            "actionType": "JSFunctionAsync",
            "method": "cleanUpSelectionData",
            "inputData": {
                "parentSelectedData": "{{fields.selectionData}}"
            },
            "deps": "js/Ics1ClassificationTabService"
        }
    },
    "conditions": {
        "selectedClass": {
            "expression": "classifyState.selectedClass !== null"
        }
    },
    "messages": {},
    "onEvent": [
        {
            "eventId": "primaryWorkArea.selectionChangeEvent",
            "action": "closePanel"
        },
        {
            "eventId": "classifyPanel.closePanel",
            "action": "closePanel"
        }
    ],
    "i18n": {
        "addClassification": [
            "ClassificationPanelMessages"
        ],
        "Classify": [
            "ClassificationPanelMessages"
        ]
    },
    "_viewModelId": "Awp0Classification",
    "_uniqueViewModelId": "Awp0Classification"
};

    /*
    <aw-command-panel caption="i18n.Classify" >
    <!-- View mode-->
    <aw-command-sub-panel panel-id="Awp0ClassificationSub"
        sub-panel-context="{...props.subPanelContext, selectionData: data.selectionData, classifyState: fields.classifyState, responseState: fields.responseState, workspaceObjectUID:data.workspaceObjectUID }"
        visible-when="fields.classifyState.value.panelMode === -1 || fields.classifyState.value.editProperties">
    </aw-command-sub-panel>
    <!-- Create/Edit mode-->
    <aw-navigate-panel title="i18n.addClassification" panel-id='Awp0CreateClassificationSub'
        sub-panel-context="{...props.subPanelContext, selectionData: data.selectionData, classifyState: fields.classifyState, responseState: fields.responseState, workspaceObjectUID:data.workspaceObjectUID, selected: data.selectedItem }"
         visible-when="fields.classifyState.value.panelMode !== -1 && fields.classifyState.value.panelMode !== 1" action="initializeClassifyPanel">
    </aw-navigate-panel>
 </aw-command-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwCommandPanel revealAction={actions.reveal} updateActiveView={AwParseService.instance( "{activeView: data.activeView, dispatch: dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} caption={i18n.Classify}>
        <AwCommandSubPanelVisibleWhen panelId="Awp0ClassificationSub" subPanelContext={AwParseService.instance( "{...props.subPanelContext, selectionData: fields.selectionData, classifyState: fields.classifyState, responseState: fields.responseState, workspaceObjectUID:data.workspaceObjectUID }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} visibleWhen={AwParseService.instance( "fields.classifyState.value.panelMode === -1 || fields.classifyState.value.editProperties" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwCommandSubPanelVisibleWhen>
        <AwNavigatePanelVisibleWhen title={i18n.addClassification} panelId="Awp0CreateClassificationSub" subPanelContext={AwParseService.instance( "{...props.subPanelContext, selectionData: fields.selectionData, classifyState: fields.classifyState, responseState: fields.responseState, workspaceObjectUID:data.workspaceObjectUID, selected: data.selectedItem }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.initializeClassifyPanel} visibleWhen={AwParseService.instance( "fields.classifyState.value.panelMode !== -1 && fields.classifyState.value.panelMode !== 1" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwNavigatePanelVisibleWhen>
    </AwCommandPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;