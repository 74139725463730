// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/advancedSearchService', () => import('js/advancedSearchService'));
registerDynImportEntry('js/appCtxService', () => import('js/appCtxService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "priority": 0,
            "label": {
                "source": "/i18n/ActiveCollabDeclarativeMessages",
                "key": "feed"
            },
            "breadcrumbConfig": {
                "type": "search",
                "vm": "Ac0FeedBreadcrumb",
                "noBreadCrumb": "false",
                "contextName": "feed",
                "noCriteriaSpecifiedMessage": {
                    "source": "/i18n/ActiveCollabDeclarativeMessages",
                    "key": "feedSubLocationDefaultMessage"
                },
                "noResultsFoundMessage": {
                    "source": "/i18n/ActiveCollabDeclarativeMessages",
                    "key": "feedSubLocationDefaultMessage"
                },
                "resultsFoundMessage": {
                    "source": "/i18n/ActiveCollabDeclarativeMessages",
                    "key": "feedSubLocationDefaultMessage"
                }
            },
            "clientScopeURI": "Ac0CollaborationFeed",
            "nameToken": "com_siemens_splm_client_activecollab_DiscussionsLocation:feedSubLocation",
            "context": {
                "istcgql": true,
                "search": {
                    "hideInContentSearchBox": true
                }
            },
            "selectionMode": "single",
            "viewModes": {
                "SummaryView": {
                    "primaryWorkArea": "list",
                    "secondaryWorkArea": true
                }
            },
            "defaultDisplayMode": "SummaryView",
            "viewBase": "feed",
            "isXrtApplicable": false
        },
        "searchState": {
            "initialValues": {
                "provider": "Awp0SavedQuerySearchProvider",
                "advancedSearchCriteria": "",
                "savedQuery": {
                    "name": "",
                    "value": ""
                }
            },
            "meta": {}
        }
    },
    "actions": {
        "initialize": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeSearchState"
                }
            ]
        },
        "initializeSearchState": {
            "actionType": "JSFunction",
            "method": "createStateFromUrl",
            "inputData": {
                "searchState": "{{data.searchState}}",
                "searchStateUpdater": "{{data.updateAtomicData}}"
            },
            "deps": "js/advancedSearchService"
        },
        "unmountAction": {
            "actionType": "JSFunction",
            "deps": "js/appCtxService",
            "method": "unRegisterCtx",
            "inputData": {
                "0": "ac0FeedFilter"
            }
        }
    },
    "lifecycleHooks": {
        "onMount": "initialize",
        "onUnmount": "unmountAction"
    },
    "_viewModelId": "Ac0MyDiscussionFeed",
    "_uniqueViewModelId": "Ac0MyDiscussionFeed",
    "ctx": {}
};

    /*
    <aw-search-page showsearchbox="false" tabsdata="subPanelContext.tabsData"  provider="data._provider"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage showsearchbox={AwParseService.instance( "false" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;