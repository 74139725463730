// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import MbmAlignment from 'viewmodel/MbmAlignmentViewModel';
    import { ExistWhen } from 'js/hocCollection';
const MbmAlignmentExistWhen = ExistWhen(MbmAlignment);
MbmAlignmentExistWhen.displayName = 'MbmAlignmentExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/mbmSubLocationService', () => import('js/mbmSubLocationService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "splitViewConfig": {
            "mode": true,
            "isSkipClearingRHS3D": true,
            "viewKeys": [
                "ebomContext",
                "mbomContext"
            ]
        },
        "mbmContext": {
            "initialValues": {
                "isOpenWithCsp": false,
                "mbmFocusModeSelected": false,
                "mbmEnableFocus": false,
                "detailsContentState": {
                    "ebomContext": {
                        "showDetails": false,
                        "showOnBottom": false,
                        "showOnSide": false,
                        "initialize3DViewer": false
                    },
                    "mbomContext": {
                        "showDetails": false,
                        "showOnSide": false,
                        "showOnBottom": false,
                        "initialize3DViewer": false
                    }
                },
                "greyoutElementSelected": {
                    "ebomContext": null,
                    "mbomContext": null
                },
                "selectedScope": {
                    "ebomContext": null,
                    "mbomContext": null
                },
                "selectedObjects": {
                    "ebomContext": [],
                    "mbomContext": []
                },
                "asyncMode": {
                    "ebomContext": false,
                    "mbomContext": false
                },
                "isScopeElementSelected": {
                    "ebomContext": false,
                    "mbomContext": false
                },
                "topElementSelected": {
                    "ebomContext": false,
                    "mbomContext": false
                },
                "partSelected": {
                    "ebomContext": false,
                    "mbomContext": false
                },
                "parentChildSelected": {
                    "ebomContext": false,
                    "mbomContext": false
                },
                "focusedScopeUids": {
                    "ebomContext": null,
                    "mbomContext": null
                },
                "onDemandCompare": {
                    "showAssignmentState": null,
                    "selected": false
                },
                "definedBoMTypeElementSelected": {
                    "ebomContext": false,
                    "mbomContext": false
                },
                "contextMenuSelected": {
                    "isCut": false,
                    "isCopy": false
                },
                "mbmImportExcelInfo": {
                    "importInProgress": false
                },
                "isFocusOrUnfocusModeWith3DActivated": {
                    "ebomContext": false,
                    "mbomContext": false
                }
            },
            "meta": {}
        },
        "mbmTaskBarContext": {
            "initialValues": {
                "mbmOnDemandCompareInfo": {
                    "OnDemandCompareSelected": false,
                    "showAssignmentState": false
                },
                "partialComapareInfo": {
                    "selectedNodes": [],
                    "contextKey": "",
                    "showPartialCompareBaner": false,
                    "refresh": false,
                    "refreshLable": "refresh",
                    "dismiss": false,
                    "dismissLable": "dismiss"
                }
            },
            "meta": {}
        }
    },
    "actions": {
        "mbmInitializeSplitView": {
            "actionType": "JSFunction",
            "method": "initializeSplitView",
            "inputData": {
                "splitViewConfig": "{{data.splitViewConfig}}"
            },
            "deps": "js/mbmSubLocationService"
        },
        "mbmInitializeStateData": {
            "actionType": "JSFunctionAsync",
            "method": "initializeStateData",
            "outputData": {
                "mbmAlignmentInfo": {}
            },
            "deps": "js/mbmSubLocationService"
        },
        "mbmDestroyMultiBomManager": {
            "actionType": "JSFunction",
            "method": "destroyMultiBomManager",
            "inputData": {
                "mbmContext": "{{fields.mbmContext}}",
                "ccUid": "{{data.mbmAlignmentInfo.modelObjectsToOpen.ebomContextInfo.modelObject.uid}}"
            },
            "deps": "js/mbmSubLocationService"
        }
    },
    "lifecycleHooks": {
        "onMount": "mbmInitializeSplitView",
        "onUnmount": "mbmDestroyMultiBomManager"
    },
    "onEvent": [
        {
            "eventId": "ep.pageContext.changed",
            "action": "mbmInitializeStateData"
        }
    ],
    "_viewModelId": "MbmSubLocation",
    "_uniqueViewModelId": "MbmSubLocation",
    "ctx": {}
};

    /*
    <mbm-alignment exist-when="data.mbmAlignmentInfo.modelObjectsToOpen"
    sub-panel-context="{modelObjectsToOpen:data.mbmAlignmentInfo.modelObjectsToOpen, cnObject: data.mbmAlignmentInfo.cnObject, mbmContext: data.mbmContext , mbmTaskBarContext: data.mbmTaskBarContext}">
</mbm-alignment>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <MbmAlignmentExistWhen subPanelContext={AwParseService.instance( "{modelObjectsToOpen:data.mbmAlignmentInfo.modelObjectsToOpen, cnObject: data.mbmAlignmentInfo.cnObject, mbmContext: fields.mbmContext , mbmTaskBarContext: fields.mbmTaskBarContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "data.mbmAlignmentInfo.modelObjectsToOpen" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </MbmAlignmentExistWhen>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;