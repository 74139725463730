// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwFullScreen from 'viewmodel/AwFullScreenViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "_viewModelId": "multiSelectCompareText",
    "_uniqueViewModelId": "multiSelectCompareText",
    "ctx": {}
};

    /*
    <div class="sw-column aw-requirements-compareReqHistory" width="fill">
    <aw-full-screen include-component-name='multiSelectCompareTextContent' header-title='{{subPanelContext.caption}}' header-path='{{subPanelContext.activeTab.name ? subPanelContext.activeTab.name : subPanelContext.showObjectContext.activeTab.name}}' header-context-title='{{subPanelContext.context.baseSelection.props.object_string.uiValues[0]}}' context='subPanelContext'>
    </aw-full-screen>
</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="sw-column aw-requirements-compareReqHistory" width="fill">
        <AwFullScreen includeComponentName="multiSelectCompareTextContent" headerTitle={AwParseService.instance( "subPanelContext.caption" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} headerPath={AwParseService.instance( "subPanelContext.activeTab.name ? subPanelContext.activeTab.name : subPanelContext.showObjectContext.activeTab.name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} headerContextTitle={AwParseService.instance( "subPanelContext.context.baseSelection.props.object_string.uiValues[0]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} context={AwParseService.instance( "subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwFullScreen>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;