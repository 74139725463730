// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    
    

    /*
    render function
    */
    import { uicompRenderFunction } from 'js/uicompService';
    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/uicompService', () => import('js/uicompService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "lifecycleHooks": {
        "onMount": "initialize",
        "render": "render",
        "onUnmount": "cleanup"
    },
    "actions": {
        "initialize": {
            "actionType": "JSFunctionAsync",
            "method": "initializePage",
            "inputData": {},
            "outputData": {
                "headerTitle": "headerTitle"
            },
            "deps": "js/uicompService"
        },
        "render": {
            "method": "uicompRenderFunction",
            "deps": "js/uicompService"
        },
        "cleanup": {
            "actionType": "JSFunction",
            "method": "cleanupPage",
            "inputData": {},
            "deps": "js/uicompService"
        }
    },
    "_viewModelId": "uicomp",
    "_uniqueViewModelId": "uicomp",
    "ctx": {}
};

    


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, uicompRenderFunction, undefined, ctxImportObj );

    export default Component;