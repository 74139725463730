// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/DuplicateFTMService
 */

import _ from "lodash";
import soaSvc from "soa/kernel/soaService";
import AwStateService from "js/awStateService";

("use strict");

let exports = {};

let EPM_target_attachment = 1;

//Function Defination For Applying "Created" Status on FTM TKB ISSUREVISION "
/**
 * This Function is for To set Created Status on Creation of FTM Issue.
 * @function startWorkflow
 * @param {objdata} modelObjs (FTMRevision) Newly created FTMIssueRevision.
 */
let startWorkflow = function (modelObjs) {
  let FTMRevision = null;
  for (let key1 in modelObjs) {
    if (modelObjs[key1].type == "V4B_FTMTKB_IssueRevision") {
      FTMRevision = modelObjs[key1].uid;
    }
  }

  let input = {
    processName: "Vim_set_status",
    processDescription: "Vim_set_status",
    processTemplateName: "VIM_set_created_status",
    attachments: [FTMRevision],
    attachmentTypes: [EPM_target_attachment],
  };
  let request = {
    inputData: input,
  };
  soaSvc.post(
    "Internal-Workflow-2017-11-Workflow",
    "createWorkflowAsync",
    request
  );
};
//Execution of function start from here

/**
 * This function is for Open newly created FTM Issue Revision in Edit mode.
 * @function saveAsNewItemResponse
 * @param {object} responseData output response from the service called from module.json
 */
export let saveAsNewItemResponse = function (responseData) {
  let modelObjs = responseData.ServiceData.modelObjects;

  // find uid in responseData modelObjects
  let uid_final = "";
  for (let key in modelObjs) {
    if (modelObjs[key].type == "V4B_FTMTKB_IssueRevision") {
      uid_final = modelObjs[key].uid;
    }
  }

  // Calling function for applying Status
  //startWorkflow(modelObjs);
  // calling showObjectCommandHandler to open the created object in edit mode
  /// PARAMS for .execute() function:
  /// First parameter is vmo json
  /// Second - DON'T KNOW/CARE :)
  /// THIRD - boolean - specifies whether to open object in Edit mode

  let showObject = "com_siemens_splm_clientfx_tcui_xrt_showObject";
  let toParams = {};
  let options = {};

  toParams.uid = uid_final;
  toParams.cmdId = "Awp0StartEdit";
  options.inherit = false;

  AwStateService.instance.go(showObject, toParams, options);
};

export let getDuplicatedFTMandVconCreateRelInput = function (data, ctx) {
  let ftmUID = null;
  let creRelInput = [];
  for (var i = 0; i < data.ftmRevisionList.length; i++) {
    if (!data.ftmRevisionList[i].uid) {
      ftmUID = data.ftmRevisionList[i].objUID;
    }
    else {
      ftmUID = data.ftmRevisionList[i].uid;
    }
    creRelInput.push(
      {
        clientId: "VIMContainer",
        relationType: "V4B_Container_VIM_FTM_Rel",
        primaryObject: ctx.xrtSummaryContextObject,
        secondaryObject: {
          "uid": ftmUID,
          type: "V4B_FTMTKB_IssueRevision",
        },
      }
    )
  }
  return creRelInput;
};
export let setPropertiesOnDuplicateFTMIssuesInput = function (data) {
  let infoList = [];
  for (let i = 0; i < data.ftmRevisionList.length; i++) {   
    let infoObj = {
      object: {
        uid: data.ftmRevisionList[i].objUID,
        type: "V4B_FTMTKB_IssueRevision"
      },
      vecNameVal: [
        {
          name: "v4b_IssueTransMatrix_Anchor",
          values: [
            ""
          ]
        },
        {
          name: "v4b_IssueTransMatrix_LblOff",
          "values": [
            ""
          ]
        }
      ],
      timestamp: ""
    }
    infoList.push(infoObj);
  }
   return infoList

}
export let setPropertiesOptionsDuplicateFTMIssuesInput = function (data) {
  let optionList = [];
  for (let i = 0; i < data.ftmRevisionList.length; i++) { 
    optionList.push("NO");
  }
   return optionList;

}

exports = {
  saveAsNewItemResponse,
  getDuplicatedFTMandVconCreateRelInput,
  setPropertiesOnDuplicateFTMIssuesInput,
  setPropertiesOptionsDuplicateFTMIssuesInput
};

export default exports;
