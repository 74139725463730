// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/userMgmtService', () => import('js/userMgmtService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "type": "navigate",
                "vm": "orgBreadCrumb"
            },
            "label": {
                "source": "/i18n/UsermanagementMessages",
                "key": "organizationTitle"
            },
            "clientScopeURI": "Um0ShowOrganization",
            "nameToken": "com.siemens.splm.client.usermanagement:organizationsublocation",
            "context": {
                "search": {
                    "provider": "um0AdminDataProvider",
                    "criteria": {
                        "resourceProviderContentType": "Organization"
                    },
                    "sortType": "Alphabetical",
                    "maxToLoad": 50,
                    "maxToReturn": 50,
                    "hideInContentSearchBox": true,
                    "showLoadingText": true
                }
            },
            "policy": "/policies/adminObjectPropertyPolicy.json",
            "selectionMode": "single",
            "viewBase": "org",
            "viewModes": {
                "SummaryView": {
                    "primaryWorkArea": "list",
                    "secondaryWorkArea": true
                },
                "TableSummaryView": {
                    "primaryWorkArea": "table",
                    "secondaryWorkArea": true
                },
                "ListView": {
                    "primaryWorkArea": "list",
                    "secondaryWorkArea": false
                },
                "TableView": {
                    "primaryWorkArea": "table",
                    "secondaryWorkArea": false
                },
                "ImageView": {
                    "primaryWorkArea": "image",
                    "secondaryWorkArea": false
                },
                "TreeView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": false
                },
                "TreeSummaryView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": true
                }
            }
        },
        "selectionData": {
            "initialValues": {},
            "meta": {}
        },
        "searchState": {
            "initialValues": {},
            "meta": {}
        }
    },
    "onEvent": [
        {
            "eventId": "locationChangeSuccess",
            "action": "updateSearchCriteriaAndXrtContext"
        }
    ],
    "lifecycleHooks": {
        "onMount": "updateSearchCriteriaAndXrtContext",
        "onUpdate": [
            {
                "action": "updateSearchCriteriaAndXrtContext",
                "observers": [
                    "viewModel.atomicData.selectionData"
                ]
            }
        ]
    },
    "actions": {
        "updateSearchCriteriaAndXrtContext": {
            "actionType": "JSFunction",
            "method": "updateSearchCriteriaAndXrtContext",
            "inputData": {
                "searchStateAtomicData": "{{data.atomicDataRef.searchState}}",
                "searchStateUpdater": "{{data.updateAtomicData}}",
                "selectionData": "{{data.selectionData}}"
            },
            "deps": "js/userMgmtService"
        }
    },
    "_viewModelId": "AwOrganizationPage",
    "_uniqueViewModelId": "AwOrganizationPage",
    "ctx": {}
};

    /*
    <aw-search-page tabsdata="subPanelContext.tabsData" provider="data._provider" selection-data="data.selectionData" search-state="data.searchState"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} selectionData={AwParseService.instance( "fields.selectionData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchState={AwParseService.instance( "fields.searchState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;