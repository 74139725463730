// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwCommandPanel from 'viewmodel/AwCommandPanelViewModel';
import AwFlexRow from 'viewmodel/AwFlexRowViewModel';
import AwCommandSubPanel from 'viewmodel/AwCommandSubPanelViewModel';
import AwNavigatePanel from 'viewmodel/AwNavigatePanelViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwSplitter from 'viewmodel/AwSplitterViewModel';
    import { ExistWhen,AwClass } from 'js/hocCollection';
const AwCommandSubPanelExistWhen = ExistWhen(AwCommandSubPanel);
const AwNavigatePanelExistWhen = ExistWhen(AwNavigatePanel);
const DivExistWhen = ExistWhen('div');
const AwPanelSectionAwClass = AwClass(AwPanelSection);
AwCommandSubPanelExistWhen.displayName = 'AwCommandSubPanelExistWhen';
AwNavigatePanelExistWhen.displayName = 'AwNavigatePanelExistWhen';
DivExistWhen.displayName = 'DivExistWhen';
AwPanelSectionAwClass.displayName = 'AwPanelSectionAwClass';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/occmgmtSubsetUtils', () => import('js/occmgmtSubsetUtils'));
registerDynImportEntry('js/hostedDiscoveryFilterService', () => import('js/hostedDiscoveryFilterService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "actions": {
        "navigateBackToDiscoverySubPanel": {
            "actionType": "JSFunction",
            "method": "navigateBackToDiscoverySubPanel",
            "inputData": {
                "activeViewSharedData": "{{data.activeViewSharedData}}",
                "sharedData": "{{data.sharedData}}",
                "nextActiveView": "Awb0DiscoveryFilterCommandSubPanel"
            },
            "outputData": {
                "activeViewSharedData": "newViewSharedData",
                "sharedData": "sharedData"
            },
            "deps": "js/occmgmtSubsetUtils"
        },
        "updateSharedActiveViewBasedOnPCI": {
            "actionType": "JSFunction",
            "method": "updateSharedActiveViewBasedOnPCI",
            "inputData": {
                "activeViewSharedData": "{{data.activeViewSharedData}}"
            },
            "outputData": {
                "activeViewSharedData": ""
            },
            "deps": "js/hostedDiscoveryFilterService"
        },
        "closeFilterPanel": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "complete",
                        "eventData": {
                            "source": "toolAndInfoPanel"
                        }
                    },
                    {
                        "name": "complete",
                        "eventData": {
                            "source": "navigationPanel"
                        }
                    }
                ]
            }
        },
        "onMount": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "callGetOccs"
                },
                {
                    "action": "updateSharedActiveViewBasedOnPCI"
                }
            ]
        },
        "callGetOccs": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-ActiveWorkspaceBom-2022-06-OccurrenceManagement",
            "method": "getOccurrences4",
            "inputData": {
                "inputData": {
                    "product": {
                        "type": "Fnd0WorksetRevision",
                        "uid": "{{ctx.aw_hosting_state.worksetUid}}"
                    },
                    "requestPref": {
                        "savedSessionMode": [
                            "ignore"
                        ],
                        "component": [
                            "NX"
                        ],
                        "calculateFilters": [
                            "true"
                        ]
                    }
                }
            },
            "outputData": {
                "allProducts": "{{function:getProductsInfo}}",
                "totalFound": "parentChildrenInfos[0].childrenInfo.length"
            },
            "events": {
                "success": [
                    {
                        "name": "populateFilterInformation"
                    },
                    {
                        "name": "updateAdvancedFilterPanel"
                    }
                ]
            },
            "deps": "js/hostedDiscoveryFilterService"
        },
        "updateAdvancedFilterPanel": {
            "actionType": "JSFunction",
            "method": "updateAdvancedFilterPanel",
            "inputData": {
                "data": "{{data.allProducts}}"
            },
            "deps": "js/hostedDiscoveryFilterService"
        },
        "populateFilterInformation": {
            "actionType": "JSFunction",
            "method": "populateFilterInformation",
            "inputData": {},
            "outputData": {
                "occContext": "occContext"
            },
            "deps": "js/hostedDiscoveryFilterService"
        }
    },
    "data": {
        "sharedData": {
            "initialValues": {
                "clickedObj": null,
                "IsEmbeddedComponent": true,
                "autoApply": false,
                "enableFilterApply": false
            },
            "meta": {}
        },
        "activeViewSharedData": {
            "initialValues": {
                "activeView": ""
            },
            "meta": {}
        }
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        }
    },
    "i18n": {
        "filter": [
            "NXConstants"
        ],
        "ProximitySubPanel": [
            "NXConstants"
        ],
        "EditProximitySubPanel": [
            "NXConstants"
        ],
        "PlaneZoneSubPanel": [
            "NXConstants"
        ],
        "BoxZoneSubPanel": [
            "NXConstants"
        ]
    },
    "lifecycleHooks": {
        "onMount": "onMount"
    },
    "conditions": {
        "isStructureFilterSupported": {
            "expression": "props.subPanelContext.occContext.supportedFeatures.Awb0StructureFilterFeature === true && !ctx.hiddenCommands.Awb0StructureFilter"
        },
        "isPartitionView": {
            "expression": "data !== null && data.sharedData !== undefined && data.sharedData.clickedObj !== null && data.sharedData.clickedObj.categoryName === 'Partition Scheme'"
        }
    },
    "onEvent": [
        {
            "eventId": "productContextChangedEvent",
            "action": "closeFilterPanel",
            "condition": "(dataProviderActionType === 'productChangedOnSelectionChange' || dataProviderActionType === 'initializeAction' ) && !conditions.isStructureFilterSupported"
        },
        {
            "eventId": "occDataLoadedEvent",
            "action": "updateSharedActiveViewBasedOnPCI"
        },
        {
            "eventId": "productContextChangedEvent",
            "action": "updateSharedActiveViewBasedOnPCI",
            "condition": "dataProviderActionType === 'initializeAction' || dataProviderActionType === 'productChangedOnSelectionChange'"
        },
        {
            "eventId": "updateAdvancedFilterPanel",
            "action": "updateAdvancedFilterPanel"
        },
        {
            "eventId": "populateFilterInformation",
            "action": "populateFilterInformation"
        }
    ],
    "_viewModelId": "Awn0HostedStructureFilterCommand",
    "_uniqueViewModelId": "Awn0HostedStructureFilterCommand",
    "ctx": {
        "aw_hosting_state": {
            "type": "object"
        },
        "hiddenCommands": {
            "type": "object"
        }
    }
};

    /*
    <aw-command-panel caption="i18n.filter" hide-title="true">
    <aw-flex-row>
        <aw-command-sub-panel panel-id="Awb0DiscoveryFilterCommandSubPanel"
            exist-when="data.activeViewSharedData.activeView === 'Awb0DiscoveryFilterCommandSubPanel'"
            sub-panel-context="{...props.subPanelContext, data: data, activeViewSharedData: data.activeViewSharedData, sharedData:data.sharedData, occContext: data.occContext}">
        </aw-command-sub-panel>
        <aw-navigate-panel title="{{data.sharedData.spatialRecipeIndexToUpdate >=0 ?  i18n.EditProximitySubPanel: i18n.ProximitySubPanel }}"
            exist-when="data.activeViewSharedData.activeView === 'ProximitySubPanel'"
            pre-panel-id='Awb0DiscoveryFilterCommandSubPanel' panel-id='ProximitySubPanel'
            sub-panel-context='{activeViewSharedData: data.activeViewSharedData, sharedData:data.sharedData, occContext: data.occContext}'
            action="navigateBackToDiscoverySubPanel">
        </aw-navigate-panel>
        <aw-navigate-panel title='i18n.BoxZoneSubPanel'
            exist-when="data.activeViewSharedData.activeView === 'BoxZoneSubPanel'"
            pre-panel-id='Awb0DiscoveryFilterCommandSubPanel' panel-id='BoxZoneSubPanel'
                sub-panel-context='{activeViewSharedData: data.activeViewSharedData, sharedData:data.sharedData, occContext: data.occContext}'
            action="navigateBackToDiscoverySubPanel">
        </aw-navigate-panel>
        <aw-navigate-panel title='i18n.PlaneZoneSubPanel'
            exist-when="data.activeViewSharedData.activeView === 'PlaneZoneSubPanel'"
            pre-panel-id='Awb0DiscoveryFilterCommandSubPanel' panel-id='PlaneZoneSubPanel'
                sub-panel-context='{activeViewSharedData: data.activeViewSharedData, sharedData:data.sharedData, occContext: data.occContext}'
            action="navigateBackToDiscoverySubPanel">
        </aw-navigate-panel>
        <div class="sw-row aw-partition-filter-panel" exist-when="data.activeViewSharedData.activeView === 'PartitionHierarchySubPanel'">
            <aw-panel-section collapsed="disabled" caption="i18n.filters" class="aw-filters-section aw-search-left-panel-view aw-partition-filter-panel1" aw-class="{'disabled': data.activeViewSharedData.activeView === 'PartitionHierarchySubPanel' }">
                <aw-command-sub-panel panel-id="Awb0DiscoveryFilterCommandSubPanel"
                    sub-panel-context="{...props.subPanelContext, data: data, activeViewSharedData: data.activeViewSharedData, sharedData:data.sharedData}">
                </aw-command-sub-panel>
            </aw-panel-section>
            <aw-splitter></aw-splitter>
            <aw-panel-section collapsed="disabled" caption='{{data.sharedData.clickedObj.name}}' class="aw-results-section aw-partition-filter-panel2 w-6">
                <aw-command-sub-panel panel-id="PartitionHierarchySubPanel"
                    sub-panel-context='{occContext: data.occContext, activeViewSharedData: data.activeViewSharedData, sharedData:data.sharedData}'>
                </aw-command-sub-panel>
            </aw-panel-section>
        </div>
    </aw-flex-row>
</aw-command-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwCommandPanel revealAction={actions.reveal} hideTitle={true} updateActiveView={AwParseService.instance( "{activeView: data.activeView, dispatch: dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} caption={i18n.filter}>
        <AwFlexRow>
            <AwCommandSubPanelExistWhen panelId="Awb0DiscoveryFilterCommandSubPanel" subPanelContext={AwParseService.instance( "{...props.subPanelContext, data: data, activeViewSharedData: fields.activeViewSharedData, sharedData:fields.sharedData, occContext: data.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "fields.activeViewSharedData.activeView === 'Awb0DiscoveryFilterCommandSubPanel'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwCommandSubPanelExistWhen>
            <AwNavigatePanelExistWhen title={AwParseService.instance( "fields.sharedData.spatialRecipeIndexToUpdate >=0 ?  i18n.EditProximitySubPanel: i18n.ProximitySubPanel " )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} prePanelId="Awb0DiscoveryFilterCommandSubPanel" panelId="ProximitySubPanel" subPanelContext={AwParseService.instance( "{activeViewSharedData: fields.activeViewSharedData, sharedData:fields.sharedData, occContext: data.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.navigateBackToDiscoverySubPanel} existWhen={AwParseService.instance( "fields.activeViewSharedData.activeView === 'ProximitySubPanel'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwNavigatePanelExistWhen>
            <AwNavigatePanelExistWhen title={i18n.BoxZoneSubPanel} prePanelId="Awb0DiscoveryFilterCommandSubPanel" panelId="BoxZoneSubPanel" subPanelContext={AwParseService.instance( "{activeViewSharedData: fields.activeViewSharedData, sharedData:fields.sharedData, occContext: data.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.navigateBackToDiscoverySubPanel} existWhen={AwParseService.instance( "fields.activeViewSharedData.activeView === 'BoxZoneSubPanel'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwNavigatePanelExistWhen>
            <AwNavigatePanelExistWhen title={i18n.PlaneZoneSubPanel} prePanelId="Awb0DiscoveryFilterCommandSubPanel" panelId="PlaneZoneSubPanel" subPanelContext={AwParseService.instance( "{activeViewSharedData: fields.activeViewSharedData, sharedData:fields.sharedData, occContext: data.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} action={actions.navigateBackToDiscoverySubPanel} existWhen={AwParseService.instance( "fields.activeViewSharedData.activeView === 'PlaneZoneSubPanel'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwNavigatePanelExistWhen>
            <DivExistWhen className="sw-row aw-partition-filter-panel" existWhen={AwParseService.instance( "fields.activeViewSharedData.activeView === 'PartitionHierarchySubPanel'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwPanelSectionAwClass collapsed="disabled" caption={i18n.filters} className="aw-filters-section aw-search-left-panel-view aw-partition-filter-panel1" awClass={AwParseService.instance( "{'disabled': fields.activeViewSharedData.activeView === 'PartitionHierarchySubPanel' }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <AwCommandSubPanel panelId="Awb0DiscoveryFilterCommandSubPanel" subPanelContext={AwParseService.instance( "{...props.subPanelContext, data: data, activeViewSharedData: fields.activeViewSharedData, sharedData:fields.sharedData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </AwCommandSubPanel>
                </AwPanelSectionAwClass>
                <AwSplitter>
                </AwSplitter>
                <AwPanelSection collapsed="disabled" caption={AwParseService.instance( "fields.sharedData.clickedObj.name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-results-section aw-partition-filter-panel2 w-6">
                    <AwCommandSubPanel panelId="PartitionHierarchySubPanel" subPanelContext={AwParseService.instance( "{occContext: data.occContext, activeViewSharedData: fields.activeViewSharedData, sharedData:fields.sharedData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </AwCommandSubPanel>
                </AwPanelSection>
            </DivExistWhen>
        </AwFlexRow>
    </AwCommandPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;