// Copyright 2020 Siemens Product Lifecycle Management Software Inc.

/*global*/

/**
 * @module js/tcmaEditStudyService
 */
import soaService from 'soa/kernel/soaService';
import policySvc from 'soa/kernel/propertyPolicyService';
import eventBus from 'js/eventBus';
import messagingService from 'js/messagingService';
import mfeTypeUtils from 'js/utils/mfeTypeUtils';
let exports = {};

/**
 *
 * @param {Object} response - open context response
 * @param {Object} commandContext -commandContext type
 *
 *
 */
function updateCommandContextObjects( response, commandContext, studySC ) {
    if( response && response.output && response.output.length > 0 ) {
        let contexts = response.output[0].contexts;
        for( let i = 0; i < contexts.length; i++ ) {
            let object = contexts[i].context;
            if( mfeTypeUtils.isOfType( object, 'Mfg0BvrPlantBOP' ) ||  mfeTypeUtils.isOfType( object, 'Mfg0BvrProcess' ) ) {
                commandContext.processContext = object;
            } else if( mfeTypeUtils.isOfType( object, 'Mfg0BvrWorkarea' ) ) {
                commandContext.plantContext = object;
            } else if( ( mfeTypeUtils.isOfType( object, 'Mfg0BvrShdStudy' ) || mfeTypeUtils.isOfType( object, 'Mfg0BvrSimStudy' ) ) && contexts[i].object.uid === studySC.uid ) {
                commandContext.study = object;
            }
            commandContext.update( commandContext );
        }
    }
}

/**
 *
 * @param {Object} objects - input
 * @param {Object} policy -policy
 *
 * @returns {Object} refresh Object response
 */
function getProperties( objects, policy ) {
    if( policy ) {
        const policyId = policySvc.register( policy );
        return soaService
            .postUnchecked( 'Core-2006-03-DataManagement', 'getProperties', {
                objects: objects,
                attributes: [
                    'bl_all_child_lines'
                ]
            } )
            .then( function( response ) {
                policySvc.unregister( policyId );
                let childrenModelObjects = [];
                if( response && response.modelObjects ) {
                    let childrenUids = response.modelObjects[ objects[ 0 ].uid ].props.bl_all_child_lines.dbValues;
                    for( let i = 0; i < childrenUids.length; i++ ) {
                        childrenModelObjects.push( response.modelObjects[ childrenUids[ i ] ] );
                    }
                }
                return childrenModelObjects;
            } );
    }
}

/**
 * This function disconnets consumed selected study elements from study
 *
 * @param {Array} sourceObjects - selected Study element
 * @param {Object} target - study
 */
function disconnectObjects( sourceObjects, target ) {
    let input = [];
    for( let sourceObject of sourceObjects ) {
        input.push( {
            disconnectFrom: {
                uid: target.uid,
                type: target.type
            },
            clientId: '',
            relationName: '',
            object: {
                uid: sourceObject.uid,
                type: sourceObject.type
            }
        } );
    }
    soaService
        .postUnchecked( 'Manufacturing-2009-10-DataManagement', 'disconnectObjects', {
            input: input
        } )
        .then( function( response ) {
            if( response.partialErrors && response.partialErrors.length > 0 ) {
                showError( response.partialErrors );
            } else {
                let parsedAddRemoveSaveEvents = [];
                parsedAddRemoveSaveEvents.MEConsumed = {
                    eventObjectUid: target.uid,
                    relatedEvents: {
                        removedFromRelation: sourceObjects.map( obj => obj.uid )
                    }

                };
                eventBus.publish( 'ep.addRemoveEvents', parsedAddRemoveSaveEvents );
            }
        } );
}

/**
 *
 * @param {Array} partialErrors - partial errors array
 */
function showError( partialErrors ) {
    for( let i = 0; i < partialErrors.length; i++ ) {
        messagingService.showError( partialErrors[ i ].errorValues[ 0 ].message );
    }
}

export default exports = {
    getProperties,
    updateCommandContextObjects,
    disconnectObjects
};
