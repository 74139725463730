// @<COPYRIGHT>@
// ==================================================
// Copyright 2022
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/* global

 */
import eventBus from 'js/eventBus';
import popupService from 'js/popupService';

'use strict';

/**
 * @module js/recipeOperatorService
 */

/**
 * This method adds the click action on the data
 *
 * @param {Object} data view model data
 *
 */

export let recipeLogicClickAction = ( newSelection, currentSelection, index ) => {
    if( newSelection !== currentSelection ) {
        eventBus.publish( 'operatorModified', {
            recipeIndex: index,
            criteriaOperatorType: newSelection
        } );
        popupService.hide();
    }
};

export let onClick = ( ) => {
    var parentNode = event.currentTarget.parentNode;
    var eventData = {
        popupUpLevelElement: parentNode
    };
    popupService.show( eventData );
};

export default {
    recipeLogicClickAction,
    onClick
};
