// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwLabel from 'viewmodel/AwLabelViewModel';
import AwCheckbox from 'viewmodel/AwCheckboxViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/showcaseHelper', () => import('js/showcaseHelper'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-panel.directive",
        "js/aw-panel-body.directive",
        "js/aw-panel-section.directive",
        "js/aw-checkbox.directive",
        "js/aw-label.directive",
        "js/aw-break.directive",
        "js/aw-flex-row.directive"
    ],
    "data": {
        "descriptionBasic": {
            "displayName": "Description",
            "dispValue": "To display basic checkbox vertically and allows the user for single, multiple or no selection"
        },
        "categoryEntertainment": {
            "displayName": "Entertainment",
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "true",
            "dbValue": "",
            "dispValue": "Entertainment"
        },
        "categoryTechnology": {
            "displayName": "Technology",
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "true",
            "dbValue": "",
            "dispValue": ""
        },
        "categorySports": {
            "displayName": "Sports",
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "true",
            "dbValue": "",
            "dispValue": ""
        },
        "descriptionHorizontal": {
            "displayName": "Description",
            "dispValue": "To display basic checkbox horizontally, allows the user for single, multiple or no selection and to display pre-selected value"
        },
        "foodWaffles": {
            "displayName": "Waffles",
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "true",
            "dbValue": true,
            "dispValue": "Waffles",
            "labelPosition": "PROPERTY_LABEL_AT_RIGHT"
        },
        "foodPanCakes": {
            "displayName": "Pan Cakes",
            "type": "BOOLEAN",
            "isRequired": "true",
            "isEditable": "true",
            "dbValue": "",
            "dispValue": "Pan Cakes",
            "labelPosition": "PROPERTY_LABEL_AT_RIGHT"
        },
        "foodBrownie": {
            "displayName": "Brownie",
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "true",
            "dbValue": true,
            "dispValue": "Brownie",
            "labelPosition": "PROPERTY_LABEL_AT_RIGHT"
        },
        "selectedFood": {
            "displayName": "Selected Food",
            "isRequired": "false",
            "isEditable": "true"
        },
        "descriptionLeft": {
            "displayName": "Description",
            "dispValue": "To display checkbox at left and to show pre-selected value"
        },
        "colorBlack": {
            "displayName": "Black",
            "type": "BOOLEAN",
            "isRequired": "true",
            "isEditable": "true",
            "dbValue": true,
            "labelPosition": "PROPERTY_LABEL_AT_SIDE"
        },
        "colorBlue": {
            "displayName": "Blue",
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "true",
            "dbValue": "",
            "labelPosition": "PROPERTY_LABEL_AT_SIDE"
        },
        "descriptionTop": {
            "displayName": "Description",
            "dispValue": "display basic checkbox with label on top"
        },
        "categoryHeart": {
            "displayName": "Heart",
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "true",
            "dbValue": "",
            "dispValue": ""
        },
        "categoryMind": {
            "displayName": "Mind",
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "true",
            "dbValue": "",
            "dispValue": "",
            "labelPosition": "PROPERTY_LABEL_AT_TOP"
        },
        "categoryBody": {
            "displayName": "Bag of Bones",
            "type": "BOOLEAN",
            "isRequired": "true",
            "isEditable": "true",
            "dbValue": "",
            "dispValue": "",
            "labelPosition": "PROPERTY_LABEL_AT_TOP"
        },
        "categoryLbl": {
            "displayName": "Selected Categories",
            "isRequired": "false",
            "isEditable": "true",
            "dbValue": "",
            "dispValue": ""
        },
        "disabledUnchecked": {
            "displayName": "Disabled",
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "false",
            "dbValue": "",
            "dispValue": "Disabled"
        },
        "disabledChecked": {
            "displayName": "Disabled Checked",
            "type": "BOOLEAN",
            "isRequired": "false",
            "isEditable": "false",
            "dbValue": true,
            "dispValue": "Disabled Checked"
        },
        "descriptionCheckboxArray": {
            "displayName": "Description",
            "dispValue": "To add multiple checkbox boolean values (press Enter once you check/uncheck the checkbox). Double click the added value to go in inline edit mode. Check/uncheck the checkbox and press Enter key."
        },
        "checkBoxArray": {
            "displayName": "Check Box Array",
            "type": "BOOLEANARRAY",
            "isRequired": "false",
            "isEditable": "true",
            "labelPosition": "PROPERTY_LABEL_AT_SIDE",
            "isArray": "true"
        }
    },
    "actions": {
        "reveal": {
            "actionType": "JSFunction",
            "method": "onLoadValues",
            "inputData": {
                "data": "{{data}}"
            },
            "outputData": {
                "selectedFood": ""
            },
            "deps": "js/showcaseHelper"
        },
        "updateEntertainment": {
            "actionType": "JSFunction",
            "method": "updateValues",
            "inputData": {
                "data": "{{data.categoryEntertainment}}",
                "dataCategory": "{{data.categoryLbl}}"
            },
            "outputData": {
                "categoryLbl": ""
            },
            "deps": "js/showcaseHelper"
        },
        "updateTechnology": {
            "actionType": "JSFunction",
            "method": "updateValues",
            "inputData": {
                "data": "{{data.categoryTechnology}}",
                "dataCategory": "{{data.categoryLbl}}"
            },
            "outputData": {
                "categoryLbl": ""
            },
            "deps": "js/showcaseHelper"
        },
        "updateSports": {
            "actionType": "JSFunction",
            "method": "updateValues",
            "inputData": {
                "data": "{{data.categorySports}}",
                "dataCategory": "{{data.categoryLbl}}"
            },
            "outputData": {
                "categoryLbl": ""
            },
            "deps": "js/showcaseHelper"
        },
        "updateWaffles": {
            "actionType": "JSFunction",
            "method": "updateValues",
            "inputData": {
                "data": "{{data.foodWaffles}}",
                "dataCategory": "{{data.selectedFood}}"
            },
            "outputData": {
                "selectedFood": ""
            },
            "deps": "js/showcaseHelper"
        },
        "updatePanCakes": {
            "actionType": "JSFunction",
            "method": "updateValues",
            "inputData": {
                "data": "{{data.foodPanCakes}}",
                "dataCategory": "{{data.selectedFood}}"
            },
            "outputData": {
                "selectedFood": ""
            },
            "deps": "js/showcaseHelper"
        },
        "updateBrownie": {
            "actionType": "JSFunction",
            "method": "updateValues",
            "inputData": {
                "data": "{{data.foodBrownie}}",
                "dataCategory": "{{data.selectedFood}}"
            },
            "outputData": {
                "selectedFood": ""
            },
            "deps": "js/showcaseHelper"
        }
    },
    "lifecycleHooks": {
        "onMount": "reveal"
    },
    "_viewModelId": "CheckboxExample",
    "_uniqueViewModelId": "CheckboxExample",
    "ctx": {}
};

    /*
            <aw-panel class='w-6' caption='Checkbox'>
            <aw-panel-body>
                <aw-panel-section caption='Default View'>
                    <aw-label prop="data.descriptionBasic"></aw-label>
                    <aw-checkbox
                        action="updateEntertainment"
                        prop="data.categoryEntertainment"
                    ></aw-checkbox>
                    <aw-checkbox
                        action="updateTechnology"
                        prop="data.categoryTechnology"
                    ></aw-checkbox>
                    <aw-checkbox
                        action="updateSports"
                        prop="data.categorySports"
                    ></aw-checkbox>
                    <aw-label prop="data.categoryLbl"></aw-label>
                </aw-panel-section>
                <aw-panel-section caption='Horizontal View'>
                    <aw-label prop="data.descriptionHorizontal"></aw-label>
                    <aw-checkbox
                        prop="data.foodWaffles"
                        action="updateWaffles"
                    ></aw-checkbox>
                    <aw-checkbox
                        prop="data.foodPanCakes"
                        action="updatePanCakes"
                    ></aw-checkbox>
                    <aw-checkbox
                        prop="data.foodBrownie"
                        action="updateBrownie"
                    ></aw-checkbox>
                    <aw-label prop="data.selectedFood"></aw-label>
                </aw-panel-section>
                <aw-panel-section caption='Pre-selected and label at left'>
                    <aw-label prop="data.descriptionLeft"></aw-label>
                    <aw-checkbox
                        prop="data.colorBlack"
                    ></aw-checkbox>
                    <aw-checkbox
                        prop="data.colorBlue"
                    ></aw-checkbox>
                </aw-panel-section>
                <aw-panel-section caption='Disabled'>
                    <aw-label prop="data.descriptionLeft"></aw-label>
                    <div class='sw-row sw-component'>
                        <aw-checkbox
                            prop="data.disabledUnchecked"
                        ></aw-checkbox>
                        <aw-checkbox
                            prop="data.disabledChecked"
                        ></aw-checkbox>
                    </div>
                </aw-panel-section>
            </aw-panel-body>
        </aw-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanel  revealAction={actions.reveal}  className="w-6" caption="Checkbox">
        <AwPanelBody>
            <AwPanelSection caption="Default View">
                <AwLabel {...getField("data.descriptionBasic", fields, $index, null, null )}>
                </AwLabel>
                <AwCheckbox action={actions.updateEntertainment} {...getField("data.categoryEntertainment", fields, $index, null, null )}>
                </AwCheckbox>
                <AwCheckbox action={actions.updateTechnology} {...getField("data.categoryTechnology", fields, $index, null, null )}>
                </AwCheckbox>
                <AwCheckbox action={actions.updateSports} {...getField("data.categorySports", fields, $index, null, null )}>
                </AwCheckbox>
                <AwLabel {...getField("data.categoryLbl", fields, $index, null, null )}>
                </AwLabel>
            </AwPanelSection>
            <AwPanelSection caption="Horizontal View">
                <AwLabel {...getField("data.descriptionHorizontal", fields, $index, null, null )}>
                </AwLabel>
                <AwCheckbox {...getField("data.foodWaffles", fields, $index, null, null )} action={actions.updateWaffles}>
                </AwCheckbox>
                <AwCheckbox {...getField("data.foodPanCakes", fields, $index, null, null )} action={actions.updatePanCakes}>
                </AwCheckbox>
                <AwCheckbox {...getField("data.foodBrownie", fields, $index, null, null )} action={actions.updateBrownie}>
                </AwCheckbox>
                <AwLabel {...getField("data.selectedFood", fields, $index, null, null )}>
                </AwLabel>
            </AwPanelSection>
            <AwPanelSection caption="Pre-selected and label at left">
                <AwLabel {...getField("data.descriptionLeft", fields, $index, null, null )}>
                </AwLabel>
                <AwCheckbox {...getField("data.colorBlack", fields, $index, null, null )}>
                </AwCheckbox>
                <AwCheckbox {...getField("data.colorBlue", fields, $index, null, null )}>
                </AwCheckbox>
            </AwPanelSection>
            <AwPanelSection caption="Disabled">
                <AwLabel {...getField("data.descriptionLeft", fields, $index, null, null )}>
                </AwLabel>
                <div className="sw-row sw-component">
                    <AwCheckbox {...getField("data.disabledUnchecked", fields, $index, null, null )}>
                    </AwCheckbox>
                    <AwCheckbox {...getField("data.disabledChecked", fields, $index, null, null )}>
                    </AwCheckbox>
                </div>
            </AwPanelSection>
        </AwPanelBody>
    </AwPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;