// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 *
 * File description : This file contains functions for open model object in PDMVis (e.g. DiC ZA, VGA ZA or DirectModelAssembly etc.)
 * @module js/VIMOpenInTCVisService
 * ModifyBy             ModifyOn            Comment
 * Raju Pandit       15-Sep-2020          TcVis download launcher .bat file implementation.
 * Sunil Petkar      18-Sep-2020          Add property policy for FTM Revision
 * Sunil Petkar      16-Oct-2020          add error validation (if No geometry added)
 * Niranjan Rekhate  20-Nov-2020          Removed unused functions (After modifying TcVis functionality)
 */
import fmsUtils from "js/fmsUtils";
import browserUtils from "js/browserUtils";
import _ from "lodash";
import frameAdapterService from "js/frameAdapter.service";
import appCtxSvc from "js/appCtxService";
import AwHttpService from "js/awHttpService";
import cdmSvc from "soa/kernel/clientDataModel";

let exports = {};
let savedQueryName = "TeGGGI_OEDE_Query_General";
let $http = AwHttpService.instance;
let CLIENT_FMS_DOWNLOAD_PATH = "fms/fmsdownload/";
let vviFileTicket;
("use strict");
/**
 * Get Query Input for TcVis
 * @function getQueryInputForTcVis
 */
export let getQueryInputForTcVis = function (selectedObjects) {
  let queryInputs = [];
  let uids = selectedObjects.map((eachZAForm) => {
    return eachZAForm.uid;
  });
  selectedObjects = cdmSvc.getObjects(uids);
  selectedObjects.forEach(function (eachZA) {
    let partItemID = eachZA.props.v4b_ZBS_SNR.dbValues[0];
    let partRevID =
      _.get(eachZA.props, "v4b_VERS_IND.dbValues[0]") ||
      _.get(eachZA.props, "v4b_ZBS_ZI.dbValues[0]");
    let docPart = eachZA.props.v4b_ZBS_DOKU_TEIL.dbValues[0];
    let alternative = eachZA.props.v4b_ZBS_DOKU_ALT.dbValues[0];
    let docFormat = eachZA.props.v4b_ZBS_DOKF_ID.dbValues[0];
    let docType = eachZA.props.v4b_ZBS_DOKT_ID.dbValues[0];

    let qryInput = {
      query: appCtxSvc.getCtx("vimCtx.savedQueryForTcVis"),
      entries: [
        "PartItemID",
        "VersionID",
        "DocumentPart",
        "Alternative",
        "DocumentFormat",
        "DocumentType",
      ],
      values: [
        partItemID,
        partRevID.trim(),
        docPart,
        alternative,
        docFormat,
        docType,
      ],
      maxNumToReturn: 50,
      clientId: "AWC",
    };
    queryInputs.push(qryInput);
  });
  return queryInputs;
};

/**
 * Get server information from session
 *
 * @function _getServerInfoObject
 *
 * @return {Object} Server info object (or NULL if no tcSessionData  on app ctx).
 */
let getServerInfoObject = function () {
  if (
    appCtxSvc.getCtx("tcSessionData") &&
    appCtxSvc.getCtx("tcSessionData.server")
  ) {
    let soaPath = appCtxSvc.getCtx("tcSessionData.server");
    let protocol = soaPath.substring(0, soaPath.indexOf("://", 0));
    let replacementSoaPath = soaPath;
    //Production OR Integration: Replace the trailing 'tc/' with 'bmwtc/'
    if (
      soaPath.indexOf("tc4jb.bmwgroup") !== -1 ||
      soaPath.indexOf("tc4jbi.bmwgroup") !== -1
    ) {
      replacementSoaPath = soaPath.replace("/tc/", "/bmwtc/");
    }
    console.log(replacementSoaPath);
    return {
      protocol: protocol,
      hostpath: replacementSoaPath,
      servermode: 4,
    };
  }
  return null;
};

let buildBatchFileContent = function (vviFileData, fileName) {
  let vviLines = decodeURI(vviFileData.data).split("\n");
  console.log(vviLines);

  let tokens = appCtxSvc
    .getCtx("preferences.V4B_VIM_TC_VIZ_LAUNCHER_BATCH_FILE_PATH[0]")
    .split(";");
  let server = tokens[0];
  let exePath = tokens[1];

  //build batch file
  let batchFileData = [];

  batchFileData.push("set FILE_NAME=" + fileName);
  batchFileData.push("set ENVIRONMENT=" + server);
  batchFileData.push("set EXE_BAT=" + exePath);

  batchFileData.push("echo OFF");
  batchFileData.push("set OUTPUT_DIR=%userprofile%\\TCVis");
  batchFileData.push("set OUTPUT_FILE=%OUTPUT_DIR%\\%FILE_NAME%");
  batchFileData.push("set LOG_FILE=%OUTPUT_FILE%.log");
  batchFileData.push("");

  batchFileData.push("if not exist %OUTPUT_DIR% (");
  batchFileData.push("@echo Creating[ %OUTPUT_DIR%] >> %LOG_FILE%");
  batchFileData.push("mkdir %OUTPUT_DIR%");
  batchFileData.push(")");
  batchFileData.push("");

  batchFileData.push("if exist %OUTPUT_FILE% (");
  batchFileData.push("@echo Removing %OUTPUT_FILE% >> %LOG_FILE%");
  batchFileData.push("del %OUTPUT_FILE%");
  batchFileData.push(")");
  batchFileData.push("");

  batchFileData.push("@echo writting vvi file.");
  vviLines.forEach(function (eachLine) {
    if (eachLine.trim() === "") {
      batchFileData.push("echo.>> %OUTPUT_FILE%");
    } else {
      batchFileData.push("echo " + eachLine + ">> %OUTPUT_FILE%");
    }
  });

  batchFileData.push("");
  batchFileData.push(
    '@echo executing command [call "%EXE_BAT%" %ENVIRONMENT% "%OUTPUT_FILE%"]'
  );
  batchFileData.push("");
  batchFileData.push(
    'call "%EXE_BAT%" %ENVIRONMENT% "%OUTPUT_FILE%" >> %LOG_FILE%'
  );
  batchFileData.push("");
  batchFileData.push("exit");
  return batchFileData;
};

// https://stackoverflow.com/questions/18755750/saving-text-in-a-local-file-in-internet-explorer-10
let saveTextAsFile = function (fileNameToSaveAs, textToWrite) {
  /* Saves a text string as a blob file*/
  let ie = navigator.userAgent.match(/MSIE\s([\d.]+)/),
    ie11 =
      navigator.userAgent.match(/Trident\/7.0/) &&
      navigator.userAgent.match(/rv:11/),
    ieEDGE = navigator.userAgent.match(/Edge/g),
    ieVer = ie ? ie[1] : ie11 ? 11 : ieEDGE ? 12 : -1;

  if (ie && ieVer < 10) {
    console.log("No blobs on IE ver<10");
    return;
  }

  let textFileAsBlob = new Blob([textToWrite], {
    type: "text/plain",
  });

  if (ieVer > -1) {
    window.navigator.msSaveBlob(textFileAsBlob, fileNameToSaveAs);
  } else {
    let downloadLink = document.createElement("a");
    downloadLink.download = fileNameToSaveAs;
    downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
    downloadLink.onclick = function (e) {
      document.body.removeChild(e.target);
    };
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
};

export let callTcVisSession = function (queryResult) {
  let allResultsUID = [];
  queryResult.forEach(function (eachObj) {
    if (eachObj.objectUIDS && eachObj.objectUIDS.length > 0) {
      allResultsUID.push(eachObj.objectUIDS[0]);
      // assuming we will get only one design item revision for given input
    }
  });
  if (allResultsUID.length == 0) {
    return;
  }
  let idInfos = allResultsUID.map(function (eachUID) {
    let idInfo = {};
    idInfo.launchedObject = {
      uid: eachUID,
      type: "T4B_DesignItemRevision",
    };
    idInfo.createOptions = {
      CreateVisSC: {
        includeInLaunchFile: false,
        optionValue: "True",
      },
      Operation: {
        includeInLaunchFile: true,
        optionValue: "Interop",
      },
      TransientDoc: {
        includeInLaunchFile: true,
        optionValue: "True",
      },
    };
    return idInfo;
  });
  let serverInfo = getServerInfoObject();
  let vviFileTicket;
  return frameAdapterService
    .createLaunchInfo(idInfos, serverInfo)
    .then(function (lanchInfoResp) {
      if (lanchInfoResp && lanchInfoResp.ticket) {
        vviFileTicket = lanchInfoResp.ticket;
        let urlToDownload =
          browserUtils.getBaseURL() +
          CLIENT_FMS_DOWNLOAD_PATH +
          "?ticket=" +
          encodeURIComponent(vviFileTicket);
        return $http.get(urlToDownload);
      }
    })
    .then(function (vviFileData) {
      if (vviFileData && vviFileTicket) {
        let fileName = fmsUtils.getFilenameFromTicket(vviFileTicket);
        let batchFileData = buildBatchFileContent(vviFileData, fileName);
        saveTextAsFile(fileName + ".bat", batchFileData.join("\n"));
      }
    });
};

exports = {
  callTcVisSession,
  getQueryInputForTcVis
};
export default exports;
