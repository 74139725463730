// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwColumn from 'viewmodel/AwColumnViewModel';
import AwFullScreen from 'viewmodel/AwFullScreenViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "_viewModelId": "Arm0HTMLSpecTemplateEdit",
    "_uniqueViewModelId": "Arm0HTMLSpecTemplateEdit",
    "ctx": {}
};

    /*
    <aw-column width="fill" height="fill">
    <aw-full-screen include-component-name='Arm0HTMLSpecTemplateEditContent' header-title='{{subPanelContext.caption}}' header-path='{{subPanelContext.activeTab.name ? subPanelContext.activeTab.name : subPanelContext.showObjectContext.activeTab.name}}' header-context-title='{{subPanelContext.selection[0].props.object_string.uiValues[0]}}' context='subPanelContext'>
    </aw-full-screen>
</aw-column>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwColumn width="fill" height="fill">
        <AwFullScreen includeComponentName="Arm0HTMLSpecTemplateEditContent" headerTitle={AwParseService.instance( "subPanelContext.caption" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} headerPath={AwParseService.instance( "subPanelContext.activeTab.name ? subPanelContext.activeTab.name : subPanelContext.showObjectContext.activeTab.name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} headerContextTitle={AwParseService.instance( "subPanelContext.selection[0].props.object_string.uiValues[0]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} context={AwParseService.instance( "subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwFullScreen>
    </AwColumn>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;