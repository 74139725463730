// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

let exports = {};

export let getUnderlyingPartUsage = function (sourceObjects) {
  let underlyingPartUsageObjects = [];

  for (let i = 0; i < sourceObjects.length; i++) {
    underlyingPartUsageObjects.push({
      uid: sourceObjects[i].props.awb0UnderlyingObject.dbValues[0],
      type: sourceObjects[i].props.awb0UnderlyingObjectType.dbValues[0],
    });
  }
  return underlyingPartUsageObjects;
};

export let refreshWindow = function () {
  location.reload();
};
export let g4b_getSelectedObjectUid = function (mselected) {

  let arrOfUID=[];
  arrOfUID.push(...mselected.map(item=>item.uid));
  
  return arrOfUID;
};
exports = {
  refreshWindow,
  getUnderlyingPartUsage,
  g4b_getSelectedObjectUid,
};

export default exports;
