// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSublocation from 'viewmodel/AwSublocationViewModel';
import AwDefaultSublocation from 'viewmodel/AwDefaultSublocationViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/importPreviewService', () => import('js/importPreviewService'));
registerDynImportEntry('js/locationNavigation.service', () => import('js/locationNavigation.service'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-sublocation.directive",
        "js/aw-native-sublocation.directive"
    ],
    "actions": {
        "launchImportPanel": {
            "actionType": "JSFunction",
            "method": "launchImportPanelInPreview",
            "deps": "js/importPreviewService",
            "inputData": {
                "commandId": "Awb0ImportFromExcel",
                "location": "aw_toolsAndInfo"
            }
        },
        "navigateToBack": {
            "actionType": "JSFunction",
            "method": "goBack",
            "deps": "js/locationNavigation.service"
        },
        "cleanupPreviewVariablesFromCtx": {
            "actionType": "JSFunction",
            "method": "cleanupPreviewVariablesFromCtx",
            "deps": "js/importPreviewService"
        }
    },
    "data": {
        "provider": {
            "breadcrumbConfig": {
                "noBreadCrumb": "true",
                "noCriteriaSpecifiedMessage": {
                    "key": "noCriteriaSpecifiedMessageForNoBreadCrumb",
                    "source": "OccmgmtImportExportConstants"
                },
                "noResultsFoundMessage": {
                    "key": "",
                    "source": ""
                },
                "resultsFoundMessage": {
                    "key": "",
                    "source": ""
                }
            },
            "viewKey": "IFEContext",
            "name": "com.siemens.splm.client.Awb0ImportPreview:importPreviewSublocation",
            "label": {
                "source": "/i18n/OccmgmtImportExportConstants",
                "key": "previewSubLocationTitle"
            },
            "clientScopeURI": "Awb0ImportPreview",
            "nameToken": "com.siemens.splm.client.Awb0ImportPreview:importPreviewSublocation",
            "policy": "/policies/occMgmtPropertyPolicy.json",
            "viewBase": "Awb0ImportPreview",
            "selectionQueryParamKey": "c_uid",
            "contextKey": "IFEContext",
            "defaultDisplayMode": "TreeView",
            "viewModes": {
                "TreeView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": false
                }
            },
            "awSubLocationPref": {
                "hideNavigationArea": false,
                "hideRightWall": true,
                "hideFooter": false
            }
        },
        "modelObject": "ctx.modelObjectsToOpen[0]",
        "importBOMMOde": ""
    },
    "lifecycleHooks": {
        "onUnmount": "cleanupPreviewVariablesFromCtx"
    },
    "onEvent": [
        {
            "eventId": "importBOMPreview.navigateToBack",
            "action": "navigateToBack"
        },
        {
            "eventId": "importPreviewDataProvider.treeNodesLoaded",
            "cacheEventData": true,
            "action": "launchImportPanel"
        },
        {
            "eventId": "importBOM.previewTreeLoadFailure",
            "cacheEventData": true,
            "action": "launchImportPanel"
        }
    ],
    "_viewModelId": "Awb0ImportPreview",
    "_uniqueViewModelId": "Awb0ImportPreview",
    "ctx": {
        "modelObjectsToOpen": {
            "type": "object"
        }
    }
};

    /*
    <aw-sublocation sub-location-preference="data.provider.awSubLocationPref">
    <aw-sublocation-body>
        <aw-default-sublocation sub-panel-context="data.provider" base-selection="ctx.modelObjectsToOpen[0]"></aw-default-sublocation>
    </aw-sublocation-body>
</aw-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSublocation subLocationPreference={AwParseService.instance( "data.provider.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <aw-sublocation-body>
            <AwDefaultSublocation subPanelContext={AwParseService.instance( "data.provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} baseSelection={AwParseService.instance( "ctx.modelObjectsToOpen[0]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwDefaultSublocation>
        </aw-sublocation-body>
    </AwSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;