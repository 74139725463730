// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import CbaSplit from 'viewmodel/CbaSplitViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/CadBomOccurrenceAlignmentService', () => import('js/CadBomOccurrenceAlignmentService'));
registerDynImportEntry('js/cbaPageViewService', () => import('js/cbaPageViewService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "cbaContext": {
            "initialValues": {
                "srcStructure": "",
                "trgStructure": "",
                "isCBAFirstLaunch": false,
                "ImpactAnalysis": {},
                "linkedBOM": {}
            },
            "meta": {}
        }
    },
    "ports": {
        "updateCBAContextPage": {
            "syncObject": {}
        }
    },
    "actions": {
        "initCBAPage": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeServiceForCBA"
                },
                {
                    "action": "getData"
                }
            ]
        },
        "getData": {
            "actionType": "JSFunctionAsync",
            "method": "loadCBAData",
            "inputData": {
                "context": "{{fields.cbaContext}}"
            },
            "deps": "js/CadBomOccurrenceAlignmentService",
            "outputData": {
                "data.modelObjectsToOpen": "result.modelObjectsToOpen",
                "cbaContext.srcStructure": "result.srcStructure",
                "cbaContext.trgStructure": "result.trgStructure",
                "cbaContext.isCBAFirstLaunch": "result.isCBAFirstLaunch",
                "cbaContext.ImpactAnalysis": "result.ImpactAnalysis",
                "cbaContext.isImpactAnalysisMode": "result.isImpactAnalysisMode"
            }
        },
        "initializeServiceForCBA": {
            "actionType": "JSFunction",
            "method": "initializeServiceForCBA",
            "deps": "js/CadBomOccurrenceAlignmentService"
        },
        "destroyCBASplitView": {
            "actionType": "JSFunction",
            "method": "destroyCbaPageView",
            "deps": "js/cbaPageViewService",
            "inputData": {}
        },
        "clearCbaContext": {
            "actionType": "JSFunction",
            "method": "clearCbaContext",
            "deps": "js/CadBomOccurrenceAlignmentService",
            "outputData": {
                "cbaContext": ""
            },
            "events": {
                "success": [
                    {
                        "name": "refreshCbaPage.getData",
                        "eventData": {
                            "urlParams": "{{data.eventData}}"
                        }
                    }
                ]
            }
        },
        "refreshCbaSublocation": {
            "actionType": "JSFunction",
            "method": "refreshCba",
            "deps": "js/CadBomOccurrenceAlignmentService",
            "inputData": {
                "data": "{{data}}",
                "context": "{{fields.cbaContext}}"
            }
        },
        "updateCbaContextInTaskBar": {
            "actionType": "Sync",
            "inputData": {
                "port": "updateCBAContextPage",
                "syncObject": {
                    "cbaContext": "{{fields.cbaContext}}"
                }
            }
        }
    },
    "lifecycleHooks": {
        "onInit": "initCBAPage",
        "onUnmount": "destroyCBASplitView",
        "onUpdate": [
            {
                "action": "updateCbaContextInTaskBar",
                "observers": [
                    "viewModel.data.cbaContext"
                ]
            }
        ]
    },
    "onEvent": [
        {
            "eventId": "refreshCbaPage",
            "cacheEventData": true,
            "action": "clearCbaContext"
        },
        {
            "eventId": "refreshCbaPage.getData",
            "cacheEventData": true,
            "action": "refreshCbaSublocation"
        }
    ],
    "_viewModelId": "CbaPage",
    "_uniqueViewModelId": "CbaPage",
    "ctx": {}
};

    /*
    <cba-split sub-panel-context="{...data.modelObjectsToOpen,...fields}"></cba-split>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <CbaSplit subPanelContext={AwParseService.instance( "{...data.modelObjectsToOpen,...fields}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </CbaSplit>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;