// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * VIM Help command service to open Help in new Tab
 *
 * @module js/VIMHelpCommnandService
 */

import appCtxSvc from 'js/appCtxService';



'use strict';

let exports = {};

let vimHelpPrefValues = null;
let vimBestPracticePrefValues = null;
/**
 * Function for open VIM Best Practice, which requires one input argument.
 * @param {string} locationName - The currently opend location name
 */
export let openBestPractice = function (locationName) {
    let finalLink = null;
    if (locationName === "com.siemens.splm.clientfx.tcui.xrt.showObjectLocation" || locationName === "com.siemens.splm.client.search.SearchLocation") {
        vimBestPracticePrefValues = appCtxSvc.getCtx('preferences.V4B_VIM_BEST_PRACTICE_OBJECT_MAPPING_PREF');
        let objectType = appCtxSvc.getCtx('selected.type');
        for (let ii = 0; ii < vimBestPracticePrefValues.length; ii++) {
            let prefValue = vimBestPracticePrefValues[ii].split('=');
            let prefObjectType = prefValue[0].split('.');
            if (prefObjectType[0] === objectType) {
                for (let jj = 0; jj < prefObjectType.length; jj++) {
                    if ("Default" === prefObjectType[jj]) {
                        finalLink = vimBestPracticePrefValues[ii].substring(vimBestPracticePrefValues[ii].indexOf("http"), vimBestPracticePrefValues[ii].length);
                        window.open(finalLink, "", "");
                        break;
                    }
                }
            }
        }
    } else {
        vimBestPracticePrefValues = appCtxSvc.getCtx('preferences.V4B_VIM_BEST_PRACTICE_MAPPING_PREF');
        for (let ii = 0; ii < vimBestPracticePrefValues.length; ii++) {
            let prefValue = vimBestPracticePrefValues[ii].split('=');
            let prefLocationName = prefValue[0].split('.');
            if (prefLocationName[0] === locationName) {
                for (let mm = 0; mm < prefLocationName.length; mm++) {
                    if ("Default" === prefLocationName[mm]) {
                        finalLink = vimBestPracticePrefValues[ii].substring(vimBestPracticePrefValues[ii].indexOf("http"), vimBestPracticePrefValues[ii].length);
                        window.open(finalLink, "", "");
                        break;
                    }
                }
            }
        }
    }
};

/**
 * Function for open VIM help, which requires two input arguments.
 * @param {string} locationName - The currently opend location name
 * @param {string} currentLoginRole - The Currenlty login role
 */
export let openHelp = function(locationName, currentLoginRole) {

    //Open VIM help for object types(AUFOder, FTMIssue Revision, Ticket and BEMi Item)


    if (locationName === "com.siemens.splm.clientfx.tcui.xrt.showObjectLocation" || locationName === "com.siemens.splm.client.search.SearchLocation") {
      let finalLink = null;
      let matchRole = false;
      vimHelpPrefValues = appCtxSvc.getCtx('preferences.V4B_VIM_HELP_SYSTEM_OBJECT_MAPPING_PREF');
      let obejctType = appCtxSvc.getCtx('selected.type');
      for (let ii = 0; ii < vimHelpPrefValues.length; ii++) {
        let prefValue = vimHelpPrefValues[ii].split('=');
        let prefLocationName = prefValue[0].split('.');
        if (prefLocationName[0] === obejctType) {
          for (let jj = 0; jj < prefLocationName.length; jj++) {
            if (currentLoginRole === prefLocationName[jj]) {
              matchRole = true;
              finalLink = vimHelpPrefValues[ii].substring(vimHelpPrefValues[ii].indexOf("http"), vimHelpPrefValues[ii].length);
              window.open(finalLink, "", "");
              break;
            }
          }

            }


        }
        if (!matchRole) {
            for (let kk = 0; kk < vimHelpPrefValues.length; kk++) {
                let prefValue = vimHelpPrefValues[kk].split('=');
                let prefLocationName = prefValue[0].split('.');

                if (prefLocationName[0] === obejctType) {
                    for (let mm = 0; mm < prefLocationName.length; mm++) {
                        if ("Default" === prefLocationName[mm]) {
                            finalLink = vimHelpPrefValues[kk].substring(vimHelpPrefValues[kk].indexOf("http"), vimHelpPrefValues[kk].length);
                            window.open(finalLink, "", "");
                            break;
                        }

                    }

                }
            }
        }
    }

    //Open help for locations(AUF and FTM TKB)
    else {

        let matchRole = false;
        vimHelpPrefValues = appCtxSvc.getCtx('preferences.V4B_VIM_HELP_SYSTEM_MAPPING_PREF');

        let finalLink = null;

        for (let ii = 0; ii < vimHelpPrefValues.length; ii++) {
            let prefValue = vimHelpPrefValues[ii].split('=');
            let prefLocationName = prefValue[0].split('.');
            if (prefLocationName[0] === locationName) {
                for (let jj = 0; jj < prefLocationName.length; jj++) {
                    if (currentLoginRole === prefLocationName[jj]) {
                        matchRole = true;
                        finalLink = vimHelpPrefValues[ii].substring(vimHelpPrefValues[ii].indexOf("http"), vimHelpPrefValues[ii].length);


                        window.open(finalLink, "", "");


                        break;
                    }

                }
            }

        }
        if (!matchRole) {
            for (let kk = 0; kk < vimHelpPrefValues.length; kk++) {
                let prefValue = vimHelpPrefValues[kk].split('=');
                let prefLocationName = prefValue[0].split('.');

                if (prefLocationName[0] === locationName) {
                    for (let mm = 0; mm < prefLocationName.length; mm++) {
                        if ("Default" === prefLocationName[mm]) {
                            finalLink = vimHelpPrefValues[kk].substring(vimHelpPrefValues[kk].indexOf("http"), vimHelpPrefValues[kk].length);
                            window.open(finalLink, "", "");
                            break;
                        }

                    }

                }
            }
        }
    }
};

exports = {
    openHelp,
    openBestPractice
  };

export default exports;