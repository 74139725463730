// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 *
 * @module js/epManageProductViewsService
 */

import _ from 'lodash';
import browserUtils from 'js/browserUtils';
import cdm from 'soa/kernel/clientDataModel';
import epLoadService from 'js/epLoadService';
import epLoadInputHelper from 'js/epLoadInputHelper';
import { constants as _epLoadConstants } from 'js/epLoadConstants';
import fmsUtils from 'js/fmsUtils';
import saveInputWriterService from 'js/saveInputWriterService';
import epSaveService from 'js/epSaveService';
import soaService from 'soa/kernel/soaService';
import sessionCtxSvc from 'js/sessionContext.service';
import AwPromiseService from 'js/awPromiseService';
import preferenceService from 'soa/preferenceService';

'use strict';

const SNAPSHOT_OBJECT_TYPE = 'SnapShotViewData';
const PMI_INFO_KEY = 'StrucExpandClientControlInfo';
const PMI_TARGET_ID = 'PMITargetIds';
const PMI_DEFAULT_PV_ANCHOR_UID = 'DefaultPVAnchorUID';
const VIS_REUSE_TC_SERVER_PREF = 'AWV0AWVisReuseTCServer';
const SHARE_PART_VIEWER = 'SharePartViewer';
const SHARE_ALL_VIEWER = 'ShareAllViewers';

let _soaPath;

/**
 * The TC (SOA) proxy servlet context. This must be the same as the FmsProxyServlet mapping in the web.xml
 */
const WEB_XML_SOA_PROXY_CONTEXT = 'tc';

/**
 * The Vis proxy servlet context. This must be the same as the VisPoolProxy mapping in the web.xml
 */
const WEB_XML_VIS_PROXY_CONTEXT = 'VisProxyServlet' + '/';

/**
 * Relative path to client side accessible SOA services. Specifically this is the root path, not the path
 * which AWC client generally sends requests to.
 */
const CLIENT_SOA_PATH = WEB_XML_SOA_PROXY_CONTEXT + '/';

/**
 * open in Visualisation
 * This creates a vvi file which can be opened in Standalone Vis application
 *
 */

export function openInVis( datasetsToShow, currentScope, selectedOperation, ebomRoot ) {
    const objsForVis = [];
    objsForVis.push( currentScope );

    const input = {
        info: [ { occurrencesList: objsForVis } ]
    };

    // This selectedPVs is an array of Product views which needs to be included in Standalone vis.
    const selectedPVs = datasetsToShow.filter( dataset => dataset.type === SNAPSHOT_OBJECT_TYPE );

    //Returns the vis context uid of the given dataset
    soaService.post( 'Visualization-2013-12-StructureManagement', 'createVisSCsFromBOMsWithOptions', input )
        .then( function( response ) {
            const output = response.output;
            if( output && output.length > 0 ) {
                const visContext = output[ 0 ].structureRecipe;

                const baseUid = visContext.uid;

                const idInfos = {
                    id: null,
                    idAdditionalInfo: {
                        BaseDoc_UID: baseUid,
                        BaseDocTransient: 'True'
                    },
                    item: null,
                    itemRev: null,
                    operation: 'Open'
                };

                if( selectedPVs === null || selectedPVs === undefined || selectedPVs.length === 0 ) {
                    idInfos.id = visContext;
                } else {
                    idInfos.id = selectedPVs[ 0 ];

                    for( const pvIndex of selectedPVs.keys() ) {
                        idInfos.idAdditionalInfo.NumSnapshots = ( pvIndex + 1 ).toString();
                        const key = 'VisDoc_UID_' + ( pvIndex + 1 ).toString();
                        idInfos.idAdditionalInfo[ key ] = selectedPVs[ pvIndex ].uid;
                    }
                }

                /**
                 * adding the data required to load PMIs consumed under selected operation
                 * Key = StrucExpandClientControlInfo
                 * Vaue = EBOM Root ; selected operation
                 */
                if( selectedOperation ) {
                    if( ebomRoot ) {
                        const pmiInfo = ebomRoot.uid + ';' + selectedOperation.uid + ';scope-' + currentScope.uid;
                        idInfos.idAdditionalInfo[ PMI_INFO_KEY ] = pmiInfo;
                    }

                    // Adding key for default selection in standalone vis
                    idInfos.idAdditionalInfo[ PMI_DEFAULT_PV_ANCHOR_UID ] = selectedOperation.uid;

                    let loadTypeInput = [];
                    const loadTypeforObj = epLoadInputHelper.getLoadTypeInputs( [ _epLoadConstants.GET_PROPERTIES ], selectedOperation.uid, [ 'bl_clone_stable_occurrence_id' ], '', [] );
                    loadTypeInput.push( loadTypeforObj[ 0 ] );

                    epLoadService.loadObject( loadTypeInput, false ).then( function( output ) {
                        // Adding bl_clone_stable_occurrence_id of selected operation
                        if( selectedOperation.props.bl_clone_stable_occurrence_id && selectedOperation.props.bl_clone_stable_occurrence_id.dbValues.length > 0 ) {
                            if( selectedOperation.props.bl_clone_stable_occurrence_id.dbValues[ 0 ] !== null ) {
                                idInfos.idAdditionalInfo[ PMI_TARGET_ID ] = selectedOperation.props.bl_clone_stable_occurrence_id.dbValues[ 0 ];
                            }
                        }
                        reviseCheckAndLaunchFile( selectedOperation, idInfos );
                    } );
                } else {
                    // BTO do not have selected operation it takes selected pert node.
                    reviseCheckAndLaunchFile( currentScope, idInfos );
                }
            }
        } );
}

function reviseCheckAndLaunchFile( selectedObject, additionaInfo ) {
    const saveInputWriter = saveInputWriterService.get();
    let relatedObjects = [];
    const selectedObj = cdm.getObject( selectedObject.uid );
    saveInputWriter.addReviseInput( selectedObj );
    relatedObjects.push( selectedObj );
    let resourceBundle = 'GraphicsMessages';
    epSaveService.saveChanges( saveInputWriter, true, relatedObjects, resourceBundle ).then( function( _output ) {
        createLaunchFile( additionaInfo ).then( function( fmsTkt ) {
            const fileName = fmsUtils.getFilenameFromTicket( fmsTkt );
            fmsUtils.openFile( fmsTkt, fileName );
        } );
    } );
}

function createLaunchFile( additionalInfo ) {
    return AwPromiseService.instance.all( [ _getAWTcSharePreference(), _getServerInfo() ] ).then( function(values ) {
        const userAgentInfo = _getUserAgentInfo();

        let sessionDescVal = null;
        const useTcSharePref = values[0];
        if (useTcSharePref === SHARE_PART_VIEWER || useTcSharePref === SHARE_ALL_VIEWER) {
            sessionDescVal = sessionCtxSvc.getSessionDiscriminator();
        }

        const sessionInfo = {};
        sessionInfo.sessionDescriminator = sessionDescVal;
        sessionInfo.sessionAdditionalInfo = {};
        sessionInfo.sessionAdditionalInfo.CLIENT = 'AW';
    
        const idInfos = [];
        idInfos.push( additionalInfo );
    
        const input = {};
        input.idInfos = idInfos;
        input.serverInfo = values[1];
        input.userDataAgentInfo = userAgentInfo;
        input.sessionInfo = sessionInfo;
    
        return soaService.post( 'Visualization-2011-02-DataManagement', 'createLaunchFile', input )
            .then( function( response ) {
                return response.ticket;
            } );
           } );
}

function _getServerInfo() {
    return _getSoaPath().then(function (soaPath) {
        const protocol = soaPath.substring(0, soaPath.indexOf('://', 0));

        const serverInfo = {};

        serverInfo.protocol = protocol;
        serverInfo.hostpath = soaPath;
        serverInfo.servermode = 4;

        return serverInfo;
    });
}

function _getUserAgentInfo() {
    const userAgentInfo = {};

    userAgentInfo.userApplication = sessionCtxSvc.getClientID();
    userAgentInfo.userAppVersion = sessionCtxSvc.getClientVersion();

    return userAgentInfo;
}

/**
 * Get default SOA path information
 *
 * @function _getDefaultSoaPath
 * @memberof frameAdapterService
 *
 * @return {Object} A default SOA path string
 */
function _getDefaultSoaPath() {
    return browserUtils.getBaseURL() + CLIENT_SOA_PATH;
}

/**
 * Get SOA path information from vis server
 *
 * @function _getSoaPath
 * @memberof frameAdapterService
 *
 * @return {Promise} A promise resolved once SOA path info is obtained
 */
function _getSoaPath() {
    if ( !_.isEmpty( _soaPath ) ) {
        return AwPromiseService.instance.resolve( _soaPath );
    }

    const connectionUrl = browserUtils.getBaseURL() + WEB_XML_VIS_PROXY_CONTEXT;

    return window.JSCom.Health.HealthUtils.getSOAFullPath( connectionUrl ).then(
        function( soaPathFromVisServer ) {
            if ( !_.isEmpty( soaPathFromVisServer ) ) {
                _soaPath = soaPathFromVisServer;
            } else {
                _soaPath = _getDefaultSoaPath();
            }
            return _soaPath;
        },
        function(err) {
            _soaPath = _getDefaultSoaPath();

            return _soaPath;
        } );
}

/**
 * Get AW tc Share preference value
 */
function _getAWTcSharePreference() {
    return preferenceService.getStringValue( VIS_REUSE_TC_SERVER_PREF ).then( ( value ) => {
        return value ? value : SHARE_PART_VIEWER;
    } ).catch( function() {
        return SHARE_PART_VIEWER;
    } );
}

const exports = {
    openInVis
};

export default exports;
