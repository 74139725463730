// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwCommandPanel from 'viewmodel/AwCommandPanelViewModel';
import AwPeoplePicker from 'viewmodel/AwPeoplePickerViewModel';
import AwPanelFooter from 'viewmodel/AwPanelFooterViewModel';
import AwWidget from 'viewmodel/AwWidgetViewModel';
import AwButton from 'viewmodel/AwButtonViewModel';
import AwI18n from 'viewmodel/AwI18nViewModel';
    import { VisibleWhen,EnableWhen } from 'js/hocCollection';
const AwPeoplePickerVisibleWhen = VisibleWhen(AwPeoplePicker);
const AwWidgetVisibleWhen = VisibleWhen(AwWidget);
const AwButtonVisibleWhenEnableWhen = EnableWhen(VisibleWhen(AwButton));
AwPeoplePickerVisibleWhen.displayName = 'AwPeoplePickerVisibleWhen';
AwWidgetVisibleWhen.displayName = 'AwWidgetVisibleWhen';
AwButtonVisibleWhenEnableWhen.displayName = 'AwButtonVisibleWhenEnableWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Awp0ReassignTask', () => import('js/Awp0ReassignTask'));
registerDynImportEntry('js/Awp0WorkflowAssignmentUtils', () => import('js/Awp0WorkflowAssignmentUtils'));
registerDynImportEntry('js/Awp0WorkflowUtils', () => import('js/Awp0WorkflowUtils'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-command-panel.directive",
        "js/aw-command-sub-panel.directive",
        "js/aw-panel-footer.directive",
        "js/aw-widget.directive",
        "js/aw-button.directive",
        "js/aw-i18n.directive",
        "js/visible-when.directive",
        "js/enable-when.directive"
    ],
    "actions": {
        "initPanel": {
            "actionType": "JSFunction",
            "method": "populatePanelData",
            "inputData": {
                "selectedObjects": "{{props.subPanelContext.selectionData.value.selected}}",
                "addUserPanelState": "{{data.addUserPanelState}}",
                "criteria": {
                    "providerContentType": "User,GroupMember,ResourcePool",
                    "searchString": "*",
                    "selectedObject": "{{props.subPanelContext.selectionData.value.selected[0].uid}}",
                    "searchSubGroup": "true"
                }
            },
            "outputData": {
                "addUserPanelState": "userPanelState",
                "isDataInit": "isDataInit"
            },
            "deps": "js/Awp0ReassignTask"
        },
        "reassign": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "getSelectedReviewers"
                },
                {
                    "action": "reassignInternal"
                }
            ]
        },
        "getSelectedReviewers": {
            "actionType": "JSFunctionAsync",
            "method": "getValidObjectsToAdd",
            "inputData": {
                "propName": "{{addUserPanelState.criteria}}",
                "selectedUsers": "{{addUserPanelState.selectedUsers}}",
                "baseActiveFiltersStructure": "{{addUserPanelState.baseActiveFiltersStructure}}"
            },
            "outputData": {
                "reviewers": ""
            },
            "deps": "js/Awp0WorkflowAssignmentUtils"
        },
        "reassignInternal": {
            "actionType": "TcSoaService",
            "serviceName": "Workflow-2014-06-Workflow",
            "method": "performAction3",
            "inputData": {
                "input": "{{function:getInputData}}"
            },
            "deps": "js/Awp0ReassignTask",
            "events": {
                "success": [
                    {
                        "name": "primaryWorkarea.reset",
                        "condition": "subPanelContext.openedObject === undefined"
                    },
                    {
                        "name": "complete",
                        "eventData": {
                            "source": "toolAndInfoPanel"
                        }
                    },
                    {
                        "name": "cdm.relatedModified",
                        "condition": "subPanelContext.openedObject !== undefined",
                        "eventData": {
                            "refreshLocationFlag": true,
                            "relatedModified": [
                                "{{props.subPanelContext.openedObject}}"
                            ]
                        }
                    }
                ]
            },
            "actionMessages": {
                "failure": [
                    {
                        "message": "reassignTaskFailure"
                    }
                ]
            }
        },
        "closePanel": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "complete",
                        "condition": "!data.isDestroyed()",
                        "eventData": {
                            "source": "toolAndInfoPanel"
                        }
                    }
                ]
            }
        },
        "updateUserSelection": {
            "actionType": "JSFunction",
            "method": "updateUserSelection",
            "inputData": {
                "data": "{{data.eventData.selectedObjects}}"
            },
            "outputData": {
                "selectedObjects": ""
            },
            "deps": "js/Awp0ReassignTask"
        },
        "closePanelOnSelectionChange": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "cacheInitialSelection",
                    "condition": "!data.initialSelectionUids"
                },
                {
                    "action": "closePanel",
                    "condition": "data.initialSelectionUids[0] !== props.subPanelContext.selectionData.selected[0].uid || data.initialSelectionCount !== props.subPanelContext.selectionData.selected.length"
                }
            ]
        },
        "cacheInitialSelection": {
            "actionType": "JSFunction",
            "method": "cacheMultiSelection",
            "inputData": {
                "selectedUid": "{{props.subPanelContext.selectionData.selected}}"
            },
            "outputData": {
                "initialSelectionUids": "selectedObjectUids",
                "initialSelectionCount": "selectionCount"
            },
            "deps": "js/Awp0WorkflowUtils"
        }
    },
    "data": {
        "comments": {
            "displayName": "{{i18n.comments}}",
            "type": "STRING",
            "isRequired": "false",
            "numberOfLines": 3,
            "maxLength": 4000,
            "dbValue": "",
            "uiValue": ""
        },
        "addUserPanelState": {
            "initialValues": {
                "provider": "Awp0PeoplePickerProvider",
                "selectionModelMode": "single",
                "criteria": {
                    "providerContentType": "User,GroupMember,ResourcePool",
                    "searchString": "*",
                    "selectedObject": "",
                    "searchSubGroup": "true"
                },
                "preferenceCriteria": [
                    "WRKFLW_user_panel_content_display",
                    "WRKFLW_allow_resourcepool_assignments",
                    "WRKFLW_show_user_assignment_options"
                ],
                "selectedUsers": "",
                "isAddButtonNeeded": false
            },
            "meta": {
                "selectedUsers": {
                    "field": "true",
                    "type": "OBJECTARRAY"
                }
            }
        }
    },
    "lifecycleHooks": {
        "onMount": "initPanel",
        "onUpdate": {
            "action": "closePanelOnSelectionChange",
            "observers": [
                "props.subPanelContext.selectionData.selected"
            ]
        }
    },
    "functions": {
        "getInputData": {
            "functionName": "getInputData",
            "parameters": [
                "{{props.subPanelContext.selectionData.value.selected}}",
                "{{data.reviewers}}",
                "{{data.comments.dbValue}}"
            ]
        }
    },
    "conditions": {
        "isValidToAdd": {
            "expression": "addUserPanelState.selectedUsers && addUserPanelState.selectedUsers.length > 0"
        }
    },
    "onEvent": [
        {
            "eventId": "Workflow.closeOpenedPanel",
            "action": "closePanel"
        }
    ],
    "messages": {
        "reassignTaskFailure": {
            "messageType": "ERROR",
            "messageText": "{{errorCode}}"
        }
    },
    "i18n": {
        "reassignTaskCommandTitle": [
            "InboxMessages"
        ],
        "reassign": [
            "InboxMessages"
        ],
        "comments": [
            "InboxMessages"
        ]
    },
    "_viewModelId": "Awp0ReassignTask",
    "_uniqueViewModelId": "Awp0ReassignTask",
    "ctx": {}
};

    /*
    <aw-command-panel caption="i18n.reassignTaskCommandTitle">
    <aw-people-picker visible-when="data.isDataInit" sub-panel-context="{...props.subPanelContext, addUserPanelState : data.addUserPanelState}"></aw-people-picker>
    <aw-panel-footer class="sw-column">
        <aw-widget prop="data.comments" hint="textarea" visible-when="conditions.isValidToAdd" > </aw-widget>
        <div class='sw-button-group justify-right align-right'>
            <aw-button size="auto" action="reassign" visible-when="true" enable-when="conditions.isValidToAdd"><aw-i18n>i18n.reassign</aw-i18n></aw-button>
        </div>
    </aw-panel-footer>
</aw-command-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwCommandPanel revealAction={actions.reveal} updateActiveView={AwParseService.instance( "{activeView: data.activeView, dispatch: dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} caption={i18n.reassignTaskCommandTitle}>
        <AwPeoplePickerVisibleWhen subPanelContext={AwParseService.instance( "{...props.subPanelContext, addUserPanelState : fields.addUserPanelState}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} visibleWhen={AwParseService.instance( "data.isDataInit" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwPeoplePickerVisibleWhen>
        <AwPanelFooter className="sw-column">
            <AwWidgetVisibleWhen {...getField("data.comments", fields, $index, null, null )} hint="textarea" visibleWhen={AwParseService.instance( "conditions.isValidToAdd" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwWidgetVisibleWhen>
            <div className="sw-button-group justify-right align-right">
                <AwButtonVisibleWhenEnableWhen size="auto" action={actions.reassign} visibleWhen={AwParseService.instance( "true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} enableWhen={AwParseService.instance( "conditions.isValidToAdd" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <AwI18n>
                        {i18n.reassign}
                    </AwI18n>
                </AwButtonVisibleWhenEnableWhen>
            </div>
        </AwPanelFooter>
    </AwCommandPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;