// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanel from 'viewmodel/AwPanelViewModel';
import AwTabSet from 'viewmodel/AwTabSetViewModel';
    import { ExistWhen } from 'js/hocCollection';
const AwTabSetExistWhen = ExistWhen(AwTabSet);
AwTabSetExistWhen.displayName = 'AwTabSetExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/tcmaMfgSearchInputBuilderService', () => import('js/tcmaMfgSearchInputBuilderService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "scopes": {
            "initialValues": {
                "objects": []
            },
            "meta": {}
        },
        "mfgSearchTabs": [
            {
                "name": "{{i18n.searchTitle}}",
                "panelId": "TcmaMfgSearchTab",
                "tabKey": "TcmaMfgSearchTabKey"
            },
            {
                "name": "{{i18n.resultsTitle}}",
                "panelId": "TcmaMfgSearchResultsTab",
                "tabKey": "TcmaMfgSearchResultsTabKey"
            }
        ]
    },
    "actions": {
        "loadScopeObjects": {
            "actionType": "JSFunctionAsync",
            "method": "loadScopeObjects",
            "deps": "js/tcmaMfgSearchInputBuilderService",
            "inputData": {
                "data": "{{fields.scopes}}"
            }
        }
    },
    "lifecycleHooks": {
        "onMount": "loadScopeObjects"
    },
    "i18n": {
        "searchTitle": [
            "tcmaMfgSearchMessages"
        ],
        "resultsTitle": [
            "tcmaMfgSearchMessages"
        ]
    },
    "_viewModelId": "TcmaMfgSearch",
    "_uniqueViewModelId": "TcmaMfgSearch",
    "ctx": {}
};

    /*
    <aw-panel class="afx-content-background aw-tcma-tab-container">
    <aw-tab-set tabs="data.mfgSearchTabs" exist-when= "data.scopes" tab-set-id="mfgSearchTabSet" sub-panel-context="{scopes: data.scopes}"></aw-tab-set>
</aw-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanel  revealAction={actions.reveal}  className="afx-content-background aw-tcma-tab-container">
        <AwTabSetExistWhen  updateHook={AwParseService.instance( "{data, conditions,dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} tabs={AwParseService.instance( "data.mfgSearchTabs" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} tabSetId="mfgSearchTabSet" subPanelContext={AwParseService.instance( "{scopes: fields.scopes}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "fields.scopes" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwTabSetExistWhen>
    </AwPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;