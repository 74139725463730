// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * @module js/mbmScopingService
 */
import mbmAssignPartsHandler from 'js/mbmAssignPartsHandler';
import cdm from 'soa/kernel/clientDataModel';
import mbmUtils from 'js/mbmUtils';
import { constants as mbmConstants } from 'js/mbmConstants';
import mfeVMOService from 'js/services/mfeViewModelObjectLifeCycleService';

/**
 * Performs set assignment scope operation
 *  @param {String} contextKey current contextKey
 * @param {Object} mbmContext  Objects
 * @param {Array} sourceObjects array of source Objects
 * @param {Object} targetObject target object
 * @param {String} actionType specifies action scope
 * @returns {Promise} promise
 */
let removeScope = function( contextKey, mbmContext, sourceObjects, targetObject, actionType ) {
    let sourceVmo = sourceObjects.map( function( obj ) {
        return mfeVMOService.createViewModelObjectFromUid( obj.removeScopeUid );
    } );
    return  mbmAssignPartsHandler._saveChanges( contextKey, mbmContext, sourceVmo, targetObject, actionType ).then( function( response ) {
        if ( actionType === mbmConstants.MBM_REMOVE_SCOPE && targetObject.props.mbm0AssignmentDomain.dbValues.length === 0  ) {
            const objToUpdate = mbmUtils.getValueToUpdate( false, mbmConstants.MBM_ENABLE_FOCUS );
            mbmUtils.updateValueToContext( mbmContext, objToUpdate );
        }
        return response;
    } );
};

let evaluateObjectForRemoveScope = function( modelObject ) {
    let evaluatedRemoveScopeObject = {
        isMultipleEbomScope: false,
        assignmentScopeInfo: {
            targetObject: modelObject,
            sourceObjects: []
        }
    };
    if ( modelObject.hasOwnProperty( 'props' ) && modelObject.props.mbm0AssignmentDomain ) {
        if( modelObject.props.mbm0AssignmentDomain.dbValues.length > 1 ) {
            evaluatedRemoveScopeObject.isMultipleEbomScope = true;
        }
    } else if ( !modelObject.hasOwnProperty( 'props' ) ) {
        modelObject = cdm.getObject( modelObject.uid );
        if( modelObject.props.mbm0AssignmentDomain.dbValues.length > 1 ) {
            evaluatedRemoveScopeObject.isMultipleEbomScope = true;
        }
    }
    let uids = modelObject.props.mbm0AssignmentDomain.dbValues;
    let uiValues = modelObject.props.mbm0AssignmentDomain.uiValues;
    let sourceObjects = [];
    for( let i = 0; i < uids.length; i++ ) {
        sourceObjects.push( {
            displayName: uiValues[i],
            removeScopeUid: uids[i]
        } );
    }
    evaluatedRemoveScopeObject.assignmentScopeInfo.sourceObjects = sourceObjects;
    return evaluatedRemoveScopeObject;
};

export default {
    removeScope,
    evaluateObjectForRemoveScope
};

