import $ from "jquery";
import soaSvc from "soa/kernel/soaService";
import appCtxSvc from "js/appCtxService";
import cdm from "soa/kernel/clientDataModel";
import dmSvc from "soa/dataManagementService";
import AwPromiseService from "js/awPromiseService";
import viewModelObjSvc from "js/viewModelObjectService";
import configuratorUtils from 'js/configuratorUtils';

let exports = {};

let effectivityDisplayStringParser = function (data) {
  let inx;
  let deferred = AwPromiseService.instance.defer();
  let tsArr = [];
  data.g4b_BP = " ";
  data.g4b_DER = " ";
  data.g4b_SOP = " ";
  let effString = appCtxSvc.getCtx(
    "occmgmtContext.productContextInfo.props.fgf0EffectivityDisplay.dbValues[0]"
  ); //'Unit=1..UP, BP = VPBG & DER = G12 & SOP = 1803';

  effString = effString.replace(/ /g, "");
  effString = effString.replace(/[<>]/g, "");
  // eslint-disable-next-line no-console
  console.log(effString);
  if (effString.indexOf(",") > 0) {
    let effArr = effString.split(",");
    for (inx = 0; inx < effArr.length; inx++) {
      if (
        effArr[inx].indexOf("DER") > -1 &&
        effArr[inx].indexOf("BP") > -1 &&
        effArr[inx].indexOf("SOP") > -1
      ) {
        tsArr = effArr[inx].split("&");
      }
    }
  }

  if (tsArr.length > 0) {
    for (inx = 0; inx < tsArr.length; inx++) {
      if (tsArr[inx].indexOf("DER=") > -1) {
        if (tsArr[inx].split("=").length > 1) {
          data.g4b_DER = tsArr[inx].split("=")[1];
        }
      }
      if (tsArr[inx].indexOf("BP=") > -1) {
        if (tsArr[inx].split("=").length > 1) {
          data.g4b_BP = tsArr[inx].split("=")[1];
        }
      }
      if (tsArr[inx].indexOf("SOP=") > -1) {
        if (tsArr[inx].split("=").length > 1) {
          data.g4b_SOP = tsArr[inx].split("=")[1];
        }
      }
    }
  }
  deferred.resolve(" ");
  return deferred.promise;
};

//  loading for getting the variant rules from PCI

let loadProductContextInfoObj = function (selected) {
  let deferred = AwPromiseService.instance.defer();

  let aceActiveCtx = appCtxSvc.getCtx("aceActiveContext.context");
  let uidsToload = [];

  let pciObj = cdm.getObject(aceActiveCtx.elementToPCIMap[selected.uid]);
  if (pciObj == null) {
    uidsToload.push(aceActiveCtx.elementToPCIMap[selected.uid]);
  }

  if (uidsToload.length <= 0) {
    deferred.resolve("no pci to load");
  } else {
    let inputData = {
      uids: uidsToload,
    };
    soaSvc
      .post("Core-2007-09-DataManagement", "loadObjects", inputData)
      .then(function () {
        deferred.resolve("objects loaded");
      });
  }

  return deferred.promise;
};

export let getPartitionSchemeUID = function () {
  let occmgmtContext = appCtxSvc.getCtx("occmgmtContext");
  let partitionSchemeUID = "none";
  for (let key in occmgmtContext.elementToPCIMap) {
    if (occmgmtContext.elementToPCIMap[key].indexOf(",PS:") != -1) {
      partitionSchemeUID = occmgmtContext.elementToPCIMap[key].substring(
        occmgmtContext.elementToPCIMap[key].indexOf(",PS:") + 4,
        occmgmtContext.elementToPCIMap[key].indexOf(
          ",",
          occmgmtContext.elementToPCIMap[key].indexOf(",PS:") + 1
        )
      );
      //
      break;
    }
  }

  return partitionSchemeUID;
};

export let initiateSummaryGeneration = function (data, selected, i18n) {
  let initiateSummaryGenerationDeferred = AwPromiseService.instance.defer();

  let promise = loadProductContextInfoObj(selected);
  let aceActiveCtx = appCtxSvc.getCtx("aceActiveContext.context");

  appCtxSvc.updateCtx("TaKoExcelExportCtx", {});

  $.when(promise).done(function () {
    let pciObj = cdm.getObject(aceActiveCtx.elementToPCIMap[selected.uid]);
    let variantRule = {
      uid: pciObj.props.awb0VariantRules.dbValues[0],
      name: pciObj.props.awb0VariantRules.uiValues[0],
    };
    let configInputData = {
      input: {
        selectedContext: {
          uid: selected.uid,
          type: selected.type,
        },
        activeVariantRules: [
          {
            uid: pciObj.props.awb0VariantRules.dbValues[0],
            type: "VariantRule",
          },
        ],
        scopes: [""],
        payloadStrings: {},
        requestInfo: {
          requestType: ["getConfigSettings"],
          mode: ["fetchActiveSettings"]
        },
      },
    };

    if (variantRule.uid == undefined) {
      data.variantRuleMissingFlag = true;
      let takoExcelExportContext = {
        TakoVariantExcelExportData: {
          inputForTaKoExcelUtility: "No_VariantRule",
        },
      };
      appCtxSvc.updateCtx("TaKoExcelExportCtx", takoExcelExportContext);
      initiateSummaryGenerationDeferred.resolve("No_VariantRule");
    } else {
      soaSvc.post("Internal-ProductConfiguratorAw-2022-12-ConfiguratorManagement", "variantConfigurationView3", configInputData)
        .then(function (response1) {
          let configPerspective = response1 && response1.configPerspective || {};
          let inputData = {
            input: {
              configPerspective: {
                uid: configPerspective.uid,
                type: configPerspective.type
              },
              selectedContext: {
                uid: selected.uid,
                type: selected.type,
              },
              activeVariantRules: [
                {
                  uid: pciObj.props.awb0VariantRules.dbValues[0],
                  type: "VariantRule",
                },
              ],
              scopes: [""],
              payloadStrings: {},
              requestInfo: {
                requestType: ["getConfig"],
                configurationControlMode: ["guided"],
                switchingToGuidedMode: [""],
              },
            },
          };
          soaSvc.post("Internal-ProductConfiguratorAw-2022-12-ConfiguratorManagement", "variantConfigurationView3", inputData)
          .then(function (response2) {
            let configPerspective = response2 && response2.configPerspective || {};
            let inputData = {
              input: {
                configPerspective: {
                  uid: configPerspective.uid,
                  type: configPerspective.type
                },
                selectedContext: {
                  uid: selected.uid,
                  type: selected.type,
                },
                scopes: ["__Selection_Summary__"],
                selectedExpressions: response2.selectedExpressions,
                payloadStrings: {},
                requestInfo: {
                  requestType: ["getConfig"],
                  configurationControlMode: ["guided"]
                },
              },
            };
            soaSvc.post("Internal-ProductConfiguratorAw-2022-12-ConfiguratorManagement", "variantConfigurationView3", inputData)
            .then(function (response3) {
              let populatedScopes = configuratorUtils.populateScopes(response2);
              let summaryOfSelections = [];
              if(response3?.selectionsSummary?.summaryOfSelections) {                
                summaryOfSelections = response3.selectionsSummary.summaryOfSelections;
              }
              let TaKoExcelCtx = {
                selectedCtx: inputData.input.selectedContext,
                variantRule: variantRule,
                configPerspective: response2.configPerspective,
                summaryOfSelections: summaryOfSelections,
                variabilityTreeData: response2.variabilityTreeData,
                populatedScopes: populatedScopes
              };
              let TaKoExcelExportCtx = appCtxSvc.getCtx("TaKoExcelExportCtx");
              if (TaKoExcelExportCtx == undefined) {
                appCtxSvc.registerCtx("TaKoExcelExportCtx", []);
                TaKoExcelExportCtx = appCtxSvc.getCtx("TaKoExcelExportCtx");
              }
              appCtxSvc.updateCtx("TaKoExcelExportCtx", TaKoExcelCtx);
              exports.formatGroupsAndFamilies(response2, data, inputData.input.selectedContext, populatedScopes, initiateSummaryGenerationDeferred);
            });
          });
        });
      }
  });

  return initiateSummaryGenerationDeferred.promise;
};

export let getSummarySelectedContext = function (data, initiateSummaryGenerationDeferred) {
  //console.log("In  getSummary ");
  let TaKoExcelExportCtx = appCtxSvc.getCtx("TaKoExcelExportCtx");

  let selected = TaKoExcelExportCtx.selectedCtx;
  let selectedObj_uid = selected.uid;
  let selectedObj_type = selected.type;
  let summaryOfSelections = TaKoExcelExportCtx.summaryOfSelections;

  let summaryResponse = {
    selectedContext: {
        uid: selectedObj_uid,
        type: selectedObj_type,
    },
    summaryOfSelections: summaryOfSelections,
  };
  TaKoExcelExportCtx.summaryResponse = summaryResponse;
  appCtxSvc.updateCtx("TaKoExcelExportCtx", TaKoExcelExportCtx);

  let promise = populateSelections(data, TaKoExcelExportCtx.summaryResponse);
  $.when(promise).done(function (populateSummary1) {
    TaKoExcelExportCtx.populateSummary = populateSummary1;
    appCtxSvc.updateCtx("TaKoExcelExportCtx", TaKoExcelExportCtx);
    exports.createInputForTaKoExcelExportUtility(populateSummary1.selections, data, initiateSummaryGenerationDeferred);
  });
    
};

export let isTaKoExportDataReady = function () {
  let deferred = AwPromiseService.instance.defer();
  let itr = 0;
  let timer = setInterval(checkCtxFunc, 1000);
  function checkCtxFunc() {
    itr++;
    //console.log("iteration: ", itr);
    if (appCtxSvc.getCtx("TaKoExcelExportCtx") != undefined) {
      if (
        appCtxSvc.getCtx("TaKoExcelExportCtx.TakoVariantExcelExportData") !=
        undefined
      ) {
        if (
          appCtxSvc.getCtx(
            "TaKoExcelExportCtx.TakoVariantExcelExportData.inputForTaKoExcelUtility"
          ) != undefined
        ) {
          clearInterval(timer);
          deferred.resolve("");
        }
      }
    }

    if (itr > 600) {
      //console.log("timedOut for tako excel export");
      deferred.reject("Timed Out for Tako export");
      clearInterval(timer);
    }
  }

  return deferred.promise;
};

export let createInputForTaKoExcelExportUtility = function (
  selections,
  data,
  initiateSummaryGenerationDeferred
) {
  let getTSpromise = effectivityDisplayStringParser(data);

  $.when(getTSpromise).done(function () {
    let getPPGFiltersFromSubsetPromise = exports.getPPGsFromTakoSubset(
      appCtxSvc.getCtx("selected.props.awb0UnderlyingObject.dbValues[0]")
    );
    $.when(getPPGFiltersFromSubsetPromise).done(function (PPGValues) {
      let getBTNumberPromise = dmSvc.getPropertiesUnchecked(
        [
          {
            uid: appCtxSvc.getCtx(
              "occmgmtContext.topElement.props.awb0UnderlyingObject.dbValues[0]"
            ),
          },
        ],
        ["g4b_BTNumber"]
      );
      $.when(getBTNumberPromise).done(function (response) {
        let g4b_BTNumber = "";
        if (
          response.modelObjects[
            appCtxSvc.getCtx(
              "occmgmtContext.topElement.props.awb0UnderlyingObject.dbValues[0]"
            )
          ] != undefined
        ) {
          g4b_BTNumber =
            response.modelObjects[
              appCtxSvc.getCtx(
                "occmgmtContext.topElement.props.awb0UnderlyingObject.dbValues[0]"
              )
            ].props.g4b_BTNumber.dbValues[0];
        }

        // eslint-disable-next-line no-console
        console.log("PPGdata: ", PPGValues);
        let summaryData = selections;
        let inputForTaKoExcelUtility = "";
        for (let inx = 0; inx < summaryData.length; inx++) {
          if (
            summaryData[inx].selectionState != undefined &&
            summaryData[inx].selectionState >= 0
          ) {
            //avoid negative selection states
            //Incase of Multi-select families SLPS,SLPP , the option name itself to be shown as family name
            if (
              summaryData[inx].familyDisplayName == "SLPS" ||
              summaryData[inx].familyDisplayName == "SLPP"
            ) {
              inputForTaKoExcelUtility +=
                summaryData[inx].summaryFamilyOptValue + "|";
            } else {
              inputForTaKoExcelUtility +=
                summaryData[inx].familyDisplayName + "|";
            }
            inputForTaKoExcelUtility +=
              summaryData[inx].summaryNameOptValue + "|";
            if (
              summaryData[inx].selectionState == 1 ||
              summaryData[inx].selectionState == 5 ||
              summaryData[inx].selectionState == 9
            ) {
              inputForTaKoExcelUtility +=
                summaryData[inx].summaryFamilyOptValue;
            } else if (
              summaryData[inx].selectionState == 2 ||
              summaryData[inx].selectionState == 6 ||
              summaryData[inx].selectionState == 10
            ) {
              inputForTaKoExcelUtility += "NOT";
            }
            inputForTaKoExcelUtility += "~";
          }
        }
        let variantRule = appCtxSvc.getCtx(
          "occmgmtContext.productContextInfo.props.awb0CurrentVariantRule.uiValues[0]"
        );
        let productLine = appCtxSvc.getCtx(
          "selected.props.fgd0ModelObject.uiValues[0]"
        );

        //TODO: putting empty values now for tako excel export
        let g4b_SOP = data.g4b_SOP;
        let g4b_DER = data.g4b_DER;
        let g4b_BP = data.g4b_BP;
        let lastModUser = appCtxSvc.getCtx(
          "selected.props.fgf0LastModUser.uiValues[0]"
        );
        let lastModDate = appCtxSvc.getCtx(
          "selected.props.fgf0LastModDate.uiValues[0]"
        );

        let metaDataForExcel = "~metaDataForExcel~";
        metaDataForExcel += "Filtername|" + variantRule + " ~";
        metaDataForExcel += "Produktlinie|" + productLine + " ~";
        metaDataForExcel += "SOP LCI  / PUMA|" + g4b_SOP + " ~";
        metaDataForExcel += "Derivat|" + g4b_DER + " ~";
        metaDataForExcel += "Bauphase|" + g4b_BP + " ~";
        metaDataForExcel += "BTNummer|" + g4b_BTNumber + " ~";
        metaDataForExcel += "Letzter andernder Benutzer|" + lastModUser + " ~";
        metaDataForExcel += "Letzter Anderungsdatum|" + lastModDate + " ~";
        metaDataForExcel += "PPG Filters|";

        for (let jnx = 0; jnx < PPGValues.length; jnx++) {
          metaDataForExcel += PPGValues[jnx].ppgValue + "^ !";
        }
        metaDataForExcel += " ~";
        inputForTaKoExcelUtility += metaDataForExcel;

        let ExcelVariantExport = {};

        //console.log("inputForTaKoExcelUtility: \n"+ inputForTaKoExcelUtility);
        ExcelVariantExport.uid = appCtxSvc.getCtx("selected.uid");
        ExcelVariantExport.type = appCtxSvc.getCtx("selected.type");
        ExcelVariantExport.inputForTaKoExcelUtility = inputForTaKoExcelUtility;

        appCtxSvc.updateCtx(
          "TaKoExcelExportCtx.TakoVariantExcelExportData",
          ExcelVariantExport
        );
        initiateSummaryGenerationDeferred.resolve(
          "Summary Generation Completed!"
        );
        //eventBus.publish("getSubscriptionInput_TaKoExcelExport.event");
      });
    });
  });
};

//*******************************************************************************************/
// *NOTE* Below functions are picked up from the *G4B_configuratorService.js*
//*******************************************************************************************/

export let listAllGroupData = function (
  data,
  initiateSummaryGenerationDeferred
) {
  let TaKoExcelExportCtx = appCtxSvc.getCtx("TaKoExcelExportCtx");

  let response = TaKoExcelExportCtx;
  if (response.populatedScopes.length > 0) {
    let selected = TaKoExcelExportCtx.selectedCtx;
    let selectedObj_uid = selected.uid;
    let selectedObj_type = selected.type;
    let configPerspective = TaKoExcelExportCtx.configPerspective;

    let groupsLength = response.populatedScopes.length;

    let listAllGroupData = appCtxSvc.getCtx("listAllGroupData");
    if (listAllGroupData == undefined) {
      appCtxSvc.registerCtx("listAllGroupData", []);
      listAllGroupData = appCtxSvc.getCtx("listAllGroupData");
    } else if (listAllGroupData.length == response.populatedScopes.length) {
      let loadAllGroups = false;
      for (let inx = 0; inx < listAllGroupData.length; inx++) {
        if ($.isEmptyObject(listAllGroupData[inx].families[0].values[0].optValue.props)) {
          loadAllGroups = true;
          break;
        }
      }
      if (loadAllGroups === true) {
        //GEPA-16165: summary gets corrupted : since the wsObject of the feature objects gets emptied/unloaded somehow on change of page/sublocation
        listAllGroupData = [];
        appCtxSvc.updateCtx("storeFamilyData", []); //empty the context variables if the
        appCtxSvc.updateCtx("listAllGroupData", []);
      } else {
        exports.getSummarySelectedContext(
          data,
          initiateSummaryGenerationDeferred
        );
        return;
      }
    }

    for (let i = 0; i < TaKoExcelExportCtx.populatedScopes.length; i++) {
      let inputData = {
        input: {
          configPerspective: configPerspective,
          selectedContext: {
            uid: selectedObj_uid,
            type: selectedObj_type,
          },

          scopes: [TaKoExcelExportCtx.populatedScopes[i].optGroup],
          requestInfo: {
            requestType: ["getConfig"],
            configurationControlMode: ["manual"],
            switchingToGuidedMode: [""],
          },
        },
      };
      if (
        cdm.getObject(TaKoExcelExportCtx.populatedScopes[i].optGroup)
          ?.props.object_string.dbValues[0].indexOf("SLP") > -1
      ) {
        inputData.input.requestInfo.configurationControlMode = ["guided"];
      }

      soaSvc.post("Internal-ProductConfiguratorAw-2022-12-ConfiguratorManagement", "variantConfigurationView3",inputData)
        .then(function (response3) {
          try {
            let existingfscContext = appCtxSvc.getCtx("fscContext");
            let needFscContextModification = false;
            if(response3?.scopes[0] === "__Fsc_Unassigned_Group__") {
              needFscContextModification = true;
              let fscContext = appCtxSvc.getCtx("fscContext");
              if(fscContext) {
                  fscContext.guidedMode = false;
              } else {
                fscContext = { guidedMode: false};
              }
              appCtxSvc.updateCtx("fscContext", {...fscContext});
            }
            let scopesList = configuratorUtils.populateScopes(response3);
            if (needFscContextModification) {
              appCtxSvc.updateCtx("fscContext", {...existingfscContext});
            }
          // eslint-disable-next-line no-console
            console.log("iteration_", i, "_", response3.variabilityTreeData);

            listAllGroupData.push(scopesList);
          appCtxSvc.updateCtx("listAllGroupData", listAllGroupData);

          } catch (error) {
            console.error("Error occured while populating scopes for: ", response3, error)
          }
          if (listAllGroupData.length == groupsLength) {
            // eslint-disable-next-line no-console
            console.log("all Groups loaded");
            exports.getSummarySelectedContext(
              data,
              initiateSummaryGenerationDeferred
            );
            //0000 eventBus.publish("callPopulateSummary", response);
          }
        });
    }
    return "";
  } else {
    return "";
  }
};

export let formatGroupsAndFamilies = function (response, data, selectedContext, populatedScopes, initiateSummaryGenerationDeferred) {
  let allResponseUids = [];
    allResponseUids = extractUids(populatedScopes);

  let inputData = {
    objects: allResponseUids,
    attributes: ["object_name", "object_desc"],
  };

    soaSvc.post("Internal-Core-2007-12-Session", "getProperties", inputData).then(function (getPropResponse) {
      //  console.log("Properties of Groups and Families",response);
        populatedScopes = populateScopes(populatedScopes, getPropResponse);
        let TaKoExcelExportCtx = appCtxSvc.getCtx("TaKoExcelExportCtx");
        TaKoExcelExportCtx.groupsInContext = populatedScopes;
        TaKoExcelExportCtx.populatedScopes = populatedScopes;

        appCtxSvc.updateCtx("TaKoExcelExportCtx", TaKoExcelExportCtx);
        //eventBus.publish("listAllGroupDataForCompare");
        exports.listAllGroupData(data, initiateSummaryGenerationDeferred);
    });
};

let extractUids = function (scopeList = []) {
  return scopeList.map(scope => {
    if(scope?.families?.length > 0) {
      return {uid: scope.families[0].familyStr, type: ""};
      }
    else {
      return {uid: scope.optGroup, type: ""}
    }
  });



};

export let getPPGsFromTakoSubset = function (subsetUid, data) {
  let deferred = AwPromiseService.instance.defer();

  let subsetObj = cdm.getObject(subsetUid);
  let inputData = {};
  inputData.objects = [subsetObj];
  inputData.attributes = ["g4b_TaKoPPGs"];

  soaSvc
    .post("Core-2006-03-DataManagement", "getProperties", inputData)
    .then(function (response) {
      let PPGListArr =
        response.modelObjects[subsetUid].props.g4b_TaKoPPGs.dbValues;
      PPGListArr = removeDuplicates(PPGListArr);
      PPGListArr.map(function (e) {
        return e.toString();
      });
      PPGListArr.sort();

      //console.log("PPGArray: " + PPGListArr);

      let ppgListCheckBoxArray = [];
        for (let inx = 0; inx < PPGListArr.length; inx++) {
          ppgListCheckBoxArray.push({
            ppgValue: PPGListArr[inx],
            isChecked: false,
            status: 0,
          });
        }
      
      //register a ctx for impact analysis of PPG
      appCtxSvc.registerCtx("ppgListArray", ppgListCheckBoxArray);
      deferred.resolve(ppgListCheckBoxArray);
    });
  return deferred.promise;
};

let removeDuplicates = function (arr) {
  let obj = {};
  let ret_arr = [];
  for (let i = 0; i < arr.length; i++) {
    obj[arr[i]] = true;
  }
  for (let key in obj) {
    ret_arr.push(key);
  }
  return ret_arr;
};

let populateSelections = function (data, resultData) {
  let i, j;
  let deferred = AwPromiseService.instance.defer();
  let familiesUid = [];
  let familiesUidTemp = [];
  let allGroups = [];
  let selections = [];

  let listAllGroupData = appCtxSvc.getCtx("listAllGroupData");
  if (listAllGroupData != undefined) {
    for (i = 0; i < listAllGroupData.length; i++) {
      if (listAllGroupData[i]?.families != undefined) {
        allGroups.push(listAllGroupData[i]);
      } else {
        for (j = 0; j < listAllGroupData[i].length; j++) {
          if (listAllGroupData[i][j]?.families?.length > 0) {
            allGroups.push(listAllGroupData[i][j]);
          }
        }
      }
    }
  }

  for (i = 0; i < allGroups.length; i++) {
    if (allGroups[i].families.length > 0) {
      let families = allGroups[i].families;
      for (j = 0; j < families.length; j++) {
        let familyUidObject = {};
        familyUidObject.uid = families[j].familyStr;
        familyUidObject.type = "";
        familiesUid.push(familyUidObject);
      }
    }
  }

  familiesUidTemp = familiesUid;
  let inputData = { objects: familiesUidTemp, attributes: ["object_name", "object_desc"]};

  soaSvc.post("Internal-Core-2007-12-Session", "getProperties", inputData)
    .then(function (response) {
      let i, j, summary;
      appCtxSvc.updateCtx("listAllGroupData", allGroups);
      if (resultData.summaryOfSelections) {
        for (i = 0; i < resultData.summaryOfSelections.length; i++) {
          summary = {};
          let selectionInfo = resultData.summaryOfSelections[i];
          try {
            if ((selectionInfo?.selectionState == 1 && selectionInfo?.valueDisplayName?.length == 0) || selectionInfo?.valueDisplayName == "") {
              let foundOptionFamilyWSObject = checkIfOptionPresentInSLPGroup(selectionInfo.familyDisplayName, allGroups);
              if (JSON.stringify(foundOptionFamilyWSObject) === "{}") {
                continue;
              } else {
                summary.familyDisplayName =foundOptionFamilyWSObject.familyObject.props.object_name.dbValues[0];
                summary.valueDisplayValue = foundOptionFamilyWSObject.featureObject.props.object_name.dbValues[0];
                summary.selectionState = selectionInfo.selectionState;
              }
            } else {
              summary.familyDisplayName = selectionInfo.familyDisplayName;
              summary.selectionState = selectionInfo.selectionState;
              summary.valueDisplayValue = selectionInfo.valueDisplayName;
              summary.isSystemSelection = true; //Adding a letiable in summary to distinguish between system and user selections
            }
  
            /**
             * fetching all details of values present in selection from allGroups
             */
            for (let p = 0; p < allGroups.length; p++) {
              let families = allGroups[p].families;
              for (let q = 0; q < families.length; q++) {
                let optionValues = families[q].values;
                for (let r = 0; r < optionValues.length; r++) {
                  if (summary?.familyDisplayName == optionValues[r]?.valueDisplayName || summary?.valueDisplayValue == optionValues[r]?.optValue?.props?.object_name?.dbValues[0]) {
                    summary.familyName = families[q].familyDisplayName;
                    summary.parentGroup = "";
                    summary.optName = optionValues[r].optValue.props.object_name.dbValues[0];
                    summary.summaryFamilyOptValue = summary.optName;
                    let desc = optionValues[r].optValue.cellHeader2;
  
                    summary.summaryNameOptValue = desc;
                    summary.optDesc = summary.summaryNameOptValue;
                    summary.familySourceUid = allGroups[p].families[q].familyStr;
  
                    if (response != undefined) {
                      summary.technicalFamilyName = response.modelObjects[summary.familySourceUid].props.object_desc.dbValues[0];
                    }
  
                    if (summary?.optName != summary?.valueDisplayValue && summary?.valueDisplayValue != "") {
                      summary.summaryFamilyOptValue = summary.valueDisplayValue;
                    }
  
                    if (summary?.familyDisplayName == "_TYP" ||summary?.familyDisplayName == "TYP") {
                      summary.summaryNameOptValue = summary.valueDisplayValue;
                      summary.summaryFamilyOptValue = summary.familyDisplayName;
                    }
                  }
                }
              }
            }
            selections.push(summary);
          } catch (error) {
            console.error("Error occured while fetching selectionInfo details for excel export: ", selectionInfo, allGroups);
          }
         
        }
      }

      let populateSummary = {};
      populateSummary.selections = selections;

      deferred.resolve(populateSummary);
    });
  return deferred.promise;
};

/**
 * Create group structure to display in view
 */
export let populateScopes = function (scopeList, getPropResponse) {
  for (let i = 0; i < scopeList.length; i++) {
    if (getPropResponse != undefined) {
      try {
        scopeList[i].group_DisplayName = getPropResponse.modelObjects[scopeList[i].optGroup] &&
        getPropResponse.modelObjects[scopeList[i].optGroup].props.object_name.dbValues[0] || "";
 
      } catch (error) {
        console.error("Error while updating family disply name", error);
    }
      if (scopeList[i]?.families?.length > 0 ) {
        try {
          scopeList[i].families.forEach(family => {
            family.familyDesc = getPropResponse.modelObjects[family.familyStr] &&
            getPropResponse.modelObjects[family.familyStr].props.object_desc.dbValues[0] || "";
          });
        } catch (error) {
          console.error("Error while updating familyDesc", error);
      }
    }
    }

  }
  return scopeList;
};

/**
 * Returns option families for a group *
 *
 * @param {Object} optionGroup - option group from SOA response
 * @param {ObjectArray} families - option families list for a group from SOA response
 * @param {ObjectArray} labels - Labels to be shown on feature objects like violations etc.
 *
 * @returns {ObjectArray} The array of option families for option group
 */
function getFamiliesForGroup(optionGroup, families, labels, getPropResponse) {
  let tmpfamilies = [];
  for (let i = 0; i < families.length; i++) {
    let tmpFamily = {};
    tmpFamily.familyDisplayName = families[i].familyObj.displayName;
    tmpFamily.familyStr = families[i].selectionInfo.nodeID;
    if (getPropResponse != undefined) {
      tmpFamily.familyDesc =
        getPropResponse.modelObjects[
          tmpFamily.familyStr
        ].props.object_desc.dbValues[0];
    }

    tmpFamily.familyType = families[i].familyObj.sourceType;
    tmpFamily.complete = families[i].familyObj.props.isComplete[0] === "true";
    tmpFamily.isFiltered = true;
    tmpFamily.isThumbnailDisplay =
      families[i].familyObj.props.isThumbnailDisplay[0] === "true";
    tmpFamily.values = getValuesForFamily(families[i].features, labels);
    tmpFamily.singleSelect =
      families[i].familyObj.props.isSingleSelect[0] === "true";
    if (families[i].familyObj.props.cfg0IsDiscretionary) {
      tmpFamily.cfg0IsDiscretionary =
        families[i].familyObj.props.cfg0IsDiscretionary[0] === "true";
    }
    tmpfamilies.push(tmpFamily);
  }
  return tmpfamilies;
}
/**
 * Returns option values for a family
 *
 * @param {ObjectArray} option values list for a families from SOA response
 * @param {ObjectArray} labels - Labels to be shown on feature objects like violations etc.
 *
 * @returns {ObjectArray} The array of values for option family
 */
function getValuesForFamily(optionValues, labels) {
  let tmpValues = [];
  for (let i = 0; i < optionValues.length; i++) {
    let tmpValue = {};
    tmpValue.index = i % 2;
    tmpValue.valueDisplayName = optionValues[i].featureObject.displayName;
    tmpValue.isFiltered = true;
    tmpValue.selectionState = optionValues[i].selectionInfo.selectionState;

    tmpValue.allowedSelectionStates = optionValues[i].allowedSelectionStates;
    tmpValue.isPackage =
      optionValues[i].featureObject.props.isPackage[0] === "true";
    if (tmpValue.isPackage) {
      tmpValue.packageOpened = false;
    }
    tmpValue.isThumbnailDisplay =
      optionValues[i].featureObject.props.isThumbnailDisplay[0] === "true";

    let violationsInfo = buildViolationString(
      optionValues[i].selectionInfo.nodeID,
      labels
    );

    if (
      violationsInfo !== null &&
      violationsInfo.violationMessage !== undefined
    ) {
      tmpValue.violationsInfo = violationsInfo;
      tmpValue.hasViolation = true;
    } else {
      tmpValue.hasViolation = false;
    }

    if (cdm.isValidObjectUid(optionValues[i].featureObject.wsObject.uid)) {
      tmpValue.optValue = viewModelObjSvc.createViewModelObject(
        optionValues[i].featureObject.wsObject
      );
    }
    tmpValue.optValueStr = optionValues[i].selectionInfo.nodeID;
    tmpValues.push(tmpValue);
  }
  return tmpValues;
}

/**
 * Builds the violation string.
 *
 * @param {String} featureId - UID of a feature
 * @param {ObjectArray} labels - Labels to be shown on feature objects like violations etc.
 *
 * @returns {ObjectArray} The array of values for option family
 */

function buildViolationString(featureId, labels) {
  let violationInfo = {};
  let violationMessage = "";
  let violationIds = [];
  let violationObj;

  if (labels !== undefined) {
    let nodeMap = labels.violationMap[0].nodeMap;
    if (nodeMap !== undefined) {
      violationObj = nodeMap[featureId];
      if (violationObj !== undefined) {
        violationIds = violationObj[0].props.violationIDs;
      }
    }
  }

  if (violationIds.length > 0) {
    for (let i = 0; i < violationIds.length; i++) {
      let violationId = violationIds[i];
      violationObj = labels.violations[0].nodeMap[violationId];
      violationMessage =
        violationMessage + (i + 1) + ". " + violationObj[0].displayName + "\n";

      if (i === 0) {
        // Server will always contain first violation id with highest severity.
        // We can rely on it to decide the icon to be shown for violation.
        violationInfo.violationSeverity = violationObj[0].props.serverity[0];
      }
    }
    if (violationMessage.length > 0) {
      violationInfo.violationMessage = violationMessage.slice(0, -1);
    }
  }
  return violationInfo;
}

let checkIfOptionPresentInSLPGroup = function (optionName, allGroups) {
  let foundOptionFamilyWSObject = {};
  try {
    for (let p = 0; p < allGroups.length; p++) {
      if (allGroups[p].vmo?.cellHeader1.indexOf("SLP_") > -1) {
        let families = allGroups[p].families;
        for (let q = 0; q < families.length; q++) {
          let optionValues = families[q].values;
          for (let r = 0; r < optionValues.length; r++) {
            let optionWsObject = optionValues[r].optValue;
            if (optionName == optionWsObject.props.object_name.dbValues[0]) {
              foundOptionFamilyWSObject = {
                featureObject: optionWsObject,
                familyObject: cdm.getObject(families[q].familyStr),
              };
              break;
            }
          }
        }
      }
    }
  } catch (error) {
    console.error("Error occured while executing function checkIfOptionPresentInSLPGroup for ", optionName, allGroups, error);
  }
  
  return foundOptionFamilyWSObject;
};

exports = {
  getPPGsFromTakoSubset,
  formatGroupsAndFamilies,
  listAllGroupData,
  createInputForTaKoExcelExportUtility,
  isTaKoExportDataReady,
  getSummarySelectedContext,
  initiateSummaryGeneration,
  getPartitionSchemeUID,
};

export default exports;
