// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import EpConfigurationChipArea from 'viewmodel/EpConfigurationChipAreaViewModel';
import AwSublocation from 'viewmodel/AwSublocationViewModel';
import MfeLoadingMessage from 'viewmodel/MfeLoadingMessageViewModel';
import MfeContentContainer from 'viewmodel/MfeContentContainerViewModel';
import MfeContentPanel from 'viewmodel/MfeContentPanelViewModel';
import EpHighLevelPlanningDetailsArea from 'viewmodel/EpHighLevelPlanningDetailsAreaViewModel';
import MfeSyncManager from 'viewmodel/MfeSyncManagerViewModel';
    import { ExistWhen } from 'js/hocCollection';
const EpConfigurationChipAreaExistWhen = ExistWhen(EpConfigurationChipArea);
const MfeLoadingMessageExistWhen = ExistWhen(MfeLoadingMessage);
const MfeContentContainerExistWhen = ExistWhen(MfeContentContainer);
EpConfigurationChipAreaExistWhen.displayName = 'EpConfigurationChipAreaExistWhen';
MfeLoadingMessageExistWhen.displayName = 'MfeLoadingMessageExistWhen';
MfeContentContainerExistWhen.displayName = 'MfeContentContainerExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwBaseSublocationService', () => import('js/AwBaseSublocationService'));
registerDynImportEntry('js/epInitializationService', () => import('js/epInitializationService'));
registerDynImportEntry('js/mfeViewModelUtils', () => import('js/mfeViewModelUtils'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "provider": {
            "label": "{{i18n.highLevelPlanningTaskTitle}}",
            "priority": 3,
            "name": "highLevelPlanning",
            "clientScopeURI": "highLevelPlanning",
            "nameToken": "highLevelPlanning"
        },
        "awSubLocationPref": {
            "hideRightWall": true
        },
        "occContext": {
            "initialValues": {
                "selectedModelObjects": [],
                "viewKey": "",
                "productContextInfo": null,
                "elementToPCIMapCount": null,
                "supportedFeatures": null,
                "skipReloadOnConfigParamChange": true
            },
            "meta": {}
        },
        "highLevelPlanningMbomTreeContext": {
            "tabs": [
                {
                    "name": "{{i18n.highLevelPlanningMbomTreeTitle}}",
                    "tabKey": "EpHighLevelPlanningMbomTree"
                }
            ]
        },
        "highLevelPlanningProcessLayoutAreaContext": {
            "tabs": [
                {
                    "name": "{{i18n.highLevelPlanningProcessLayoutAreaTitle}}",
                    "tabKey": "EpHighLevelPlanningProcessTree"
                }
            ],
            "sharedCommandAnchor": "processLayoutAreaAnchor"
        },
        "sharedSelectionData": {
            "initialValues": {
                "isPertModeActive": false,
                "isPRIModeActive": false
            },
            "meta": {}
        },
        "configurationChipsData": {
            "initialValues": {},
            "meta": {}
        }
    },
    "actions": {
        "handleCompleteEvent": {
            "actionType": "JSFunction",
            "method": "handleCompleteEvent",
            "inputData": {
                "provider": "{{parameters.eventData}}"
            },
            "deps": "js/AwBaseSublocationService"
        },
        "initialize": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeEPPage"
                },
                {
                    "action": "updateVisibleTabs"
                },
                {
                    "action": "initialiseConfigurationChips"
                }
            ]
        },
        "initializeEPPage": {
            "actionType": "JSFunctionAsync",
            "method": "initializeEPPage",
            "inputData": {
                "provider": "{{data.provider}}"
            },
            "outputData": {
                "stateContext": "stateContext",
                "propertyPolicyId": "propertyPolicyId"
            },
            "deps": "js/epInitializationService"
        },
        "updateVisibleTabs": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "showBOPTreeView",
                    "condition": "!data.sharedSelectionData.isPertModeActive"
                },
                {
                    "action": "showSequenceView",
                    "condition": "data.sharedSelectionData.isPertModeActive"
                }
            ]
        },
        "showBOPTreeView": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "highLevelPlanningProcessLayoutAreaContext": {
                    "tabs": [
                        {
                            "name": "{{i18n.highLevelPlanningProcessLayoutAreaTitle}}",
                            "tabKey": "EpHighLevelPlanningProcessTree"
                        }
                    ],
                    "sharedCommandAnchor": "processLayoutAreaAnchor"
                }
            },
            "outputData": {
                "highLevelPlanningProcessLayoutAreaContext": ""
            },
            "deps": "js/mfeViewModelUtils"
        },
        "showSequenceView": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "highLevelPlanningProcessLayoutAreaContext": {
                    "tabs": [
                        {
                            "name": "{{i18n.highLevelPlanningProcessLayoutAreaTitle}}",
                            "tabKey": "EpPert",
                            "contextObject": "{{data.sharedSelectionData.selected[0]}}"
                        }
                    ],
                    "sharedCommandAnchor": "processLayoutAreaAnchor"
                }
            },
            "outputData": {
                "highLevelPlanningProcessLayoutAreaContext": ""
            },
            "deps": "js/mfeViewModelUtils"
        },
        "initialiseConfigurationChips": {
            "actionType": "JSFunction",
            "method": "mergeValueInAtomicData",
            "inputData": {
                "targetContext": "{{fields.configurationChipsData}}",
                "updatedValues": {
                    "ebomStructure": "{{ctx.epPageContext.ebomStructure}}",
                    "ebomPCI": "{{ctx.epTaskPageContext.ebomPCI}}",
                    "ebomStructureConfigFlags": "{{ctx.epTaskPageContext.ebomStructureConfigFlags}}",
                    "mbomStructure": "{{ctx.epPageContext.mbomStructure}}",
                    "mbomPCI": "{{ctx.epTaskPageContext.mbomPCI}}",
                    "mbomStructureConfigFlags": "{{ctx.epTaskPageContext.mbomStructureConfigFlags}}",
                    "processStructure": "{{ctx.epPageContext.processStructure}}",
                    "processPCI": "{{ctx.epTaskPageContext.processPCI}}",
                    "processStructureConfigFlags": "{{ctx.epTaskPageContext.processStructureConfigFlags}}",
                    "rootPlant": "{{ctx.epPageContext.rootPlant}}",
                    "plantPCI": "{{ctx.epTaskPageContext.plantPCI}}",
                    "rootPlantConfigFlags": "{{ctx.epTaskPageContext.rootPlantConfigFlags}}",
                    "functionalPlan": "{{ctx.epPageContext.functionalPlan}}",
                    "functionalPlanPCI": "{{ctx.epTaskPageContext.functionalPlanPCI}}",
                    "functionalPlanConfigFlags": "{{ctx.epTaskPageContext.functionalPlanConfigFlags}}"
                }
            },
            "deps": "js/mfeViewModelUtils"
        },
        "hideTrees": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "value": true
            },
            "outputData": {
                "hidePage": ""
            },
            "deps": "js/mfeViewModelUtils"
        }
    },
    "i18n": {
        "highLevelPlanningMbomTreeTitle": [
            "HighLevelPlanningMessages"
        ],
        "highLevelPlanningProcessLayoutAreaTitle": [
            "HighLevelPlanningMessages"
        ]
    },
    "lifecycleHooks": {
        "onMount": "initialize",
        "onUpdate": [
            {
                "action": "updateVisibleTabs",
                "observers": [
                    "viewModel.atomicData.sharedSelectionData.isPertModeActive"
                ]
            }
        ]
    },
    "onEvent": [
        {
            "eventId": "ep.pageContext.changed",
            "action": "initialiseConfigurationChips"
        },
        {
            "eventId": "ep.preSaveConfigurationEvent",
            "action": "hideTrees"
        },
        {
            "eventId": "complete",
            "action": "handleCompleteEvent",
            "inputArgs": {
                "eventData": "{{eventData}}"
            }
        }
    ],
    "_viewModelId": "EpHighLevelPlanningSubPage",
    "_uniqueViewModelId": "EpHighLevelPlanningSubPage",
    "ctx": {
        "epPageContext": {
            "type": "object"
        },
        "epTaskPageContext": {
            "type": "object"
        },
        "ep": {
            "type": "object"
        }
    }
};

    /*
    <ep-configuration-chip-area exist-when="ctx.epTaskPageContext.loadedObject.uid && ctx.ep.scopeObject.uid && !data.hidePage" configuration-chips-data = "data.configurationChipsData"></ep-configuration-chip-area>
<aw-sublocation sub-location-preference="data.awSubLocationPref" sub-panel-context="{provider : data.provider, occContext:data.occContext}">
    <mfe-loading-message exist-when="!ctx.epPageContext.loadedObject.uid || !ctx.ep.scopeObject.uid"></mfe-loading-message>
    <mfe-content-container container-direction="HORIZONTAL" container-name="HighLevelPlanningPage" class="h-12" exist-when="ctx.epPageContext.loadedObject.uid && ctx.ep.scopeObject.uid">
        <mfe-content-container container-direction="VERTICAL" container-name="HighLevelPlanningMBOMArea" class="w-6">
            <mfe-content-panel sub-panel-context="{...data.highLevelPlanningMbomTreeContext,sharedSelectionData: data.sharedSelectionData}"></mfe-content-panel>
        </mfe-content-container>
        <mfe-content-container container-direction="VERTICAL" container-name="HighLevelPlanningProcessAndDetailsArea" class="flex-auto">
            <mfe-content-container container-direction="HORIZONTAL" container-name="HighLevelPlanningProcessArea" class="h-7">
                <mfe-content-panel sub-panel-context="{...data.highLevelPlanningProcessLayoutAreaContext, sharedSelectionData: data.sharedSelectionData}"></mfe-content-panel>
            </mfe-content-container>
            <mfe-content-container container-direction="HORIZONTAL" container-name="HighLevelPlanningDetailsArea" class="h-5">
                <ep-high-level-planning-details-area occ-context="data.occContext"></ep-high-level-planning-details-area>
            </mfe-content-container>
        </mfe-content-container>
    </mfe-content-container>
    <mfe-sync-manager view-id="EpSelectedAssemblyInHighLevelPlanningPageSyncMgr"></mfe-sync-manager>
    <mfe-sync-manager view-id="EpSelectedProcessInHighLevelPlanningPageSyncMgr"></mfe-sync-manager>
</aw-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <EpConfigurationChipAreaExistWhen configurationChipsData={AwParseService.instance( "fields.configurationChipsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "ctx.epTaskPageContext.loadedObject.uid && ctx.ep.scopeObject.uid && !data.hidePage" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </EpConfigurationChipAreaExistWhen>
    <AwSublocation subLocationPreference={AwParseService.instance( "data.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{provider : data.provider, occContext:fields.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <MfeLoadingMessageExistWhen existWhen={AwParseService.instance( "!ctx.epPageContext.loadedObject.uid || !ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </MfeLoadingMessageExistWhen>
        <MfeContentContainerExistWhen containerDirection="HORIZONTAL" containerName="HighLevelPlanningPage" className="h-12" existWhen={AwParseService.instance( "ctx.epPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <MfeContentContainer containerDirection="VERTICAL" containerName="HighLevelPlanningMBOMArea" className="w-6">
                <MfeContentPanel subPanelContext={AwParseService.instance( "{...data.highLevelPlanningMbomTreeContext,sharedSelectionData: fields.sharedSelectionData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MfeContentPanel>
            </MfeContentContainer>
            <MfeContentContainer containerDirection="VERTICAL" containerName="HighLevelPlanningProcessAndDetailsArea" className="flex-auto">
                <MfeContentContainer containerDirection="HORIZONTAL" containerName="HighLevelPlanningProcessArea" className="h-7">
                    <MfeContentPanel subPanelContext={AwParseService.instance( "{...data.highLevelPlanningProcessLayoutAreaContext, sharedSelectionData: fields.sharedSelectionData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </MfeContentPanel>
                </MfeContentContainer>
                <MfeContentContainer containerDirection="HORIZONTAL" containerName="HighLevelPlanningDetailsArea" className="h-5">
                    <EpHighLevelPlanningDetailsArea occ-context="fields.occContext">
                    </EpHighLevelPlanningDetailsArea>
                </MfeContentContainer>
            </MfeContentContainer>
        </MfeContentContainerExistWhen>
        <MfeSyncManager viewId="EpSelectedAssemblyInHighLevelPlanningPageSyncMgr" viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </MfeSyncManager>
        <MfeSyncManager viewId="EpSelectedProcessInHighLevelPlanningPageSyncMgr" viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </MfeSyncManager>
    </AwSublocation>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;