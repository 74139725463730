/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/EP_reviseAndRefreshService
 */

/**
 * Dummy alert.
 * @param {String} text - text to display
 */
 
import cdmSvc from 'soa/kernel/clientDataModel';
import soaService from 'soa/kernel/soaService';
import viewModelObjectSvc from 'js/viewModelObjectService';

let Workflowuid = null;
let WorkflowName = null;

export let assignToWorkflow = function (commandContext, objectname) {
	console.log("assignToWorkflow");
	console.log(commandContext);
	
	if(commandContext.pageId !== undefined)
	{
		let contextObject = cdmSvc.getObject(commandContext.selection[0].props.bl_revision.dbValues[0]);
		//console.log("The Selected uid is :", commandContext.selection[0].props.bl_revision.dbValues[0]);
		//let contextObject = cdmSvc.getObject(data.props.bl_revision.dbValues[0]);
		//console.log("The Selected uid is :", data.props.bl_revision.dbValues[0]);
		console.log("The Selected item id is :", objectname);
		//let vmo = viewModelObjectSvc.createViewModelObject( contextObject );
		
		soaService.post('Workflow-2008-06-Workflow', 'getWorkflowTemplates', {
			//targets: [vmo],
			targets: [contextObject],
			allOrAssignedCriteria: "SOA_EPM_All"
		}).then(function (response) {
			let WorkflowTemplates = response.workflowTemplates;
			WorkflowTemplates.forEach(template => {
				let TemplateName = template.props.object_name.dbValues[0];

				if (TemplateName === "WSP_Revise_Single_Objects_incl_Twin") {
					Workflowuid = template.uid;
					WorkflowName=TemplateName;
					console.log("Found Workflow uid is:", Workflowuid);
					console.log("Found the desired template:", TemplateName);
				}
			});

			let requestPayload = {
				startImmediately: true,
				observerKey: '',
				name: `${WorkflowName} : ${objectname}`,  
				subject: "",
				description: "",
				contextData: {
					processTemplate: WorkflowName,
					attachmentCount: 1,
					attachments: [commandContext.selection[0].props.bl_revision.dbValues[0]],
					attachmentTypes: [1]				
				}
				
			};
			
			soaService.post('Workflow-2008-06-Workflow', 'createInstance', requestPayload).then(function (response) {
				console.log("Workflow instance created successfully", response);
				window.location.reload();
			}).catch(function (error) {
				console.log("Error creating workflow instance", error);
			});
		}).catch(function (error) {
			console.log('SOA error ::', error);
		});
	}
	else
	{
		let contextObject = cdmSvc.getObject(commandContext.selection[0].props.awb0UnderlyingObject.dbValues[0]);

		soaService.post('Workflow-2008-06-Workflow', 'getWorkflowTemplates', {
			targets: [contextObject],
			allOrAssignedCriteria: "SOA_EPM_All"
		}).then(function (response) {
			let WorkflowTemplates = response.workflowTemplates;
			WorkflowTemplates.forEach(template => {
				let TemplateName = template.props.object_name.dbValues[0];

				if (TemplateName === "WSP_Revise_Single_Objects_incl_Twin") {
					Workflowuid = template.uid;
					WorkflowName=TemplateName;
					console.log("Found Workflow uid is:", Workflowuid);
					console.log("Found the desired template:", TemplateName);
				}
			});

			let requestPayload = {
				startImmediately: true,
				observerKey: '',
				name: `${WorkflowName} : ${objectname}`,  
				subject: "",
				description: "",
				contextData: {
					processTemplate: WorkflowName,
					attachmentCount: 1,
					attachments: [commandContext.selection[0].props.awb0UnderlyingObject.dbValues[0]],
					attachmentTypes: [1]
				
				}
				
			};		
			
			soaService.post('Workflow-2008-06-Workflow', 'createInstance', requestPayload).then(function (response) {
				console.log("Workflow instance created successfully", response);			
				window.location.reload();			
			}).catch(function (error) {
				console.log("Error creating workflow instance", error);
			});
		}).catch(function (error) {
			console.log('SOA error ::', error);
		});
	}
};

export default {
    assign: assignToWorkflow
};

