// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwWorkingDays from 'viewmodel/AwWorkingDaysViewModel';
import AwTotalHours from 'viewmodel/AwTotalHoursViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const DivVisibleWhen = VisibleWhen('div');
DivVisibleWhen.displayName = 'DivVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwCalendarService', () => import('js/AwCalendarService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "workingTableData": {
            "meta": {}
        },
        "totalRanges": {
            "initialValues": {},
            "meta": {}
        },
        "selectedDay": {
            "initialValues": {},
            "meta": {}
        },
        "sharedData": {
            "initialValues": {},
            "meta": {}
        },
        "updatedTotalHoursData": {
            "initialValues": {},
            "meta": {}
        },
        "workingHoursDataProvider": {
            "initialValues": {},
            "meta": {}
        }
    },
    "actions": {
        "updateCalendarParent": {
            "actionType": "JSFunction",
            "method": "updateCalendarParent",
            "inputData": {
                "newUpdatedRanges": "{{data.workingHoursDataProvider.newWorkingHoursDataProvider}}",
                "updatedDefaultRanges": "{{subPanelContext.updatedDefaultRanges}}"
            },
            "deps": "js/AwCalendarService"
        }
    },
    "props": {
        "selected-calendar-data": {
            "type": "object"
        },
        "sub-panel-context": {
            "type": "object"
        },
        "selected-date": {
            "type": "object"
        },
        "edit-mode": {
            "type": "boolean"
        },
        "aw-calendar-atomic-data": {
            "type": "object"
        }
    },
    "selectionModels": {
        "workingDaysSelectionModel": {
            "selectionMode": "multiple"
        },
        "totalHoursSelectionModel": {
            "selectionMode": "multiple"
        }
    },
    "lifecycleHooks": {
        "onUpdate": [
            {
                "action": "updateCalendarParent",
                "observers": [
                    "viewModel.atomicData.workingHoursDataProvider.newWorkingHoursDataProvider"
                ]
            }
        ]
    },
    "i18n": {
        "defaultWorkingDaysHours": [
            "CalendarManagementMessages"
        ],
        "workingHours": [
            "CalendarManagementMessages"
        ]
    },
    "_viewModelId": "AwDefaultWorkingDaysAndHours",
    "_uniqueViewModelId": "AwDefaultWorkingDaysAndHours",
    "ctx": {}
};

    /*
    <aw-panel>
    <aw-panel-body>
            <aw-panel-section caption="i18n.defaultWorkingDaysHours" context="subPanelContext">
                <div class="sw-row">
                    <div class="sw-column">
                        <aw-working-days sub-panel-context="{selectionData: data.workingTableData, selectionModel: selectionModels.workingDaysSelectionModel, workingHoursDataProvider:data.workingHoursDataProvider}" updatedTotalHoursData="{{data.sharedData.value}}" selected-calendar-data="props.selectedCalendarData" selected-date="props.selectedDate"></aw-working-days>
                    </div>
                    <div class="sw-column" visible-when="data.workingTableData.selected.length === 1">
                        <aw-panel-section caption="i18n.workingHours">
                            <aw-total-hours aw-calendar-atomic-data="props.awCalendarAtomicData" edit-mode="{{props.editMode}}" sub-panel-context="{sharedData:data.sharedData, selectionModel: selectionModels.totalHoursSelectionModel}" total-ranges="data.workingTableData.selected[0].props.totalRanges" selected-day="data.workingTableData.selected[0].props.workingDays"></aw-total-hours>
                        </aw-panel-section>
                    </div>
                </div>
            </aw-panel-section>
    </aw-panel-body>
</aw-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanel  revealAction={actions.reveal}>
        <AwPanelBody>
            <AwPanelSection caption={i18n.defaultWorkingDaysHours} context="subPanelContext">
                <div className="sw-row">
                    <div className="sw-column">
                        <AwWorkingDays subPanelContext={AwParseService.instance( "{selectionData: fields.workingTableData, selectionModel: selectionModels.workingDaysSelectionModel, workingHoursDataProvider:fields.workingHoursDataProvider}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} updatedtotalhoursdata={AwParseService.instance( "fields.sharedData.value" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} selectedCalendarData={AwParseService.instance( "props.selectedCalendarData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} selectedDate={AwParseService.instance( "props.selectedDate" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        </AwWorkingDays>
                    </div>
                    <DivVisibleWhen className="sw-column" visibleWhen={AwParseService.instance( "fields.workingTableData.selected.length === 1" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        <AwPanelSection caption={i18n.workingHours}>
                            <AwTotalHours awCalendarAtomicData={AwParseService.instance( "props.awCalendarAtomicData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} editMode={AwParseService.instance( "props.editMode" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{sharedData:fields.sharedData, selectionModel: selectionModels.totalHoursSelectionModel}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} totalRanges={AwParseService.instance( "fields.workingTableData.selected[0].props.totalRanges" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} selectedDay={AwParseService.instance( "fields.workingTableData.selected[0].props.workingDays" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            </AwTotalHours>
                        </AwPanelSection>
                    </DivVisibleWhen>
                </div>
            </AwPanelSection>
        </AwPanelBody>
    </AwPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;