// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwProgressIndicator from 'viewmodel/AwProgressIndicatorViewModel';
import UiView from 'viewmodel/UiViewViewModel';
import AwLayoutSlot from 'viewmodel/AwLayoutSlotViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "_viewModelId": "MfeRoot",
    "_uniqueViewModelId": "MfeRoot",
    "ctx": {}
};

    /*
    <div class='sw-column awRoot'>
    <aw-progress-indicator></aw-progress-indicator>
    <div class='sw-row flex-auto'>
        <div class='sw-column flex-auto sw-order-two'>
            <ui-view></ui-view>
        </div>
        <div class='flex-shrink sw-order-one'>
            <aw-layout-slot name='aw_rightEdge' context="data"></aw-layout-slot>
        </div>
        <div class='flex-shrink sw-order-three'>
            <aw-layout-slot name='aw_rightEdge' context="data"></aw-layout-slot>
        </div>
    </div>
</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="sw-column awRoot">
        <AwProgressIndicator>
        </AwProgressIndicator>
        <div className="sw-row flex-auto">
            <div className="sw-column flex-auto sw-order-two">
                <UiView>
                </UiView>
            </div>
            <div className="flex-shrink sw-order-one">
                <AwLayoutSlot name="aw_rightEdge" context={AwParseService.instance( "data" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwLayoutSlot>
            </div>
            <div className="flex-shrink sw-order-three">
                <AwLayoutSlot name="aw_rightEdge" context={AwParseService.instance( "data" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwLayoutSlot>
            </div>
        </div>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;