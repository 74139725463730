// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwColumn from 'viewmodel/AwColumnViewModel';
import AwCommandPanel from 'viewmodel/AwCommandPanelViewModel';
import AwSearchObjects from 'viewmodel/AwSearchObjectsViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "searchStateForKeyword": {
            "initialValues": {
                "provider": "Awp0FullTextSearchProvider",
                "additionalCriteria": {
                    "test": "test"
                },
                "propertyPolicy": [
                    {
                        "name": "TestObjForExample",
                        "properties": [
                            {
                                "name": "TestPropertyForExample"
                            }
                        ]
                    }
                ],
                "label": {
                    "source": "/i18n/SearchMessages",
                    "key": "resultsText"
                },
                "autoApplyFilters": true
            },
            "meta": {
                "categories": [
                    {
                        "filterValues": [
                            {
                                "selected": {
                                    "field": "true",
                                    "type": "BOOLEAN"
                                }
                            }
                        ]
                    }
                ]
            }
        },
        "searchStateForAdvanced": {
            "initialValues": {
                "provider": "Awp0SavedQuerySearchProvider",
                "propertyPolicy": [
                    {
                        "name": "TestObjForExample",
                        "properties": [
                            {
                                "name": "TestPropertyForExample"
                            }
                        ]
                    }
                ]
            },
            "meta": {}
        }
    },
    "selectionModels": {
        "searchSelectionModel": {
            "selectionMode": "multiple"
        }
    },
    "_viewModelId": "AwSearchObjectsExample",
    "_uniqueViewModelId": "AwSearchObjectsExample",
    "ctx": {}
};

    /*
    <aw-column width="24f">
    <aw-command-panel caption="Search Objects" context="subPanelContext">
        <aw-search-objects sub-panel-context="subPanelContext" search-state-for-advanced="data.searchStateForAdvanced" search-state-for-keyword="data.searchStateForKeyword" selection-model="selectionModels.searchSelectionModel"></aw-search-objects>
    </aw-command-panel>
</aw-column>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwColumn width="24f">
        <AwCommandPanel revealAction={actions.reveal} context={subPanelContext} updateActiveView={AwParseService.instance( "{activeView: data.activeView, dispatch: dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} caption="Search Objects">
            <AwSearchObjects subPanelContext={AwParseService.instance( "subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchStateForAdvanced={AwParseService.instance( "fields.searchStateForAdvanced" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchStateForKeyword={AwParseService.instance( "fields.searchStateForKeyword" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} selectionModel={AwParseService.instance( "selectionModels.searchSelectionModel" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwSearchObjects>
        </AwCommandPanel>
    </AwColumn>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;