// @<COPYRIGHT>@
// ==================================================
// Copyright 2018.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * Archive Service
 *
 * @module js/g4b_ArchiveService
 */

import app from "app";
import eventBus from "js/eventBus";
import soaSvc from "soa/kernel/soaService";
import appCtxSvc from "js/appCtxService";
import cdm from "soa/kernel/clientDataModel";
import msgSvc from "js/messagingService";
import AwPromiseService from "js/awPromiseService";
import localeService from "js/localeService";
import $ from "jquery";

let exports = {};
let getProperty = {};

export let setArchiveUnarchive = function (archiveFlag, subPanelContext) {
  let selected = appCtxSvc.getCtx("selected");
  let mselected = appCtxSvc.getCtx("mselected");
  if (
    selected.type == "VariantRule" &&
    appCtxSvc.getCtx("svrSelected") != undefined
  ) {
    mselected = appCtxSvc.getCtx("svrSelected");
  }
  let currentRole = appCtxSvc.getCtx("userSession").props.role_name.dbValue;
  let surrogateForUserUidsArr = [];
  let loggedInUserUid = appCtxSvc.getCtx("user.uid");
  let inputObjects = [];
  let skippedObjects = [];
  let allObjectUid = [];

  allObjectUid.push({
    uid: loggedInUserUid,
    type: "User",
  });
  let attributes = ["awp0SurrogateFor"];
  let policyJson = {
    types: [
      {
        name: "User",
        properties: [
          {
            name: "awp0SurrogateFor",
            modifiers: [
              {
                name: "withProperties",
                Value: "true",
              },
            ],
          },
        ],
      },
      {
        name: "Awp0UserAssignment",
        properties: [
          {
            name: "awp0User",
            modifiers: [
              {
                name: "withProperties",
                Value: "true",
              },
            ],
          },
        ],
      },
    ],
  };

  getProperty = getProperties(allObjectUid, attributes, policyJson);

  $.when(getProperty)
    .done()
    .then(function (response) {
      if (response.modelObjects != undefined) {
        let surrogateForUserList =
          response.modelObjects[loggedInUserUid].props.awp0SurrogateFor
            .dbValues;
        if (surrogateForUserList != undefined) {
          for (let idx = 0; idx < surrogateForUserList.length; idx++) {
            surrogateForUserUidsArr.push(
              cdm.getObject(surrogateForUserList[idx]).props.awp0User
                .dbValues[0]
            );
          }
        }
      }

      let inputData = {};
      if (
        mselected.length > 0 &&
        (mselected[0].type == "G4B_StatCPWrkSetRevision" ||
          mselected[0].type == "G4B_DynamicConfigProduct" ||
          mselected[0].type == "G4B_AnlCtxWrkSetRevision" ||
          mselected[0].type == "VariantRule")
      ) {
        for (let i = 0; i < mselected.length; i++) {
          if (mselected[i].props.owning_user.dbValues[0] == loggedInUserUid) {
            inputObjects.push(mselected[i]);
          } else if (
            surrogateForUserUidsArr.indexOf(
              mselected[i].props.owning_user.dbValues[0]
            ) != -1
          ) {
            inputObjects.push(mselected[i]);
          } else {
            if (
              currentRole == "Gepard IT Admin" ||
              currentRole == "VVF Admin"
            ) {
              inputObjects.push(mselected[i]);
            } else {
              skippedObjects.push(mselected[i]);
            }
          }
        }
        inputData = {
          objects: inputObjects,
          attributes: {
            g4b_Archived: {
              stringVec: [archiveFlag],
            },
          },
        };

        if (skippedObjects.length > 0) {
          let g4bObjectsNotArchivedMessage = localeService.getLoadedText(
            app.getBaseUrlPath() + "/i18n/GepardOneStepCommandsMessages"
          ).g4bObjectsNotArchived;
          let message = g4bObjectsNotArchivedMessage + " \n";
          for (let j = 0; j < skippedObjects.length; j++) {
            message =
              message +
              "\u2022 " +
              skippedObjects[j].props.object_name.dbValues[0] +
              "\n";
          }
          msgSvc.showWarning(message);
        }
        if (inputObjects.length > 0) {
          soaSvc
            .post("Core-2007-01-DataManagement", "setProperties", inputData)
            .then(function () {
              //Unset svrSelected Context
              if (appCtxSvc.getCtx("svrSelected") != undefined) {
                appCtxSvc.updateCtx("svrSelected", []);
              }
              //Refresh CFSC variantRule  list
              eventBus.publish("G4B_CFG_VariantDataView.getAllVariantRules");
              //update variant rules in g4bSaveCustomVariantRule panel drop down
              if (
                appCtxSvc.getCtx("activeToolsAndInfoCommand") &&
                appCtxSvc.getCtx("activeToolsAndInfoCommand.commandId") ==
                  "g4bSaveCustomVariantRule"
              ) {
                eventBus.publish(
                  "g4bSaveCustomVariantRule.fetchAllVariantRules"
                );
              }
              if (
                subPanelContext.pageContext.secondaryActiveTabId ==
                "tc_xrt_Overview"
              ) {
                eventBus.publish("cdm.relatedModified", {
                  relatedModified: [
                    cdm.getObject(appCtxSvc.getCtx("pselected.ui")),
                  ],
                  refreshLocationFlag: true,
                  relations: "",
                });
              }
            });
        }
      }
      //Handle use case for Archive functionality from within Workset/Subset Definition
      else if (
        selected.type == "Awb0DesignElement" ||
        selected.type == "Fgf0SubsetElement"
      ) {
        let underlyingObject = cdm.getObject(
          selected.props.awb0UnderlyingObject.dbValues[0]
        );
        let owningUserUid =
          selected.type == "Fgf0SubsetElement"
            ? selected.props.fgf0OwningUser.dbValues[0]
            : underlyingObject.props.owning_user.dbValues[0];

        if (owningUserUid == loggedInUserUid) {
          inputObjects.push(underlyingObject);
        } else if (surrogateForUserUidsArr.indexOf(owningUserUid) != -1) {
          inputObjects.push(underlyingObject);
        } else {
          if (currentRole == "Gepard IT Admin" || currentRole == "VVF Admin") {
            inputObjects.push(underlyingObject);
          } else {
            skippedObjects.push(underlyingObject);
          }
        }

        if (skippedObjects.length > 0) {
          let message2 =
            localeService.getLoadedText(
              app.getBaseUrlPath() + "/i18n/GepardOneStepCommandsMessages"
            ).g4bObjectsNotArchived + " \n";
          for (let j = 0; j < skippedObjects.length; j++) {
            message2 =
              message2 +
              "\u2022 " +
              skippedObjects[j].props.object_name.dbValues[0] +
              "\n";
          }
          msgSvc.showWarning(message2);
        }

        inputData = {
          objects: inputObjects,
          attributes: {
            g4b_Archived: {
              stringVec: [archiveFlag],
            },
          },
        };

        if (inputObjects.length > 0) {
          soaSvc
            .post("Core-2007-01-DataManagement", "setProperties", inputData)
            .then(function (response) {
              console.log("response", response);
              eventBus.publish( 'cdm.relatedModified', {
                relatedModified: [appCtxSvc.ctx.xrtSummaryContextObject],
                refreshLocationFlag: true,
              } );
            });
        }
      }
    });
};

let getProperties = function (arrayOfModelObjects, attributes, policy) {
  let deferred = AwPromiseService.instance.defer();
  let inputDataGetProperties = {
    objects: arrayOfModelObjects,
    attributes: attributes,
  };
  soaSvc
    .post(
      "Internal-Core-2007-12-Session",
      "getProperties",
      inputDataGetProperties,
      policy
    )
    .then(function (response) {
      deferred.resolve(response);
    });
  return deferred.promise;
};

exports = {
  setArchiveUnarchive,
};

export default exports;
