// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwIcon from 'viewmodel/AwIconViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const AwIconVisibleWhen = VisibleWhen(AwIcon);
const DivVisibleWhen = VisibleWhen('div');
AwIconVisibleWhen.displayName = 'AwIconVisibleWhen';
DivVisibleWhen.displayName = 'DivVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {},
    "conditions": {
        "isEmptySearchResult": {
            "expression": "subPanelContext.context.searchState.totalFound<=0"
        },
        "isNoItemsCreatedByMeOrSharedByMe": {
            "expression": "conditions.isEmptySearchResult && (subPanelContext.context.name === 'mySnapshots' || subPanelContext.context.name === 'sharedByMeSnapshots')"
        },
        "isNoItemsSharedWithMe": {
            "expression": "conditions.isEmptySearchResult && subPanelContext.context.name === 'sharedwithMeSnapshots'"
        },
        "isNoItemSelected": {
            "expression": "!conditions.isEmptySearchResult && subPanelContext.selection.length === 0"
        },
        "isShowEmptyIcon": {
            "expression": "conditions.isNoItemSelected || conditions.isNoItemsSharedWithMe || conditions.isNoItemsCreatedByMeOrSharedByMe"
        }
    },
    "i18n": {
        "noSnapshotsSelectedLine1": [
            "ViewerSnapshotMessages"
        ],
        "noSnapshotsSelectedLine2": [
            "ViewerSnapshotMessages"
        ]
    },
    "_viewModelId": "AwMyGalleryEmpty",
    "_uniqueViewModelId": "AwMyGalleryEmpty",
    "ctx": {}
};

    /*
    <aw-panel>
    <aw-panel-body>
        <div class="aw-viewersnapshot-emptyCenter">
            <aw-icon class="aw-viewersnapshot-emptyIcon" visible-when="conditions.isEmptySearchResult"
                icon-id="homeGallery"></aw-icon>
            <div class="aw-viewersnapshot-emptyText" visible-when="conditions.isNoItemSelected">
                <aw-icon class="aw-tile-tileImage" icon-id="homeGallery"></aw-icon>
                <div class="aw-viewersnapshot-emptyTextBold"> i18n.noSnapshotsSelectedLine1</div>
                <div class="aw-viewersnapshot-emptyTextItalic"> i18n.noSnapshotsSelectedLine2</div>
            </div>
        </div>
    </aw-panel-body>
</aw-panel>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanel  revealAction={actions.reveal}>
        <AwPanelBody>
            <div className="aw-viewersnapshot-emptyCenter">
                <AwIconVisibleWhen className="aw-viewersnapshot-emptyIcon" iconId="homeGallery" visibleWhen={AwParseService.instance( "conditions.isEmptySearchResult" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwIconVisibleWhen>
                <DivVisibleWhen className="aw-viewersnapshot-emptyText" visibleWhen={AwParseService.instance( "conditions.isNoItemSelected" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <AwIcon className="aw-tile-tileImage" iconId="homeGallery">
                    </AwIcon>
                    <div className="aw-viewersnapshot-emptyTextBold">
                        {i18n.noSnapshotsSelectedLine1}
                    </div>
                    <div className="aw-viewersnapshot-emptyTextItalic">
                        {i18n.noSnapshotsSelectedLine2}
                    </div>
                </DivVisibleWhen>
            </div>
        </AwPanelBody>
    </AwPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;