// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 *
 * @module js/mbmValidateEffectivityService
 */
import { constants as _epBvrConstants } from 'js/epBvrConstants';
import { constants as _epLoadConstants } from 'js/epLoadConstants';
import propertyPolicySvc from 'soa/kernel/propertyPolicyService';
import { constants as _epEffectivityConstants } from 'js/epEffectivityConstants';
import _ from 'lodash';
import AwPromiseService from 'js/awPromiseService';
import saveInputWriterService from 'js/saveInputWriterService';
import mbmSaveUtils from 'js/mbmSaveUtils';
import appCtxService from 'js/appCtxService';
import { constants as epSaveConstants } from 'js/epSaveConstants';
import soaService from 'soa/kernel/soaService';
import cdm from 'soa/kernel/clientDataModel';
import localeService from 'js/localeService';
import messagingService from 'js/messagingService';
import mbmSaveEffSvc from 'js/mbmSaveEffectivityService';
import epEffectivityContainer from 'js/epEffectivityContainer';
import popupService from 'js/popupService';
import epValidateEffectivityService from 'js/epValidateEffectivityService';

'use strict';

let upRangeMaxVal = 888888;

/**
 * loads effectivity data
 * @param {Array} selectedObjects array of source Object uids
 * @param {String} loadType type of load object
 * @return {Object} effectivityData object
 */

function mbmLoadValidateEffectivityData( selectedObjects, loadType ) {
    let deferred = AwPromiseService.instance.defer();
    const policyId = registerPolicy();
    loadEffectivityData( selectedObjects, loadType, null ).then( ( result ) => {
        propertyPolicySvc.unregister( policyId );
        deferred.resolve( result );
    } );
    return deferred.promise;
}

/**
 * Created end item to object data map
 * @param {Object} instanceToEffectivityData instance to effectivity map
 * @param {Object} endItemList end item list
 * @param {Object} endItemToObjData end item to object map
 */
function setEndItemToObjDataMap( instanceToEffectivityData, endItemList, endItemToObjData, noEffectivitiesSetObj ) {
    if( instanceToEffectivityData ) {
        _.forEach( Object.keys( instanceToEffectivityData ), function( instance ) {
            let effectivityObjs = instanceToEffectivityData[ instance ].effectivites;
            if( effectivityObjs ) {
                _.forEach( effectivityObjs, function( effectivityObjUid ) {
                    let effectivityObj = cdm.getObject( effectivityObjUid );
                    let effectivityUnit = effectivityObj.props.effectivity_units.dbValues;
                    let endItem = effectivityObj.props.end_item.dbValues;
                    if( endItem.length > 0 ) {
                        let effectivityUnitRanges = [];
                        let isUpRange = false;

                        for( let count = 0; count < effectivityUnit.length; count += 2 ) {
                            let endRange;
                            if( !effectivityUnit[ count + 1 ] ) {
                                endRange = upRangeMaxVal;
                                isUpRange = true;
                            } else {
                                endRange = effectivityUnit[ count + 1 ];
                            }
                            effectivityUnitRanges.push( {
                                start: parseInt( effectivityUnit[ count ] ),
                                end: parseInt( endRange )
                            } );
                        }

                        if( !endItemToObjData[ endItem[ 0 ] ] ) {
                            endItemToObjData[ endItem[ 0 ] ] = [];
                        }

                        let foundNdx = _.findIndex( endItemToObjData[ endItem[ 0 ] ],
                            function( obj ) { return obj.object === cdm.getObject( instance ); } );
                        if( foundNdx === -1 ) {
                            endItemToObjData[ endItem[ 0 ] ].push( {
                                object: cdm.getObject( instance ),
                                effectivityUnitRanges: effectivityUnitRanges,
                                effectivityObj: effectivityObj,
                                isUp: isUpRange,
                                InheritedFrom: instanceToEffectivityData[ instance ].InheritedFrom,
                                IsAligned: instanceToEffectivityData[ instance ].IsAligned
                            } );
                        } else {
                            for( let i = 0; i < endItemToObjData[ endItem[ 0 ] ].length; ++i ) {
                                let oldObject = endItemToObjData[ endItem[ 0 ] ][ i ].object;
                                if( oldObject.uid === instance ) {
                                    endItemToObjData[ endItem[ 0 ] ][ i ] = {
                                        object: cdm.getObject( instance ),
                                        effectivityUnitRanges: effectivityUnitRanges,
                                        effectivityObj: effectivityObj,
                                        isUp: isUpRange,
                                        InheritedFrom: instanceToEffectivityData[ instance ].InheritedFrom,
                                        IsAligned: instanceToEffectivityData[ instance ].IsAligned
                                    };
                                    break;
                                }
                            }
                        }
                        endItemList[ endItem[ 0 ] ] = {
                            propInternalValue: endItem[ 0 ],
                            propDisplayValue: effectivityObj.props.end_item.uiValues[ 0 ]
                        };
                    }
                } );
            } else {
                noEffectivitiesSetObj.push( cdm.getObject( instance ) );
            }
        } );
    }
}

/**
 * Function accepts loadTypeInputs and loads effectivity data
 * @param {array} selectedObjects additional params
 * @param {String} loadType the load type
 * @return {Object} effectivityData object
 */
function loadEffectivityData( selectedObjects, loadType ) {
    let endItemList = {};
    let endItemToObjData = {};
    let endItemListForPopup = [];
    let loadTypeInput = [];
    let noEffectivitiesSetObj = [];
    let saveInputWriter = saveInputWriterService.get();

    let objectsToLoad = mbmSaveUtils.getEntryForObjectsToLoadSection( [ loadType ], [ selectedObjects[0].uid ] );
    saveInputWriter.addEntryToSection( 'objectsToLoad', objectsToLoad );

    let ebomPci = appCtxService.getCtx( 'ebomContext' + '.productContextInfo' ).uid;
    let mbomPci = appCtxService.getCtx( 'mbomContext' + '.productContextInfo' ).uid;

    let ebomScope = appCtxService.getCtx( 'ebomContext' ).topElement.uid;
    let mbomScope = appCtxService.getCtx( 'mbomContext' ).topElement.uid;

    let sessionEntry = mbmSaveUtils.getEntryForSessionSection( [ 'false' ], [ ebomPci ], [ mbomPci ], [ ebomScope ], [ mbomScope ] );
    saveInputWriter.addEntryToSection( epSaveConstants.SESSION, sessionEntry );

    let input = saveInputWriterService.getSaveInput( saveInputWriter );
    loadTypeInput = input.saveInput.sections;
    let inputJson = {
        sections: [ loadTypeInput[0], loadTypeInput[1] ]
    };

    return soaService.post( 'Internal-MfgBvrCore-2015-03-DataManagement', 'loadObjectData3', inputJson ).then( function( response ) {
        let mbomInstanceIds = [];
        let ebomInstanceIds = [];
        let mbomInstanceEffectivities = [];
        let ebomInstanceEffectivities = [];
        let mbomInstanceToEffectivityData = {};
        let ebomInstanceToEffectivityData = {};

        _.forEach( response.loadedObjectsMap.EBOM_Instances, function( instance ) {
            ebomInstanceIds.push( instance.uid );
        } );

        _.forEach( response.loadedObjectsMap.MBOM_Instances, function( instance ) {
            mbomInstanceIds.push( instance.uid );
        } );
        _.forEach( mbomInstanceIds, function( instanceId ) {
            let relatedObject = response.relatedObjectsMap[instanceId];
            mbomInstanceToEffectivityData[instanceId] = {
                effectivites: relatedObject.additionalPropertiesMap2.Effectivities,
                InheritedFrom: relatedObject.additionalPropertiesMap2.InheritedFrom,
                IsAligned: relatedObject.additionalPropertiesMap2.IsAligned
            };
        } );

        _.forEach( mbomInstanceIds, function( instanceId ) {
            let relatedObject = response.relatedObjectsMap[instanceId];
            _.forEach( relatedObject.additionalPropertiesMap2.Effectivities, function( Effectivity ) {
                mbomInstanceEffectivities.push( Effectivity );
            } );
        } );

        _.forEach( ebomInstanceIds, function( instanceId ) {
            let relatedObject = response.relatedObjectsMap[instanceId];
            ebomInstanceToEffectivityData[instanceId] = {
                effectivites: relatedObject.additionalPropertiesMap2.Effectivities,
                InheritedFrom: relatedObject.additionalPropertiesMap2.InheritedFrom,
                IsAligned: relatedObject.additionalPropertiesMap2.IsAligned
            };
            _.forEach( relatedObject.additionalPropertiesMap2.Effectivities, function( Effectivity ) {
                ebomInstanceEffectivities.push( Effectivity );
            } );
        } );

        let isAllEbomMbomInstancesAligned = true;
        const ebomMbomInsObjs = Object.assign( {}, mbomInstanceToEffectivityData, ebomInstanceToEffectivityData );
        let ebomMbomIns = Object.keys( mbomInstanceToEffectivityData ).concat( Object.keys( ebomInstanceToEffectivityData ) );
        _.forEach( ebomMbomIns, function( instance ) {
            if( ebomMbomInsObjs[instance].IsAligned[0] === 'False' ) {
                isAllEbomMbomInstancesAligned = false;
            }
        } );
        let effectivityOnEbomMbomInstances = { ebomInstanceIds: ebomInstanceIds, mbomInstanceIds: mbomInstanceIds, isAllEbomMbomInstancesAligned:isAllEbomMbomInstancesAligned };

        let isMbomInstanceEffectivitiesExist = true;
        if ( mbomInstanceEffectivities && mbomInstanceEffectivities.length === 0 && ebomInstanceEffectivities && ebomInstanceEffectivities.length === 0 ) {
            const localTextBundle = localeService.getLoadedText( 'mbmMessages' );
            messagingService.showError( localTextBundle.noUnitEffectivityAppliedErrorMessage );
            return false;
        } else if ( mbomInstanceEffectivities.length > 0 ) {
            setEndItemToObjDataMap( mbomInstanceToEffectivityData, endItemList, endItemToObjData, noEffectivitiesSetObj );
        } else if ( ebomInstanceEffectivities.length > 0 && mbomInstanceIds.length === 0 ) {
            isMbomInstanceEffectivitiesExist = false;
            setEndItemToObjDataMap( ebomInstanceToEffectivityData, endItemList, endItemToObjData );
        }

        updatePerEndItemData( endItemToObjData, noEffectivitiesSetObj );

        appendAlwaysEffectiveObjects( endItemList, noEffectivitiesSetObj, endItemToObjData, mbomInstanceToEffectivityData );

        _.forEach( ebomInstanceEffectivities, function( ebomInstanceEffectivity ) {
            let effectivityObj = cdm.getObject( ebomInstanceEffectivity );
            if ( !endItemList[effectivityObj.props.end_item.dbValues[0]] ) {
                endItemList[effectivityObj.props.end_item.dbValues[0]] = {
                    propInternalValue: effectivityObj.props.end_item.dbValues[0],
                    propDisplayValue: effectivityObj.props.end_item.uiValues[0]
                };
            }
        } );

        //create endItem list for popup
        _.forEach( endItemList, function( endItem ) {
            endItemListForPopup.push( {
                propInternalValue: endItem.propInternalValue,
                propDisplayValue: endItem.propDisplayValue,
                isEditable: false
            } );
        } );

        _.forEach( endItemListForPopup, function( endItem ) {
            if ( !endItemToObjData[endItem.propInternalValue] ) {
                endItemToObjData[endItem.propInternalValue] = [];
                endItemToObjData[endItem.propInternalValue].validObjects = [];
                _.forEach( mbomInstanceIds, function( mbomInstanceId ) {
                    endItemToObjData[endItem.propInternalValue].push( {
                        object: cdm.getObject( mbomInstanceId ),
                        effectivityUnitRanges: [],
                        isUp: false,
                        InheritedFrom: mbomInstanceToEffectivityData[mbomInstanceId].InheritedFrom,
                        IsAligned: mbomInstanceToEffectivityData[mbomInstanceId].IsAligned
                    } );

                    endItemToObjData[endItem.propInternalValue].validObjects.push( cdm.getObject( mbomInstanceId ) );
                } );
            } else {
                _.forEach( mbomInstanceIds, function( mbomInstanceId ) {
                    let isInstanceExist = false;
                    _.forEach( endItemToObjData[endItem.propInternalValue], function( endItemData ) {
                        if ( endItemData.object.uid === mbomInstanceId ) {
                            isInstanceExist = true;
                        }
                    } );
                    if ( !isInstanceExist ) {
                        endItemToObjData[endItem.propInternalValue].push( {
                            object: cdm.getObject( mbomInstanceId ),
                            effectivityUnitRanges: [],
                            isUp: false,
                            InheritedFrom: mbomInstanceToEffectivityData[mbomInstanceId].InheritedFrom,
                            IsAligned: mbomInstanceToEffectivityData[mbomInstanceId].IsAligned
                        } );
                        endItemToObjData[endItem.propInternalValue].validObjects.push( cdm.getObject( mbomInstanceId ) );
                    }
                } );
            }
        } );

        let selectedEndItem = endItemListForPopup[0].propInternalValue;
        return getValidateEffectivityData( endItemListForPopup, selectedEndItem, endItemToObjData, ebomInstanceEffectivities, isMbomInstanceEffectivitiesExist, effectivityOnEbomMbomInstances );
    } );
}

/**
 * Creates effectivity data
 * @param {array} endItemListForPopup end item list
 * @param {String} selectedEndItem selected end item
 * @param {Object} endItemToObjData end item to object map
 * @param {array} ebomInstanceEffectivities ebom instance effectivities
 *  @param {boolean} isMbomInstanceEffectivitiesExist flag for mbom instance effectivities
 * @return {Object} effectivityData object
 */
function getValidateEffectivityData( endItemListForPopup, selectedEndItem, endItemToObjData, ebomInstanceEffectivities, isMbomInstanceEffectivitiesExist, effectivityOnEbomMbomInstances ) {
    if ( endItemListForPopup.length > 0 ) {
        let effectivityObjData = endItemToObjData[selectedEndItem];
        let effectiveUnits = getEffectiveUnits( ebomInstanceEffectivities, selectedEndItem );
        let allInstanceEffectivities = [];
        _.forEach( ebomInstanceEffectivities, function( ebomInstanceEffectivity ) {
            let effectivityObj = cdm.getObject( ebomInstanceEffectivity );
            if ( effectivityObj.props.end_item.dbValues[0] === selectedEndItem ) {
                allInstanceEffectivities.push( ebomInstanceEffectivity );
            }
        } );
        _.forEach( effectivityObjData, function( effectivityObjData ) {
            if ( effectivityObjData.effectivityObj ) {
                allInstanceEffectivities.push( effectivityObjData.effectivityObj.uid );
            }
        } );

        let effectivityUnitRanges = getMinMaxEffectivities( allInstanceEffectivities );
        let validateEffectivityData;

        if ( !isMbomInstanceEffectivitiesExist && ( ebomInstanceEffectivities && ebomInstanceEffectivities.length > 0 ) ) {
            _.forEach( endItemListForPopup, function( endItem ) {
                let tempEndItemToObjData = {
                    maxEndUnit: endItemToObjData[endItem.propInternalValue].maxEndUnit,
                    minStartUnit: endItemToObjData[endItem.propInternalValue].minStartUnit
                };
                endItemToObjData[endItem.propInternalValue] = [ tempEndItemToObjData ];
            } );
            validateEffectivityData = getvalidateEffectivityData( endItemListForPopup, endItemToObjData, ebomInstanceEffectivities, effectivityUnitRanges, effectiveUnits, true, upRangeMaxVal,
                effectivityOnEbomMbomInstances );
        } else {
            validateEffectivityData = getvalidateEffectivityData( endItemListForPopup, endItemToObjData, ebomInstanceEffectivities, effectivityUnitRanges, effectiveUnits, false, upRangeMaxVal,
                effectivityOnEbomMbomInstances );
        }
        return validateEffectivityData;
    }
}

/**
 * returns effectivity data object
 * @param {object} endItemListForPopup endItemListForPopup
 * @param {object} endItemToObjData endItemToObjData
 * @param {object} ebomInstanceEffectivities ebomInstanceEffectivities
 * @param {object} effectivityUnitRanges effectivityUnitRanges
 * @param {object} effectiveUnits effectiveUnits
 * @param {Boolean} showMessage showMessage
 * @param {object} upRangeMaxVal upRangeMaxVal effectivityOnEbomMbomInstances
 * @param {object} effectivityOnEbomMbomInstances effectivityOnEbomMbomInstances
 *
 * @return {Object} effectivityData object
 */
function getvalidateEffectivityData( endItemListForPopup, endItemToObjData, ebomInstanceEffectivities, effectivityUnitRanges, effectiveUnits, showMessage, upRangeMaxVal, effectivityOnEbomMbomInstances ) {
    return {
        endItemList: endItemListForPopup,
        endItemToObjData: endItemToObjData,
        ebomInstanceEffectivities: ebomInstanceEffectivities,
        min: effectivityUnitRanges[ 0 ],
        max: effectivityUnitRanges[ 1 ],
        effectiveUnits: effectiveUnits,
        showMessage: showMessage,
        upRangeMaxVal: upRangeMaxVal,
        effectivityOnEbomMbomInstances: effectivityOnEbomMbomInstances
    };
}

/**
 * Updates effectivity data
 * @param {String} selectedEndItem selected end item
 * @param {object} effectivityData effectivity Data
 * @return {Object} effectivityData object
 */
function updateValidateEffectivityData( selectedEndItem, subPanelContext ) {
    let validateEffectivityData = subPanelContext.effectivityData.validateEffectivityData;
    if( selectedEndItem ) {
        let isEffectivityObjExist = false;
        let effectivtyObjsData = validateEffectivityData.endItemToObjData[selectedEndItem];
        _.forEach( effectivtyObjsData, function( effectivtyObjsData ) {
            if ( effectivtyObjsData.object ) {
                isEffectivityObjExist = true;
            }
        } );

        let endItemList = validateEffectivityData.endItemList;
        let endItemToObjData = validateEffectivityData.endItemToObjData;
        let ebomInstanceEffectivities = validateEffectivityData.ebomInstanceEffectivities;
        let effectivityOnEbomMbomInstances = validateEffectivityData.effectivityOnEbomMbomInstances;
        let validateEffectivityDataToUpdate = getValidateEffectivityData( endItemList, selectedEndItem, endItemToObjData, ebomInstanceEffectivities, isEffectivityObjExist, effectivityOnEbomMbomInstances );
        epValidateEffectivityService.updateEffectivityData( subPanelContext, 'validateEffectivityData', validateEffectivityDataToUpdate );
    }
}

/**
 * Returns min and max range values
 * @param {array} InstanceEffectivities Instance Effectivities
 * @return {array} min and max range
 */
function getMinMaxEffectivities( InstanceEffectivities ) {
    let min;
    let max;
    _.forEach( InstanceEffectivities, function( effectivityObjUid ) {
        let effectivityObj = cdm.getObject( effectivityObjUid );
        let effectivityUnit = effectivityObj.props.effectivity_units.dbValues;
        if ( effectivityUnit && min === undefined && max === undefined ) {
            min = parseInt( effectivityUnit[0] );
            max = parseInt( effectivityUnit[effectivityUnit.length - 1] );
        } else {
            if ( parseInt( effectivityUnit[0] ) < min ) {
                min = parseInt( effectivityUnit[0] );
            }
            if ( parseInt( effectivityUnit[effectivityUnit.length - 1] ) > max ) {
                max = parseInt( effectivityUnit[effectivityUnit.length - 1] );
            }
        }
    } );
    return [ min, max ];
}

/**
 * Returns effective units of ebom
 * @param {array} ebomInstanceEffectivities ebom Instance Effectivities
 * @param {String} selectedEndItem selected end item
 * @return {array} effectiveUnits
 */
function getEffectiveUnits( ebomInstanceEffectivities, selectedEndItem ) {
    let effectiveUnits = [];
    if ( ebomInstanceEffectivities.length > 0 ) {
        _.forEach( ebomInstanceEffectivities, function( effectivityObjUid ) {
            let effectivityObj = cdm.getObject( effectivityObjUid );
            if ( effectivityObj.props.end_item.dbValues[0] === selectedEndItem ) {
                let effectivityUnit = effectivityObj.props.effectivity_units.dbValues;
                let effectivityUnitRanges = [];
                for ( let count = 0; count < effectivityUnit.length; count += 2 ) {
                    let endRange = effectivityUnit[count + 1];
                    effectivityUnitRanges.push( {
                        start: parseInt( effectivityUnit[count] ),
                        end: parseInt( endRange )
                    } );
                }
                _.forEach( effectivityUnitRanges, function( effectivityUnitRange ) {
                    let start = effectivityUnitRange.start;
                    let end = effectivityUnitRange.end;
                    for ( let i = start; i <= end; i++ ) {
                        if ( effectiveUnits.indexOf( i ) <= -1 ) {
                            effectiveUnits.push( i );
                        }
                    }
                } );
            }
        } );

        effectiveUnits.sort( ( a, b ) => a - b );
    }
    return effectiveUnits;
}

/**
 * Updates end item data
 * @param {Object} endItemToObjData end item to object map
 */
function updatePerEndItemData( endItemToObjData, noEffectivitiesSetObj ) {
    _.forEach( endItemToObjData, function( endItemData, currentEndItem ) {
        let units = [];
        let validObjects = [];
        _.forEach( endItemData, function( obj ) {
            _.forEach( obj.effectivityUnitRanges, function( range ) {
                units.push( _.parseInt( range.start ) );
                if ( range.end !== upRangeMaxVal ) {
                    units.push( _.parseInt( range.end ) );
                }
            } );
            validObjects.push( obj.object );
        } );
        const minStartUnit = Math.min.apply( Math, units );
        const maxEndUnit = Math.max.apply( Math, units );
        endItemToObjData[currentEndItem].minStartUnit = minStartUnit;
        endItemToObjData[currentEndItem].maxEndUnit = maxEndUnit;
        endItemToObjData[ currentEndItem ].validObjects = validObjects.concat( noEffectivitiesSetObj );
    } );
}

function appendAlwaysEffectiveObjects( endItemList, noEffectivitiesSetObj, endItemToObjData, instanceToEffectivityData ) {
    _.forEach( endItemList, function( endItem ) {
        _.forEach( noEffectivitiesSetObj, function( object ) {
            endItemToObjData[ endItem.propInternalValue ].push( {
                object: object,
                effectivityUnitRanges: [],
                isUp: false,
                InheritedFrom: instanceToEffectivityData[ object.uid ].InheritedFrom,
                IsAligned: instanceToEffectivityData[ object.uid ].IsAligned
            } );
        } );
    } );
}

/**
 * Register the policy
 *
 * @return {Object}  null
 */
function registerPolicy() {
    let effectivityLoadPolicy = {
        types: [ {
            name: _epEffectivityConstants.EFFECTIVITY,
            properties: [ {
                name: _epEffectivityConstants.EFFECTIVITY_UNITS
            },
            {
                name: _epEffectivityConstants.END_ITEM
            }
            ]
        },
        {
            name: 'Awb0ConditionalElement',
            properties: [

                { name: 'awb0ArchetypeRevName' }

            ]
        }
        ]
    };
    return propertyPolicySvc.register( effectivityLoadPolicy );
}

/**
 * close popup if no effectivity is edited. shows another confirmation message if effectivity is edited
 * @param {Object} validateEffectivityPopupRef popup ref
 */
function closePopup( validateEffectivityPopupRef, effectivityData ) {
    if( epEffectivityContainer.hasEffectivityUpdated() ) {
        mbmSaveEffSvc.handleUnsavedEffectivity( effectivityData ).then(
            function() {
                appCtxService.updatePartialCtx( _epEffectivityConstants.EP_EFFECTIVITY_IS_DIRTY, false );
                closePopupAfterConfirmation();
            } );
    } else {
        closePopupAfterConfirmation( validateEffectivityPopupRef );
    }
}

/**
 * close popup
 * @param {Object} validateEffectivityPopupRef popup ref
 */
function closePopupAfterConfirmation( validateEffectivityPopupRef ) {
    popupService.hide( validateEffectivityPopupRef );
}

/**
 * handles end item selection change
 * @param {Object} endItem newly selected end item
 * @param {Object} effectivityData effectivityData
 */
function endItemSelectionChange( effectivityData ) {
    const event = {
        eventType: 'ep.endItemSelectionChangeEvent'
    };
    mbmSaveEffSvc.handleUnsavedEffectivity( effectivityData, event ).then(
        function() {
            appCtxService.updatePartialCtx( _epEffectivityConstants.EP_EFFECTIVITY_IS_DIRTY, false );
        } );
}

/**
 * handles end item selection change
 * @param {Object} endItem newly selected end item
 * @param {Object} effectivityData effectivityData
 */
function updateValidateEffectivityDataAfterSave( selectedObjects, loadType, subPanelContext ) {
    mbmLoadValidateEffectivityData( selectedObjects, loadType ).then( ( result ) => {
        epValidateEffectivityService.updateEffectivityData( subPanelContext, 'validateEffectivityData', result );
    } );
}
export default {
    mbmLoadValidateEffectivityData,
    getValidateEffectivityData,
    updateValidateEffectivityData,
    closePopup,
    endItemSelectionChange,
    updateValidateEffectivityDataAfterSave
};
