// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import EpConfigurationChipArea from 'viewmodel/EpConfigurationChipAreaViewModel';
import AwSublocation from 'viewmodel/AwSublocationViewModel';
import MfeLoadingMessage from 'viewmodel/MfeLoadingMessageViewModel';
import EpBalancingDashboard from 'viewmodel/EpBalancingDashboardViewModel';
import MfeContentContainer from 'viewmodel/MfeContentContainerViewModel';
import MfeContentPanel from 'viewmodel/MfeContentPanelViewModel';
import EpBalancingShareProcessResource from 'viewmodel/EpBalancingShareProcessResourceViewModel';
import EpBalancingManager from 'viewmodel/EpBalancingManagerViewModel';
    import { ExistWhen,AwClass } from 'js/hocCollection';
const EpConfigurationChipAreaExistWhen = ExistWhen(EpConfigurationChipArea);
const MfeLoadingMessageExistWhen = ExistWhen(MfeLoadingMessage);
const DivExistWhen = ExistWhen('div');
const DivExistWhenAwClass = AwClass(ExistWhen('div'));
const EpBalancingShareProcessResourceExistWhen = ExistWhen(EpBalancingShareProcessResource);
const MfeContentContainerAwClass = AwClass(MfeContentContainer);
const MfeContentContainerExistWhen = ExistWhen(MfeContentContainer);
const MfeContentContainerExistWhenAwClass = AwClass(ExistWhen(MfeContentContainer));
const EpBalancingManagerExistWhen = ExistWhen(EpBalancingManager);
EpConfigurationChipAreaExistWhen.displayName = 'EpConfigurationChipAreaExistWhen';
MfeLoadingMessageExistWhen.displayName = 'MfeLoadingMessageExistWhen';
DivExistWhen.displayName = 'DivExistWhen';
DivExistWhenAwClass.displayName = 'DivExistWhenAwClass';
EpBalancingShareProcessResourceExistWhen.displayName = 'EpBalancingShareProcessResourceExistWhen';
MfeContentContainerAwClass.displayName = 'MfeContentContainerAwClass';
MfeContentContainerExistWhen.displayName = 'MfeContentContainerExistWhen';
MfeContentContainerExistWhenAwClass.displayName = 'MfeContentContainerExistWhenAwClass';
EpBalancingManagerExistWhen.displayName = 'EpBalancingManagerExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwBaseSublocationService', () => import('js/AwBaseSublocationService'));
registerDynImportEntry('js/mfeViewModelUtils', () => import('js/mfeViewModelUtils'));
registerDynImportEntry('js/epInitializationService', () => import('js/epInitializationService'));
registerDynImportEntry('js/epBalancingLabelsService', () => import('js/epBalancingLabelsService'));
registerDynImportEntry('js/epObjectPropertyCacheService', () => import('js/epObjectPropertyCacheService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "ports": {
        "EpBalancingSubPage_updateIndicationContext": {
            "syncObject": {}
        }
    },
    "data": {
        "occContext": {
            "initialValues": {
                "selectedModelObjects": [],
                "viewKey": "",
                "productContextInfo": null,
                "elementToPCIMapCount": null,
                "supportedFeatures": null,
                "skipReloadOnConfigParamChange": true,
                "assignToAnotherPRView": {}
            },
            "meta": {}
        },
        "provider": {
            "label": "{{i18n.BalancingTitle}}",
            "priority": 2,
            "name": "lineBalancing",
            "clientScopeURI": "lineBalancing",
            "nameToken": "lineBalancing"
        },
        "overviewContext": {
            "tabs": [
                {
                    "name": "{{i18n.balancingOverviewTitle}}",
                    "tabKey": "EpBalancingOverviewStationTilesList"
                }
            ]
        },
        "stationTilesContext": {
            "tabs": [
                {
                    "name": "{{i18n.stationTilesTitle}}",
                    "tabKey": "EpBalancingStationTilesList"
                }
            ]
        },
        "operationsContext": {
            "tabs": [
                {
                    "name": "{{i18n.operationsTableTitle}}",
                    "tabKey": "EpBalancingOperationsTable"
                }
            ],
            "sharedCommandAnchor": "commandBalancingTabAnchor"
        },
        "sequenceContext": {
            "tabs": [
                {
                    "name": "{{i18n.operationsSequenceTitle}}",
                    "tabKey": "EpBalancingOperationsSequence"
                }
            ],
            "additionalHeaderComponent": "EpBalancingStationCycleTime"
        },
        "productBOPContext": {
            "tabs": [
                {
                    "name": "{{i18n.balancingProductBOPTitle}}",
                    "tabKey": "EpBalancingProductBOP"
                }
            ]
        },
        "awSubLocationPref": {
            "hideRightWall": true
        },
        "configurationChipsData": {
            "initialValues": {},
            "meta": {}
        },
        "sharedSelectionData": {
            "initialValues": {
                "isAllocationIndicationOn": false
            },
            "meta": {}
        },
        "missingSourceContext": {
            "tabs": [
                {
                    "name": "{{i18n.missingInSource}}",
                    "tabKey": "EpMissingInSource"
                }
            ]
        },
        "balancingData": {
            "initialValues": {
                "loadedData": {
                    "loading": true
                },
                "selectionData": {}
            },
            "meta": {}
        }
    },
    "actions": {
        "handleCompleteEvent": {
            "actionType": "JSFunction",
            "method": "handleCompleteEvent",
            "inputData": {
                "provider": "{{parameters.eventData}}"
            },
            "deps": "js/AwBaseSublocationService"
        },
        "hideOperations": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "value": true
            },
            "outputData": {
                "hideOperations": ""
            },
            "deps": "js/mfeViewModelUtils"
        },
        "initializeEPPage": {
            "actionType": "JSFunctionAsync",
            "method": "initializeEPPage",
            "inputData": {
                "provider": "{{data.provider}}"
            },
            "outputData": {
                "stateContext": "stateContext",
                "propertyPolicyId": "propertyPolicyId"
            },
            "deps": "js/epInitializationService"
        },
        "resetOperationsHeaderAndSetSelection": {
            "actionType": "JSFunction",
            "method": "resetOperationsHeaderAndSetSelection",
            "inputData": {
                "operationsTableTitle": "{{i18n.operationsTableTitle}}",
                "operationsTableTitleWithStation": "{{i18n.operationsTableTitleWithStation}}",
                "operationsTableTitleWithStationAndPR": "{{i18n.operationsTableTitleWithStationAndPR}}",
                "station": "{{fields.balancingData.selectionData.station}}",
                "processResource": "{{fields.balancingData.selectionData.processResource}}",
                "unassigned": "{{fields.balancingData.selectionData.unassigned}}",
                "contentPanelData": "{{data.operationsContext}}",
                "tabKey": "EpBalancingOperationsTable"
            },
            "outputData": {
                "operationsContext": ""
            },
            "deps": "js/epBalancingLabelsService"
        },
        "initializeConfigurationChips": {
            "actionType": "JSFunction",
            "method": "mergeValueInAtomicData",
            "inputData": {
                "targetContext": "{{fields.configurationChipsData}}",
                "updatedValues": {
                    "ebomStructure": "{{ctx.epPageContext.ebomStructure}}",
                    "ebomPCI": "{{ctx.epTaskPageContext.ebomPCI}}",
                    "ebomStructureConfigFlags": "{{ctx.epTaskPageContext.ebomStructureConfigFlags}}",
                    "mbomStructure": "{{ctx.epPageContext.mbomStructure}}",
                    "mbomPCI": "{{ctx.epTaskPageContext.mbomPCI}}",
                    "mbomStructureConfigFlags": "{{ctx.epTaskPageContext.mbomStructureConfigFlags}}",
                    "processStructure": "{{ctx.epPageContext.processStructure}}",
                    "processPCI": "{{ctx.epTaskPageContext.processPCI}}",
                    "processStructureConfigFlags": "{{ctx.epTaskPageContext.processStructureConfigFlags}}",
                    "rootPlant": "{{ctx.epPageContext.rootPlant}}",
                    "plantPCI": "{{ctx.epTaskPageContext.plantPCI}}",
                    "rootPlantConfigFlags": "{{ctx.epTaskPageContext.rootPlantConfigFlags}}",
                    "functionalPlan": "{{ctx.epTaskPageContext.functionalPlan}}",
                    "functionalPlanPCI": "{{ctx.epTaskPageContext.functionalPlanPCI}}",
                    "functionalPlanConfigFlags": "{{ctx.epTaskPageContext.functionalPlanConfigFlags}}"
                }
            },
            "deps": "js/mfeViewModelUtils"
        },
        "updateSharedSelectionPort": {
            "actionType": "Sync",
            "inputData": {
                "port": "EpBalancingSubPage_updateIndicationContext",
                "syncObject": {
                    "sharedSelectionData": "{{fields.sharedSelectionData}}"
                }
            }
        },
        "initializeEPPageAndUpdateSharedSelectionPort": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeEPPage"
                },
                {
                    "action": "updateSharedSelectionPort"
                },
                {
                    "action": "initializeConfigurationChips"
                }
            ]
        },
        "getMissingInSrcData": {
            "actionType": "JSFunction",
            "method": "getProperty",
            "inputData": {
                "name": "{{ctx.epPageContext.loadedObject.uid}}",
                "propertyName": "accountabilityResponse"
            },
            "outputData": {
                "missingInSrc": "result.missingInSrc",
                "isMissingInSource": "result.missingInSrc.length"
            },
            "deps": "js/epObjectPropertyCacheService"
        }
    },
    "onEvent": [
        {
            "eventId": "ep.pageContext.changed",
            "action": "initializeConfigurationChips"
        },
        {
            "eventId": "epObjectPropertyCache.accountabilityResponseUpdated",
            "action": "getMissingInSrcData"
        },
        {
            "eventId": "ep.preSaveConfigurationEvent",
            "action": "hideOperations"
        },
        {
            "eventId": "complete",
            "action": "handleCompleteEvent",
            "inputArgs": {
                "eventData": "{{eventData}}"
            }
        }
    ],
    "lifecycleHooks": {
        "onMount": "initializeEPPageAndUpdateSharedSelectionPort",
        "onUpdate": [
            {
                "action": "resetOperationsHeaderAndSetSelection",
                "observers": [
                    "viewModel.atomicData.balancingData.selectionData.station",
                    "viewModel.atomicData.balancingData.selectionData.processResource"
                ]
            }
        ]
    },
    "conditions": {
        "epShowMissingTab": {
            "expression": "data.missingInSrc.length > 0"
        }
    },
    "i18n": {
        "BalancingTitle": [
            "BalancingMessages"
        ],
        "balancingOverviewTitle": [
            "BalancingMessages"
        ],
        "stationTilesTitle": [
            "BalancingMessages"
        ],
        "operationsTableTitle": [
            "BalancingMessages"
        ],
        "operationsTableTitleWithStation": [
            "BalancingMessages"
        ],
        "operationsTableTitleWithStationAndPR": [
            "BalancingMessages"
        ],
        "operationsSequenceTitle": [
            "BalancingMessages"
        ],
        "balancingProductBOPTitle": [
            "BalancingMessages"
        ],
        "missingInSource": [
            "assignmentIndicationMessages"
        ]
    },
    "_viewModelId": "EpBalancingSubPage",
    "_uniqueViewModelId": "EpBalancingSubPage",
    "ctx": {
        "epPageContext": {
            "type": "object"
        },
        "epTaskPageContext": {
            "type": "object"
        },
        "ep": {
            "type": "object"
        },
        "maximizedVertically": {
            "type": "object"
        }
    }
};

    /*
    <ep-configuration-chip-area exist-when="ctx.epTaskPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" configuration-chips-data="data.configurationChipsData"></ep-configuration-chip-area>
<aw-sublocation sub-location-preference="data.awSubLocationPref" sub-panel-context="{provider : data.provider, occContext:data.occContext}">
    <mfe-loading-message exist-when="!ctx.epPageContext.loadedObject.uid || !ctx.ep.scopeObject.uid">
    </mfe-loading-message>
    <div class="sw-column w-12" exist-when="ctx.epPageContext.loadedObject.uid && ctx.ep.scopeObject.uid">
        <ep-balancing-dashboard balancing-data="data.balancingData"></ep-balancing-dashboard>
        <mfe-content-container container-direction="HORIZONTAL" container-name="BalancingPageContents" class="flex-auto">
            <mfe-content-container container-direction="VERTICAL" container-name="BalancingPageOverview" class="aw-epBalancing-overviewContainer w-1">
                <mfe-content-panel sub-panel-context="{...data.overviewContext, balancingData: data.balancingData}"></mfe-content-panel>
            </mfe-content-container>
            <div aw-class="[ctx.ep.balancingShowProductBOP ? 'w-9' : 'w-11']" class="sw-column flex-auto" exist-when="!data.hideOperations">
                <ep-balancing-share-process-resource exist-when="data.balancingData.selectionData.sharePr.isShareToAnotherPRVisible" class="w-12 h-1 aw-epBalancing-shareProcessResource" sub-panel-context="{ occContext:data.occContext, balancingData: data.balancingData }"></ep-balancing-share-process-resource>
                <mfe-content-container container-direction="HORIZONTAL" container-name="BalancingMidSubPage" class="flex-auto">
                    <mfe-content-container container-direction="VERTICAL" container-name="BalancingPageTiles" class="w-3">
                        <mfe-content-panel sub-panel-context="{...data.stationTilesContext, balancingData: data.balancingData}"></mfe-content-panel>
                    </mfe-content-container>
                    <mfe-content-container container-direction="VERTICAL" container-name="BalancingPageSingleStation" class="flex-auto" aw-class="[ctx.ep.balancingShowProductBOP ? 'w-5' : 'w-7']">
                        <mfe-content-container container-direction="HORIZONTAL" container-name="BalancingPageOperations" aw-class="[ctx.maximizedVertically === undefined ? 'h-7' : 'h-12']">
                            <mfe-content-panel sub-panel-context="{ ...data.operationsContext, occContext:data.occContext, sharedSelectionData:data.sharedSelectionData, balancingData: data.balancingData}" occ-context="data.occContext"></mfe-content-panel>
                        </mfe-content-container>
                        <mfe-content-container container-direction="HORIZONTAL" container-name="BalancingPageSequence" aw-class="[ctx.maximizedVertically === undefined ? 'h-5' : 'aw-epBalancing-maximizedVertically']">
                            <mfe-content-panel sub-panel-context="{...data.sequenceContext, balancingData: data.balancingData}"></mfe-content-panel>
                        </mfe-content-container>
                    </mfe-content-container>
                </mfe-content-container>
            </div>
            <mfe-content-container container-direction="VERTICAL" container-name="BalancingPageProductBOP" class="w-2" exist-when="ctx.ep.balancingShowProductBOP">
                <mfe-content-container container-direction="HORIZONTAL" container-name="BalancingPageProductBOP" aw-class="[ctx.ep.assignmentIndicationMode && conditions.epShowMissingTab ? 'h-7' : 'h-12']">
                    <mfe-content-panel sub-panel-context="{...data.productBOPContext, sharedSelectionData:data.sharedSelectionData, balancingData: data.balancingData}"></mfe-content-panel>
                </mfe-content-container>
                <mfe-content-container container-direction="HORIZONTAL" container-name="BalancingMissingInSource" aw-class="[ctx.maximizedVertically === undefined ? 'h-5' : 'aw-epBalancing-maximizedVertically']" exist-when="ctx.ep.assignmentIndicationMode && conditions.epShowMissingTab">
                    <mfe-content-panel class="aw-ep-missingInSourcePanel" sub-panel-context="data.missingSourceContext"></mfe-content-panel>
                </mfe-content-container>
            </mfe-content-container>
        </mfe-content-container>
        <ep-balancing-manager exist-when="ctx.ep.scopeObject" balancing-data="data.balancingData"></ep-balancing-manager>
    </div>

</aw-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <EpConfigurationChipAreaExistWhen configurationChipsData={AwParseService.instance( "fields.configurationChipsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "ctx.epTaskPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </EpConfigurationChipAreaExistWhen>
    <AwSublocation subLocationPreference={AwParseService.instance( "data.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{provider : data.provider, occContext:fields.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <MfeLoadingMessageExistWhen existWhen={AwParseService.instance( "!ctx.epPageContext.loadedObject.uid || !ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </MfeLoadingMessageExistWhen>
        <DivExistWhen className="sw-column w-12" existWhen={AwParseService.instance( "ctx.epPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <EpBalancingDashboard balancingData={AwParseService.instance( "fields.balancingData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </EpBalancingDashboard>
            <MfeContentContainer containerDirection="HORIZONTAL" containerName="BalancingPageContents" className="flex-auto">
                <MfeContentContainer containerDirection="VERTICAL" containerName="BalancingPageOverview" className="aw-epBalancing-overviewContainer w-1">
                    <MfeContentPanel subPanelContext={AwParseService.instance( "{...data.overviewContext, balancingData: fields.balancingData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </MfeContentPanel>
                </MfeContentContainer>
                <DivExistWhenAwClass className="sw-column flex-auto" existWhen={AwParseService.instance( "!data.hideOperations" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} awClass={AwParseService.instance( "[ctx.ep.balancingShowProductBOP ? 'w-9' : 'w-11']" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <EpBalancingShareProcessResourceExistWhen className="w-12 h-1 aw-epBalancing-shareProcessResource" subPanelContext={AwParseService.instance( "{ occContext:fields.occContext, balancingData: fields.balancingData }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "fields.balancingData.selectionData.sharePr.isShareToAnotherPRVisible" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </EpBalancingShareProcessResourceExistWhen>
                    <MfeContentContainer containerDirection="HORIZONTAL" containerName="BalancingMidSubPage" className="flex-auto">
                        <MfeContentContainer containerDirection="VERTICAL" containerName="BalancingPageTiles" className="w-3">
                            <MfeContentPanel subPanelContext={AwParseService.instance( "{...data.stationTilesContext, balancingData: fields.balancingData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            </MfeContentPanel>
                        </MfeContentContainer>
                        <MfeContentContainerAwClass containerDirection="VERTICAL" containerName="BalancingPageSingleStation" className="flex-auto" awClass={AwParseService.instance( "[ctx.ep.balancingShowProductBOP ? 'w-5' : 'w-7']" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            <MfeContentContainerAwClass containerDirection="HORIZONTAL" containerName="BalancingPageOperations" awClass={AwParseService.instance( "[ctx.maximizedVertically === undefined ? 'h-7' : 'h-12']" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                                <MfeContentPanel subPanelContext={AwParseService.instance( "{ ...data.operationsContext, occContext:fields.occContext, sharedSelectionData:fields.sharedSelectionData, balancingData: fields.balancingData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} occContext={AwParseService.instance( "fields.occContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                                </MfeContentPanel>
                            </MfeContentContainerAwClass>
                            <MfeContentContainerAwClass containerDirection="HORIZONTAL" containerName="BalancingPageSequence" awClass={AwParseService.instance( "[ctx.maximizedVertically === undefined ? 'h-5' : 'aw-epBalancing-maximizedVertically']" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                                <MfeContentPanel subPanelContext={AwParseService.instance( "{...data.sequenceContext, balancingData: fields.balancingData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                                </MfeContentPanel>
                            </MfeContentContainerAwClass>
                        </MfeContentContainerAwClass>
                    </MfeContentContainer>
                </DivExistWhenAwClass>
                <MfeContentContainerExistWhen containerDirection="VERTICAL" containerName="BalancingPageProductBOP" className="w-2" existWhen={AwParseService.instance( "ctx.ep.balancingShowProductBOP" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <MfeContentContainerAwClass containerDirection="HORIZONTAL" containerName="BalancingPageProductBOP" awClass={AwParseService.instance( "[ctx.ep.assignmentIndicationMode && conditions.epShowMissingTab ? 'h-7' : 'h-12']" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        <MfeContentPanel subPanelContext={AwParseService.instance( "{...data.productBOPContext, sharedSelectionData:fields.sharedSelectionData, balancingData: fields.balancingData}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        </MfeContentPanel>
                    </MfeContentContainerAwClass>
                    <MfeContentContainerExistWhenAwClass containerDirection="HORIZONTAL" containerName="BalancingMissingInSource" existWhen={AwParseService.instance( "ctx.ep.assignmentIndicationMode && conditions.epShowMissingTab" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} awClass={AwParseService.instance( "[ctx.maximizedVertically === undefined ? 'h-5' : 'aw-epBalancing-maximizedVertically']" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        <MfeContentPanel className="aw-ep-missingInSourcePanel" subPanelContext={AwParseService.instance( "data.missingSourceContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        </MfeContentPanel>
                    </MfeContentContainerExistWhenAwClass>
                </MfeContentContainerExistWhen>
            </MfeContentContainer>
            <EpBalancingManagerExistWhen balancingData={AwParseService.instance( "fields.balancingData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "ctx.ep.scopeObject" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </EpBalancingManagerExistWhen>
        </DivExistWhen>
    </AwSublocation>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;