// Copyright 2020 Semens Product Lifecycle Management Software Inc.

/*global*/

/**
 * @module js/tcmaCheckInOutStudyService
 *
 * This module is responsible for creation of study and operations related to create study
 *
 *
 */

import soaService from 'soa/kernel/soaService';
import localeService from 'js/localeService';
import messagingService from 'js/messagingService';
import mfgNotificationUtils from 'js/mfgNotificationUtils';
import awPromiseService from 'js/awPromiseService';
let exports = {};


/**
   * Check out the input Study
   *
   * @param {Object} studyUID -Uid of study to be checked out
   * @param {Object} study -Study to be checked out
   *  @param {Object} includeSubHierarchy -SubHierarchy to be included
   *  @param {Object} includeAssignedWorkAreasAndResources -Assigned Work area and resources to be included
   */
function tcmaCheckOutStudy( studyUID, study, includeSubHierarchy, includeAssignedWorkAreasAndResources ) {
    const resource = localeService.getLoadedText( 'tcmaStudyManagerMessages' );
    let failureMsg = resource.tcmaCheckOutStudyError.format( study[0].props.object_string.dbValues[0] );

    if( studyUID === undefined || study === undefined ) {
        return messagingService.showInfo( failureMsg );
    }
    let inputData =  {
        rootLines : [ studyUID ],
        params:  {
            coProcess : true,
            isRecursive : false,
            coPlant : false,
            coTools : false,
            coAssembly : false,
            changeId : '',
            reason : ''
        }
    };
    if( includeSubHierarchy.checked === true &&  includeAssignedWorkAreasAndResources.checked === true ) {
        inputData.params.isRecursive = true;
        inputData.params.coPlant = true;
        inputData.params.coTools = true;
    } else if ( includeSubHierarchy.checked === true &&  ( includeAssignedWorkAreasAndResources.checked === false || includeAssignedWorkAreasAndResources.checked === undefined ) ) {
        inputData.params.isRecursive = true;
        inputData.params.coPlant = false;
        inputData.params.coTools = false;
    }
    return soaService.post( 'Internal-Manufacturing-2008-12-Core', 'checkoutForProcessSimulate', inputData )
        .then( function( response ) {
            if ( response.modelObjects ) {
                let successMsg = '';
                if( study ) {
                    successMsg = resource.tcmaCheckOutStudyInfo.format( study[0].props.object_string.dbValues[0] );
                }
                messagingService.showInfo( successMsg );
            }
            return response;
        }, ( response ) => response.cause );
}

/**
 * Check in the input Study
 *
 * @param {Object} studyUid -Uid of study to be checked in
 * @param {Object} study -Study to be checked in
 */
function tcmaCheckInStudy( studyUid ) {
    if( studyUid === undefined ) {
        return;
    }
    let inputData =  {
        rootLines : [ studyUid ],
        params:  {
            ciProcess: true,
            isRecursive: true
        }
    };
    return soaService.post( 'Internal-Manufacturing-2008-12-Core', 'checkinForProcessSimulate', inputData )
        .then( ( studyUid ) => {
            return closeStudyContext( studyUid );
        } );
}

/**
 * Check populate Checkin Message
 *
 * @param {Object} studyUID -Uid of study to be checked in
 */
function tcmaPopulateCheckInConfirmationMsg( studyUid, study ) {
    const resource = localeService.getLoadedText( 'tcmaStudyManagerMessages' );
    // show the confirmation message
    const checkConfirmationMessage = getLocalizedMessage( resource, study );
    return mfgNotificationUtils.displayConfirmationMessage( checkConfirmationMessage, resource.ok, resource.cancelButtonLabel ).then(
        () => {
            //on checkIn
            return tcmaCheckInStudy( studyUid );
        },
        function() {
            //on discard
        } );
}

/**
  * Get the message for given key from given resource file, replace the parameter and return the localized string
  *
  * @param {String} localTextBundle - The message bundles localized files
  * @param {String} studyToCheckIn - The study to check-in
  * @returns {String} localizedValue - The localized message string
  */
function getLocalizedMessage( localTextBundle, studyToCheckIn ) {
    return localTextBundle.checkInConfirmation.format( studyToCheckIn[ 0 ].props.object_string.dbValues[ 0 ] );
}

/**
   * Open Study Object
   * @param {object} studyUID - study objects' UID
   * @returns {Promise} -
   */
async function openStudyContext( studyUID ) {
    const input = {
        input: [ {
            openViews: false,
            openAssociatedContexts: true,
            object: studyUID,
            contextSettings: {
                boolArrayProps: {},
                boolProps: {
                    ShowSuppressedOccs: true,
                    ShowUnconfiguredAssignedOccurrences: true,
                    ShowUnconfiguredVariants: true,
                    ShowUnconfiguredChanges: true,
                    ShowUnconfiguredOccurrencesEffectivity: true,
                    IsProductConfigurator: false
                },
                compoundCreateInput: {},
                dateArrayProps: {},
                dateProps: {},
                doubleArrayProps: {},
                doubleProps: {},
                floatArrayProps: {},
                floatProps: {},
                intArrayProps: {},
                intProps: {},
                stringArrayProps: {},
                stringProps: {},
                tagArrayProps: {},
                tagProps: {},
                type: ''
            }
        } ]
    };
    const ccObject = await soaService.post( 'Manufacturing-2011-06-DataManagement', 'openContexts', input );
    if ( ccObject.output && ccObject.output.length > 0 ) {
        let tempScope = [];

        ccObject.output[0].contexts.forEach( object => {
            if ( object.context.type === 'Mfg0BvrShdStudy' || object.context.type === 'Mfg0BvrSimStudy' ) {
                tempScope[0] = {
                    uid: object.context.uid,
                    type: object.context.type
                };
            }
        } );
        return tempScope[0].uid;
    }
    return null;
}

/**
   * Close Study context
   * @param {object} studyUID - study objects' UID
   * @returns {Promise} -
   */
async function closeStudyContext( studyUID ) {
    const awPromise = awPromiseService.instance;

    const input = {
        input: [ {
            object: studyUID
        }
        ] };
    const closeContextObject = await soaService.post( 'Manufacturing-2011-06-DataManagement', 'closeContexts', input );

    if( closeContextObject.ServiceData && closeContextObject.ServiceData.partialErrors ) {
        const err = messagingService.getSOAErrorMessage( closeContextObject.ServiceData );
        messagingService.showError( err );
        return awPromise.reject( err );
    }
    return awPromise.resolve();
}

/**
   * Cancel check out the input Study
   *
   * @param {Object} studyUID -Uid of study to be cancel checked out
   */
function tcmaCancelCheckOutStudy( studyUID ) {
    if( studyUID === undefined ) {
        return;
    }

    let additionalInfo = {
        strToBooleanVectorMap: {
            isConsiderSubHierarchy : [ true ]
        }
    };

    let inputData =  {
        rootObjects : [ studyUID ],
        additionalInfo: additionalInfo
    };

    return soaService.post( 'Manufacturing-2020-04-Core', 'cancelManufacturingCheckout', inputData )
        .then( function( response ) {
            if ( response.modelObjects ) {
                const resource = localeService.getLoadedText( 'tcmaStudyManagerMessages' );
                let successMsg = resource.tcmaCancelCheckoutInfo;
                return messagingService.showInfo( successMsg );
            }
            return awPromiseService.instance.reject();
        } );
}

function tcmaUpdatePopupValues( includeSubHierarchy ) {
    if( includeSubHierarchy.checked === true ) {
        return true;
    } return false;
}

export default exports = {
    openStudyContext,
    tcmaCheckOutStudy,
    tcmaCancelCheckOutStudy,
    closeStudyContext,
    tcmaPopulateCheckInConfirmationMsg,
    tcmaUpdatePopupValues
};
