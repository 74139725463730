// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * @module js/G4B_VariantInfoConfigurationService
 */
//TIP:earlier app.factory name was customVariantInfoConfigurationService changed to G4B_VariantInfoConfigurationService
import Pca0Constants from 'js/Pca0Constants';
import app from "app";
import $ from "jquery";
import _ from "lodash";
import eventBus from "js/eventBus";
import appCtxSvc from "js/appCtxService";
import uwPropertySvc from "js/uwPropertyService";
import cdm from "soa/kernel/clientDataModel";
import omStateHandler from "js/occurrenceManagementStateHandler";
import viewModelObjectService from "js/viewModelObjectService";
import endItemUnitEffectivityService from "js/endItemUnitEffectivityConfigurationService";
import soaSvc from "soa/kernel/soaService";
import localeSvc from "js/localeService";
import dateTimeService from "js/dateTimeService";
import _uwPropertySvc from 'js/uwPropertyService';
import iconSvc from 'js/iconService';
import policySvc from "soa/kernel/propertyPolicyService";
import g4bConfiguratorSvc from "js/G4B_configuratorService";
import AwPromiseService from "js/awPromiseService";

let exports = {};
let productContextInfo = null;
// eslint-disable-next-line no-unused-vars
let defaultVariantRule,
  customVariantRule,
  isSeparatorAdded = null;
let dataValue = null;

//let NULL_ID = 'AAAAAAAAAAAAAA';

let populateProductContextInfo = function () {
  let context = appCtxSvc.getCtx("aceActiveContext.context");
  if (context) {
    if (productContextInfo !== context.productContextInfo) {
      // A change in ProductContext doesn't imply a change in product.
      // Check if the product has changed and fire an event.
      // The custom variant panel needs to react to this event.
      if (
        productContextInfo !== null &&
        productContextInfo.props.awb0Product !== null &&
        productContextInfo.props.awb0Product.dbValues[0] !==
          context.productContextInfo.props.awb0Product.dbValues[0]
      ) {
        //  eventBus.publish("ConfiguratorContextContainerChangedEvent");
      }

      productContextInfo = context.productContextInfo;
      appCtxSvc.unRegisterCtx("svrOwningItemToRender");
    }
  } else {
    productContextInfo = null;
  }
};

let populateOpenedProduct = function (data) {
  let context = appCtxSvc.getCtx("aceActiveContext.context");
  if (context) {
    let productContextInfo = context.productContextInfo;
    if (productContextInfo) {
      data.openProduct = productContextInfo.props.awb0Product;
    }
  }
};

let populateSupportedFeaturesInfo = function (data) {
  if (data && data.isVariantRuleFeatureSupported) {
    data.isVariantRuleFeatureSupported.dbValue =
      omStateHandler.isFeatureSupported("Awb0VariantFeature");
  }
  if (data && data.isHideSVROwningItemFeatureSupported) {
    data.isHideSVROwningItemFeatureSupported.dbValue =
      omStateHandler.isFeatureSupported("Awb0HideSVROwningItem");
  }
  if (data && data.isCustomVariantRuleFeatureSupported) {
    data.isCustomVariantRuleFeatureSupported.dbValue =
      omStateHandler.isFeatureSupported("Awb0SupportsCustomVariantRule");
  }
};

let populateReadOnlyFeaturesInfo = function (data) {
  if (data && data.isVariantRuleFeatureReadOnly) {
    data.isVariantRuleFeatureReadOnly.dbValue =
      omStateHandler.isFeatureReadOnly("Awb0VariantFeature");
  }
};

let getSVROwningItemFromProductContextInfo = function () {
  if (productContextInfo) {
    let svrOwningItem = productContextInfo.props.awb0VariantRuleOwningRev;
    if (
      !svrOwningItem ||
      (svrOwningItem.isNulls && svrOwningItem.isNulls !== true)
    ) {
      svrOwningItem = productContextInfo.props.awb0Product;
    }
    return svrOwningItem;
  }
};

let getOpenedProductUIDFromProductContextInfo = function () {
  if (productContextInfo) {
    return productContextInfo.props.awb0Product.dbValues[0];
  }
};

let handleSVROwningItemChangeIfThePanelIsGettingReInitialized = function (
  data,
  svrOwningItemToRender
) {
  if (data && svrOwningItemToRender) {
    let svrOwningItemUID = svrOwningItemToRender.uid;
    if (svrOwningItemUID === getOpenedProductUIDFromProductContextInfo()) {
      svrOwningItemUID = null;
    }
    let eventData = {
      svrOwningItem: {
        uid: svrOwningItemUID,
      },
      variantRule: null,
    };
    eventBus.publish("G4B_awConfigPanel.variantInfoChanged", eventData);
  }
  appCtxSvc.unRegisterCtx("svrOwningItemSelected");
};

let updatePanelWithSVROwningItemToRender = function (svrOwningItemToRender) {
  if (svrOwningItemToRender) {
    appCtxSvc.updateCtx("svrOwningItemToRender", svrOwningItemToRender);
  }
};

export let updatePartialCtx = function (path, value, flag) {
  if (flag == 1) {
    appCtxSvc.updateCtx("configureFlag", flag);
    appCtxSvc.updatePartialCtx(path, value);
    return;
  }
  if (value.r_uid == undefined) {
    value.r_uid = appCtxSvc.getCtx(
      "aceActiveContext.context.productContextInfo.props.awb0CurrentRevRule.dbValues"
    )[0];
  }
  appCtxSvc.updatePartialCtx(path, value);

  /* //Remove importConfigurationCtx after config is applied Workaround: GEPA-6730
    if (appCtxSvc.ctx.importConfigurationCtx != undefined) {
        appCtxSvc.unRegisterCtx('importConfigurationCtx');
    }
    //Workaround: GEPA-6730 */

  /*let eventSubscription = eventBus.subscribe('occDataProvider.treeNodesLoaded', function () {
        eventBus.unsubscribe(eventSubscription);
        //eventBus.publish('refreshViewafterconfigure');

    });  */
  return;
  // return deferred.promise();
};

/**
 * This is a comment in a file to demonstrate the merge scenario
 */

let newSVROwningItemSelected = function () {
  return appCtxSvc.getCtx("svrOwningItemSelected");
};

let populateSVROwningItems = function (data) {
  if (data) {
    let svrOwningItemToRender = newSVROwningItemSelected();
    if (svrOwningItemToRender) {
      handleSVROwningItemChangeIfThePanelIsGettingReInitialized(
        data,
        svrOwningItemToRender
      );
      updatePanelWithSVROwningItemToRender(svrOwningItemToRender);
    } else {
      let svrOwningItemFromPCI = getSVROwningItemFromProductContextInfo();
      updatePanelWithSVROwningItemToRender(svrOwningItemFromPCI);
    }
  }
};

let convertVariantRulesIntoVMProperty = function (
  productContextInfoModelObject
) {
  let variantRuleVMProperties = [];
  for (
    let i = 0;
    i < productContextInfoModelObject.props.awb0VariantRules.dbValues.length;
    i++
  ) {
    let variantRuleVMProperty = uwPropertySvc.createViewModelProperty(
      productContextInfoModelObject.props.awb0VariantRules.dbValues[i],
      productContextInfoModelObject.props.awb0VariantRules.uiValues[i],
      "STRING",
      productContextInfoModelObject.props.awb0VariantRules.dbValues[i],
      ""
    );
    variantRuleVMProperty.uiValue =
      productContextInfoModelObject.props.awb0VariantRules.uiValues[i];
    //Set an index for applied rules. This index will be used to determine the index of clicked rule link in case of overlay.
    //This will be helpful when user has applied the same rule multiple times and we want to determine which link of the rule has been clicked.
    variantRuleVMProperty.ruleIndex = i;
    variantRuleVMProperties[i] = variantRuleVMProperty;
  }
  return variantRuleVMProperties;
};

let getVariantRuleFromProductContextInfo = function () {
  if (productContextInfo) {
    let currentVariantRules = productContextInfo.props.awb0VariantRules;
    if (
      currentVariantRules &&
      currentVariantRules.dbValues &&
      currentVariantRules.dbValues.length > 0
    ) {
      let variantRuleVMProperties =
        convertVariantRulesIntoVMProperty(productContextInfo);
      return variantRuleVMProperties;
    }
  }
};

let getDefaultVariantRule = function (data) {
  if (data) {
    return _.clone(data.defaultVariantRule, true);
  }
};

let populateVariantRule = function (data) {
  if (data) {
    let currentVariantRules = getVariantRuleFromProductContextInfo();
    if (!currentVariantRules || !currentVariantRules[0].uiValue) {
      currentVariantRules = [];
      let defaultVariantRule = getDefaultVariantRule(data);
      if (defaultVariantRule) {
        defaultVariantRule.ruleIndex = 0;
      }
      currentVariantRules[0] = defaultVariantRule;
      data.showOverlayCommand = false;
    } else {
      data.showOverlayCommand = true;
    }
    data.appliedVariantRules = currentVariantRules;
  }
};

export let showNewVariantRuleSelector = function (data) {
  if (data.appliedVariantRules && data.newVariantRule) {
    data.newVariantRule.ruleIndex = data.appliedVariantRules.length;
    data.appliedVariantRules.push(data.newVariantRule);
    data.showOverlayCommand = false;
  }
};
/**
 * Get SVROwningItems
 */
export let getSVROwningItems = function () {
  let svrOwningItemToRender = appCtxSvc.getCtx("svrOwningItemToRender");
  if (svrOwningItemToRender) {
    let svrOwningItems = [];
    svrOwningItems.push(svrOwningItemToRender);

    return svrOwningItems;
  }
};

let clearDataProviderCache = function (data) {
  if (data && data.dataProviders && data.dataProviders.getAllVariantRules) {
    data.dataProviders.getAllVariantRules.viewModelCollection.clear();
    data.dataProviders.getAllVariantRules.selectedObjects = [];
  }
};

export let G4B_showCustomVariantPanel2 = function (variantRuleToEdit, data) {
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
  let userSettings = [];
  let rootElement = {};

  if (variantConfigContext && variantConfigContext.userSettings != undefined) {
    userSettings = appCtxSvc.getCtx("variantConfigContext.userSettings");
  }
  if (variantConfigContext && variantConfigContext.rootElement != undefined) {
    rootElement = appCtxSvc.getCtx("variantConfigContext.rootElement");
  }

  if (variantRuleToEdit !== undefined && variantRuleToEdit !== null) {
    variantRuleToEdit =
      viewModelObjectService.createViewModelObject(variantRuleToEdit);
  }
  // Set initial variant rule
  if (variantRuleToEdit) {
    variantConfigContext.initialVariantRule = variantRuleToEdit;
  }

  // Register variant context
  variantConfigContext.guidedMode = true;
  variantConfigContext.userSettings = userSettings;
  variantConfigContext.rootElement = rootElement;
  variantConfigContext.customVariantPanelInitializing = true;
  let getTSpromise = exports.setTimeSliceSelections(userSettings, data);
  $.when(getTSpromise).done(function (response) {
    variantConfigContext.selections = response.selections;
    appCtxSvc.updateCtx("variantConfigContext", variantConfigContext);
    eventBus.publish(
      "G4B_CFG_VariantData.updateUserSelectionSummary",
      appCtxSvc.getCtx("responseInfoSummary")
    );
    appCtxSvc.registerCtx("storeFamilyData", []);
    variantConfigContext.currentGroup = response.scopes[0];

    //If an existing-invalid configuration is loaded
    if (!variantConfigContext.guidedMode) {
      //By default: isStandardManualMode is true when configurator loads in unguided mode
      variantConfigContext.isStandardManualMode = true;
      eventBus.publish("getProjectedData_callLoadVariantDataUnguided.event");
    } else {
      eventBus.publish("callLoadVariantData");
    }
    appCtxSvc.updateCtx("storeContext", variantConfigContext);
  });
};

export let setTimeSliceSelections = function (userSettings, data) {
  let deferred = AwPromiseService.instance.defer();
  let scope = "";
  let promise1 = getUidForTimeSliceGroup(scope, userSettings);
  $.when(promise1).done(function (response) {
    let userSelectionsSummary = [];
    for (let i = 0; i < response.allScopes.length; i++) {
      if (
        response.allScopes[i].scopeObj.displayName.indexOf("CFG_") != -1 ||
        response.allScopes[i].scopeObj.displayName.indexOf("Time-Slice") != -1
      ) {
        scope = response.allScopes[i].scopeObj.sourceUid;
        //case: When there is an existing SVR/Custom configuration
        if (
          response.responseInfo &&
          response.responseInfo.summaryOfSelections
        ) {
          let TsGroupSelection = null;

          let summaryOfSelections = JSON.parse(
            response.responseInfo.summaryOfSelections[0]
          );
          for (
            let i = 0;
            i < summaryOfSelections.summaryOfSelections.length;
            i++
          ) {
            let featureObj = null;
            if (
              summaryOfSelections.summaryOfSelections[i].featureUID.length > 0
            ) {
              featureObj = viewModelObjectService.createViewModelObject(
                summaryOfSelections.summaryOfSelections[i].featureUID
              );
            }
            let userSelection = {
              familyUID: summaryOfSelections.summaryOfSelections[i].familyUID,
              familyDisplayName:
                summaryOfSelections.summaryOfSelections[i].familyDisplayName,
              familyDesc:
                summaryOfSelections.summaryOfSelections[i].familyDisplayName,

              nodeID:
                summaryOfSelections.summaryOfSelections[i].familyUID +
                ":" +
                summaryOfSelections.summaryOfSelections[i].featureUID,
              featureDisplayName:
                featureObj != null
                  ? featureObj.props.object_name.dbValues[0]
                  : "",
              featureDescription:
                featureObj != null
                  ? featureObj.props.object_desc.dbValues[0]
                  : "",

              selectionState:
                summaryOfSelections.summaryOfSelections[i].selectionState,

              groupUID: summaryOfSelections.summaryOfSelections[i].groupUID,
              groupDisplayName:
                summaryOfSelections.summaryOfSelections[i].groupDisplayName,
            };

            if (summaryOfSelections.summaryOfSelections[i].featureUID != "") {
              userSelectionsSummary.push(userSelection);
            }

            if (userSelection.groupDisplayName.indexOf("CFG_") != -1) {
              TsGroupSelection = userSelection;
            }
          }

          if (appCtxSvc.getCtx("responseInfoSummary")) {
            appCtxSvc.updateCtx("responseInfoSummary", userSelectionsSummary);
          } else {
            appCtxSvc.registerCtx("responseInfoSummary", userSelectionsSummary);
          }

          let selection_ts;
          let TSInfo;
          if (appCtxSvc.getCtx("cdObjProps")) {
            TSInfo =
              appCtxSvc.getCtx("cdObjProps").props.object_name.dbValues[0];
          } else {
            let currentTS = timesliceEffectivityParser();
            if (isNaN(currentTS.g4b_BP)) {
              TSInfo =
                currentTS.g4b_DER +
                "_" +
                currentTS.g4b_BP +
                "_" +
                currentTS.g4b_SOP;
            } else {
              TSInfo =
                currentTS.g4b_DER +
                "-" +
                "R" +
                "-" +
                currentTS.g4b_SOP.substring(0, 2) +
                "-" +
                currentTS.g4b_SOP.substring(2, 4) +
                "-" +
                currentTS.g4b_BP;
            }
          }
          if (TsGroupSelection.featureDisplayName == TSInfo) {
            //Saving the time slice option in context to be used for other purposes
            selection_ts = {
              affectedObject: "",
              columnId: 0,
              selections: {},
            };
            let selectionInfo = {
              nodeID: TsGroupSelection.nodeID,
              selectionState: TsGroupSelection.selectionState,
            };
            selection_ts.selections[TsGroupSelection.familyUID] = [
              selectionInfo,
            ];

            if (
              appCtxSvc.getCtx("g4b_ImportConfigurationPanelCtx") == undefined
            ) {
              appCtxSvc.registerCtx("g4b_ImportConfigurationPanelCtx", [
                {
                  TimeSliceOptionSelection: [selection_ts],
                },
              ]);
            } else {
              appCtxSvc.updateCtx("g4b_ImportConfigurationPanelCtx", [
                {
                  TimeSliceOptionSelection: [selection_ts],
                },
              ]);
            }
            //END: Saving the time slice option in context to be used for other purposes
          }
          //This function response decides if we start CFSC in Manual or Guided mode
          let promiseValidateConfiguration = validateConfigurationOnLoad();
          $.when(promiseValidateConfiguration).done(function () {
            deferred.resolve(response);
          });
        } else {
          //IF there was no existing VariantRule then make another SOA call to load Timeslice family scope
          let promise2 = getUidForTimeSliceGroup(scope, userSettings);
          $.when(promise2).done(function (response) {
            let k, selection_ts;
            let family = {};
            let userSelectionsSummary = [];
            let userSelection = {};
            for (let j = 0; j < response.allScopes.length; j++) {
              if (response.allScopes[j].families.length > 0) {
                userSelection.groupUID =
                  response.allScopes[j].scopeObj.sourceUid;
                userSelection.groupDisplayName =
                  response.allScopes[j].scopeObj.displayName;

                for (k = 0; k < response.allScopes[j].families.length; k++) {
                  if (
                    response.allScopes[j].families[k].familyObj.displayName ==
                    "TS"
                  ) {
                    userSelection.familyUID =
                      response.allScopes[j].families[k].familyObj.sourceUid;
                    userSelection.familyDisplayName =
                      response.allScopes[j].families[k].familyObj.displayName;
                    userSelection.familyDesc = "";
                    family = response.allScopes[j].families[k];
                  }
                }
              }
            }

            let TSInfo;
            if (appCtxSvc.getCtx("cdObjProps")) {
              TSInfo =
                appCtxSvc.getCtx("cdObjProps").props.object_name.dbValues[0];
            } else {
              let currentTS = timesliceEffectivityParser();
              if (isNaN(currentTS.g4b_BP)) {
                TSInfo =
                  currentTS.g4b_DER +
                  "_" +
                  currentTS.g4b_BP +
                  "_" +
                  currentTS.g4b_SOP;
              } else {
                TSInfo =
                  currentTS.g4b_DER +
                  "-" +
                  "R" +
                  "-" +
                  currentTS.g4b_SOP.substring(0, 2) +
                  "-" +
                  currentTS.g4b_SOP.substring(2, 4) +
                  "-" +
                  currentTS.g4b_BP;
              }
            }

            for (k = 0; k < family.features.length; k++) {
              if (
                family.features[k].featureObject.wsObject.props.object_name
                  .dbValues[0] == TSInfo
              ) {
                family.features[k].selectionInfo.selectionState = 1;
                userSelection.nodeID = family.features[k].selectionInfo.nodeID;
                userSelection.featureDisplayName =
                  family.features[
                    k
                  ].featureObject.wsObject.props.object_name.dbValues[0];
                userSelection.selectionState =
                  family.features[k].selectionInfo.selectionState;
                //userSelection.vmo=viewModelObjectService.createViewModelObject(family.features[k].featureObject.sourceUid );
                userSelection.featureDescription =
                  family.features[
                    k
                  ].featureObject.wsObject.props.object_desc.dbValues[0];
                //Saving the time slice option in context to be used for other purposes
                selection_ts = {
                  affectedObject: "",
                  columnId: 0,
                  selections: {},
                };
                selection_ts.selections[family.familyObj.sourceUid] = [
                  family.features[k].selectionInfo,
                ];

                if (
                  appCtxSvc.getCtx("g4b_ImportConfigurationPanelCtx") ==
                  undefined
                ) {
                  appCtxSvc.registerCtx("g4b_ImportConfigurationPanelCtx", [
                    {
                      TimeSliceOptionSelection: [selection_ts],
                    },
                  ]);
                } else {
                  appCtxSvc.updateCtx("g4b_ImportConfigurationPanelCtx", [
                    {
                      TimeSliceOptionSelection: [selection_ts],
                    },
                  ]);
                }
                //END: Saving the time slice option in context to be used for other purposes

                if (
                  response.selections.length > 0 &&
                  response.selections[0].selections
                ) {
                  response.selections[0].selections[
                    family.familyObj.sourceUid
                  ] = [family.features[k].selectionInfo];
                } else {
                  if (response.selections.length > 0) {
                    //Handles empty selections with CSP3 and before
                    response.selections[0].selections = {};
                    response.selections[0].selections[
                      family.familyObj.sourceUid
                    ] = [family.features[k].selectionInfo];
                  } else {
                    //for empty selections case with CSP4 and later
                    selection_ts = {
                      affectedObject: "",
                      columnId: 0,
                      selections: {},
                    };
                    //response.selections[0].selections={};
                    selection_ts.selections[family.familyObj.sourceUid] = [
                      family.features[k].selectionInfo,
                    ];
                    response.selections.push(selection_ts);
                  }
                }
                userSelectionsSummary.push(userSelection);
                if (appCtxSvc.getCtx("responseInfoSummary")) {
                  appCtxSvc.updateCtx(
                    "responseInfoSummary",
                    userSelectionsSummary
                  );
                } else {
                  appCtxSvc.registerCtx(
                    "responseInfoSummary",
                    userSelectionsSummary
                  );
                }
              }
            }
            deferred.resolve(response);
          });
        }
      }
    }
  });
  return deferred.promise;
};

let getUidForTimeSliceGroup = function (scope, userSettings) {
  let deferred = AwPromiseService.instance.defer();
  let selectedContext = "";
  let multiSelections = appCtxSvc.getCtx("mselected");

  if (multiSelections && multiSelections.length > 1) {
    // return last selected object in case of multiple selections
    selectedContext = multiSelections[multiSelections.length - 1];
  } else {
    selectedContext = appCtxSvc.getCtx("selected");
  }

  let aceActiveContext = appCtxSvc.getCtx("aceActiveContext");

  let policy = {
    types: [
      {
        name: "Cfg0AbsConfiguratorWSO",
        properties: [
          {
            name: "object_desc",
          },
        ],
      },
    ],
  };
  let policyId = policySvc.register(policy);

  let inputData = {
    input: {
      configPerspective: appCtxSvc.getCtx(
        "variantConfigContext.configPerspective"
      ),
      selectedContext: selectedContext,
      activeVariantRules: [
  
      ],
      scopes: [scope],
      requestInfo: {
        isFsc: ["true"],
        profileSettings: [g4bConfiguratorSvc.getOrderSolverProfile()],
        requestType: ["getConfig"],
        configurationControlMode: ["manual"],
        switchingToGuidedMode: ["false"],
      },
    },
  };

  /*Incase of a Fresh CFSC start i.e No existing SVR, use manual mode for Performance reasons
    if(aceActiveContext.context.productContextInfo.props.awb0VariantRules.dbValues.length == 0){
        inputData.input.requestInfo.configurationControlMode = ["manual"]
    }*/

  soaSvc
    .post(
      "Internal-ProductConfiguratorAw-2022-12-ConfiguratorManagement",
      "variantConfigurationView3",
      inputData
    )
    .then(function (response) {
      //UnRegister Policy
      if (policyId) {
        policySvc.unregister(policyId);
      }
      deferred.resolve(response);
    });
  return deferred.promise;
};

let validateConfigurationOnLoad = function () {
  let deferred = AwPromiseService.instance.defer();

  let aceActiveContext = appCtxSvc.getCtx("aceActiveContext");
  let inputData = {
    input: {
      activeVariantRules: [
        {
          uid: aceActiveContext.context.productContextInfo.props
            .awb0VariantRules.dbValues[0],
          type: "VariantRule",
        },
      ],
      configPerspective: appCtxSvc.getCtx(
        "variantConfigContext.configPerspective"
      ),
      selectedContext: g4bConfiguratorSvc.getSelectionForVariantContext(),
      scopes: [""],
      requestInfo: {
        isFsc: ["true"],
        profileSettings: [g4bConfiguratorSvc.getOrderSolverProfile()],
        requestType: ["getConfig"],
        configurationControlMode: ["guided"],
        switchingToGuidedMode: [""],
      },
    },
  };
  soaSvc
    .post(
      "Internal-ProductConfiguratorAw-2022-12-ConfiguratorManagement",
      "variantConfigurationView3",
      inputData
    )
    .then(function (response) {
      if (
        (response.responseInfo.criteriaStatus &&
          response.responseInfo.criteriaStatus[0] == "InValid") ||
        (response.responseInfo.isValid &&
          response.responseInfo.isValid[0] == "false") ||
        (response.responseInfo.isCompletedWithError &&
          response.responseInfo.isCompletedWithError[0] == "true")
      ) {
        appCtxSvc.updateCtx("variantConfigContext.guidedMode", false);
        appCtxSvc.updateCtx("variantConfigContext.configValidation", 2); //2=Invalid
      }
      if (
        response.responseInfo.criteriaStatus &&
        response.responseInfo.criteriaStatus[0] == "ValidAndInComplete"
      ) {
        appCtxSvc.updateCtx("variantConfigContext.configValidation", 0);
      }
      if (
        response.responseInfo.criteriaStatus &&
        response.responseInfo.criteriaStatus[0] == "ValidAndComplete"
      ) {
        appCtxSvc.updateCtx("variantConfigContext.configValidation", 1);
      }
      deferred.resolve();
    });
  return deferred.promise;
};

export let setMode = function (data) {
  let context = appCtxSvc.getCtx("variantConfigContext");

  if (context.guidedMode == true) {
    data.isGuidedMode.dbValue = true;
  } else if (context.guidedMode == false) {
    data.isGuidedMode.dbValue = false;
  }

  return;
};

/**
 * This API evaluates variant rules to apply while applying the custom configuration and returns the list of
 * variant rule UIDs
 */
export let getVariantRulesToApply = function () {
  //Get the current applied rule list
  let currentAppliedVRs = appCtxSvc.getCtx(
    "aceActiveContext.context.productContextInfo.props.awb0VariantRules.dbValues"
  );
  let variantRules = currentAppliedVRs;
  let variantConfigContext = appCtxSvc.getCtx("variantConfigContext");
  let index = variantRules.length;

  /**CHANGED:-
   * THE FOLLOWING CODE ALWAYS REPLACES THE LAST CONFIGURED PRODUCT IN productContextInfo.props.awb0VariantRules
   * Check VariantInfoConfigurationService.js for OOTB implementation
   */

  if (variantConfigContext.customVariantRule == null) {
    variantRules.splice(0, 1);
  } else {
    if (index == 0) {
      variantRules[index] = variantConfigContext.customVariantRule;
    } else {
      variantRules[index - 1] = variantConfigContext.customVariantRule;
    }
  }
  return variantRules;
};

/**
 * Initialize the Variant Info Configuration Section
 */
export let getInitialVariantConfigurationData = function (data) {
  if (data) {
    populateProductContextInfo();
    if (productContextInfo) {
      populateSupportedFeaturesInfo(data);
      populateReadOnlyFeaturesInfo(data);
      populateVariantRule(data);
      populateOpenedProduct(data);
      populateSVROwningItems(data);
      if (data.defaultVariantRule) {
        defaultVariantRule = data.defaultVariantRule.propertyDisplayName;
      }
      if (data.customVariantRule) {
        customVariantRule = data.customVariantRule.propertyDisplayName;
      }
      clearDataProviderCache(data);
      eventBus.publish("G4B_CFG_VariantData.revealSVROwningItems");
      if (dataValue !== data) {
        dataValue = data;
      }
    }
  }
};

/**
 * Clears the current saved variant selection
 */
export let clearVariantConfigurationData = function (data) {
  if (data) {
    clearDataProviderCache(data);
    eventBus.publish("G4B_CFG_VariantData.revealSVROwningItems");
    if (dataValue !== data) {
      dataValue = data;
    }
  }
};

/**
 * Update Config Items
 */
export let updateConfigItems = function (newItemSelected) {
  if (newItemSelected) {
    appCtxSvc.registerCtx("svrOwningItemSelected", newItemSelected);
  }
};

export let processSVROwningItems = function (response) {
  if (
    response.partialErrors ||
    (response.ServiceData && response.ServiceData.partialErrors)
  ) {
    return response;
  }

  let svrOwningItems =
    endItemUnitEffectivityService.populateEndItemsOrSVROwningItems(
      response.preferredVarRuleOwningObjects
    );
  svrOwningItems =
    endItemUnitEffectivityService.addOpenObjectAsPreferredIfApplicable(
      svrOwningItems,
      response.addOpenObjAsPreferredEndItem
    );
  return svrOwningItems;
};

/**
 * Find Subtype Business Object
 */
export let fetchSubBOTypesForVariant = function (data) {
  if (!data.subBusinessObjects || data.subBusinessObjects.length === 0) {
    eventBus.publish("searchSVROwningItems.fetchSubBOTypes");
  } else {
    eventBus.publish("searchSVROwningItems.doSearch");
  }
};

/**
 * Process the response from Server
 */
export let processVariantRules = function (response,data) {
  let deferred = AwPromiseService.instance.defer();
  let i;
  let finalVariantRulesArr = [];
  if (appCtxSvc.getCtx("showFromOtherSVRs") == undefined) {
    appCtxSvc.registerCtx("showFromOtherSVRs", false);
  }
  if (appCtxSvc.getCtx("showArchivedRules") == undefined) {
    appCtxSvc.registerCtx("showArchivedRules", false);
  }
  if (appCtxSvc.getCtx("showAllRules") == undefined) {
    appCtxSvc.registerCtx("showAllRules", false);
  }

  if (
    response.partialErrors ||
    (response.ServiceData && response.ServiceData.partialErrors)
  ) {
    return response;
  }

  if (response.endIndex <= 20) {
    isSeparatorAdded = false;
  }

  let responseVariantRules = Object.keys(response.ServiceData.modelObjects).map(
    function (e) {
      return response.ServiceData.modelObjects[e];
    }
  );

  //remove Objects other than VariantRule
  for (let index = 0; index < responseVariantRules.length; index++) {
    if (responseVariantRules[index].type != "VariantRule") {
      responseVariantRules.splice(index, 1);
      index--;
    }
  }

  let userProjects;
  let projects = [];
  let loggedInRoleName = appCtxSvc.getCtx(
    "userSession.props.role_name.dbValue"
  );
  let user = cdm.getObject(appCtxSvc.getCtx("userSession.props.user.dbValue"));
  let inputs3 = {
    userProjectsInfoInputs: [
      {
        user: user,
        activeProjectsOnly: true,
        privilegedProjectsOnly: false,
        programsOnly: false,
        clientId: "1",
      },
    ],
  };
  soaSvc
    .post("Core-2009-10-ProjectLevelSecurity", "getUserProjects", inputs3)
    .then(function (response2) {
      userProjects = response2.userProjectInfos[0].projectsInfo;
      for (let j = 0; j < userProjects.length; j++) {
        projects.push(userProjects[j].project.props.object_string.dbValues[0]);
      }

      //Store the user projects in context
      if (appCtxSvc.getCtx("g4bUserProjects") != undefined) {
        appCtxSvc.updateCtx("g4bUserProjects", projects);
      } else {
        appCtxSvc.registerCtx("g4bUserProjects", projects);
      }

      // By default show only rules from the matching Time-slice
      let variantRulesArrTSfiltered = [];
      let TsInfo = appCtxSvc.getCtx("g4bCurrentTS");
      let currentTimeSlice =  TsInfo.g4b_DER + "_" + TsInfo.g4b_BP + "_" + TsInfo.g4b_SOP;
      appCtxSvc.registerCtx("currentTimeSlice", currentTimeSlice);
      if (appCtxSvc.getCtx("showAllRules") == false) {
        for (i = 0; i < responseVariantRules.length; i++) {
          if ( responseVariantRules[i].props.g4b_DER.dbValues[0] != null && responseVariantRules[i].props.g4b_BP.dbValues[0] != null && 
            responseVariantRules[i].props.g4b_SOP.dbValues[0] != null &&
            responseVariantRules[i].props.g4b_DER.dbValues[0] ==
              TsInfo.g4b_DER &&
            responseVariantRules[i].props.g4b_BP.dbValues[0] == TsInfo.g4b_BP &&
            responseVariantRules[i].props.g4b_SOP.dbValues[0] == TsInfo.g4b_SOP
          ) {
            variantRulesArrTSfiltered.push(responseVariantRules[i]);
          }
        }
      } else {
        variantRulesArrTSfiltered = responseVariantRules;
      }
      // checking project rights if the role is non-'Gepard IT Admin' for productLines sublocation.
      //show only time-slices for which user has project rights.
      let userProjectfiltered = [];
      if (loggedInRoleName != "Gepard IT Admin") {
        for (i = 0; i < variantRulesArrTSfiltered.length; i++) {
          if (
            projects.indexOf(
              variantRulesArrTSfiltered[i].props.g4b_DER.dbValues[0]
            ) != -1
          ) {
            userProjectfiltered.push(variantRulesArrTSfiltered[i]);
          }
        }
      } else {
        userProjectfiltered = variantRulesArrTSfiltered;
      }


    //   for (i = 0; i < responseVariantRules.length; i++) {
    //     let value = responseVariantRules[i].props;
    //     if (value.g4b_IsValid.dbValues[0] == 1 && value.g4b_isComplete.dbValues[0] == 1 && (value.g4b_ConfigurationMode.dbValues[0] == 1 || value.g4b_ConfigurationMode.dbValues[0] == null)) {
    //       responseVariantRules[i].typeIconURL = "assets/image/indicatorCockpitGreen16.svg";
    //     }
    //     else if (value.g4b_IsValid.dbValues[0] == 1 && (value.g4b_isComplete.dbValues[0] == 0 || value.g4b_isComplete.dbValues[0] == null) && (value.g4b_ConfigurationMode.dbValues[0] == 1 || value.g4b_ConfigurationMode.dbValues[0] == null)) {
    //       responseVariantRules[i].typeIconURL = "assets/image/indicatorCockpitYellow16.svg";
    //     }
    //     else if (value.g4b_IsValid.dbValues[0] == 0) {
    //       //responseVariantRules[i].typeIconURL = iconSvc.getIcon("indicatorCockpitRed16");
    //     }
    //     else if (value.g4b_IsValid.dbValues[0] == null) {
    //       responseVariantRules[i].typeIconURL = iconSvc.getTypeIconFileUrl("indicatorCockpitRed16");
    //       // value.object_name.dbValues[0] = <span style='font-size:100px;'>&#9679;</span>;
    //       // value.object_name.uiValues = <span style='font-size:100px;'>&#9679;</span>; 
    //       // _uwPropertySvc.setValue(value.g4b_TSDisplayName,<span style='font-size:100px;'>&#9679;</span>);
    //       // _uwPropertySvc.setValue(value.object_name,<span style='font-size:100px;'>&#9679;</span>);
    //       value.g4b_IsValidIndicator =_.clone(_uwPropertySvc.createViewModelProperty( "g4b_IsValidIndicator", "ValidIndicator", 'STRING', <span style='font-size:100px;'>&#9679;</span>));
    //     }
    //  }
        

      //Check for archived flag. Only unarchived objects are to be shown by default
      let variantRulesArrArchivefiltered = [];
      if (appCtxSvc.getCtx("showArchivedRules") == false) {
        for (i = 0; i < userProjectfiltered.length; i++) {
          if (userProjectfiltered[i].props.g4b_Archived.dbValues[0] == "0") {
            variantRulesArrArchivefiltered.push(userProjectfiltered[i]);
          }
        }
      } else {
        variantRulesArrArchivefiltered = userProjectfiltered;
      }

      if (appCtxSvc.getCtx("showFromOtherSVRs") == false) {
        for (i = 0; i < variantRulesArrArchivefiltered.length; i++) {
          if (
            variantRulesArrArchivefiltered[i].props.release_status_list
              .uiValues[0] == "Official Virtual Car" ||
            variantRulesArrArchivefiltered[i].props.release_status_list
              .uiValues[0] == "Offizielles virtuelles Fahrzeug" ||
            variantRulesArrArchivefiltered[i].props.owning_user.dbValues[0] ==
              appCtxSvc.getCtx("user").uid
          ) {
            finalVariantRulesArr.push(variantRulesArrArchivefiltered[i]);
          }
        }
      }
      if (appCtxSvc.getCtx("showFromOtherSVRs") == true) {
        finalVariantRulesArr = variantRulesArrArchivefiltered;
      }

       //responseVariantRules = finalVariantRulesArr;
       //sortedVariantRules.push(responseVariantRules[i]);
          deferred.resolve(finalVariantRulesArr);
      // let sortedVariantRules = [];

      // for (i = 0; i < responseVariantRules.length; i++) {
      //   responseVariantRules[i].status =
      //     responseVariantRules[i].props.release_status_list.uiValues[0]; //to keep the status list as persistent property on the vmo
      //   responseVariantRules[i].last_mod_date =
      //     dateTimeService.formatSessionDateTime(
      //       responseVariantRules[i].props.last_mod_date.dbValues[0]
      //     ); //to keep the last mod date as persistent property on the vmo
      //   responseVariantRules[i].g4b_Archived =
      //     responseVariantRules[i].props.g4b_Archived.uiValues[0]; //to keep the last mod date as persistent property on the vmo
      //   responseVariantRules[i].g4b_IsValid =
      //     responseVariantRules[i].props.g4b_IsValid.dbValues[0];
      //   responseVariantRules[i].g4b_isComplete =
      //     responseVariantRules[i].props.g4b_isComplete.dbValues[0];

      //   responseVariantRules[i].g4b_LastValidityCheck =
      //     responseVariantRules[i].props.g4b_LastValidityCheck.uiValues[0];
      //   responseVariantRules[i].g4b_ConfigurationMode =
      //     responseVariantRules[i].props.g4b_ConfigurationMode.dbValues[0];
      //   responseVariantRules[i].object_name =
      //     responseVariantRules[i].props.object_name.uiValues[0];

      //   let resource = app.getBaseUrlPath() + "/i18n/gepardMessages";
      //   let localeTextBundle = localeSvc.getLoadedText(resource);
      //   let g4b_ConfigurationMode =
      //     responseVariantRules[i].props.g4b_ConfigurationMode.dbValues[0];
      //   let g4b_IsValid = responseVariantRules[i].props.g4b_IsValid.dbValues[0];
      //   let g4b_isComplete =
      //     responseVariantRules[i].props.g4b_isComplete.dbValues[0];

      //   if (g4b_IsValid == null) {
      //     responseVariantRules[i].tooltip =
      //       localeTextBundle.G4B_VariantRuleNotEvaluated;
      //   } else if (
      //     g4b_IsValid == 1 &&
      //     g4b_isComplete == 1 &&
      //     (g4b_ConfigurationMode == 1 || g4b_ConfigurationMode == null)
      //   ) {
      //     responseVariantRules[i].tooltip =
      //       localeTextBundle.G4B_VariantRuleConsistentAndComplete;
      //   } else if (
      //     g4b_IsValid == 1 &&
      //     (g4b_isComplete == 0 || g4b_isComplete == null) &&
      //     (g4b_ConfigurationMode == 1 || g4b_ConfigurationMode == null)
      //   ) {
      //     responseVariantRules[i].tooltip =
      //       localeTextBundle.G4B_VariantRuleConsistentButInComplete;
      //   } else if (g4b_IsValid == 0) {
      //     responseVariantRules[i].tooltip =
      //       localeTextBundle.G4B_VariantRuleInconsistent;
      //   } else {
      //     responseVariantRules[i].tooltip = "";
      //   }

      //   //Check for '~Config-Import' SVRs
      //   if (
      //     responseVariantRules[i].object_name.indexOf("~Config-Import") != -1
      //   ) {
      //     continue;
      //   }
      //   sortedVariantRules.push(responseVariantRules[i]);
      //   deferred.resolve(sortedVariantRules);
      // }
    });
  return deferred.promise;
};

/**
 * Update context to show Saved Variant rules
 */
export let showSVRs = function (data) {
  appCtxSvc.registerCtx("showFromOtherSVRs", data.showFromOtherSVRs.dbValue);
  appCtxSvc.registerCtx("showMySVRs", data.showMySVRs.dbValue);
  appCtxSvc.registerCtx("showArchivedRules", data.showArchivedRules.dbValue);
  appCtxSvc.registerCtx("showAllRules", data.showAllRules.dbValue);

  eventBus.publish("G4B_CFG_VariantDataView.getAllVariantRules");
};

export let getSelectedSvr = function (data, props) {
  let selectedSVRs = data.dataProviders.getAllVariantRules.selectedObjects;
  let backupSelectedCtx = appCtxSvc.getCtx("backupSelectedCtx");
  let fscContext = appCtxSvc.getCtx( Pca0Constants.FSC_CONTEXT );
  
  let mSelectedCtx = appCtxSvc.getCtx("mselected");
  if (mSelectedCtx[0].type == "Fgf0ApplicationModel") {
    appCtxSvc.registerCtx("backupSelectedCtx", mSelectedCtx);
  }
  if (mSelectedCtx != undefined && selectedSVRs.length > 0) {
    appCtxSvc.updateCtx("mselected", selectedSVRs);
  }
  else if (mSelectedCtx != undefined && selectedSVRs.length == 0) {
    appCtxSvc.updateCtx("mselected", backupSelectedCtx);
  }
  data.selectedSvrList.dbValues = selectedSVRs;
  
  fscContext.selectedSVRs = selectedSVRs;
 
  appCtxSvc.updateCtx( Pca0Constants.FSC_CONTEXT, fscContext );
  if (selectedSVRs.length == 1){
    eventBus.publish('G4B_CFG_VariantData.showUserSummaryforSavedVariant'); 
  }
  else{
    eventBus.publish('updateOverviewEvent');
  }
  return selectedSVRs;
  // if (selectedSVRs.length == 1) {
  //   props.buttonGroupState.savedButtonId.dbValue = "true";
  //   props.buttonGroupState.update(props.buttonGroupState);
  // }
  // else {
  //   props.buttonGroupState.savedButtonId.dbValue = "false";
  //   props.buttonGroupState.update(props.buttonGroupState);

  // }
  //eventBus.publish("loadSavedVariantsOverview");
}

export let evalKeyup= function (data){
  let filterOutTemplates = [];
  if (data.variantRules && data.filterData.dbValue.length > 0) {
      for (let index = 0; index < data.variantRules.length; index++) {
          if (data.variantRules[index].props.object_name.dbValues[0].toLowerCase().indexOf(data.filterData.dbValue.toLowerCase()) !== -1 || data.variantRules[index].props.owning_user.uiValues[0].toLowerCase().indexOf(data.filterData.dbValue.toLowerCase()) !== -1) {
              filterOutTemplates.push(data.variantRules[index]);
          }
      }
  }
  else {
      filterOutTemplates = data.variantRules
  }
  data.dataProviders.getAllVariantRules.update(filterOutTemplates);
  
};

export let evaluateStartIndexForVariantRuleDataProvider = function (data) {
  if (data.dataProviders.getAllVariantRules.startIndex === 0) {
    return 0;
  }

  let isMarkerPresent = false;

  for (
    let i = 0;
    i <
    data.dataProviders.getAllVariantRules.viewModelCollection.loadedVMObjects
      .length;
    i++
  ) {
    if (
      data.dataProviders.getAllVariantRules.viewModelCollection.loadedVMObjects[
        i
      ].marker
    ) {
      isMarkerPresent = true;
      break;
    }
  }

  let extraObjectsInList = 1; // When only 'No Variant Rule' is present in list
  if (isMarkerPresent) {
    extraObjectsInList++;
  }
  if (omStateHandler.isFeatureSupported("Awb0SupportsCustomVariantRule")) {
    extraObjectsInList++;
  }

  return (
    data.dataProviders.getAllVariantRules.viewModelCollection.loadedVMObjects
      .length - extraObjectsInList
  );
};
export let getIsValidProperty = function (response) {
  let g4b_IsValid = null;
  if (
    response.modelObjects != undefined &&
    appCtxSvc.getCtx(
      "occmgmtContext.productContextInfo.props.awb0CurrentVariantRule.dbValues[0]"
    ) != null
  ) {
    g4b_IsValid =
      response.modelObjects[response.plain[0]].props.g4b_IsValid.dbValues[0];
  }
  return g4b_IsValid;
};

export let getVariantRuleConfigurationModeProperty = function (response) {
  let g4b_ConfigurationMode = null;
  if (
    response.modelObjects != undefined &&
    appCtxSvc.getCtx(
      "occmgmtContext.productContextInfo.props.awb0CurrentVariantRule.dbValues[0]"
    ) != null
  ) {
    g4b_ConfigurationMode =
      response.modelObjects[response.plain[0]].props.g4b_ConfigurationMode
        .dbValues[0];
  }
  return g4b_ConfigurationMode;
};

export let evaluateStartIndexForVariantRuleCommonDataProvider = function (
  data
) {
  if (data.dataProviders.getAllVariantRulesCommon.startIndex === 0) {
    return 0;
  }

  let isMarkerPresent = false;

  for (
    let i = 0;
    i <
    data.dataProviders.getAllVariantRulesCommon.viewModelCollection
      .loadedVMObjects.length;
    i++
  ) {
    if (
      data.dataProviders.getAllVariantRulesCommon.viewModelCollection
        .loadedVMObjects[i].marker
    ) {
      isMarkerPresent = true;
      break;
    }
  }

  let extraObjectsInList = 1; // When only 'No Variant Rule' is present in list
  if (isMarkerPresent) {
    extraObjectsInList++;
  }
  if (omStateHandler.isFeatureSupported("Awb0SupportsCustomVariantRule")) {
    extraObjectsInList++;
  }

  return (
    data.dataProviders.getAllVariantRulesCommon.viewModelCollection
      .loadedVMObjects.length - extraObjectsInList
  );
};

export let availableVariantRules = function (response) {
  let svrNameArray = [];
  let currentTs = appCtxSvc.getCtx("currentTimeSlice");
  let currentTimeSlice = currentTs;

  _.forEach(response.ServiceData.modelObjects, function (object) {
    if (object.type === "VariantRule") {
      let selectedTS =
        object.props.g4b_DER.dbValues[0] +
        "_" +
        object.props.g4b_BP.dbValues[0] +
        "_" +
        object.props.g4b_SOP.dbValues[0];
      if (selectedTS === currentTimeSlice) {
        svrNameArray.push(object.props.object_name.dbValues[0]);
      }
    }
  });
  if (appCtxSvc.getCtx("srvObjects") != undefined) {
    appCtxSvc.updateCtx("svrNameArray", svrNameArray);
  } else {
    appCtxSvc.registerCtx("svrNameArray", svrNameArray);
  }
};

let timesliceEffectivityParser = function () {
  let tsArr = [];
  let g4bCurrentTS = {
    g4b_BP: null,
    g4b_DER: null,
    g4b_SOP: null,
  };
  let effString = appCtxSvc.getCtx(
    "occmgmtContext.productContextInfo.props.fgf0EffectivityDisplay.dbValues[0]"
  ); //'Unit=1..UP, BP = VPBG & DER = G12 & SOP = 1803';

  effString = effString.replace(/ /g, "");
  effString = effString.replace(/[<>]/g, "");
  if (effString.indexOf(",") > 0) {
    let effArr = effString.split(",");
    for (let inx = 0; inx < effArr.length; inx++) {
      if (
        effArr[inx].indexOf("DER") > -1 &&
        effArr[inx].indexOf("BP") > -1 &&
        effArr[inx].indexOf("SOP") > -1
      ) {
        tsArr = effArr[inx].split("&");
      }
    }
  }

  if (tsArr.length > 0) {
    for (let inx = 0; inx < tsArr.length; inx++) {
      if (tsArr[inx].indexOf("DER=") > -1) {
        if (tsArr[inx].split("=").length > 1) {
          g4bCurrentTS.g4b_DER = tsArr[inx].split("=")[1];
        }
      }
      if (tsArr[inx].indexOf("BP=") > -1) {
        if (tsArr[inx].split("=").length > 1) {
          g4bCurrentTS.g4b_BP = tsArr[inx].split("=")[1];
        }
      }
      if (tsArr[inx].indexOf("SOP=") > -1) {
        if (tsArr[inx].split("=").length > 1) {
          g4bCurrentTS.g4b_SOP = tsArr[inx].split("=")[1];
        }
      }
    }
  }

  return g4bCurrentTS;
};

/**
 * Variant Info Configuration service utility
 */

/**
 * Since this module can be loaded GWT-side by the ModuleLoader class we need to return an object indicating
 * which service should be injected to provide the API for this module.
 */

exports = {
  updatePartialCtx,
  getSelectedSvr,
  showNewVariantRuleSelector,
  getSVROwningItems,
  G4B_showCustomVariantPanel2,
  setTimeSliceSelections,
  setMode,
  evalKeyup,
  getVariantRulesToApply,
  getInitialVariantConfigurationData,
  clearVariantConfigurationData,
  updateConfigItems,
  processSVROwningItems,
  fetchSubBOTypesForVariant,
  processVariantRules,
  showSVRs,
  evaluateStartIndexForVariantRuleDataProvider,
  getIsValidProperty,
  getVariantRuleConfigurationModeProperty,
  evaluateStartIndexForVariantRuleCommonDataProvider,
  availableVariantRules,
};

export default exports;
