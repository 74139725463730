import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwList from 'viewmodel/AwListViewModel';
import AwDefaultCell from 'viewmodel/AwDefaultCellViewModel';
import historySvc from 'js/historyService';
import tcFilterService from 'js/tcFilterService';
import AwPromiseService from 'js/awPromiseService';
import appCtxService from 'js/appCtxService';


export const g4bHistoryRenderFunction = ({ i18n, viewModel }) => {
    const { dataProviders, dndHandler } = viewModel;
    return (
        <AwPanelSection caption={i18n.recentSectionTitle} collapsed='false'>
            <AwList dataprovider={dataProviders.getRecentObjsProvider} dndHandler={dndHandler}>
                <AwDefaultCell></AwDefaultCell>
            </AwList>
        </AwPanelSection>
    );
};

export const getHistory = async (filterTypes, isIncludeSubTypes, addObjectContext) => {
    let historyObjects = await historySvc.getHistory(addObjectContext);
    var newResponse = [];
    for (var i = 0; i < historyObjects.length; i++) {
        if (historyObjects[i].type === "G4B_CollaborativeDesign" || historyObjects[i].type === "G4B_DynamicConfigProduct" ||
            historyObjects[i].type === "G4B_AnlCtxWrkSetRevision" || historyObjects[i].type === "G4B_StatCPWrkSetRevision" ||
            historyObjects[i].type === "G4B_ProtTypeSpecRevision" || historyObjects[i].type === "G4B_EvalPhaseSubsetDefn") {
            newResponse.push(historyObjects[i]);
        }
    }
    historyObjects = newResponse;
    let histResultLength = historyObjects.length;
    let histResults = historyObjects;
    let filterTypesList = [];
    if (filterTypes) {
        filterTypesList = filterTypes.split(',');
    }
    let isIncludeSubTypesLocal = !(isIncludeSubTypes && isIncludeSubTypes === 'false');
    // filter the favorite items by filter types
    if (filterTypesList.length > 0) {
        let filteredFavoriteObjects = await tcFilterService.getFilteredObjects(histResults, filterTypesList, isIncludeSubTypesLocal);
        histResultLength = filteredFavoriteObjects.length;
        histResults = filteredFavoriteObjects;
    }
    
    return { histResultLength, histResults };
};

export const openObjectFromPanel = function ($adapt) {
    var selectedObject = $adapt;
    if (appCtxService.getCtx('getG4bRecentObjsProvider') !== undefined) {
        appCtxService.updateCtx('getG4bRecentObjsProvider', selectedObject);
    }
    else {
        appCtxService.registerCtx('getG4bRecentObjsProvider', selectedObject);
    }
    return selectedObject;
};