// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/* global
 define
 */

/**
 * @module js/mbmTreeService
 */
import appContextService from 'js/appCtxService';
import occMgmtTableService from 'js/occmgmtTreeTableDataService';
import eventBus from 'js/eventBus';
import { constants as mbmConstants } from 'js/mbmConstants';
import _ from 'lodash';
import mbmAlignmentInitializationService from 'js/mbmAlignmentInitializationService';
import cdm from 'soa/kernel/clientDataModel';
import AwStateService from 'js/awStateService';
import dataManagementService from 'soa/dataManagementService';
import awPromiseSvc from 'js/awPromiseService';
import mbm3DViewerService from 'js/mbm3DViewerService';
import epLoadInputHelper from 'js/epLoadInputHelper';
import { constants as _epLoadConstants } from 'js/epLoadConstants';
import soaService from 'soa/kernel/soaService';
import mbmUtils from 'js/mbmUtils';
import mbmPartialCompareService from 'js/mbmPartialCompareService';
import mbmSaveService from 'js/mbmSaveService';
import messagingService from 'js/messagingService';
import localeService from 'js/localeService';
import mfgNotificationUtils from 'js/mfgNotificationUtils';
import occmgmtBackingObjectProviderService from 'js/occmgmtBackingObjectProviderService';
import locationNavigationService from 'js/locationNavigation.service';
import mbmOnDemandCompareService from 'js/mbmOnDemandCompareService';
import epLoadService from 'js/epLoadService';

/**
 *
 * @param {Object} mbmContext  mbmContext
 * @param {Object} treeLoadResult mbmContext
 * @param {Object} contextKey contextKey
 */
function updateContextData(mbmContext, treeLoadResult, contextKey, selectionModel) {
    if (selectionModel) {
        let selectionModelObj = mbmUtils.getContextValue(mbmContext, 'selectionModel');
        if (!selectionModelObj) {
            selectionModelObj = {};
        }

        selectionModelObj[contextKey] = selectionModel;
        const objToUpdate = mbmUtils.getValueToUpdate(selectionModelObj, 'selectionModel');
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
    }
    if (treeLoadResult.topModelObject) {
        let topElement = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_TOP_ELEMENT);
        if (!topElement) {
            topElement = {};
        }
        topElement[contextKey] = treeLoadResult.topModelObject;
        const objToUpdate = mbmUtils.getValueToUpdate(topElement, mbmConstants.MBM_TOP_ELEMENT);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
    }
    if (treeLoadResult.productContextInfo) {
        let pci = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_PRODUCT_CONTEXT_INFO);
        if (!pci) {
            pci = {};
        }
        pci[contextKey] = treeLoadResult.productContextInfo;
        const objToUpdate = mbmUtils.getValueToUpdate(pci, mbmConstants.MBM_PRODUCT_CONTEXT_INFO);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
    }
    if (treeLoadResult.vmc) {
        let vmc = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_VMC);
        if (!vmc) {
            vmc = {};
        }
        vmc[contextKey] = treeLoadResult.vmc;
        const objToUpdate = mbmUtils.getValueToUpdate(vmc, mbmConstants.MBM_VMC);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
    }
}
/**
 * update find panel configuration
 * @param {Object} mbmContext mbmContext
 */
function updateUnifiedInContextSearchConfig(mbmContext, occContext) {
    let focusMode = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_FOCUS_MODE);
    focusMode = focusMode || false;
    let unifiedInContextSearchConfig = mbmUtils.getContextValue(occContext, mbmConstants.MBM_UNIFIED_INCONTEXT_SEARCH_CONFIG);
    unifiedInContextSearchConfig[mbmConstants.MBM_DISABLE_SEARCH_SCOPE] = focusMode;
    unifiedInContextSearchConfig[mbmConstants.MBM_SELECT_SEARCHSCOPE_CHECKBOX] = focusMode;
    unifiedInContextSearchConfig[mbmConstants.MBM_SHOW_SEARCHSCOPE_ON_LEAF_NODE] = focusMode;

    const objToUpdate = mbmUtils.getValueToUpdate(unifiedInContextSearchConfig, mbmConstants.MBM_UNIFIED_INCONTEXT_SEARCH_CONFIG);
    mbmUtils.updateValueToContext(occContext, objToUpdate);
}

/**
 * populates Checkout Data for mbom selections into an output object
 * @param {Boolean} selectedObjects array of selected objects in mbom
 * @param {String} currentUser current user uid
 * @returns {Promise} mbomCheckoutInfo object with populated data
 */
function populateCheckoutData(selectedObjects, currentUser) {
    let deferred = awPromiseSvc.instance.defer();
    let mbomCheckoutInfo = {
        allSelectionsCheckedOutByCurrentUser: false,
        anySelectionsCheckedOutByAnotherUser: false,
        checkoutInput: [],
        singleSelectionCheckoutInfo: {},
        anySelectionNotCheckoutByCurrentUser: false
    };
    let checkedOutByCurrentCount = 0;
    let underlyingObjectUidArray = [];
    selectedObjects.forEach(function (selectedObject) {
        underlyingObjectUidArray.push(selectedObject.props.awb0UnderlyingObject.dbValues[0]);
    });
    if (underlyingObjectUidArray && currentUser) {
        dataManagementService.getProperties(underlyingObjectUidArray, ['checked_out_user']).then(function (response) {
            underlyingObjectUidArray.forEach(function (underlyingObjectUid) {
                let underlyingObject = cdm.getObject(underlyingObjectUid);
                if (underlyingObject.props.checked_out_user.dbValues[0]) {
                    if (currentUser === underlyingObject.props.checked_out_user.dbValues[0]) {
                        checkedOutByCurrentCount++;
                    } else {
                        mbomCheckoutInfo.anySelectionsCheckedOutByAnotherUser = true;
                    }
                } else {
                    let props = underlyingObject.props;
                    if (props && (!props.date_released || props.date_released === undefined || props.date_released.dbValues && props.date_released.dbValues[0] === null)) {
                        mbomCheckoutInfo.checkoutInput.push(underlyingObject);
                    }
                }
            });
            if (selectedObjects.length === checkedOutByCurrentCount) {
                mbomCheckoutInfo.allSelectionsCheckedOutByCurrentUser = true;
            } else if (mbomCheckoutInfo.checkoutInput.length > 0) {
                mbomCheckoutInfo.anySelectionNotCheckoutByCurrentUser = true;
            } else if (selectedObjects.length === 1) {
                let underlyingObject = cdm.getObject(underlyingObjectUidArray[0]);
                mbomCheckoutInfo.singleSelectionCheckoutInfo = {
                    checkedOutUser: underlyingObject.props.checked_out_user.uiValues[0],
                    objectName: underlyingObject.props.object_string.uiValues[0]
                };
            }
            deferred.resolve(mbomCheckoutInfo);
        }).catch(function (error) {
            deferred.reject(error);
        });
    } else {
        deferred.resolve(mbomCheckoutInfo);
    }
    return deferred.promise;
}

/**
 *
 * @param {String} objectUidToFind  objectUidToFind
 * @param {Object} occContext occContext
 */
function findObject(objectUidToFind, occContext) {
    if ( objectUidToFind && objectUidToFind !== '' && occContext ) {
        if ( occContext.treeDataProvider ) { occContext.treeDataProvider.selectionModel.selectNone(); }   //deselect previously selected element
        let currentState = { ...mbmUtils.getContextValue(occContext, mbmConstants.MBM_CURRENT_STATE) };
        currentState.c_uid = objectUidToFind;
        let objToUpdate = mbmUtils.getValueToUpdate(currentState, mbmConstants.MBM_CURRENT_STATE);
        mbmUtils.updateValueToContext(occContext, objToUpdate);
    }
}

/**
 * update focus mode
 * @param {Object} mbmContext  mbmContext
 * @param {String} contextKey view key
 * @param {Array} selectedObjects selectedObjects
 */
function updateFocusMode(mbmContext, contextKey, selectedObjects) {
    let focusMode = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_FOCUS_MODE);
    let focusToUpdate = focusMode ? !focusMode : true;
    let selectedScope = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTED_SCOPE);
    if (selectedObjects) {
        if (selectedObjects.length === 0) {
            focusToUpdate = false;
            selectedScope[contextKey] = null;
        } else {
            focusToUpdate = true;
            selectedScope[contextKey] = selectedObjects;
        }
    } else {
        selectedScope[contextKey] = focusToUpdate ? mbmContext.selectedObjects[contextKey] : null;
    }

    let objToUpdate = mbmUtils.getValueToUpdate(selectedScope, mbmConstants.MBM_SELECTED_SCOPE);
    objToUpdate[mbmConstants.MBM_FOCUS_MODE] = focusToUpdate;
    objToUpdate[mbmConstants.MBM_ENABLE_FOCUS] = !focusMode;
    mbmUtils.updateValueToContext(mbmContext, objToUpdate);
}

/**
 * Update scopes and clears previous input
 * @param {Object} mbmContext  mbmContext
 * @param {String} contextKey view key
 * @param {Array} scopeLinesUIds scopeLines
 * @returns {Promise} promise object
 */
function updateScopeLineInContext(mbmContext, contextKey, scopeLinesUIds) {
    //update selected scope in context
    let selectedScopeToUpdate = [];
    let deferred = awPromiseSvc.instance.defer();
    if (!scopeLinesUIds && contextKey) {
        if (contextKey === mbmConstants.MBOM_CONTEXT) {
            scopeLinesUIds = mbmAlignmentInitializationService.getScopes(mbmConstants.MBM_SCOPE_INFO, AwStateService.instance.params.cc_uid);
        } else if (contextKey === mbmConstants.EBOM_CONTEXT) {
            scopeLinesUIds = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_EBOMFOCUSED_SCOPE_UID);
            scopeLinesUIds = scopeLinesUIds ? scopeLinesUIds[mbmConstants.EBOM_CONTEXT] : null;
        }
    }
    let selectedScope = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTED_SCOPE);
    if (scopeLinesUIds && scopeLinesUIds.length > 0) {
        dataManagementService.loadObjects(scopeLinesUIds).then(function () {
            scopeLinesUIds.forEach(function (uid) {
                selectedScopeToUpdate.push(cdm.getObject(uid));
            });
            selectedScope[contextKey] = selectedScopeToUpdate;
            let objToUpdate = mbmUtils.getValueToUpdate(selectedScope, mbmConstants.MBM_SELECTED_SCOPE);
            mbmUtils.updateValueToContext(mbmContext, objToUpdate);
            deferred.resolve();
        });
    } else {
        selectedScope[contextKey] = selectedScopeToUpdate;
        let objToUpdate = mbmUtils.getValueToUpdate(selectedScope, mbmConstants.MBM_SELECTED_SCOPE);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
        deferred.resolve();
    }
    updateScopeStatusInVmo(mbmContext, contextKey);
    return deferred.promise;
}
/**
 *
 * @param {*} mbmContext
 * @param {*} contextKey
 */
function updateScopeStatusInVmo(mbmContext, contextKey) {
    let selectedScope = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTED_SCOPE);
    let selectedScopeUids = selectedScope[contextKey] ? selectedScope[contextKey].map(obj => obj.uid) : [];
    let vmcs = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_VMC);
    let vmos = vmcs && vmcs[contextKey] ? vmcs[contextKey].getLoadedViewModelObjects() : [];
    vmos.forEach(function (vmo) {
        if (selectedScopeUids.indexOf(vmo.uid) > -1) {
            vmo.isScopeLineSelected = true;
        } else {
            vmo.isScopeLineSelected = false;
        }
    });
}
/**
 *
 * @param {Object} mbmContext  mbmContext
 * @param {String} contextKey  contextKey
 */
function updateFocusStatusInVmos(mbmContext, contextKey) {
    let focusMode = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_FOCUS_MODE);
    let vmcs = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_VMC);
    let vmos = vmcs && vmcs[contextKey] ? vmcs[contextKey].getLoadedViewModelObjects() : [];
    vmos.forEach(function (vmo) {
        vmo.isFocusMode = focusMode;
    });
}

/**
 * Updates grey lines in tree
 * @param {Object} mbmContext  mbmContext
 * @param {String} contextKey view context
 * @param {String} gridName gridName
 */
function updateGreyOutLineInContext(mbmContext, contextKey, gridName) {
    let ancestors = [];
    let selectedScopes = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTED_SCOPE);
    if (selectedScopes[contextKey]) {
        selectedScopes[contextKey].forEach(function (scope) {
            ancestors = ancestors.concat(getAncestorsOf(scope));
        });
    }

    let vmc = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_VMC);
    const vmTreeNodes = vmc[contextKey].getLoadedViewModelObjects();
    if (vmTreeNodes) {
        vmTreeNodes.forEach(function (vmtn) {
            if (ancestors.indexOf(vmtn.uid) > -1) {
                vmtn.isGreyedOutElement = true;
            } else {
                vmtn.isGreyedOutElement = false;
            }
        });
        eventBus.publish(gridName + '.plTable.clientRefresh');
    }
}

/**
 * Returns ancestors
 * @param {Object} scopeObject scopeObject
 * @returns {Array} ancestors
 */
function getAncestorsOf(scopeObject) {
    let parent = getParent(scopeObject);
    let ancestors = [];
    while (parent) {
        ancestors.push(parent.uid);
        parent = getParent(parent);
    }
    return ancestors;
}

/**
 * Returns parent
 *  @param {Object} modelObject modelObject
 * @returns {Object} parent
 */
function getParent(modelObject) {
    if (modelObject && modelObject.props.awb0Parent.dbValues && modelObject.props.awb0Parent.dbValues.length > 0) {
        let parentUid = modelObject.props.awb0Parent.dbValues[0];
        return cdm.getObject(parentUid);
    }

    return null;
}

/**
 * sets focus State
 * @param {Object} mbmContext mbmContext
 * @param {Object} occContext occContext
 */
function setFocusState(mbmContext, occContext) {
    const focusMode = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_FOCUS_MODE);
    if (focusMode) {
        let context = appContextService.getCtx(occContext.viewKey);
        context.recipe[0].criteriaDisplayValue = mbmConstants.MBM_FOCUS_CRITERIA_DISPLAY_VALUE;
        appContextService.updatePartialCtx(occContext.viewKey + '.recipe', context.recipe);
        let recipe = mbmUtils.getContextValue(occContext, 'recipe');
        recipe = context.recipe;
        let objToUpdate = mbmUtils.getValueToUpdate(recipe, 'recipe');
        mbmUtils.updateValueToContext(occContext, objToUpdate);
    }
}

/**
 * resets focus State
 */
function resetState() {
    let fullscreen = appContextService.getCtx('fullscreenDisabled');
    if (fullscreen) {
        appContextService.updatePartialCtx('fullscreenDisabled', false);
    }

    appContextService.updatePartialCtx(mbmConstants.MBOM_CONTEXT + '.' + mbmConstants.MBM_GREYOUT_LINE_SELECTED, false);
    appContextService.updatePartialCtx(mbmConstants.EBOM_CONTEXT + '.' + mbmConstants.MBM_GREYOUT_LINE_SELECTED, false);

    if (appContextService.getCtx(mbmConstants.MBM_FOCUS_MODE) || appContextService.getCtx(mbmConstants.MBM_ENABLE_FOCUS)) {
        appContextService.updatePartialCtx(mbmConstants.MBM_FOCUS_MODE, false);
        appContextService.updatePartialCtx(mbmConstants.MBM_ENABLE_FOCUS, false);
        let pageContext = appContextService.getCtx('epPageContext');
        if (pageContext && pageContext.hasOwnProperty('collaborationContext') && pageContext.collaborationContext.hasOwnProperty('uid')) {
            let ccUid = pageContext.collaborationContext.uid;
            mbmAlignmentInitializationService.removeScopes(mbmConstants.MBM_SCOPE_INFO, ccUid);
        }
    }
}

/**
 * Repopulates values for refresh in focus operation
 * @param {Object} mbmContext  mbmContext
 * @param {String} contextKey view key
 * @param {String} criteriaType criteria Type
 */
function initializeFocusData(mbmContext, contextKey, criteriaType) {
    if (appContextService.ctx.preferences.MBMEnableBOMFiltering[0] !== 'true') {
        mbmAlignmentInitializationService.removeScopes(mbmConstants.MBM_SCOPE_INFO, AwStateService.instance.params.cc_uid);
    }
    let selectedScope = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTED_SCOPE);
    let scopeModelObjects = selectedScope[mbmConstants.MBOM_CONTEXT];
    if (!scopeModelObjects) {
        let scopes = mbmAlignmentInitializationService.getScopes(mbmConstants.MBM_SCOPE_INFO, AwStateService.instance.params.cc_uid);
        if (scopes && scopes.length > 0) {
            scopeModelObjects = [];
            _.forEach(scopes, function (scopeUid) {
                scopeModelObjects.push(cdm.getObject(scopeUid));
            });
            selectedScope[mbmConstants.MBOM_CONTEXT] = scopeModelObjects;
            let objToUpdate = mbmUtils.getValueToUpdate(selectedScope, mbmConstants.MBM_SELECTED_SCOPE);
            objToUpdate[mbmConstants.MBM_FOCUS_MODE] = true;
            objToUpdate[mbmConstants.MBM_ENABLE_FOCUS] = true;
            mbmUtils.updateValueToContext(mbmContext, objToUpdate);
        }
    }

    if (scopeModelObjects && scopeModelObjects.length > 0) {
        const scopeUids = [];
        _.forEach(scopeModelObjects, function (scopeModelObject) {
            scopeUids.push(scopeModelObject.uid);
        });
        if (scopeModelObjects.length > 0) {
            appContextService.updatePartialCtx(contextKey + '.appliedFilters', getAppliedFilters());
            appContextService.updatePartialCtx(contextKey + '.recipe', getRecipe(criteriaType, scopeUids));
        }
    }
}

/**
 *
 * @param {Object} mbmContext mbmContext
 * @param {Object} focusInfo focusInfo
 */
function toggleFocusMode(mbmContext, focusInfo, taskBarContext) {
    const focusMode = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_FOCUS_MODE);
    if (focusMode) {
        focus(mbmContext, focusInfo);
    } else {
        let contextKeys = focusInfo.map(function (obj) { return obj.contextKey; });
        unFocus(mbmContext, contextKeys, taskBarContext);
    }
}
/**
 * Performs focus
 * @param {Object} mbmContext mbmContext
 * @param {Object} focusInfos focusInfo
 */
function focus(mbmContext, focusInfos) {
    const selectedScope = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTED_SCOPE);

    let isFocusOrUnfocusModeWith3DActivated = mbmUtils.getContextValue(mbmContext, mbmConstants.FOCUS_UNFOCUS_MODE_WITH_3D_ACTIVATED);
    isFocusOrUnfocusModeWith3DActivated.ebomContext = true;
    isFocusOrUnfocusModeWith3DActivated.mbomContext = true;
    const objToUpdate = mbmUtils.getValueToUpdate(isFocusOrUnfocusModeWith3DActivated, mbmConstants.FOCUS_UNFOCUS_MODE_WITH_3D_ACTIVATED);
    mbmUtils.updateValueToContext(mbmContext, objToUpdate);

    const selectedObjects = selectedScope ? selectedScope[mbmConstants.MBOM_CONTEXT] : [];
    if (selectedObjects && selectedObjects.length > 0 && focusInfos && focusInfos.length > 0) {
        const scopeUids = [];
        _.forEach(selectedObjects, function (selectedObject) {
            scopeUids.push(selectedObject.uid);
        });

        _.forEach(focusInfos, function (focusInfo) {
            mbm3DViewerService.hideViewerRootNode(focusInfo.contextKey);
            appContextService.updatePartialCtx(focusInfo.contextKey + '.appliedFilters', getAppliedFilters());
            appContextService.updatePartialCtx(focusInfo.contextKey + '.recipe', getRecipe(focusInfo.criteriaType, scopeUids));

            resetViewContext(focusInfo.contextKey, mbmContext);
        });

        const ccId = AwStateService.instance.params.cc_uid;
        mbmAlignmentInitializationService.setScopes(mbmConstants.MBM_SCOPE_INFO, { ccUid: ccId, scopeUids: scopeUids });
    }
}

/**
 * Performs un focus
 * @param {Object} mbmContext mbmContext
 * @param {Array} contextKeys view keys
 * @param {Object} taskBarContext taskBar Context
 */
function unFocus(mbmContext, contextKeys, taskBarContext) {
    mbmAlignmentInitializationService.removeScopes(mbmConstants.MBM_SCOPE_INFO, AwStateService.instance.params.cc_uid);

    let isFocusOrUnfocusModeWith3DActivated = mbmUtils.getContextValue(mbmContext, mbmConstants.FOCUS_UNFOCUS_MODE_WITH_3D_ACTIVATED);
    isFocusOrUnfocusModeWith3DActivated.ebomContext = true;
    isFocusOrUnfocusModeWith3DActivated.mbomContext = true;
    const objToUpdate2 = mbmUtils.getValueToUpdate(isFocusOrUnfocusModeWith3DActivated, mbmConstants.FOCUS_UNFOCUS_MODE_WITH_3D_ACTIVATED);
    mbmUtils.updateValueToContext(mbmContext, objToUpdate2);

    const selectedObjects = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTED_OBJECTS)[mbmConstants.MBOM_CONTEXT];
    let isFocusEnable = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_ENABLE_FOCUS);
    if (selectedObjects.length === 1 && selectedObjects[0].hasOwnProperty('props') && !selectedObjects[0].props.hasOwnProperty(
        'mbm0AssignmentDomain')) {
        isFocusEnable = false;
    }
    let objToUpdate = mbmUtils.getValueToUpdate(isFocusEnable, mbmConstants.MBM_ENABLE_FOCUS);
    mbmUtils.updateValueToContext(mbmContext, objToUpdate);
    mbmPartialCompareService.removePartialCompareInfo(taskBarContext);

    _.forEach(contextKeys, function (contextKey) {
        mbm3DViewerService.hideViewerRootNode(contextKey);
        appContextService.updatePartialCtx(contextKey + '.recipe', []);

        let greyoutLineStatus = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_GREYOUT_LINE_SELECTED);
        if (!greyoutLineStatus) {
            greyoutLineStatus = {};
        }
        greyoutLineStatus[contextKey] = false;
        let objToUpdate = mbmUtils.getValueToUpdate(greyoutLineStatus, mbmConstants.MBM_GREYOUT_LINE_SELECTED);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
        objToUpdate = mbmUtils.getValueToUpdate(greyoutLineStatus, mbmConstants.MBM_GREYOUT_ELEMENT_SELECTED);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
        resetViewContext(contextKey, mbmContext);
    });
}
/**
 * * resetViewContext
 *  @param {String} contextKey contextKeys
 *  @param {Object} mbmContext Object
 **/
function resetViewContext(contextKey, mbmContext) {
    let vmc = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_VMC);
    let selectionModel = mbmUtils.getContextValue(mbmContext, 'selectionModel')[contextKey];

    if (selectionModel) {
        const dp = selectionModel.getDpListener();
        let selectedObjs = dp.selectedObjects;
        if (selectedObjs && selectedObjs.length) {
            selectionModel.setSelection([vmc[contextKey].loadedVMObjects[0]]);
            selectionModel.setSelection([]);
        }
        setTimeout(function () {
            if (vmc) {
                vmc[contextKey].clear();
            }
            mbmUtils.clearFromContext(mbmContext, [mbmConstants.MBM_TOP_ELEMENT, mbmConstants.MBM_PRODUCT_CONTEXT_INFO, mbmConstants.MBM_VMC], contextKey);
            dp.resetDataProvider();
        }, 0);
    }
}

/**
 * Gives default value of applied filter
 * @returns {Object} Applied filter object
 */
function getAppliedFilters() {
    return {
        filterMap: {},
        filterCategories: []
    };
}

const criteriaTypes = {
    ebomContext: 'MbmShowScopedLinesInEbom',
    mbomContext: 'MbmShowScopedLinesInMbom'
};

/**
 * updates focus mode for 3D commands
 * @param {Object} mbmContext  mbmCntext
 * @param {Object} occContext occContext
 */
export function updateRecipe(mbmContext, occContext) {
    if (mbmContext.mbmFocusModeSelected) {
        const scopeUids = [];
        _.forEach(mbmContext.selectedScope.mbomContext, function (selectedObject) {
            scopeUids.push(selectedObject.uid);
        });
        let recipe = mbmUtils.getContextValue(occContext, 'recipe');
        recipe = getRecipe(criteriaTypes[occContext.viewKey], scopeUids);
        let objToUpdate = mbmUtils.getValueToUpdate(recipe, 'recipe');
        if( !_.isEmpty(occContext.recipe) ) {
            objToUpdate.recipe[0].criteriaDisplayValue = occContext.recipe[0].criteriaDisplayValue;
        }
        mbmUtils.updateValueToContext(occContext, objToUpdate);

        let appliedFilters = mbmUtils.getContextValue(occContext, 'appliedFilters');
        appliedFilters = getAppliedFilters();
        objToUpdate = mbmUtils.getValueToUpdate(appliedFilters, 'appliedFilters');
        mbmUtils.updateValueToContext(occContext, objToUpdate);
    } else if (!mbmContext.mbmFocusModeSelected) {
        let recipe = mbmUtils.getContextValue(occContext, 'recipe');
        recipe = [];
        let objToUpdate = mbmUtils.getValueToUpdate(recipe, 'recipe');
        mbmUtils.updateValueToContext(occContext, objToUpdate);
    }
}

/**
 * Gives Recipe
 * @param {Object} criteriaType scope flag
 * @param {Object} criteriaValues scope uids
 * @returns {Object} Recipe
 */
function getRecipe(criteriaType, criteriaValues) {
    return [{
        criteriaType: criteriaType,
        criteriaValues: criteriaValues,
        criteriaDisplayValue: mbmConstants.MBM_FOCUS_CRITERIA_DISPLAY_VALUE,
        criteriaOperatorType: '',
        subCriteria: ''
    }];
}

/**
 *
 * @param {Object} dataProvider dataProvider
 * @param {Object} columnProvider columnProvider
 * @returns {Promise} promise
 */
function loadTreeTableColumns(dataProvider, columnProvider) {
    let clientColumns = [];
    if (columnProvider && columnProvider.clientColumns) {
        columnProvider.clientColumns.forEach(function (clientColumn) {
            if (!clientColumn.hasOwnProperty('showColumn') || clientColumn.hasOwnProperty('showColumn') && (clientColumn.showColumn === 'true' || clientColumn.showColumn === true)) {
                clientColumns.push(clientColumn);
            }
        });
        columnProvider.clientColumns = clientColumns;
        columnProvider.frozenColumnIndex = clientColumns.length;
    }

    return occMgmtTableService.loadTreeTableColumns(dataProvider, columnProvider);
}

/**
 * sets selection mode for tree and viewer
 * @param {Object}pwaSelectionModel, pwaSelectionModel
 * @param {Boolean} multiSelectionEnable  multi Selection Enable flag
 */
function setSingleSelectMode(pwaSelectionModel, multiSelectionEnable) {
    if (pwaSelectionModel) {
        pwaSelectionModel.setMultiSelectionEnabled(multiSelectionEnable);
    }
}

/**
 *
 * @param {Object} mbmContext mbmContext
 * @param {Object} selectedObjects selectedObjects
 * @param {Object} contextKey contextKey
 */
function validateSelection(mbmContext, selectedObjects, contextKey, eventData) {
    if (eventData && eventData.objectsToSelect && eventData.objectsToSelect.length > 0 && eventData.objectsToHighlight) {
        _.forEach(eventData.objectsToHighlight, function (selectedObj) {
            if (eventData.objectsToSelect.findIndex(obj => obj.uid === selectedObj.uid) > -1) {
                return;
            }
        });
    }
    if (selectedObjects.length === 0 && eventData && eventData.objectsToSelect) {
        selectedObjects = eventData.objectsToSelect;
    }
    let focusMode = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_FOCUS_MODE);
    let greyoutElementSelected = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_GREYOUT_ELEMENT_SELECTED);
    let selectedObjectToUpdate = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTED_OBJECTS);
    let topElementSelected = false;

    let selectedObjectsWithPropsLoaded = [];

    _.forEach(selectedObjects, function (selectedObject) {
        if (!selectedObject.hasOwnProperty('props')) {
            let tempSelected = cdm.getObject(selectedObject.uid);
            selectedObjectsWithPropsLoaded.push(tempSelected);
        } else {
            selectedObjectsWithPropsLoaded.push(selectedObject);
        }
    });
    selectedObjects = selectedObjectsWithPropsLoaded;
    selectedObjectToUpdate[contextKey] = selectedObjects;
    let objToUpdate = mbmUtils.getValueToUpdate(selectedObjectToUpdate, mbmConstants.MBM_SELECTED_OBJECTS);
    mbmUtils.updateValueToContext(mbmContext, objToUpdate);

    if (focusMode) {
        greyoutElementSelected[contextKey] = isGreyoutElementSelected(selectedObjects);
        objToUpdate = mbmUtils.getValueToUpdate(greyoutElementSelected, mbmConstants.MBM_GREYOUT_ELEMENT_SELECTED);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
    }
    let selectedScope = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTED_SCOPE);
    let scopeElementSelected = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTION_CONTAINS_SCOPE_ELEMENT);
    scopeElementSelected[contextKey] = isScopeElementSelected(selectedObjects, selectedScope);
    objToUpdate = mbmUtils.getValueToUpdate(scopeElementSelected, mbmConstants.MBM_SELECTION_CONTAINS_SCOPE_ELEMENT);
    mbmUtils.updateValueToContext(mbmContext, objToUpdate);

    if (!focusMode && contextKey !== mbmConstants.EBOM_CONTEXT) {
        objToUpdate = mbmUtils.getValueToUpdate(false, mbmConstants.MBM_ENABLE_FOCUS);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
    }
    if (selectedObjects && selectedObjects.length > 0) {
        let topElementSelectedToUpdate = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTION_CONTAINS_TOP_ELEMENT);
        topElementSelected = isTopElementSelected(selectedObjects);
        topElementSelectedToUpdate[contextKey] = topElementSelected;
        objToUpdate[mbmConstants.MBM_SELECTION_CONTAINS_TOP_ELEMENT] = topElementSelectedToUpdate;
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
    }

    if (!topElementSelected && selectedObjects && selectedObjects.length > 0 && appContextService.ctx.preferences.MBMEnableBOMFiltering && appContextService.ctx.preferences.MBMEnableBOMFiltering[0] ===
        'true') {
        let uidArray = selectedObjects.map((modelObj) => modelObj.uid);
        let partSelected = false;
        let parentChildSelected = false;
        let vmoParent = null;
        selectedObjects.some(function (vmo) {
            if (vmo.hasOwnProperty('props')) {
                if (vmo.props.awb0NumberOfChildren && vmo.props.awb0NumberOfChildren.dbValues && vmo.props.awb0NumberOfChildren.dbValues[0] ===
                    '0') {
                    partSelected = true;
                    return true;
                } else if (vmo.props.awb0Parent && vmo.props.awb0Parent.dbValues[0]) {
                    vmoParent = getParent(vmo);
                    while (vmoParent) {
                        if (vmoParent.hasOwnProperty('props') && vmoParent.props.awb0Parent && vmoParent.props.awb0Parent.dbValues[0]) {
                            if (uidArray.indexOf(vmoParent.uid) > -1) {
                                parentChildSelected = true;
                                return true;
                            }
                        }
                        vmoParent = getParent(vmoParent);
                    }
                }
            }
            return false;
        });
        let containsPartSelected = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTION_CONTAINS_PART);
        containsPartSelected[contextKey] = partSelected;
        let parentChildSelectedToUpdate = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTION_CONTAINS_PARENT_AND_CHILD);
        parentChildSelectedToUpdate[contextKey] = parentChildSelected;

        let definedBomTypeElementSelectedToUpdate = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_SELECTION_CONTAINS_DEFINEBOM_TYPE);
        definedBomTypeElementSelectedToUpdate[contextKey] = isDefinedBomTypeElementSelected(selectedObjects);
        objToUpdate[mbmConstants.MBM_SELECTION_CONTAINS_PART] = containsPartSelected;
        objToUpdate[mbmConstants.MBM_SELECTION_CONTAINS_PARENT_AND_CHILD] = parentChildSelectedToUpdate;
        objToUpdate[mbmConstants.MBM_SELECTION_CONTAINS_DEFINEBOM_TYPE] = definedBomTypeElementSelectedToUpdate;
        if (isScopeExist(selectedObjects, contextKey) && contextKey !== mbmConstants.EBOM_CONTEXT) {
            objToUpdate[mbmConstants.MBM_ENABLE_FOCUS] = true;
        }
    }
    let selectionCount = 0;
    let asyncMode = mbmUtils.getContextValue(mbmContext, mbmConstants.ASYNCMODE);
    selectedObjects.forEach((selectedObject) => {
        if (selectedObject.props && selectedObject.props.awb0IsPacked && selectedObject.props.awb0IsPacked.dbValues[0] !== '0') {
            selectionCount = selectionCount + parseInt(selectedObject.props.awb0Quantity.dbValues[0]) - 1;
        }
    });

    selectionCount += selectedObjects.length;
    if (appContextService.ctx.preferences.MBMMinimumLimitForBackgoundMode && selectionCount >= parseInt(appContextService.ctx.preferences.MBMMinimumLimitForBackgoundMode[0])) {
        asyncMode[contextKey] = true;
    } else {
        asyncMode[contextKey] = false;
    }
    objToUpdate[mbmConstants.ASYNCMODE] = asyncMode;
    mbmUtils.updateValueToContext(mbmContext, objToUpdate);
}
/**
 *
 * @param {Array} selectedObjects selectedObjects
 * @param {String} contextKey contextKey
 * @returns {boolean} true if exist otherwise not
 */
function isScopeExist(selectedObjects, contextKey) {
    let isScopeExist = true;
    let isScopeSet = false;
    _.forEach(selectedObjects, function (selectedObject) {
        if (selectedObject.hasOwnProperty('props') && selectedObject.props.mbm0AssignmentDomain) {
            isScopeSet = selectedObject.props.mbm0AssignmentDomain.dbValues.length > 0;
        } else if (!selectedObject.hasOwnProperty('props')) {
            selectedObject = cdm.getObject(selectedObject.uid);
            if (selectedObject.hasOwnProperty('props') && selectedObject.props.mbm0AssignmentDomain) {
                isScopeSet = selectedObject.props.mbm0AssignmentDomain.dbValues.length > 0;
            }
        }
        if (!isScopeSet && contextKey !== mbmConstants.EBOM_CONTEXT) {
            isScopeExist = false;
        }
    });

    return isScopeExist;
}
/**
 *
 * @param {Array} selectedObjects  selectedObjects
 * @returns {boolean} true if geryout line selected otherwise false
 */
function isGreyoutElementSelected(selectedObjects) {
    let isGreyedOutElementSelected = false;
    if (selectedObjects && selectedObjects.length > 0) {
        selectedObjects.some(function (selectedObject) {
            if (selectedObject.isGreyedOutElement) {
                isGreyedOutElementSelected = true;
                return true;
            }
        });
    }

    return isGreyedOutElementSelected;
}
/**
 *
 * @param {Array} selectedObjects  selectedObjects
 * @returns {boolean} true if definedBomType element selected otherwise false
 */
function isDefinedBomTypeElementSelected(selectedObjects) {
    let isDefinedBomTypeElementSelected = false;
    let definedBomType = appContextService.getCtx(mbmConstants.PREFERENCES)[mbmConstants.MBM_ASSIGNMENT_DOMAIN_BOMTYPE_PREF];
    if (selectedObjects && selectedObjects.length > 0) {
        selectedObjects.some(selectedObject => {
            let underLyingType = selectedObject.props && selectedObject.props.awb0UnderlyingObjectType ? selectedObject.props.awb0UnderlyingObjectType.dbValues[0] : null;
            if (definedBomType.indexOf(underLyingType) > -1) {
                isDefinedBomTypeElementSelected = true;
                return true;
            }
        });
    }

    return isDefinedBomTypeElementSelected;
}

/**
 *
 * @param {Array} selectedObjects  selectedObjects
 * @param {Array}selectedScopes
 * @returns {boolean} true if top element selected otherwise false
 */
function isTopElementSelected(selectedObjects) {
    let isTopElementSelected = false;
    selectedObjects.some(function (vmo) {
        if (vmo.hasOwnProperty('props') && vmo.props.awb0Parent.dbValues[0] === null) {
            isTopElementSelected = true;
            return true;
        }
        return false;
    });
    return isTopElementSelected;
}

/**
 *
 * @param {Array} selectedObjects  selectedObjects
 * @param {Array}selectedScopes
 * @returns {boolean} true if scope element selected otherwise false
 */
function isScopeElementSelected(selectedObjects, selectedScopes) {
    let isScopeElementSelected = false;
    if (selectedObjects && selectedObjects.length > 0 && selectedScopes && selectedScopes.length > 0) {
        selectedObjects.some(function (selectedObject) {
            if (selectedScopes.indexOf(selectedObject.uid) > -1) {
                isScopeElementSelected = true;
                return true;
            }
            return false;
        });
    }

    return isScopeElementSelected;
}
/**
 * Select the MBOM node in the tree.
 * @param {*} mbomRootUid
 * @param {*} mbomNodeToSelect
 */
function selectMbomNode(mbomRootUid, mbomNodeToSelect) {
    const awPromise = awPromiseSvc.instance;
    let targetPci = appContextService.getCtx(mbmConstants.MBOM_CONTEXT + '.productContextInfo').uid;
    let sourcePciUid = appContextService.getCtx(mbmConstants.EBOM_CONTEXT + '.productContextInfo').uid;

    const addLoadParams = [{
        tagName: 'currentScope',
        attributeName: 'objectUid',
        attributeValue: ''
    },
    {
        tagName: 'contextObject',
        attributeName: 'objectUid',
        attributeValue: mbomRootUid
    },
    {
        tagName: 'nodesToFind',
        attributeName: 'objectUid',
        attributeValue: mbomNodeToSelect
    },
    {
        tagName: 'searchType',
        attributeName: 'type',
        attributeValue: ''
    }
    ];
    const loadTypeInputs = epLoadInputHelper.getLoadTypeInputs(_epLoadConstants.FIND_NODE_IN_CONTEXT, '', '', '', addLoadParams);
    let jsonInput = epLoadInputHelper.getLoadInputJSON(loadTypeInputs);
    let session = jsonInput.sections.find(function (obj) { return obj.sectionName === 'session'; });
    session.dataEntries[0].entry.mbomPCI = {
        nameToValuesMap: {
            uid: [targetPci]
        }
    };
    session.dataEntries[0].entry.ebomPCI = {
        nameToValuesMap: {
            uid: [sourcePciUid]
        }
    };
    const promise = soaService.postUnchecked('Internal-MfgBvrCore-2015-03-DataManagement', 'loadObjectData3', jsonInput);
    return promise.then(function (response) {
        if (response && response.relatedObjectsMap) {
            let foundNodes = response.relatedObjectsMap[mbomNodeToSelect].additionalPropertiesMap2.foundNodes;
            if (foundNodes && foundNodes.length > 0) {
                let eventData = {
                    elementsToSelect: foundNodes[0],
                    viewToReact: mbmConstants.MBOM_CONTEXT
                };
                eventBus.publish('aceElementsSelectedEvent', eventData);
            }
        }
        return awPromise.resolve();
    });
}

/**
 * To update mbomNodeToSelect
 * @param {String} objectUid mbmContext
 */
function getAwbElement(objectUid) {
    const loadType = ['GetAwbElements'];
    const loadTypeInputs = epLoadInputHelper.getLoadTypeInputs(loadType, [objectUid]);
    return epLoadService.loadObject(loadTypeInputs, false).then(function (response) {
        if (response.relatedObjectsMap) {
            return response.relatedObjectsMap[objectUid].additionalPropertiesMap2.mbc0awbElement[0];
        }
    });
}

/**
 * Array of ebom scope uid is set on context
 * @param {Object} mbmContext mbmContext
 * @param {Array} scopeUids ebom scope uid array
 */
function setEbomScopeUidsInContext(mbmContext, scopeUids) {
    if (scopeUids && scopeUids.length > 0) {
        let scopeUidsToUpdate = mbmContext.getValue()[mbmConstants.MBM_EBOMFOCUSED_SCOPE_UID];
        scopeUidsToUpdate[mbmConstants.EBOM_CONTEXT] = scopeUids;
        let objToUpdate = mbmUtils.getValueToUpdate(scopeUidsToUpdate, mbmConstants.MBM_EBOMFOCUSED_SCOPE_UID);
        mbmUtils.updateValueToContext(mbmContext, objToUpdate);
    }
}
/**
 *
 * @param {*} mbmContext
 * @param {*} contextKey
 * @param {*} actionType
 * @param {*} indicationData
 * @param {*} removedObjectUids
 */
function updateStatusOnSave(mbmContext, contextKey, actionType, indicationData, removedObjectUids, activePartialCompareView) {
    if (contextKey === mbmConstants.MBOM_CONTEXT && removedObjectUids && (actionType === mbmConstants.REMOVE || actionType === mbmConstants.MOVE || actionType === mbmConstants.PROPAGATE)) {
        let vmos = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_VMC)[contextKey].getLoadedViewModelObjects();
        removedObjectUids.forEach(obj => {
            vmos.forEach(vmo => {
                if (obj.id === vmo.uid) {
                    delete vmo.compareStatus;
                    delete vmo.targetInfo;
                }
            });
        });
    }

    if ((!activePartialCompareView || activePartialCompareView && activePartialCompareView === contextKey) && indicationData) {
        let vmos = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_VMC)[contextKey].getLoadedViewModelObjects();
        indicationData.forEach(obj => {
            vmos.forEach(vmo => {
                if (obj.id === vmo.uid) {
                    vmo.compareStatus = obj.status || null;
                    vmo.targetInfo = obj.equiLines ? obj.equiLines.map(uid => { return { uid: uid }; }) : null;
                }
            });
        });
    }
}
/**
 *@param {Object} mbmContext mbmContext
 * @param {String} contextKey view key
 * @param {boolean} progress true if progress otherwise false
 */
function updatePackUnpacElementProgress(mbmContext, contextKey, progress) {
    let context = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_PACK_UNPACK_PROGRESS);
    if (!context) {
        context = {};
    }
    context[contextKey] = progress;
    const objToUpdate = mbmUtils.getValueToUpdate(context, mbmConstants.MBM_PACK_UNPACK_PROGRESS);
    mbmUtils.updateValueToContext(mbmContext, objToUpdate);
}

/**
 *
 * @param {*} mbmContext
 * @param {*} contextKeys
 */
function clearStatusFromNode(mbmContext, contextKeys) {
    contextKeys.forEach(contextKey => {
        if( !mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_VMC ) ) {
            return;
        }
        let vmos = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_VMC)[contextKey].getLoadedViewModelObjects();
        vmos.forEach(vmo => {
            delete vmo.compareStatus;
            delete vmo.targetInfo;
        });
    });
}

/**
 *
 * @param {*} mbmContext mbmContext
 * @param {boolean} isExcelImortProgress isExcelImortProgress
 */
function updateMbmExcelImportDataFlag(mbmContext, isExcelImortProgress) {
    let objToUpdate = mbmUtils.getContextValue(mbmContext, mbmConstants.MBM_IMPORT_EXCEL);
    objToUpdate.importInProgress = isExcelImortProgress;
    mbmUtils.updateValueToContext(mbmContext, objToUpdate);
}

/**
 *
 *@param {Object} sourceObjects selected line object uid in ebom
 *@param {String} actionType specifies action Update
 *@param {Boolean} async boolean value true or false for calling async savedata3
 */
function updateEquivalentAssignmentDomains(sourceObjects, actionType, async, mbmContext) {
    let deferred = awPromiseSvc.instance.defer();
    let sortPropertyName;
    let sortOrder;
    let mfgReps;
    let aceActiveCtx = appContextService.getCtx(mbmConstants.MBM_ACE_ACTIVE_CONTEXT);
    if (aceActiveCtx.context.hasOwnProperty('sortCriteria') && aceActiveCtx.context.sortCriteria.length > 0) {
        sortPropertyName = aceActiveCtx.context.sortCriteria[0].fieldName;
        sortOrder = aceActiveCtx.context.sortCriteria[0].sortDirection;
    } else {
        sortPropertyName = '';
        sortOrder = '';
    }
    mbmSaveService.saveChanges(mbmContext, actionType, sourceObjects, '', mbmConstants.EBOM_CONTEXT, mbmConstants.MBOM_CONTEXT, ['false'], [sortPropertyName], [sortOrder], mfgReps, async).then(
        function (result) {
            if (result.serviceData.hasOwnProperty('partialErrors') && result.serviceData.partialErrors.length > 0 && result.newElements.length === 0) {
                let err = messagingService.getSOAErrorMessage(result.serviceData);
                const mbmMsgs = localeService.getLoadedText('mbmMessages');
                let buttonArray = [];
                buttonArray.push(mfgNotificationUtils.createButton(mbmMsgs.mbmClose, function (callBack) {
                    callBack.close();
                }));
                messagingService.showError(err, null, null, buttonArray);

                deferred.reject();
            } else {
                deferred.resolve();
            }
        });
    return deferred.promise;
}

/**
 * * Returns bomlines of selected objects
 * * @param {Array} selectedObjects array of selected objects in mbom
 **/

function getBomlineOfSelectedObjects(selectedObjects) {
    let deferred = awPromiseSvc.instance.defer();
    occmgmtBackingObjectProviderService.getBackingObjects(selectedObjects).then(function (response) {
        return deferred.resolve(response);
    });
    return deferred.promise;
}

/*
 * *  Checks if there's a single greyed out object, if true returns flag isSingleGreyedOutElementObject
 * *  @param {Array} selectedObjects array of selected objects in mbom
 **/

function checkGreyedOutElements(selectedObjects) {
    const initData = {};
    if (selectedObjects.some(obj => obj.isGreyedOutElement === true)) {
        if (selectedObjects.length > 1) {
            initData.singleGreyedOutObject = false;
            initData.selectedObjects = selectedObjects.filter(obj => obj.isGreyedOutElement === false);
            return initData;
        }
        initData.singleGreyedOutObject = true;
        initData.selectedObjects = selectedObjects;
        return initData;
    }
    initData.singleGreyedOutObject = false;
    initData.selectedObjects = selectedObjects;
    return initData;
}

/**
 * * Sets the value of ObjectSetUri for Arrange Panel CommandContext
 * * @param {String} ClientScopeUri from the props
 **/

function setObjectSetUriInProvider(clientScopeUri) {
    const initData = {};
    initData.objectSetUri = clientScopeUri;
    return initData;
}

/**
 *
 * @param {Object} notificationObject Notification object.
 */
function openMbmNotification(notificationObject) {
    if (notificationObject.object.props && notificationObject.object.props.fnd0TargetObject) {
        let srcUidToken = notificationObject.object.props.fnd0TargetObject.dbValues[0];
        const loadType = [ _epLoadConstants.CC, _epLoadConstants.HEADER ];
        const additionalLoadParams = [];

        const loadTypeInputs = epLoadInputHelper.getLoadTypeInputs( loadType, srcUidToken, null, null, additionalLoadParams );
        return epLoadService.loadObject( loadTypeInputs, true ).then( ( response ) => {
            let transitionTo = 'multiBOMManager';
            let toParams = {
                cc_uid: srcUidToken,
                tracking_cn: null,
                impacting_cn: null,
                ebomPCI: response.loadedObjectsMap.ebomPCI[0].uid,
                mbomPCI: response.loadedObjectsMap.mbomPCI[0].uid
            };
            let options = {};
            options.reload = true;
            locationNavigationService.instance.go(transitionTo, toParams, options);
        });
    }
}
/**
 * * refreshDataProvider
 *  @param {String} contextKey contextKeys
 *  @param {Object} mbmContext Object
 **/

export const resetDataProvider = (contextKey, mbmContext) => {
    let selectionModel = mbmUtils.getContextValue(mbmContext, 'selectionModel')[contextKey];
    let dataProvider = selectionModel.getDpListener();
    dataProvider.resetDataProvider();
};
/**
 *
 * @param {Object} taskBarContext taskBar Context
 * @param {Boolean} toggleState taskBarContext
 */
function updateToggleStateAfterCancelCheckout(taskBarContext, toggleState) {
    let mbmOnDemandCompareInfo = mbmUtils.getContextValue(taskBarContext, mbmConstants.MBM_ONDEMAND_COMPARE);
    mbmOnDemandCompareInfo.OnDemandCompareSelected = toggleState;
    const objToUpdate = mbmUtils.getValueToUpdate(mbmOnDemandCompareInfo, mbmConstants.MBM_ONDEMAND_COMPARE);
    mbmUtils.updateValueToContext(taskBarContext, objToUpdate);
    mbmOnDemandCompareService.setCompareToggleSelectionToLocalStorage(mbmOnDemandCompareInfo.OnDemandCompareSelected);
    eventBus.publish('mbm.updateChangeSummary');
    eventBus.publish('mbm.assignmentStateToggleChanged', {
        taskBarContext: taskBarContext
    });
}
/**
 *
 *@param {Object} selectedObjects selected line object  in mbom
 */
function mbmCheckIfReleaseNodeSelected(selectedObjects) {
    let mbmSelectedInfo = {
        isAnyNonreleaseNodeSelected: [],
        isAnyReleaseNodeSelected: []
    };
    if (selectedObjects.length > 0) {
        selectedObjects.forEach(function (selectedObject) {
            if (selectedObject.props && selectedObject.props.awb0ArchetypeRevRelStatus) {
                if (selectedObject.props.awb0ArchetypeRevRelStatus.dbValues.length > 0) {
                    mbmSelectedInfo.isAnyReleaseNodeSelected.push(selectedObject);
                } else {
                    mbmSelectedInfo.isAnyNonreleaseNodeSelected.push(selectedObject);
                }
            }
        });
    }
    return mbmSelectedInfo;
}
/**
 * Checks if the SelectedObjects has its Parent released, if released sets the value of isParentReleased Flag as true
 * @param {array} selectedObjects  Collection of selected objects from the MBOM tree Table
 * @param {object} mbomContext AtomicData Object
 *
 **/
function checkParentReleaseStatus(selectedObjects, mbomContext) {
    let updatedObjects = selectedObjects.map(obj => {
        if (obj.props !== undefined && obj.parentUid !== null) {
            let parentElement = cdm.getObject(obj.props.awb0Parent.dbValues[0]);
            if (parentElement.props.awb0ArchetypeRevRelStatus.uiValues.length === 0) {
                obj.isParentReleased = false;
                return obj;
            }
            obj.isParentReleased = true;
            return obj;
        }
        return obj;
    });
    mbmUtils.updateValueToContext(mbomContext, { mbomContext: updatedObjects });
}

/**
 * Filters selection object to remove the GreyedOut elements & Top Element for Variant Formula Popup
 * @param {array} selectedObjects Collection of selected objects from Command Context
 * @param {object} commandContext Command Context Object
 * @returns {object} commandContext.selection
 **/
function getFilteredSelectionForVariantFormula(selectedObjects, commandContext) {
    if (commandContext.mbmContext.mbmFocusModeSelected === true) {
        let nonGreyedOutObjects = selectedObjects.filter(obj => obj.isGreyedOutElement === false);
        return nonGreyedOutObjects.filter(obj => obj.parentUid !== null && obj.isParentReleased !== true);
    }
    return selectedObjects.filter(obj => obj.parentUid !== null && obj.isParentReleased !== true);
}

/**
 * Function to get loadIds
 */
function getTreeloadInputForInitializeMbomDada(treeloadInput, c_uid, o_uid) {
    let treeLoadInputObj = {
        treeloadInput: treeloadInput,
        loadIds: null
    };
    if (c_uid && o_uid) {
        treeLoadInputObj.loadIds = {
            c_uid: c_uid,
            o_uid: o_uid
        };
    }
    return treeLoadInputObj;
}
/**
 * This function is used to clear Criteria Value from the recipe after focus action
 *
 * @param {object} occContext occContext object
 */
function clearCriteriaValueAfterFocusAction(occContext){
    let objToUpdate = mbmUtils.getContextValue(occContext, 'recipe');
    if( !objToUpdate[0] ){
        return;
    }
    objToUpdate[0].criteriaDisplayValue = "";
    mbmUtils.updateValueToContext(occContext, objToUpdate);
}
export default {
    updateContextData,
    loadTreeTableColumns,
    updateFocusMode,
    updateStatusOnSave,
    setSingleSelectMode,
    toggleFocusMode,
    focus,
    unFocus,
    resetState,
    initializeFocusData,
    validateSelection,
    updateScopeLineInContext,
    updateFocusStatusInVmos,
    updateGreyOutLineInContext,
    setFocusState,
    selectMbomNode,
    setEbomScopeUidsInContext,
    updatePackUnpacElementProgress,
    findObject,
    updateUnifiedInContextSearchConfig,
    populateCheckoutData,
    clearStatusFromNode,
    updateMbmExcelImportDataFlag,
    updateEquivalentAssignmentDomains,
    getBomlineOfSelectedObjects,
    checkGreyedOutElements,
    setObjectSetUriInProvider,
    openMbmNotification,
    mbmCheckIfReleaseNodeSelected,
    resetViewContext,
    resetDataProvider,
    updateToggleStateAfterCancelCheckout,
    getAwbElement,
    checkParentReleaseStatus,
    getTreeloadInputForInitializeMbomDada,
    getFilteredSelectionForVariantFormula,
    updateRecipe,
    clearCriteriaValueAfterFocusAction
};
