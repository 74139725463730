// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwBaseSublocation from 'viewmodel/AwBaseSublocationViewModel';
import AwFlexColumn from 'viewmodel/AwFlexColumnViewModel';
import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwLabel from 'viewmodel/AwLabelViewModel';
import AwSplitter from 'viewmodel/AwSplitterViewModel';
import AwFullScreen from 'viewmodel/AwFullScreenViewModel';
    import { VisibleWhen } from 'js/hocCollection';
const DivVisibleWhen = VisibleWhen('div');
DivVisibleWhen.displayName = 'DivVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/awGraphService', () => import('js/awGraphService'));
registerDynImportEntry('js/viewerAdminService', () => import('js/viewerAdminService'));
registerDynImportEntry('js/viewerAdminGraph', () => import('js/viewerAdminGraph'));
registerDynImportEntry('js/viewerAdminHealth', () => import('js/viewerAdminHealth'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "actions": {
        "clearGraph": {
            "actionType": "JSFunction",
            "method": "clearGraph",
            "inputData": {
                "graphModel": "{{data.graphModel}}"
            },
            "outputData": {},
            "deps": "js/awGraphService"
        },
        "vaGraphInitialized": {
            "actionType": "JSFunction",
            "method": "vaGraphInitialized",
            "inputData": {
                "data": "{{data}}"
            },
            "outputData": {},
            "deps": "js/viewerAdminService"
        },
        "updateSelection": {
            "actionType": "JSFunction",
            "method": "updateSelection",
            "inputData": {
                "selectedNodes": "{{data.eventMap['awGraph.selectionChanged'].selected}}",
                "data": "{{data}}"
            },
            "deps": "js/viewerAdminService"
        },
        "showHealthInfo": {
            "actionType": "JSFunction",
            "method": "showHealthInfo",
            "inputData": {
                "data": "{{data}}"
            },
            "deps": "js/viewerAdminService"
        },
        "updateLayoutOnNodeMovement": {
            "actionType": "JSFunction",
            "method": "updateLayoutOnNodeMovement",
            "inputData": {
                "data": "{{data}}",
                "items": "{{data.eventMap['awGraph.graphItemsMoved'].items}}"
            },
            "deps": "js/viewerAdminGraph"
        },
        "parseHealthData": {
            "actionType": "JSFunction",
            "method": "parseHealthData",
            "inputData": {
                "data": "{{data}}"
            },
            "deps": "js/viewerAdminHealth"
        },
        "fetchHealthInfo": {
            "actionType": "JSFunction",
            "method": "fetchHealthInfo",
            "inputData": {
                "data": "{{data}}"
            },
            "deps": "js/viewerAdminService"
        },
        "resetVAPanel": {
            "actionType": "JSFunction",
            "method": "resetVAPanel",
            "inputData": {
                "data": "{{data}}"
            },
            "deps": "js/viewerAdminService"
        }
    },
    "onEvent": [
        {
            "eventId": "viewerAdmin.HealthDataParsed",
            "action": "showHealthInfo"
        },
        {
            "eventId": "awGraph.initialized",
            "action": "vaGraphInitialized"
        },
        {
            "eventId": "awGraph.selectionChanged",
            "action": "updateSelection",
            "cacheEventData": true
        },
        {
            "eventId": "viewerAdmin.healthInfoAvailable",
            "action": "parseHealthData"
        },
        {
            "eventId": "viewerAdmin.contentLoaded",
            "action": "fetchHealthInfo"
        },
        {
            "eventId": "viewerAdmin.HealthData",
            "action": "resetVAPanel"
        },
        {
            "eventId": "awGraph.graphItemsMoved",
            "action": "updateLayoutOnNodeMovement",
            "cacheEventData": true
        },
        {
            "eventId": "viewerAdmin.clearGraph",
            "action": "clearGraph"
        }
    ],
    "data": {
        "serverPoolAssigners": {
            "displayName": "{{i18n.serverPoolAssignersText}}",
            "type": "INTEGER"
        },
        "serverManagers": {
            "displayName": "{{i18n.serverManagersText}}",
            "type": "STRING"
        },
        "visProcesses": {
            "displayName": "{{i18n.visProcessesText}}",
            "type": "STRING"
        },
        "clients": {
            "displayName": "{{i18n.clientsText}}",
            "type": "INTEGER"
        },
        "graphModel": {
            "inputModes": {
                "viewInputMode": {
                    "editMode": false,
                    "enableManualEdgeRouting": false,
                    "enableNodeResize": false,
                    "enableLabelEdit": false,
                    "dragableCursorUrl": "cursor/openhand.cur",
                    "draggingCursorUrl": "cursor/closedhand.cur"
                }
            },
            "config": {
                "minZoom": 0,
                "maxZoom": 1,
                "autoEdgeRoutingType": "HV_SEGMENT3",
                "tooltipDelayTime": 500,
                "tooltipOffset": {
                    "x": 0,
                    "y": 20
                },
                "enableJumper": true,
                "jumper": {
                    "jumperType": "ARC",
                    "jumperSize": {
                        "width": 10,
                        "height": 10
                    },
                    "jumperPriority": "HORIZONTAL"
                },
                "movableItems": [
                    "Node"
                ],
                "selectableItems": [
                    "Node"
                ],
                "layout": {
                    "layoutMode": "IncUpdateLayout",
                    "defaultOption": "TopToBottom",
                    "options": [
                        "TopToBottom",
                        "LeftToRight",
                        "RightToLeft",
                        "BottomToTop",
                        "Balloon",
                        "Snake"
                    ],
                    "config": {
                        "nodeToNodeDistance": {
                            "x": 45,
                            "y": 45
                        },
                        "edgeToEdgeDistance": {
                            "x": 15,
                            "y": 15
                        },
                        "portSize": {
                            "width": 0,
                            "height": 0
                        },
                        "minSegmentLength": 15,
                        "enableRSOP": true,
                        "makeRoomInRSOPDisable": true,
                        "snakeWrapLength": 500
                    }
                },
                "inputMode": "viewInputMode",
                "moveWithoutSelection": false,
                "enableAutoPan": true,
                "enableMultipleSelection": false,
                "showOverview": true,
                "defaults": {
                    "nodePaddings": {
                        "top": 50,
                        "bottom": 50,
                        "left": 50,
                        "right": 50
                    },
                    "resizeMinimumSize": {
                        "width": 150,
                        "height": 125
                    },
                    "nodeStyle": {
                        "templateId": "overviewTemplateId"
                    },
                    "edgeStyle": {
                        "dashStyle": "SOLID",
                        "thickness": 2,
                        "color": "(170,170,150)",
                        "targetArrow": {
                            "arrowShape": "SIMPLE",
                            "arrowScale": "1.0"
                        }
                    }
                }
            }
        }
    },
    "conditions": {
        "isLoadInProgress": {
            "expression": "ctx.viewerAdmin.isLoadInProgress"
        }
    },
    "i18n": {
        "viewerSubLocation": [
            "ViewerAdminMessages"
        ],
        "Refresh": [
            "ViewerAdminMessages"
        ],
        "viewerAdminDisplayName": [
            "ViewerAdminMessages"
        ],
        "Info": [
            "ViewerAdminMessages"
        ],
        "serverPoolAssignersText": [
            "ViewerAdminMessages"
        ],
        "serverManagersText": [
            "ViewerAdminMessages"
        ],
        "visProcessesText": [
            "ViewerAdminMessages"
        ],
        "clientsText": [
            "ViewerAdminMessages"
        ],
        "vaExpandUp": [
            "ViewerAdminMessages"
        ],
        "vaExpandDown": [
            "ViewerAdminMessages"
        ],
        "noViewerHealth": [
            "ViewerAdminMessages"
        ],
        "ViewerAdminTitle": [
            "ViewerAdminMessages"
        ],
        "ViewerServerHealth": [
            "ViewerAdminMessages"
        ],
        "NoViewerHealth": [
            "ViewerAdminMessages"
        ],
        "zero": [
            "ViewerAdminMessages"
        ],
        "healthInfo ": [
            "ViewerAdminMessages"
        ],
        "healthInfoAssigners": [
            "ViewerAdminMessages"
        ],
        "healthInfoPoolManagers": [
            "ViewerAdminMessages"
        ],
        "healthInfoVisProcesses": [
            "ViewerAdminMessages"
        ],
        "healthInfoClients": [
            "ViewerAdminMessages"
        ],
        "healthInfoVisProcess": [
            "ViewerAdminMessages"
        ],
        "healthInfoClient": [
            "ViewerAdminMessages"
        ],
        "healthInfoAssigner": [
            "ViewerAdminMessages"
        ],
        "healthInfoServerManager": [
            "ViewerAdminMessages"
        ],
        "adminToolPanelTitle": [
            "ViewerAdminMessages"
        ],
        "CommandTitle": [
            "ViewerAdminMessages"
        ],
        "FailedToLoadGraph": [
            "ViewerAdminMessages"
        ],
        "nodeType": [
            "ViewerAdminMessages"
        ],
        "loadingGraph": [
            "ViewerAdminMessages"
        ],
        "loadingHealth": [
            "ViewerAdminMessages"
        ],
        "refresh": [
            "ViewerAdminMessages"
        ],
        "togglePoolManager": [
            "ViewerAdminMessages"
        ],
        "clientIPAddress": [
            "ViewerAdminMessages"
        ],
        "sessionID": [
            "ViewerAdminMessages"
        ],
        "clientID": [
            "ViewerAdminMessages"
        ],
        "host": [
            "ViewerAdminMessages"
        ],
        "hostName": [
            "ViewerAdminMessages"
        ],
        "cpuUsageRatio": [
            "ViewerAdminMessages"
        ],
        "gpuUsageRatio": [
            "ViewerAdminMessages"
        ],
        "memoryConsumptionRatio": [
            "ViewerAdminMessages"
        ],
        "prefers": [
            "ViewerAdminMessages"
        ],
        "serves": [
            "ViewerAdminMessages"
        ],
        "bandwidthBytes": [
            "ViewerAdminMessages"
        ],
        "bytesReceivedPerSec": [
            "ViewerAdminMessages"
        ],
        "bytesSentAndReceivedPerSec": [
            "ViewerAdminMessages"
        ],
        "bytesSentPerSec": [
            "ViewerAdminMessages"
        ],
        "totalMemoryMB": [
            "ViewerAdminMessages"
        ],
        "numGPUs": [
            "ViewerAdminMessages"
        ],
        "accepting": [
            "ViewerAdminMessages"
        ],
        "msSinceEMM": [
            "ViewerAdminMessages"
        ],
        "totalEmms": [
            "ViewerAdminMessages"
        ],
        "totalReceivedBytes": [
            "ViewerAdminMessages"
        ],
        "totalSentBytes": [
            "ViewerAdminMessages"
        ],
        "port": [
            "ViewerAdminMessages"
        ],
        "numClients": [
            "ViewerAdminMessages"
        ],
        "numConnections": [
            "ViewerAdminMessages"
        ],
        "computerNumGPUs": [
            "ViewerAdminMessages"
        ],
        "processCPUUsageRatio": [
            "ViewerAdminMessages"
        ],
        "processMemoryConsumptionRatio": [
            "ViewerAdminMessages"
        ],
        "processAssignedGPUIndex": [
            "ViewerAdminMessages"
        ],
        "poolName": [
            "ViewerAdminMessages"
        ],
        "models": [
            "ViewerAdminMessages"
        ],
        "numEMMs": [
            "ViewerAdminMessages"
        ],
        "totalGPUMemory": [
            "ViewerAdminMessages"
        ],
        "maxBandwidthBytes": [
            "ViewerAdminMessages"
        ],
        "upTimeSecs": [
            "ViewerAdminMessages"
        ],
        "startupDate": [
            "ViewerAdminMessages"
        ],
        "numAssignsSinceStart": [
            "ViewerAdminMessages"
        ],
        "totalMemPerGPU": [
            "ViewerAdminMessages"
        ],
        "dateCreated": [
            "ViewerAdminMessages"
        ]
    },
    "_viewModelId": "viewerAdmin",
    "_uniqueViewModelId": "viewerAdmin",
    "ctx": {
        "viewerAdmin": {
            "type": "object"
        }
    }
};

    /*
    <aw-base-sublocation provider="summary">
    <aw-flex-column width="3">
        <aw-panel>
            <aw-panel-body>
                <aw-panel-section caption="i18n.Info">
                    <aw-label prop="data.serverPoolAssigners"></aw-label>
                    <aw-label prop="data.serverManagers"></aw-label>
                    <aw-label prop="data.visProcesses"></aw-label>
                    <aw-label prop="data.clients"></aw-label>
                </aw-panel-section>
                <div class="aw-viewerAdmin-healthLoadProgress" visible-when="conditions.isLoadInProgress"></div>
            </aw-panel-body>
        </aw-panel>
    </aw-flex-column>
    <aw-splitter></aw-splitter>
    <aw-flex-column width="fill">
        <aw-full-screen include-component-name='viewerAdminContent' header-title='{{i18n.viewerAdminDisplayName}}' header-path='{{subPanelContext.activeTab.name ? subPanelContext.activeTab.name : subPanelContext.showObjectContext.activeTab.name}}' header-context-title='{{subPanelContext.selected.props.object_string.uiValues[0]}}' context='{ graphModel: data.graphModel }'>
        </aw-full-screen>
    </aw-flex-column>
</aw-base-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwBaseSublocation provider={AwParseService.instance( "summary" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <AwFlexColumn width="3">
            <AwPanel  revealAction={actions.reveal}>
                <AwPanelBody>
                    <AwPanelSection caption={i18n.Info}>
                        <AwLabel {...getField("data.serverPoolAssigners", fields, $index, null, null )}>
                        </AwLabel>
                        <AwLabel {...getField("data.serverManagers", fields, $index, null, null )}>
                        </AwLabel>
                        <AwLabel {...getField("data.visProcesses", fields, $index, null, null )}>
                        </AwLabel>
                        <AwLabel {...getField("data.clients", fields, $index, null, null )}>
                        </AwLabel>
                    </AwPanelSection>
                    <DivVisibleWhen className="aw-viewerAdmin-healthLoadProgress" visibleWhen={AwParseService.instance( "conditions.isLoadInProgress" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </DivVisibleWhen>
                </AwPanelBody>
            </AwPanel>
        </AwFlexColumn>
        <AwSplitter>
        </AwSplitter>
        <AwFlexColumn width="fill">
            <AwFullScreen includeComponentName="viewerAdminContent" headerTitle={AwParseService.instance( "i18n.viewerAdminDisplayName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} headerPath={AwParseService.instance( "subPanelContext.activeTab.name ? subPanelContext.activeTab.name : subPanelContext.showObjectContext.activeTab.name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} headerContextTitle={AwParseService.instance( "subPanelContext.selected.props.object_string.uiValues[0]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} context={AwParseService.instance( "{ graphModel: data.graphModel }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwFullScreen>
        </AwFlexColumn>
    </AwBaseSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;