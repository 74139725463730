// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwScrollpanel from 'viewmodel/AwScrollpanelViewModel';
import AwToolbar from 'viewmodel/AwToolbarViewModel';
import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import AwWidget from 'viewmodel/AwWidgetViewModel';
import AwFullScreen from 'viewmodel/AwFullScreenViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwSplmTable from 'viewmodel/AwSplmTableViewModel';
import AwLink from 'viewmodel/AwLinkViewModel';
    import { ExistWhen,VisibleWhen,AwClass } from 'js/hocCollection';
const AwToolbarExistWhen = ExistWhen(AwToolbar);
const AwCommandPanelSectionVisibleWhenAwClass = AwClass(VisibleWhen(AwCommandPanelSection));
const DivVisibleWhen = VisibleWhen('div');
const AwCommandPanelSectionVisibleWhen = VisibleWhen(AwCommandPanelSection);
const DivAwClass = AwClass('div');
const AwPanelSectionVisibleWhen = VisibleWhen(AwPanelSection);
const AwPanelSectionExistWhen = ExistWhen(AwPanelSection);
const DivExistWhen = ExistWhen('div');
AwToolbarExistWhen.displayName = 'AwToolbarExistWhen';
AwCommandPanelSectionVisibleWhenAwClass.displayName = 'AwCommandPanelSectionVisibleWhenAwClass';
DivVisibleWhen.displayName = 'DivVisibleWhen';
AwCommandPanelSectionVisibleWhen.displayName = 'AwCommandPanelSectionVisibleWhen';
DivAwClass.displayName = 'DivAwClass';
AwPanelSectionVisibleWhen.displayName = 'AwPanelSectionVisibleWhen';
AwPanelSectionExistWhen.displayName = 'AwPanelSectionExistWhen';
DivExistWhen.displayName = 'DivExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/classifyAdminService', () => import('js/classifyAdminService'));
registerDynImportEntry('js/commandPanel.service', () => import('js/commandPanel.service'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "ctx": {
        "clsAdmin.classSystem": {
            "type": "object"
        }
    },
    "lifecycleHooks": {
        "onMount": "initializeClassesSWA",
        "onUpdate": [
            {
                "action": "updateClassesSWA",
                "observers": [
                    "props.subPanelContext.context.searchState.propertiesSWA"
                ]
            },
            {
                "action": "activeClassificationPanelIfAllowed",
                "observers": [
                    "props.subPanelContext.context.searchState.isLinkClicked"
                ]
            }
        ]
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        }
    },
    "data": {
        "headerProperties": [
            {
                "propertyName": "ID"
            },
            {
                "propertyName": "IRDI"
            },
            {
                "propertyName": "owning_user"
            },
            {
                "propertyName": "dateModified"
            },
            {
                "propertyName": "Status"
            },
            {
                "propertyName": "object_type"
            }
        ],
        "clsadminPropCommands": "clsadmin_propCommands"
    },
    "actions": {
        "updateClassesSWA": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeClassesSWA"
                },
                {
                    "action": "reloadAttributes",
                    "condition": "!conditions.supported"
                }
            ]
        },
        "reloadAttributes": {
            "actionType": "dataProvider",
            "method": "attributes"
        },
        "initializeClassesSWA": {
            "actionType": "JSFunction",
            "method": "initializeSWA",
            "inputData": {
                "subPanelContext": "{{subPanelContext.context.searchState}}",
                "subLocationName": "Classes",
                "optionsTitle": "{{i18n.optionsTitle}}",
                "aliasTitle": "{{i18n.aliasTitle}}"
            },
            "outputData": {
                "currentSecData": "currentSecData",
                "aspects": "aspects",
                "property": "property",
                "classAttributes": "classAttributes",
                "hasClassAttributes": "hasClassAttributes",
                "referenceLinks": "referenceLinks",
                "attrprop": "attrprop",
                "attrSelected": false,
                "optionsTitle": "optionsTitle",
                "aliasTitle": "aliasTitle",
                "options": "options",
                "hasOptions": "hasOptions",
                "alias": "alias",
                "hasAlias": "hasAlias",
                "hasImages": "hasImages",
                "selectedUid": "selectedUid",
                "multiSiteData": "multiSiteData"
            },
            "deps": "js/classifyAdminService"
        },
        "selectNodeInSecWorkArea": {
            "actionType": "JSFunction",
            "method": "selectNodeInSecWorkArea",
            "inputData": {
                "data": "{{data}}",
                "selected": "{{data.eventData.selected}}",
                "subPanelContext": "{{props.subPanelContext.context}}",
                "classSystem": "{{ctx.clsAdmin.classSystem}}"
            },
            "outputData": {
                "attrSelected": "attrSelected",
                "attrprop": "attrprop",
                "reference": "reference"
            },
            "deps": "js/classifyAdminService"
        },
        "selectLink": {
            "actionType": "JSFunction",
            "method": "selectNodeForPanel",
            "inputData": {
                "data": "{{data}}",
                "subPanelContext": "{{props.subPanelContext.context}}",
                "classSystem": "{{ctx.clsAdmin.classSystem}}",
                "eventDataToSuppress": "{{parameters.eventData}}"
            },
            "deps": "js/classifyAdminService"
        },
        "selectLinkOps": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "closePanel"
                },
                {
                    "action": "selectLink"
                },
                {
                    "action": "activeClassificationPanel"
                }
            ]
        },
        "activeClassificationPanelIfAllowed": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "activeClassificationPanel",
                    "condition": "!conditions.supported"
                }
            ]
        },
        "activeClassificationPanelIfOpen": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "activeClassificationPanel",
                    "condition": "!conditions.panelOpen"
                }
            ]
        },
        "activeClassificationPanel": {
            "actionType": "JSFunction",
            "method": "activateCommandPanel",
            "deps": "js/commandPanel.service",
            "inputData": {
                "commandId": "Awp0ClsAdminLink",
                "location": "aw_toolsAndInfo"
            }
        },
        "closePanel": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "complete",
                        "eventData": {
                            "source": "aw_toolsAndInfo"
                        }
                    }
                ]
            }
        },
        "loadDataForAttributes": {
            "actionType": "JSFunctionAsync",
            "method": "loadDataForAttributes",
            "inputData": {
                "data": "{{data}}",
                "type": "Attributes",
                "subPanelContext": null,
                "classSystem": "{{ctx.clsAdmin.classSystem}}"
            },
            "outputData": {
                "attributes": "objects",
                "totalFound": "totalFound"
            },
            "deps": "js/classifyAdminService"
        }
    },
    "dataProviders": {
        "attributes": {
            "action": "loadDataForAttributes",
            "commands": {},
            "response": "{{data.attributes}}",
            "totalFound": "{{data.totalFound}}",
            "selectionModelMode": "single"
        }
    },
    "columnProviders": {
        "structureColumnProvider": {
            "columns": [
                {
                    "name": "Name",
                    "displayName": "Name",
                    "minWidth": 200,
                    "width": 600,
                    "isTableCommand": true
                }
            ],
            "frozenColumnIndex": -1
        }
    },
    "grids": {
        "entryGrid": {
            "dataProvider": "attributes",
            "columnProvider": "structureColumnProvider",
            "addIconColumn": false,
            "gridOptions": {
                "enableGridMenu": false,
                "enablePinning": false,
                "enableSorting": false,
                "useStaticFirstCol": true,
                "maxRowsToShow": 15
            }
        }
    },
    "onEvent": [
        {
            "eventId": "attributes.selectionChangeEvent",
            "cacheEventData": true,
            "action": "selectNodeInSecWorkArea"
        },
        {
            "eventId": "classifyAdmin.launchPanel",
            "action": "activeClassificationPanel"
        },
        {
            "eventId": "classifyAdmin.linkClicked",
            "action": "selectLinkOps",
            "inputArgs": {
                "link": "{{eventData.linkId}}",
                "type": "{{eventData.type}}"
            }
        }
    ],
    "conditions": {
        "supported": {
            "expression": "ctx.tcSessionData.tcMajorVersion > 14 || (ctx.tcSessionData.tcMajorVersion === 14 && ctx.tcSessionData.tcMinorVersion >= 3)"
        },
        "showAllProp": {
            "expression": "props.subPanelContext.context.searchState.showAllProp === true"
        },
        "isSummaryHeaderHidden": {
            "expression": "!(ctx.state && ctx.state.urlAttributes && ctx.state.urlAttributes.flexibleLayouts !== undefined)"
        }
    },
    "i18n": {
        "associations": [
            "ClassificationAdminMessages"
        ],
        "attributes": [
            "ClassificationAdminMessages"
        ],
        "dataType": [
            "ClassificationAdminMessages"
        ],
        "metric": [
            "ClassificationAdminMessages"
        ],
        "multiSite": [
            "ClassificationAdminMessages"
        ],
        "nonMetric": [
            "ClassificationAdminMessages"
        ],
        "preview": [
            "ClassificationAdminMessages"
        ],
        "propertiesTitle": [
            "ClassificationAdminMessages"
        ],
        "optionsTitle": [
            "ClassificationAdminMessages"
        ],
        "aliasTitle": [
            "ClassificationAdminMessages"
        ]
    },
    "_viewModelId": "Awp0ClsClassesSecWorkArea",
    "_uniqueViewModelId": "Awp0ClsClassesSecWorkArea"
};

    /*
    <aw-scrollpanel class="aw-clsadmin-classes">
    <aw-toolbar second-anchor="aw_summaryHeader" exist-when="conditions.isSummaryHeaderHidden" context="props.subPanelContext.context"></aw-toolbar>
    <div class="aw-clsadmin-classes-properties" >
        <aw-command-panel-section caption="{{i18n.propertiesTitle}}" visible-when="data.currentSecData.length > 0"
            collapsed="false" context="props.subPanelContext.context"
            class="sw-column aw-clsadmin-class-properties" aw-class="{'w-6': data.hasImages}">
            <div class="aw-clspadmin-prop-section" aw-repeat="item : data.currentSecData" visible-when="item.value !== '' || conditions.showAllProp">
                <aw-widget prop="item"  ></aw-widget>
            </div>
        </aw-command-panel-section>

        <aw-command-panel-section caption="i18n.preview" visible-when="data.hasImages === true" class="sw-column w-6">
            <aw-full-screen include-component-name="Ics1ClassificationTabUniViewerInternal" header-title="i18n.preview" header-path='{{subPanelContext.activeTab.name ? subPanelContext.activeTab.name : subPanelContext.showObjectContext.activeTab.name}}' header-context-title='{{subPanelContext.selected.props.object_string.uiValues[0]}}' context='data.selectedUid'>
            </aw-full-screen>
        </aw-command-panel-section>
    </div>

    <div class="aw-clsadmin-class-attributes" >
        <div aw-class="[conditions.supported ? 'w-12': 'w-6 sw-column']" class="h-12">
            <aw-panel-section class="w-12" caption="{{i18n.multiSite}}" visible-when="data.multiSiteData.length > 0" collapsed="false">
                <div class="aw-clspadmin-prop-section" aw-repeat="item : data.multiSiteData" visible-when="item.value !== '' || conditions.showAllProp">
                    <aw-widget prop="item"></aw-widget>
                </div>
            </aw-panel-section>
            <aw-panel-section caption="{{i18n.optionsTitle}}" class="aw-clsadmin-attributes" exist-when="data.hasOptions" collapsed="true">
                <div class="aw-clspadmin-prop-section" aw-repeat="item : data.options" visible-when="item.value !== '' || conditions.showAllProp">
                    <aw-widget prop="item"></aw-widget>
                </div>
            </aw-panel-section>
            <aw-panel-section caption="{{i18n.aliasTitle}}" class="aw-clsadmin-attributes" exist-when="data.hasAlias" collapsed="true">
                <div class="aw-clspadmin-prop-section" aw-repeat="item : data.alias" visible-when="item.value !== '' || conditions.showAllProp">
                    <aw-widget prop="item"></aw-widget>
                </div>
            </aw-panel-section>
            <aw-panel-section caption="{{i18n.attributes}}" class="aw-clsadmin-attributes" exist-when="data.hasClassAttributes && !conditions.supported" collapsed="false">
                <aw-splm-table gridid="entryGrid" show-decorators="true"></aw-splm-table>
            </aw-panel-section>
        </div>
        <div class="sw-column w-6 h-12" exist-when="data.hasClassAttributes && !conditions.supported">
            <aw-panel-section caption="{{i18n.associations}}"  class="aw-clsadmin-associations" visible-when="data.attrSelected" collapsed="false">
                <div class="aw-base-scrollPanel" exist-when="data.attrprop.length">
                    <aw-widget prop="item" aw-repeat="item : data.attrprop" ></aw-widget>
                    <aw-panel-section caption="{{i18n.metric}}" visible-when="data.dataTypeMetric.length > 0" collapsed="false">
                        <aw-widget prop="item" aw-repeat="item : data.dataTypeMetric"></aw-widget>
                    </aw-panel-section>
                    <aw-panel-section caption="{{i18n.nonMetric}}" visible-when="data.dataTypeNonMetric.length > 0" collapsed="false">
                        <aw-widget prop="item" aw-repeat="item : data.dataTypeNonMetric"></aw-widget>
                    </aw-panel-section>
                    <aw-link prop="data.reference" action="selectLink"></aw-link>
                </div>
            </aw-panel-section>
        </div>
    </div>

</aw-scrollpanel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwScrollpanel className="aw-clsadmin-classes">
        <AwToolbarExistWhen secondAnchor="aw_summaryHeader" context={AwParseService.instance( "props.subPanelContext.context" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "conditions.isSummaryHeaderHidden" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwToolbarExistWhen>
        <div className="aw-clsadmin-classes-properties">
            <AwCommandPanelSectionVisibleWhenAwClass caption={AwParseService.instance( "i18n.propertiesTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" context={AwParseService.instance( "props.subPanelContext.context" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="sw-column aw-clsadmin-class-properties" visibleWhen={AwParseService.instance( "data.currentSecData.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} awClass={AwParseService.instance( "{'w-6': data.hasImages}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                { Object.entries( AwParseService.instance( "data.currentSecData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                    <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || conditions.showAllProp" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                        <AwWidget {...getField("item", fields, $index, 'data.currentSecData', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                        </AwWidget>
                    </DivVisibleWhen>
                ) ) }
            </AwCommandPanelSectionVisibleWhenAwClass>
            <AwCommandPanelSectionVisibleWhen caption={i18n.preview} className="sw-column w-6" visibleWhen={AwParseService.instance( "data.hasImages === true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwFullScreen includeComponentName="Ics1ClassificationTabUniViewerInternal" headerTitle={i18n.preview} headerPath={AwParseService.instance( "subPanelContext.activeTab.name ? subPanelContext.activeTab.name : subPanelContext.showObjectContext.activeTab.name" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} headerContextTitle={AwParseService.instance( "subPanelContext.selected.props.object_string.uiValues[0]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} context={AwParseService.instance( "data.selectedUid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwFullScreen>
            </AwCommandPanelSectionVisibleWhen>
        </div>
        <div className="aw-clsadmin-class-attributes">
            <DivAwClass className="h-12" awClass={AwParseService.instance( "[conditions.supported ? 'w-12': 'w-6 sw-column']" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwPanelSectionVisibleWhen className="w-12" caption={AwParseService.instance( "i18n.multiSite" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "data.multiSiteData.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    { Object.entries( AwParseService.instance( "data.multiSiteData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                        <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || conditions.showAllProp" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                            <AwWidget {...getField("item", fields, $index, 'data.multiSiteData', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                            </AwWidget>
                        </DivVisibleWhen>
                    ) ) }
                </AwPanelSectionVisibleWhen>
                <AwPanelSectionExistWhen caption={AwParseService.instance( "i18n.optionsTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-clsadmin-attributes" collapsed="true" existWhen={AwParseService.instance( "data.hasOptions" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    { Object.entries( AwParseService.instance( "data.options" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                        <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || conditions.showAllProp" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                            <AwWidget {...getField("item", fields, $index, 'data.options', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                            </AwWidget>
                        </DivVisibleWhen>
                    ) ) }
                </AwPanelSectionExistWhen>
                <AwPanelSectionExistWhen caption={AwParseService.instance( "i18n.aliasTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-clsadmin-attributes" collapsed="true" existWhen={AwParseService.instance( "data.hasAlias" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    { Object.entries( AwParseService.instance( "data.alias" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                        <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || conditions.showAllProp" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                            <AwWidget {...getField("item", fields, $index, 'data.alias', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                            </AwWidget>
                        </DivVisibleWhen>
                    ) ) }
                </AwPanelSectionExistWhen>
                <AwPanelSectionExistWhen caption={AwParseService.instance( "i18n.attributes" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-clsadmin-attributes" collapsed="false" existWhen={AwParseService.instance( "data.hasClassAttributes && !conditions.supported" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <AwSplmTable {...grids.entryGrid} showDecorators={AwParseService.instance( "true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </AwSplmTable>
                </AwPanelSectionExistWhen>
            </DivAwClass>
            <DivExistWhen className="sw-column w-6 h-12" existWhen={AwParseService.instance( "data.hasClassAttributes && !conditions.supported" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwPanelSectionVisibleWhen caption={AwParseService.instance( "i18n.associations" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-clsadmin-associations" collapsed="false" visibleWhen={AwParseService.instance( "data.attrSelected" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <DivExistWhen className="aw-base-scrollPanel" existWhen={AwParseService.instance( "data.attrprop.length" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        { Object.entries( AwParseService.instance( "data.attrprop" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                            <AwWidget {...getField("item", fields, $index, 'data.attrprop', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )} key={$index}>
                            </AwWidget>
                        ) ) }
                        <AwPanelSectionVisibleWhen caption={AwParseService.instance( "i18n.metric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "data.dataTypeMetric.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            { Object.entries( AwParseService.instance( "data.dataTypeMetric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                                <AwWidget {...getField("item", fields, $index, 'data.dataTypeMetric', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )} key={$index}>
                                </AwWidget>
                            ) ) }
                        </AwPanelSectionVisibleWhen>
                        <AwPanelSectionVisibleWhen caption={AwParseService.instance( "i18n.nonMetric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "data.dataTypeNonMetric.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            { Object.entries( AwParseService.instance( "data.dataTypeNonMetric" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                                <AwWidget {...getField("item", fields, $index, 'data.dataTypeNonMetric', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )} key={$index}>
                                </AwWidget>
                            ) ) }
                        </AwPanelSectionVisibleWhen>
                        <AwLink {...getField("data.reference", fields, $index, null, null )} action={actions.selectLink}>
                        </AwLink>
                    </DivExistWhen>
                </AwPanelSectionVisibleWhen>
            </DivExistWhen>
        </div>
    </AwScrollpanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;