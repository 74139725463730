// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwInclude from 'viewmodel/AwIncludeViewModel';
import AwSublocation from 'viewmodel/AwSublocationViewModel';
import AwDataNavigator from 'viewmodel/AwDataNavigatorViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/configurationBaselineService', () => import('js/configurationBaselineService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_configurationBaselineLocation": {
            "contextKey": "occmgmtContext",
            "dataProviderName": "occDataProvider",
            "gridid": "occTreeConfigurationPanel",
            "name": "com.siemens.splm.client.configbaseline:ConfigbaselineSublocation",
            "label": {
                "source": "/i18n/OccMgmtConfigBaselineConstants",
                "key": "occMgmtConfigurationBaselineTitle"
            },
            "requestPref": {
                "savedSessionMode": "ignore",
                "showMarkup": [
                    "false"
                ]
            },
            "clientScopeURI": "Awb0OccurrenceManagement",
            "nameToken": "com.siemens.splm.client.configbaseline:ConfigbaselineSublocation",
            "policy": "/policies/occMgmtPropertyPolicy.json",
            "viewBase": "OccMgmtConfigurationBaseline",
            "editContext": "occDataProvider",
            "tabSetId": "occmgmtContext",
            "selectionQueryParamKey": "c_uid",
            "editSupportParamKeys": [
                "c_uid",
                "o_uid",
                "spageId",
                "pci_uid"
            ],
            "viewModes": {
                "TreeSummaryView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": true
                },
                "TreeView": {
                    "primaryWorkArea": "tree",
                    "secondaryWorkArea": false
                }
            },
            "context": {
                "commandContextParameters": [
                    "filter",
                    "recipe",
                    "uid"
                ],
                "search": {
                    "hideInContentSearchBox": true
                }
            },
            "breadcrumbConfig": {
                "type": "navigate",
                "vm": "occMgmtBreadcrumb",
                "chevronDataProvider": "breadCrumbDataProvider",
                "crumbDataProvider": "aceBreadCrumbDataProvider",
                "noUpdate": true,
                "popuplist": "/html/occMgmtBreadcrumblist.html",
                "id": "occmgmtContext",
                "displayProperty": "object_string"
            },
            "urlParams": {
                "selectionQueryParamKey": "c_uid",
                "openStructureQueryParamKey": "o_uid",
                "rootQueryParamKey": "uid",
                "productContextQueryParamKey": "pci_uid",
                "csidQueryParamKey": "c_csid",
                "secondaryPageIdQueryParamKey": "spageId",
                "topElementQueryParamKey": "t_uid",
                "pageIdQueryParamKey": "pageId",
                "recipeParamKey": "recipe",
                "subsetFilterParamKey": "filter",
                "topBomLineParamKey": "t_bl_uid"
            },
            "headerPreference": {
                "hideGlobalSearch": true,
                "hideLogo": true
            },
            "awSubLocationPref": {
                "hideRightWall": true,
                "hideToolsAndInfoArea": true,
                "hideFooter": true
            }
        },
        "hiddenCommands": {
            "isSaveWorkingContextNotSupported": true,
            "Awb0StructureFilter": true,
            "Awp0ShowColor": true,
            "Awb0ShowMarkup": true,
            "Awb0AddSubstitutes": true,
            "Awb0RemoveSubstitutes": true,
            "Awb0UseSubstitute": true,
            "ps0AddAlternate": true,
            "ps0RemoveAlternate": true
        },
        "contextKey": "occmgmtContext",
        "viewKey": "occmgmtContext",
        "occContext": {
            "initialValues": {
                "configContext": {},
                "displayToggleOptions": {},
                "supportedFeatures": {},
                "productContextInfo": {},
                "readOnlyFeatures": {},
                "currentState": {},
                "previousState": {},
                "transientRequestPref": {},
                "persistentRequestPref": {
                    "defaultClientScopeUri": "Awb0OccurrenceManagement"
                },
                "disabledFeatures": [],
                "BackGroundSoaDebug": "true",
                "LoadTreePropsTimerDebug": "true",
                "LoadTreePropsDebug": "false",
                "ExpandBelowDebug": "true",
                "ExpandOneDebug": "false",
                "ReconfigureDebug": "false",
                "viewKey": "occmgmtContext"
            },
            "meta": {}
        }
    },
    "actions": {
        "initializeConfigurationBaselineView": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeOccContext"
                },
                {
                    "action": "initializeOccMgmtConfigurationBaselineView"
                }
            ]
        },
        "initializeOccMgmtConfigurationBaselineView": {
            "actionType": "JSFunction",
            "method": "initializeOccMgmtConfigurationBaselineView",
            "deps": "js/configurationBaselineService",
            "inputData": {
                "data": "{{data}}",
                "subPanelContext": "{{data}}"
            },
            "outputData": {
                "ctx.modelObjectsToOpen": "result",
                "data.modelObjectsToOpen": "result"
            }
        },
        "initializeOccContext": {
            "actionType": "JSFunction",
            "method": "initializeOccContext",
            "deps": "js/configurationBaselineService",
            "inputData": {
                "subPanelContext": "{{data}}"
            },
            "outputData": {
                "occContext": "result.occContext"
            }
        },
        "destroyOccmgmtConfigurationBaselineView": {
            "actionType": "JSFunction",
            "method": "destroyOccmgmtConfigurationBaselineView",
            "deps": "js/configurationBaselineService",
            "inputData": {}
        }
    },
    "lifecycleHooks": {
        "onMount": "initializeConfigurationBaselineView",
        "onUnmount": "destroyOccmgmtConfigurationBaselineView"
    },
    "ctx": {
        "aceActiveContext": {
            "key": "occmgmtContext"
        }
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        },
        "provider": {
            "type": "object"
        },
        "occContext": {
            "type": "string"
        }
    },
    "_viewModelId": "OccMgmtConfigurationBaseline",
    "_uniqueViewModelId": "OccMgmtConfigurationBaseline"
};

    /*
    <div class="sw-column">
    <aw-include name="OccMgmtConfigurationBaselineTaskbar" sub-panel-context="{provider:data._configurationBaselineLocation, occContext:data.occContext,contextKey:data._configurationBaselineLocation.contextKey, selectionQueryParamKey : data._configurationBaselineLocation.selectionQueryParamKey }"></aw-include>
    <!-- <occmgmt-breadcrumb class="aw-configurationBaseline-breadcrumb" occ-context="subPanelContext.occContext ? subPanelContext.occContext : data.occContext"></occmgmt-breadcrumb> -->
    <aw-sublocation sub-panel-context="{provider:data._configurationBaselineLocation,occContext:data.occContext}" sub-location-preference="data._configurationBaselineLocation.awSubLocationPref">
        <div class="sw-row h-12 afx-content-background">
            <aw-data-navigator sub-panel-context="{provider:data._configurationBaselineLocation, occContext:data.occContext,contextKey:data._configurationBaselineLocation.contextKey, selectionQueryParamKey : data._configurationBaselineLocation.selectionQueryParamKey }" provider="data._configurationBaselineLocation" contextKey="data._configurationBaselineLocation.contextKey" baseSelection="ctx.modelObjectsToOpen[0]"></aw-data-navigator>
        </div>
    </aw-sublocation>
</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="sw-column">
        <AwInclude viewId="OccMgmtConfigurationBaselineTaskbar" viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} name="OccMgmtConfigurationBaselineTaskbar" subPanelContext={AwParseService.instance( "{provider:data._configurationBaselineLocation, occContext:fields.occContext,contextKey:data._configurationBaselineLocation.contextKey, selectionQueryParamKey : data._configurationBaselineLocation.selectionQueryParamKey }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwInclude>
        <AwSublocation subPanelContext={AwParseService.instance( "{provider:data._configurationBaselineLocation,occContext:fields.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subLocationPreference={AwParseService.instance( "data._configurationBaselineLocation.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <div className="sw-row h-12 afx-content-background">
                <AwDataNavigator subPanelContext={AwParseService.instance( "{provider:data._configurationBaselineLocation, occContext:fields.occContext,contextKey:data._configurationBaselineLocation.contextKey, selectionQueryParamKey : data._configurationBaselineLocation.selectionQueryParamKey }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._configurationBaselineLocation" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} contextkey="data._configurationBaselineLocation.contextKey" baseselection="ctx.modelObjectsToOpen[0]">
                </AwDataNavigator>
            </div>
        </AwSublocation>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;