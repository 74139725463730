// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "priority": 0,
            "label": {
                "source": "/i18n/tcmaClassificationMessages",
                "key": "classificationSearchTitle"
            },
            "clientScopeURI": "Awp0SearchResults",
            "nameToken": "com_siemens_splm_classificationLocation:showClassificationSubLocation",
            "context": {
                "search": {
                    "provider": "Awp0FullTextSearchProvider",
                    "maxToLoad": 50,
                    "maxToReturn": 50,
                    "showNoCriteriaMessage": true,
                    "noCriteriaMessage": "No criteria given."
                }
            },
            "breadcrumbConfig": {
                "type": "search",
                "vm": "AwClsBreadCrumb",
                "noUpdate": true,
                "popuplist": "/html/defaultbreadcrumblist.html",
                "overflowIndex": 0
            },
            "headerPreference": {
                "hideGlobalSearch": true,
                "hideLogo": true
            },
            "viewModes": {
                "TableView": {
                    "primaryWorkArea": "table",
                    "secondaryWorkArea": false
                },
                "SummaryView": {
                    "primaryWorkArea": "list",
                    "secondaryWorkArea": true
                }
            },
            "defaultDisplayMode": "SummaryView",
            "viewBase": "ShowClassificationInfo",
            "isXrtApplicable": true,
            "awSubLocationPref": {
                "hideRightWall": true,
                "embeddedLocationView": true
            }
        }
    },
    "actions": {
        "activateClassificationSearchNavigate": {
            "actionType": "activateCommandPanel",
            "inputData": {
                "commandId": "Awp0ClassificationSearchNavigate",
                "location": "aw_navigation",
                "context": "{{commandContext}}"
            }
        }
    },
    "lifecycleHooks": {
        "onMount": "activateClassificationSearchNavigate"
    },
    "_viewModelId": "ShowClassification",
    "_uniqueViewModelId": "ShowClassification",
    "ctx": {}
};

    /*
    <aw-search-page  class="aw-tcmaClassification-header" tabsdata="subPanelContext" provider="data._provider">
</aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage className="aw-tcmaClassification-header" tabsdata={AwParseService.instance( "subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;