// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import mbmUtils from 'js/mbmUtils';
import { constants as mbmConstants } from 'js/mbmConstants';
import saveAsService from 'js/mbmSaveAsService';
/**
 * @module js/mbmAlignService
 */

/**
 *
 * @param {Object} mbmContext mbmContext
 * @returns{Object} alignmentInfo
 */
function evaluateAlignObjects( mbmContext ) {
    let alignmentInfo = {};
    let topElementSelected =  mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_SELECTION_CONTAINS_TOP_ELEMENT );
    let selectedObjects =  mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_SELECTED_OBJECTS );
    let ebomletSelectedObjects = selectedObjects[mbmConstants.EBOM_CONTEXT];
    let mbomletSelectedObjects = selectedObjects[mbmConstants.MBOM_CONTEXT];
    alignmentInfo.isRootIncluded = topElementSelected[mbmConstants.EBOM_CONTEXT] || topElementSelected[mbmConstants.MBOM_CONTEXT];
    alignmentInfo.ebomletSelectedObjects = ebomletSelectedObjects;
    alignmentInfo.mbomletSelectedObjects = mbomletSelectedObjects;
    alignmentInfo.isSingleObjectSelected = ebomletSelectedObjects.length === 1 && mbomletSelectedObjects.length === 1;
    alignmentInfo.isMultiObjectSelected = ebomletSelectedObjects.length > 1 && mbomletSelectedObjects.length > 1;
    alignmentInfo.isEbomMultiObjectSelected = ebomletSelectedObjects.length > 1 && mbomletSelectedObjects.length === 1;
    alignmentInfo.isMbomMultiObjectSelected = ebomletSelectedObjects.length === 1 && mbomletSelectedObjects.length > 1;

    return alignmentInfo;
}

/**
 * Align source objects with Target Object
 * @param {Object} mbmContext   mbmContext
 * @param {Array} sourceElement array of source objects for Alignment
 * @param {Array} targetElement array of Target objects for Alignment
 *
 * @return {Promise} promise
 */
function alignElements( mbmContext, sourceElement, targetElement, mode ) {
    let sourceUids = [];
    let targetUids = [];
    sourceElement.forEach( ( object ) => sourceUids.push( object.uid ) );
    targetElement.forEach( ( object ) => targetUids.push( object.uid ) );

    return saveAsService.alignMFgAssemblies( mbmContext, sourceUids, targetUids, mode );
}

export default {
    evaluateAlignObjects,
    alignElements
};
