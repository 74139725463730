// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/* global
 define
 */

/**
 * @module js/mbmPackUnpackService
 */

import saveInputWriterService from 'js/saveInputWriterService';
import epSaveService from 'js/epSaveService';
import { constants as mbmConstants } from 'js/mbmConstants';
import appCtxService from 'js/appCtxService';
import mbmStructureConfigurationService from 'js/mbmStructureConfigurationService';

/**
   * Call epSaveService to pack and unpack all
   *
   * @param {String} contextKey contextKey
   * @param {Boolean} PackAllorUnpackAll true for PackAll and false for unpackAll
   *
   * @returns {Object} saveResponse
   */
function packAllOrUnpackAllElements( contextKey, packAllorUnpackAll, mbmContext, cspContext, occContext ) {
    let topBomlineUid = mbmConstants.MBOM_CONTEXT === contextKey ? appCtxService.getCtx( 'epTaskPageContext.mbomStructure' ) : appCtxService.getCtx( 'epTaskPageContext.ebomStructure' );
    const saveWriter = saveInputWriterService.get();
    saveWriter.addPackAllUnpackAllChangeEntry( { id: topBomlineUid.uid }, { packAll: [ packAllorUnpackAll.toString() ] } );
    return epSaveService.saveChanges( saveWriter, false, [ topBomlineUid ] ).then( function( saveResponse ) {
        saveResponse.saveEvents && saveResponse.saveEvents.length > 0 && mbmStructureConfigurationService.reloadStructure( saveResponse, mbmContext, cspContext, occContext, false, false );
        return Promise.resolve( saveResponse );
    } );
}
export default {
    packAllOrUnpackAllElements
};


