// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwCommandPanel from 'viewmodel/AwCommandPanelViewModel';
import Awp0NewWorkflowProcessSub from 'viewmodel/Awp0NewWorkflowProcessSubViewModel';
import AwNavigatePanel from 'viewmodel/AwNavigatePanelViewModel';
    import { ShowWhen,ExistWhen } from 'js/hocCollection';
const DivShowWhen = ShowWhen('div');
const Awp0NewWorkflowProcessSubShowWhen = ShowWhen(Awp0NewWorkflowProcessSub);
const DivExistWhen = ExistWhen('div');
DivShowWhen.displayName = 'DivShowWhen';
Awp0NewWorkflowProcessSubShowWhen.displayName = 'Awp0NewWorkflowProcessSubShowWhen';
DivExistWhen.displayName = 'DivExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Awp0NewWorkflowProcess', () => import('js/Awp0NewWorkflowProcess'));
registerDynImportEntry('js/peoplePickerUtils', () => import('js/peoplePickerUtils'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-command-panel.directive",
        "js/visible-when.directive",
        "js/aw-command-sub-panel.directive"
    ],
    "actions": {
        "setPanelInitialValues": {
            "actionType": "JSFunction",
            "method": "setPanelInitialValues",
            "inputData": {
                "targetObjects": "{{ctx.panelContext.targetObjects}}",
                "submitPanelState": "{{data.submitPanelState}}"
            },
            "outputData": {
                "isDataInit": "isDataInit",
                "submitPanelState": "submitPanelState"
            },
            "deps": "js/Awp0NewWorkflowProcess"
        },
        "setActiveView": {
            "actionType": "JSFunction",
            "method": "resetActiveView",
            "inputData": {
                "stateName": "{{submitPanelState}}",
                "destPanelId": "Awp0NewWorkflowProcessSub"
            },
            "outputData": {
                "submitPanelState": ""
            },
            "deps": "js/Awp0NewWorkflowProcess"
        },
        "updateSideNavUserPanelStateBatchJob": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "updateSideNavUserPanelState",
                    "condition": "submitPanelState.activeView ==='Awp0NewWorkflowProcessSub'"
                }
            ]
        },
        "updateSideNavUserPanelState": {
            "actionType": "JSFunction",
            "method": "updateSideNavUserPanelState",
            "inputData": {
                "sideNavMode": "{{props.subPanelContext.sidenavMode}}",
                "addUserPanelState": "{{fields.addUserPanelState}}"
            },
            "deps": "js/peoplePickerUtils"
        }
    },
    "data": {
        "submitPanelState": {
            "initialValues": {
                "activeView": "Awp0NewWorkflowProcessSub",
                "prePanelId": "Awp0NewWorkflowProcessSub",
                "processName": "",
                "processTemplate": {},
                "targetObjects": [],
                "referenceObjects": []
            },
            "meta": {
                "activeView": {
                    "field": "true",
                    "type": "STRING"
                }
            }
        },
        "addUserPanelState": {
            "initialValues": {
                "provider": "Awp0PeoplePickerProvider",
                "selectionModelMode": "multiple",
                "criteria": {
                    "providerContentType": "GroupMember,User,ResourcePool",
                    "searchString": "*",
                    "searchSubGroup": "true",
                    "group": "",
                    "role": "",
                    "participantType": ""
                },
                "defaultCriteria": {
                    "providerContentType": "GroupMember,User,ResourcePool",
                    "searchSubGroup": "true",
                    "group": "",
                    "role": "",
                    "participantType": ""
                },
                "preferenceCriteria": [
                    "WRKFLW_user_panel_content_display",
                    "WRKFLW_allow_resourcepool_assignments",
                    "WRKFLW_show_user_assignment_options"
                ],
                "selectedUsers": "",
                "isAddButtonNeeded": false,
                "triggerUpdateSearchCriteria": false
            },
            "meta": {
                "selectedUsers": {
                    "field": "true",
                    "type": "OBJECTARRAY"
                }
            }
        },
        "assignmentState": {
            "initialValues": {},
            "meta": {}
        }
    },
    "onEvent": [],
    "lifecycleHooks": {
        "onMount": "setPanelInitialValues",
        "onUpdate": [
            {
                "action": "updateSideNavUserPanelStateBatchJob",
                "observers": [
                    "props.subPanelContext.sidenavMode"
                ]
            }
        ]
    },
    "ctx": {
        "panelContext.targetObjects": null,
        "workflow_process_candidates": null
    },
    "conditions": {
        "isHostedMode": {
            "expression": "ctx.workflow_process_candidates && ctx.workflow_process_candidates.IsEmbeddedComponent"
        }
    },
    "i18n": {
        "Workflow_Title": [
            "WorkflowCommandPanelsMessages"
        ],
        "Add_Title": [
            "WorkflowCommandPanelsMessages"
        ]
    },
    "_viewModelId": "Awp0NewWorkflowProcess",
    "_uniqueViewModelId": "Awp0NewWorkflowProcess"
};

    /*
    <aw-command-panel caption="i18n.Workflow_Title" context="subPanelContext" hide-title="conditions.isHostedMode">
    <div class="aw-layout-flexColumn" show-when=" data.isDataInit && data.submitPanelState.value.activeView === 'Awp0NewWorkflowProcessSub'">
        <awp0-new-workflow-process-sub show-when="data.submitPanelState.value.activeView === 'Awp0NewWorkflowProcessSub'"
        sub-panel-context="{...subPanelContext, submitPanelState:data.submitPanelState, addUserPanelState:data.addUserPanelState, assignmentState:data.assignmentState}"></awp0-new-workflow-process-sub>
    </div>
    <div class="aw-layout-flexColumn" exist-when="data.submitPanelState.value.activeView ==='Awp0AddWorkflowProcessAttachmentSub' ">
        <aw-navigate-panel title="i18n.Add_Title" pre-panel-id="Awp0NewWorkflowProcessSub" panel-id="Awp0AddWorkflowProcessAttachmentSub"
        action="actions.setActiveView" sub-panel-context="{...subPanelContext, submitPanelState:data.submitPanelState, assignmentState:data.assignmentState}"></aw-navigate-panel>
    </div>
    <div class="aw-layout-flexColumn" exist-when="data.submitPanelState.value.activeView ==='AwPeoplePicker' ">
        <aw-navigate-panel title="i18n.Add_Title" pre-panel-id="Awp0NewWorkflowProcessSub" panel-id="AwPeoplePicker"
        action="actions.setActiveView" sub-panel-context="{ ...subPanelContext, addUserPanelState:data.addUserPanelState}"></aw-navigate-panel>
    </div>
</aw-command-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwCommandPanel revealAction={actions.reveal} hideTitle={conditions.isHostedMode} context={subPanelContext} updateActiveView={AwParseService.instance( "{activeView: data.activeView, dispatch: dispatch }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} caption={i18n.Workflow_Title}>
        <DivShowWhen className="aw-layout-flexColumn" showWhen={AwParseService.instance( "data.isDataInit && fields.submitPanelState.value.activeView === 'Awp0NewWorkflowProcessSub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <Awp0NewWorkflowProcessSubShowWhen subPanelContext={AwParseService.instance( "{...subPanelContext, submitPanelState:fields.submitPanelState, addUserPanelState:fields.addUserPanelState, assignmentState:fields.assignmentState}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} showWhen={AwParseService.instance( "fields.submitPanelState.value.activeView === 'Awp0NewWorkflowProcessSub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </Awp0NewWorkflowProcessSubShowWhen>
        </DivShowWhen>
        <DivExistWhen className="aw-layout-flexColumn" existWhen={AwParseService.instance( "fields.submitPanelState.value.activeView ==='Awp0AddWorkflowProcessAttachmentSub'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwNavigatePanel title={i18n.Add_Title} prePanelId="Awp0NewWorkflowProcessSub" panelId="Awp0AddWorkflowProcessAttachmentSub" action={AwParseService.instance( "actions.setActiveView" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{...subPanelContext, submitPanelState:fields.submitPanelState, assignmentState:fields.assignmentState}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwNavigatePanel>
        </DivExistWhen>
        <DivExistWhen className="aw-layout-flexColumn" existWhen={AwParseService.instance( "fields.submitPanelState.value.activeView ==='AwPeoplePicker'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwNavigatePanel title={i18n.Add_Title} prePanelId="Awp0NewWorkflowProcessSub" panelId="AwPeoplePicker" action={AwParseService.instance( "actions.setActiveView" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{ ...subPanelContext, addUserPanelState:fields.addUserPanelState}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwNavigatePanel>
        </DivExistWhen>
    </AwCommandPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;