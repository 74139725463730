// @<COPYRIGHT>@
// ==================================================
// Copyright 2024.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */
/**
 * @module js/G4B_TakoDispatcherPopupService
 */

import _ from "lodash";
import appCtxSvc from 'js/appCtxService';
import appCtxService from "js/appCtxService";
import viewModelObjectService from "js/viewModelObjectService";
import _uwPropertySvc from 'js/uwPropertyService';
import dateTimeService from "js/dateTimeService";
("use strict");

let exports = {};
export let g4b_parseDispatcherRequests = function (
  dispatcherRequests,
  sortCriteria
) {
  let sortedDisReq = [];
  if (dispatcherRequests.outputObjects[0] != undefined) {
    let totalQryDisRequest = dispatcherRequests.outputObjects[0].queriedRequests;
    if (totalQryDisRequest != undefined) {
      _.forEach(totalQryDisRequest, function (disReqObject) {
        if (appCtxSvc.ctx.userSession.props.user.dbValue == disReqObject.props.owning_user.dbValues[0]) {
          let dispVmoObject = viewModelObjectService.createViewModelObject(disReqObject.uid);
          //Empty the duration prop for Dispatcher Request is in Complete state:
          if (disReqObject.props.currentState != undefined && disReqObject.props.currentState.dbValues[0] == "COMPLETE") {
            dispVmoObject.props.g4bDuration = _uwPropertySvc.createViewModelProperty("g4bDuration", "g4bDuration", "INTEGER", 0, "");
          }
          else {
            let lastModeDatetimeInSec = dispVmoObject.props.last_mod_date.dbValue.toString().slice(0, -3);
            let currentTimestampInSec = new Date().getTime().toString().slice(0, -3);
            let totalDuration = currentTimestampInSec - lastModeDatetimeInSec;
            let seconds = totalDuration;
            let hours = Math.floor(seconds / 3600);
            seconds %= 3600;
            let minutes = Math.floor(seconds / 60);
            let remainingSeconds = seconds % 60;
            let result = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + remainingSeconds.toString().padStart(2, '0');
            dispVmoObject.props.g4bDuration = _uwPropertySvc.createViewModelProperty("g4bDuration", "g4bDuration", "INTEGER", totalDuration, [result.toString()]);
          }
          let durationLimit = appCtxSvc.ctx.preferences.G4B_textcolorlimitforDispatcherDuration[0];
          dispVmoObject.props.g4bMaxLimit = _uwPropertySvc.createViewModelProperty("g4bMaxLimit", "g4bMaxLimit", "INTEGER", durationLimit, [durationLimit]);
          _uwPropertySvc.updateDisplayValues(dispVmoObject.props.creation_date, [dateTimeService.formatSessionDateTime(dispVmoObject.props.creation_date.dbValue)]);
          _uwPropertySvc.updateDisplayValues(dispVmoObject.props.last_mod_date, [dateTimeService.formatSessionDateTime(dispVmoObject.props.last_mod_date.dbValue)]);
          dispVmoObject.props.secondaryObjects.value = dispVmoObject.props.secondaryObjects.uiValues[0];
          sortedDisReq.push(dispVmoObject);
        }
      })
    }
  }
  let allSelectedProtoList = appCtxSvc.ctx.mselected;
  let disReqPrototypeuid = [];
  if (sortedDisReq != undefined) {
    _.forEach(sortedDisReq, function (dispatcherObj) {
      disReqPrototypeuid.push(dispatcherObj.props.secondaryObjects.dbValues[0]);
    })
  }
  if (allSelectedProtoList != undefined) {
    _.forEach(allSelectedProtoList, function (object) {
      if (!disReqPrototypeuid.includes(object.uid)) {
        let prototypeObj = createVMObject(object);
        sortedDisReq.push(prototypeObj);
      }
    })
  }
  if (sortCriteria && sortCriteria.length > 0) {
    let criteria = sortCriteria[0];
    let sortDirection = criteria.sortDirection;
    let sortColName = criteria.fieldName;
    if (sortDirection === "ASC") {
      sortedDisReq.sort(function (a, b) {
        if (a.props[sortColName].value <= b.props[sortColName].value) {
          return -1;
        }
        return 1;
      });
    } else if (sortDirection === "DESC") {
      sortedDisReq.sort(function (a, b) {
        if (a.props[sortColName].value >= b.props[sortColName].value) {
          return -1;
        }
        return 1;
      });
    }
  }
  let outputData = {
    outputFilteredObjects: sortedDisReq,
    totalFound: sortedDisReq.length,
  };
  appCtxService.updateCtx("search".totalFound, sortedDisReq.length);
  return outputData;
};
export let g4b_getInputForDispatcherSOA = function (subsetInput) {
  let outputPrototypeInfo = subsetInput.prototypeInfo;
  let subsetObjects = [];
  for (let inx = 0; inx < outputPrototypeInfo.length; inx++) {
    for (let itr = 0; itr < outputPrototypeInfo[inx].subsets.length; itr++) {
      if (outputPrototypeInfo[inx].subsets[itr].subsetName.includes("03")) {
        subsetObjects.push(outputPrototypeInfo[inx].subsets[itr].subset);
        break;
      }
    }
  }
  let response = [];
  let req = {
    states: appCtxSvc.ctx.preferences.G4B_takoDispatcherStatesToTrackInAW,
    services: ["G4B_GEP_TaKoExcelExport"],
    providers: ["TAKO"],
    primaryObjects: subsetObjects
  };
  response.push(req);
  return response;
};
export let createVMObject = function (obj) {
  let prototypeVmo = viewModelObjectService.createViewModelObject(obj.uid);
  prototypeVmo.props.g4bDuration = _uwPropertySvc.createViewModelProperty("g4bDuration", "g4bDuration", "INTEGER", "", "");
  prototypeVmo.props.primaryObjects = _uwPropertySvc.createViewModelProperty("primaryObjects", "primaryObjects", "OBJECTARRAY", "", "");
  prototypeVmo.props.secondaryObjects = _uwPropertySvc.createViewModelProperty("secondaryObjects", "secondaryObjects", "OBJECTARRAY", obj.uid, [prototypeVmo.props.object_string.displayValues[0]]);
  prototypeVmo.props.secondaryObjects.value = prototypeVmo.props.object_string.uiValues[0];
  prototypeVmo.props.currentState = _uwPropertySvc.createViewModelProperty("currentState", "currentState", "STRING", "", "");
  prototypeVmo.props.taskID = _uwPropertySvc.createViewModelProperty("taskID", "taskID", "STRING", "", "");
  prototypeVmo.props.creation_date = _uwPropertySvc.createViewModelProperty("creation_date", "creation_date", "Date", "", "");
  prototypeVmo.props.last_mod_date = _uwPropertySvc.createViewModelProperty("last_mod_date", "last_mod_date", "Date", "", "");
  return prototypeVmo;
};
export let g4b_getselectedvalue = function (selected) {
  // Check if any selected object meets the criteria
  if (selected.selectedObjects != undefined) {
    const hasDispatcherRequest = selected.selectedObjects.some(obj =>
      obj.type === "DispatcherRequest" && obj.props.currentState.dbValue !== null
    );
    // Update the context based on the condition
    if (appCtxSvc.ctx.G4B_DispatcherStabilizationMail != undefined) {
      appCtxSvc.updateCtx("G4B_DispatcherStabilizationMail", hasDispatcherRequest);
    } else {
      appCtxSvc.registerCtx("G4B_DispatcherStabilizationMail", hasDispatcherRequest);
    }
  }
  return selected;
};
export let G4B_DispatcherStabilizationOpenMail = function (commandContext, ctx) {
  //read the values form preference for emailAddress
  let toList = ctx.preferences.G4B_TakoDispatcherMailAddress.map(obj => obj).join(';');
  // Define the email parameters
  const emailAddress = toList;
  const emailSubject = ctx.preferences.G4B_TakoDispatcherMailSubject;
  // Extract task IDs from selected objects and filter by conditions
  const taskID = commandContext.selectedData.selectedObjects
    .filter(obj => obj.type === "DispatcherRequest" && obj.props.currentState.dbValue !== null)
    .map(obj => obj.props.taskID.dbValue + " (State:" + obj.props.currentState.dbValue + ")").join('%0A');
  const message = "Hello Team,%0A%0AWe have problems with the Dispatcher requests.%0A%0APlease open an INC for service group: TEAMCENTER-TAKO_Prod#Global.%0AProvider : TAKO%0ATranslator : G4B_GEP_TaKoExcelExport%0A%0ADispatcher request Task IDs:-%0A"
  // Prepare the email body
  const emailBody = message + taskID + "%0A%0APlease check.";
  // Redirect to the email client with the generated mailto link
  window.location.href = "mailto:" + emailAddress + "?subject=" + emailSubject + "&body=" + emailBody;
}

/**
 * @memberof NgServices
 * @member G4B_TakoDispatcherPopupService
 */
exports = {
  g4b_parseDispatcherRequests,
  g4b_getInputForDispatcherSOA,
  createVMObject,
  g4b_getselectedvalue,
  G4B_DispatcherStabilizationOpenMail
};

export default exports;
