// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * This service for add Table Row
 *
 * @module js/AddCommentService
 */

import $ from "jquery";
import selectionService from "js/selection.service";
import VIMSoaUtils from "js/VIMSoaUtils";
import messagingSvc from "js/messagingService";
import policySvc from "soa/kernel/propertyPolicyService";
import soaSvc from "soa/kernel/soaService";
import appCtxService from "js/appCtxService";
import commandPanelService from "js/commandPanel.service";
import cdmSvc from "soa/kernel/clientDataModel";
import AwHttpService from "js/awHttpService";
import _browserUtils from "js/browserUtils";

("use strict");

let selectedIssue = null;
let tableComment = null;
let tableDate = null;
let tableUser = null;
let tableCommentType = null;
let current_datetime = null;
let existingRowUIDList = [];
let issueCommentForm = null;

let $http = AwHttpService.instance;
let exports = {};
/**
 * Function Returns opened Issue Revision object from ctx
 * @function getIssueRevision
 */
let getIssueRevision = function () {
  let mselected = appCtxService.getCtx("mselected");

  if (
    mselected &&
    mselected[0] &&
    ("V4B_VGA_IssueRevision" === mselected[0].type ||
      
      "V4B_DALi_IssueRevision" === mselected[0].type ||
      "V4B_FTMTKB_IssueRevision" === mselected[0].type ||
      "V4B_FALi_IssueRevision" === mselected[0].type ||
      "V4B_EBE_IssueRevision" === mselected[0].type ||
      "V4B_OrderRequestRevision" === mselected[0].type)
  )
    return mselected[0];
  else return appCtxService.getCtx("pselected");
};

/**
 * Hides extra label shown for Comment History section
 * This also removes first column of the Comments table which contains all the icons
 */
export let hideExtraCommentHistoryLabel = function (data) {
  let sectionHeadingName = data.i18n.commentSectionHeading;

  // Remove Extra Label
  let iHandle = setInterval(function () {
    let extraLabelObj = $("label:contains('" + sectionHeadingName + "')")
      .closest("div[ng-switch-when='htmlpanel']")
      .parent("div")
      .siblings("div")
      .find($("div.aw-widgets-propertyLabelTop.ng-binding"));

    if (extraLabelObj) {
      extraLabelObj.hide();
      clearInterval(iHandle);
    }
  }, 500);

  // Remove icon column
  let iHandle2 = setInterval(function () {
    let iconColDiv = $("aw-table[gridid='TableProperty_3_Provider']")
      .find($("aw-table-icon-cell"))
      .closest("div[role='rowgroup']")
      .closest("div[role='grid']");

    if (iconColDiv) {
      iconColDiv.hide();
      clearInterval(iHandle2);
    }
  }, 500);
};

/**
 * Returns timezone string in the required format to be appended to Time String
 */
export let getTimeZoneStr = function () {
  function pad(number, length) {
    let str = "" + number;
    while (str.length < length) {
      str = "0" + str;
    }
    return str;
  }

  let offset = new Date().getTimezoneOffset();
  offset =
    (offset < 0 ? "+" : "-") +
    pad(parseInt(Math.abs(offset / 60)), 2) +
    pad(Math.abs(offset % 60), 2);

  return offset;
};

/**
 * Loads existing data in comments table and populates global variables with their values.
 * @param {Object} _issueCommentFormLocal - issueCommentForm Object
 * @param {Object} data - Declarative viewmodel data object
 */
export let getExistingTableRowData = function (_issueCommentFormLocal, data) {
  let inputDataCreateRow = {
    owningObject: _issueCommentFormLocal,
    tablePropertyName: "v4b_comment",
  };
  let policyId = policySvc.register({
    types: [
      {
        name: "User",
        properties: [
          {
            name: "user_name",
          },
        ],
      },
    ],
  });
  return soaSvc
    .post(
      "Internal-AWS2-2016-04-DataManagement",
      "getInitialTableRowData",
      inputDataCreateRow
    )
    .then(function (response) {
      if (
        !(
          response.tableRows &&
          response.tableRows.length > 0 &&
          response.tableRows[0].tableRowData
        )
      ) {
        return null;
      }
      let userModelObject = cdmSvc.getObject(appCtxService.getCtx("user.uid"));
      let personName = userModelObject.props.user_name.uiValues[0];
      policySvc.unregister(policyId);
      let tableRowDatavar = response.tableRows[0].tableRowData;
      for (let key in tableRowDatavar) {
        if ("v4b_commentdate" === tableRowDatavar[key].name) {
          current_datetime = new Date();
          let formatted_date =
            current_datetime.getFullYear() +
            "-" +
            (current_datetime.getMonth() + 1) +
            "-" +
            current_datetime.getDate() +
            "T" +
            current_datetime.getHours() +
            ":" +
            current_datetime.getMinutes() +
            ":" +
            current_datetime.getSeconds() +
            exports.getTimeZoneStr();
          (tableRowDatavar[key].dbValues[0] = formatted_date),
            (tableRowDatavar[key].uiValues[0] = formatted_date),
            (tableDate = {
              propertyName: tableRowDatavar[key].name,
              dbValues: [tableRowDatavar[key].dbValues[0]],
              uiValues: [tableRowDatavar[key].uiValues[0]],
              isModifiable: false,
            });
        } else if ("v4b_commenttype" === tableRowDatavar[key].name) {
          (tableRowDatavar[key].dbValues[0] = "User Comment"),
            (tableRowDatavar[key].uiValues[0] = "User Comment"),
            (tableCommentType = {
              propertyName: tableRowDatavar[key].name,
              dbValues: [tableRowDatavar[key].dbValues[0]],
              uiValues: [tableRowDatavar[key].uiValues[0]],
              isModifiable: false,
            });
        } else if ("v4b_commentuser" === tableRowDatavar[key].name) {
          (tableRowDatavar[key].dbValues[0] = personName),
            (tableRowDatavar[key].uiValues[0] = personName),
            (tableUser = {
              propertyName: tableRowDatavar[key].name,
              dbValues: [tableRowDatavar[key].dbValues[0]],
              uiValues: [tableRowDatavar[key].uiValues[0]],
              isModifiable: false,
            });
        } else if ("v4b_comments" === tableRowDatavar[key].name) {
          (tableRowDatavar[key].dbValues[0] = data.commentBox.dbValue),
            (tableRowDatavar[key].uiValues[0] = data.commentBox.dbValue),
            (tableComment = {
              propertyName: tableRowDatavar[key].name,
              dbValues: [tableRowDatavar[key].dbValues[0]],
              uiValues: [tableRowDatavar[key].uiValues[0]],
              isModifiable: false,
            });
        }
      }
    });
};

/**
 * Creates V4B_IssueCommentForm if it is not attached to selectedIssue.
 * If it is already attached, then simply returns the attached object.
 */
export let createFormIfNotPresent = function () {
  selectedIssue = getIssueRevision();
  return VIMSoaUtils.expandGrmRelationsForPrimary(
    selectedIssue,
    "IMAN_reference",
    "V4B_IssueCommentForm"
  ).then(function (response) {
    let commentFormType = "V4B_IssueCommentForm";
    if (
      response.output != null &&
      response.output.length > 0 &&
      response.output[0].relationshipData &&
      response.output[0].relationshipData.length > 0 &&
      response.output[0].relationshipData[0].relationshipObjects &&
      response.output[0].relationshipData[0].relationshipObjects.length > 0 &&
      response.output[0].relationshipData[0].relationshipObjects[0]
        .otherSideObject
    ) {
      return VIMSoaUtils.loadObject(
        response.output[0].relationshipData[0].relationshipObjects[0]
          .otherSideObject.uid,
        ["process_stage_list"]
      ).then(function (responseObject) {
        return responseObject;
      });
    } else {
      let createInput = {
        inputs: [
          {
            clientId: "CreateFormObject",
            createData: {
              boName: commentFormType,
              compoundCreateInput: {},
              propertyNameValues: {
                object_name: [""],
              },
            },
            pasteProp: "IMAN_reference",
            targetObject: selectedIssue,
            workflowData: {},
          },
        ],
      };
      return soaSvc
        .post(
          "Internal-Core-2012-10-DataManagement",
          "createRelateAndSubmitObjects",
          createInput
        )
        .then(function (createResponse) {
          if (
            createResponse.output &&
            createResponse.output.length > 0 &&
            createResponse.output[0].objects &&
            createResponse.output[0].objects.length > 0
          )
            return createResponse.output[0].objects[0];
        });
    }
  });
};

function pad(number) {
  let str = "" + number;
  while (str.length < 2) {
    str = "0" + str;
  }
  return str;
}

/**
 * @function addNewRowinTable - Adds new comment to the comments table.
 * @param {Object} data - Declarative view model data object
 */
export let addNewRowinTable = function (data) {
  data.okButtonVisibility = false;
  let wfDesc = "";

  return exports
    .getExistingTableRowData(issueCommentForm, data)
    .then(function () {
      let policyId = policySvc.register({
        types: [
          {
            name: "User",
            properties: [
              {
                name: "user_id",
              },
            ],
          },
          {
            name: "V4B_Gen_IssueRevision",
            properties: [
              {
                name: "object_name",
              },
              {
                name: "v4b_comp_issue_comment",
              },
              {
                name: "owning_user",
                modifiers: [
                  {
                    name: "withProperties",
                    Value: "true",
                  },
                ],
              },
            ],
          },
          {
            name: "V4B_VGA_IssueRevision",
            properties: [
              {
                name: "v4b_DB",
              },
              {
                name: "v4b_domaine",
              },
              {
                name: "last_mod_user",
                modifiers: [
                  {
                    name: "withProperties",
                    Value: "true",
                  },
                ],
              },
              {
                name: "last_mod_date",
              },
            ],
          },
        ],
      });
      return soaSvc
        .post("Core-2007-09-DataManagement", "loadObjects", {
          uids: [selectedIssue.uid],
        })
        .then(function (loadResponse) {
          let responseData = loadResponse.modelObjects[selectedIssue.uid];
          policySvc.unregister(policyId);
          if (responseData) {
            for (let uid in responseData) {
              if (
                ("V4B_VGA_IssueRevision" === responseData.type ||
                  
                  "V4B_FTMTKB_IssueRevision" === responseData.type ||
                  "V4B_DALi_IssueRevision" === responseData.type ||
                  "V4B_EBE_IssueRevision" === responseData.type ||
                  "V4B_FALi_IssueRevision" === responseData.type ||
                  "V4B_OrderRequestRevision" === responseData.type) &&
                responseData.props.v4b_comp_issue_comment
              ) {
                existingRowUIDList =
                  responseData.props.v4b_comp_issue_comment.dbValues;
              }
            }
          }
          let loadRowObject = [];
          //wfDesc = current_datetime.getFullYear() + "." + pad(current_datetime.getMonth() + 1) + "." + pad(current_datetime.getDate()) + " " + pad(current_datetime.getHours()) + ":" + pad(current_datetime.getMinutes()) + ":" + pad(current_datetime.getSeconds());
          //wfDesc = current_datetime.getUTCFullYear() + "." + (current_datetime.getUTCMonth() + 1) + "." + current_datetime.getUTCDate() + " " + current_datetime.getUTCHours() + ":" + current_datetime.getUTCMinutes() + ":" + current_datetime.getUTCSeconds();

          wfDesc = getDateInBerlinFormat(current_datetime);

          loadRowObject.push({
            viewModelProperties: [
              tableDate,
              tableCommentType,
              tableUser,
              tableComment,
              {
                propertyName: "owningObject",
                dbValues: [issueCommentForm.uid],
              },
              {
                propertyName: "tablePropertyName",
                dbValues: ["v4b_comment"],
              },
              {
                propertyName: "newRowTypeName",
                dbValues: ["V4B_IssueCommentRow"],
              },
            ],
          });
          for (let key in existingRowUIDList) {
            loadRowObject.push({
              obj: {
                type: "V4B_IssueCommentRow",
                uid: existingRowUIDList[key],
              },
              viewModelProperties: [
                {
                  propertyName: "owningObject",
                  dbValues: [issueCommentForm.uid],
                },
                {
                  propertyName: "tablePropertyName",
                  dbValues: ["v4b_comment"],
                },
              ],
            });
          }

          let saveInputTableData = {
            inputs: loadRowObject,
          };
          return soaSvc
            .post(
              "Internal-AWS2-2017-06-DataManagement",
              "saveViewModelEditAndSubmitWorkflow",
              saveInputTableData
            )
            .then(function () {
              /**
               * New Functionality added ti initiate process for T4EA for DALi and VGA object
               **/
              let commentSyncNeeded = false;
              let content = {};
              if (responseData.type === "V4B_DALi_IssueRevision") {
                commentSyncNeeded = true;
                let owningUser =
                  loadResponse.modelObjects[
                    responseData.props.owning_user.dbValues[0]
                  ];
                let ownerUserID = owningUser.props.user_id.dbValues[0];
                content = {
                  object_type: "V4B_DALi_Issue",
                  item_id: responseData.props.awp0Item_item_id.dbValues[0],
                  owning_user: ownerUserID.toUpperCase(),
                  Uid: responseData.uid,
                  v4b_DALi_Kommentar:
                    wfDesc +
                    ";" +
                    appCtxService.getCtx(
                      "userSession.props.user_id.dbValue"
                    ).toUpperCase() +
                    ";Zus\u00e4tzlicher Kommentar | Additional Comment;" +
                    data.commentBox.dbValue,
                  v4b_DALi_Kommentar_date: wfDesc,
                };
              }
              if (responseData.type === "V4B_VGA_IssueRevision") {
                commentSyncNeeded = true;
                let lastModUser =
                  loadResponse.modelObjects[
                    responseData.props.last_mod_user.dbValues[0]
                  ];
                let lastModUserID = lastModUser.props.user_id.dbValues[0];
                let vga_edit_date = getDateInBerlinFormat(
                  new Date(
                    Date.parse(responseData.props.last_mod_date.dbValues[0])
                  )
                );
                content = {
                  item_id: responseData.props.awp0Item_item_id.dbValues[0],
                  vga_issue_id: responseData.props.awp0Item_item_id.dbValues[0],
                  vga_db_name: responseData.props.v4b_DB.dbValues[0],
                  vga_domain_name: responseData.props.v4b_domaine.dbValues[0],
                  vga_edit_user_id: lastModUserID.toUpperCase(),
                  vga_edit_date: vga_edit_date,
                  vga_object_tag: responseData.uid,
                  v4b_Kommentar_date: wfDesc,
                  v4b_Kommentar_user: appCtxService
                    .getCtx("userSession.props.user_id.dbValue")
                    .toUpperCase(),
                  v4b_Kommentar_user_comment: data.commentBox.dbValue,
                  v4b_Kommentar_type: "User Comment",
                };
              }
              if (commentSyncNeeded) {
                if (
                  appCtxService.getCtx(
                    "preferences.AWC_V4B_DALi_VGA_Comment_Sync"
                  )
                ) {
                  $http
                    .post(
                      _browserUtils.getBaseURL() + "sync_comment/syncComment",
                      content,
                      {
                        headers: {
                          Authorization:
                            "Basic " +
                            appCtxService.getCtx(
                              "preferences.AWC_V4B_DALi_VGA_Comment_Sync"
                            ),
                        },
                      }
                    )
                    .then((httpResp) => {
                      console.log(httpResp);
                    }).catch(error => { 
                      messagingSvc.showError(error.message);
                  });
                } else {
                  console.log(
                    "Preference AWC_V4B_DALi_VGA_Comment_Sync is unavailable."
                  );
                  messagingSvc.showError(data.i18n.CommentSyncError);
                }
              }
              if (responseData.type === "V4B_OrderRequestRevision") {   
                var inputData = {
                  "webApplicationServiceName": "ORDER_REQ_BDSYNC_COMMENT",
                  "issuesUID": [appCtxService.ctx.xrtSummaryContextObject.uid],
                  "issueComment" : data.commentBox.dbValue  
                };                
                //sync comment to blue diamond application
                VIMSoaUtils.syncUpdatesToWebApplication(inputData).then(function (response) {
                    if (response != undefined && response.messages != undefined && response.messages.length > 0 ) {
                        messagingSvc.showError(data.i18n.BDSyncCommentError);
                    }
                }, function (errObj) {
                    var msg = errObj;
                    errObj && errObj.message && (msg = errObj.message);
                    messagingSvc.showError(data.i18n.BDSyncError);
                });                    
            }                  
              data.okButtonVisibility = true;
              selectionService.updateSelection(selectedIssue);
            });
        });
    });
};
function getDateInBerlinFormat(date) {
  let time = date.toLocaleString("en-US", {
    timeZone: "UTC",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  }); //"09/29/2022, 15:21:15";
  time = time.replace(",", "");
  let t1 = time.substring(3, 5);
  let t2 = time.substring(0, 2);
  let t3 = time.substring(6);
  return t1 + "." + t2 + "." + t3;
}
export let precheckAddComment = function (data) {
  selectedIssue = getIssueRevision();
  exports.createFormIfNotPresent().then(
    function (response) {
      issueCommentForm = response;
      if (
        (selectedIssue.type === "V4B_DALi_IssueRevision" ||
          selectedIssue.type === "V4B_VGA_IssueRevision") &&
        issueCommentForm !== undefined
      ) {
        if (issueCommentForm.props.process_stage_list.dbValues.length == 0) {
          commandPanelService.activateCommandPanel(
            "AddComment",
            "aw_toolsAndInfo"
          );
        } else {
          messagingSvc.showError(data.i18n.waitMsgForCommentForm);
        }
      } else if (issueCommentForm !== undefined) {
        commandPanelService.activateCommandPanel(
          "AddComment",
          "aw_toolsAndInfo"
        );
      }
    },
    function (errObj) {
      let msg = errObj;
      errObj && errObj.message && (msg = errObj.message),
        messagingSvc.showError(msg);
    }
  );
};
async function addAndSyncComment(data) {
  let errormsg = "";
  let selected = appCtxService.getCtx("selected");
  if (selected.type === "V4B_FALi_IssueRevision") {
    let inputData = {
      "webApplicationServiceName": "FALI_BDSYNC_COMMENT",
      "issuesUID": [appCtxService.ctx.xrtSummaryContextObject.uid],
      "issueComment": data.commentBox.dbValue,
    };
    appCtxService.registerCtx("buttonAndPanelState",{enableButton:false,closePanel:false});
    try {
      let response =  await VIMSoaUtils.syncFaliUpdatesToWebApplication(inputData);
      if (typeof response.error == 'string') {
       throw response.error; 
      } 
      else
      {
        appCtxService.updateCtx("buttonAndPanelState",{enableButton:false,closePanel:true});
        await addNewRowinTable(data);
      }
      
    } catch (error) {
      console.error('Error occurred:', error);
      appCtxService.updateCtx("buttonAndPanelState",{enableButton:true,closePanel:false});
    }
  } else {
     appCtxService.updateCtx("buttonAndPanelState",{enableButton:false,closePanel:true});
     await addNewRowinTable(data);
  }
  let buttonAndPanelState=appCtxService.getCtx("buttonAndPanelState");
  return buttonAndPanelState.closePanel;
}

exports = {
  hideExtraCommentHistoryLabel,
  getTimeZoneStr,
  getExistingTableRowData,
  createFormIfNotPresent,
  addNewRowinTable,
  precheckAddComment,
  addAndSyncComment
};

export default exports;
