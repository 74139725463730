// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSublocation from 'viewmodel/AwSublocationViewModel';
import AwDataNavigator from 'viewmodel/AwDataNavigatorViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "type": "board",
                "noBreadCrumb": true
            },
            "priority": 0,
            "label": {
                "source": "/i18n/ReportChartMessages",
                "key": "addReport"
            },
            "clientScopeURI": "Awp0ReportBuilder",
            "nameToken": "com.siemens.splm.reports:createReportTemplate",
            "viewModes": {
                "ImageView": {
                    "primaryWorkArea": "image",
                    "secondaryWorkArea": false
                }
            },
            "viewBase": "createReportTemplate",
            "context": {
                "search": {
                    "hideInContentSearchBox": true
                }
            },
            "useAutoBookmark": true,
            "requestPref": {
                "savedSessionMode": "ignore",
                "showMarkup": [
                    "false"
                ]
            },
            "headerPreference": {
                "hideGlobalSearch": true,
                "hideLogo": true
            },
            "awSubLocationPref": {
                "hideNavigationArea": true,
                "hideRightWall": true,
                "hideFooter": true,
                "orientation": "HORIZONTAL"
            }
        },
        "reportsState": {
            "initialValues": {
                "initRepDisp": true,
                "reportParameters": {},
                "rootClassSampleObject": [],
                "segments": []
            },
            "meta": {
                "segments": [
                    {
                        "type": "viewModelObject",
                        "field": true
                    }
                ]
            }
        },
        "occContext": {
            "initialValues": {
                "transientRequestPref": {}
            },
            "meta": {}
        }
    },
    "_viewModelId": "CreateReportTemplateViewer",
    "_uniqueViewModelId": "CreateReportTemplateViewer",
    "ctx": {
        "modelObjectsToOpen": {
            "type": "object"
        }
    }
};

    /*
    <aw-sublocation class="aw-reports-workAreaCommandBar" sub-panel-context="{provider:data._provider,reportsState:data.reportsState}" sub-location-preference="data._provider.awSubLocationPref">
    <div class="sw-row h-12 afx-content-background">
        <div class="sw-column w-12">
            <aw-data-navigator sub-panel-context="{provider:data._provider, reportsState:data.reportsState, occContext: data.occContext, contextKey:data._provider.contextKey, selectionQueryParamKey : data._provider.selectionQueryParamKey }" provider="data._provider" contextKey="data._provider.contextKey" baseSelection="ctx.modelObjectsToOpen[0]"></aw-data-navigator>
        </div>
    </div>
</aw-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSublocation className="aw-reports-workAreaCommandBar" subPanelContext={AwParseService.instance( "{provider:data._provider,reportsState:fields.reportsState}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subLocationPreference={AwParseService.instance( "data._provider.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <div className="sw-row h-12 afx-content-background">
            <div className="sw-column w-12">
                <AwDataNavigator subPanelContext={AwParseService.instance( "{provider:data._provider, reportsState:fields.reportsState, occContext: fields.occContext, contextKey:data._provider.contextKey, selectionQueryParamKey : data._provider.selectionQueryParamKey }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} contextkey="data._provider.contextKey" baseselection="ctx.modelObjectsToOpen[0]">
                </AwDataNavigator>
            </div>
        </div>
    </AwSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;