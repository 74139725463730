// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "type": "search",
                "vm": "AwSearchBreadcrumb",
                "noUpdate": true,
                "popuplist": "/html/defaultbreadcrumblist.html",
                "overflowIndex": 0,
                "noBreadCrumb": true
            },
            "priority": 7,
            "label": {
                "source": "/i18n/ScheduleManagerMessages",
                "key": "scheduleTemplates"
            },
            "clientScopeURI": "Saw1ScheduleTemplates",
            "nameToken": "scheduleTemplatesSublocation",
            "context": {
                "search": {
                    "provider": "Awp0FullTextSearchProvider",
                    "criteria": {
                        "searchString": "*"
                    },
                    "activeFilterMap": {
                        "WorkspaceObject.object_type": [
                            {
                                "searchFilterType": "StringFilter",
                                "stringValue": "Schedule"
                            }
                        ],
                        "Schedule.is_template": [
                            {
                                "searchFilterType": "StringFilter",
                                "stringValue": "true"
                            }
                        ]
                    },
                    "sortType": "Priority",
                    "chartTitle": {
                        "source": "/i18n/ScheduleManagerMessages",
                        "key": "resultsChartTitle"
                    },
                    "maxToLoad": 50,
                    "maxToReturn": 50
                }
            },
            "viewBase": "Awp0Base"
        }
    },
    "_viewModelId": "scheduleTemplatesPage",
    "_uniqueViewModelId": "scheduleTemplatesPage",
    "ctx": {}
};

    /*
    <aw-search-page tabsdata="subPanelContext.tabsData" provider="data._provider"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;