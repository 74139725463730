// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * Service for Planning / twin / sync creation
 *
 * @module js/EP_syncOperationService
 */
import saveInputWriterService from 'js/saveInputWriterService';
import epSaveService from 'js/epSaveService';
import appCtxService from 'js/appCtxService';
import eventBus from 'js/eventBus';
import localeService from 'js/localeService';
import msgSvc from 'js/messagingService';
import { constants as epBvrConstants } from 'js/epBvrConstants';
import mfeTableSvc from 'js/mfeTableService';
import _ from 'lodash';
import epReloadService from 'js/epReloadService';
import occmgmtBackingObjectProviderService from 'js/occmgmtBackingObjectProviderService';


const showPopupForSync = function(objToSync, ctx ) {
	
	console.log("showPopupForSync");
	console.log(ctx);
	console.log(ctx.userSession.props.fnd0groupmember.displayValues);
	console.log(ctx.userSession.props.group.uiValue);
	
	// Create the modal element
	const modal = document.createElement('div');
	modal.className = 'aw-panel';
	Object.assign(modal.style, {
		display: 'none',
		position: 'fixed',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '300px',
		height: '200px',
		backgroundColor: 'transparent',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: '9999'
	});
	document.body.appendChild(modal);

	// Create the modal content container with a blue outline
	const modalContent = document.createElement('div');
	modalContent.className = 'aw-panel-body';
	Object.assign(modalContent.style, {
		backgroundColor: 'lightcyan',
		padding: '10px',
		borderRadius: '5px',
		textAlign: 'center',
		width: '100%',
		boxSizing: 'border-box',
		color: 'black',
		fontSize: 'small',
		border: '2px solid cadetblue'  // Add blue outline here
	});
	modal.appendChild(modalContent);

	// Create a heading
	const heading = document.createElement('h3');
	heading.textContent = 'Pull Sync will be performed.';
	heading.style.fontSize = 'small';
	modalContent.appendChild(heading);

	// Create a checkbox and label
	const checkbox = document.createElement('input');
	checkbox.type = 'checkbox';
	checkbox.id = 'modalCheckbox';
	const label = document.createElement('label');
	label.className = 'aw-label';
	label.textContent = ' Remove obsolete lines';
	label.style.fontSize = 'small';
	modalContent.append(checkbox, label, document.createElement('br'));

	// Create a close button
	const closeBtn = document.createElement('button');
	closeBtn.textContent = 'Ok';
	closeBtn.style.marginLeft = '10px';
	closeBtn.style.fontSize = 'small';
	closeBtn.style.border= '2px solid gray'
	modalContent.appendChild(closeBtn);

	// Variable to store checkbox state
	let checkboxState = 'unchecked';

	// Event listener to update checkbox state
	checkbox.addEventListener('change', () => {
		checkboxState = checkbox.checked ? 'checked' : 'unchecked';
	});

	// Event listener to show modal and close button
	const showModal = () => modal.style.display = 'flex';


	closeBtn.addEventListener('click', () => {
		//alert('Checkbox is ' + checkboxState);
		modal.style.display = 'none';
		
		let isRemoveObsoleteLine;
		if (checkboxState === 'checked')
		{
			isRemoveObsoleteLine = 'true';
		}
		else{
			isRemoveObsoleteLine = 'false';
		}
		console.log(isRemoveObsoleteLine);

		if(objToSync.type ==='Mfg0BvrPlantBOP' && objToSync.type !== undefined )
		{
			console.log("objToSync.type", objToSync.type);
			w4b_syncBOPToPlant(objToSync, isRemoveObsoleteLine);
		}
		else{
			console.log("objToSync.type", objToSync.type);
			w4b_syncPlantToBOP(objToSync, isRemoveObsoleteLine);
		}
		
	});		
}


/**
 * Create sync for the Plant BOP with associated Plant
 *
 * @param {ModelObject} objToSync - the object to sync
 * @param {boolean} isRemoveObsoleteLine - flag to remove async lines
 */
const w4b_syncBOPToPlant = function( objToSync, isRemoveObsoleteLine) {
	
	console.log("w4b_syncBOPToPlant");
	console.log(objToSync);
	//console.log(objToSync.selected.uid);
	//console.log(checkboxResponse.newDisplayValues);
	console.log(isRemoveObsoleteLine);


    const epPageContext = appCtxService.getCtx( 'epPageContext' );
    const collaborationContext = epPageContext.collaborationContext;
    let objectsToSyncUids = [];
    let objectsToSync = [];

    if( !Array.isArray( objToSync ) ) {
        objectsToSync.push( objToSync );
    } else {
        objectsToSync = objToSync;
    }

    if( isRemoveObsoleteLine === undefined ) {
        isRemoveObsoleteLine = 'false';
    }
    _.each( objectsToSync, function( selectedNode ) {
        if( selectedNode.uid ) {
            objectsToSyncUids.push( selectedNode.uid );
        }
    } );
    if( collaborationContext ) {
        let pageContextModelObject = {
            Object: collaborationContext.uid,
            syncFrom: objectsToSyncUids,
            isRemoveObsoleteTwin: isRemoveObsoleteLine
        };

        let saveInputWriter = saveInputWriterService.get();
        saveInputWriter.addSyncObject( pageContextModelObject );
        saveInputWriter.addRelatedObjects( objectsToSync );
        const additionalLoadParamsForChildLoadInputData = [ {

                tagName: 'expandType',
                attributeName: 'type',
                attributeValue: 'ExpandProcessHighLevelPlanning'
            },
            {
                tagName: 'expandInfo',
                attributeName: 'level',
                attributeValue: 'CHILDREN'
            },
            {
                tagName: 'expandInfo',
                attributeName: 'rootsProperty',
                attributeValue: 'RootObject'
            }
        ];
        epReloadService.registerReloadInput( 'epSync', { reloadTypeName: 'CommonExpand', object: objectsToSync[ 0 ].uid, additionalLoadParams: additionalLoadParamsForChildLoadInputData } );
        return epSaveService.saveChanges( saveInputWriter, true, [ collaborationContext ] ).then( ( response ) => {
            /* Below forEach loop should be removed when TCM-430416 is resolved. */
            objectsToSync[ 0 ].children.forEach( treeNodeToToggle => {
                treeNodeToToggle.isExpanded = false;
                if( response.relatedObjectsMap.hasOwnProperty( treeNodeToToggle.uid ) ) {
                    treeNodeToToggle.isLeaf = !JSON.parse( response.relatedObjectsMap[ treeNodeToToggle.uid ].additionalPropertiesMap2.hasChildren[ 0 ].toLowerCase() );
                }
                eventBus.publish( 'EpTreeTable.plTable.toggleTreeNode', treeNodeToToggle );
            } );

            //No saveResults in case saveChanges fails
            if( response.saveResults ) {
                showSynSuccessMessage( response.saveResults );
            }
            epReloadService.unregisterReloadInput( 'epSync' );
        } );
    }

};

/**
 * Create sync for the Plant with associated plant BOP
 *
 * @param {ModelObject} objToSync - the object to sync
 * @param {boolean} isRemoveObsoleteLine - flag to remove async lines
 */
const w4b_syncPlantToBOP = function( objToSync, isRemoveObsoleteLine ) {
	
	console.log("w4b_syncPlantToBOP");
	console.log(isRemoveObsoleteLine);
    const epPageContext = appCtxService.getCtx( 'epPageContext' );
    const collaborationContext = epPageContext.collaborationContext;
    let objectsToSyncUids = [];
    let objectsToSync = [];

    if( !Array.isArray( objToSync ) ) {
        objectsToSync.push( objToSync );
    } else {
        objectsToSync = objToSync;
    }

    if( isRemoveObsoleteLine === undefined ) {
        isRemoveObsoleteLine = 'false';
    }
    _.each( objectsToSync, function( selectedNode ) {
        if( selectedNode.uid ) {
            objectsToSyncUids.push( selectedNode.uid );
        }
    } );
    if( collaborationContext ) {
        let pageContextModelObject = {
            Object: collaborationContext.uid,
            syncFrom: objectsToSyncUids,
            isRemoveObsoleteTwin: isRemoveObsoleteLine
        };

        let saveInputWriter = saveInputWriterService.get();
        saveInputWriter.addSyncObject( pageContextModelObject );
        saveInputWriter.addRelatedObjects( objectsToSync );

        return epSaveService.saveChanges( saveInputWriter, true, [ collaborationContext ] ).then( ( response ) => {
            if( objectsToSync[ 0 ].type === epBvrConstants.MBC_WORKAREA_ELEMENT ) {
                let nodesToToggle = [];
                let treeDataObject = appCtxService.getCtx( 'occmgmtContext' );
                _.each( treeDataObject.vmc.getLoadedViewModelObjects(), function( child ) {
                    if( objectsToSyncUids.includes( child.uid ) ) {
                        nodesToToggle.push( child );
                    }
                } );
                _.each( nodesToToggle, function( node ) {
                    fireTreeExpandEvent( node, 'occTreeTable' );
                } );
            }
            //No saveResults in case saveChanges fails
            if( response.saveResults ) {
                showSynSuccessMessage( response.saveResults );
            }
        } );
    }

};

/**
 * Show the message stating the sync action was successful.
 * @param {*} saveResults
 */
function showSynSuccessMessage( saveResults ) {
    if( Array.isArray( saveResults ) && saveResults.length > 0 ) {
        for( let resultObj of saveResults ) {
            if( resultObj.saveResultObject.type !== epBvrConstants.ME_COLLABORATION_CONTEXT ) {
                const source = resultObj.saveResultObject.props.object_string.uiValues[ 0 ];
                let localTextBundle = localeService.getLoadedText( 'TwinMessages' );
                let successResponseMessage = localTextBundle.syncSuccessful;
                let msg = successResponseMessage.replace( '{0}', source );
                msgSvc.showInfo( msg );
				//window.location.reload();
			

                // Delay the reload to allow the message to be shown
                setTimeout(function() {
                    window.location.reload();
                }, 2000); // Delay in milliseconds (2 seconds)
            }
        }
    }
}

/**
 * Expand/Collapse selected node
 * @param {Object} treeNodeToToggle tree node to be toggled
 * @param {string} tableID of the table to expand
 */
function fireTreeExpandEvent( treeNodeToToggle, tableID ) {
    /* If this node is already expanded, then we have to collapse and
    then expand this tree to refresh the contents of this node. */
    if( treeNodeToToggle.isLeaf || treeNodeToToggle.isExpanded ) {
        treeNodeToToggle.isExpanded = false;
        eventBus.publish( `${tableID}.plTable.toggleTreeNode`, treeNodeToToggle );
    }
    if( treeNodeToToggle.__expandState ) {
        delete treeNodeToToggle.__expandState;
    }
    treeNodeToToggle.isExpanded = true;
    //Need to run this call in a separate thread to make expanded node refresh correctly.
    setTimeout( function() {
        eventBus.publish( `${tableID}.plTable.toggleTreeNode`, treeNodeToToggle );
    }, 0 );
}

export default {
    w4b_syncBOPToPlant,
    w4b_syncPlantToBOP,
    fireTreeExpandEvent,
	showPopupForSync
};
