// @<COPYRIGHT>@
// ==================================================
// Copyright 2023.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * @module js/G4B_TimeSliceTreeService
 * This service generates time-Slice tree view based on Collaborative designs and provides functionalities w.r.t Time-slice application
 */
import $ from 'jquery';
import _ from 'lodash';
import _appCtxService from "js/appCtxService";
import _soaSvc from "soa/kernel/soaService";
import _awSearchService from 'js/awSearchService';
import _vmoSvc from 'js/viewModelObjectService';
import _cdm from 'soa/kernel/clientDataModel';
import AwPromiseService from 'js/awPromiseService';
import _uwPropertySvc from 'js/uwPropertyService';
import uwPropertySvc from 'js/uwPropertyService';
import _appCtxSvc from 'js/appCtxService';
import eventBus from 'js/eventBus';
import iconService from 'js/iconService';
import viewModelSvc from 'js/viewModelService';
import awTableSvc from 'js/awTableService';
import  localStrg from 'js/localStorage';
import propPolicySvc from 'soa/kernel/propertyPolicyService';

var exports = {};

export let loadTimeSliceTreeTableData = function (treeLoadInput, data,selectionModel, columnConfigInput, saveColumnConfigData, searchInput, policy) {
    var deferredGetTimeSliceTreeData = AwPromiseService.instance.defer();
    if(selectionModel && selectionModel.setMode){
      selectionModel.setMode('single'); // GEPA-17654 Multiple Selection possible in PL
    }
    let tsCtx = _appCtxService.getCtx('g4bTimeSliceTreeContext')
    if(tsCtx != undefined){
        let treeLoadResult = null;
        if(tsCtx.filteredJSONData){
            treeLoadResult = getTreeLoadResult(data, tsCtx.filteredJSONData , treeLoadInput)
            deferredGetTimeSliceTreeData.resolve(treeLoadResult);
        }
        else {
            exports.executeProductLineSmartSearch("", data, null);
            treeLoadResult = getTreeLoadResult(data, _appCtxService.getCtx("g4bTimeSliceTreeContext").filteredJSONData , treeLoadInput)

            deferredGetTimeSliceTreeData.resolve(treeLoadResult);
        }
    }

    else{
        _soaSvc.postUnchecked( 'Internal-AWS2-2023-06-Finder', 'performSearchViewModel5', {
            columnConfigInput: columnConfigInput,
            saveColumnConfigData: saveColumnConfigData,
            searchInput: searchInput,
            inflateProperties: true,
            noServiceData: false
        }, policy )
        .then(
            function( response ) {
                if( response.searchResultsJSON ) {
                    response.searchResults = JSON.parse( response.searchResultsJSON );
                    delete response.searchResultsJSON;
                }

                // Create view model objects
                response.searchResults = response.searchResults && response.searchResults.objects ? response.searchResults.objects
                    .map( function( vmo ) {
                        return _vmoSvc.createViewModelObject( vmo.uid, 'EDIT', null, vmo );
                    } ) : [];

                var collDesObjArr = [];

                response.searchResults.forEach(element => {
                    collDesObjArr.push(_cdm.getObject(element.uid)); 
                });

                //Fix issue with 'Splitter' not showing up in G4B_ProductLines Tree view
                if (_appCtxSvc.getCtx("search") != undefined) {
                    _appCtxSvc.ctx.search.totalFound = collDesObjArr.length;
                } else {
                    _appCtxSvc.search = {
                        totalFound: collDesObjArr.length
                    }
                }

                    let timeSliceTreePromise = generateTimeSliceTreeData(collDesObjArr);
                    $.when(timeSliceTreePromise).done().then(
                        function (timeSliceTreeData) {
                            if (_appCtxService.getCtx('g4bTimeSliceTreeContext') != undefined) {
                                _appCtxService.updateCtx('g4bTimeSliceTreeContext', {
                                    masterJSONData: timeSliceTreeData
                                });
                            }
                            else {
                                _appCtxService.registerCtx('g4bTimeSliceTreeContext', {
                                    masterJSONData: timeSliceTreeData
                                });
                            }
                            exports.executeProductLineSmartSearch("", data, null);
                            let treeLoadResult = getTreeLoadResult(data, _appCtxService.getCtx("g4bTimeSliceTreeContext").filteredJSONData, treeLoadInput)

                        deferredGetTimeSliceTreeData.resolve(treeLoadResult);
                    }
                );    
            }
        );
    }
    return deferredGetTimeSliceTreeData.promise;
};
  
let generateTimeSliceTreeData = function (collDesObjArr) {
    var deferred = AwPromiseService.instance.defer();
    var userProjects;
    var projects = [];

    //Initialize timeslice tree structure data
    var timeSliceTreeData = {
        "data": {
            "top": []
        }
    }

    var user = _cdm.getObject(_appCtxSvc.ctx.userSession.props.user.dbValue);
    var inputs3 = {
        userProjectsInfoInputs: [{
            user: user,
            activeProjectsOnly: true,
            privilegedProjectsOnly: false,
            programsOnly: false,
            clientId: "1"
        }]
    }
    _soaSvc.post("Core-2009-10-ProjectLevelSecurity", "getUserProjects", inputs3).then(
        function (response) {
            userProjects = response.userProjectInfos[0].projectsInfo;
            for (let i = 0; i < userProjects.length; i++) {
                projects.push(userProjects[i].project.props.object_string.dbValues[0]);
            }

            //Store the user projects in context
            if (_appCtxSvc.getCtx("g4bUserProjects") != undefined) {
                _appCtxSvc.updateCtx("g4bUserProjects", projects);
            } else {
                _appCtxSvc.registerCtx("g4bUserProjects", projects);
            }
        }
    ).then(
        function () {
            var inputData = {};

            if (collDesObjArr) {
                inputData.objects = collDesObjArr;
                inputData.attributes = ["g4b_TSInfos2"];
                var policy = {
                    "types": [{
                        "name": "G4B_TSInfo2",
                        "properties": [{
                            "name": "object_name"
                        },
                        {
                            "name": "g4b_BP"
                        },
                        {
                            "name": "g4b_REL2BP"
                        },
                        {
                            "name": "g4b_DER"
                        },
                        {
                            "name": "g4b_SOP"
                        },
                        {
                            "name": "g4b_TSType"
                        },
                        {
                            "name": "G4B_HasVariantRuleRel"
                        },
                        {
                            "name": "G4B_SecondaryTSInfoRel",
                            "modifiers": [ {
                                "name": "withProperties",
                                "Value": "true"
                            } ]
                        }]
                    },
                    {
                        "name": "G4B_GenSecondaryTSInfo",
                        "properties": [{
                            "name": "g4b_IsArchived"
                        }]
                    }
                    ]
                };
                propPolicySvc.register(policy);
                _soaSvc.post("Core-2006-03-DataManagement", "getProperties", inputData).then(
                    function (getPropResp) {
                        propPolicySvc.unregister(policy);
                        if (getPropResp) {
                            for (let inx = 0; inx < collDesObjArr.length; inx++) {
                                let cdObjVmo = _vmoSvc.createViewModelObject(collDesObjArr[inx], null);
                                let cdObjTreeNode = awTableSvc.createViewModelTreeNode(collDesObjArr[inx].uid, collDesObjArr[inx].type, cdObjVmo.cellHeader1, 0, inx, iconService.getTypeIconURL("G4B_CollaborativeDesign"));
                                cdObjTreeNode.props = cdObjVmo.props;
                                cdObjTreeNode.props.object_name = _uwPropertySvc.createViewModelProperty(cdObjVmo.cellHeader1, cdObjVmo.cellHeader1, 'STRING', cdObjVmo.cellHeader1, '');
                                cdObjTreeNode.isLeaf = true;//Initialize as a Leaf node. Leaf status will be updated in func createTimeSliceDerivativesAndTsInfoObj()

                                timeSliceTreeData.data.top.push(cdObjTreeNode);
                                createTimeSliceDerivativesAndTsInfoObj(timeSliceTreeData, cdObjVmo, projects)
                            }
                            deferred.resolve(timeSliceTreeData);
                        }
                    }
                )
            }
        })
    return deferred.promise;
}
var sortByTsKey = function(array, key) {
    return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
    });
}
/**
 * Function to create derivatives and time-slice nodes for a given CD object
 * @param {JSON} timeSliceTreeData Master JSON data for tree structure
 * @param {modelObject} cdObj Collaborative design object
 * @param {StringArray} projects An array of projects
 */
let createTimeSliceDerivativesAndTsInfoObj = function (timeSliceTreeData, cdObj, projects) {
    var uniqueDERArr = [];
    let tsInfoObj;
    //Check: If we are opening product line via sublocation or quick access panel
    let isProductLineSublocation = (_appCtxService.getCtx('sublocation.nameToken') == "G4B_ProductLines" || _appCtxService.getCtx('activeNavigationCommand.commandId') == "g4b_cmdQuickAccess") ? true : false;
    let loggedInRoleName = _appCtxSvc.ctx.userSession.props.role_name.dbValue;
    //Loop to create Time Slice Objs and unique DER objs
    for (let inx = 0; inx < cdObj.props.g4b_TSInfos2.dbValues.length; inx++) {
        tsInfoObj = _vmoSvc.createViewModelObject(cdObj.props.g4b_TSInfos2.dbValues[inx], null);
        
        // checking project rights if the role is non-'Gepard IT Admin' for productLines sublocation 
        // Case: When time-slice tree is displayed in the popup after subset creation, show only time-slices for which user has project rights. 
        // if (tsInfoObj && (isProductLineSublocation && loggedInRoleName == 'Gepard IT Admin' ||
        //     isProductLineSublocation && loggedInRoleName != 'Gepard IT Admin' && projects.indexOf(tsInfoObj.props.g4b_DER.dbValues[0]) != -1 ||
        //     !isProductLineSublocation && projects.indexOf(tsInfoObj.props.g4b_DER.dbValues[0]) != -1)) {
        if(tsInfoObj){
            //Create empty node for CD
            if (timeSliceTreeData.data[cdObj["uid"]] == undefined) {
                timeSliceTreeData.data[cdObj["uid"]] = [];
            }

            //Check If a unique DER is found then create a New DER object
            if(uniqueDERArr.indexOf(tsInfoObj.props.g4b_DER.dbValues[0]) == -1){
                uniqueDERArr.push(tsInfoObj.props.g4b_DER.dbValues[0]);
                let derObjectName = tsInfoObj.props.g4b_DER.dbValues[0];
                let derModelObjUid = derObjectName + '-' + cdObj.uid;
                let derModelObj = awTableSvc.createViewModelTreeNode(derModelObjUid, "", tsInfoObj.props.g4b_DER.dbValues[0], 1, uniqueDERArr.length, iconService.getTypeIconURL("DesignSubsetElement"));
                derModelObj.props= {
                    object_name : _uwPropertySvc.createViewModelProperty( derObjectName, derObjectName, 'STRING', derObjectName, '' )
                }
                derModelObj.isLeaf = false;

                timeSliceTreeData.data[cdObj["uid"]].push(derModelObj);
                timeSliceTreeData.data[derModelObj["uid"]] = [];

                //Change the leaf node status to false for the parent CD object
                setLeafNodeStatusOnTreeNode( timeSliceTreeData, cdObj.uid ,false)
            }

            //Add additional props to object if required

            let timeSliceString = tsInfoObj.props.object_name.dbValues[0];
            let derUID = tsInfoObj.props.g4b_DER.dbValues[0] + '-' + cdObj.uid;

            let tsInfoTreeObj = awTableSvc.createViewModelTreeNode(tsInfoObj.uid, tsInfoObj.type, timeSliceString, 2, inx,iconService.getTypeIconURL("FeaturePackage"));
            tsInfoTreeObj.props = tsInfoObj.props;
            tsInfoObj.props.parentUid = cdObj.uid;
            tsInfoTreeObj.isLeaf = true;

            //Create column prop to show relavent BuildPhase
            let g4b_BPColumnPropVal = tsInfoObj.props.g4b_BP.dbValues[0];
            if(tsInfoObj.props.g4b_TSType.dbValues[0] == '1'){
                g4b_BPColumnPropVal = tsInfoObj.props.g4b_REL2BP.dbValues[0]
            }
            tsInfoTreeObj.props.g4b_BPColumnProp =_.clone(_uwPropertySvc.createViewModelProperty( "g4b_BPColumnProp", tsInfoObj.props.g4b_BP.propertyDisplayName, 'STRING', g4b_BPColumnPropVal, [g4b_BPColumnPropVal] ));

            //Create and set archive prop from Secondary Time slice info object to TSInfo obj
            if(tsInfoObj.props.G4B_SecondaryTSInfoRel.dbValues[0] != null){
                let g4b_IsArchivedProp = _cdm.getObject(tsInfoObj.props.G4B_SecondaryTSInfoRel.dbValues[0]).props.g4b_IsArchived;
                tsInfoTreeObj.props.g4b_IsArchived =_.clone(g4b_IsArchivedProp);
            }
            else{
                tsInfoTreeObj.props.g4b_IsArchived =_.clone(_uwPropertySvc.createViewModelProperty( "g4b_IsArchived", "g4b_IsArchived", 'BOOLEAN', null));
                tsInfoTreeObj.props.g4b_IsArchived.isNull = true;
            }
            
            timeSliceTreeData.data[derUID].push(tsInfoTreeObj);
            sortByTsKey(timeSliceTreeData.data[derUID],"displayName");
        }
    }
}
/**
 * Sets the required isLeaf status on tree node with the given uid
 * @param {JSON} timeSliceTreeData Master JSON data for tree structure
 * @param {String} nodeUid 
 * @param {Boolean} leafStatus 
 * @returns 
 */

let setLeafNodeStatusOnTreeNode = function(timeSliceTreeData, nodeUid, leafStatus){
    for (let key in timeSliceTreeData.data) {
        for (let index = 0; index < timeSliceTreeData.data[key].length; index++) {
            if(timeSliceTreeData.data[key][index].uid == nodeUid){
                timeSliceTreeData.data[key][index].isLeaf = leafStatus;
                return;
            }
        }
    }
}

/**
 * This function is used to apply the time slice effectivity intent on the collaborative design.
 * @param {json} selected selected Time Slice Object
 */

export let getPreviousTSpreference = function (response, data) {
    var prefValue = [];
    prefValue = response.response[0].values.values;

    if (prefValue.length > 0) {
        if (data.dataProviders.timeSliceTreeDataProvider.selectedObjects.length == 0) {
            let tsLast = prefValue[prefValue.length - 1];
            let tsLastSplit = tsLast.split(':');
            uwPropertySvc.setValue(data.lastTimeSliceUrl, tsLastSplit[1]);
            uwPropertySvc.setValue(data.lastTimeSliceUid, tsLastSplit[0]);

            if (_appCtxSvc.ctx.lastTimeSlice != undefined) {
                _appCtxSvc.updateCtx("lastTimeSliceUid", tsLastSplit[0]);
                _appCtxSvc.updateCtx("lastTimeSliceName", tsLastSplit[1]);
            }
            else {
                _appCtxSvc.registerCtx("lastTimeSliceName", tsLastSplit[1]);
                _appCtxSvc.registerCtx("lastTimeSliceUid", tsLastSplit[0]);
            }

        }
        else {
            let foundFlag = false;
            for (let i = 0; i < prefValue.length; i++) {
                let tsSplit = prefValue[i].split(':');
                if (data.dataProviders.timeSliceTreeDataProvider.selectedObjects[0].uid == tsSplit[0]) {
                    uwPropertySvc.setValue(data.lastTimeSliceUrl, tsSplit[1]);
                    uwPropertySvc.setValue(data.lastTimeSliceUid, tsSplit[0]);
                    if (_appCtxSvc.ctx.lastTimeSlice != undefined) {
                        _appCtxSvc.updateCtx("lastTimeSliceUid", tsSplit[0]);
                        _appCtxSvc.updateCtx("lastTimeSliceName", tsSplit[1]);

                    }
                    else {
                        _appCtxSvc.registerCtx("lastTimeSliceName", tsSplit[1]);
                        _appCtxSvc.registerCtx("lastTimeSliceUid", tsSplit[0]);
                    }
                    foundFlag = true;
                    break;
                }
            }
            if (!foundFlag) {
                uwPropertySvc.setValue(data.lastTimeSliceUrl, "");
            }
        }
    }

    return prefValue;
};

export let initTimesliceEffectivityIntent = function (selected, isPrevTSHyperlink, openType, commandContext) {
    console.log(commandContext);
    //Unregister variant config context vars when opening a time-slice
    _appCtxSvc.unRegisterCtx('variantConfigContext');
    _appCtxSvc.unRegisterCtx('isAppliedVariantConfigurationModified');
    _appCtxSvc.unRegisterCtx('isAppliedVariantConfiguration');
    _appCtxSvc.unRegisterCtx('appliedVariantConfiguration');
    _appCtxSvc.unRegisterCtx('isCustomConfigurationApplied');

    //Remove if 'isCDOpenedInNewTabOrWindow'local storage variable exists
    localStrg.removeItem("isCDOpenedInNewTabOrWindow");

    //tsCtx can be an array with sequential data of opt.values 
    var der, bp, sop;
    var tsCtx = _appCtxSvc.getCtx("g4b_TimeSliceContext");

    var LastTimesliceUid = _appCtxService.getCtx("lastTimeSliceUid");
    var LastTimesliceName = _appCtxService.getCtx("lastTimeSliceName");
    if (isPrevTSHyperlink) {
        let prevTSUrl = LastTimesliceName;
        if (prevTSUrl.indexOf('-R-') > -1) {
            let prevTS = prevTSUrl.split('-');
            der = prevTS[0]
            bp = prevTS[4]
            sop = prevTS[2].concat(prevTS[3]);
        }
        else {
            let prevTS = prevTSUrl.split('_');
            der = prevTS[0];
            bp = prevTS[1];
            sop = prevTS[2];
        }

    }
    else {
        der = selected.props.g4b_DER.dbValue;
        bp = selected.props.g4b_BP.dbValue;
        sop = selected.props.g4b_SOP.dbValue;
    }

    var TSgroupSelections = [];
    var family = ['BP', 'DER', 'SOP'];
    tsCtx = [bp, der, sop];

    for (var index = 0; index < family.length; index++) {
        var familyAndOptionValue = {
            familyName: family[index],
            optionValue: tsCtx[index]
        };
        TSgroupSelections.push(familyAndOptionValue);

    }
    if (_appCtxSvc.getCtx("revRuleEffectivityData") != undefined) {
        _appCtxSvc.updateCtx("revRuleEffectivityData", TSgroupSelections);
    } else {
        _appCtxSvc.registerCtx("revRuleEffectivityData", TSgroupSelections);
    }

    createTimeSliceIntentFormula(openType, {"BP": bp, "DER": der, "SOP": sop});

    //Update Last Used time-slice preference
    let prefValue = _appCtxSvc.ctx.previousTimeSliceArr;
    //check the array and update the values as per selected TS
    if (isPrevTSHyperlink == false) {
        for (var index = 0; index < prefValue.length; index++) {
            let tsSplit = prefValue[index].split(':');
            if (selected.props.parentUid == tsSplit[0]) {
                prefValue.splice(index, 1);
                break;
            }
        }
        let tsStr = selected.props.parentUid + ":" + selected.props.object_name.dbValues[0];
        prefValue.push(tsStr);
    }
    else {
        for (var index = 0; index < prefValue.length; index++) {
            let tsSplit = prefValue[index].split(':');
            if (LastTimesliceUid == tsSplit[0]) {
                prefValue.splice(index, 1);
                let tsStr = LastTimesliceUid + ":" + LastTimesliceName;
                prefValue.push(tsStr);
                break;
            }
        }

    }

    var inputData3 = {
        setPreferenceIn: [{
            location: {
                location: "user"
            },
            preferenceInputs: {
                preferenceName: "G4B_AWC_PreviousTimeSliceOnSubset",
                values: prefValue
            }
        }]
    }
    _soaSvc.post("Administration-2012-09-PreferenceManagement", "setPreferencesAtLocations", inputData3).then(function () {
    });
};

/**
 * Generate the Revision Effectivity intent formula based on the time-slice selected
 * @param {String} openType Type of open CD newTab/newWindow/SameTab
 * @param {Boolean} timeSliceParams TSInfo2 parameters (DER,BP,SOP)
 */
export let createTimeSliceIntentFormula = function (openType, timeSliceParams) {
    let selected = _appCtxSvc.getCtx("selected");
    let LastTimesliceUid = _appCtxService.getCtx("lastTimeSliceUid");
    //let LastTimesliceName = _appCtxService.getCtx("lastTimeSliceName");
    let SVRRules = [];
    // let LastTimesliceName = _appCtxService.getCtx("lastTimeSlice.name");
    let collaborativeDesignUid = "";
    if(selected){
        if(selected.type === 'G4B_CollaborativeDesign'){
            collaborativeDesignUid = selected.uid;
        }
        else{
            collaborativeDesignUid = selected.props.parentUid;
        }
    }
    else if (LastTimesliceUid) {
        collaborativeDesignUid = LastTimesliceUid;
    }

    let collaborativeDesignObj = _cdm.getObject(collaborativeDesignUid)

    let context = _appCtxSvc.getCtx("revRuleEffectivityData");
    if (context != undefined) {
        let namespace = collaborativeDesignObj.props.g4b_EffProductNameSpace.dbValues[0];
        var formula = "";
        var concateAmp = "&"

        for (var i = 0; i < context.length - 1; i++) {
            var _formula = '[' + namespace + ']' + context[i].familyName + '=' + context[i].optionValue + concateAmp
            formula = formula + _formula;
        }

        formula = formula + '[' + namespace + ']' + context[context.length - 1].familyName + '=' + context[context.length - 1].optionValue;        

        //GEPA-12549 ( Implementation: Apply initial time-slice product configuration)
        for (let jnx = 0; jnx < collaborativeDesignObj.props.g4b_TSInfos2.dbValues.length; jnx++) {
            let tsInfo2Obj = _cdm.getObject(collaborativeDesignObj.props.g4b_TSInfos2.dbValues[jnx]);
            if (tsInfo2Obj.props.g4b_DER.dbValues[0] == timeSliceParams.DER && tsInfo2Obj.props.g4b_BP.dbValues[0] == timeSliceParams.BP && 
                tsInfo2Obj.props.g4b_SOP.dbValues[0] == timeSliceParams.SOP && tsInfo2Obj.props.G4B_HasVariantRuleRel.dbValues.length > 0) {
                    
                    SVRRules.push(tsInfo2Obj.props.G4B_HasVariantRuleRel.dbValues[0]);
                    break;
            }
        }
        subscribeForApplyRevisionEffectivityIntentToCD(formula, SVRRules);

        if (openType == null) {
            eventBus.publish("openProductLine_Event");
        }
        
    }
}

/**
 * This function updates the product line with the new effectivity intent formula
 * @param {String} formula String formula
 * @param {Array} SVRRules Time-slice SVR array
 */
var subscribeForApplyRevisionEffectivityIntentToCD = function (formula, SVRRules) {
    var eventSubscription = eventBus.subscribe('G4BLoadTSPropertiesOnSecondaryWorkAreaLoaded', function () {
        var productContextInfo = _appCtxSvc.getCtx('aceActiveContext').context.productContextInfo;
        if (SVRRules.length == 0) {
            SVRRules = [''];
        }

        if (productContextInfo != undefined) {
            let eventData = {
                "value": {
                    "configContext": {
                        "r_uid": productContextInfo.props.awb0CurrentRevRule.dbValues[0],
                        "var_uids": SVRRules,
                        "fromUnit": 1,
                        "toUnit": 1,
                        "startDate": productContextInfo.props.awb0StartEffDates.dbValues[0],
                        "endDate": productContextInfo.props.awb0EndEffDates.dbValues[0],
                        "intentFormula": formula,
                        "startFreshNavigation": true
                    }
                }
            };

            eventBus.publish( 'setTimesliceIntentEvent',  eventData );
            eventBus.unsubscribe(eventSubscription);
        }
    });
}

export let getTreeLoadResult = function(data, treeData, treeLoadInput){

    var response = treeData.data[ treeLoadInput.parentNode.uid ];
    _.forEach( response, function( treeNode ) {
        treeNode.id = treeNode.uid;
        treeNode.levelNdx = treeLoadInput.parentNode.levelNdx + 1;
        if(treeNode.type == "G4B_TSInfo2"){
            treeNode.typeIconURL = iconService.getTypeIconURL('FeaturePackage');
        }
        else if(treeNode.type == "G4B_CollaborativeDesign"){
            treeNode.typeIconURL = iconService.getTypeIconURL("G4B_CollaborativeDesign");
        }
        else {
            treeNode.typeIconURL = iconService.getTypeIconURL("DesignSubsetElement");
        }
    } );
    var tempCursorObject = {
        startReached: true,
        endReached: true
    };

    // setting cursorObject fixes bug in dataProviderFactory
    treeLoadInput.parentNode.cursorObject = tempCursorObject;

    var treeLoadResult = {
        parentNode: treeLoadInput.parentNode,
        childNodes: response,
        totalChildCount: response.length,
        startChildNdx: 0
    };
    // put the treeLoadResult into data
    data.treeLoadResult = treeLoadResult;
    return {
        treeLoadResult: treeLoadResult
    };
}

/**
 * This function resets the expansion states from the input tree Node data
 * @param {json} jSONData Tree Node data
 */
let removeExpansionCacheData = function(jSONData){
    for (let key in jSONData.data) {
        for (let inx = 0; inx < jSONData.data[key].length; inx++) {
            if(jSONData.data[key][inx].isExpanded){
                delete jSONData.data[key][inx].isExpanded;
            }
        }
    }
}
 
/**
 * This function creates a filteredJSONData based on the masterJSONData to show the required search results
 * @param {String} searchCriteria string containing the search term entered by user
 */
export let executeProductLineSmartSearch =function(searchCriteria, data , isArchiveCheckboxAction){
    //Clear expansion data before executing search
    let masterJSONData = _appCtxService.getCtx('g4bTimeSliceTreeContext.masterJSONData');
    removeExpansionCacheData(masterJSONData);
    let filteredJSONData = _appCtxService.getCtx('g4bTimeSliceTreeContext.filteredJSONData');
    if(filteredJSONData){
        removeExpansionCacheData(filteredJSONData);
    }

    if(searchCriteria.trim().length == 0 && data.showArchivedTimeSliceCheckbox.dbValue == true){
        //Reset the filtered data if user enters NO/Empty search criteria
        if(_appCtxService.ctx.g4bTimeSliceTreeContext.filteredJSONData){
            _appCtxService.ctx.g4bTimeSliceTreeContext.filteredJSONData = JSON.parse(JSON.stringify(masterJSONData));
        }
        eventBus.publish('timeSliceTreeGrid.plTable.reload');
    }

    else{
        let searchCriteriaArr = []; 

        if(searchCriteria.trim().length > 0){
            searchCriteriaArr = searchCriteria.split(/[;\s,*_]+/); //create array by removing delim i.e commas,semicolons,spaces,newline, star,underscore
            searchCriteriaArr = searchCriteriaArr.filter(Boolean); //remove elements that are falsy, which include an empty string "", 0, NaN, null, undefined, and false.
        }

    
        let tsTreeCtxMasterDataCopy = JSON.parse(JSON.stringify(masterJSONData));
        if(tsTreeCtxMasterDataCopy != undefined){
            for (let key in tsTreeCtxMasterDataCopy.data) {
                for (let index = 0; tsTreeCtxMasterDataCopy.data[key] != undefined && index < tsTreeCtxMasterDataCopy.data[key].length; index++) {
                    if(tsTreeCtxMasterDataCopy.data[key][index].type == "G4B_TSInfo2"){
                        let tsArchiveStatus = false;
                        if(tsTreeCtxMasterDataCopy.data[key][index].props.G4B_SecondaryTSInfoRel.dbValues.length > 0){
                            tsArchiveStatus = _cdm.getObject(tsTreeCtxMasterDataCopy.data[key][index].props.G4B_SecondaryTSInfoRel.dbValues[0]).props.g4b_IsArchived.dbValues[0] == "1" ? true : false;
                        }
                        let tsStringToMatch = tsTreeCtxMasterDataCopy.data[key][index].props.object_name.dbValue;
                        //Adjustment for filtration in case of a release time slice
                        if(tsTreeCtxMasterDataCopy.data[key][index].props.g4b_TSType.dbValues[0] == "1"){
                            tsStringToMatch += '-' + tsTreeCtxMasterDataCopy.data[key][index].props.g4b_REL2BP.dbValues[0];
                        }
                        if(tsTreeCtxMasterDataCopy.data[key][index])
                        //Removing TimeSlices that don`t match Filter or Archive criteria
                        if( searchCriteriaArr.length > 0 && !matchTSInSearchCriteriaArr(tsStringToMatch , searchCriteriaArr) || 
                            data.showArchivedTimeSliceCheckbox.dbValue == false && tsArchiveStatus == true){

                            tsTreeCtxMasterDataCopy.data[key].splice(index, 1);
                            if (tsTreeCtxMasterDataCopy.data[key].length == 0) {
                                delete tsTreeCtxMasterDataCopy.data[key];
                                //set the parent DER node as leaf if all child TSInfo`s are filtered Out
                                let derUid = key;
                                let cdUid = key.substring(key.indexOf('-') + 1,key.length);
                                for (let jnx = 0; jnx < tsTreeCtxMasterDataCopy.data[cdUid].length; jnx++) {
                                    if(tsTreeCtxMasterDataCopy.data[cdUid][jnx].uid == derUid){
                                        tsTreeCtxMasterDataCopy.data[cdUid][jnx].isLeaf = true;
                                    }
                                }
                                //set the parent CD nodes as leaf if all child TSInfo`s are filtered Out
                                for (let knx = 0; knx < tsTreeCtxMasterDataCopy.data["top"].length; knx++) {
                                    let isCDLeaf = true;
                                    let cdUid = tsTreeCtxMasterDataCopy.data["top"][knx].uid;
                                    for (let lnx = 0; tsTreeCtxMasterDataCopy.data[cdUid] != undefined && lnx < tsTreeCtxMasterDataCopy.data[cdUid].length; lnx++) {
                                        if(!tsTreeCtxMasterDataCopy.data[cdUid][lnx].isLeaf){
                                            isCDLeaf = false
                                            break;
                                        }
                                    }
                                    if(isCDLeaf){
                                        tsTreeCtxMasterDataCopy.data["top"][knx].isLeaf = true;
                                    }
                                }
                            }   
                            else{
                                index--;
                            }
                        }
                    }

                }
            }
            _appCtxService.ctx.g4bTimeSliceTreeContext.filteredJSONData = tsTreeCtxMasterDataCopy;
            eventBus.publish('timeSliceTreeGrid.plTable.reload');
        }
        //Auto Expand
        if(searchCriteria == "*" || searchCriteriaArr.length > 0){
            let treeLoadedEventSub = eventBus.subscribe('timeSliceTreeDataProvider.treeNodesLoaded', function(){
                eventBus.unsubscribe(treeLoadedEventSub);
                _.defer(function(){
                    g4bExpandAllTreeNodes(data);
                })
            });
        }

    }
}

/**
 * Function to match a string with an array of expressions
 * @param {string} string 
 * @param {string Array} searchCriteriaArr 
 * @returns 
 */

let matchTSInSearchCriteriaArr = function(string, searchCriteriaArr) {
    for (let i = 0; i < searchCriteriaArr.length; i++) {
        if (!string.toLowerCase().match(searchCriteriaArr[i].toLowerCase())) {
            return false;
        }
    }
    return true;
};

let g4bExpandAllTreeNodes = function(data){
    let timeSliceTreeDataProvider = data.dataProviders.timeSliceTreeDataProvider;
    const dataCtxNode = {
        data: data,
        ctx: _appCtxService.ctx
    };

    let filteredJSONData = _appCtxService.ctx.g4bTimeSliceTreeContext.filteredJSONData;
    let nodesToExpandArr = [];
    //We are dealing with a 3 level tree structure. Filling the nodesToExpandArr in sequence of ascending treeLevel
    for (let treeLevel = 0; treeLevel < 3; treeLevel++) {
        for (let key in filteredJSONData.data) {
            for (let index = 0; index < filteredJSONData.data[key].length; index++) {
                if(filteredJSONData.data[key][index].$$treeLevel == treeLevel){
                    nodesToExpandArr.push(filteredJSONData.data[key][index])
                }
            }
        }
    }
    //Executing the deffered expansion tasks sequentially
    var dfd = $.Deferred().resolve();
    nodesToExpandArr.forEach(function(node){
        if(node.isExpanded == undefined && (node.isLeaf == undefined || node.isLeaf == false)){
            dfd = dfd.then(function(){
                return g4bExpandNode(timeSliceTreeDataProvider, dataCtxNode, node)
            }).then(function(){
                //console.log("done");
            });
        }

    });
}
let g4bExpandNode = function(timeSliceTreeDataProvider,dataCtxNode, node){
    let deferred = AwPromiseService.instance.defer();

    node.isExpanded=true;
    timeSliceTreeDataProvider.expandObject(dataCtxNode,node).then( ( updatedViewModelCollection ) => {
        timeSliceTreeDataProvider.update( updatedViewModelCollection.loadedVMObjects );
    });;
    let treeLoadedEventSub = eventBus.subscribe('timeSliceTreeDataProvider.treeNodesLoaded', function(){
        eventBus.unsubscribe(treeLoadedEventSub);
        deferred.resolve();
    });
    return deferred.promise;
}

export let getInternalPropertyName = function () {
    return _awSearchService.getInternalPropertyName();
};

export let getDataProvider = function (filterMap) {
    return _awSearchService.getDataProvider(filterMap);
};

export let getSearchCriteria = function (filterMap) {
    return _awSearchService.getSearchCriteria(filterMap);
};

export let g4bGetCustomSearchSortCriteria = function (sortCriteria, data) {
    return _awSearchService.getSearchSortCriteria(sortCriteria, data);
  };

export let getCDObjectData = function (data) {
    let bookmarkObjForEffectivityData = data.bookmarkObjForEffectivityData;
    let awb0BookmarkProductDataObj = _cdm.getObject(bookmarkObjForEffectivityData.props.awb0OwningObject.dbValues[0]);
    

    let inputData = {
        objects: [{
            uid: awb0BookmarkProductDataObj.uid,
            type: awb0BookmarkProductDataObj.type
        }],
        attributes: ["awb0Product"]
    };

    _soaSvc.post("Internal-Core-2007-12-Session", "getProperties", inputData).then(
        function (response) {
            if (response) {
                let cdObject;

                _.forEach(response.modelObjects, function (element) {
                    if (element.type === "G4B_CollaborativeDesign") {
                       cdObject= element
                    }
                });
                _appCtxSvc.updateCtx("g4bCDObjectForGlobalLastTimeslice", cdObject);

                eventBus.publish("setPreviousTimeSliceOnCDctxEvent");
            }
    })
};

export let setPreviousTimeSliceOnCDctx = function (data) {
    let bookmarkObjForEffectivityData = data.bookmarkObjForEffectivityData;
    if (bookmarkObjForEffectivityData ) {

        var currentEffectivity = bookmarkObjForEffectivityData.props.fgf0EffectivityFormula.dbValues[0];
        if (currentEffectivity != null && currentEffectivity.indexOf('DER') > -1 && currentEffectivity.indexOf('BP') > -1 && currentEffectivity.indexOf('SOP') > -1) {
            var _curr = currentEffectivity.split("&");

            var sop = _curr[1].split('= ');
            sop = sop[1].split(" ");
            var der = _curr[2].split('= ');
            der = der[1].split(" ");
            var bp = _curr[3].split('= ');
            bp = bp[1].split(" ");

            var tsOption = der[0] + '_' + bp[0] + '_' + sop[0];
            var selectedTs = {
                g4b_der: der[0],
                g4b_bp: bp[0],
                g4b_sop: sop[0],
                g4b_quickSelect: tsOption
            }
            data.lastTimeSliceUrl.propertyDisplayName = selectedTs.g4b_quickSelect;
            if (_appCtxSvc.ctx.g4b_PreviousTimeSliceOnCD) {
                _appCtxSvc.updateCtx("g4b_PreviousTimeSliceOnCD", selectedTs);
            }
            else {
                _appCtxSvc.registerCtx("g4b_PreviousTimeSliceOnCD", selectedTs);
            }
        }
    }
    else {
        data.lastTimeSliceUrl.propertyDisplayName = ""
    }
};

/**
 * Load columns for Timeslice tree display
 *
 * @param {Object} dataprovider - the data provider
 * @param {Object} data - the data
 * @param {Object} i18n - i18n
 */
 export let loadTimesliceTreeColumns = function( dataprovider, data , i18n) {
    var colInfos = [ {
        propertyName: 'object_name',
        minWidth: 250,
        width: 300,
        isTreeNavigation : true,
        typeName: 'WorkspaceObject'
    },
    {
        propertyName: 'g4b_BPColumnProp',
        displayName: i18n.G4B_BuildPhaseColumnProp,
        minWidth: 125,
        width: 150,
        typeName: 'G4B_TSInfo2'
    } ];

    // Add (any) additional cols if defined at G4B_TimeSliceTreeColumns preference
    let timeSliceTreeColumnsPref = _appCtxService.ctx.preferences.G4B_TimeSliceTreeColumns
    if(timeSliceTreeColumnsPref){
        for (let inx = 0; inx < timeSliceTreeColumnsPref.length; inx++) {
            if(timeSliceTreeColumnsPref[inx].trim().length >0){
                colInfos.push ({
                    propertyName: timeSliceTreeColumnsPref[inx],
                    minWidth: 150,
                    width: 250,
                    typeName: 'G4B_TSInfo2'
                })
            }
        }
    }

    dataprovider.columnConfig = {
        columns: colInfos,
        operationType: "Union",
        typesForArrange: [
            "G4B_CollaborativeDesign",
            "G4B_TSInfo2"
        ]
    };
};

export let saveTimesliceInLocalStorage = function(data){
    /*TODO:commented 09122023
    let selectedTs = {};
    if(_appCtxService.getCtx('sublocation.nameToken') == "G4B_ProductLines"){
        selectedTs = {
            g4b_BP: _appCtxService.ctx.selected.props.g4b_BP.dbValues[0],
            g4b_DER: _appCtxService.ctx.selected.props.g4b_DER.dbValues[0],
            g4b_SOP: _appCtxService.ctx.selected.props.g4b_SOP.dbValues[0],
            g4b_objectName: _appCtxService.ctx.selected.props.object_name.dbValue,
            referenceUid: _appCtxService.ctx.selected.props.parentUid
        }
    }
    localStrg.publish("TimeSlice",  JSON.stringify(selectedTs));
    */
}

/**
 * @memberof NgServices
 * @member G4B_TimeSliceTreeService
 */
export default exports = {
    getInternalPropertyName,
    getDataProvider,
    getSearchCriteria,
    g4bGetCustomSearchSortCriteria,
    loadTimeSliceTreeTableData,
    subscribeForApplyRevisionEffectivityIntentToCD,
    initTimesliceEffectivityIntent,
    executeProductLineSmartSearch,
    setPreviousTimeSliceOnCDctx,
    getCDObjectData,
    loadTimesliceTreeColumns,
    saveTimesliceInLocalStorage,
    getPreviousTSpreference,
    createTimeSliceIntentFormula
};