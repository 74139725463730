// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import epObjectPropertyCacheService from 'js/epObjectPropertyCacheService';
import epGraphicsService from 'js/epGraphicsService';
import epSingleViewerCacheService from 'js/epSingleViewerCacheService';
import appCtxService from 'js/appCtxService';
import { constants as epGraphicsConstants } from 'js/epGraphicsConstants';
import { constants as epBvrConstants } from 'js/epBvrConstants';
import mfeTypeUtils from 'js/utils/mfeTypeUtils';
import cdm from 'soa/kernel/clientDataModel';

/**
 * EP graphics assignment indication service
 *
 * @module js/epGraphicsAssignmentIndicationService
 */
'use strict';


/**
 * This method sets objects in focus based on assignment type
 * @param {string} viewerInstanceId:viewerInstanceId
 * @param {String} filter Filter type
 * @param {bool} isCurrentFilterApplied if filter is applied no need to update CTX
 */
function epGraphicsSetFilter( viewerInstanceId, filter, isCurrentFilterApplied ) {
    !isCurrentFilterApplied && epGraphicsSetFilterInCtx( filter );

    if ( !viewerInstanceId ) {
        return;
    }
    //get all the visible nodes(parts) from viewer
    const idToVisibilityMap = epGraphicsService.getAllTheVisibleNodesInViewer( viewerInstanceId );

    let nodesToFocus = [];
    let nodesOutOfFocus = [];
    for( let [ uid, visibility ] of idToVisibilityMap.entries() ) {
        if( visibility === true ) {
            //get all the first level parts of assembly
            getGraphicsFilterForObjectStatus( filter, uid, nodesToFocus, nodesOutOfFocus );
        }
    }
    setPMIsToNodesOutOfFoucs( viewerInstanceId );
    setTransparencyForObjects( viewerInstanceId, nodesToFocus, nodesOutOfFocus );
}

/**
 *
 * @param {Integer} filter AssignmentFilter
 */
function epGraphicsSetFilterInCtx( filter ) {
    appCtxService.updatePartialCtx( epGraphicsConstants.EP_ASSIGNMENT_INDICATION_GRAPHICS_FILTER, filter[0] );
}
/**
 *
 * @param {String} viewerInstanceId viewerInstanceId
 * @param {Objest} nodesToFocus objects to focus in viewer
 * @param {*} nodesOutOfFocus objects to be transparent in viewer
 */
function setTransparencyForObjects( viewerInstanceId, nodesToFocus, nodesOutOfFocus ) {
    nodesOutOfFocus.length > 0 &&  epGraphicsService.setTransparencyById( viewerInstanceId,  nodesOutOfFocus,  0.1 );
    nodesToFocus.length > 0 && epGraphicsService.setTransparencyById( viewerInstanceId,  nodesToFocus, 1.0 );
}

/**
 *
 * @param {String} viewerInstanceId viewerInstanceId
 * @param {Objest} nodesToFocus objects to focus in viewer
 * @param {*} nodesOutOfFocus objects to be transparent in viewer
 */
function setTransparencyForPMIs( viewerInstanceId, nodesToFocus, nodesOutOfFocus ) {
    nodesOutOfFocus && nodesOutOfFocus.length > 0 && epGraphicsService.setPMITransparencyById( viewerInstanceId,  nodesOutOfFocus,  0.1 );
    nodesToFocus && nodesToFocus.length > 0 && epGraphicsService.setPMITransparencyById( viewerInstanceId,  nodesToFocus, 1.0 );
}

/**
 *
 * @param {*} eventMap eventMap
 * @param {*} viewerInstanceId  viewerInstanceId
 */
function handleAccountibilityUpdateEvents( viewerInstanceId, objUids, modelIdToVisibilityMap ) {
    const filter = appCtxService.getCtx( epGraphicsConstants.EP_ASSIGNMENT_INDICATION_GRAPHICS_FILTER );
    if ( !filter ) {
        return;
    }
    if ( !modelIdToVisibilityMap ) {
        modelIdToVisibilityMap = epGraphicsService.getAllTheVisibleNodesInViewer( viewerInstanceId );
    }
    if( !Array.isArray( objUids ) ) {
        objUids = [ objUids ];
    }
    let nodesToFocus = [];
    let nodesOutOfFocus = [];
    objUids.filter( ( uid )=>{
        return modelIdToVisibilityMap.has( uid ) && modelIdToVisibilityMap[uid];
    } );

    objUids.forEach( uid => {
        getGraphicsFilterForObjectStatus( filter, uid, nodesToFocus, nodesOutOfFocus );
    } );
    const vmosToFocus = [];
    const vmosOutOfFocus = [];
    for( let [ uid, visibility ] of modelIdToVisibilityMap.entries() ) {
        if( visibility === true ) {
            nodesToFocus.indexOf( uid ) > -1 && vmosToFocus.push( uid );
            nodesOutOfFocus.indexOf( uid ) > -1 && vmosOutOfFocus.push( uid );
        }
    }
    setTransparencyForObjects( viewerInstanceId, vmosToFocus, vmosOutOfFocus );
}


/**
 *@returns missing in source object uids
 */
function getMissingInSourceObjectUids() {
    const epPageContext = appCtxService.getCtx( 'epPageContext' );
    const loadedObjectUid = epPageContext.loadedObject.uid;
    const result = epObjectPropertyCacheService.getProperty( loadedObjectUid, 'accountabilityResponse' );
    return result && result.missingInSrc && result.missingInSrc.map( ( obj ) => obj.uid );
}

/**
 *
 * @param {*} filter filter
 * @param {*} uid uid
 * @param {*} nodesToFocus nodesToFocus
 * @param {*} nodesOutOfFocus nodesOutOfFocus
 */
function getGraphicsFilterForObjectStatus( filter, uid, nodesToFocus,  nodesOutOfFocus ) {
    const firstLevelParent = epSingleViewerCacheService.getFirstLevelParent( uid );
    const obj = cdm.getObject( uid );
    if( firstLevelParent ) {
        const result = epObjectPropertyCacheService.getProperty( firstLevelParent.uid, epGraphicsConstants.MATCH_TYPE_PROP );
        //if object' match type matches the filter, set the object in focus. Otherwise out of focus
        if( filter && result && result[ 0 ] && filter.includes( result[ 0 ] ) && !mfeTypeUtils.isOfTypes( obj, [ epBvrConstants.MFG_BVR_EQUIPMENT ] )
             || filter.includes( epGraphicsConstants.INDICATIONS_OFF )  )  {
            nodesToFocus.push( uid );
        } else {
            nodesOutOfFocus.push( uid );
        }
    }
    //check if part is missing in source
    const missingInSourceUids = getMissingInSourceObjectUids();
    if ( missingInSourceUids && missingInSourceUids.includes( uid ) ) {
        if( filter && filter.includes( '-1' ) || filter.includes( epGraphicsConstants.INDICATIONS_OFF ) )  {
            nodesToFocus.push( uid );
        } else {
            nodesOutOfFocus.push( uid );
        }
    }
}


/**
 * @param {*} viewerInstanceId viewerInstanceId
 * @param {*} nodesOutOfFocus nodesOutOfFocus
 */
function setPMIsToNodesOutOfFoucs( viewerInstanceId ) {
    let nodesOutOfFocus = [];
    let nodesToFocus = [];
    const filter =  appCtxService.getCtx( epGraphicsConstants.EP_ASSIGNMENT_INDICATION_GRAPHICS_FILTER );
    const pmiIdToVisibilityMap = epGraphicsService.getAllTheVisiblePMIsInViewer( viewerInstanceId );
    if ( filter && filter !== epGraphicsConstants.INDICATIONS_OFF ) {
        for( let [ uid, visibility ] of pmiIdToVisibilityMap.entries() ) {
            if( visibility === true ) {
                nodesOutOfFocus.push( uid );
            }
        }
    } else{
        for( let [ uid, visibility ] of pmiIdToVisibilityMap.entries() ) {
            if( visibility === true ) {
                nodesToFocus.push( uid );
            }
        }
    }
    setTransparencyForPMIs( viewerInstanceId, nodesToFocus, nodesOutOfFocus );
}

/**
 *
 * @param {*} viewerInstanceId viewerInstanceId
 * @param {Object} obj vmo
 */
function updateObjectMaterialBasedOnAssignmentFilter( viewerInstanceId, obj ) {
    const filter =  appCtxService.getCtx( epGraphicsConstants.EP_ASSIGNMENT_INDICATION_GRAPHICS_FILTER );
    if ( viewerInstanceId && filter && filter !== epGraphicsConstants.INDICATIONS_OFF ) {
        const nodesOutOfFocus = [];
        const nodesToFocus = [];
        getGraphicsFilterForObjectStatus( filter, obj.uid, nodesToFocus, nodesOutOfFocus );
        setTransparencyForObjects( viewerInstanceId, nodesToFocus, nodesOutOfFocus );
    }
    filter && epGraphicsSetFilter( viewerInstanceId, filter, true );
}

/**
 * PMIs will be always out of focus if any assignment filter is applied
 * @param {String} viewerInstanceId viewerInstanceId
 * @param {Object} pmis pmis
 */
function updatePMIMaterialBasedOnAssignmentFilter( viewerInstanceId, pmis ) {
    const filter =  appCtxService.getCtx( epGraphicsConstants.EP_ASSIGNMENT_INDICATION_GRAPHICS_FILTER );
    if ( filter && filter !== epGraphicsConstants.INDICATIONS_OFF ) {
        if( !Array.isArray( pmis ) ) {
            pmis = [ pmis ];
        }
        setTransparencyForPMIs( viewerInstanceId, [], pmis );
    }
}
const exports = {
    epGraphicsSetFilterInCtx,
    epGraphicsSetFilter,
    handleAccountibilityUpdateEvents,
    updateObjectMaterialBasedOnAssignmentFilter,
    updatePMIMaterialBasedOnAssignmentFilter
};

export default exports;
