// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * @module js/mbmCutCopyPasteService
 */

import clipboardService from 'js/clipboardService';
import appCtxSvc from 'js/appCtxService';
import addElementService from 'js/addElementService';
import soaSvc from 'soa/kernel/soaService';
import messagingService from 'js/messagingService';
import localeService from 'js/localeService';
import mfgNotificationUtils from 'js/mfgNotificationUtils';
import eventBus from 'js/eventBus';
import occmgmtBackingObjectProviderSvc from 'js/occmgmtBackingObjectProviderService';
import saveInputWriterService from 'js/saveInputWriterService';
import { constants as epSaveConstants } from 'js/epSaveConstants';
import cdm from 'soa/kernel/clientDataModel';
import epSaveService from 'js/epSaveService';
import { constants as mbmConstants } from 'js/mbmConstants';
import mbmUtils from 'js/mbmUtils';
import mbmSaveUtils from 'js/mbmSaveUtils';
import dataMgmtService from 'soa/dataManagementService';

/**
  * Perform mbm cut
  * @param {object} nodesToCut ViewModelTreeNodes to be cut
  * @param {object} mbmContext mbmContext
  */
function mbmCut( nodesToCut, mbmContext ) {
    resetCutElementIndication( clipboardService.instance.getContents() );
    nodesToCut.forEach( ( node )=>{
        node.isCutElementGreyedout = node.isGreyedOutElement;
        node.isGreyedOutElement = true;
    } );
    clipboardService.instance.setContents( nodesToCut );
    let objToUpdate = mbmUtils.getContextValue( mbmContext, 'contextMenuSelected' );
    objToUpdate.isCut = true;
    objToUpdate.isCopy = false;
    mbmUtils.updateValueToContext( mbmContext, objToUpdate );
}

/**
  *
  * @param {Array} viewModelNodes viewModelNodes
  */
function resetCutElementIndication( viewModelNodes ) {
    viewModelNodes.forEach( ( node )=>{
        node.isGreyedOutElement = node.isCutElementGreyedout;
        delete node.isCutElementGreyedout;
    } );
}

/**
  * Perform mbm copy
  * @param {object} nodesToCopy ViewModelTreeNodes to be copied
  * @param {object} mbmContext mbmContext
  */
function mbmCopy( nodesToCopy, mbmContext ) {
    resetCutElementIndication( clipboardService.instance.getContents() );
    clipboardService.instance.setContents( nodesToCopy );
    let objToUpdate = mbmUtils.getContextValue( mbmContext, 'contextMenuSelected' );
    objToUpdate.isCut = false;
    objToUpdate.isCopy = true;
    mbmUtils.updateValueToContext( mbmContext, objToUpdate );
}

/**
  *@param {Object} occContext viewToReact
  * @param {Object} mbmContext  mbmContext
  * @param {Array} sourceObjects  sourceObjects
  * @param {Object} targetObject targetObject
  * @returns {Promise} promise
  */
function mbmPaste( occContext, mbmContext, sourceObjects, targetObject ) {
    let addElementInput = {};
    addElementInput.parent = targetObject;
    addElementService.processAddElementInput();
    let objToUpdate = mbmUtils.getContextValue( mbmContext, 'contextMenuSelected' );
    objToUpdate.isCut = false;
    objToUpdate.isCopy = false;
    return soaSvc.postUnchecked( 'Internal-ActiveWorkspaceBom-2021-06-OccurrenceManagement', 'addObject3', getCopyPasteSoaInput( occContext, sourceObjects, targetObject ) ).then( ( addResponse )=>{
        if( addResponse.ServiceData.hasOwnProperty( 'partialErrors' ) && addResponse.ServiceData.partialErrors.length > 0 ) {
            var err = messagingService.getSOAErrorMessage( addResponse.ServiceData );
            localeService.getTextPromise( 'mbmMessages' ).then( function( localizedText ) {
                messagingService.showError( err, null, null, getCloseButton( localizedText.mbmClose ) );
            } );
        }
        let addedElementsEventData = {
            addElementInput: addElementInput,
            objectsToSelect: addResponse.selectedNewElementInfo.newElements,
            addElementResponse: addResponse,
            viewToReact: 'mbomContext'
        };


        eventBus.publish( 'addElement.elementsAdded', addedElementsEventData );

        return occmgmtBackingObjectProviderSvc.getBackingObjects( addResponse.selectedNewElementInfo.newElements ).then( ( createdBomlines )=>{
            return processObjectsAfterGetBackingObjects( mbmContext, createdBomlines, addResponse.selectedNewElementInfo.newElements );
        } );
    }, ( error ) =>{
        var err = messagingService.getSOAErrorMessage( error );
        localeService.getTextPromise( 'mbmMessages' ).then( function( localizedText ) {
            messagingService.showError( err, null, null, getCloseButton( localizedText.mbmClose ) );
        } );
        return err;
    }
    );
}

const processObjectsAfterGetBackingObjects = async function( mbmContext, createdBomlines, newElements ) {
    if( appCtxSvc.ctx.preferences.MBMPasteAsReferenceType && appCtxSvc.ctx.preferences.MBMPasteAsReferenceType[0] ) {
        let propValue = appCtxSvc.ctx.preferences.MBMPasteAsReferenceType;
        let createdBomlineUids = [];
        let createdBomlineElements = [];
        createdBomlines.forEach( function( createdBomline ) {
            createdBomlineUids.push( createdBomline.uid );
        } );
        await dataMgmtService.loadObjects( createdBomlineUids );
        createdBomlines.forEach( function( createdBomline ) {
            createdBomlineElements.push( cdm.getObject( createdBomline.uid ) );
        } );

        let saveInputWriter = saveInputWriterService.get();
        addEntryToObjectToModifySection( createdBomlineUids, propValue, saveInputWriter );
        let sessionEntry = getEntryToSession( mbmContext );
        saveInputWriter.addEntryToSection( epSaveConstants.SESSION, sessionEntry );
        saveInputWriter.addRelatedObjects( createdBomlineElements );
        saveInputWriter.addRelatedObjects( newElements );
        let soaInput = saveInputWriterService.getSaveInput( saveInputWriter );
        return epSaveService.performSaveChangesCall( soaInput )
            .then( function( response ) {
                if( response.ServiceData.hasOwnProperty( 'partialErrors' ) && response.ServiceData.partialErrors.length > 0 ) {
                    var err = messagingService.getSOAErrorMessage( response.ServiceData );
                    localeService.getTextPromise( 'mbmMessages' ).then( function( localizedText ) {
                        messagingService.showError( err, null, null, getCloseButton( localizedText.mbmClose ) );
                    } );
                }
            }, function( error ) {
                var err = messagingService.getSOAErrorMessage( error );
                localeService.getTextPromise( 'mbmMessages' ).then( function( localizedText ) {
                    messagingService.showError( err, null, null, getCloseButton( localizedText.mbmClose ) );
                } );
                return err;
            } );
    }
};

const getEntryToSession = function( mbmContext ) {
    let topElement = mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_TOP_ELEMENT );
    let pci = mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_PRODUCT_CONTEXT_INFO );

    return mbmSaveUtils.getEntryForSessionSection( [ 'false' ], [ pci[mbmConstants.EBOM_CONTEXT].uid ], [ pci[mbmConstants.MBOM_CONTEXT].uid ], [ topElement[mbmConstants.EBOM_CONTEXT].uid ], [ topElement[mbmConstants.MBOM_CONTEXT].uid ] );
};
/**
  *
  * @param {Array} createdBomlineUids createdBomlineUids
  * @param {Array} propValue propValue
  * @param {Object} saveInputWriter saveInputWriter
  */
function addEntryToObjectToModifySection( createdBomlineUids, propValue, saveInputWriter ) {
    createdBomlineUids.forEach( ( uid ) => {
        saveInputWriter.addEntryToSection( epSaveConstants.OBJECTS_TO_MODIFY, {
            Object: {
                nameToValuesMap: {
                    id: [ uid ]
                }
            },
            Prop: {
                nameToValuesMap: {
                    bl_occ_type: propValue
                }
            }
        } );
    } );
}
/**
  *
  * @param {Object} sourceObjects sourceObjects
  * @param {Object} targetObject targetObject
  * @returns {Object} input
  */
function getCopyPasteSoaInput( occContext, sourceObjects, targetObject ) {
    return {
        input: {
            siblingElement: '',
            fetchPagedOccurrences: true,
            inputCtxt: {
                productContext: occContext.productContextInfo
            },
            parentElement: targetObject,
            objectsToBeAdded: sourceObjects,
            requestPref: {
                displayMode: [ 'Tree' ]
            },
            sortCriteria: {
                propertyName: '',
                sortingOrder: ''
            },
            numberOfElements: 1
        }
    };
}

/**
  * return Array of button
  * @param {String} buttonName localized name of button
  * @return {Array} Array of button
  */
function getCloseButton( buttonName ) {
    let buttonArray = [];
    buttonArray.push( mfgNotificationUtils.createButton( buttonName, function( callBack ) {
        callBack.close();
    } ) );
    return buttonArray;
}

export default {
    mbmCut,
    mbmCopy,
    mbmPaste
};

