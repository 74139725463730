// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwContextControl from 'viewmodel/AwContextControlViewModel';
import AwInclude from 'viewmodel/AwIncludeViewModel';
import MfeContentPanel from 'viewmodel/MfeContentPanelViewModel';
import AwSplitter from 'viewmodel/AwSplitterViewModel';
import AwIconButton from 'viewmodel/AwIconButtonViewModel';
import AwCommandBar from 'viewmodel/AwCommandBarViewModel';
import TcmaEditStudy from 'viewmodel/TcmaEditStudyViewModel';
    import { ShowWhen,ExistWhen } from 'js/hocCollection';
const DivShowWhen = ShowWhen('div');
const DivExistWhen = ExistWhen('div');
const AwSplitterExistWhen = ExistWhen(AwSplitter);
DivShowWhen.displayName = 'DivShowWhen';
DivExistWhen.displayName = 'DivExistWhen';
AwSplitterExistWhen.displayName = 'AwSplitterExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/mfeViewModelUtils', () => import('js/mfeViewModelUtils'));
registerDynImportEntry('js/tcmaStudyManagerService', () => import('js/tcmaStudyManagerService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "props": {
        "view-path": {
            "type": "object"
        },
        "view-id": {
            "type": "string"
        },
        "sub-panel-context": {
            "type": "object"
        }
    },
    "data": {
        "ccListPanelData": {
            "tabs": [
                {
                    "name": "{{i18n.searchLabel}}",
                    "tabKey": "TcmaStudyManagerList"
                }
            ]
        },
        "studyDetailsPanelData": {
            "tabs": [
                {
                    "name": "{{i18n.studyLabel}}",
                    "tabKey": "TcmaStudyDetails"
                }
            ]
        },
        "backButtonCommand": {
            "action": "showStudyManager",
            "iconName": "Back",
            "tooltip": "{{i18n.editStudyBackButton}}"
        },
        "studyManagerContext": {
            "initialValues": {
                "selectedCC": "",
                "scopes": [],
                "selection": {},
                "processContext": {},
                "plantContext": {},
                "study": {},
                "isEditStudyVisible": false
            },
            "meta": {}
        },
        "uids": null
    },
    "i18n": {
        "editStudyTitle": [
            "tcmaStudyManagerMessages"
        ],
        "editStudyBackButton": [
            "tcmaStudyManagerMessages"
        ],
        "studyLabel": [
            "tcmaStudyManagerMessages"
        ],
        "searchLabel": [
            "tcmaStudyManagerMessages"
        ]
    },
    "actions": {
        "showStudyManager": {
            "actionType": "JSFunction",
            "method": "mergeValueInAtomicData",
            "deps": "js/mfeViewModelUtils",
            "inputData": {
                "targetAtomicData": "{{fields.studyManagerContext}}",
                "updatedValues": {
                    "isEditStudyVisible": false,
                    "processContext": {},
                    "plantContext": {},
                    "study": {}
                }
            }
        },
        "initialize": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "extractUidsFromParam",
                    "condition": "conditions.isCCProvided"
                },
                {
                    "action": "loadObjects",
                    "condition": "data.uids !== null"
                },
                {
                    "action": "setSelectedCCFromLoadedObject"
                }
            ]
        },
        "setInputToStudyManager": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "extractUidsFromEvent"
                },
                {
                    "action": "loadObjects",
                    "condition": "data.uids !== null"
                },
                {
                    "action": "setSelectedCCFromLoadedObject"
                }
            ]
        },
        "loadObjects": {
            "actionType": "TcSoaService",
            "serviceName": "Core-2007-09-DataManagement",
            "method": "loadObjects",
            "inputData": {
                "uids": "{{data.uids}}"
            },
            "outputData": {
                "selectedCC": "{{function:getCC}}",
                "scopes": "{{function:getScopes}}"
            },
            "deps": "js/tcmaStudyManagerService"
        },
        "setSelectedCCFromLoadedObject": {
            "actionType": "JSFunction",
            "method": "mergeValueInAtomicData",
            "inputData": {
                "targetAtomicData": "{{fields.studyManagerContext}}",
                "updatedValues": {
                    "selectedCC": "{{data.selectedCC}}",
                    "scopes": "{{data.scopes}}"
                }
            },
            "deps": "js/mfeViewModelUtils"
        },
        "setSelectedCC": {
            "actionType": "JSFunction",
            "method": "setSelectedCC",
            "inputData": {
                "cc": "{{fields.studyManagerContext.selectedCC}}",
                "scopes": "{{fields.studyManagerContext.scopes}}"
            },
            "outputData": {
                "selectedCC": "selectedCC",
                "scopes": "scopes"
            },
            "deps": "js/tcmaStudyManagerService"
        },
        "extractUidsFromParam": {
            "actionType": "JSFunction",
            "method": "getUids",
            "inputData": {
                "data": "{{data}}",
                "ccUid": "{{ctx.state.params.cc}}",
                "scopeUids": "{{ctx.state.params.scopeUid}}"
            },
            "outputData": {
                "uids": null
            },
            "deps": "js/tcmaStudyManagerService"
        },
        "extractUidsFromEvent": {
            "actionType": "JSFunction",
            "method": "getUids",
            "inputData": {
                "data": "{{data}}",
                "ccUid": "{{data.eventData.ccUid}}",
                "scopeUids": "{{data.eventData.scopeUids}}"
            },
            "outputData": {
                "uids": null
            },
            "deps": "js/tcmaStudyManagerService"
        }
    },
    "functions": {
        "getCC": {
            "functionName": "getCC",
            "parameters": [
                "{{data.ccUid}}",
                "response"
            ]
        },
        "getScopes": {
            "functionName": "getScopes",
            "parameters": [
                "{{data.ccUid}}",
                "response"
            ]
        }
    },
    "onEvent": [
        {
            "eventId": "tcmaStudyManager.setInputToStudyManager",
            "action": "setInputToStudyManager",
            "cacheEventData": true
        }
    ],
    "conditions": {
        "isSelectedStudy": {
            "expression": "fields.studyManagerContext.selection !== null && fields.studyManagerContext.selection[0] !== null"
        },
        "isCCProvided": {
            "expression": {
                "$source": "ctx.state.params.cc",
                "$query": {
                    "$notNull": true
                }
            }
        }
    },
    "lifecycleHooks": {
        "onMount": "initialize",
        "onUpdate": {
            "action": "setSelectedCC",
            "observers": [
                "viewModel.atomicData.studyManagerContext.selectedCC"
            ]
        }
    },
    "_viewModelId": "TcmaStudyManager",
    "_uniqueViewModelId": "TcmaStudyManager",
    "ctx": {
        "state": {
            "type": "object"
        },
        "user": {
            "type": "object"
        }
    }
};

    /*
    <div class="sw-container  aw-tcmaStudyManager-header afx-content-background" show-when="!data.studyManagerContext.isEditStudyVisible">
    <!--Header-->
    <div class="sw-row h-1 aw-tcma-headerSection" height="3f" align-content="center" exist-when="!conditions.isCCProvided">
        <aw-context-control anchor='tcmaHeaderContextPosition'>
            {{ctx.user.cellHeader1}} ({{ctx.user.cellHeader2}})
            <div aw-repeat="result : contributedViews">
                <aw-include name="{{result}}"></aw-include>
            </div>
        </aw-context-control>
    </div>
    <!--Header End-->
    <div class="sw-row">
        <div class="sw-container aw-tcmaStudyManager-listContainer w-3" exist-when="!conditions.isCCProvided">
            <mfe-content-panel class="afx-alt-content-background" sub-panel-context="{...data.ccListPanelData, studyManagerContext: data.studyManagerContext}"></mfe-content-panel>
        </div>
        <aw-splitter exist-when="!conditions.isCCProvided" min-size-1="200" min-size-2="500"></aw-splitter>
        <div class="sw-column aw-tcmaStudyManager-details-container h-12 w-12">
            <mfe-content-panel sub-panel-context="{...data.studyDetailsPanelData,  studyManagerContext: data.studyManagerContext,runActionWithViewModel:props.runActionWithViewModel}"></mfe-content-panel>
        </div>
    </div>
</div>
 <div class="sw-container  afx-content-background" exist-when="data.studyManagerContext.isEditStudyVisible">
    <!-- Edit Study Header Start-->
    <div class="sw-row w-12 aw-advanced-bar-horizontal align-center" height="4f">
        <aw-icon-button class="sw-column justify-left align-left" size="large" command="data.backButtonCommand"></aw-icon-button>
        <div class="sw-column w-6 align-left justify-left aw-layout-locationTitle" exist-when="data.studyManagerContext.selection[0] !== null">
            {{i18n.editStudyTitle}} | {{data.studyManagerContext.selection[0].props.object_name.displayValues[ 0 ]}}</div>
        <aw-command-bar class="sw-column w-6 justify-right align-right" context="data.studyManagerContext" anchor="tcmaEditStudyCommandAnchor" alignment="HORIZONTAL" overflow="true" show-command-labels="false"></aw-command-bar>
    </div>
    <!-- Edit Study Header End-->
    <div class="sw-row h-12">
        <tcma-edit-study sub-panel-context="{ studyManagerContext: data.studyManagerContext}"></tcma-edit-study>
    </div>

</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <DivShowWhen className="sw-container  aw-tcmaStudyManager-header afx-content-background" showWhen={AwParseService.instance( "!fields.studyManagerContext.isEditStudyVisible" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <DivExistWhen className="sw-row h-1 aw-tcma-headerSection" height="3f" align-content="center" existWhen={AwParseService.instance( "!conditions.isCCProvided" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwContextControl anchor="tcmaHeaderContextPosition">
                {AwParseService.instance( "ctx.user.cellHeader1" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} ({AwParseService.instance( "ctx.user.cellHeader2" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )})
                { Object.entries( AwParseService.instance( "contributedViews" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, result ] ) => (
                    <div key={$index}>
                        <AwInclude viewId={AwParseService.instance( "result" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, result, $index } )} viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, result, $index } )} name={AwParseService.instance( "result" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, result, $index } )}>
                        </AwInclude>
                    </div>
                ) ) }
            </AwContextControl>
        </DivExistWhen>
        <div className="sw-row">
            <DivExistWhen className="sw-container aw-tcmaStudyManager-listContainer w-3" existWhen={AwParseService.instance( "!conditions.isCCProvided" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <MfeContentPanel className="afx-alt-content-background" subPanelContext={AwParseService.instance( "{...data.ccListPanelData, studyManagerContext: fields.studyManagerContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MfeContentPanel>
            </DivExistWhen>
            <AwSplitterExistWhen minSize1="200" minSize2="500" existWhen={AwParseService.instance( "!conditions.isCCProvided" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwSplitterExistWhen>
            <div className="sw-column aw-tcmaStudyManager-details-container h-12 w-12">
                <MfeContentPanel subPanelContext={AwParseService.instance( "{...data.studyDetailsPanelData,  studyManagerContext: fields.studyManagerContext,runActionWithViewModel:props.runActionWithViewModel}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MfeContentPanel>
            </div>
        </div>
    </DivShowWhen>
    <DivExistWhen className="sw-container  afx-content-background" existWhen={AwParseService.instance( "fields.studyManagerContext.isEditStudyVisible" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <div className="sw-row w-12 aw-advanced-bar-horizontal align-center" height="4f">
            <AwIconButton className="sw-column justify-left align-left" size="large" {...getField("data.backButtonCommand", fields, $index, null, null )}>
            </AwIconButton>
            <DivExistWhen className="sw-column w-6 align-left justify-left aw-layout-locationTitle" existWhen={AwParseService.instance( "fields.studyManagerContext.selection[0] !== null" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                {AwParseService.instance( "i18n.editStudyTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} | {AwParseService.instance( "fields.studyManagerContext.selection[0].props.object_name.displayValues[ 0 ]" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
            </DivExistWhen>
            <AwCommandBar className="sw-column w-6 justify-right align-right" context={AwParseService.instance( "fields.studyManagerContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} anchor="tcmaEditStudyCommandAnchor" alignment="HORIZONTAL" overflow={AwParseService.instance( "true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} showCommandLabels={AwParseService.instance( "false" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwCommandBar>
        </div>
        <div className="sw-row h-12">
            <TcmaEditStudy subPanelContext={AwParseService.instance( "{ studyManagerContext: fields.studyManagerContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </TcmaEditStudy>
        </div>
    </DivExistWhen>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;