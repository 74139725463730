// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwBreak from 'viewmodel/AwBreakViewModel';
import AwWidget from 'viewmodel/AwWidgetViewModel';
import AwPropertyLabel from 'viewmodel/AwPropertyLabelViewModel';
import AwPanelFooter from 'viewmodel/AwPanelFooterViewModel';
import AwButton from 'viewmodel/AwButtonViewModel';
import AwI18n from 'viewmodel/AwI18nViewModel';
    import { ExistWhen } from 'js/hocCollection';
const AwWidgetExistWhen = ExistWhen(AwWidget);
const AwPropertyLabelExistWhen = ExistWhen(AwPropertyLabel);
const DivExistWhen = ExistWhen('div');
const AwPanelFooterExistWhen = ExistWhen(AwPanelFooter);
const AwButtonExistWhen = ExistWhen(AwButton);
AwWidgetExistWhen.displayName = 'AwWidgetExistWhen';
AwPropertyLabelExistWhen.displayName = 'AwPropertyLabelExistWhen';
DivExistWhen.displayName = 'DivExistWhen';
AwPanelFooterExistWhen.displayName = 'AwPanelFooterExistWhen';
AwButtonExistWhen.displayName = 'AwButtonExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/editAdminPreferenceService', () => import('js/editAdminPreferenceService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "actions": {
        "startEditPreference": {
            "actionType": "JSFunction",
            "method": "startEditPreference",
            "inputData": {
                "prefCtx": "{{ctx.tcadmconsole.preferences}}",
                "data": "{{data}}"
            },
            "outputData": {
                "productAreaList": "productAreaList"
            },
            "deps": "js/editAdminPreferenceService"
        },
        "savePreferenceEdits": {
            "actionType": "JSFunction",
            "method": "savePreferenceEdits",
            "inputData": {
                "prefCtx": "{{ctx.tcadmconsole.preferences}}"
            },
            "deps": "js/editAdminPreferenceService"
        },
        "cancelPreferenceEdits": {
            "actionType": "JSFunction",
            "method": "cancelPreferenceEdits",
            "inputData": {
                "prefCtx": "{{ctx.tcadmconsole.preferences}}"
            },
            "deps": "js/editAdminPreferenceService"
        },
        "updatePreference": {
            "actionType": "JSFunctionAsync",
            "method": "updatePreference",
            "inputData": {
                "vmData": "{{data}}",
                "selectedObj": "{{subPanelContext.selection[0]}}",
                "prefCtx": "{{ctx.tcadmconsole.preferences}}"
            },
            "deps": "js/editAdminPreferenceService",
            "events": {
                "success": [
                    {
                        "name": "primaryWorkarea.reset"
                    }
                ]
            }
        },
        "populateLists": {
            "actionType": "JSFunction",
            "method": "populateEditPreferencePanel",
            "inputData": {
                "data": "{{data}}"
            },
            "outputData": {
                "productAreaList": "productAreaList",
                "protectionScopeList": "protectionScopeList",
                "ctx.tcadmconsole.preferences.editInProgress": true
            },
            "deps": "js/editAdminPreferenceService"
        },
        "selectionChange": {
            "actionType": "JSFunction",
            "method": "preferenceInfoSelectionChange",
            "inputData": {
                "selectedObj": "{{subPanelContext.selection[0]}}",
                "prefCtx": "{{ctx.tcadmconsole.preferences}}"
            },
            "dataParsers": [
                {
                    "id": "prefInfo",
                    "responseObjs": "response"
                }
            ],
            "outputData": {
                "preferenceInfo": "response"
            },
            "deps": "js/editAdminPreferenceService"
        },
        "revealPreferenceInfo": {
            "actionType": "JSFunction",
            "method": "preferenceInfoReveal",
            "inputData": {
                "selectedObj": "{{subPanelContext.selection[0]}}",
                "prefCtx": "{{ctx.tcadmconsole.preferences}}"
            },
            "dataParsers": [
                {
                    "id": "prefInfo",
                    "responseObjs": "response"
                }
            ],
            "outputData": {
                "preferenceInfo": "response"
            },
            "deps": "js/editAdminPreferenceService",
            "events": {
                "success": [
                    {
                        "name": "Preferences.addEditHandler",
                        "condition": "ctx.tcadmconsole.preferences.editInProgress === true"
                    }
                ]
            }
        },
        "addEditHandler": {
            "actionType": "JSFunction",
            "method": "addEditHandler",
            "inputData": {
                "data": "{{data}}",
                "selectedObj": "{{subPanelContext.selection[0]}}",
                "prefCtx": "{{ctx.tcadmconsole.preferences}}"
            },
            "deps": "js/editAdminPreferenceService"
        }
    },
    "onEvent": [
        {
            "eventId": "Preferences.revealPreferenceInfo",
            "action": "revealPreferenceInfo"
        },
        {
            "eventId": "Preferences.updatePreference",
            "action": "updatePreference"
        },
        {
            "eventId": "Preferences.addEditHandler",
            "action": "addEditHandler"
        }
    ],
    "dataParseDefinitions": {
        "prefInfo": {
            "identifier": "AAAAAAAAAA",
            "props": {
                "fnd0PreferenceName": {
                    "name": "fnd0PreferenceName",
                    "displayName": "{{data.data.nameTitle}}",
                    "value": "{{response.fnd0PreferenceName}}",
                    "displayValue": "{{response.fnd0PreferenceName}}",
                    "type": "STRING",
                    "isArray": false,
                    "isEditable": false,
                    "isModifiable": true
                },
                "fnd0ProductArea": {
                    "name": "fnd0ProductArea",
                    "displayName": "{{data.data.productAreaTitle}}",
                    "value": "{{response.fnd0ProductArea}}",
                    "displayValue": "{{response.fnd0ProductArea}}",
                    "type": "STRING",
                    "isEditable": "{{function:isPropertyEditable}}",
                    "dataProvider": "productAreaProvider",
                    "isRequired": true,
                    "hasLov": true,
                    "isSelectOnly": true,
                    "isModifiable": true
                },
                "fnd0ProtectionScope": {
                    "name": "fnd0ProtectionScope",
                    "displayName": "{{data.data.protectionScopeTitle}}",
                    "value": "{{response.fnd0ProtectionScope}}",
                    "displayValue": "{{function:getProtectionScopeDisplayValue}}",
                    "type": "STRING",
                    "isEditable": "{{function:isProtectionScopeEditable}}",
                    "dataProvider": "protectionScopeProvider",
                    "isRequired": true,
                    "isModifiable": true,
                    "hasLov": true
                },
                "fnd0Environment": {
                    "name": "fnd0Environment",
                    "displayName": "{{data.data.environmentTitle}}",
                    "value": "{{response.fnd0Environment}}",
                    "displayValue": "{{function:getEnvironmentEnabledDisplayValue}}",
                    "type": "BOOLEAN",
                    "isEditable": false,
                    "isRequired": true
                },
                "fnd0EditEnvironment": {
                    "name": "fnd0EditEnvironment",
                    "displayName": "{{data.data.enabledEnv}}",
                    "value": "{{response.fnd0Environment}}",
                    "displayValue": "{{function:getEnvironmentEnabledDisplayValue}}",
                    "type": "BOOLEAN",
                    "isEditable": true,
                    "isRequired": true,
                    "labelPosition": "PROPERTY_LABEL_AT_RIGHT"
                },
                "fnd0Description": {
                    "name": "fnd0Description",
                    "displayName": "{{data.data.descriptionTitle}}",
                    "value": "{{response.fnd0Description}}",
                    "displayValue": "{{response.fnd0Description}}",
                    "type": "STRING",
                    "isEditable": "{{function:isPropertyEditable}}",
                    "isRequired": true
                },
                "fnd0ValueType": {
                    "name": "fnd0ValueType",
                    "displayName": "{{data.data.valueTypeTitle}}",
                    "value": "{{response.fnd0ValueType}}",
                    "displayValue": "{{function:getTypeDisplayValue}}",
                    "type": "STRING",
                    "isEditable": false
                },
                "fnd0IsMultiValue": {
                    "name": "fnd0IsMultiValue",
                    "displayName": "{{data.data.isMultiValueTitle}}",
                    "value": "{{response.fnd0IsMultiValue}}",
                    "displayValue": "{{function:getIsArrayDisplayValue}}",
                    "type": "BOOLEAN",
                    "isEditable": false
                },
                "fnd0Location": {
                    "name": "fnd0Location",
                    "displayName": "{{data.data.locationTitle}}",
                    "value": "{{response.fnd0Location}}",
                    "displayValue": "{{function:getLocationDisplayValue}}",
                    "type": "STRING",
                    "isEditable": false
                },
                "fnd0PreferenceValues": {
                    "name": "fnd0PreferenceValues",
                    "displayName": "{{data.data.valuesTitle}}",
                    "value": "{{function:getClonedValue}}",
                    "displayValue": "{{function:getClonedValue}}",
                    "isArray": "{{response.fnd0IsMultiValue}}",
                    "type": "{{function:getVMPropertyType}}",
                    "isEditable": true
                }
            }
        }
    },
    "dataProviders": {
        "productAreaProvider": {
            "action": "populateLists",
            "selectionModelMode": "single",
            "response": "{{data.productAreaList}}"
        },
        "protectionScopeProvider": {
            "action": "populateLists",
            "selectionModelMode": "single",
            "response": "{{data.protectionScopeList}}"
        }
    },
    "data": {
        "nameTitle": "{{i18n.Name}}",
        "productAreaTitle": "{{i18n.ProductArea}}",
        "descriptionTitle": "{{i18n.Description}}",
        "protectionScopeTitle": "{{i18n.ProtectionScope}}",
        "locationTitle": "{{i18n.Location}}",
        "valueTypeTitle": "{{i18n.ValueType}}",
        "isMultiValueTitle": "{{i18n.IsMultiValue}}",
        "environmentTitle": "{{i18n.Environment}}",
        "canUserEdit": "false",
        "localizedProtectionScopes": {
            "User": "{{i18n.User}}",
            "Role": "{{i18n.Role}}",
            "Group": "{{i18n.Group}}",
            "Site": "{{i18n.Site}}",
            "System": "{{i18n.System}}"
        },
        "valuesTitle": "{{i18n.Values}}",
        "valuesTitleLabel": {
            "displayName": "{{i18n.Values}}"
        },
        "enabledEnv": "{{i18n.enabledEnv}}",
        "fnd0EnvironmentLabelProp": {
            "displayName": "{{i18n.Environment}}",
            "isRequired": true
        }
    },
    "i18n": {
        "definitionPropSectionHdr": [
            "preferenceInfoMessages"
        ],
        "Values": [
            "preferenceMessages"
        ],
        "Name": [
            "preferenceMessages"
        ],
        "ProductArea": [
            "preferenceMessages"
        ],
        "Description": [
            "preferenceInfoMessages"
        ],
        "ProtectionScope": [
            "preferenceInfoMessages"
        ],
        "Environment": [
            "preferenceInfoMessages"
        ],
        "Location": [
            "preferenceMessages"
        ],
        "ValueType": [
            "preferenceInfoMessages"
        ],
        "IsMultiValue": [
            "preferenceInfoMessages"
        ],
        "enabledEnv": [
            "preferenceInfoMessages"
        ],
        "disabledEnv": [
            "preferenceInfoMessages"
        ],
        "User": [
            "preferenceMessages"
        ],
        "Role": [
            "preferenceMessages"
        ],
        "Group": [
            "preferenceMessages"
        ],
        "Site": [
            "preferenceMessages"
        ],
        "System": [
            "preferenceMessages"
        ],
        "preferencesStartEdit": [
            "preferenceInfoMessages"
        ],
        "preferencesSaveEdit": [
            "preferenceInfoMessages"
        ],
        "preferencesCancelEdit": [
            "preferenceInfoMessages"
        ]
    },
    "conditions": {
        "showEditButton": {
            "expression": "!ctx.tcadmconsole.preferences.editInProgress && ctx.tcadmconsole.preferences.canUserEdit && ctx.search.tableLoaded"
        },
        "showCancelButton": {
            "expression": "ctx.tcadmconsole.preferences.editInProgress"
        },
        "showSaveButton": {
            "expression": "ctx.tcadmconsole.preferences.editInProgress"
        },
        "isTablePopulated": {
            "expression": "ctx.search.tableLoaded === true"
        },
        "isSummaryHeaderHidden": {
            "expression": "!(ctx.state && ctx.state.urlAttributes && ctx.state.urlAttributes.flexibleLayouts !== undefined)"
        }
    },
    "functions": {
        "getEnvironmentEnabledDisplayValue": {
            "functionName": "getDisplayValueForEnvEnabled",
            "parameters": [
                "{{response.fnd0Environment}}"
            ]
        },
        "getLocationDisplayValue": {
            "functionName": "getDisplayValueForLocation",
            "parameters": [
                "{{response.fnd0Location}}"
            ]
        },
        "getTypeDisplayValue": {
            "functionName": "getDisplayValueForType",
            "parameters": [
                "{{response.fnd0ValueType}}"
            ]
        },
        "getProtectionScopeDisplayValue": {
            "functionName": "getDisplayValueForProtectionScope",
            "parameters": [
                "{{response.fnd0ProtectionScope}}"
            ]
        },
        "getIsArrayDisplayValue": {
            "functionName": "getDisplayValueForBoolean",
            "parameters": [
                "{{response.fnd0IsMultiValue}}"
            ]
        },
        "getVMPropertyType": {
            "functionName": "getVMPropertyType",
            "parameters": [
                "{{response.fnd0IsMultiValue}}",
                "{{response.fnd0ValueType}}"
            ]
        },
        "getClonedValue": {
            "functionName": "getClonedValue",
            "parameters": [
                "{{response.fnd0PreferenceValues}}"
            ]
        },
        "isPropertyEditable": {
            "functionName": "isPropertyEditable",
            "parameters": [
                "{{ctx.tcadmconsole.preferences.canUserEditDefinition}}",
                "{{ctx.tcadmconsole.preferences.editInProgress}}"
            ]
        },
        "isProtectionScopeEditable": {
            "functionName": "isProtectionScopeEditable",
            "parameters": [
                "{{ctx.tcadmconsole.preferences.canUserEditDefinition}}",
                "{{ctx.tcadmconsole.preferences.editInProgress}}",
                "{{response.fnd0ProtectionScope}}"
            ]
        }
    },
    "lifecycleHooks": {
        "onUpdate": [
            {
                "action": "selectionChange",
                "observers": [
                    "props.subPanelContext.selection"
                ]
            }
        ],
        "onMount": "revealPreferenceInfo"
    },
    "_viewModelId": "preferenceProperties",
    "_uniqueViewModelId": "preferenceProperties",
    "ctx": {
        "tcadmconsole": {
            "type": "object"
        },
        "search": {
            "type": "object"
        },
        "state": {
            "type": "object"
        }
    }
};

    /*
    
        <aw-panel>
            <aw-panel-body>
                    <!-- Beginning of Definition Panel Section -->
                    <aw-panel-section caption="i18n.definitionPropSectionHdr" collapsed="false">
                        <aw-break></aw-break>
                        <!-- Name, Product Area, Description-->
                        <aw-widget prop="data.preferenceInfo.props.fnd0PreferenceName"> </aw-widget>
                        <aw-widget prop="data.preferenceInfo.props.fnd0ProductArea"> </aw-widget>
                        <aw-widget prop="data.preferenceInfo.props.fnd0Description"> </aw-widget>
                        <aw-break></aw-break>

                        <!-- Protection Scope,> Environment Enabled, Preference location, type, MultiValued -->
                        <aw-widget prop="data.preferenceInfo.props.fnd0ProtectionScope"> </aw-widget>
                        <aw-widget exist-when="!ctx.tcadmconsole.preferences.editInProgress" prop="data.preferenceInfo.props.fnd0Environment"></aw-widget>
                        <aw-property-label exist-when="ctx.tcadmconsole.preferences.editInProgress" prop="data.fnd0EnvironmentLabelProp"></aw-property-label>
                        <aw-widget exist-when="ctx.tcadmconsole.preferences.editInProgress" prop="data.preferenceInfo.props.fnd0EditEnvironment"></aw-widget>
                        <aw-widget prop="data.preferenceInfo.props.fnd0Location"> </aw-widget>
                        <aw-widget prop="data.preferenceInfo.props.fnd0ValueType"> </aw-widget>
                        <aw-widget prop="data.preferenceInfo.props.fnd0IsMultiValue"> </aw-widget>
                    </aw-panel-section>
                    <!-- Beginning of Values Panel Section -->
                   <aw-panel-section caption="i18n.Values" collapsed="false" class="aw-preferences-properties">
                     <aw-property-label exist-when="!ctx.tcadmconsole.preferences.editInProgress" prop="data.valuesTitleLabel">
                        </aw-property-label>
                        <div class="aw-widgets-propertyNonEditValue">
                            <div class="aw-jswidgets-arrayValueCellListItem aw-preferences-value"
                                exist-when="!ctx.tcadmconsole.preferences.editInProgress"
                                aw-repeat="val : data.preferenceInfo.props.fnd0PreferenceValues.displayValues ">{{val}}
                            </div>
                        </div>
                      <aw-widget exist-when="ctx.tcadmconsole.preferences.editInProgress"
                            prop="data.preferenceInfo.props.fnd0PreferenceValues"> </aw-widget>
                    </aw-panel-section>
                </aw-panel-body>
                <aw-panel-footer  exist-when="conditions.isSummaryHeaderHidden">
                    <aw-button action="startEditPreference" size="auto" exist-when="conditions.showEditButton">
                        <aw-i18n>i18n.preferencesStartEdit</aw-i18n>
                    </aw-button>
                    <aw-button action="cancelPreferenceEdits" size="auto" button-type="negative" exist-when="conditions.showCancelButton">
                        <aw-i18n>i18n.preferencesCancelEdit</aw-i18n>
                    </aw-button>
                    <aw-button action="savePreferenceEdits" size="auto" exist-when="formProp.attributes.valid && conditions.showSaveButton">
                        <aw-i18n>i18n.preferencesSaveEdit</aw-i18n>
                    </aw-button>
                    <aw-button button-type="disabled" size="auto" action="savePreferenceEdits" exist-when="!formProp.attributes.valid && conditions.showSaveButton">
                        <aw-i18n>i18n.preferencesSaveEdit</aw-i18n>
                    </aw-button>
                </aw-panel-footer>
        </aw-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanel  revealAction={actions.reveal}>
        <AwPanelBody>
            <AwPanelSection caption={i18n.definitionPropSectionHdr} collapsed="false">
                <AwBreak>
                </AwBreak>
                <AwWidget {...getField("data.preferenceInfo.props.fnd0PreferenceName", fields, $index, null, null )}>
                </AwWidget>
                <AwWidget {...getField("data.preferenceInfo.props.fnd0ProductArea", fields, $index, null, null )}>
                </AwWidget>
                <AwWidget {...getField("data.preferenceInfo.props.fnd0Description", fields, $index, null, null )}>
                </AwWidget>
                <AwBreak>
                </AwBreak>
                <AwWidget {...getField("data.preferenceInfo.props.fnd0ProtectionScope", fields, $index, null, null )}>
                </AwWidget>
                <AwWidgetExistWhen {...getField("data.preferenceInfo.props.fnd0Environment", fields, $index, null, null )} existWhen={AwParseService.instance( "!ctx.tcadmconsole.preferences.editInProgress" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwWidgetExistWhen>
                <AwPropertyLabelExistWhen {...getField("data.fnd0EnvironmentLabelProp", fields, $index, null, null )} existWhen={AwParseService.instance( "ctx.tcadmconsole.preferences.editInProgress" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwPropertyLabelExistWhen>
                <AwWidgetExistWhen {...getField("data.preferenceInfo.props.fnd0EditEnvironment", fields, $index, null, null )} existWhen={AwParseService.instance( "ctx.tcadmconsole.preferences.editInProgress" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwWidgetExistWhen>
                <AwWidget {...getField("data.preferenceInfo.props.fnd0Location", fields, $index, null, null )}>
                </AwWidget>
                <AwWidget {...getField("data.preferenceInfo.props.fnd0ValueType", fields, $index, null, null )}>
                </AwWidget>
                <AwWidget {...getField("data.preferenceInfo.props.fnd0IsMultiValue", fields, $index, null, null )}>
                </AwWidget>
            </AwPanelSection>
            <AwPanelSection caption={i18n.Values} collapsed="false" className="aw-preferences-properties">
                <AwPropertyLabelExistWhen {...getField("data.valuesTitleLabel", fields, $index, null, null )} existWhen={AwParseService.instance( "!ctx.tcadmconsole.preferences.editInProgress" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwPropertyLabelExistWhen>
                <div className="aw-widgets-propertyNonEditValue">
                    { Object.entries( AwParseService.instance( "data.preferenceInfo.props.fnd0PreferenceValues.displayValues" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, val ] ) => (
                        <DivExistWhen className="aw-jswidgets-arrayValueCellListItem aw-preferences-value" existWhen={AwParseService.instance( "!ctx.tcadmconsole.preferences.editInProgress" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, val, $index } )} key={$index}>
                            {AwParseService.instance( "val" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, val, $index } )}
                        </DivExistWhen>
                    ) ) }
                </div>
                <AwWidgetExistWhen {...getField("data.preferenceInfo.props.fnd0PreferenceValues", fields, $index, null, null )} existWhen={AwParseService.instance( "ctx.tcadmconsole.preferences.editInProgress" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwWidgetExistWhen>
            </AwPanelSection>
        </AwPanelBody>
        <AwPanelFooterExistWhen existWhen={AwParseService.instance( "conditions.isSummaryHeaderHidden" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwButtonExistWhen action={actions.startEditPreference} size="auto" existWhen={AwParseService.instance( "conditions.showEditButton" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwI18n>
                    {i18n.preferencesStartEdit}
                </AwI18n>
            </AwButtonExistWhen>
            <AwButtonExistWhen action={actions.cancelPreferenceEdits} size="auto" buttonType="negative" existWhen={AwParseService.instance( "conditions.showCancelButton" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwI18n>
                    {i18n.preferencesCancelEdit}
                </AwI18n>
            </AwButtonExistWhen>
            <AwButtonExistWhen action={actions.savePreferenceEdits} size="auto" existWhen={AwParseService.instance( "formProp.attributes.valid && conditions.showSaveButton" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwI18n>
                    {i18n.preferencesSaveEdit}
                </AwI18n>
            </AwButtonExistWhen>
            <AwButtonExistWhen buttonType="disabled" size="auto" action={actions.savePreferenceEdits} existWhen={AwParseService.instance( "!formProp.attributes.valid && conditions.showSaveButton" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwI18n>
                    {i18n.preferencesSaveEdit}
                </AwI18n>
            </AwButtonExistWhen>
        </AwPanelFooterExistWhen>
    </AwPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;