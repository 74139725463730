// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwBaseSublocation from 'viewmodel/AwBaseSublocationViewModel';
import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwBreak from 'viewmodel/AwBreakViewModel';
import AwLink from 'viewmodel/AwLinkViewModel';
    import { ExistWhen } from 'js/hocCollection';
const AwPanelSectionExistWhen = ExistWhen(AwPanelSection);
AwPanelSectionExistWhen.displayName = 'AwPanelSectionExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('soa/dataManagementService', () => import('soa/dataManagementService'));
registerDynImportEntry('soa/kernel/clientDataModel', () => import('soa/kernel/clientDataModel'));
registerDynImportEntry('js/fileDownloadService', () => import('js/fileDownloadService'));
registerDynImportEntry('soa/fileManagementService', () => import('soa/fileManagementService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-base-sublocation.directive",
        "js/aw-panel.directive",
        "js/aw-panel-body.directive",
        "js/aw-panel-section.directive",
        "js/aw-link.directive",
        "js/aw-break.directive",
        "js/exist-when.directive"
    ],
    "actions": {
        "loadObjectWithUIDInfo": {
            "actionType": "JSFunctionAsync",
            "method": "loadObjects",
            "inputData": {
                "data": [
                    "{{ctx.state.params.uid}}"
                ]
            },
            "events": {
                "success": [
                    {
                        "name": "Awp0FileDownload.objectLoaded"
                    }
                ]
            },
            "policy": {
                "name": "ImanFile",
                "properties": [
                    {
                        "name": "original_file_name"
                    }
                ]
            },
            "deps": "soa/dataManagementService"
        },
        "convertLoadedObjToModelObj": {
            "actionType": "JSFunction",
            "method": "getObject",
            "deps": "soa/kernel/clientDataModel",
            "inputData": {
                "dataset": "{{ctx.state.params.uid}}"
            },
            "outputData": {
                "iModelObject": ""
            },
            "events": {
                "success": [
                    {
                        "name": "fileDownload.modelObjLoaded"
                    }
                ]
            }
        },
        "forkEventAction": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "condition": "conditions.isDatasetToBeProcessedCondition",
                        "name": "fileDownload.datasetToBeProcessed"
                    },
                    {
                        "condition": "conditions.isImanFileToBeProcessedCondition",
                        "name": "fileDownload.iManFileToBeProcessed"
                    }
                ]
            }
        },
        "retryDownloadAction": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "condition": "conditions.isDatasetToBeProcessedCondition",
                        "name": "fileDownload.datasetToBeProcessed"
                    },
                    {
                        "condition": "conditions.isImanFileToBeProcessedCondition",
                        "name": "fileDownload.getFileTicketForImanFileObj"
                    }
                ]
            }
        },
        "getFileNamesAndTicketsForDataset": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-AWS2-2020-05-FileMgmt",
            "method": "getFileNamesWithTicketInfo",
            "inputData": {
                "businessObjectsIn": [
                    "{{data.iModelObject}}"
                ]
            },
            "outputData": {
                "fileTicketForDatasetObj": "output[0].fileTicket",
                "fileNameForDatasetObj": "output[0].fileName"
            },
            "events": {
                "success": [
                    {
                        "name": "fileDownload.prepareMessageBeforeDownload"
                    },
                    {
                        "name": "fileDownload.openFileTicketFromDatasetInfo"
                    }
                ]
            }
        },
        "openFileTicketFromDatasetObj": {
            "actionType": "openFile",
            "inputData": {
                "fileTicket": "{{data.fileTicketForDatasetObj}}",
                "openFileName": "{{data.fileNameForDatasetObj}}"
            }
        },
        "prepareMessageActionBeforeDownload": {
            "actionType": "JSFunction",
            "method": "prepareMessageBeforeDownload",
            "deps": "js/fileDownloadService",
            "inputData": {
                "data": "{{data}}",
                "textMsgParamForImanFileObj": "{{data.iModelObject.props.original_file_name.uiValues[0]}}",
                "textMsgParamForDatasetObj": "{{data.fileNameForDatasetObj}}"
            },
            "outputData": {
                "messageText": ""
            }
        },
        "initiateDownloadForImanFileObj": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "fileDownload.prepareMessageBeforeDownload"
                    },
                    {
                        "name": "fileDownload.getFileTicketForImanFileObj"
                    }
                ]
            }
        },
        "downloadFileForImanFileObj": {
            "actionType": "JSFunctionAsync",
            "method": "getFileReadTickets",
            "deps": "soa/fileManagementService",
            "inputData": {
                "files": {
                    "$adapt": [
                        "{{data.iModelObject}}"
                    ]
                }
            },
            "outputData": {
                "fileTicketForImanFileObj": ""
            },
            "events": {
                "success": [
                    {
                        "name": "fileDownload.openFileTicketFromImanFileObj"
                    }
                ]
            }
        },
        "openFileTicketFromImanFileObj": {
            "actionType": "openFile",
            "inputData": {
                "fileTicket": "{{data.fileTicketForImanFileObj.tickets[1][0]}}",
                "openFileName": "{{data.fileTicketForImanFileObj.tickets[0][0].props.original_file_name.dbValues[0]}}"
            }
        }
    },
    "onEvent": [
        {
            "eventId": "Awp0FileDownload.contentLoaded",
            "action": "loadObjectWithUIDInfo"
        },
        {
            "eventId": "Awp0FileDownload.objectLoaded",
            "action": "convertLoadedObjToModelObj"
        },
        {
            "eventId": "fileDownload.datasetToBeProcessed",
            "action": "getFileNamesAndTicketsForDataset"
        },
        {
            "eventId": "fileDownload.modelObjLoaded",
            "action": "forkEventAction"
        },
        {
            "eventId": "fileDownload.openFileTicketFromDatasetInfo",
            "action": "openFileTicketFromDatasetObj"
        },
        {
            "eventId": "fileDownload.prepareMessageBeforeDownload",
            "action": "prepareMessageActionBeforeDownload"
        },
        {
            "eventId": "fileDownload.iManFileToBeProcessed",
            "action": "initiateDownloadForImanFileObj"
        },
        {
            "eventId": "fileDownload.getFileTicketForImanFileObj",
            "action": "downloadFileForImanFileObj"
        },
        {
            "eventId": "fileDownload.openFileTicketFromImanFileObj",
            "action": "openFileTicketFromImanFileObj"
        }
    ],
    "conditions": {
        "isDatasetToBeProcessedCondition": {
            "expression": {
                "$source": {
                    "$adapt": "data.iModelObject"
                },
                "$query": {
                    "$source": "modelType.typeHierarchyArray",
                    "$query": {
                        "$in": [
                            "Dataset"
                        ]
                    }
                }
            }
        },
        "isImanFileToBeProcessedCondition": {
            "expression": {
                "$source": {
                    "$adapt": "data.iModelObject"
                },
                "$query": {
                    "$source": "modelType.typeHierarchyArray",
                    "$query": {
                        "$in": [
                            "ImanFile"
                        ]
                    }
                }
            }
        }
    },
    "i18n": {
        "fileDownloadRetryMessage": [
            "ZeroCompileCommandMessages"
        ],
        "retryDownloadLinkLabel": [
            "ZeroCompileCommandMessages"
        ]
    },
    "functions": {},
    "data": {
        "retryFileDownload": {
            "displayName": "{{i18n.retryDownloadLinkLabel}}",
            "type": "STRING"
        }
    },
    "_viewModelId": "Awp0FileDownload",
    "_uniqueViewModelId": "Awp0FileDownload",
    "ctx": {
        "state": {
            "type": "object"
        }
    }
};

    /*
    <aw-base-sublocation>
    <aw-panel>
        <aw-panel-body>
            <aw-panel-section exist-when="data.messageText !== null">
                {{data.messageText}}
                <aw-break></aw-break>
                <aw-link prop="data.retryFileDownload" action="retryDownloadAction"></aw-link>
            </aw-panel-section>
        </aw-panel-body>
    </aw-panel>
</aw-base-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwBaseSublocation>
        <AwPanel  revealAction={actions.reveal}>
            <AwPanelBody>
                <AwPanelSectionExistWhen existWhen={AwParseService.instance( "data.messageText !== null" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    {AwParseService.instance( "data.messageText" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                    <AwBreak>
                    </AwBreak>
                    <AwLink {...getField("data.retryFileDownload", fields, $index, null, null )} action={actions.retryDownloadAction}>
                    </AwLink>
                </AwPanelSectionExistWhen>
            </AwPanelBody>
        </AwPanel>
    </AwBaseSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;