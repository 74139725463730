// @<COPYRIGHT>@
// ==================================================
// Copyright 2021.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/* global
define
*/

/**
 * @module js/mbmOnDemandCompareService
 */

import mbmAlignmentInitializationService from 'js/mbmAlignmentInitializationService';
import mbmUtils from 'js/mbmUtils';
import { constants as mbmConstants } from 'js/mbmConstants';
import eventBus from 'js/eventBus';
var exports = {};

/**
  * Toggles show assignment state
  *
  * @return {Promise} returns promise
  */
function mbmToggleAssignmentState( targetContext, updatedToggleState ) {
    let mbmOnDemandCompareInfo = mbmUtils.getContextValue( targetContext, mbmConstants.MBM_ONDEMAND_COMPARE );
    mbmOnDemandCompareInfo.OnDemandCompareSelected = updatedToggleState;

    const objToUpdate = mbmUtils.getValueToUpdate( mbmOnDemandCompareInfo, mbmConstants.MBM_ONDEMAND_COMPARE );
    mbmUtils.updateValueToContext( targetContext, objToUpdate );

    setCompareToggleSelectionToLocalStorage( targetContext.mbmOnDemandCompareInfo.OnDemandCompareSelected );
    eventBus.publish( 'mbm.updateChangeSummary' );
}

/**
  * Sets given compare toggle selection to localstorage
  *
  * @param {Boolean} selection
  */
function setCompareToggleSelectionToLocalStorage( selection ) {
    mbmAlignmentInitializationService.setIntoLocalStorage( 'onDemandCompareSelection', selection );
}

/**
  * sets setOnDemandCompareToggleInfo
  * @param {Boolean} showAssignmentState show the toggle or not
  * @param {Boolean} selectionState select the toggle or not
  */
function setOnDemandCompareToggleInfo( mbmContext, targetContext ) {
    let mbmFocusModeSelected = mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_FOCUS_MODE );

    let mbmOnDemandCompareInfo = mbmUtils.getContextValue( targetContext, mbmConstants.MBM_ONDEMAND_COMPARE );
    mbmOnDemandCompareInfo.showAssignmentState = mbmFocusModeSelected;

    if ( !mbmFocusModeSelected ) {
        mbmOnDemandCompareInfo.OnDemandCompareSelected = mbmFocusModeSelected;
    }

    const objToUpdate = mbmUtils.getValueToUpdate( mbmOnDemandCompareInfo, mbmConstants.MBM_ONDEMAND_COMPARE );
    mbmUtils.updateValueToContext( targetContext, objToUpdate );
}

export default exports = {
    mbmToggleAssignmentState,
    setOnDemandCompareToggleInfo,
    setCompareToggleSelectionToLocalStorage
};

