// @<COPYRIGHT>@
// ==================================================
// Copyright 2023.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * @module js/epClassificationService
 */

import dmSvc from 'soa/dataManagementService';
import cdm from 'soa/kernel/clientDataModel';
import { includeComponent } from 'js/moduleLoader';
import { renderComponent } from 'js/declReactUtils';
import localeService from 'js/localeService';
import epTableCellRenderer from 'js/epTableCellRenderer';
import { svgString } from 'image/miscTableHeaderClassification16.svg';
import epLoadService from 'js/epLoadService';
import epLoadInputHelper from 'js/epLoadInputHelper';
import eventBus from 'js/eventBus';

const localizedMsgs = localeService.getLoadedText( 'classificationResourceMessages' );
const TABLE_CELL_IMAGE_VIEW = 'MfeTableCellImage';

const cellRenderer = {
    imageSrc: 'typeClassificationElement48',
    tooltipView: 'MfeGenericTooltip',
    tooltipData: {
        extendedTooltip: {
            title: localizedMsgs.classified
        }
    },
    isClickable: false
};

/**
 * This will load the revision object from uid and will update in occContext.
 * @param {object} occContext - occContext
 * @param {object} sourceObject - object
 */
async function getSelectedObject( occContext, sourceObject ) {
    let selectedUid = sourceObject[0].props.bl_revision.dbValue;
    let selectedObj = [];
    if( selectedUid ) {
        await dmSvc.loadObjects( [ selectedUid ] );
        selectedObj.push( cdm.getObject( selectedUid ) );
        occContext.update( { ...occContext.getValue(), selectedModelObjects: selectedObj, sourceObject:sourceObject } );
    }
}

/**
 * Render Classification on cell.
 *
 * @param {Object} vmo - the vmo for the cell
 * @param {DOMElement} containerElement - the container element
 * @param {Objcet} field - prop name
 */
export function rendererForEpClassification( vmo, containerElement, field ) {
    if( !containerElement ) {
        return;
    }
    if( vmo.props && vmo.props[ field ] && vmo.props[ field ].dbValues[ 0 ].length > 0 ) {
        const imageElement = includeComponent( TABLE_CELL_IMAGE_VIEW, cellRenderer );
        renderComponent( imageElement, containerElement );
    }
}

/**

 * Renderer for classification column header
 *
 * @param {DOMElement} containerElement - the icon container element
 */
export function classificationHeaderRenderer( containerElement ) {
    epTableCellRenderer.renderColumnHeader( containerElement, svgString, localizedMsgs.isClassified );
}


/**
 * THis function will update the classification props in the Vmo
 * @param {object} selectedObj - occContext
 * @returns {Promise} promise -
 */
function updateClassificationPropForVmo(  selectedObj ) {
    if( selectedObj ) {
        const loadTypeInput = epLoadInputHelper.getLoadTypeInputs( [ 'getProperties' ], selectedObj[0].uid, [
            'bl_rev_fnd0IcsClassNames'
        ] );
        return epLoadService.loadObject( loadTypeInput, false ).then( ( reponse )=>{
            let eventData = {};
            eventData[cdm.getObject( selectedObj[0].uid )] = [ 'bl_rev_fnd0IcsClassNames' ];
            eventBus.publish( 'viewModelObject.propsUpdated', eventData );
        } );
    }
}

export default {
    getSelectedObject,
    classificationHeaderRenderer,
    rendererForEpClassification,
    updateClassificationPropForVmo
};
