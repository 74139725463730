// @<COPYRIGHT>@
// ==================================================
// Copyright 2021.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import preferenceService from 'soa/preferenceService';
import { constants as mbmConstants } from 'js/mbmConstants';
import mbmUtils from 'js/mbmUtils';

const NO_EBOM_MBOM_DETAILS = '0';

const EBOM_DETAILS_BOTTOM = '1';
const EBOM_DETAILS_SIDE = '2';

const MBOM_DETAILS_BOTTOM = '3';
const MBOM_DETAILS_SIDE = '4';

const EBOM_DETAILS_BOTTOM_MBOM_DETAILS_SIDE = '5';
const EBOM_DETAILS_SIDE_MBOM_DETAILS_BOTTOM = '6';
const EBOM_MBOM_DETAILS_BOTTOM = '7';
const EBOM_MBOM_DETAILS_SIDE = '8';

const EBOM_SHOW_DETAILS = [
    EBOM_DETAILS_BOTTOM,
    EBOM_DETAILS_SIDE,
    EBOM_MBOM_DETAILS_BOTTOM,
    EBOM_MBOM_DETAILS_SIDE,
    EBOM_DETAILS_BOTTOM_MBOM_DETAILS_SIDE,
    EBOM_DETAILS_SIDE_MBOM_DETAILS_BOTTOM
];
const MBOM_SHOW_DETAILS = [
    MBOM_DETAILS_BOTTOM,
    MBOM_DETAILS_SIDE,
    EBOM_MBOM_DETAILS_BOTTOM,
    EBOM_MBOM_DETAILS_SIDE,
    EBOM_DETAILS_BOTTOM_MBOM_DETAILS_SIDE,
    EBOM_DETAILS_SIDE_MBOM_DETAILS_BOTTOM
];

const EBOM_SHOW_BOTTOM = [
    EBOM_DETAILS_BOTTOM,
    EBOM_MBOM_DETAILS_BOTTOM,
    EBOM_DETAILS_BOTTOM_MBOM_DETAILS_SIDE
];

const EBOM_SHOW_SIDE = [
    EBOM_DETAILS_SIDE,
    EBOM_MBOM_DETAILS_SIDE,
    EBOM_DETAILS_SIDE_MBOM_DETAILS_BOTTOM
];
const MBOM_SHOW_BOTTOM = [
    MBOM_DETAILS_BOTTOM,
    EBOM_MBOM_DETAILS_BOTTOM,
    EBOM_DETAILS_SIDE_MBOM_DETAILS_BOTTOM
];
const MBOM_SHOW_SIDE = [
    MBOM_DETAILS_SIDE,
    EBOM_MBOM_DETAILS_SIDE,
    EBOM_DETAILS_BOTTOM_MBOM_DETAILS_SIDE
];

/**
 *
 * @param {mbmContext} mbmContext mbmContext
 */
function initializeDetailsLayout( mbmContext ) {
    preferenceService.getStringValue( mbmConstants.MBM_DETAILS_CONTENT_LAYOUT_PREFERENCE ).then( function( prefValue ) {
        let detailsContentState = mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_DETAILS_CONTENT_STATE );

        detailsContentState.ebomContext.showDetails = EBOM_SHOW_DETAILS.indexOf( prefValue ) > -1;
        detailsContentState.ebomContext.showOnBottom = EBOM_SHOW_BOTTOM.indexOf( prefValue ) > -1;
        detailsContentState.ebomContext.showOnSide = EBOM_SHOW_SIDE.indexOf( prefValue ) > -1;
        detailsContentState.ebomContext.initialize3DViewer = detailsContentState.ebomContext.showDetails;

        detailsContentState.mbomContext.showDetails = MBOM_SHOW_DETAILS.indexOf( prefValue ) > -1;
        detailsContentState.mbomContext.showOnBottom = MBOM_SHOW_BOTTOM.indexOf( prefValue ) > -1;
        detailsContentState.mbomContext.showOnSide = MBOM_SHOW_SIDE.indexOf( prefValue ) > -1;
        detailsContentState.mbomContext.initialize3DViewer = detailsContentState.mbomContext.showDetails;
        let objToUpdate = mbmUtils.getValueToUpdate( detailsContentState, mbmConstants.MBM_DETAILS_CONTENT_STATE );
        mbmUtils.updateValueToContext( mbmContext, objToUpdate );
    } );
}

/**
 *
 * @param {Object} mbmContext mbmContext
 * @param {String} contextKey contextKey
 */
function mbmShowHideToggleDetailsContent( mbmContext, contextKey ) {
    let detailsContentState = mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_DETAILS_CONTENT_STATE );
    if( detailsContentState && detailsContentState.hasOwnProperty( contextKey ) ) {
        let showDetails = detailsContentState[ contextKey ].showDetails;
        let initialize3DViewer = detailsContentState[ contextKey ].initialize3DViewer;
        if( !showDetails && !initialize3DViewer ) {
            detailsContentState[ contextKey ].initialize3DViewer = true;
        }

        detailsContentState[ contextKey ].showDetails = !showDetails;
        let showOnBottom = detailsContentState[ contextKey ].showOnBottom;
        let showOnSide = detailsContentState[ contextKey ].showOnSide;
        if( !showDetails && !showOnBottom && !showOnSide ) {
            detailsContentState[ contextKey ].showOnBottom = !showOnBottom;
        }
        let objToUpdate = mbmUtils.getValueToUpdate( detailsContentState, mbmConstants.MBM_DETAILS_CONTENT_STATE );
        mbmUtils.updateValueToContext( mbmContext, objToUpdate );
    }

    update3DViewerContentLayoutPreference( mbmContext );
}
/**
 * @param {Object} commandContext commandContext
 * @param {Object} mbmContext mbmContext
 * @param {String} contextKey contextKey
 */
function mbmShowDetailsContentOnBottom( commandContext, mbmContext, contextKey ) {
    let detailsContentState = mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_DETAILS_CONTENT_STATE );
    if( !detailsContentState[ contextKey ].showOnBottom ) {
        mbmUpdateDetailsContentBottomSideState( commandContext, mbmContext, true, false, contextKey );
    }
}

/**
 * @param {Object} commandContext commandContext
 * @param {Object} mbmContext mbmContext
 * @param {String} contextKey contextKey
 */
function mbmShowDetailsContentOnSide( commandContext, mbmContext, contextKey ) {
    let detailsContentState = mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_DETAILS_CONTENT_STATE );
    if( !detailsContentState[ contextKey ].showOnSide ) {
        mbmUpdateDetailsContentBottomSideState( commandContext, mbmContext, false, true, contextKey );
    }
}
/**
 * @param {Object} commandContext commandContext
 * @param {Object} mbmContext mbmContext
 * @param {boolean} showOnBottom showOnBottom
 * @param {boolean} showOnSide showOnSide
 * @param {String} contextKey contextKey
 */
function mbmUpdateDetailsContentBottomSideState( commandContext, mbmContext, showOnBottom, showOnSide, contextKey ) {
    let newCommandContext = { ...commandContext.getValue() };
    let detailsContentState = { ...mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_DETAILS_CONTENT_STATE ) };

    detailsContentState[ contextKey ].showOnBottom = showOnBottom;
    detailsContentState[ contextKey ].showOnSide = showOnSide;
    const objToUpdate = mbmUtils.getValueToUpdate( detailsContentState, mbmConstants.MBM_DETAILS_CONTENT_STATE );
    mbmUtils.updateValueToContext( mbmContext, objToUpdate );
    mbmContext[ mbmConstants.MBM_DETAILS_CONTENT_STATE ] = detailsContentState;
    if( newCommandContext ) {
        newCommandContext.mbmContext = mbmContext;
        commandContext.update( newCommandContext );
    }
    update3DViewerContentLayoutPreference( mbmContext  );
}
/**
 *
 * @param {Object} mbmContext mbmContext
 */
function update3DViewerContentLayoutPreference( mbmContext ) {
    let detailsContentState = mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_DETAILS_CONTENT_STATE );
    let prefValue = NO_EBOM_MBOM_DETAILS;
    if( detailsContentState.ebomContext.showDetails ) {
        if( detailsContentState.mbomContext.showDetails ) {
            if( detailsContentState.ebomContext.showOnBottom && detailsContentState.mbomContext.showOnBottom ) {
                prefValue = EBOM_MBOM_DETAILS_BOTTOM;
            } else if( detailsContentState.ebomContext.showOnBottom && !detailsContentState.mbomContext.showOnBottom ) {
                prefValue = EBOM_DETAILS_BOTTOM_MBOM_DETAILS_SIDE;
            } else if( detailsContentState.ebomContext.showOnSide && detailsContentState.mbomContext.showOnBottom ) {
                prefValue = EBOM_DETAILS_SIDE_MBOM_DETAILS_BOTTOM;
            } else if( detailsContentState.ebomContext.showOnSide && !detailsContentState.mbomContext.showOnBottom ) {
                prefValue = EBOM_MBOM_DETAILS_SIDE;
            }
        } else if( detailsContentState.ebomContext.showOnBottom ) {
            prefValue = EBOM_DETAILS_BOTTOM;
        } else {
            prefValue = EBOM_DETAILS_SIDE;
        }
    } else if( detailsContentState.mbomContext.showDetails ) {
        if( detailsContentState.mbomContext.showOnBottom ) {
            prefValue = MBOM_DETAILS_BOTTOM;
        } else {
            prefValue = MBOM_DETAILS_SIDE;
        }
    }

    preferenceService.setStringValue( mbmConstants.MBM_DETAILS_CONTENT_LAYOUT_PREFERENCE, [ prefValue ] );
}

/**
 *
 * @param {Object} mbmContext mbmContext
 * @param {Object} cnObject  cnObject
 */
async function setOpenWithChangeNotice( mbmContext, cnObject ) {
    let mbmChangeManagementPrefValue = await preferenceService.getStringValue( mbmConstants.MBM_CHANGE_MANAGEMENT_WITH_CSP );

    if ( cnObject && mbmChangeManagementPrefValue === 'true' ) {
        mbmUtils.updateValueToContext( mbmContext, { isOpenWithCsp: true } );
    }
}

export default {
    initializeDetailsLayout,
    setOpenWithChangeNotice,
    mbmShowHideToggleDetailsContent,
    mbmShowDetailsContentOnBottom,
    mbmShowDetailsContentOnSide
};
