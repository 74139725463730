// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwChart from 'viewmodel/AwChartViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Cm1ChangeDashboardService', () => import('js/Cm1ChangeDashboardService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-chart.directive",
        "js/aw-row.directive"
    ],
    "actions": {
        "createPieChartForType": {
            "actionType": "TcSoaService",
            "serviceName": "Query-2014-11-Finder",
            "method": "performSearch",
            "inputData": {
                "searchInput": {
                    "maxToLoad": 50,
                    "maxToReturn": 50,
                    "providerName": "Cm1MyChangesProvider",
                    "searchCriteria": {
                        "changesProviderContentType": "Dashboard"
                    },
                    "searchFilterMap": {},
                    "searchFilterFieldSortType": "Priority",
                    "startIndex": 0
                }
            },
            "outputData": {
                "totalFound": "totalFound",
                "searchResults": "searchResults",
                "pieChartProviderType_chartPoints": "{{function:getTypePieChartPoints}}"
            },
            "deps": "js/Cm1ChangeDashboardService"
        },
        "createPieChartForCreationDate": {
            "actionType": "TcSoaService",
            "serviceName": "Query-2014-11-Finder",
            "method": "performSearch",
            "inputData": {
                "searchInput": {
                    "maxToLoad": 50,
                    "maxToReturn": 50,
                    "providerName": "Cm1MyChangesProvider",
                    "searchCriteria": {
                        "changesProviderContentType": "Dashboard"
                    },
                    "searchFilterMap": {},
                    "searchFilterFieldSortType": "Priority",
                    "startIndex": 0
                }
            },
            "outputData": {
                "totalFound": "totalFound",
                "searchResults": "searchResults",
                "pieChartProviderCreationDate_chartPoints": "{{function:getCreationDatePieChartPoints}}"
            },
            "deps": "js/Cm1ChangeDashboardService"
        },
        "createPieChartForMaturity": {
            "actionType": "TcSoaService",
            "serviceName": "Query-2014-11-Finder",
            "method": "performSearch",
            "inputData": {
                "searchInput": {
                    "maxToLoad": 50,
                    "maxToReturn": 50,
                    "providerName": "Cm1MyChangesProvider",
                    "searchCriteria": {
                        "changesProviderContentType": "Dashboard"
                    },
                    "searchFilterMap": {},
                    "searchFilterFieldSortType": "Priority",
                    "startIndex": 0
                }
            },
            "outputData": {
                "totalFound": "totalFound",
                "searchResults": "searchResults",
                "pieChartProviderMaturity_chartPoints": "{{function:getMaturityPieChartPoints}}"
            },
            "deps": "js/Cm1ChangeDashboardService"
        },
        "clearAndSetPieChartFilter": {
            "actionType": "JSFunction",
            "method": "clearPreviousChartFilters",
            "inputData": {
                "data": "{{data}}",
                "currentChart": "{{data.eventMap['clearAndSetPieChartFilter'].currentChart}}"
            },
            "events": {
                "success": [
                    {
                        "name": "setPieChartFilter"
                    }
                ]
            },
            "outputData": {
                "activeChart": "activeChart",
                "clearParams": "clearParams"
            },
            "deps": "js/Cm1ChangeDashboardService"
        },
        "updateFiltersForTypePieChart": {
            "actionType": "JSFunction",
            "method": "updateFilters",
            "inputData": {
                "data": "{{data}}",
                "currentChart": "pieChartProviderType"
            },
            "outputData": {
                "chartListboxPropData": "chartListboxPropData",
                "filterValue": "filterValue",
                "filterCategory": "filterCategory"
            },
            "events": {
                "success": [
                    {
                        "name": "clearAndSetPieChartFilter",
                        "eventData": {
                            "currentChart": "pieChartProviderType"
                        }
                    }
                ]
            },
            "deps": "js/Cm1ChangeDashboardService"
        },
        "setPieChartFilter": {
            "actionType": "JSFunction",
            "method": "setPieChartFilter",
            "inputData": {
                "data": "{{data}}"
            },
            "events": {
                "success": [
                    {
                        "name": "setFilters",
                        "eventData": {
                            "filterParams": "{{data.filterParams}}"
                        }
                    }
                ]
            },
            "outputData": {
                "filterParams": ""
            },
            "deps": "js/Cm1ChangeDashboardService"
        },
        "updateFiltersForCreationDatePieChart": {
            "actionType": "JSFunction",
            "method": "updateFilters",
            "inputData": {
                "data": "{{data}}",
                "currentChart": "pieChartProviderCreationDate"
            },
            "outputData": {
                "chartListboxPropData": "chartListboxPropData",
                "filterValue": "filterValue",
                "filterCategory": "filterCategory"
            },
            "events": {
                "success": [
                    {
                        "name": "clearAndSetPieChartFilter",
                        "eventData": {
                            "currentChart": "pieChartProviderCreationDate"
                        }
                    }
                ]
            },
            "deps": "js/Cm1ChangeDashboardService"
        },
        "updateFiltersForMaturityPieChart": {
            "actionType": "JSFunction",
            "method": "updateFilters",
            "inputData": {
                "data": "{{data}}",
                "currentChart": "pieChartProviderMaturity"
            },
            "outputData": {
                "chartListboxPropData": "chartListboxPropData",
                "filterValue": "filterValue",
                "filterCategory": "filterCategory"
            },
            "events": {
                "success": [
                    {
                        "name": "clearAndSetPieChartFilter",
                        "eventData": {
                            "currentChart": "pieChartProviderMaturity"
                        }
                    }
                ]
            },
            "deps": "js/Cm1ChangeDashboardService"
        },
        "updatePieChart": {
            "actionType": "JSFunction",
            "method": "updatePieChart",
            "inputData": {
                "data": "{{data}}",
                "searchState": "{{data.eventMap['updatePieChart'].searchState}}"
            },
            "outputData": {
                "chartProviders": "chartProviders",
                "pieChartProviderType_chartPoints": "pieChartProviderType_chartPoints",
                "pieChartProviderCreationDate_chartPoints": "pieChartProviderCreationDate_chartPoints",
                "pieChartProviderMaturity_chartPoints": "pieChartProviderMaturity_chartPoints"
            },
            "deps": "js/Cm1ChangeDashboardService"
        }
    },
    "functions": {
        "getTypePieChartPoints": {
            "functionName": "createPieChart",
            "parameters": [
                "{{data}}",
                "pieChartProviderType"
            ]
        },
        "getCreationDatePieChartPoints": {
            "functionName": "createPieChart",
            "parameters": [
                "{{data}}",
                "pieChartProviderCreationDate"
            ]
        },
        "getMaturityPieChartPoints": {
            "functionName": "createPieChart",
            "parameters": [
                "{{data}}",
                "pieChartProviderMaturity"
            ]
        }
    },
    "data": {
        "chartListboxPropData": {
            "displayName": "{{i18n.SearchResultsChartSelectorTitle}}",
            "type": "STRING",
            "isRequired": "false",
            "hasLov": "true",
            "dbValue": "{{ctx.chartProvider.chartListboxPropData.dbValue}}",
            "dispValue": "{{ctx.chartProvider.chartListboxPropData.dispValue}}"
        },
        "dashboardActiveFilter": {
            "type": "STRING",
            "isArray": true
        },
        "chartTitleValue": {
            "titleValue": "{{i18n.SearchResultsChartTitle}} {{ctx.chartProvider.chartListboxPropData.dispValue}}"
        },
        "pieChartProviderType": {
            "chartBy": "ChangeItemRevision.object_type",
            "defaultChartCategory": "ChangeItemRevision.object_type",
            "categoryDisplayName": "Type"
        },
        "pieChartProviderCreationDate": {
            "chartBy": "ChangeItemRevision.creation_date",
            "defaultChartCategory": "ChangeItemRevision.creation_date",
            "categoryDisplayName": "Creation Date"
        },
        "pieChartProviderMaturity": {
            "chartBy": "ChangeItemRevision.CMMaturity",
            "defaultChartCategory": "ChangeItemRevision.CMMaturity",
            "categoryDisplayName": "Maturity"
        }
    },
    "onEvent": [
        {
            "eventId": "pieChartProviderType.selected",
            "cacheEventData": true,
            "action": "updateFiltersForTypePieChart"
        },
        {
            "eventId": "pieChartProviderCreationDate.selected",
            "cacheEventData": true,
            "action": "updateFiltersForCreationDatePieChart"
        },
        {
            "eventId": "pieChartProviderMaturity.selected",
            "cacheEventData": true,
            "action": "updateFiltersForMaturityPieChart"
        },
        {
            "eventId": "updatePieChart",
            "cacheEventData": true,
            "action": "updatePieChart"
        },
        {
            "eventId": "clearAndSetPieChartFilter",
            "cacheEventData": true,
            "action": "clearAndSetPieChartFilter"
        },
        {
            "eventId": "pieChartProviderType.create",
            "cacheEventData": true,
            "action": "createPieChartForType"
        },
        {
            "eventId": "pieChartProviderCreationDate.create",
            "cacheEventData": true,
            "action": "createPieChartForCreationDate"
        },
        {
            "eventId": "pieChartProviderMaturity.create",
            "cacheEventData": true,
            "action": "createPieChartForMaturity"
        },
        {
            "eventId": "setPieChartFilter",
            "action": "setPieChartFilter"
        }
    ],
    "chartProviders": {
        "pieChartProviderType": {
            "title": "Changes",
            "chartType": "pie",
            "loadDataAction": "createPieChartForType",
            "chartConfig": {
                "isChartZoomable": true,
                "isYAxisLinearOrLogarithmic": "logarithmic",
                "xAxisLabel": "",
                "yAxisLabel": "",
                "isDataLabelOnChartEnabled": true
            }
        },
        "pieChartProviderCreationDate": {
            "title": "Changes",
            "chartType": "pie",
            "loadDataAction": "createPieChartForCreationDate",
            "chartConfig": {
                "isChartZoomable": true,
                "isYAxisLinearOrLogarithmic": "logarithmic",
                "xAxisLabel": "",
                "yAxisLabel": "",
                "isDataLabelOnChartEnabled": true
            }
        },
        "pieChartProviderMaturity": {
            "title": "Changes",
            "chartType": "pie",
            "loadDataAction": "createPieChartForMaturity",
            "chartConfig": {
                "isChartZoomable": true,
                "isYAxisLinearOrLogarithmic": "logarithmic",
                "xAxisLabel": "",
                "yAxisLabel": "",
                "isDataLabelOnChartEnabled": true
            }
        }
    },
    "_viewModelId": "Cm1ChangeDashboardPieChart",
    "_uniqueViewModelId": "Cm1ChangeDashboardPieChart",
    "ctx": {
        "chartProvider": {
            "type": "object"
        }
    }
};

    /*
    <div class="sw-row flex-auto">

    <div class="sw-column w-1 flex-auto">
        <aw-chart chart-provider="data.chartProviders.pieChartProviderType" chart-points="data.pieChartProviderType_chartPoints"></aw-chart>
    </div>
    <div class="sw-column w-1 flex-auto">
        <aw-chart chart-provider="data.chartProviders.pieChartProviderCreationDate" chart-points="data.pieChartProviderCreationDate_chartPoints"></aw-chart>
    </div>
    <div class="sw-column w-1 flex-auto">
        <aw-chart chart-provider="data.chartProviders.pieChartProviderMaturity" chart-points="data.pieChartProviderMaturity_chartPoints"></aw-chart>
    </div>

</div>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="sw-row flex-auto">
        <div className="sw-column w-1 flex-auto">
            <AwChart chartProvider={chartProviders.pieChartProviderType} chartPoints={data.pieChartProviderType_chartPoints}>
            </AwChart>
        </div>
        <div className="sw-column w-1 flex-auto">
            <AwChart chartProvider={chartProviders.pieChartProviderCreationDate} chartPoints={data.pieChartProviderCreationDate_chartPoints}>
            </AwChart>
        </div>
        <div className="sw-column w-1 flex-auto">
            <AwChart chartProvider={chartProviders.pieChartProviderMaturity} chartPoints={data.pieChartProviderMaturity_chartPoints}>
            </AwChart>
        </div>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;