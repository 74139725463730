// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import EpConfigurationChipArea from 'viewmodel/EpConfigurationChipAreaViewModel';
import AwSublocation from 'viewmodel/AwSublocationViewModel';
import MfeLoadingMessage from 'viewmodel/MfeLoadingMessageViewModel';
import MfeContentContainer from 'viewmodel/MfeContentContainerViewModel';
import MfeContentPanel from 'viewmodel/MfeContentPanelViewModel';
    import { ExistWhen } from 'js/hocCollection';
const EpConfigurationChipAreaExistWhen = ExistWhen(EpConfigurationChipArea);
const MfeLoadingMessageExistWhen = ExistWhen(MfeLoadingMessage);
const MfeContentContainerExistWhen = ExistWhen(MfeContentContainer);
EpConfigurationChipAreaExistWhen.displayName = 'EpConfigurationChipAreaExistWhen';
MfeLoadingMessageExistWhen.displayName = 'MfeLoadingMessageExistWhen';
MfeContentContainerExistWhen.displayName = 'MfeContentContainerExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/epInitializationService', () => import('js/epInitializationService'));
registerDynImportEntry('js/mfeViewModelUtils', () => import('js/mfeViewModelUtils'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "provider": {
            "label": "operationPlanning",
            "priority": 3,
            "name": "operationPlanning",
            "clientScopeURI": "operationPlanning",
            "nameToken": "operationPlanning"
        },
        "awSubLocationPref": {
            "hideNavigationArea": false,
            "hideRightWall": true,
            "hideToolsAndInfoArea": false
        },
        "tabs": [
            {
                "name": "{{i18n.StationOperationsTitle}}",
                "tabKey": "EpOperationPlanningOperationsTable"
            }
        ],
        "configurationChipsData": {
            "initialValues": {},
            "meta": {}
        },
        "occContext": {
            "initialValues": {
                "variantsListInfo": {
                    "availableList": "",
                    "selectedList": ""
                }
            },
            "meta": {}
        }
    },
    "actions": {
        "initialize": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeEPPage"
                },
                {
                    "action": "initialiseConfigurationChips"
                }
            ]
        },
        "initializeEPPage": {
            "actionType": "JSFunctionAsync",
            "method": "initializeEPPage",
            "inputData": {
                "provider": "{{data.provider}}"
            },
            "deps": "js/epInitializationService"
        },
        "initialiseConfigurationChips": {
            "actionType": "JSFunction",
            "method": "mergeValueInAtomicData",
            "inputData": {
                "targetContext": "{{fields.configurationChipsData}}",
                "updatedValues": {
                    "ebomStructure": "{{ctx.epPageContext.ebomStructure}}",
                    "ebomPCI": "{{ctx.epTaskPageContext.ebomPCI}}",
                    "ebomStructureConfigFlags": "{{ctx.epTaskPageContext.ebomStructureConfigFlags}}",
                    "mbomStructure": "{{ctx.epPageContext.mbomStructure}}",
                    "mbomPCI": "{{ctx.epTaskPageContext.mbomPCI}}",
                    "mbomStructureConfigFlags": "{{ctx.epTaskPageContext.mbomStructureConfigFlags}}",
                    "processStructure": "{{ctx.epPageContext.processStructure}}",
                    "processPCI": "{{ctx.epTaskPageContext.processPCI}}",
                    "processStructureConfigFlags": "{{ctx.epTaskPageContext.processStructureConfigFlags}}",
                    "rootPlant": "{{ctx.epPageContext.rootPlant}}",
                    "plantPCI": "{{ctx.epTaskPageContext.plantPCI}}",
                    "rootPlantConfigFlags": "{{ctx.epTaskPageContext.rootPlantConfigFlags}}",
                    "functionalPlan": "{{ctx.epTaskPageContext.functionalPlan}}",
                    "functionalPlanPCI": "{{ctx.epTaskPageContext.functionalPlanPCI}}",
                    "functionalPlanConfigFlags": "{{ctx.epTaskPageContext.functionalPlanConfigFlags}}"
                }
            },
            "deps": "js/mfeViewModelUtils"
        }
    },
    "onEvent": [
        {
            "eventId": "ep.pageContext.changed",
            "action": "initialiseConfigurationChips"
        }
    ],
    "lifecycleHooks": {
        "onMount": "initialize"
    },
    "i18n": {
        "StationOperationsTitle": [
            "PlanningMessages"
        ]
    },
    "_viewModelId": "EpOperationPlanningSubpage",
    "_uniqueViewModelId": "EpOperationPlanningSubpage",
    "ctx": {
        "epPageContext": {
            "type": "object"
        },
        "epTaskPageContext": {
            "type": "object"
        },
        "ep": {
            "type": "object"
        }
    }
};

    /*
    <ep-configuration-chip-area exist-when="ctx.epTaskPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" configuration-chips-data = "data.configurationChipsData"></ep-configuration-chip-area>
<aw-sublocation sub-location-preference="data.awSubLocationPref" sub-panel-context="{provider : data.provider}" class="afx-content-background">
    <mfe-loading-message exist-when="!ctx.epPageContext.loadedObject.uid"></mfe-loading-message>
    <mfe-content-container container-direction="HORIZONTAL" container-name="OperationPlanningPage" class="h-12" exist-when="ctx.epTaskPageContext.loadedObject.uid">
        <mfe-content-container container-direction="VERTICAL" container-name="OperationPlanningPageLeftArea" class="w-12">
            <mfe-content-panel occ-context="data.occContext" sub-panel-context="{...data,...props.subPanelContext, occContext:data.occContext}"></mfe-content-panel>
            </mfe-content-container>
    </mfe-content-container>
</aw-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <EpConfigurationChipAreaExistWhen configurationChipsData={AwParseService.instance( "fields.configurationChipsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "ctx.epTaskPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </EpConfigurationChipAreaExistWhen>
    <AwSublocation subLocationPreference={AwParseService.instance( "data.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{provider : data.provider}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="afx-content-background">
        <MfeLoadingMessageExistWhen existWhen={AwParseService.instance( "!ctx.epPageContext.loadedObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </MfeLoadingMessageExistWhen>
        <MfeContentContainerExistWhen containerDirection="HORIZONTAL" containerName="OperationPlanningPage" className="h-12" existWhen={AwParseService.instance( "ctx.epTaskPageContext.loadedObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <MfeContentContainer containerDirection="VERTICAL" containerName="OperationPlanningPageLeftArea" className="w-12">
                <MfeContentPanel occContext={AwParseService.instance( "fields.occContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{...data,...props.subPanelContext, occContext:fields.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MfeContentPanel>
            </MfeContentContainer>
        </MfeContentContainerExistWhen>
    </AwSublocation>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;