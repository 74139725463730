// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwScrollpanel from 'viewmodel/AwScrollpanelViewModel';
import AwToolbar from 'viewmodel/AwToolbarViewModel';
import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import AwWidget from 'viewmodel/AwWidgetViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwSplmTable from 'viewmodel/AwSplmTableViewModel';
    import { ExistWhen,VisibleWhen } from 'js/hocCollection';
const AwToolbarExistWhen = ExistWhen(AwToolbar);
const AwCommandPanelSectionVisibleWhen = VisibleWhen(AwCommandPanelSection);
const DivVisibleWhen = VisibleWhen('div');
const AwPanelSectionExistWhen = ExistWhen(AwPanelSection);
const AwPanelSectionVisibleWhen = VisibleWhen(AwPanelSection);
AwToolbarExistWhen.displayName = 'AwToolbarExistWhen';
AwCommandPanelSectionVisibleWhen.displayName = 'AwCommandPanelSectionVisibleWhen';
DivVisibleWhen.displayName = 'DivVisibleWhen';
AwPanelSectionExistWhen.displayName = 'AwPanelSectionExistWhen';
AwPanelSectionVisibleWhen.displayName = 'AwPanelSectionVisibleWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/classifyAdminService', () => import('js/classifyAdminService'));
registerDynImportEntry('js/classifyAdminTreeTableService', () => import('js/classifyAdminTreeTableService'));
registerDynImportEntry('js/classifyAdminUtil', () => import('js/classifyAdminUtil'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "lifecycleHooks": {
        "onMount": "initializeKeylovSWA",
        "onUpdate": [
            {
                "action": "reloadKeylovSWA",
                "observers": [
                    "props.subPanelContext.context.searchState.propertiesSWA"
                ]
            },
            {
                "action": "clearPrevTreeInput",
                "observers": [
                    "props.subPanelContext.selection"
                ]
            }
        ]
    },
    "props": {
        "sub-panel-context": {
            "type": "object"
        }
    },
    "data": {
        "headerProperties": [
            {
                "propertyName": "ID"
            },
            {
                "propertyName": "IRDI"
            },
            {
                "propertyName": "owning_user"
            },
            {
                "propertyName": "dateModified"
            },
            {
                "propertyName": "Status"
            },
            {
                "propertyName": "object_type"
            }
        ],
        "clsadminPropCommands": "clsadmin_propCommands"
    },
    "actions": {
        "reloadKeylovSWA": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeKeylovSWA"
                },
                {
                    "action": "keylovLoad",
                    "condition": "!conditions.supported"
                },
                {
                    "action": "keylovLoad2",
                    "condition": "conditions.ready && conditions.supported"
                }
            ]
        },
        "initializeKeylovSWA": {
            "actionType": "JSFunction",
            "method": "initializeSWA",
            "inputData": {
                "subPanelContext": "{{subPanelContext.context.searchState}}",
                "subLocationName": "KeyLOV"
            },
            "outputData": {
                "currentSecData": "currentSecData",
                "lovTypeItems": "lovTypeItems",
                "dataType": "dataType",
                "multiSiteData": "multiSiteData",
                "treeLoadResult": {}
            },
            "deps": "js/classifyAdminService"
        },
        "keylovLoad": {
            "actionType": "dataProvider",
            "method": "entryDataProvider"
        },
        "keylovLoad2": {
            "actionType": "dataProvider",
            "method": "keyLovItemsProvider"
        },
        "loadData": {
            "actionType": "JSFunction",
            "method": "loadDataForTreeKeyLOV",
            "inputData": {
                "treeLoadInput": "request.treeLoadInput",
                "data": "{{data}}",
                "dataProvider": "{{data.dataProviders.entryDataProvider}}"
            },
            "outputData": {
                "treeLoadResult": ""
            },
            "deps": "js/classifyAdminTreeTableService"
        },
        "loadColumnsForKeyLOV": {
            "actionType": "JSFunctionAsync",
            "method": "loadColumnsForKeyLOV",
            "inputData": {
                "dataProvider": "{{data.dataProviders.entryDataProvider}}",
                "type": "{{data.dataType}}"
            },
            "deps": "js/classifyAdminTreeTableService"
        },
        "loadColumnsForKeyLOVTable": {
            "actionType": "JSFunctionAsync",
            "method": "loadColumnsForKeyLOV",
            "inputData": {
                "dataProvider": "{{data.dataProviders.keyLovItemsProvider}}",
                "type": "{{data.dataType}}",
                "columnProvider": "{{columnProviders.keysColumnProvider}}"
            },
            "deps": "js/classifyAdminTreeTableService"
        },
        "loadTableKeys": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "loadTableAttributes"
                },
                {
                    "action": "loadDataForTreeKeyLOV"
                }
            ]
        },
        "loadTableKeysInitial": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "loadTableAttributes"
                },
                {
                    "action": "loadColumnsForKeyLOVTable"
                },
                {
                    "action": "loadDataForTreeKeyLOVInitial"
                }
            ]
        },
        "loadTableAttributes": {
            "actionType": "JSFunctionAsync",
            "method": "loadTableAttributes",
            "inputData": {
                "data": "{{data}}",
                "type": "Attributes",
                "classSystem": "{{ctx.clsAdmin.classSystem}}",
                "columnProvider": "{{columnProviders.keysColumnProvider}}",
                "descriptorColumns": "{{props.subPanelContext.context.searchState.propertiesSWA.attrColumns}}",
                "objects": "{{data.lovTypeItems}}"
            },
            "outputData": {
                "processedKeys": "objects",
                "pTotalFound": "totalFound",
                "columnProviders.keysColumnProvider": "columnProvider"
            },
            "deps": "js/classifyAdminService"
        },
        "loadDataForTreeKeyLOV": {
            "actionType": "JSFunction",
            "method": "loadDataForTreeKeyLOV",
            "inputData": {
                "treeLoadInput": "request.treeLoadInput",
                "data": "{{data}}",
                "dataProvider": "{{data.dataProviders.keyLovItemsProvider}}",
                "columnConfigsSet": "true",
                "columnProvider": "{{columnProviders.keysColumnProvider}}"
            },
            "outputData": {
                "treeLoadResult": ""
            },
            "deps": "js/classifyAdminTreeTableService"
        },
        "loadDataForTreeKeyLOVInitial": {
            "actionType": "JSFunction",
            "method": "loadDataForTreeKeyLOV",
            "inputData": {
                "treeLoadInput": null,
                "data": "{{data}}",
                "dataProvider": "{{data.dataProviders.keyLovItemsProvider}}",
                "columnConfigsSet": "true",
                "columnProvider": "{{columnProviders.keysColumnProvider}}"
            },
            "outputData": {
                "treeLoadResult": ""
            },
            "deps": "js/classifyAdminTreeTableService"
        },
        "clearPrevTreeInput": {
            "actionType": "JSFunction",
            "method": "destroyKeylovTreeInfo",
            "inputData": {
                "dataProvider": "{{data.dataProviders.keyLovItemsProvider}}"
            },
            "deps": "js/classifyAdminUtil"
        }
    },
    "dataProviders": {
        "entryDataProvider": {
            "action": "loadData",
            "initializeAction": "loadData",
            "treeLoadResult": "{{data.treeLoadResult}}",
            "inputData": {
                "accessMode": "tree",
                "topNodeUid": "top",
                "selectionData": "{{subPanelContext.selectionData}}"
            }
        },
        "keyLovItemsProvider": {
            "action": "loadTableKeys",
            "initializeAction": "loadTableKeysInitial",
            "treeLoadResult": "{{data.treeLoadResult}}",
            "inputData": {
                "accessMode": "tree",
                "topNodeUid": "top",
                "selectionData": "{{subPanelContext.selectionData}}"
            }
        }
    },
    "columnProviders": {
        "structureColumnProvider": {
            "loadColumnAction": "loadColumnsForKeyLOV",
            "columns": "{{data.columns}}",
            "frozenColumnIndex": -1,
            "sortCriteria": []
        },
        "keysColumnProvider": {
            "columns": [],
            "frozenColumnIndex": -1
        }
    },
    "grids": {
        "entryGridForKeyLOV": {
            "dataProvider": "entryDataProvider",
            "columnProvider": "structureColumnProvider",
            "addIconColumn": false,
            "gridOptions": {
                "enableGridMenu": false,
                "enablePinning": false,
                "enableSorting": false,
                "useStaticFirstCol": true
            }
        },
        "keyLovGrid": {
            "dataProvider": "keyLovItemsProvider",
            "columnProvider": "keysColumnProvider",
            "addIconColumn": false,
            "gridOptions": {
                "enableColumnMoving": true,
                "enableGridMenu": true,
                "enablePinning": true,
                "enableSorting": false,
                "useStaticFirstCol": true
            }
        }
    },
    "i18n": {
        "dataType": [
            "ClassificationAdminMessages"
        ],
        "entries": [
            "ClassificationAdminMessages"
        ],
        "metric": [
            "ClassificationAdminMessages"
        ],
        "multiSite": [
            "ClassificationAdminMessages"
        ],
        "nonMetric": [
            "ClassificationAdminMessages"
        ],
        "propertiesTitle": [
            "ClassificationAdminMessages"
        ]
    },
    "conditions": {
        "ready": {
            "expression": "data.treeLoadResult && props.subPanelContext.context.searchState.propertiesSWA.currentSecData && props.subPanelContext.context.searchState.propertiesSWA.currentSecData[0] === data.currentSecData[0]"
        },
        "supported": {
            "expression": "(ctx.tcSessionData.tcMajorVersion > 14 || (ctx.tcSessionData.tcMajorVersion === 14 && ctx.tcSessionData.tcMinorVersion >= 3))"
        },
        "isSummaryHeaderHidden": {
            "expression": "!(ctx.state && ctx.state.urlAttributes && ctx.state.urlAttributes.flexibleLayouts !== undefined)"
        }
    },
    "_viewModelId": "Awp0ClsKeyLOVSecWorkArea",
    "_uniqueViewModelId": "Awp0ClsKeyLOVSecWorkArea",
    "ctx": {
        "clsAdmin": {
            "type": "object"
        },
        "tcSessionData": {
            "type": "object"
        },
        "state": {
            "type": "object"
        }
    }
};

    /*
    <aw-scrollpanel>
    <aw-toolbar second-anchor="aw_summaryHeader" exist-when="conditions.isSummaryHeaderHidden" context="props.subPanelContext.context"></aw-toolbar>
    <div class="aw-clsadmin-keylov w-12">
        <aw-command-panel-section caption="{{i18n.propertiesTitle}}" visible-when="data.currentSecData.length > 0"
            collapsed="false" anchor="data.clsadminPropCommands" context="props.subPanelContext.context"
            class="aw-clsadmin-keylov-properties">
                <div class="aw-clspadmin-prop-section" aw-repeat="item : data.currentSecData" visible-when="item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true">
                    <aw-widget prop="item"  ></aw-widget>
                </div>
        </aw-command-panel-section>
    <aw-panel-section caption="{{i18n.entries}}" exist-when="data.lovTypeItems.length > 0 && conditions.ready && conditions.supported" collapsed="false">
        <aw-splm-table gridid="keyLovGrid" use-tree="true" show-decorators="true"></aw-splm-table>
    </aw-panel-section>
    <aw-panel-section caption="{{i18n.entries}}" visible-when="data.lovTypeItems.length > 0 && !conditions.supported" collapsed="false">
            <aw-splm-table gridid="entryGridForKeyLOV" use-tree="true" show-decorators="true"></aw-splm-table>
        </aw-panel-section>
        <aw-panel-section class="w-12" caption="{{i18n.multiSite}}" visible-when="data.multiSiteData.length > 0" collapsed="false">
            <div class="aw-clspadmin-prop-section" aw-repeat="item : data.multiSiteData" visible-when="item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true">
                <aw-widget prop="item"  ></aw-widget>
            </div>
        </aw-panel-section>
    </div>
</aw-scrollpanel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwScrollpanel>
        <AwToolbarExistWhen secondAnchor="aw_summaryHeader" context={AwParseService.instance( "props.subPanelContext.context" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "conditions.isSummaryHeaderHidden" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwToolbarExistWhen>
        <div className="aw-clsadmin-keylov w-12">
            <AwCommandPanelSectionVisibleWhen caption={AwParseService.instance( "i18n.propertiesTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" anchor={AwParseService.instance( "data.clsadminPropCommands" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} context={AwParseService.instance( "props.subPanelContext.context" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-clsadmin-keylov-properties" visibleWhen={AwParseService.instance( "data.currentSecData.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                { Object.entries( AwParseService.instance( "data.currentSecData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                    <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                        <AwWidget {...getField("item", fields, $index, 'data.currentSecData', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                        </AwWidget>
                    </DivVisibleWhen>
                ) ) }
            </AwCommandPanelSectionVisibleWhen>
            <AwPanelSectionExistWhen caption={AwParseService.instance( "i18n.entries" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" existWhen={AwParseService.instance( "data.lovTypeItems.length > 0 && conditions.ready && conditions.supported" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwSplmTable {...grids.keyLovGrid} useTree={AwParseService.instance( "true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} showDecorators={AwParseService.instance( "true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwSplmTable>
            </AwPanelSectionExistWhen>
            <AwPanelSectionVisibleWhen caption={AwParseService.instance( "i18n.entries" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "data.lovTypeItems.length > 0 && !conditions.supported" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                <AwSplmTable {...grids.entryGridForKeyLOV} useTree={AwParseService.instance( "true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} showDecorators={AwParseService.instance( "true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </AwSplmTable>
            </AwPanelSectionVisibleWhen>
            <AwPanelSectionVisibleWhen className="w-12" caption={AwParseService.instance( "i18n.multiSite" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} collapsed="false" visibleWhen={AwParseService.instance( "data.multiSiteData.length > 0" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                { Object.entries( AwParseService.instance( "data.multiSiteData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } ) || [] ).map( ( [ $index, item ] ) => (
                    <DivVisibleWhen className="aw-clspadmin-prop-section" visibleWhen={AwParseService.instance( "item.value !== '' || props.subPanelContext.context.searchState.showAllProp === true" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index } )} key={$index}>
                        <AwWidget {...getField("item", fields, $index, 'data.multiSiteData', {props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports, item, $index}  )}>
                        </AwWidget>
                    </DivVisibleWhen>
                ) ) }
            </AwPanelSectionVisibleWhen>
        </div>
    </AwScrollpanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;