// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelHeader from 'viewmodel/AwPanelHeaderViewModel';
import AwGuidanceMessage from 'viewmodel/AwGuidanceMessageViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import Csi1ChangeRelatedObjects from 'viewmodel/Csi1ChangeRelatedObjectsViewModel';
import AwSplitter from 'viewmodel/AwSplitterViewModel';
import Csi1ScheduleTask from 'viewmodel/Csi1ScheduleTaskViewModel';
import Csi1ScheduleTaskRelatedObjects from 'viewmodel/Csi1ScheduleTaskRelatedObjectsViewModel';
import AwButton from 'viewmodel/AwButtonViewModel';
    import { ExistWhen,EnableWhen } from 'js/hocCollection';
const Csi1ScheduleTaskRelatedObjectsExistWhen = ExistWhen(Csi1ScheduleTaskRelatedObjects);
const AwButtonEnableWhen = EnableWhen(AwButton);
Csi1ScheduleTaskRelatedObjectsExistWhen.displayName = 'Csi1ScheduleTaskRelatedObjectsExistWhen';
AwButtonEnableWhen.displayName = 'AwButtonEnableWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Csi1PropagateOrRollupService', () => import('js/Csi1PropagateOrRollupService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "actions": {
        "countRelations": {
            "actionType": "JSFunction",
            "method": "countRelations",
            "inputData": {
                "eventData": "{{data.eventData}}",
                "created": "{{data.created}}",
                "modelObjects": "{{data.modelObjects}}",
                "i18nProblem": "{{i18n.problemContent}}",
                "i18nImpacted": "{{i18n.impactedContent}}",
                "i18nSolution": "{{i18n.solutionContent}}",
                "i18nReference": "{{i18n.referenceContent}}"
            },
            "outputData": {
                "contentStrings": ""
            },
            "actionMessages": {
                "success": [
                    {
                        "condition": "data.contentStrings.count===1",
                        "message": "propagateOperationPassedSingleContent"
                    },
                    {
                        "condition": "data.contentStrings.count>1",
                        "message": "propagateOperationPassedMultipleContent"
                    }
                ]
            },
            "deps": "js/Csi1PropagateOrRollupService"
        },
        "getSelectedChangeAndSchedule": {
            "actionType": "JSFunction",
            "method": "getSelectedChangeAndSchedule",
            "inputData": {
                "ctx": "{{ctx}}"
            },
            "outputData": {
                "selectedChange": "selectedChange",
                "selectedSchedule": "selectedSchedule"
            },
            "deps": "js/Csi1PropagateOrRollupService"
        },
        "propagate": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-CmSmInterface-2020-01-RelationManagement",
            "method": "propagateOrRollupRelations",
            "inputData": {
                "relationships": "{{function:propagate}}",
                "rollup": false
            },
            "outputData": {
                "created": "created",
                "modelObjects": "modelObjects",
                "isRollupOp": ""
            },
            "events": {
                "success": [
                    {
                        "name": "csi1propagateRelations.propagated"
                    },
                    {
                        "name": "scheduleTaskRelatedObjectsTable.plTable.reload"
                    },
                    {
                        "name": "changeRelatedObjectsDataProvider.selectAction",
                        "eventData": {
                            "deselect": "{{data.relatedObjectsTable.selected}}"
                        }
                    },
                    {
                        "name": "scheduleTaskDataProvider.selectAction",
                        "condition": "data.relatedSchTasksTable.selected.length > 1",
                        "eventData": {
                            "deselect": "{{data.relatedSchTasksTable.selected}}"
                        }
                    }
                ]
            },
            "deps": "js/Csi1PropagateOrRollupService"
        },
        "closePropagateView": {
            "actionType": "Navigate",
            "navigateTo": "showChanges",
            "navigationParams": {}
        }
    },
    "data": {
        "relatedObjectsTable": {
            "meta": {}
        },
        "relatedSchTasksTable": {
            "meta": {}
        },
        "subPanelContext": {
            "type": "object"
        }
    },
    "functions": {
        "propagate": {
            "functionName": "propagate",
            "parameters": [
                "{{data}}"
            ]
        }
    },
    "conditions": {
        "scheduleTaskReferences": {
            "expression": "data.relatedSchTasksTable.selected[0].props.CMReferences.dbValues.length > 0"
        },
        "scheduleTaskHasImpacted": {
            "expression": "data.relatedSchTasksTable.selected[0].props.CMHasImpactedItem.dbValues.length > 0"
        },
        "scheduleTaskHasProblem": {
            "expression": "data.relatedSchTasksTable.selected[0].props.CMHasProblemItem.dbValues.length > 0"
        },
        "scheduleTaskHasSolution": {
            "expression": "data.relatedSchTasksTable.selected[0].props.CMHasSolutionItem.dbValues.length > 0"
        },
        "isScheuleTaskSelected": {
            "expression": "data.relatedSchTasksTable.selected.length ===1 && (conditions.scheduleTaskHasImpacted || conditions.scheduleTaskHasProblem || conditions.scheduleTaskHasSolution || conditions.scheduleTaskReferences)"
        },
        "propagateButtonActiveWhen": {
            "expression": "data.relatedObjectsTable.selected.length > 0 && data.relatedSchTasksTable.selected.length > 0"
        }
    },
    "lifecycleHooks": {
        "onMount": "getSelectedChangeAndSchedule"
    },
    "i18n": {
        "Csi1PropagateChangeContentDesc": [
            "ChangeContentMessages"
        ],
        "propagateRelationsTitle": [
            "ChangeContentMessages"
        ],
        "problemContent": [
            "ChangeContentMessages"
        ],
        "impactedContent": [
            "ChangeContentMessages"
        ],
        "solutionContent": [
            "ChangeContentMessages"
        ],
        "referenceContent": [
            "ChangeContentMessages"
        ],
        "noContentPropagateMessage": [
            "ChangeContentMessages"
        ],
        "propagateOperationPassedSingleContent": [
            "ChangeContentMessages"
        ],
        "propagateOperationPassedMultipleContent": [
            "ChangeContentMessages"
        ]
    },
    "messages": {
        "propagateOperationPassedSingleContent": {
            "messageType": "INFO",
            "messageText": "{{i18n.propagateOperationPassedSingleContent}}",
            "messageTextParams": [
                "{{data.contentStrings.one}}",
                "{{ctx.state.params.selectedChange}}",
                "{{ctx.state.params.selectedSchedule}}"
            ]
        },
        "propagateOperationPassedMultipleContent": {
            "messageType": "INFO",
            "messageText": "{{i18n.propagateOperationPassedMultipleContent}}",
            "messageTextParams": [
                "{{data.contentStrings.one}}",
                "{{data.contentStrings.two}}",
                "{{ctx.state.params.selectedChange}}",
                "{{ctx.state.params.selectedSchedule}}"
            ]
        },
        "Csi1PropagateChangeContentDesc": {
            "messageType": "INFO",
            "messageText": "{{i18n.Csi1PropagateChangeContentDesc}}"
        }
    },
    "onEvent": [
        {
            "eventId": "csi1propagateRelations.propagated",
            "action": "countRelations",
            "cacheEventData": true
        }
    ],
    "_viewModelId": "Csi1PropagateRelationsParent",
    "_uniqueViewModelId": "Csi1PropagateRelationsParent"
};

    /*
    <aw-panel>
    <aw-panel-header>
        <h3>{{i18n.propagateRelationsTitle}} | {{data.selectedChange}} - {{data.selectedSchedule}}</h3>
    </aw-panel-header>
    <aw-guidance-message class="afx-medium-background" message="Csi1PropagateChangeContentDesc" closable="false"
        banner-style="true" show-icon="true" show-type="true">
    </aw-guidance-message>
    <aw-panel-body class="sw-row afx-medium-background demo-h-50f">
        <div class="sw-column aw-csi-table-margin aw-csi-objectsRelatedToChange w-6">
            <csi1-change-related-objects sub-panel-context="{data,selectionData:data.relatedObjectsTable}">
            </csi1-change-related-objects>
        </div>
        <aw-splitter direction="VERTICAL"></aw-splitter>
        <div class="sw-column aw-csi-table-margin w-6">
            <div class="sw-row demo-h-26f">
                <csi1-schedule-task sub-panel-context="{data,selectionData:data.relatedSchTasksTable}">
                </csi1-schedule-task>
            </div>
            <div class="sw-row demo-h-24f aw-csi-schedule-task-related-objects">
                <csi1-schedule-task-related-objects task-object="data.relatedSchTasksTable"
                    exist-when="conditions.isScheuleTaskSelected">
                </csi1-schedule-task-related-objects>
            </div>
            <div class="sw-row aw-csi-button-padding justify-right align-bottom">
                <div class="sw-button-group">
                    <aw-button class="small" button-type='base' size='auto' action="closePropagateView">Close
                    </aw-button>
                    <aw-button class="small" button-type='base' size='auto' action="propagate"
                        enable-when="conditions.propagateButtonActiveWhen">Propagate</aw-button>
                </div>
            </div>
        </div>
    </aw-panel-body>
</aw-panel>
    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanel  revealAction={actions.reveal}>
        <AwPanelHeader>
            <h3>
                {AwParseService.instance( "i18n.propagateRelationsTitle" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} | {AwParseService.instance( "data.selectedChange" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} - {AwParseService.instance( "data.selectedSchedule" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
            </h3>
        </AwPanelHeader>
        <AwGuidanceMessage className="afx-medium-background" message={messages.Csi1PropagateChangeContentDesc} closable="false" bannerStyle="true" showIcon="true" showType="true">
        </AwGuidanceMessage>
        <AwPanelBody className="sw-row afx-medium-background demo-h-50f">
            <div className="sw-column aw-csi-table-margin aw-csi-objectsRelatedToChange w-6">
                <Csi1ChangeRelatedObjects subPanelContext={AwParseService.instance( "{data,selectionData:fields.relatedObjectsTable}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </Csi1ChangeRelatedObjects>
            </div>
            <AwSplitter direction="VERTICAL">
            </AwSplitter>
            <div className="sw-column aw-csi-table-margin w-6">
                <div className="sw-row demo-h-26f">
                    <Csi1ScheduleTask subPanelContext={AwParseService.instance( "{data,selectionData:fields.relatedSchTasksTable}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </Csi1ScheduleTask>
                </div>
                <div className="sw-row demo-h-24f aw-csi-schedule-task-related-objects">
                    <Csi1ScheduleTaskRelatedObjectsExistWhen taskObject={AwParseService.instance( "fields.relatedSchTasksTable" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "conditions.isScheuleTaskSelected" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    </Csi1ScheduleTaskRelatedObjectsExistWhen>
                </div>
                <div className="sw-row aw-csi-button-padding justify-right align-bottom">
                    <div className="sw-button-group">
                        <AwButton className="small" buttonType="base" size="auto" action={actions.closePropagateView}>
                            Close
                        </AwButton>
                        <AwButtonEnableWhen className="small" buttonType="base" size="auto" action={actions.propagate} enableWhen={AwParseService.instance( "conditions.propagateButtonActiveWhen" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            Propagate
                        </AwButtonEnableWhen>
                    </div>
                </div>
            </div>
        </AwPanelBody>
    </AwPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;