// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwFlexColumn from 'viewmodel/AwFlexColumnViewModel';
import AwProgressIndicator from 'viewmodel/AwProgressIndicatorViewModel';
import UiView from 'viewmodel/UiViewViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/hosting/hostConfigService', () => import('js/hosting/hostConfigService'));
registerDynImportEntry('js/appCtxService', () => import('js/appCtxService'));
registerDynImportEntry('js/defaultLocationService', () => import('js/defaultLocationService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-flex-column.directive",
        "js/aw-progress-indicator.directive"
    ],
    "data": {},
    "actions": {
        "initializeData": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "setLogoDisabled"
                },
                {
                    "action": "initializeHostingConfig"
                },
                {
                    "action": "getLocationName"
                },
                {
                    "action": "registerLocationName"
                }
            ]
        },
        "initializeHostingConfig": {
            "actionType": "JSFunction",
            "method": "initialize",
            "deps": "js/hosting/hostConfigService"
        },
        "setLogoDisabled": {
            "actionType": "JSFunction",
            "method": "updatePartialCtx",
            "deps": "js/appCtxService",
            "inputData": {
                "path": "aw_hosting_config.ShowSiemensLogo",
                "value": false
            }
        },
        "getLocationName": {
            "actionType": "JSFunction",
            "method": "normalizeStateName",
            "deps": "js/defaultLocationService",
            "outputData": {
                "normalizeStateName": ""
            }
        },
        "registerLocationName": {
            "actionType": "JSFunction",
            "method": "updateCtx",
            "deps": "js/appCtxService",
            "inputData": {
                "name": "locationContext",
                "value": {
                    "ActiveWorkspace:Location": "{{data.normalizeStateName}}",
                    "ActiveWorkspace:SubLocation": "{{ctx.locationContext['ActiveWorkspace:SubLocation']}}"
                }
            }
        }
    },
    "messages": {},
    "dataProviders": {},
    "onEvent": [],
    "conditions": {},
    "functions": {},
    "i18n": {},
    "lifecycleHooks": {
        "onMount": "initializeData"
    },
    "_viewModelId": "TcmaHostedRoot",
    "_uniqueViewModelId": "TcmaHostedRoot",
    "ctx": {
        "locationContext": {
            "type": "object"
        }
    }
};

    /*
    <aw-flex-column class="aw-tcmaHostedRoot-layout">
    <aw-progress-indicator></aw-progress-indicator>
    <ui-view class="aw-layout-flexRow" ></ui-view>
</aw-flex-column >

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwFlexColumn className="aw-tcmaHostedRoot-layout">
        <AwProgressIndicator>
        </AwProgressIndicator>
        <UiView className="aw-layout-flexRow">
        </UiView>
    </AwFlexColumn>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;