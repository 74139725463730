// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import EpConfigurationChipArea from 'viewmodel/EpConfigurationChipAreaViewModel';
import AwSublocation from 'viewmodel/AwSublocationViewModel';
import MfeLoadingMessage from 'viewmodel/MfeLoadingMessageViewModel';
import MfeContentContainer from 'viewmodel/MfeContentContainerViewModel';
import MfeContentPanel from 'viewmodel/MfeContentPanelViewModel';
import EpGraphicsArea from 'viewmodel/EpGraphicsAreaViewModel';
import AwLayoutSlot from 'viewmodel/AwLayoutSlotViewModel';
    import { ExistWhen } from 'js/hocCollection';
const EpConfigurationChipAreaExistWhen = ExistWhen(EpConfigurationChipArea);
const MfeLoadingMessageExistWhen = ExistWhen(MfeLoadingMessage);
const MfeContentContainerExistWhen = ExistWhen(MfeContentContainer);
const AwLayoutSlotExistWhen = ExistWhen(AwLayoutSlot);
EpConfigurationChipAreaExistWhen.displayName = 'EpConfigurationChipAreaExistWhen';
MfeLoadingMessageExistWhen.displayName = 'MfeLoadingMessageExistWhen';
MfeContentContainerExistWhen.displayName = 'MfeContentContainerExistWhen';
AwLayoutSlotExistWhen.displayName = 'AwLayoutSlotExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwBaseSublocationService', () => import('js/AwBaseSublocationService'));
registerDynImportEntry('js/epInitializationService', () => import('js/epInitializationService'));
registerDynImportEntry('js/epTabsService', () => import('js/epTabsService'));
registerDynImportEntry('js/mfeViewModelUtils', () => import('js/mfeViewModelUtils'));
registerDynImportEntry('js/appCtxService', () => import('js/appCtxService'));
registerDynImportEntry('js/epBackgroundPartService', () => import('js/epBackgroundPartService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "ports": {
        "EpBackgroundPartsSubPage_updateTabContentCountPort": {
            "onChangeAction": "updateTabContentCountData",
            "syncObject": {}
        }
    },
    "data": {
        "provider": {
            "label": "{{i18n.backgroundPartsTaskTitle}}",
            "priority": 3,
            "name": "backgroundParts",
            "clientScopeURI": "backgroundParts",
            "nameToken": "backgroundParts"
        },
        "awSubLocationPref": {
            "hideRightWall": true
        },
        "occContext": {
            "initialValues": {
                "selectedModelObjects": [],
                "viewKey": "",
                "productContextInfo": null,
                "elementToPCIMapCount": null,
                "supportedFeatures": null,
                "skipReloadOnConfigParamChange": true
            },
            "meta": {}
        },
        "backgroundPartsEbomTreeContext": {
            "tabs": [
                {
                    "name": "{{i18n.backgroundPartsEbomTreeTitle}}",
                    "tabKey": "EpBackgroundPartsEbomTree"
                }
            ]
        },
        "backgroundPartsTableContext": {
            "tabs": [
                {
                    "name": "{{i18n.backgroundPartsTableTitle}}",
                    "tabKey": "EpBackgroundPartsTable"
                }
            ]
        },
        "configurationChipsData": {
            "initialValues": {},
            "meta": {}
        }
    },
    "actions": {
        "handleCompleteEvent": {
            "actionType": "JSFunction",
            "method": "handleCompleteEvent",
            "inputData": {
                "provider": "{{parameters.eventData}}"
            },
            "deps": "js/AwBaseSublocationService"
        },
        "initializeEPPage": {
            "actionType": "JSFunctionAsync",
            "method": "initializeEPPage",
            "inputData": {
                "provider": "{{data.provider}}"
            },
            "outputData": {
                "stateContext": "stateContext",
                "propertyPolicyId": "propertyPolicyId"
            },
            "deps": "js/epInitializationService"
        },
        "updateTabContentCountData": {
            "actionType": "JSFunction",
            "method": "setLabelOnTab",
            "inputData": {
                "contentPanelData": "{{data.backgroundPartsTableContext}}",
                "tabKey": "{{ports.EpBackgroundPartsSubPage_updateTabContentCountPort.syncObject.tabKey}}",
                "name": "{{ports.EpBackgroundPartsSubPage_updateTabContentCountPort.syncObject.tabName}}"
            },
            "outputData": {
                "backgroundPartsTableContext": ""
            },
            "deps": "js/epTabsService"
        },
        "initBatchAction": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "initializeEPPage"
                },
                {
                    "action": "updatePartialCtxForBackgroundPartsPage"
                },
                {
                    "action": "loadAssociatedAssembly"
                },
                {
                    "action": "initialiseConfigurationChips"
                },
                {
                    "action": "epPageInitializeComplete"
                }
            ]
        },
        "epPageInitializeComplete": {
            "actionType": "JSFunction",
            "method": "getValueInViewModel",
            "inputData": {
                "initialize": true
            },
            "outputData": {
                "pageIntializeComplete": ""
            },
            "deps": "js/mfeViewModelUtils"
        },
        "updatePartialCtxForBackgroundPartsPage": {
            "actionType": "JSFunction",
            "method": "updatePartialCtx",
            "inputData": {
                "name": "backgroundPartsPageCtx",
                "value": {
                    "isBackgroundPartsPageEnabled": true,
                    "showMBOMInViewer": true
                }
            },
            "deps": "js/appCtxService"
        },
        "loadAssociatedAssembly": {
            "actionType": "JSFunctionAsync",
            "method": "loadAssociatedAssembly",
            "inputData": {
                "objectUid": "{{ctx.state.params.uid}}"
            },
            "deps": "js/epBackgroundPartService"
        },
        "initialiseConfigurationChips": {
            "actionType": "JSFunction",
            "method": "mergeValueInAtomicData",
            "inputData": {
                "targetContext": "{{fields.configurationChipsData}}",
                "updatedValues": {
                    "ebomStructure": "{{ctx.epPageContext.ebomStructure}}",
                    "ebomPCI": "{{ctx.epTaskPageContext.ebomPCI}}",
                    "ebomStructureConfigFlags": "{{ctx.epTaskPageContext.ebomStructureConfigFlags}}",
                    "mbomStructure": "{{ctx.epPageContext.mbomStructure}}",
                    "mbomPCI": "{{ctx.epTaskPageContext.mbomPCI}}",
                    "mbomStructureConfigFlags": "{{ctx.epTaskPageContext.mbomStructureConfigFlags}}",
                    "processStructure": "{{ctx.epPageContext.processStructure}}",
                    "processPCI": "{{ctx.epTaskPageContext.processPCI}}",
                    "processStructureConfigFlags": "{{ctx.epTaskPageContext.processStructureConfigFlags}}",
                    "rootPlant": "{{ctx.epPageContext.rootPlant}}",
                    "plantPCI": "{{ctx.epTaskPageContext.plantPCI}}",
                    "rootPlantConfigFlags": "{{ctx.epTaskPageContext.rootPlantConfigFlags}}"
                }
            },
            "deps": "js/mfeViewModelUtils"
        }
    },
    "lifecycleHooks": {
        "onMount": "initBatchAction"
    },
    "onEvent": [
        {
            "eventId": "ep.pageContext.changed",
            "action": "initialiseConfigurationChips"
        },
        {
            "eventId": "complete",
            "action": "handleCompleteEvent",
            "inputArgs": {
                "eventData": "{{eventData}}"
            }
        }
    ],
    "i18n": {
        "backgroundPartsTaskTitle": [
            "BackgroundPartsMessages"
        ],
        "backgroundPartsEbomTreeTitle": [
            "BackgroundPartsMessages"
        ],
        "backgroundPartsTableTitle": [
            "BackgroundPartsMessages"
        ]
    },
    "_viewModelId": "EpBackgroundPartsSubPage",
    "_uniqueViewModelId": "EpBackgroundPartsSubPage",
    "ctx": {
        "state": {
            "type": "object"
        },
        "epPageContext": {
            "type": "object"
        },
        "epTaskPageContext": {
            "type": "object"
        },
        "ep": {
            "type": "object"
        }
    }
};

    /*
    <ep-configuration-chip-area exist-when="ctx.epTaskPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" configuration-chips-data = "data.configurationChipsData"></ep-configuration-chip-area>
<aw-sublocation sub-location-preference="data.awSubLocationPref" sub-panel-context="{provider : data.provider, occContext:data.occContext}">
    <mfe-loading-message exist-when="!ctx.epPageContext.loadedObject.uid || !ctx.ep.scopeObject.uid"></mfe-loading-message>
    <mfe-content-container container-direction="HORIZONTAL" container-name="BackgroundPartsPage" class="h-12" exist-when="ctx.epPageContext.loadedObject.uid && ctx.ep.scopeObject.uid">
        <mfe-content-container container-direction="VERTICAL" container-name="BackgroundPartsPageLeftArea" class="w-3">
            <mfe-content-panel sub-panel-context="data.backgroundPartsEbomTreeContext"></mfe-content-panel>
        </mfe-content-container>

        <mfe-content-container container-direction="VERTICAL" container-name="BackgroundPartsPageMiddleArea" class="flex-auto">
            <ep-graphics-area></ep-graphics-area>
        </mfe-content-container>

        <mfe-content-container container-direction="VERTICAL" container-name="BackgroundPartsPageRightArea" class="w-3">
            <mfe-content-panel sub-panel-context="data.backgroundPartsTableContext"></mfe-content-panel>
        </mfe-content-container>
    </mfe-content-container>
    <aw-layout-slot exist-when="data.pageIntializeComplete" name="ep_singleVisGraphicsViewer"></aw-layout-slot>
</aw-sublocation>


    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <EpConfigurationChipAreaExistWhen configurationChipsData={AwParseService.instance( "fields.configurationChipsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "ctx.epTaskPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </EpConfigurationChipAreaExistWhen>
    <AwSublocation subLocationPreference={AwParseService.instance( "data.awSubLocationPref" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{provider : data.provider, occContext:fields.occContext}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        <MfeLoadingMessageExistWhen existWhen={AwParseService.instance( "!ctx.epPageContext.loadedObject.uid || !ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </MfeLoadingMessageExistWhen>
        <MfeContentContainerExistWhen containerDirection="HORIZONTAL" containerName="BackgroundPartsPage" className="h-12" existWhen={AwParseService.instance( "ctx.epPageContext.loadedObject.uid && ctx.ep.scopeObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <MfeContentContainer containerDirection="VERTICAL" containerName="BackgroundPartsPageLeftArea" className="w-3">
                <MfeContentPanel subPanelContext={AwParseService.instance( "data.backgroundPartsEbomTreeContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MfeContentPanel>
            </MfeContentContainer>
            <MfeContentContainer containerDirection="VERTICAL" containerName="BackgroundPartsPageMiddleArea" className="flex-auto">
                <EpGraphicsArea>
                </EpGraphicsArea>
            </MfeContentContainer>
            <MfeContentContainer containerDirection="VERTICAL" containerName="BackgroundPartsPageRightArea" className="w-3">
                <MfeContentPanel subPanelContext={AwParseService.instance( "data.backgroundPartsTableContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </MfeContentPanel>
            </MfeContentContainer>
        </MfeContentContainerExistWhen>
        <AwLayoutSlotExistWhen name="ep_singleVisGraphicsViewer" existWhen={AwParseService.instance( "data.pageIntializeComplete" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwLayoutSlotExistWhen>
    </AwSublocation>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;