// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwBaseSublocation from 'viewmodel/AwBaseSublocationViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "_viewModelId": "AwPDFFileSublocation",
    "_uniqueViewModelId": "AwPDFFileSublocation",
    "ctx": {}
};

    /*
    <aw-base-sublocation>
</aw-base-sublocation>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwBaseSublocation>
    </AwBaseSublocation>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;