// @<COPYRIGHT>@
// ==================================================
// Copyright 2022.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/* global
define
*/

/**
 * @module js/mbmPartialCompareService
 */
import appCtxSvc from 'js/appCtxService';
import mbmTreeService from 'js/mbmTreeService';
import mbmUtils from 'js/mbmUtils';
import { constants as mbmConstants } from 'js/mbmConstants';
import _ from 'lodash';

/**
 *
 * @param {Object} selectedObjects Objects for partial compare
 *  @param {Boolean} focusMode focus mode
 */
function validatePartialCompareSelections( selectedObjects, focusMode ) {
    if ( !focusMode ) {
        return false;
    }

    let partialComapareValidationInfo = {
        isInvalidSelection: false
    };
    if( appCtxSvc.ctx.preferences.MBM_HideOutOfScopeIndications[0] === 'false' ) {
        _.forEach( selectedObjects, function( selectedObject ) {
            if ( !selectedObject.props.mbm0AssignmentDomain || selectedObject.props.mbm0AssignmentDomain && selectedObject.props.mbm0AssignmentDomain.uiValues.length === 0 ) {
                partialComapareValidationInfo.isInvalidSelection = true;
                return partialComapareValidationInfo;
            }
        } );
    }
    return partialComapareValidationInfo;
}


/**
 * @param {Object} mbmContext mbm Context
 * @param {Object} taskBarContext taskBar Context
 * @param {Array} contextKeys context keys
 */
function clearPartialCompare( mbmContext, taskBarContext, contextKeys ) {
    mbmTreeService.clearStatusFromNode( mbmContext, contextKeys );
    removePartialCompareInfo( taskBarContext );
}

/**
 *
 * @param {String} contextKey active context key
 * @param {Array} partialCompareObjects partial compare objects
 * @param {Object} taskBarContext taskBar Context
 */
function updatePartialCompareInfo( contextKey, partialCompareObjects, taskBarContext ) {
    let partialComapareInfo = mbmUtils.getContextValue( taskBarContext, mbmConstants.MBM_PARTIAL_COMPARE );
    partialComapareInfo.selectedNodes = partialCompareObjects;
    partialComapareInfo.contextKey = contextKey;
    partialComapareInfo.showPartialCompareBaner = true;
    const objToUpdate = mbmUtils.getValueToUpdate( partialComapareInfo, mbmConstants.MBM_PARTIAL_COMPARE );
    mbmUtils.updateValueToContext( taskBarContext, objToUpdate );
}

/**
 *
 * @param {Object} taskBarContext taskBar Context
 * @param {Boolean} flag partial compare flags
 */
function updatePartialCompareBannerFlags( taskBarContext, flag ) {
    let partialComapareInfo = mbmUtils.getContextValue( taskBarContext, mbmConstants.MBM_PARTIAL_COMPARE );
    partialComapareInfo[flag.flagKey] = !flag.flagValue;
    const objToUpdate = mbmUtils.getValueToUpdate( partialComapareInfo, mbmConstants.MBM_PARTIAL_COMPARE );
    mbmUtils.updateValueToContext( taskBarContext, objToUpdate );
}

/**
 *
 * @param {Object} taskBarContext taskBar Context
 */
function removePartialCompareInfo( taskBarContext ) {
    let partialComapareInfo = mbmUtils.getContextValue( taskBarContext, mbmConstants.MBM_PARTIAL_COMPARE );
    partialComapareInfo.selectedNodes = [];
    partialComapareInfo.contextKey = '';
    partialComapareInfo.showPartialCompareBaner = false;
    partialComapareInfo.refresh = false;
    partialComapareInfo.dismiss = false;
    const objToUpdate = mbmUtils.getValueToUpdate( partialComapareInfo, mbmConstants.MBM_PARTIAL_COMPARE );
    mbmUtils.updateValueToContext( taskBarContext, objToUpdate );
}

/**
 *@param {Object} messages partial compare messages
 *@param {Array} selectedNodes partial compare objects
 */
function setMessage( messages, selectedNodes ) {
    if ( selectedNodes.length > 1 ) {
        messages.mbmpartialCompareMessage = messages.multiMbmPartialCompareMsg;
    } else {
        messages.mbmpartialCompareMessage = messages.singleMbmPartialCompareMsg;
    }
    return messages.mbmpartialCompareMessage;
}


export default {
    validatePartialCompareSelections,
    updatePartialCompareInfo,
    removePartialCompareInfo,
    clearPartialCompare,
    setMessage,
    updatePartialCompareBannerFlags
};
