// @<COPYRIGHT>@
// ==================================================
// Copyright 2018.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * Service to interact between AW and a MQTT endpoint
 *
 *
 */

import $ from "jquery";
import _ from "lodash";
import soaSvc from "soa/kernel/soaService";
import cdm from "soa/kernel/clientDataModel";
import messagingSvc from "js/messagingService";
import prefSvc from "soa/preferenceService";
import appCtxService from "js/appCtxService";
import paho from "js/lib/pahomqtt31ver1.1.0";
import mqtt from "js/lib/MqttMessagingService";
import AwPromiseService from "js/awPromiseService";
import policySvc from 'soa/kernel/propertyPolicyService';

let exports = {};

/**
 *
 * Starts the local application gateway (via opening a URL in a new window)
 */
let startLocalAppGateway = function (b64encfile, fileName) {
  //let deferred = $.Deferred();
  let MQTTMessageClient = new mqtt.Client(paho);

  let errorEventFunction = function (msg) {
    // eslint-disable-next-line no-console
    console.log("MQTT Error: ", msg.toString());
  };

  let successConnectEventFunction = function () {};
  let successSubscribedEventFunction = function () {};

  //$.when(deferred).done(function () {
  let startMqttJoinSessionObject = {};
  let MQTTUrlPromise = getMQTTURL();
  startMqttJoinSessionObject.host = null;
  startMqttJoinSessionObject.port = "443";
  startMqttJoinSessionObject.userId = "devTest";
  startMqttJoinSessionObject.requestIdTopic =
    MQTTMessageClient.generateUiUiv4();
  startMqttJoinSessionObject.applicationId = "xml2catia";
  let fileObj = {
    base64StringFile: b64encfile,
    fileName: fileName,
    fileType: "",
  };
  startMqttJoinSessionObject.base64File = fileObj;

  let mqttEventListener = {};
  mqttEventListener.errorEventFunction = errorEventFunction;
  mqttEventListener.successConnectEventFunction = successConnectEventFunction;
  mqttEventListener.successSubscribedEventFunction =
    successSubscribedEventFunction;

  $.when(MQTTUrlPromise).done(function (returnURL) {
    startMqttJoinSessionObject.host = returnURL;
    MQTTMessageClient.startmqttJoinSession(
      startMqttJoinSessionObject,
      mqttEventListener
    );
    let startappLink =
      "startappcommunicator://openSession?user=" +
      startMqttJoinSessionObject.userId +
      "&requestId=" +
      startMqttJoinSessionObject.requestIdTopic +
      "&applicationId=" +
      startMqttJoinSessionObject.applicationId +
      "&host=" +
      startMqttJoinSessionObject.host +
      "&port=" +
      startMqttJoinSessionObject.port;
    window.open(startappLink);
  });

  //});
};

/**
 * returns a promise object that resolves to the value of the preference 'G4B_MQTT_connection_url'
 * when prefService has fetched the preference value
 */
let getMQTTURL = function () {
  let deferred = $.Deferred();
  prefSvc.getStringValue("G4B_MQTT_connection_url").then(function (prefValue) {
    if (prefValue == null || prefValue == undefined || prefValue.length == 0) {
      console.log(
        "Please check the preference: G4B_MQTT_connection_url. Using Fallback INT URL: lag-int.bmwgroup.net"
      );
      deferred.resolve("lag-int.bmwgroup.net");
    } else {
      deferred.resolve(prefValue);
    }
  });
  return deferred;
  //Prod environment: https://tc4jbi.bmwgroup.net/awc_gp_01 or https://tc4jb.bmwgroup.net/awc
  //if (window.location.host.indexOf("tc4jb.bmwgroup.net") !== -1) {
  //return "lag-bluediamond.cnap-00-mp-dev2.bmwgroup.net"
  // }
  //Integ environment: https://tc4jbi.bmwgroup.net/awc_gp_02 or https://tc4jbi.bmwgroup.net/awc
  // else if (window.location.host.indexOf("tc4jbi.bmwgroup.net") !== -1) {
  // return "lag-int-bluediamond.cnap-00-mp-dev2.bmwgroup.net"
  // }
  //Test environment: https://tc4jbt.bmwgroup.net/awc_test_xx
  // else if (window.location.pathname.indexOf("awc_test") !== -1 || window.location.host.indexOf("tc4jbt.bmwgroup.net") !== -1) {
  //return "lag-int-bluediamond.cnap-00-mp-dev2.bmwgroup.net"
  //   }
  //Siemens Dev environment
  // else {
  //return "lag-int-bluediamond.cnap-00-mp-dev2.bmwgroup.net"
};

export let startExportForPLMXMLToCatiaFromAttachement = function (
  notificationObject,
  i18n
) {
  //console.log("My button was pushed and this is what i got: " + notificationObject);

  let deferred = AwPromiseService.instance.defer();
  let downloadUrl = null;

  //Get the target object (==WorksetRevision) from the notification object
  let worksetRevision = null;
  let exportFileName = null;
  let exportImanFile = null;
  //let objectUID = null;
  let inputData = {
    uids: [notificationObject.props.awb0UnderlyingObject.dbValues[0]],
  };

  soaSvc
    .post("Core-2007-09-DataManagement", "loadObjects", inputData)
    .then(function () {
      worksetRevision = cdm.getObject(
        notificationObject.props.awb0UnderlyingObject.dbValues[0]
      );

      if (worksetRevision == null) {
        // eslint-disable-next-line no-console
        console.warn("No target object was found on the notification message.");
        return;
      }

      //get all attachments (IMAN_reference) from the workset revision
      inputData = {
        attributes: ["IMAN_reference"],
        objects: [worksetRevision],
      };
      //ensure the ImanType objects are loaded
      let policyId = policySvc.register({
        types: [{
            name: 'Dataset',
            properties: [{
                name: 'ref_list'
            }]
        }]
      });

      soaSvc
        .post("Core-2006-03-DataManagement", "getProperties", inputData)
        .then(function (response) {
          if (policyId) {
            policySvc.unregister(policyId);
          }
          //console.log(response);
          //loop through response objects and get the ZIP file with the exported PLMXML structure

          let flag = false;
          for (let prop in response.modelObjects) {
            if (
              response.modelObjects[prop].type == "Zip" &&
              response.modelObjects[prop].props.ref_list.uiValues[0].indexOf(
                "CATIA_export"
              ) != -1
            ) {
              exportImanFile = cdm.getObject(
                response.modelObjects[prop].props.ref_list.dbValues[0]
              );
              exportFileName =
                response.modelObjects[prop].props.ref_list.uiValues[0];
              flag = true;
              break;
            }
          }

          if (flag == true) {
            let infoMsg = i18n.G4BMQTTTransferInProgress;
            messagingSvc.showInfo(infoMsg);
          } else {
            let errorMsg = i18n.G4BMQTTTransferFailed;
            messagingSvc.showError(errorMsg);
            return;
          }

          //get read ticket
          inputData = {
            files: [exportImanFile],
          };
          soaSvc
            .post(
              "Core-2006-03-FileManagement",
              "getFileReadTickets",
              inputData
            )
            .then(function (response) {
              //console.log(response);
              downloadUrl =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname +
                "fms/fmsdownload/" +
                exportFileName +
                "?ticket=" +
                encodeURIComponent(response.tickets[1]);
              deferred.resolve("done constructing the ticket.");
            });

          //eventBus.publish('G4BCatiaInfo.event')
        });
    });
  $.when(deferred).done(function () {
    //console.log("currently at: when promise is done: constructing the ticket.")
    let b64encFile = null;

    let oReq = new XMLHttpRequest();
    oReq.open("GET", downloadUrl, true);
    oReq.responseType = "arraybuffer";

    oReq.onload = function () {
      let arrayBuffer = oReq.response;
      let byteArray = new Uint8Array(arrayBuffer);

      b64encFile = b64EncodeUnicode(byteArray);
      //console.log("Encoded (base64) date: ");
      //console.log(b64encFile);

      if (exportFileName == null) {
        exportFileName = "catia_export.plmxml2catia";
      }

      startLocalAppGateway(b64encFile, exportFileName);
    };
    oReq.send();
  });
};

export let startExportForPLMXMLToCatia = function (notificationObject) {
  //console.log("My button was pushed and this is what i got: " + notificationObject);

  let deferred = AwPromiseService.instance.defer();
  let downloadUrl = null;

  //Get the target object (==WorksetRevision) from the notification object
  let worksetRevision = null;
  let exportFileName = null;
  let exportImanFile = null;
  let inputData = {
    uids: [notificationObject.props.fnd0TargetObject.dbValues[0]],
  };
  soaSvc
    .post("Core-2007-09-DataManagement", "loadObjects", inputData)
    .then(function () {
      worksetRevision = cdm.getObject(
        notificationObject.props.fnd0TargetObject.dbValues[0]
      );

      if (worksetRevision == null) {
        // eslint-disable-next-line no-console
        console.warn("No target object was found on the notification message.");
        return;
      }

      //get all attachments (IMAN_reference) from the workset revision
      inputData = {
        attributes: ["IMAN_reference"],
        objects: [worksetRevision],
      };

      soaSvc
        .post("Core-2006-03-DataManagement", "getProperties", inputData)
        .then(function (response) {
          //console.log(response);
          //loop through response objects and get the ZIP file with the exported PLMXML structure
          for (let prop in response.modelObjects) {
            if (
              response.modelObjects[prop].type == "Zip" &&
              response.modelObjects[prop].props.ref_list.uiValues[0].indexOf(
                "CATIA_export"
              ) != -1
            ) {
              exportImanFile = cdm.getObject(
                response.modelObjects[prop].props.ref_list.dbValues[0]
              );
              exportFileName =
                response.modelObjects[prop].props.ref_list.uiValues[0];
              break;
            }
          }

          //get read ticket
          inputData = {
            files: [exportImanFile],
          };
          soaSvc
            .post(
              "Core-2006-03-FileManagement",
              "getFileReadTickets",
              inputData
            )
            .then(function (response) {
              //console.log(response);
              downloadUrl =
                window.location.protocol +
                "//" +
                window.location.host +
                window.location.pathname +
                "fms/fmsdownload/" +
                exportFileName +
                "?ticket=" +
                encodeURIComponent(response.tickets[1]);
              deferred.resolve("done constructing the ticket.");
            });
        });
    });
  $.when(deferred).done(function () {
    //console.log("currently at: when promise is done: constructing the ticket.")
    let b64encFile = null;

    let oReq = new XMLHttpRequest();
    oReq.open("GET", downloadUrl, true);
    oReq.responseType = "arraybuffer";

    oReq.onload = function () {
      let arrayBuffer = oReq.response;
      let byteArray = new Uint8Array(arrayBuffer);

      b64encFile = b64EncodeUnicode(byteArray);
      //console.log("Encoded (base64) date: ");
      //console.log(b64encFile);

      if (exportFileName == null) {
        exportFileName = "catia_export.plmxml2catia";
      }

      startLocalAppGateway(b64encFile, exportFileName);
    };
    oReq.send();
  });
};

let b64EncodeUnicode = function (uInt8ArrayIn) {
  let bytes = Array.from(uInt8ArrayIn);
  let base64StringFile = btoa(
    bytes
      .map(function (item) {
        return String.fromCharCode(item);
      })
      .join("")
  );

  return base64StringFile;
};

export let getFnd0MessageObj = function () {
  let fnd0MessageObj = "";
  if (appCtxService.ctx.selected.type === "Fnd0Message") {
    fnd0MessageObj = appCtxService.ctx.selected;
  } else if (
    appCtxService.ctx.selected.type === "Zip" &&
    appCtxService.ctx.pselected.type === "Fnd0Message"
  ) {
    fnd0MessageObj = appCtxService.ctx.pselected;
  } else if (
    appCtxService.ctx.locationContext.modelObject.type === "Fnd0Message"
  ) {
    fnd0MessageObj = appCtxService.ctx.locationContext.modelObject;
  }
  return fnd0MessageObj;
};

exports = {
  getFnd0MessageObj,
  startExportForPLMXMLToCatiaFromAttachement,
  startExportForPLMXMLToCatia,
};

export default exports;
