/**
 * @author Nitin Tilekar
 * Service defined for Open Application link to differnet tools urls.
 *
 * @module js/ApplicationLinkOpenService
 * ModifyBy              ModifyOn      Comment
 * Nitin Tilekar         23/Mar/2020   Initial Draft
 * Nitin Tilekar         20/July/2020  link open add error message
 * Nitin Tilekar         22/July/2020  link copy button added and and message changes for local path open
 */
import app from "app";
import adapterSvc from "js/adapterService";
import appCtxSvc from "js/appCtxService";
import messagingSvc from "js/messagingService";
import localeSvc from 'js/localeService';
import VIMSoaUtils from "js/VIMSoaUtils";

("use strict");

// Set Command Context method is not using anywhere
// /**
//  * @function setCommandContextIn
//  * @param {*} context
//  * @param {*} $scope
//  */
// function setCommandContextIn(context, $scope) {
//   commandsMapSvc.isInstanceOf("Dataset", context.modelType) ||
//   commandsMapSvc.isInstanceOf("Folder", context.modelType)
//     ? ($scope.cellCommandVisiblilty = !1)
//     : ($scope.cellCommandVisiblilty = !0);
// }
/**
 * Executes "Open" action for Links
 * @function executeAction
 * @param {*} vmo
 * @param {*} dataCtxNode
 * @param {*} openInEditMode
 */
function executeAction(vmo, dataCtxNode, openInEditMode) {
  let selectedLinkUid = vmo.uid;
  let selectedLinkType = null;
  let PropertyList = [
    "v4b_app_type",
    "v4b_number",
    "v4b_comp_owninguser",
    "object_desc",
  ];
  return VIMSoaUtils.loadObject(selectedLinkUid, PropertyList).then(function (
    responseObject
  ) {
    selectedLinkType = responseObject.props.v4b_app_type.dbValues[0];
    if (selectedLinkType != null) {
      let numberValue = responseObject.props.v4b_number.dbValues[0];
      let linkHlafPart = null;
      let formedLink = null;
      let listofPrefvalue = appCtxSvc.getCtx('preferences.V4B_VIM_Links_List');
      for (let ii = 0; ii < listofPrefvalue.length; ii++) {
        let splitofPrefValue = listofPrefvalue[ii].split("$");
        if (splitofPrefValue[0] == selectedLinkType) {
          if (selectedLinkType != "VIM-Issue") {
            linkHlafPart = splitofPrefValue[1];
            if (selectedLinkType != "Link") {
              formedLink = linkHlafPart + numberValue;
            } else {
              //For Link open
              formedLink = numberValue;
              if (formedLink.startsWith("http") === false) {
                let resource =
                  app.getBaseUrlPath() + "/i18n/VIMCommandsMessages";
                let button = [
                  {
                    addClass: "btn btn-notify",
                    text: localeSvc.getLoadedText(resource).CopyLinkButton,
                    onClick: function ($noty) {
                      $noty.close();
                      let input = document.body.appendChild(
                        document.createElement("input")
                      );
                      input.value = formedLink;
                      input.focus();
                      input.select();
                      navigator.clipboard.writeText(input);
                      input.parentNode.removeChild(input);
                    },
                  },
                ];
                let localeTextBundle =
                  localeSvc.getLoadedText(resource).LocalFileErrorMsg;
                let warningMsg = localeTextBundle + "\n" + formedLink;
                messagingSvc.showWarning(warningMsg, button);
                return;
              }
            }
            window.open(formedLink);
            return formedLink;
          } else {
            //For VIM-Link open
            //https://tc4jbt.bmwgroup.net/awc_test_02/?hashOpenIssue;uid=DC000000228&email
            let baseURL = window.location.href;
            let newBaseUrl = baseURL.substring(0, baseURL.indexOf("/#/"));
            //preference value =?hashOpenIssue;uid=&email
            linkHlafPart = splitofPrefValue[1].split("=");
            formedLink =
              newBaseUrl +
              linkHlafPart[0] +
              "=" +
              numberValue +
              linkHlafPart[1];
            window.open(formedLink);
            return formedLink;
          }
        }
      }
    }
  });
}
let exports = {};

// Set Command Context method is not using anywhere
  
// export let setCommandContext = function (context, $scope) {
//   if ("Awp0XRTObjectSetRow" === context.type) {
//     adapterSvc.getAdaptedObjects([$scope.vmo]).then(function (adaptedObjs) {
//       setCommandContextIn(adaptedObjs[0], $scope);
//     });
//   } else setCommandContextIn(context, $scope);
// };

/**
 * @function execute
 * @param {*} vmo
 * @param {*} dataCtxNode
 * @param {*} openInEditMode
 */
export let execute = function (vmo, dataCtxNode, openInEditMode) {
  if ("Awp0XRTObjectSetRow" === vmo.type) {
    adapterSvc.getAdaptedObjects([vmo]).then(function (adaptedObjs) {
      executeAction(adaptedObjs[0], dataCtxNode, openInEditMode);
    });
  } else executeAction(vmo, dataCtxNode, openInEditMode);
};

exports = {
    execute
};

export default exports;
