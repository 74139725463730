// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwCommandPanelSection from 'viewmodel/AwCommandPanelSectionViewModel';
import Awp0ClassificationNavigate from 'viewmodel/Awp0ClassificationNavigateViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "ports": {},
    "lifecycleHooks": {},
    "props": {},
    "actions": {},
    "functions": {},
    "dataProviders": {},
    "columnProviders": {},
    "grids": {},
    "data": {
        "subPanelContext": {
            "type": "object"
        }
    },
    "onEvent": [],
    "conditions": {},
    "i18n": {},
    "_viewModelId": "ClsNavigateTest",
    "_uniqueViewModelId": "ClsNavigateTest",
    "ctx": {}
};

    /*
    <div class="sw-column">
    <div class="sw-row">
        <aw-command-panel-section caption="Test ClsTreeNavigate" class="aw-clspanel-treeNavigate">
            <awp0-classification-navigate has-releases="true" sub-panel-context="data.subPanelContext"></awp0-classification-navigate>
        </aw-command-panel-section>
    </div>
</div>


    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="sw-column">
        <div className="sw-row">
            <AwCommandPanelSection caption="Test ClsTreeNavigate" className="aw-clspanel-treeNavigate">
                <Awp0ClassificationNavigate hasReleases="true" subPanelContext={AwParseService.instance( "data.subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                </Awp0ClassificationNavigate>
            </AwCommandPanelSection>
        </div>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;