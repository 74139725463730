// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "type": "board",
                "noBreadCrumb": true
            },
            "priority": 0,
            "label": "{{ctx.state.params.title}}",
            "clientScopeURI": "Awp0ReportBuilder",
            "nameToken": "com.siemens.splm.reports:showReport",
            "viewModes": {
                "ImageView": {
                    "primaryWorkArea": "image",
                    "secondaryWorkArea": false
                }
            },
            "viewBase": "showReport",
            "defaultDisplayMode": "ImageView",
            "context": {
                "search": {
                    "hideInContentSearchBox": true
                }
            }
        },
        "reportsContext": {
            "initialValues": {
                "sourceObjects": ""
            },
            "meta": {
                "sourceObjects": {
                    "field": "true",
                    "type": "OBJECTARRAY"
                }
            }
        },
        "headertitleValue": "{{ctx.state.params.title}}"
    },
    "actions": {},
    "_viewModelId": "ShowReportViewerPage",
    "_uniqueViewModelId": "ShowReportViewerPage",
    "ctx": {
        "state": {
            "type": "object"
        }
    }
};

    /*
    <aw-search-page tabsdata="subPanelContext.tabsData" header-title="data.headertitleValue" provider="data._provider"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} headerTitle={AwParseService.instance( "data.headertitleValue" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;