// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwTextbox from 'viewmodel/AwTextboxViewModel';
import AwListbox from 'viewmodel/AwListboxViewModel';
import AwLabel from 'viewmodel/AwLabelViewModel';
import AwButton from 'viewmodel/AwButtonViewModel';
import AwPopupWrapper from 'viewmodel/AwPopupWrapperViewModel';
import AwBreak from 'viewmodel/AwBreakViewModel';
import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwDatePicker from 'viewmodel/AwDatePickerViewModel';
import AwDefaultWorkingDaysAndHours from 'viewmodel/AwDefaultWorkingDaysAndHoursViewModel';
import AwDetailsOfDay from 'viewmodel/AwDetailsOfDayViewModel';
    import { ExistWhen,EnableWhen } from 'js/hocCollection';
const DivExistWhen = ExistWhen('div');
const AwButtonEnableWhen = EnableWhen(AwButton);
const AwPopupWrapperExistWhen = ExistWhen(AwPopupWrapper);
const AwBreakExistWhen = ExistWhen(AwBreak);
const AwDefaultWorkingDaysAndHoursExistWhen = ExistWhen(AwDefaultWorkingDaysAndHours);
const AwDetailsOfDayExistWhen = ExistWhen(AwDetailsOfDay);
DivExistWhen.displayName = 'DivExistWhen';
AwButtonEnableWhen.displayName = 'AwButtonEnableWhen';
AwPopupWrapperExistWhen.displayName = 'AwPopupWrapperExistWhen';
AwBreakExistWhen.displayName = 'AwBreakExistWhen';
AwDefaultWorkingDaysAndHoursExistWhen.displayName = 'AwDefaultWorkingDaysAndHoursExistWhen';
AwDetailsOfDayExistWhen.displayName = 'AwDetailsOfDayExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwCalendarService', () => import('js/AwCalendarService'));
registerDynImportEntry('viewmodel/AwResetCalendarPopupViewModel', () => import('viewmodel/AwResetCalendarPopupViewModel'));
registerDynImportEntry('js/popupService', () => import('js/popupService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "actions": {
        "revealAction": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "getCurrentDate"
                },
                {
                    "action": "createDayDetailsProp"
                },
                {
                    "action": "resetIsDirty"
                }
            ]
        },
        "getCurrentDate": {
            "actionType": "JSFunction",
            "method": "getCurrentDate",
            "deps": "js/AwCalendarService",
            "inputData": {
                "datePickerInfo": "{{data.datePickerInfo}}"
            },
            "outputData": {
                "todayDate.dbValue": "todayDate",
                "datePickerInfo": "datePickerInfo"
            }
        },
        "getNonWorkingDays": {
            "actionType": "JSFunction",
            "method": "getNonWorkingDays",
            "deps": "js/AwCalendarService",
            "inputData": {
                "updatedDefaultRanges": "{{data.updatedDefaultRanges.newUpdatedRanges}}"
            },
            "outputData": {
                "nonWorkingDays": ""
            }
        },
        "createDayDetailsProp": {
            "actionType": "JSFunction",
            "method": "createDayDetailsProp",
            "deps": "js/AwCalendarService",
            "inputData": {
                "selectedCalendar": "{{props.tcCalendar.props}}",
                "i18n": "{{i18n}}",
                "updatedDefaultRanges": "{{data.updatedDefaultRanges}}",
                "calendarName": "{{data.calendarName}}",
                "calendarTimeZone": "{{data.calendarTimeZone}}",
                "baseCalendar": "{{data.baseCalendar}}",
                "previousCalendarName": "{{props.tcCalendar.props.object_string}}",
                "previousTimeZone": "{{props.tcCalendar.props.time_zone}}",
                "previousBaseCalendar": "{{props.tcCalendar.props.base_calendar_tag}}"
            },
            "outputData": {
                "dayData": "dayData",
                "nonWorkingDays": "nonWorkingDays",
                "updatedDefaultRanges": "updatedDefaultRanges",
                "calendarName": "calendarName",
                "calendarTimeZone": "calendarTimeZone",
                "baseCalendar": "baseCalendar"
            }
        },
        "updateMonthOrYear": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "getEvents",
                    "condition": "data.datePickerInfo.fromDate !== '' && data.datePickerInfo.toDate !== '' "
                }
            ]
        },
        "getTimezoneLov": {
            "actionType": "TcSoaService",
            "serviceName": "Core-2013-05-LOV",
            "method": "getInitialLOVValues",
            "inputData": {
                "initialData": {
                    "lovInput": {
                        "boName": "TCCalendar",
                        "operationName": "Search"
                    },
                    "propertyName": "time_zone"
                }
            },
            "outputData": {
                "timezoneList": "{{function:getTimezoneList}}",
                "moreValuesExist": "moreValuesExist"
            },
            "deps": "js/AwCalendarService"
        },
        "resetIsDirty": {
            "actionType": "JSFunction",
            "method": "resetIsDirty",
            "deps": "js/AwCalendarService",
            "inputData": {
                "awCalendarAtomicData": "{{data.awCalendarAtomicData}}",
                "updatedEventRanges": "{{data.updatedEventRanges}}"
            },
            "outputData": {
                "awCalendarAtomicData": "awCalendarAtomicData",
                "updatedEventRanges": "updatedEventRanges"
            }
        },
        "confirmUpdateCalendar": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "confirmSaveCalendar",
                        "condition": "props.tcCalendar.props.tccal_type.dbValues[0] !== '2'"
                    },
                    {
                        "name": "saveScheduleCalendar",
                        "condition": "props.tcCalendar.props.tccal_type.dbValues[0] === '2'"
                    }
                ]
            }
        },
        "saveCalendar": {
            "actionType": "TcSoaService",
            "serviceName": "CalendarManagement-2008-06-CalendarManagement",
            "method": "updateCalendars",
            "deps": "js/AwCalendarService",
            "inputData": {
                "tccalendarUpdates": "{{function:getUpdateCalendarInput}}"
            },
            "outputData": {
                "updatedCalendarResponse": ""
            },
            "events": {
                "success": [
                    {
                        "name": "updateCalendarViewEvent"
                    }
                ]
            }
        },
        "updateCalendarViewAction": {
            "actionType": "JSFunction",
            "method": "updateCalendarView",
            "deps": "js/AwCalendarService",
            "inputData": {
                "dayData": "{{data.dayData}}",
                "updatedDefaultRanges": "{{data.updatedDefaultRanges}}",
                "awCalendarAtomicData": "{{data.awCalendarAtomicData}}",
                "calendarName": "{{data.calendarName}}",
                "calendarTimeZone": "{{data.calendarTimeZone}}",
                "baseCalendar": "{{data.baseCalendar}}",
                "updatedEventRanges": "{{data.updatedEventRanges}}"
            },
            "outputData": {
                "dayData": "dayData",
                "updatedDefaultRanges": "updatedDefaultRanges",
                "awCalendarAtomicData": "awCalendarAtomicData",
                "calendarName": "calendarName",
                "calendarTimeZone": "calendarTimeZone",
                "baseCalendar": "baseCalendar",
                "updatedEventRanges": "updatedEventRanges"
            },
            "events": {
                "success": [
                    {
                        "name": "getSelectedDateDetails"
                    },
                    {
                        "name": "WorkingDaysTable.plTable.reload"
                    }
                ]
            }
        },
        "getEvents": {
            "actionType": "TcSoaService",
            "serviceName": "Query-2014-11-Finder",
            "method": "performSearch",
            "inputData": {
                "searchInput": {
                    "maxToLoad": 50,
                    "maxToReturn": 50,
                    "providerName": "Awp0CalendarDataProvider",
                    "searchCriteria": "{{function:getCalendarEventsInput}}"
                }
            },
            "policy": {
                "types": [
                    {
                        "name": "TCCalendarEvent",
                        "properties": [
                            {
                                "name": "first_recur_start"
                            },
                            {
                                "name": "event_range",
                                "modifiers": [
                                    {
                                        "name": "withProperties",
                                        "Value": "true"
                                    }
                                ]
                            },
                            {
                                "name": "tccalendar_tag"
                            }
                        ]
                    },
                    {
                        "name": "Range",
                        "properties": [
                            {
                                "name": "range_end"
                            },
                            {
                                "name": "range_start"
                            }
                        ]
                    }
                ]
            },
            "outputData": {
                "datePickerInfo": "{{function:processEventResponseObjects}}"
            },
            "deps": "js/AwCalendarService"
        },
        "getBaseCalendarLov": {
            "actionType": "TcSoaService",
            "serviceName": "Internal-AWS2-2023-06-Finder",
            "method": "performSearchViewModel5",
            "inputData": {
                "searchInput": {
                    "maxToLoad": 50,
                    "maxToReturn": 50,
                    "providerName": "Awp0CalendarDataProvider",
                    "searchCriteria": {
                        "searchContentType": "baseCalendars",
                        "tccal_type": "1"
                    }
                }
            },
            "outputData": {
                "baseCalendarList": "{{function:getBaseCalendarsList}}",
                "moreValuesExist": "moreValuesExist"
            },
            "deps": "js/AwCalendarService"
        },
        "displayResetPopup": {
            "actionType": "popup",
            "inputData": {
                "options": {
                    "view": "AwResetCalendarPopup",
                    "caption": "{{i18n.resetScheduleCalendar}}",
                    "clickOutsideToClose": false,
                    "width": 400,
                    "height": 265,
                    "draggable": true,
                    "anchor": "awClosePopupAnchor",
                    "subPanelContext": {
                        "scheduleTCCalendarUid": "{{props.tcCalendar.uid}}"
                    }
                }
            }
        },
        "resetCalendarAction": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "revealAction"
                },
                {
                    "action": "getEvents"
                }
            ]
        },
        "recalculateSchedule": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "recalculateScheduleAction",
                    "condition": "props.tcCalendar.props.tccal_type.dbValues[0] === '2'"
                }
            ]
        },
        "recalculateScheduleAction": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "recalculateScheduleEvent"
                    }
                ]
            }
        },
        "hideAddRowPopup": {
            "actionType": "JSFunction",
            "method": "hide",
            "deps": "js/popupService"
        },
        "updateEditModeAction": {
            "actionType": "JSFunction",
            "method": "updateEditMode",
            "deps": "js/AwCalendarService",
            "inputData": {
                "calendarTimeZone": "{{data.calendarTimeZone}}",
                "baseCalendar": "{{data.baseCalendar}}",
                "editMode": "{{props.editMode}}"
            },
            "outputData": {
                "calendarTimeZone": "calendarTimeZone",
                "baseCalendar": "baseCalendar"
            }
        }
    },
    "functions": {
        "getUpdateCalendarInput": {
            "functionName": "getUpdateCalendarInput",
            "parameters": [
                "{{data.dayData}}",
                "{{props.tcCalendar}}",
                "{{data.updatedDefaultRanges}}",
                "{{data.calendarName}}",
                "{{data.calendarTimeZone}}",
                "{{data.baseCalendar}}",
                "{{data.updatedEventRanges}}",
                "{{data.datePickerInfo}}"
            ]
        },
        "getCalendarEventsInput": {
            "functionName": "getCalendarEventsInput",
            "parameters": [
                "{{data.datePickerInfo}}",
                "{{props.tcCalendar}}"
            ]
        },
        "processEventResponseObjects": {
            "functionName": "processEventResponseObjects",
            "parameters": [
                "{{data.datePickerInfo}}",
                "{{props.tcCalendar.uid}}"
            ]
        }
    },
    "data": {
        "datePickerInfo": {
            "initialValues": {
                "currentMonth": "",
                "currentYear": "",
                "selectedDate": "",
                "fromDate": "",
                "toDate": "",
                "exceptionBucket": [],
                "inheritedBucket": [],
                "eventDateToRangeMap": {}
            },
            "meta": {}
        },
        "updatedEventRanges": {
            "initialValues": {
                "dailyEventDateToRangeMap": {}
            },
            "meta": {}
        },
        "awCalendarAtomicData": {
            "initialValues": {
                "isDirty": false
            },
            "meta": {}
        },
        "calendarName": {
            "displayName": "{{i18n.name}}",
            "type": "STRING",
            "isRequired": "true",
            "isEditable": "{{props.isNameEditable}}",
            "dbValue": "{{props.tcCalendar.props.object_string.dbValue}}",
            "dispValue": "{{props.tcCalendar.props.object_string.uiValue}}"
        },
        "calendarTimeZone": {
            "displayName": "{{i18n.calendarTimeZone}}",
            "type": "STRING",
            "isRequired": "false",
            "isEditable": "{{props.editMode}}",
            "labelPosition:": "PROPERTY_LABEL_AT_TOP",
            "hasLov": true,
            "dbValue": "{{props.tcCalendar.props.time_zone.dbValue}}",
            "dispValue": "{{props.tcCalendar.props.time_zone.displayValues[0]}}",
            "dataProvider": "CalendarTimezoneProvider"
        },
        "baseCalendar": {
            "displayName": "{{i18n.baseCalendar}}",
            "type": "STRING",
            "isRequired": "true",
            "isEditable": "{{props.editMode}}",
            "labelPosition:": "PROPERTY_LABEL_AT_TOP",
            "hasLov": true,
            "dbValue": "{{props.tcCalendar.props.base_calendar_tag.dbValues[0]}}",
            "dispValue": "{{props.tcCalendar.props.base_calendar_tag.uiValues[0]}}",
            "dataProvider": "BaseCalendarsProvider"
        },
        "weekStart": {
            "displayName": "{{i18n.weekStart}}",
            "type": "STRING",
            "isRequired": "false",
            "isEditable": "false",
            "dbValue": "{{i18n.awDay_Sunday}}",
            "displayValue": "{{i18n.awDay_Sunday}}"
        },
        "todayDate": {
            "displayName": "{{i18n.date}}",
            "type": "DATE",
            "dbValue": ""
        },
        "dateLabel": {
            "displayName": "{{i18n.date}}",
            "type": "STRING",
            "labelPosition": "PROPERTY_LABEL_AT_TOP"
        },
        "updatedDefaultRanges": {
            "initialValues": {},
            "meta": {}
        }
    },
    "props": {
        "edit-mode": {
            "type": "boolean"
        },
        "tc-calendar": {
            "type": "object"
        },
        "is-name-editable": {
            "type": "boolean"
        }
    },
    "dataProviders": {
        "CalendarTimezoneProvider": {
            "dataProviderType": "Action",
            "action": "getTimezoneLov",
            "response": "{{data.timezoneList}}",
            "moreValuesExist": "{{data.moreValuesExist}}"
        },
        "BaseCalendarsProvider": {
            "dataProviderType": "Action",
            "action": "getBaseCalendarLov",
            "response": "{{data.baseCalendarList}}",
            "moreValuesExist": "{{data.moreValuesExist}}"
        }
    },
    "onEvent": [
        {
            "eventId": "confirmSaveCalendar",
            "message": "confirmSaveCalendarMessage"
        },
        {
            "eventId": "saveScheduleCalendar",
            "message": "saveScheduleCalendarMessage"
        },
        {
            "eventId": "updateCalendarViewEvent",
            "action": "updateCalendarViewAction"
        },
        {
            "eventId": "resetCalendarEvent",
            "action": "resetCalendarAction"
        },
        {
            "eventId": "hideAddRowPopup",
            "action": "hideAddRowPopup"
        }
    ],
    "messages": {
        "confirmSaveCalendarMessage": {
            "messageType": "WARNING",
            "messageText": "{{i18n.confirmSaveCalendarMessage}}",
            "messageTextParams": [
                "{{props.tcCalendar.props.object_string.dbValues[0]}}"
            ],
            "navigationOptions": [
                {
                    "option": "cancel",
                    "text": "{{i18n.cancelButton}}",
                    "action": ""
                },
                {
                    "option": "continue",
                    "text": "{{i18n.continueButton}}",
                    "action": "saveCalendar"
                }
            ]
        },
        "saveScheduleCalendarMessage": {
            "messageType": "WARNING",
            "messageText": "{{i18n.saveScheduleCalendarMessage}}",
            "messageTextParams": [
                "{{props.tcCalendar.props.object_string.dbValues[0]}}"
            ],
            "navigationOptions": [
                {
                    "option": "cancel",
                    "text": "{{i18n.cancelButton}}",
                    "action": ""
                },
                {
                    "option": "continue",
                    "text": "{{i18n.continueButton}}",
                    "action": "saveCalendar"
                }
            ]
        }
    },
    "conditions": {
        "isCalendarPropertyModified": {
            "expression": "(data.calendarName.prevDisplayValues[0] !== data.calendarName.displayValues[0]) || (data.calendarTimeZone.prevDisplayValues[0] !== data.calendarTimeZone.displayValues[0]) || (data.baseCalendar.prevDisplayValues[0] !== data.baseCalendar.displayValues[0])"
        }
    },
    "lifecycleHooks": {
        "onMount": "revealAction",
        "onUpdate": [
            {
                "action": "getNonWorkingDays",
                "observers": [
                    "viewModel.atomicData.updatedDefaultRanges.newUpdatedRanges"
                ]
            },
            {
                "action": "updateMonthOrYear",
                "observers": [
                    "viewModel.atomicData.datePickerInfo.currentMonth",
                    "viewModel.atomicData.datePickerInfo.currentYear"
                ]
            },
            {
                "action": "getEvents",
                "observers": [
                    "viewModel.data.updatedCalendarResponse"
                ]
            },
            {
                "action": "recalculateSchedule",
                "observers": [
                    "viewModel.data.updatedCalendarResponse"
                ]
            },
            {
                "action": "updateEditModeAction",
                "observers": [
                    "props.editMode"
                ]
            }
        ]
    },
    "i18n": {
        "name": [
            "CalendarManagementMessages"
        ],
        "calendarTimeZone": [
            "CalendarManagementMessages"
        ],
        "weekStart": [
            "CalendarManagementMessages"
        ],
        "awDay_Sunday": [
            "CalendarManagementMessages"
        ],
        "awDay_Monday": [
            "CalendarManagementMessages"
        ],
        "awDay_Tuesday": [
            "CalendarManagementMessages"
        ],
        "awDay_Wednesday": [
            "CalendarManagementMessages"
        ],
        "awDay_Thursday": [
            "CalendarManagementMessages"
        ],
        "awDay_Friday": [
            "CalendarManagementMessages"
        ],
        "awDay_Saturday": [
            "CalendarManagementMessages"
        ],
        "cancelButton": [
            "CalendarManagementMessages"
        ],
        "saveButton": [
            "CalendarManagementMessages"
        ],
        "calendarPreview": [
            "CalendarManagementMessages"
        ],
        "date": [
            "CalendarManagementMessages"
        ],
        "confirmSaveCalendarMessage": [
            "CalendarManagementMessages"
        ],
        "saveScheduleCalendarMessage": [
            "CalendarManagementMessages"
        ],
        "continueButton": [
            "CalendarManagementMessages"
        ],
        "baseCalendar": [
            "CalendarManagementMessages"
        ],
        "resetButton": [
            "CalendarManagementMessages"
        ],
        "resetScheduleCalendar": [
            "CalendarManagementMessages"
        ]
    },
    "_viewModelId": "AwCalendar",
    "_uniqueViewModelId": "AwCalendar",
    "ctx": {}
};

    /*
    <div class="sw-row justify-justified aw-calendar-label">
    <div class="sw-column">
        <aw-textbox class="aw-startWeek-label" prop="data.calendarName"></aw-textbox>
    </div>
    <div class="sw-column">
        <aw-listbox class="aw-startWeek-label" prop="data.calendarTimeZone"></aw-listbox>
    </div>
    <div class="sw-column" exist-when= "props.tcCalendar.props.tccal_type.dbValues[0] !== '1'">
        <aw-listbox class="aw-startWeek-label" prop="data.baseCalendar"></aw-listbox>
    </div>
    <div class="sw-column">
        <aw-label class="aw-startWeek-label" prop="data.weekStart"></aw-label>
    </div>
    <div class="sw-column">
        <div class="sw-row align-top sw-button-wrapper">
            <aw-button button-type='base' class='medium' action="revealAction" enable-when="data.awCalendarAtomicData.isDirty || conditions.isCalendarPropertyModified">i18n.cancelButton</aw-button>
            <aw-popup-wrapper exist-when="props.tcCalendar.props.tccal_type.dbValues[0] === '2'" popup="displayResetPopup">
                <aw-button button-type='base' class='medium' action="displayResetPopup" enable-when="props.editMode">i18n.resetButton</aw-button>
            </aw-popup-wrapper>
            <aw-button class='medium' action="confirmUpdateCalendar" enable-when="data.awCalendarAtomicData.isDirty || conditions.isCalendarPropertyModified">i18n.saveButton</aw-button>
        </div>
    </div>
</div>
<aw-break exist-when="props.editMode === false"></aw-break>
<div class="sw-row">
    <div class="sw-column w-3 aw-calenderMgmt-datePicker">
        <aw-panel>
            <aw-panel-body>
                <aw-panel-section caption="i18n.calendarPreview">
                    <aw-label prop="data.dateLabel"></aw-label>
                    <aw-date-picker sub-panel-context="subPanelContext" prop="data.todayDate" selected-calendar-data="data.dayData" non-working-days="{{data.nonWorkingDays}}" date-picker-info="data.datePickerInfo" updated-events="data.updatedEventRanges.dailyEventDateToRangeMap"></aw-date-picker>
                </aw-panel-section>
            </aw-panel-body>
        </aw-panel>
    </div>
    <div class="sw-column w-9">
        <aw-default-working-days-and-hours exist-when="data.dayData && data.todayDate" aw-calendar-atomic-data="data.awCalendarAtomicData" edit-mode="{{props.editMode}}" selected-date="data.todayDate" selected-calendar-data="data.dayData" sub-panel-context="{updatedDefaultRanges:data.updatedDefaultRanges}"></aw-default-working-days-and-hours>
        <aw-details-of-day exist-when="data.dayData && data.todayDate && data.datePickerInfo" aw-calendar-atomic-data="data.awCalendarAtomicData" edit-mode="{{props.editMode}}" selected-date="data.todayDate" selected-calendar-data="data.dayData" sub-panel-context="{updatedEventRanges:data.updatedEventRanges}" event-info="data.datePickerInfo"></aw-details-of-day>
    </div>
</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <div className="sw-row justify-justified aw-calendar-label">
        <div className="sw-column">
            <AwTextbox className="aw-startWeek-label" {...getField("data.calendarName", fields, $index, null, null )}>
            </AwTextbox>
        </div>
        <div className="sw-column">
            <AwListbox className="aw-startWeek-label" {...getField("data.calendarTimeZone", fields, $index, null, null )}>
            </AwListbox>
        </div>
        <DivExistWhen className="sw-column" existWhen={AwParseService.instance( "props.tcCalendar.props.tccal_type.dbValues[0] !== '1'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            <AwListbox className="aw-startWeek-label" {...getField("data.baseCalendar", fields, $index, null, null )}>
            </AwListbox>
        </DivExistWhen>
        <div className="sw-column">
            <AwLabel className="aw-startWeek-label" {...getField("data.weekStart", fields, $index, null, null )}>
            </AwLabel>
        </div>
        <div className="sw-column">
            <div className="sw-row align-top sw-button-wrapper">
                <AwButtonEnableWhen buttonType="base" className="medium" action={actions.revealAction} enableWhen={AwParseService.instance( "fields.awCalendarAtomicData.isDirty || conditions.isCalendarPropertyModified" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    {i18n.cancelButton}
                </AwButtonEnableWhen>
                <AwPopupWrapperExistWhen popup={actions.displayResetPopup} existWhen={AwParseService.instance( "props.tcCalendar.props.tccal_type.dbValues[0] === '2'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    <AwButtonEnableWhen buttonType="base" className="medium" action={actions.displayResetPopup} enableWhen={AwParseService.instance( "props.editMode" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        {i18n.resetButton}
                    </AwButtonEnableWhen>
                </AwPopupWrapperExistWhen>
                <AwButtonEnableWhen className="medium" action={actions.confirmUpdateCalendar} enableWhen={AwParseService.instance( "fields.awCalendarAtomicData.isDirty || conditions.isCalendarPropertyModified" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                    {i18n.saveButton}
                </AwButtonEnableWhen>
            </div>
        </div>
    </div>
    <AwBreakExistWhen existWhen={AwParseService.instance( "props.editMode === false" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwBreakExistWhen>
    <div className="sw-row">
        <div className="sw-column w-3 aw-calenderMgmt-datePicker">
            <AwPanel  revealAction={actions.reveal}>
                <AwPanelBody>
                    <AwPanelSection caption={i18n.calendarPreview}>
                        <AwLabel {...getField("data.dateLabel", fields, $index, null, null )}>
                        </AwLabel>
                        <AwDatePicker sub-panel-context="subPanelContext" {...getField("data.todayDate", fields, $index, null, null )} selectedCalendarData={AwParseService.instance( "data.dayData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} nonWorkingDays={AwParseService.instance( "data.nonWorkingDays" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} datePickerInfo={AwParseService.instance( "fields.datePickerInfo" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} updatedEvents={AwParseService.instance( "fields.updatedEventRanges.dailyEventDateToRangeMap" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        </AwDatePicker>
                    </AwPanelSection>
                </AwPanelBody>
            </AwPanel>
        </div>
        <div className="sw-column w-9">
            <AwDefaultWorkingDaysAndHoursExistWhen awCalendarAtomicData={AwParseService.instance( "fields.awCalendarAtomicData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} editMode={AwParseService.instance( "props.editMode" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} selectedDate={AwParseService.instance( "data.todayDate" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} selectedCalendarData={AwParseService.instance( "data.dayData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{updatedDefaultRanges:fields.updatedDefaultRanges}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "data.dayData && data.todayDate" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwDefaultWorkingDaysAndHoursExistWhen>
            <AwDetailsOfDayExistWhen awCalendarAtomicData={AwParseService.instance( "fields.awCalendarAtomicData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} editMode={AwParseService.instance( "props.editMode" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} selectedDate={AwParseService.instance( "data.todayDate" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} selectedCalendarData={AwParseService.instance( "data.dayData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{updatedEventRanges:fields.updatedEventRanges}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} eventInfo={AwParseService.instance( "fields.datePickerInfo" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "data.dayData && data.todayDate && fields.datePickerInfo" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwDetailsOfDayExistWhen>
        </div>
    </div>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;