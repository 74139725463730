// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    
    

    /*
    render function
    */
    import { AwClsVncParentTestRenderFunction } from 'js/AwClsVncParentTestService';
    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwClsVncParentTestService', () => import('js/AwClsVncParentTestService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "ports": {},
    "lifecycleHooks": {
        "render": "render"
    },
    "props": {},
    "dataParseDefinitions": {},
    "actions": {
        "render": {
            "method": "AwClsVncParentTestRenderFunction",
            "deps": "js/AwClsVncParentTestService"
        }
    },
    "functions": {},
    "dataProviders": {},
    "columnProviders": {},
    "grids": {},
    "data": {
        "rootstate": {
            "initialValues": {
                "rootString": "Root Class"
            },
            "meta": {
                "rootString": {
                    "field": "true",
                    "type": "STRING"
                }
            }
        }
    },
    "onEvent": [],
    "conditions": {},
    "messages": {},
    "i18n": {
        "more": [
            "ClassificationPanelMessages"
        ]
    },
    "_viewModelId": "AwClsVncParentTest",
    "_uniqueViewModelId": "AwClsVncParentTest"
};

    


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, AwClsVncParentTestRenderFunction, undefined, ctxImportObj );

    export default Component;