// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * @module js/mbmFindInService
 */
import _ from 'lodash';
import cdm from 'soa/kernel/clientDataModel';
import dataMgmtService from 'soa/dataManagementService';
import { constants as mbmConstants } from 'js/mbmConstants';
import mbmUtils from './mbmUtils';
const packPropName = 'awb0IsPacked';
const quantityPropName = 'awb0Quantity';
import epLoadInputHelper from 'js/epLoadInputHelper';
import soaService from 'soa/kernel/soaService';
/**
 * Get target info of given uid
 * @param {*} mbmContext  Context
 * @param {*} vmo  of which to find target line
 * @param {String} contextKey context of vmo
 * @param {*} displayPropName  dispaly name property main property name in display name
 * @param {*} additionalPropNames  property name which will be comma separated displayable property in bracket in display name
 * @param {*} parentLevelIndex  parentLevelIndex
 * @return {Promise} promise which will return array of object
 */
export function findEquivalentTargetInfo( mbmContext, vmo, contextKey, displayPropName, additionalPropNames, parentLevelIndex ) {
    let targetInfo = vmo.targetInfo;
    let targetUids =  vmo.targetInfo ? vmo.targetInfo.map( trg=>trg.uid ) : [];
    let status = vmo.compareStatus;
    let srcObj = vmo;
    let isPacked = vmo.props[ packPropName ].dbValues[ 0 ] !== '0';
    let parentUid = vmo.props.awb0Parent.dbValues[ 0 ];
    if ( mbmContext ) {
        let vmc = mbmUtils.getContextValue( mbmContext, mbmConstants.MBM_VMC );
        let vmTreeNodes = vmc[contextKey].getLoadedViewModelObjects();
        while( targetUids.length === 0 && parentUid ) {
            targetUids =  getTargetUidsFor( vmTreeNodes, parentUid );
            srcObj = cdm.getObject( parentUid );
            parentUid = srcObj.props.awb0Parent.dbValues[ 0 ];
        }
    }
    let parentUidToLoad = [];
    if( parentLevelIndex ) {
        for( let tagetUidCount = 0; tagetUidCount < targetUids.length; tagetUidCount++ ) {
            let objs = cdm.getObject( targetUids[ tagetUidCount ] );
            if( objs ) {
                let parentId = objs.props.awb0Parent.dbValues[ 0 ];
                parentUidToLoad.push( parentId );
                for( let i = 0;
                    i < parentLevelIndex - 1 && parentId; i++ ) {
                    objs = cdm.getObject( parentId );
                    parentUidToLoad.push( parentId );
                    parentId = objs.props.awb0Parent.dbValues[ 0 ];
                }
            }
        }
    }
    return getObjectsToFind( targetUids, displayPropName, isPacked, additionalPropNames, parentUidToLoad, parentLevelIndex ).then( function( objectsTofind ) {
        let scopeUids = getScopeUids( targetInfo );
        let inscope = _.filter( objectsTofind, function( objsToFind ) {
            if( scopeUids.inScope && scopeUids.inScope.length > 0 ) {
                return scopeUids.inScope.indexOf( objsToFind.objectToFind.uid ) > -1;
            }
        } );
        let outscope = _.filter( objectsTofind, function( objsToFind ) {
            if( scopeUids && scopeUids.outOfScope && scopeUids.outOfScope.length > 0 ) {
                return scopeUids.outOfScope.indexOf( objsToFind.objectToFind.uid ) > -1;
            }
        } );
        return {
            objectToFind: objectsTofind && objectsTofind.length > 0 ? objectsTofind[0].objectToFind : null,
            objectsTofind: objectsTofind,
            isPacked: isPacked,
            status: status,
            inScope: inscope,
            outOfScope: outscope
        };
    } );
}

/**
 *
 * @param {*} vmos
 * @param {*} uid
 * @returns
 */
function getTargetUidsFor( vmos, uid ) {
    let targetUids = null;
    _.forEach( vmos, vmo=>{
        if ( vmo.uid === uid ) {
            targetUids = vmo.targetInfo ? vmo.targetInfo.map( trg=>trg.uid ) : [];
            return false;
        }
    } );

    return targetUids ? targetUids : [];
}
/**
 *
 * @param {*} targetInfo
 * @returns
 */
function getScopeUids( targetInfo ) {
    let outOfScope = [];
    let inScope = [];
    if ( targetInfo ) {
        targetInfo.forEach( ( element ) => {
            if( element.outOfScope === true ) {
                outOfScope.push( element.uid );
            } else {
                inScope.push( element.uid );
            }
        } );
    }
    return { outOfScope, inScope };
}
/**
 * @param {*} uids uids of object to load
 * @param {*} displayPropName  dispaly name property main property name in display name
 * @param {boolean}  showAdditionalProp if true show addtional properties in ()
 * @param {*} additionalPropNames  property name which will be comma separated displayable property in bracket in display name
 * @return {Promise} promise which will return array of object
 */
export function getObjectsToFind( contextUids, displayPropName, showAdditionalProp, additionalPropNames, parentUidToLoad, levelIndex ) {
    return dataMgmtService.loadObjects( [ ...contextUids, ...parentUidToLoad ] ).then( function() {
        let propNames = [ packPropName ];
        if( additionalPropNames ) {
            propNames = [ ...additionalPropNames ];
        }

        if( displayPropName && propNames.indexOf( displayPropName ) < 0 ) {
            propNames.push( displayPropName );
        }

        return dataMgmtService.getProperties( parentUidToLoad, propNames ).then( function() {
            let objsToFind = [];
            _.forEach( contextUids, function( uid ) {
                let obj = cdm.getObject( uid );
                let displayName = displayPropName ? obj.props[ displayPropName ].uiValues[ 0 ] : null;
                if( levelIndex ) {
                    let objs = obj;
                    let nameOfParents = [];
                    for( let i = 1;
                        i < levelIndex && objs.props.awb0Parent.dbValues[ 0 ]; i++ ) {
                        nameOfParents.push( objs.props[ displayPropName ].uiValues[ 0 ] || null );
                        objs = cdm.getObject( objs.props.awb0Parent.dbValues[ 0 ] );
                        if( i === levelIndex - 1 && objs.props.awb0Parent.dbValues[ 0 ] ) {
                            nameOfParents.push( '...' );
                        }
                    }
                    displayName = createBreadbrumbForFindin( nameOfParents );
                }
                let packStatus = obj.props[ packPropName ].dbValues[ 0 ] !== '0';
                if( displayName && showAdditionalProp && additionalPropNames ) {
                    let values = [];
                    _.forEach( additionalPropNames, function( propName ) {
                        let value = obj.props[ propName ].uiValues[ 0 ];
                        if( propName === quantityPropName && _.isEmpty( value ) ) {
                            value = 1;
                        }
                        if( value ) {
                            values.push( value );
                        }
                    } );
                    if( values.length > 0 ) {
                        displayName = displayName + ' (' + _.toString( values ) + ')';
                    }
                }
                objsToFind.push( {
                    objectToFind: obj,
                    displayName: displayName,
                    isPacked: packStatus
                } );
            } );

            return objsToFind;
        } );
    } );
}

/**
 * @param {Array} parents name of higher level parent
 * @return {String} creates Bread crumb with parent and grand parent
 */
function createBreadbrumbForFindin( parents ) {
    let breadCrumb = [];
    for( let i = parents.length - 1; i >= 0; i-- ) {
        breadCrumb.push( parents[ i ] );
    }
    return breadCrumb.join( ' > ' );
}

/**
 * Get  target info for no indiaction
 * @param {*} mbmContext mbmContext
 * @param {*} displayPropName  dispaly name property main property name in display name
 * @param {*} additionalPropNames  property name which will be comma separated displayable property in bracket in display name
 * @param {String} parentLevelIndex parentLevelIndex
 * @param {String} contextObjectKey contextObjectKey
 * @param {*} vmo vmo
 * @return {Promise} promise which will return array of object
 */
function getTarget(
    mbmContext,
    displayPropName,
    additionalPropNames,
    parentLevelIndex,
    contextObjectKey,
    vmo
) {
    let contextObject = mbmContext.topElement[ contextObjectKey ].uid;
    let uid = vmo.uid;

    let scopeUids = getUidsFor( mbmContext.selectedScope[contextObjectKey] );
    const addLoadParams = [ {
        tagName: 'contextObject',
        attributeName: 'objectUid',
        attributeValue: [ contextObject ]
    },
    {
        tagName: 'nodesToFind',
        attributeName: 'objectUid',
        attributeValue: uid
    },
    {
        tagName: 'scopeUids',
        attributeName: 'objectUid',
        attributeValue: scopeUids
    }
    ];
    const loadTypeInputs = epLoadInputHelper.getLoadTypeInputs(
        [ 'MbmFindNodeInContext' ],
        '',
        '',
        '',
        addLoadParams
    );
    let jsonInput = epLoadInputHelper.getLoadInputJSON( loadTypeInputs );
    return soaService
        .postUnchecked(
            'Internal-MfgBvrCore-2015-03-DataManagement',
            'loadObjectData3',
            jsonInput
        )
        .then( function( response ) {
            if( response ) {
                let targetUids = [];
                let targetInfo = [];
                let inScope =
                    response.relatedObjectsMap[ uid ].additionalPropertiesMap2
                        .IN_SCOPE;
                if( inScope !== undefined ) {
                    inScope.forEach( ( element ) => {
                        targetUids.push( element );
                        let object = {
                            outOfScope: false,
                            uid: element
                        };
                        targetInfo.push( object );
                    } );
                }
                let outScope =
                    response.relatedObjectsMap[ uid ].additionalPropertiesMap2
                        .OUT_OF_SCOPE;
                if( outScope !== undefined ) {
                    outScope.forEach( ( element ) => {
                        targetUids.push( element );
                        let object = {
                            outOfScope: true,
                            uid: element
                        };
                        targetInfo.push( object );
                    } );
                }
                let isPacked = vmo.props[ packPropName ].dbValues[ 0 ] !== '0';
                let parentUidToLoad = [];
                if( parentLevelIndex ) {
                    for( let tagetUidCount = 0; tagetUidCount < targetUids.length; tagetUidCount++ ) {
                        let objs = cdm.getObject( targetUids[ tagetUidCount ] );
                        if( objs ) {
                            let parentId = objs.props.awb0Parent.dbValues[ 0 ];
                            parentUidToLoad.push( parentId );
                            for( let i = 0; i < parentLevelIndex - 1 && parentId; i++ ) {
                                objs = cdm.getObject( parentId );
                                parentUidToLoad.push( parentId );
                                parentId = objs.props.awb0Parent.dbValues[ 0 ];
                            }
                        }
                    }
                }
                return getObjectsToFind( targetUids, displayPropName, isPacked, additionalPropNames, parentUidToLoad, parentLevelIndex ).then( function( objectsTofind ) {
                    let scopeUids = getScopeUids( targetInfo );
                    let inscope = _.filter( objectsTofind, function( objsToFind ) {
                        if( scopeUids.inScope && scopeUids.inScope.length > 0 ) {
                            return scopeUids.inScope.indexOf( objsToFind.objectToFind.uid ) > -1;
                        }
                    } );
                    let outscope = _.filter( objectsTofind, function( objsToFind ) {
                        if( scopeUids && scopeUids.outOfScope && scopeUids.outOfScope.length > 0 ) {
                            return scopeUids.outOfScope.indexOf( objsToFind.objectToFind.uid ) > -1;
                        }
                    } );
                    return {
                        objectToFind: objectsTofind && objectsTofind.length > 0 ? objectsTofind[ 0 ].objectToFind : null,
                        objectsTofind: objectsTofind,
                        isPacked: isPacked,
                        status: '',
                        inScope: inscope,
                        outOfScope: outscope
                    };
                } );
            }
        } );
}
/**
 *
 * @param {Array} vmos vmos
 * @returns {Array} array of uid
 */
function getUidsFor( vmos ) {
    if ( vmos ) {
        return vmos.map( function( vmo ) {
            return vmo.uid;
        } );
    }
    return null;
}

export default {
    getObjectsToFind,
    findEquivalentTargetInfo,
    getTarget,
    getUidsFor
};
