// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    import { ExistWhen } from 'js/hocCollection';
const AwSearchPageExistWhen = ExistWhen(AwSearchPage);
AwSearchPageExistWhen.displayName = 'AwSearchPageExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/viewerProductSnapshotService', () => import('js/viewerProductSnapshotService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "actions": {
        "getSnapshotSearchString": {
            "actionType": "JSFunction",
            "method": "getSnapshotSearchString",
            "inputData": {
                "provider": "{{data._provider}}"
            },
            "outputData": {
                "_provider": "provider",
                "isLoadSearch": true
            },
            "deps": "js/viewerProductSnapshotService"
        }
    },
    "lifecycleHooks": {
        "onMount": "getSnapshotSearchString"
    },
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "vm": "AwMyGalleryBreadcrumb"
            },
            "nameToken": "",
            "defaultDisplayMode": "ImageView",
            "selectionMode": "single",
            "viewModes": {
                "ImageView": {
                    "primaryWorkArea": "image",
                    "secondaryWorkArea": true
                },
                "TableView": {
                    "primaryWorkArea": "table",
                    "secondaryWorkArea": true
                }
            },
            "clientScopeURI": "Awv0SnapshotSearchResults",
            "context": {
                "search": {
                    "provider": "Awp0FullTextSearchProvider",
                    "sortType": "Priority",
                    "accessPrivilege": "sharedByMe",
                    "hideInContentSearchBox": "true",
                    "criteria": {
                        "forceThreshold": "true",
                        "limitedFilterCategoriesEnabled": "false",
                        "listOfExpandedCategories": "",
                        "searchFromLocation": "global",
                        "searchString": "type:\"Fnd0Snapshot\" AND owner:$ME AND \"Owning Identifier\":Conversation"
                    },
                    "maxToLoad": 50,
                    "maxToReturn": 50
                }
            },
            "viewBase": "snapshotSearch"
        }
    },
    "_viewModelId": "AwSharedByMeSnapshotsPage",
    "_uniqueViewModelId": "AwSharedByMeSnapshotsPage",
    "ctx": {}
};

    /*
    <aw-search-page tabsdata="subPanelContext.tabsData" provider="data._provider" exist-when="data.isLoadSearch"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPageExistWhen tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "data.isLoadSearch" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPageExistWhen>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;