// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwScrollpanel from 'viewmodel/AwScrollpanelViewModel';
import AwLogo from 'viewmodel/AwLogoViewModel';
import AwForm from 'viewmodel/AwFormViewModel';
import AwListbox from 'viewmodel/AwListboxViewModel';
import AwCheckbox from 'viewmodel/AwCheckboxViewModel';
import AwButton from 'viewmodel/AwButtonViewModel';
    import { EnableWhen } from 'js/hocCollection';
const AwButtonEnableWhen = EnableWhen(AwButton);
AwButtonEnableWhen.displayName = 'AwButtonEnableWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/geographyData.service', () => import('js/geographyData.service'));
registerDynImportEntry('js/sessionManager.service', () => import('js/sessionManager.service'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [
        "js/aw-listbox.directive",
        "js/aw-checkbox.directive",
        "js/aw-button.directive",
        "js/aw-form.directive",
        "js/aw-logo.directive",
        "js/aw-scrollpanel.directive"
    ],
    "actions": {
        "NextPage": {
            "actionType": "JSFunction",
            "method": "continueToNextPage",
            "deps": "js/geographyData.service",
            "events": {}
        },
        "signOut": {
            "actionType": "JSFunction",
            "method": "terminateSession",
            "deps": "js/sessionManager.service",
            "events": {}
        },
        "continueSignin": {
            "actionType": "TcSoaService",
            "serviceName": "Administration-2016-10-UserManagement",
            "method": "saveAndValidateCurrentCountry",
            "inputData": {
                "selectedcountry": "{{data.listBox.dbValue}}"
            },
            "events": {
                "success": [
                    {
                        "name": "nextPage"
                    }
                ]
            }
        },
        "reveal": {
            "actionType": "TcSoaService",
            "serviceName": "Administration-2016-10-UserManagement",
            "method": "getCurrentCountryPageInfo",
            "inputData": {},
            "outputData": {
                "locationValues": "{{function:getLOVList}}",
                "confidentialityAgreement": "extraInfoOut.confidentialityStatement"
            },
            "events": {
                "success": [
                    {
                        "name": "setConfidentialityStatement"
                    }
                ]
            },
            "deps": "js/geographyData.service"
        },
        "setConfidentialStatement": {
            "actionType": "JSFunction",
            "method": "setConfidentialStatement",
            "inputData": {
                "data": "{{data}}",
                "elementId": "ConfidentialityAgreement1"
            },
            "deps": "js/geographyData.service"
        }
    },
    "lifecycleHooks": {
        "onMount": "reveal"
    },
    "functions": {
        "getLOVList": {
            "functionName": "getLOVList",
            "parameters": [
                "{{data}}"
            ]
        }
    },
    "onEvent": [
        {
            "eventId": "nextPage",
            "action": "NextPage"
        },
        {
            "eventId": "setConfidentialityStatement",
            "action": "setConfidentialStatement"
        }
    ],
    "data": {
        "listBox": {
            "displayName": "{{i18n.GEOGRAPHY_SECTION_PROMPT}}",
            "type": "STRING",
            "isRequired": "true",
            "hasLov": "true",
            "dbValue": "",
            "dispValue": ""
        },
        "agreeCheck": {
            "displayName": "{{i18n.CONFIDENTIAL_CHECKBOX_LABEL}}",
            "type": "BOOLEAN",
            "isRequired": "true",
            "dbValue": "false",
            "dispValue": "{{i18n.CONFIDENTIAL_CHECKBOX_LABEL}}",
            "labelPosition": "PROPERTY_LABEL_AT_RIGHT"
        }
    },
    "messages": {},
    "conditions": {},
    "i18n": {
        "BRAND_NAME": [
            "geographyMessages"
        ],
        "SIGN_OUT_TEXT": [
            "geographyMessages"
        ],
        "CONTINUE_TEXT": [
            "geographyMessages"
        ],
        "GEOGRAPHY_SECTION_TITLE": [
            "geographyMessages"
        ],
        "GEOGRAPHY_SECTION_PROMPT": [
            "geographyMessages"
        ],
        "CONFIDENTIAL_SECTION_TITLE": [
            "geographyMessages"
        ],
        "CONFIDENTIAL_CHECKBOX_LABEL": [
            "geographyMessages"
        ],
        "INITIAL_LIST_VALUE": [
            "geographyMessages"
        ]
    },
    "dataProviders": {},
    "_viewModelId": "geographyPicker",
    "_uniqueViewModelId": "geographyPicker",
    "ctx": {}
};

    /*
    <aw-scrollpanel>
    <div class="aw-geography-mainView">
        <div class="aw-geography-pagesHeader">
            <h2>{{i18n.BRAND_NAME}}</h2>
        </div>
        <div class="aw-geography-logoImage">
            <aw-logo></aw-logo>
        </div>
        <div class ="aw-layout-subLocation afx-content-background aw-flex-row aw-layout-flexbox afx-base-parentElement aw-geography-cornerRadius">
            <aw-form name="myForm">
                <div class="aw-geography-section">
                    <div class="aw-layout-panelSectionTitle aw-base-tabTitle">{{i18n.GEOGRAPHY_SECTION_TITLE}}</div>
                    <div class="aw-geography-listox">
                        <aw-listbox prop="data.listBox" list="data.locationValues"></aw-listbox>
                    </div>
                </div>
                <div class="aw-confidentiality-section">
                    <div class="aw-layout-panelSectionTitle aw-geography-sectionTitle">{{i18n.CONFIDENTIAL_SECTION_TITLE}}</div>
                    <div id="ConfidentialityAgreement1" class="aw-confidentialityStatementSection aw-base-scrollPanel"></div>
                </div>
                <div class="aw-layout-panelFooter aw-geographyFooter-buttons" >
                    <div class="aw-geography-checkbox">
                        <aw-checkbox prop="data.agreeCheck"></aw-checkbox>
                    </div>
                    <div class='sw-row sw-button-wrapper sw-button-group justify-left'>
                        <div class="sw-column justify-center">
                            <aw-button class='small' button-type='base' action = "signOut">{{i18n.SIGN_OUT_TEXT}}</aw-button>
                        </div>
                        <div class="sw-column justify-center">
                            <aw-button class='small'  enable-when="data.agreeCheck.dbValue===true && data.listBox.dbValue !== ''" action ="continueSignin" >{{i18n.CONTINUE_TEXT}}</aw-button>
                        </div>
                    </div>
                </div>
            </aw-form>
        </div>
    </div>
</aw-scrollpanel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwScrollpanel>
        <div className="aw-geography-mainView">
            <div className="aw-geography-pagesHeader">
                <h2>
                    {AwParseService.instance( "i18n.BRAND_NAME" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                </h2>
            </div>
            <div className="aw-geography-logoImage">
                <AwLogo>
                </AwLogo>
            </div>
            <div className="aw-layout-subLocation afx-content-background aw-flex-row aw-layout-flexbox afx-base-parentElement aw-geography-cornerRadius">
                <AwForm name="myForm">
                    <div className="aw-geography-section">
                        <div className="aw-layout-panelSectionTitle aw-base-tabTitle">
                            {AwParseService.instance( "i18n.GEOGRAPHY_SECTION_TITLE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                        </div>
                        <div className="aw-geography-listox">
                            <AwListbox {...getField("data.listBox", fields, $index, null, null )} list={AwParseService.instance( "data.locationValues" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            </AwListbox>
                        </div>
                    </div>
                    <div className="aw-confidentiality-section">
                        <div className="aw-layout-panelSectionTitle aw-geography-sectionTitle">
                            {AwParseService.instance( "i18n.CONFIDENTIAL_SECTION_TITLE" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                        </div>
                        <div id="ConfidentialityAgreement1" className="aw-confidentialityStatementSection aw-base-scrollPanel">
                        </div>
                    </div>
                    <div className="aw-layout-panelFooter aw-geographyFooter-buttons">
                        <div className="aw-geography-checkbox">
                            <AwCheckbox {...getField("data.agreeCheck", fields, $index, null, null )}>
                            </AwCheckbox>
                        </div>
                        <div className="sw-row sw-button-wrapper sw-button-group justify-left">
                            <div className="sw-column justify-center">
                                <AwButton className="small" buttonType="base" action={actions.signOut}>
                                    {AwParseService.instance( "i18n.SIGN_OUT_TEXT" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                                </AwButton>
                            </div>
                            <div className="sw-column justify-center">
                                <AwButtonEnableWhen className="small" action={actions.continueSignin} enableWhen={AwParseService.instance( "data.agreeCheck.dbValue===true && data.listBox.dbValue !== ''" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                                    {AwParseService.instance( "i18n.CONTINUE_TEXT" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}
                                </AwButtonEnableWhen>
                            </div>
                        </div>
                    </div>
                </AwForm>
            </div>
        </div>
    </AwScrollpanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;