/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/V4B_InitiateBulkWfProcessService
 * */
 import AwButton from 'viewmodel/AwButtonViewModel';
 import AwCommandPanel from 'viewmodel/AwCommandPanelViewModel';
 import localeService from 'js/localeService';
 import app from "app";
 import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
 import AwLabel from 'viewmodel/AwLabelViewModel';
 import AwPanelFooter from 'viewmodel/AwPanelFooterViewModel';
 import appCtxSvc from 'js/appCtxService';
 import commandPanelService from 'js/commandPanel.service';
 import AwTextarea from 'viewmodel/AwTextareaViewModel';
 import eventBus from "js/eventBus";
  
 
 let commandsList = [];
 /**
  * @author Vaidehi
  * This function is used to create dynamic preference anme according to the seleted type.
  * @returns {String} returns preferenceName
  */
  export let getPreferenceName = function (selectedObj) {
     let selectedType = selectedObj.type; 
     let prefName ="";
     if(selectedType){
         if(selectedType.localeCompare("V4B_VGA_IssueRevision")==0){
             if(selectedObj.props.v4b_vga_in_nael_releaseProc){
                 if(selectedObj.props.v4b_vga_in_nael_releaseProc.dbValue.localeCompare("Inside")==0){
                     prefName = "V4B_WF_Operation_List_V4B_VGA_IssueRevision_VGAF"
                 }else{
                     prefName = "V4B_WF_Operation_List_V4B_VGA_IssueRevision_VGAE"
                 }              
             }
         }else{
             prefName = "V4B_WF_Operation_List_"+selectedType
         }
     }
     return prefName;
  }
 
 /**
  * @author Vaidehi
  * This function reads the prefrence value and forms a json object (taskname, commands for task) for the input taskname, registers it in ctx and activate panel.
  * @param {*} taskName for which respective commands list are required to show on the panel
  * @returns {Json object} returns json object (taskname, commands for task)
  */
 export let getPeferenceValueAndshowPanel = function (prefName,taskName) {
     var currentLocale = localeService.getLocale();
     var localeName;
     if( currentLocale !== null && currentLocale !== '') {
         localeName = currentLocale.substring( 0, 2 );
     }
     
     let preferenceList = appCtxSvc.ctx.preferences;
     console.log(appCtxSvc.ctx.selected)
     let prefValueList = preferenceList[prefName];
     let taskCommandsInfo = {
     };
     prefValueList.forEach(input => {
         const taskName_commands = input.split('=');
        
         if (taskName.localeCompare(taskName_commands[0]) == 0) {
             var commandsDe_EN = taskName_commands[1].split('~')
             var commands = [];
             commandsList=commandsDe_EN;
             commandsDe_EN.forEach(command => {
                 var commandName = command.split('/');
                 if(localeName.localeCompare("de")==0){
                     commands.push(commandName[0].trim())
                 }else{
                     commands.push(commandName[1].trim())
                 }
             })
             taskCommandsInfo = {
                 taskName: taskName,
                 commandsList: commands
             }
         }
     })
     let currentProblemItem = appCtxSvc.getCtx('taskCommandsInfo');
     if (currentProblemItem) {
         appCtxSvc.updateCtx('taskCommandsInfo', taskCommandsInfo);
     } else {
         appCtxSvc.registerCtx('taskCommandsInfo', taskCommandsInfo);
     }
     commandPanelService.activateCommandPanel('V4B_InitiateBulkWfProcess', 'aw_toolsAndInfo');
     return;
 }
 
 /**
  * @author Vaidehi
  * This function is used to create dynamic panel
  * @param {*} prop viewmodel propertiesl
  * @returns {HTML} respective workflow panel for the input task
  */
 export let createWFPanel = function (props,ref) {
 
     let localTextBundle = null;
     localTextBundle = localeService.getLoadedText(
         app.getBaseUrlPath() + "/i18n/VIMWFToolsAndInfoCommandMessages"
     );
     let { ctxDeprecated, actions, fields, ...prop } = props;
     
     var taskCommandsInfo = { ...ctxDeprecated.taskCommandsInfo }
     var taskName = taskCommandsInfo.taskName
     var commandsList = taskCommandsInfo.commandsList
 
     var name = taskName + "_Name";
     let fielddata = { ...fields.TaskName.fielddata };
     fielddata.uiValue = localTextBundle[name];
     const newTaskNameProps = { ...fields, fielddata };
 
     var description = taskName + "_Desp"
     fielddata = { ...fields.TaskInstruction.fielddata };
     fielddata.uiValue = localTextBundle[description];
     const newTaskInstProps = { ...fields, fielddata };
 
     fielddata = { ...fields.TaskComments.fielddata };
     fielddata.displayName="Comments"
     fielddata.rows=7
     fielddata.isEditable=true;
     const newtaskCommentstProps = { ...fields, fielddata };
 
     const onKeyUp = (event) => {     // Your logic for handling key up events }
     }
     var buttonsData = [];
 
     commandsList.forEach(command => {
         var actionName=""
         buttonsData.push({action:() => buttonClicked(command), label: command})
     })
     var panelCaption = localTextBundle["StartWorkflow"];
     return (<AwCommandPanel caption={panelCaption}>
         <AwPanelBody>
             <AwLabel {...newTaskNameProps}></AwLabel>
             <AwLabel {...newTaskInstProps}></AwLabel>
             <AwTextarea  {...Object.assign( {}, fields.TaskComments, { autocomplete:'off' } )} onKeyUp={onKeyUp} aria-label={newtaskCommentstProps.displayName}></AwTextarea>
              </AwPanelBody>
         <AwPanelFooter>
             {buttonsData.map((button, index) => (
                 <AwButton  id={button.label} className='v4b-wf-button' class='accent-highContrast small'  action={button.action}  size="stretched">{button.label}</AwButton>))}
         </AwPanelFooter>
     </AwCommandPanel>)
 };
 
 let buttonClicked = (buttonName) =>  {
     console.log(buttonName)
     console.log(commandsList);
     var commandNameNew = "";
     commandsList.forEach(command => {
        var commandName = command.split('/');
        commandName[0] = commandName[0].trim();
        commandName[1] = commandName[1].trim();
        buttonName = buttonName.trim();
        if(buttonName.localeCompare(commandName[0])==0 || buttonName.localeCompare(commandName[1])==0){
            commandNameNew = command
        }
    })
    console.log(commandNameNew);
     eventBus.publish( 'initiateBulkWF', { commandNameNew } );
     
 }
 export let getListOfSelectedVIMIssues = function (data) {
     let inputs = [];
     let mselectedFTms = appCtxSvc.ctx.mselected;
     mselectedFTms.forEach((selectedFTM, index) => {
         let each = {
             "type": selectedFTM.type,
             "uid": selectedFTM.uid
         };
         inputs.push(each);
     });
    return  inputs ;
 };
 
 export let getTaskName= function(taskName){
     return taskName.trim();
 }
 
 export default {
     createWFPanel,
     getPeferenceValueAndshowPanel,
     getPreferenceName,
     getListOfSelectedVIMIssues,
     getTaskName
     
 };
 