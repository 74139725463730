// @<COPYRIGHT>@
// ==================================================
// Copyright 2018.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/t4b_LoginInfoMessageArchiveService
 */

import soaSvc from "soa/kernel/soaService";
import appCtxSvc from "js/appCtxService";
import eventBus from 'js/eventBus';


let exports = {};

/**
 * Dummy alert.
 */
export let setProperties = function () {
  let selected = appCtxSvc.getCtx("selected");

  //console.log('selected',selected);
  let inputData = {};
  if (selected.type == "T4B_LoginInfoMessage") {
    if (selected.props.t4b_archived.dbValues[0] == "0") {
      inputData = {
        objects: [selected],
        attributes: {
          t4b_archived: {
            stringVec: ["true"],
          },
        },
      };
    }
    if (selected.props.t4b_archived.dbValues[0] == "1") {
      inputData = {
        objects: [selected],
        attributes: {
          t4b_archived: {
            stringVec: ["false"],
          },
        },
      };
    }

    soaSvc
      .post("Core-2007-01-DataManagement", "setProperties", inputData)
      .then(function () {
        var updatedObjects = [ selected ];
        eventBus.publish( 'cdm.relatedModified', {
            relatedModified: updatedObjects
        } );
      });
  }
};


exports = {
  setProperties
};

export default exports;
