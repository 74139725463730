// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AssemblyPlanningSubpage from 'viewmodel/AssemblyPlanningSubpageViewModel';
    import { ExistWhen } from 'js/hocCollection';
const AssemblyPlanningSubpageExistWhen = ExistWhen(AssemblyPlanningSubpage);
AssemblyPlanningSubpageExistWhen.displayName = 'AssemblyPlanningSubpageExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "occContext": {
            "initialValues": {
                "selectedModelObjects": [],
                "viewKey": "",
                "productContextInfo": null,
                "elementToPCIMapCount": null,
                "supportedFeatures": null,
                "skipReloadOnConfigParamChange": true
            },
            "meta": {}
        },
        "provider": {
            "label": "{{i18n.productBOPTitle}}",
            "priority": 3,
            "name": "functionalPlan",
            "clientScopeURI": "functionalPlan",
            "nameToken": "functionalPlan"
        },
        "tabConfig": {
            "tabTitle": "{{i18n.productBOPTitle}}",
            "tabKey": "EpProductBopStructureTree"
        },
        "assemblyTabsConfig": {
            "tabs": [
                {
                    "name": "{{i18n.mbomTitle}}",
                    "tabKey": "EpProductBopMbomTree"
                }
            ]
        },
        "detailsTabsConfig": {
            "tabs": [
                {
                    "name": "{{i18n.partTitle}}",
                    "tabKey": "WiPartsTable",
                    "loadInputObject": {
                        "propertiesToLoad": [
                            "Mfg0all_material"
                        ]
                    }
                },
                {
                    "name": "{{i18n.resourcesTitle}}",
                    "tabKey": "WiResourcesTable",
                    "loadInputObject": {
                        "propertiesToLoad": [
                            "Mfg0used_equipment"
                        ]
                    }
                },
                {
                    "name": "{{i18n.summaryTitle}}",
                    "tabKey": "EpInformation",
                    "xrtType": "SUMMARY",
                    "noElementSelectedMessageForXRT": [
                        {
                            "text": "{{i18n.epInformationNoSelectionMessage}}"
                        }
                    ]
                },
                {
                    "name": "{{i18n.filesTitle}}",
                    "tabKey": "EpFiles",
                    "xrtType": "SUMMARY",
                    "loadInputObject": {
                        "propertiesToLoad": [
                            "mbc0AttachedFiles"
                        ]
                    }
                },
                {
                    "name": "{{i18n.documentsTitle}}",
                    "tabKey": "EpDocuments",
                    "xrtType": "SUMMARY",
                    "loadInputObject": {
                        "loadTypes": [
                            "GetWeblinks"
                        ],
                        "propertiesToLoad": [
                            "mbc0AssignedDocuments"
                        ],
                        "loadedObjectMapKeys": [
                            "WebLink"
                        ]
                    }
                }
            ]
        }
    },
    "i18n": {
        "productBOPTitle": [
            "FunctionalPlanMessages"
        ],
        "assemblyTreeTitle": [
            "AssemblyMessages"
        ],
        "mbomTitle": [
            "FunctionalPlanMessages"
        ],
        "partTitle": [
            "DetailsMessages"
        ],
        "resourcesTitle": [
            "DetailsMessages"
        ],
        "summaryTitle": [
            "DetailsMessages"
        ],
        "filesTitle": [
            "DetailsMessages"
        ],
        "documentsTitle": [
            "DetailsMessages"
        ],
        "assignedPmisTitle": [
            "DetailsMessages"
        ],
        "epInformationNoSelectionMessage": [
            "DetailsMessages"
        ]
    },
    "_viewModelId": "EpProductBopSubpage",
    "_uniqueViewModelId": "EpProductBopSubpage",
    "ctx": {
        "epTaskPageContext": {
            "type": "object"
        }
    }
};

    /*
    <assembly-planning-subpage provider="data.provider" exist-when="ctx.epTaskPageContext.functionalPlan.uid" tab-config="data.tabConfig" assembly-tabs-config="data.assemblyTabsConfig" details-tabs-config="data.detailsTabsConfig" occ-context="fields.occContext"></assembly-planning-subpage>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AssemblyPlanningSubpageExistWhen provider={AwParseService.instance( "data.provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} tabConfig={AwParseService.instance( "data.tabConfig" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} assemblyTabsConfig={AwParseService.instance( "data.assemblyTabsConfig" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} detailsTabsConfig={AwParseService.instance( "data.detailsTabsConfig" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} occContext={AwParseService.instance( "fields.occContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "ctx.epTaskPageContext.functionalPlan.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AssemblyPlanningSubpageExistWhen>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;