// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwInclude from 'viewmodel/AwIncludeViewModel';
import AwSplitter from 'viewmodel/AwSplitterViewModel';
import AwStandardSublocation from 'viewmodel/AwStandardSublocationViewModel';
    import { ExistWhen } from 'js/hocCollection';
const AwIncludeExistWhen = ExistWhen(AwInclude);
const AwSplitterExistWhen = ExistWhen(AwSplitter);
AwIncludeExistWhen.displayName = 'AwIncludeExistWhen';
AwSplitterExistWhen.displayName = 'AwSplitterExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/showPreferencesService', () => import('js/showPreferencesService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {
        "_provider": {
            "clientScopeURI": "Prf1PreferenceResults",
            "nameToken": "showPreferences:PrefSubLocation",
            "breadcrumbConfig": {
                "type": "search",
                "vm": "AwSearchBreadcrumb",
                "noUpdate": true,
                "popuplist": "/html/defaultbreadcrumblist.html",
                "overflowIndex": 0
            },
            "viewModes": {
                "TableView": {
                    "primaryWorkArea": "table",
                    "secondaryWorkArea": true
                }
            },
            "defaultDisplayMode": "TableView",
            "isXrtApplicable": false,
            "context": {
                "search": {}
            }
        },
        "sublocationState": {
            "initialValues": {
                "secondaryActiveTabId": ""
            },
            "meta": {}
        },
        "searchState": {
            "initialValues": {
                "autoApplyFilters": true,
                "filterString": "",
                "criteria": {
                    "searchString": "",
                    "searchFromLocation": "global"
                },
                "sortType": "Priority",
                "label": {
                    "source": "/i18n/SearchMessages",
                    "key": "resultsText"
                },
                "searchFilterMap": "",
                "activeFilterMap": "",
                "pwaSelection": ""
            },
            "meta": {
                "categories": [
                    {
                        "filterValues": [
                            {
                                "selected": {
                                    "field": "true",
                                    "type": "BOOLEAN"
                                }
                            }
                        ]
                    }
                ]
            }
        },
        "isAdministrator": false
    },
    "actions": {
        "initialize": {
            "actionType": "JSFunction",
            "method": "initializeshowPreferences",
            "inputData": {
                "searchState": "{{data.searchState}}",
                "searchStateUpdater": "{{data.updateAtomicData}}"
            },
            "deps": "js/showPreferencesService"
        }
    },
    "ctx": {
        "selected": {
            "type": "object"
        },
        "isAdmin": {
            "type": "BOOLEAN"
        }
    },
    "props": {
        "view-base": {
            "type": "object"
        },
        "view": {
            "type": "object"
        },
        "sub-panel-context": {
            "type": "object"
        },
        "search-state": {
            "type": "object"
        },
        "selection-model": {
            "type": "object"
        },
        "selection-data": {
            "type": "field"
        }
    },
    "lifecycleHooks": {
        "onMount": "initialize"
    },
    "_viewModelId": "showPreferences",
    "_uniqueViewModelId": "showPreferences"
};

    /*
    <div class="sw-column h-12 w-12 flex-shrink">
    <div class="sw-row wrapper row: flex-shrink afx-default-background justify-left aw-preferences-breadcrumb">
        <aw-include name="{{data._provider.breadcrumbConfig.vm}}"
            sub-panel-context="{'searchState': fields.searchState}">
        </aw-include>
    </div>
    <div class="sw-row h-12 w-12">
        <aw-include class="aw-preferences-orgTreePanelContainer" name="preferencesOrgTree" exist-when="ctx.isAdmin"></aw-include>
        <aw-splitter is-primary-splitter="false" direction="{{props.isHorizontalOrientation ? 'HORIZONTAL':''}}" exist-when="ctx.isAdmin">
        </aw-splitter>
        <aw-standard-sublocation sub-panel-context="{'searchState': fields.searchState}" provider="data._provider"
            search-state="data.searchState" base-selection="props.baseSelection" selection-model="props.selectionModel">
        </aw-standard-sublocation>
    </div>
</div>


    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="sw-column h-12 w-12 flex-shrink">
        <div className="sw-row wrapper row: flex-shrink afx-default-background justify-left aw-preferences-breadcrumb">
            <AwInclude viewId={AwParseService.instance( "data._provider.breadcrumbConfig.vm" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} name={AwParseService.instance( "data._provider.breadcrumbConfig.vm" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{'searchState': fields.searchState}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwInclude>
        </div>
        <div className="sw-row h-12 w-12">
            <AwIncludeExistWhen viewId="preferencesOrgTree" viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} className="aw-preferences-orgTreePanelContainer" name="preferencesOrgTree" existWhen={AwParseService.instance( "ctx.isAdmin" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwIncludeExistWhen>
            <AwSplitterExistWhen isPrimarySplitter="false" direction={AwParseService.instance( "props.isHorizontalOrientation ? 'HORIZONTAL':''" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "ctx.isAdmin" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwSplitterExistWhen>
            <AwStandardSublocation subPanelContext={AwParseService.instance( "{'searchState': fields.searchState}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchState={AwParseService.instance( "fields.searchState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} baseSelection={AwParseService.instance( "props.baseSelection" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} selectionModel={AwParseService.instance( "props.selectionModel" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
            </AwStandardSublocation>
        </div>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;