// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Awp0ShapeSearchService', () => import('js/Awp0ShapeSearchService'));
registerDynImportEntry('js/awSearchLocationFilterPanelService', () => import('js/awSearchLocationFilterPanelService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "lifecycleHooks": {
        "onMount": "initializeShapeSearch",
        "onUpdate": {
            "action": "openCloseFilterPanelAction",
            "observers": [
                "viewModel.searchState.categories"
            ]
        }
    },
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "type": "search",
                "vm": "AwShapeSearchBreadcrumb",
                "noUpdate": true,
                "popuplist": "/html/defaultbreadcrumblist.html",
                "overflowIndex": 0
            },
            "clientScopeURI": "Awp0SearchResults",
            "nameToken": "com.siemens.splm.client.search:ShapeSearchResultsSubLocation",
            "context": {
                "search": {
                    "provider": "SS1ShapeSearchDataProvider",
                    "chartTitle": {
                        "source": "/i18n/SearchMessages",
                        "key": "SearchResultsChartTitle"
                    },
                    "criteria": {
                        "forceThreshold": "false",
                        "searchFromLocation": "global",
                        "dcpSortByDataProvider": "true"
                    },
                    "showLoadingText": true,
                    "showChartColorBars": true,
                    "selectFirstObjectPreference": "AWC_select_firstobject_inSearchLocation",
                    "showNoCriteriaMessage": true,
                    "skipFiltersForClearAll": [
                        "SS1partShapeFilter",
                        "SS1shapeBeginFilter",
                        "SS1shapeEndFilter"
                    ]
                }
            },
            "viewBase": "AwShapeSearchResults"
        },
        "searchState": {
            "initialValues": {
                "pwaSelection": []
            },
            "meta": {
                "categories": [
                    {
                        "filterValues": [
                            {
                                "selected": {
                                    "field": "true",
                                    "type": "BOOLEAN"
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    "actions": {
        "activateShapesPanelAction": {
            "actionType": "activateCommandPanel",
            "inputData": {
                "commandId": "SS1ShapeSearchInShapeLocation",
                "location": "aw_navigation"
            }
        },
        "initializeShapeSearch": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "validateCriteriaAndInitiateSearch"
                },
                {
                    "action": "activateSearchFilterAction",
                    "condition": "data.autoOpenFilterPanel"
                },
                {
                    "action": "activateShapesPanelAction",
                    "condition": "!data.autoOpenFilterPanel"
                }
            ]
        },
        "validateCriteriaAndInitiateSearch": {
            "actionType": "JSFunction",
            "method": "validateCriteriaAndInitiateSearch",
            "inputData": {},
            "outputData": {
                "autoOpenFilterPanel": ""
            },
            "deps": "js/Awp0ShapeSearchService"
        },
        "activateSearchFilterAction": {
            "actionType": "activateCommandPanel",
            "inputData": {
                "commandId": "Awp0SearchFilter",
                "location": "aw_navigation"
            }
        },
        "openCloseFilterPanelAction": {
            "actionType": "JSFunction",
            "method": "openCloseFilterPanelAction",
            "inputData": {
                "searchState": "{{data.searchState}}"
            },
            "deps": "js/awSearchLocationFilterPanelService"
        }
    },
    "conditions": {},
    "_viewModelId": "AwShapeSearchResults",
    "_uniqueViewModelId": "AwShapeSearchResults",
    "ctx": {}
};

    /*
    <aw-search-page showsearchbox="subPanelContext.showsearchbox" tabsdata="subPanelContext.tabsData" search-state="data.searchState" provider="data._provider"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage showsearchbox={AwParseService.instance( "subPanelContext.showsearchbox" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchState={AwParseService.instance( "fields.searchState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;