// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwSearchPage from 'viewmodel/AwSearchPageViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/Cm1ChangeCommandService', () => import('js/Cm1ChangeCommandService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "lifecycleHooks": {
        "onUpdate": {
            "action": "openCloseFilterPanelAction",
            "observers": [
                "viewModel.searchState.categories"
            ]
        }
    },
    "data": {
        "_provider": {
            "breadcrumbConfig": {
                "type": "search",
                "vm": "AwSearchBreadcrumb"
            },
            "priority": 1,
            "label": {
                "source": "/i18n/ChangeMessages",
                "key": "allSubLocation"
            },
            "clientScopeURI": "fnd0Change",
            "nameToken": "com.siemens.splm.client.change:allChangesSubLocation",
            "context": {
                "search": {
                    "provider": "Cm1MyChangesProvider",
                    "criteria": {
                        "changesProviderContentType": "All",
                        "searchFromLocation": "global",
                        "getGroupedCategories": "true",
                        "dcpSortByDataProvider": "true",
                        "forceThreshold": "true"
                    },
                    "isFilteringEnabled": true,
                    "sortType": "Priority",
                    "chartTitle": {
                        "source": "/i18n/ChangeMessages",
                        "key": "changeResultsChartTitle"
                    },
                    "maxToLoad": 50,
                    "maxToReturn": 50,
                    "showLoadingText": true,
                    "showChartColorBars": true,
                    "numberOfCategoriesShownInGroupPreference": "AWC_Search_Filter_Panel_Number_Of_Categories_Shown_Inside_Each_Group",
                    "numberOfMaxGroupsShownPreference": "AWC_Search_Filter_Panel_Number_Of_Groups_Shown"
                }
            },
            "policy": "/policies/changeObjectPropertyPolicy.json",
            "viewBase": "Awp0Base"
        },
        "searchState": {
            "initialValues": {
                "filterString": "",
                "selectedFiltersString": ""
            },
            "meta": {
                "categories": [
                    {
                        "filterValues": [
                            {
                                "selected": {
                                    "field": "true",
                                    "type": "BOOLEAN"
                                }
                            }
                        ]
                    }
                ]
            }
        }
    },
    "actions": {
        "openCloseFilterPanelAction": {
            "actionType": "JSFunction",
            "method": "openCloseFilterPanel",
            "inputData": {
                "searchState": "{{data.searchState}}"
            },
            "deps": "js/Cm1ChangeCommandService"
        }
    },
    "_viewModelId": "ShowChangesPage",
    "_uniqueViewModelId": "ShowChangesPage",
    "ctx": {}
};

    /*
    <aw-search-page tabsdata="subPanelContext.tabsData" search-state="data.searchState" provider="data._provider"></aw-search-page>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwSearchPage tabsdata={AwParseService.instance( "subPanelContext.tabsData" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} searchState={AwParseService.instance( "fields.searchState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} provider={AwParseService.instance( "data._provider" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwSearchPage>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;