// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwClsUpdateGraphics from 'viewmodel/AwClsUpdateGraphicsViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "ports": {},
    "lifecycleHooks": {},
    "props": {},
    "dataParseDefinitions": {},
    "actions": {},
    "functions": {},
    "dataProviders": {},
    "columnProviders": {},
    "grids": {},
    "data": {
        "rootstate": {
            "initialValues": {
                "rootString": "iwkB627eZZc72B"
            },
            "meta": {
                "rootString": {
                    "field": "true",
                    "type": "STRING"
                }
            }
        }
    },
    "onEvent": [],
    "conditions": {},
    "messages": {},
    "i18n": {},
    "_viewModelId": "AwClsUpdateGraphicsParentTest",
    "_uniqueViewModelId": "AwClsUpdateGraphicsParentTest",
    "ctx": {}
};

    /*
    <div class="h-12 w-12">
    <aw-cls-update-graphics icoUid="{{data.rootstate.value.rootString}}"></aw-cls-update-graphics>
</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="h-12 w-12">
        <AwClsUpdateGraphics icouid={AwParseService.instance( "fields.rootstate.value.rootString" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
        </AwClsUpdateGraphics>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;