// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwPanel from 'viewmodel/AwPanelViewModel';
import AwPanelBody from 'viewmodel/AwPanelBodyViewModel';
import AwPanelSection from 'viewmodel/AwPanelSectionViewModel';
import AwFlexColumn from 'viewmodel/AwFlexColumnViewModel';
import AwFlexRow from 'viewmodel/AwFlexRowViewModel';
import AwColumn from 'viewmodel/AwColumnViewModel';
import AwTextarea from 'viewmodel/AwTextareaViewModel';
import AwButton from 'viewmodel/AwButtonViewModel';
import AwI18n from 'viewmodel/AwI18nViewModel';
import AwGanttChart from 'viewmodel/AwGanttChartViewModel';
    import { ExistWhen } from 'js/hocCollection';
const AwTextareaExistWhen = ExistWhen(AwTextarea);
AwTextareaExistWhen.displayName = 'AwTextareaExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwGanttChartTestService', () => import('js/AwGanttChartTestService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "data": {
        "ganttChartState": {
            "initialValues": {},
            "meta": {}
        },
        "editorContent": {
            "dbValue": ""
        },
        "config": {
            "language": "javascript",
            "theme": "vs",
            "options": {
                "automaticLayout": true,
                "readOnly": false,
                "lineNumbers": "on"
            },
            "height": "600",
            "width": "800"
        },
        "jsonDataText": {
            "displayName": "",
            "type": "STRING",
            "numberOfLines": "13",
            "isRequired": "false",
            "isEditable": "true",
            "dbValue": "",
            "dispValue": ""
        }
    },
    "actions": {
        "initSampleData": {
            "actionType": "JSFunction",
            "method": "initSampleData",
            "inputData": {
                "atomicDataRef": "{{data.atomicDataRef}}"
            },
            "outputData": {
                "isDataInited": "isDataInited",
                "editor.dbValue": "sampleJsonData",
                "jsonDataText.dbValue": "sampleJsonData"
            },
            "deps": "js/AwGanttChartTestService"
        },
        "setGanttData": {
            "actionType": "JSFunction",
            "method": "setGanttData",
            "inputData": {
                "atomicDataRef": "{{data.atomicDataRef}}",
                "jsonData": "{{data.jsonDataText.dbValue}}"
            },
            "deps": "js/AwGanttChartTestService"
        }
    },
    "lifecycleHooks": {
        "onMount": "initSampleData"
    },
    "ctx": {},
    "conditions": {},
    "onEvent": [],
    "i18n": {
        "apply": [
            "GanttInterfaceConstants"
        ]
    },
    "_viewModelId": "AwGanttChartTest",
    "_uniqueViewModelId": "AwGanttChartTest"
};

    /*
    <aw-panel>
    <aw-panel-body>
        <aw-panel-section caption="Data" collapsed="true">
            <aw-flex-column width="fill">
                <aw-flex-row height="20f">
                    <aw-column width="15">
                        <aw-textarea exist-when=" data.isDataInited" prop="data.jsonDataText" hint="textarea"> </aw-textarea>
                    </aw-column>
                </aw-flex-row>
                <aw-flex-row height="2.5f">
                    <aw-button action="setGanttData" size="auto">
                        <aw-i18n>i18n.apply</aw-i18n>
                    </aw-button>
                </aw-flex-row>
            </aw-flex-column>
        </aw-panel-section>
        <aw-panel-section caption="Gantt Chart">
            <aw-flex-row height="40f">
                <aw-column>
                    <aw-gantt-chart gantt-id="ganttTestComponent" gantt-chart-state='data.ganttChartState'></aw-gantt-chart>
                </aw-column>
            </aw-flex-row>
        </aw-panel-section>
    </aw-panel-body>
</aw-panel>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwPanel  revealAction={actions.reveal}>
        <AwPanelBody>
            <AwPanelSection caption="Data" collapsed="true">
                <AwFlexColumn width="fill">
                    <AwFlexRow height="20f">
                        <AwColumn width="15">
                            <AwTextareaExistWhen {...getField("data.jsonDataText", fields, $index, null, null )} hint="textarea" existWhen={AwParseService.instance( "data.isDataInited" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                            </AwTextareaExistWhen>
                        </AwColumn>
                    </AwFlexRow>
                    <AwFlexRow height="2.5f">
                        <AwButton action={actions.setGanttData} size="auto">
                            <AwI18n>
                                {i18n.apply}
                            </AwI18n>
                        </AwButton>
                    </AwFlexRow>
                </AwFlexColumn>
            </AwPanelSection>
            <AwPanelSection caption="Gantt Chart">
                <AwFlexRow height="40f">
                    <AwColumn>
                        <AwGanttChart ganttId="ganttTestComponent" ganttChartState={AwParseService.instance( "fields.ganttChartState" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                        </AwGanttChart>
                    </AwColumn>
                </AwFlexRow>
            </AwPanelSection>
        </AwPanelBody>
    </AwPanel>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;