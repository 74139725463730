// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwFullScreen from 'viewmodel/AwFullScreenViewModel';
    

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "i18n": {
        "requirementsManagerDisplayName": [
            "RequirementsManagerMessages"
        ]
    },
    "_viewModelId": "requirementsManager",
    "_uniqueViewModelId": "requirementsManager",
    "ctx": {}
};

    /*
    <aw-full-screen include-component-name="requirementsManagerContent" header-title="i18n.requirementsManagerDisplayName"  context="props.subPanelContext"></aw-full-screen>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <AwFullScreen includeComponentName="requirementsManagerContent" headerTitle={i18n.requirementsManagerDisplayName} context={AwParseService.instance( "props.subPanelContext" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwFullScreen>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;