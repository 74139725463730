// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwInclude from 'viewmodel/AwIncludeViewModel';
import AwShowObjectPageForError from 'viewmodel/AwShowObjectPageForErrorViewModel';
    import { ExistWhen } from 'js/hocCollection';
const AwIncludeExistWhen = ExistWhen(AwInclude);
const AwShowObjectPageForErrorExistWhen = ExistWhen(AwShowObjectPageForError);
AwIncludeExistWhen.displayName = 'AwIncludeExistWhen';
AwShowObjectPageForErrorExistWhen.displayName = 'AwShowObjectPageForErrorExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/AwShowObjectLocationService', () => import('js/AwShowObjectLocationService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "data": {
        "primaryActiveTabId": {
            "dbValue": "",
            "type": "OBJECT"
        }
    },
    "actions": {
        "initialize": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "cleanCdmCache"
                },
                {
                    "action": "initializeShowObjLoc"
                }
            ]
        },
        "initializeShowObjLoc": {
            "actionType": "JSFunctionAsync",
            "method": "initialize",
            "inputData": {
                "data": "{{data}}"
            },
            "outputData": {
                "showObjContext": "showObjContext",
                "viewModelObject": "viewModelObject",
                "xrtState": "xrtState"
            },
            "deps": "js/AwShowObjectLocationService"
        },
        "cleanUp": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "unmount"
                }
            ]
        },
        "unmount": {
            "actionType": "JSFunction",
            "method": "unmount",
            "deps": "js/AwShowObjectLocationService"
        },
        "handleStateChange": {
            "actionType": "JSFunctionAsync",
            "method": "handleStateChange",
            "inputData": {
                "showObjContext": "{{data.showObjContext}}",
                "viewModelObject": "{{data.viewModelObject}}",
                "data": "{{data}}"
            },
            "outputData": {
                "showObjContext": "showObjContext",
                "viewModelObject": "viewModelObject",
                "xrtState": "xrtState"
            },
            "deps": "js/AwShowObjectLocationService"
        },
        "cleanCdmCache": {
            "actionType": "Event",
            "method": "Event",
            "inputData": {
                "events": [
                    {
                        "name": "cdm.cleanCache",
                        "eventData": {}
                    }
                ]
            }
        },
        "syncOpenedObjectOnCdmModified": {
            "actionType": "JSFunctionAsync",
            "method": "syncOpenedObject",
            "inputData": {
                "0": "{{data.viewModelObject}}",
                "1": "{{data.eventMap['cdm.modified']}}"
            },
            "outputData": {
                "viewModelObject": "viewModelObject",
                "showObjContext.displayName": "displayName"
            },
            "deps": "js/AwShowObjectLocationService"
        },
        "syncOpenedObjectonRelatedModifed": {
            "actionType": "JSFunctionAsync",
            "method": "syncOpenedObject",
            "inputData": {
                "0": "{{data.viewModelObject}}",
                "1": "{{data.eventMap['cdm.relatedModified']}}"
            },
            "outputData": {
                "viewModelObject": "viewModelObject",
                "showObjContext.displayName": "displayName"
            },
            "deps": "js/AwShowObjectLocationService"
        },
        "activateTab": {
            "actionType": "JSFunctionAsync",
            "method": "activateTab",
            "inputData": {
                "0": "{{data.primaryActiveTabId.dbValue}}",
                "1": "{{data.showObjContext.subLocationTabs}}"
            },
            "deps": "js/AwShowObjectLocationService"
        },
        "handleObjectDeletedEvent": {
            "actionType": "JSFunctionAsync",
            "method": "handleObjectDeletedEvent",
            "inputData": {
                "0": "{{data.viewModelObject}}",
                "1": "{{data.showObjContext}}",
                "2": "{{data.eventData}}"
            },
            "outputData": {
                "showObjContext": "showObjContext"
            },
            "deps": "js/AwShowObjectLocationService"
        }
    },
    "lifecycleHooks": {
        "onMount": "initialize",
        "onUnmount": "cleanUp",
        "onUpdate": [
            {
                "action": "activateTab",
                "observers": [
                    "viewModel.data.primaryActiveTabId"
                ]
            }
        ]
    },
    "i18n": {
        "ShowObjectLocationTitle": [
            "XRTMessages"
        ]
    },
    "onEvent": [
        {
            "eventId": "stateChangeSuccess",
            "action": "handleStateChange"
        },
        {
            "eventId": "cdm.modified",
            "action": "syncOpenedObjectOnCdmModified",
            "cacheEventData": true
        },
        {
            "eventId": "cdm.relatedModified",
            "action": "syncOpenedObjectonRelatedModifed",
            "cacheEventData": true
        },
        {
            "eventId": "cdm.deleted",
            "action": "handleObjectDeletedEvent",
            "cacheEventData": true
        }
    ],
    "props": {
        "sub-panel-context": {
            "type": "object"
        }
    },
    "_viewModelId": "AwShowObjectLocation",
    "_uniqueViewModelId": "AwShowObjectLocation",
    "ctx": {}
};

    /*
    <aw-include name="{{data.showObjContext.activeTab.view?data.showObjContext.activeTab.view:'AwXrtSublocation'}}"  key="{{data.viewModelObject.uid}}"
    sub-panel-context="{provider:props.subPanelContext, openedObject:data.viewModelObject, showObjectContext:data.showObjContext, headerData: fields.viewModelObject, pageContext:{ primarySublocTabState: fields.primaryActiveTabId, primaryActiveTabId: data.showObjContext.activeTab.id} }"
    exist-when="data.viewModelObject && !data.showObjContext.errorMsg"></aw-include>
<aw-show-object-page-for-error exist-when="data.viewModelObject && data.showObjContext.errorMsg" error-msg="{{data.showObjContext.errorMsg}}" display-name="{{data.showObjContext.displayName}}"></aw-show-object-page-for-error>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
            <Fragment>
    <AwIncludeExistWhen viewId={AwParseService.instance( "data.showObjContext.activeTab.view?data.showObjContext.activeTab.view:'AwXrtSublocation'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} viewPath={AwParseService.instance( "viewPath" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} name={AwParseService.instance( "data.showObjContext.activeTab.view?data.showObjContext.activeTab.view:'AwXrtSublocation'" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} key={AwParseService.instance( "data.viewModelObject.uid" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} subPanelContext={AwParseService.instance( "{provider:props.subPanelContext, openedObject:data.viewModelObject, showObjectContext:data.showObjContext, headerData: fields.viewModelObject, pageContext:{ primarySublocTabState: fields.primaryActiveTabId, primaryActiveTabId: data.showObjContext.activeTab.id} }" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "data.viewModelObject && !data.showObjContext.errorMsg" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwIncludeExistWhen>
    <AwShowObjectPageForErrorExistWhen errorMsg={AwParseService.instance( "data.showObjContext.errorMsg" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} displayName={AwParseService.instance( "data.showObjContext.displayName" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )} existWhen={AwParseService.instance( "data.viewModelObject && data.showObjContext.errorMsg" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
    </AwShowObjectPageForErrorExistWhen>
</Fragment>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;